import {Grid, Stack, Typography} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import React from "react";
import {User} from "../model";
import Box from "@mui/material/Box";
import {Controller, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  user: User,
  enabled?: boolean
}

export const PersonalData = ({user, enabled}: ComponentProps) => {

  let control;
  if (enabled) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formMethods = useFormContext();
    control = formMethods.control;
  }

  const {t} = useTranslation('user', {keyPrefix: "detail.personalData"});

  return (
    <Grid
      mt={2.5}
      spacing={2}
      container
    >
      <Grid item xs={12}>
        <Typography variant="h6" textTransform={"uppercase"}>{t("title")}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Stack
          direction={{
            xs: "column",
            sm: "row"
          }}
          spacing={2}
        >
          <Box
            flexBasis={"60%"}
          >
            {enabled ? (
              <Controller
                name="fiscalCode"
                control={control}
                render={({field, fieldState}) => {
                  return <TextFieldZts
                    {...field}
                    label={t("fiscalCode")}
                    errorMsg={fieldState.error?.message}
                  />
                }}
              />
            ) : (
              <TextFieldZts
                disabled={true}
                label={t("fiscalCode")}
                value={user.fiscalCode}
              />
            )}
          </Box>
          <Box
            flexBasis={"40%"}
          >
            {enabled ? (
              <Controller
                name="matricNumber"
                control={control}
                render={({field, fieldState}) => {
                  return <TextFieldZts
                    {...field}
                    label={t("matricNum")}
                    errorMsg={fieldState.error?.message}
                  />
                }}
              />
            ) : (
              <TextFieldZts
                disabled={true}
                label={t("matricNum")}
                value={user.matricNumber}
              />
            )}
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        sx={{
          display: {
            xs: 'none',
            sm: 'block'
          }
        }}
        xs={12}
        sm={6}
      >
        {/* lasciare vuoto */}
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Stack
          direction={{
            xs: "column",
            sm: "row"
          }}
          spacing={2}
        >
          <Box
            flexBasis={"50%"}
          >
            {enabled ? (
              <Controller
                name="address"
                control={control}
                render={({field, fieldState}) => {
                  return <TextFieldZts
                    {...field}
                    label={t("address")}
                    errorMsg={fieldState.error?.message}
                  />
                }}
              />
            ) : (
              <TextFieldZts
                disabled={true}
                label={t("address")}
                value={user.address}
              />
            )}
          </Box>
          <Box
            flexBasis={"20%"}
          >
            {enabled ? (
              <Controller
                name="cap"
                control={control}
                render={({field, fieldState}) => {
                  return <TextFieldZts
                    {...field}
                    label={t("cap")}
                    errorMsg={fieldState.error?.message}
                  />
                }}
              />
            ) : (
              <TextFieldZts
                disabled={true}
                label={t("cap")}
                value={user.cap}
              />
            )}
          </Box>
          <Box
            flexBasis={"30%"}
          >
            {enabled ? (
              <Controller
                name="location"
                control={control}
                render={({field, fieldState}) => {
                  return <TextFieldZts
                    {...field}
                    label={t("locality")}
                    errorMsg={fieldState.error?.message}
                  />
                }}
              />
            ) : (
              <TextFieldZts
                disabled={true}
                label={t("locality")}
                value={user.location}
              />
            )}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}
