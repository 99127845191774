import {Route, Routes} from "react-router-dom";
import {ProtectedRoute} from "../base/protected-routes";
import {FilterableList} from "./list/FilterableList";
import {AccountEdit} from "./edit/AccountEdit";
import {AccountDetail} from "./detail/AccountDetail";
import {useHelp} from "../help/hook";
import {ACCOUNT_URL, DETAIL_URL, EDIT_URL, NEW_URL} from "../model";
import {HelpCurrentPage} from "../../reducers/Help";
import {PremiumWrapper} from "../base/premium-wrapper";
import {PopupContentType} from "../license-popup/model";

export const Accounts = () => {
  useHelp({
    paths: [
      {
        path: `${ACCOUNT_URL}${NEW_URL}`,
        page: HelpCurrentPage.ACCOUNT_NEW,
        exactMatch: true
      },
      {
        path: `${ACCOUNT_URL}${EDIT_URL}`,
        page: HelpCurrentPage.ACCOUNT_EDIT
      },
      {
        path: `${ACCOUNT_URL}${DETAIL_URL}`,
        page: HelpCurrentPage.ACCOUNT_DETAIL
      },
      {
        path: ACCOUNT_URL,
        page: HelpCurrentPage.ACCOUNT_LIST
      }
    ]
  });

  return (
    <PremiumWrapper type={PopupContentType.ACCOUNTS}>
      <ProtectedRoute isAdmin>
        <Routes>
          <Route path="/" element={<FilterableList/>}/>
          <Route path="/detail/:id" element={<AccountDetail/>}/>
          <Route path="/edit/:id" element={<AccountEdit/>}/>
        </Routes>
      </ProtectedRoute>
    </PremiumWrapper>
  );
}
