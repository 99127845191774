import React from "react";

// MUI
import {Box, Grid, Skeleton, Stack} from "@mui/material";


export const TravelPolicyEditSkeleton: React.FC = () => {

  return (
    <>
      <Box width={"100%"} mb={2}>
        <Skeleton variant="rectangular" width='100%' height='56px'/>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width='200px' />
        </Grid>
        <Grid item xs={2} xl={1}>
          <Skeleton variant="rectangular" height='42px'/>
        </Grid>

        <Grid item xs={10} lg={4} xl={5}>
          <Skeleton variant="rectangular" height='42px'/>
        </Grid>

        <Grid item xs={9} lg={6}>
          <Stack direction='row' spacing={2}>
            <Skeleton variant="rectangular" width='148px' height='42px'/>
            <Skeleton variant="rectangular" width='148px' height='42px'/>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop:'26px'}}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width='200px' />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width='500px' />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width='500px' />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop:'50px'}}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width='200px' />
        </Grid>
      </Grid>

      <div style={{display:'flex', flexWrap:'wrap', paddingTop:'10px'}}>
        {[1,2,3,4,5].map( i =>
          <Skeleton key={i} variant="rectangular" width='275px' height='110px' style={{marginRight:'15px', marginBottom:'10px'}}/>
        )}
      </div>
    </>
  );
}
