import {ZtsTable} from "../../../../../base/table";
import React, {useEffect, useState} from "react";
import {ZtsTableColumn} from "../../../../../base/table/model";
import {ExpNotePayment, PaymentCausal} from "../../../../model";
import {Typography} from "@mui/material";
import {EmptyStateExpNote} from "../../../../empty-state";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  columns: ZtsTableColumn[],
  openPayDetailHandler: (id: number) => void,
  payments: ExpNotePayment[]
}

export const ExpNotePaymentList = (props: ComponentProps) => {

  const {
    columns,
    payments,
    openPayDetailHandler
  } = props;

  const [localRows, setLocalRows] = useState<ExpNotePayment[]>(payments);

  const [order, setOrder] = useState<{ orderBy: string, orderDir: 'asc' | 'desc' }>({
    orderBy: 'docNum',
    orderDir: 'asc'
  });

  const {t} = useTranslation("exp-note");

  const getCurrencyChangeRows = (payment: ExpNotePayment) => {
    const rows = [
      payment,
      {
        ...payment,
        hideAggregate: true,
        id: 1000 * payment.id,
        currAmount: payment.convertedCurrAmount,
        currencyCode: payment.convertedCurrencyCode,
        exchange: payment.convertedExchange,
        compAmount: payment.convertedCompAmount,
      }];

    return <ZtsTable
      showHeader={false}
      columns={columns}
      rows={rows}
      rowClickHandler={() => {
      }}
    />
  }

  useEffect(() => {
    const elabPayments = [...payments];

    payments.forEach((payment, index) => {
      if (payment.causal === PaymentCausal.CHANGE_CURRENCY) {
        const newRow = {
          ...payment,
          childrenRows: getCurrencyChangeRows(payment)
        };
        elabPayments.splice(index, 1, newRow);
      }
    });

    setLocalRows(elabPayments);
  }, [payments]);

  const onSortChanged = (orderBy: string, orderDir: 'asc' | 'desc') => {
    setOrder({orderBy, orderDir});
  }

  return (
    <>
      <ZtsTable
        forcedSort={order}
        notifySortChanged={onSortChanged}
        columns={columns}
        rows={localRows}
        rowClickHandler={openPayDetailHandler}
      />
      {localRows.length === 0 && (
        <EmptyStateExpNote
          message={t("payment.list.emptyList")}
        />
      )}
    </>
  );
}
