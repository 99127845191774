import {Card, Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import {Project} from "../model";
import {ProjectCardContent} from "./ProjectCardContent";
import {useNavigate} from "react-router-dom";
import {canceledStateCode} from "../../model";
import {useTranslation} from "react-i18next";

type ProjectCardProps = {
  project: Project,
  onOpen: Function,
  onRestore: Function
}

type ComponentProps = {
  props: ProjectCardProps
}

export const ProjectCard = ({props}: ComponentProps) => {
  const {t} = useTranslation('project', {keyPrefix: 'detail.button'});
  // State
  //------

  const [projectId, setProjectId] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  let className = "account-card";
  if (props.project.state === canceledStateCode) {
    className += " disabled-card";
  }

  // Functions
  //----------

  const handleMenuClick = (projectId: number, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setProjectId(projectId);
    setAnchorEl(e.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    setProjectId(0);
  }

  const onEditItem = (projectId: number) => {
    navigate(`/projects/edit/${projectId}`);
  }

  // React Component
  //----------------

  return (
    <>
      <Card className={className}>
        <ProjectCardContent props={
          {
            project: props.project,
            handleMenuClick: handleMenuClick,
            onOpen: props.onOpen,
            onRestore: props.onRestore
          }
        }
        />
      </Card>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => onEditItem(projectId)}>
          {t("modify")}
        </MenuItem>
      </Menu>
    </>
  );
}
