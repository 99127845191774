import {Button, FormHelperText, Grid, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {TextFieldZts} from "../../base/text-field";
import IconButton from "@mui/material/IconButton";
import {useTranslation} from "react-i18next";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {NewExpenseFormValues, NewExpenseRouteFormValue} from "./validation";
import {formatNumber, parseNumber} from "../../../util/NumberUtil";
import {Delete} from "@mui/icons-material";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {GooglePlaceZTS} from "../../base/autocomplete/google-places";
import {getDistance} from "../Service";
import {AutocompleteGenericOption} from "../../base/autocomplete/model";
import {CarRouteKmDecimalNum} from "../../model";
import {GoogleMaps} from "../../base/svg-icons";
import {CarRoutesMap} from "./CarRoutesMap";

type ComponentProps = {
  onKmChange: (quantity: string) => void,
  editableAndReadonly?: boolean,
}

const ExpectedKmInvalidValue = -1;

export const CarRoutes = ({onKmChange, editableAndReadonly}: ComponentProps) => {

  const [computeAmountTO, setComputeAmountTO] = useState<any>(null);
  const [showMap, setShowMap] = useState(false);

  const {userLocale: locale} = useLoggedUser();

  const formMethods = useFormContext<NewExpenseFormValues>();

  const {fields, append, replace} = useFieldArray({
    name: "routes",
    control: formMethods.control
  });

  const {t} = useTranslation('exp-note', {keyPrefix: 'expense.edit'});

  const routes: NewExpenseRouteFormValue[] = useWatch({name: 'routes', exact: true});

  // aggiunge la prima tratta vuota
  useEffect(() => {
    if (routes.length === 0) {
      append([{location: null, distance: '0', expectedDistance: 0}, {
        location: null,
        distance: '',
        expectedDistance: 0
      }], {shouldFocus: false});
    } else if (!editableAndReadonly) {
      append([{location: null, distance: '', expectedDistance: 0}], {shouldFocus: false});
    }
  }, []);

  useEffect(() => {
    computeAmount();
  }, [routes]);

  const handleDeleteRoute = (index: number) => {
    if (index === 1) {
      const emptyRowIndex = getEmptyRowIndex();
      if (emptyRowIndex > 1) {
        removeRow(index);
      } else {
        formMethods.setValue(`routes.${index}`, {location: null, distance: '', expectedDistance: 0});
      }
    } else {
      removeRow(index);
    }
  }

  const removeRow = (index) => {
    const values = routes;
    if (values) {
      replace(values.filter((_, idx) => index !== idx));
    }
  }

  const getEmptyRowIndex = (): number => {
    return routes.findIndex(g => !g || !g.location || g.location.code === '');
  }

  const computeAmountDebounce = () => {
    if (computeAmountTO) {
      clearTimeout(computeAmountTO);
    }
    setComputeAmountTO(setTimeout(computeAmount, 500));
  }

  const computeAmount = () => {
    if (routes) {
      if (routes.length === 0) {
        formMethods.setValue('quantity', '');
        formMethods.setValue('amount', '');
      } else {
        const totalKm = routes
          .map(route => route && route.distance ? parseNumber(route.distance, locale) : 0)
          .reduce((sum, km) => {
            if (km) {
              if (sum) {
                sum += km;
              } else {
                sum = km;
              }
            }
            return sum;
          });
        //console.log(totalKm)
        if (totalKm) {
          const formattedQuantity = formatNumber(totalKm, locale, CarRouteKmDecimalNum);
          formMethods.setValue('quantity', formattedQuantity);
          onKmChange(formattedQuantity);
        } else {
          formMethods.setValue('quantity', '');
          formMethods.setValue('amount', '');
          onKmChange('');
        }
      }
    }
  }

  const calculateDistance = async (destinationItem: AutocompleteGenericOption, index: number) => {
    let origRoute: NewExpenseRouteFormValue | null = null;
    if (index > 0) {
      origRoute = routes[index - 1];
    }
    const origin: string | null = origRoute && origRoute.location ? origRoute.location.desc : null;
    const destination = destinationItem.desc;

    if (origin && destination) {
      try {
        const resp = await getDistance(origin, destination);
        if (resp.valid) {
          formMethods.setValue(`routes.${index}.distance`, formatNumber(resp.distance, locale, CarRouteKmDecimalNum));
          formMethods.setValue(`routes.${index}.expectedDistance`, resp.distance);
          computeAmount();
        } else {
          formMethods.setValue(`routes.${index}.expectedDistance`, ExpectedKmInvalidValue);
          formMethods.trigger(`routes.${index}.expectedDistance`);
        }
      } catch (error) {
        formMethods.setValue(`routes.${index}.expectedDistance`, ExpectedKmInvalidValue);
        formMethods.trigger(`routes.${index}.expectedDistance`);
      }
    }
  }

  const isDiffFromExpected = (index: number): boolean => {
    const route = routes[index];
    if (route && route.distance) {
      const userValue = parseNumber(route.distance, locale);
      const expectedValue = route.expectedDistance;
      return !!expectedValue && !!userValue && userValue !== expectedValue;
    }
    return false;
  }

  const getKmHelperData = (index): { helperText: string, diffKm: boolean, noKm: boolean } | null => {
    const route = routes[index];
    if (route) {
      const expectedKmNotAvailable = route.expectedDistance === ExpectedKmInvalidValue;

      let diffKm = false;
      if (expectedKmNotAvailable) {
        return {
          noKm: true,
          diffKm: false,
          helperText: t("expectedKmNotAvailable")
        }
      } else {
        diffKm = isDiffFromExpected(index);
        if (diffKm) {
          return {
            noKm: false,
            diffKm: true,
            helperText: `${t("expectedKm")}: ${formatNumber(route.expectedDistance, locale, CarRouteKmDecimalNum)}`
          }
        }
      }
    }
    return null;
  }

  const getReadonlyKm = (index: number): JSX.Element => {
    const helper = getKmHelperData(index);

    return <>
      <Grid item xs={3}>
        {index > 0 &&
          <TextFieldZts
            disabled={true}
            hiddenLabel={true}
            align="right"
            value={formMethods.getValues(`routes.${index}.distance`)}
            helperText={helper && helper.helperText}
            color={helper && (helper.noKm || helper.diffKm) ? "warning" : "primary"}
          />
        }
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  }

  const handleShowMap = () => {
    if (routes.filter(route => route.location && route.location.desc).length > 1) {
      setShowMap(true);
    }
  }

  return (
    <>
      <Grid
        container
        columnSpacing={3}
        rowSpacing={1}
        alignItems="center"
      >

        <Grid
          item
          xs={6}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mb={2}
          >
            <Typography
              variant={"h4"}
            >
              {t("routes")}
            </Typography>

            <Button
              className="map-button"
              variant="contained"
              onClick={handleShowMap}
              startIcon={<GoogleMaps style={{fontSize: "40px"}}/>}
            >
              {t("map")}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={6}></Grid>

        {fields.map((item, index) => (
          <React.Fragment key={item.id}>
            <Grid item xs={6}>
              {editableAndReadonly ? (
                <TextFieldZts
                  disabled={true}
                  hiddenLabel={true}
                  value={formMethods.getValues(`routes.${index}.location`)?.desc}
                />
              ) : (

                <Controller
                  name={`routes.${index}.location`}
                  control={formMethods.control}
                  render={({field, fieldState}) => {
                    return <GooglePlaceZTS
                      setFocus={!editableAndReadonly && index === (routes.filter(r => r.location && r.location.code).length === 0 ? 0 : routes.length - 1)}
                      id={field.name}
                      disabled={!!field.value && (index > 0 || routes.filter(r => !!r.location).length > 1)}
                      errorMsg={fieldState.error?.message}
                      placeholder={index === 0 ? t('departure') : t('destination')}
                      value={field.value}
                      setValue={value => {
                        field.onChange(value);

                        if (value) {
                          calculateDistance(value, index);
                        }

                        const emptyRowIdx = getEmptyRowIndex();

                        if (value && emptyRowIdx === -1) {
                          append({location: null, distance: '', expectedDistance: 0}, {shouldFocus: false});
                        } else if (!value && emptyRowIdx !== index && emptyRowIdx !== routes.length - 1) {
                          removeRow(emptyRowIdx);
                        }
                      }}
                    />
                  }}
                />
              )}
            </Grid>

            {editableAndReadonly ? getReadonlyKm(index) : (
              <>
                <Grid item xs={3}>
                  {index > 0 &&
                    <Controller
                      name={`routes.${index}.distance`}
                      control={formMethods.control}
                      render={({field, fieldState}) => {

                        const helper = getKmHelperData(index);

                        return <TextFieldZts
                          hiddenLabel={true}
                          align={'right'}
                          placeholder={t('km')}
                          {...field}
                          errorMsg={fieldState.error?.message}
                          fullWidth
                          onChange={(e) => {
                            const value = e.target.value;
                            field.onChange(value);
                            computeAmountDebounce();
                          }}
                          helperText={helper && helper.helperText}
                          color={!fieldState.error && helper && (helper.noKm || helper.diffKm) ? "warning" : "primary"}
                        />
                      }}
                    />}
                </Grid>
                <Grid item xs={1}>
                  {index > 0 && index === routes.length - 2 &&
                    <>
                      <IconButton
                        size={"small"}
                        onClick={() => handleDeleteRoute(index)}
                      >
                        <Delete
                          className={"text-danger"}
                          fontSize={"small"}
                        />
                      </IconButton>
                      <FormHelperText> </FormHelperText>
                    </>
                  }
                </Grid>
              </>
            )}
          </React.Fragment>
        ))}
      </Grid>

      <CarRoutesMap
        show={showMap}
        addresses={routes.filter(r => !!r.location).map(r => r.location ? r.location.desc : '')}
        onClose={() => setShowMap(false)}
      />
    </>
  );
}
