import React, {ReactChild, ReactNode, useState} from "react";
import {
  Alert, Box,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import AnnouncementIcon from '@mui/icons-material/Announcement';
import {Loader} from "../loader";
import {useTheme} from "@mui/material/styles";
import {TransitionLeftZts} from "../transition";
import {ArrowBack, Save} from "@mui/icons-material";
import {HelpCurrentPage, setCurrentPage} from "../../../reducers/Help";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import SimpleJsLog from "../../../util/Logger";
import {Logger} from "react-logger-lib";

export interface DialogWidth {
  breakpoint: Breakpoint,
  width: string
}

type CommonDialogProps = {
  show: boolean,
  title: string,
  widths: DialogWidth[],
  saving: boolean,
  onClose: Function,
  errorMsg: string | null,
  className?: string,
  dividers?: boolean,
  children: ReactChild,
  page?: HelpCurrentPage,
  subTitle?: string
  titleIcon?: ReactNode,
  hideSave?: boolean,
  announcementMsg?: string,
  showCta?: boolean
}

const LOG: SimpleJsLog = Logger.of('ZTS.base.CommonDialog');

export const CommonDialog = (props: CommonDialogProps) => {

  //const [data, setData] = useState<ReactChild | null>(null);
  const [hideErrorMsg, setHideErrorMsg] = useState(false);

  const {t} = useTranslation("common", {keyPrefix: "modal"});

  const {
    show,
    title,
    widths,
    saving,
    onClose,
    errorMsg,
    className,
    dividers,
    page,
    subTitle,
    titleIcon,
    hideSave,
    announcementMsg,
    showCta = true
  } = props;

  /*
  useEffect(() => {
    setData(props.children)
  }, [show, props.children]);

   */

  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  //console.log("isXs", isXs, "isSm", isSm, "isMd", isMd, "isLg", isLg)

  let computedWidth;
  let fullScreen;
  if (isXs || isSm) {
    // forzo la visualizzazione fullScreen dal breakpoint Tablet
    computedWidth = "100%";
    fullScreen = true;
  } else {
    let breakpoint: Breakpoint = "xs";
    if (isSm) {
      breakpoint = "sm";
    } else if (isMd) {
      breakpoint = "md";
    } else if (isLg) {
      breakpoint = "lg";
    }

    const breakpointList: Breakpoint[] = [
      "xs", "sm", "md", "lg"
    ];

    const breakpointWidths = new Map<string, string>();
    breakpointList.forEach((b, index) => {
      const widthBp = widths.find(bw => bw.breakpoint === b);
      if (widthBp) {
        breakpointWidths.set(b, widthBp.width);
      } else {
        if (index === 0) {
          breakpointWidths.set(b, "100%");
        } else {
          let found = false;
          let tmpIndex = index;
          while (!found && tmpIndex > 0) {
            const tmpBp = breakpointList[tmpIndex - 1];
            const tmpWidthBp = breakpointWidths.get(tmpBp);
            if (tmpWidthBp) {
              breakpointWidths.set(b, tmpWidthBp);
              found = true;
            }
            tmpIndex -= 1;
          }
        }
      }
    });

    //console.log(breakpointWidths)
    computedWidth = breakpointWidths.get(breakpoint);
    fullScreen = computedWidth === "100%";
  }

  //console.log("computedWidth", computedWidth, "fulScreen", fullScreen)

  const dispatch = useDispatch();

  if (page) {
    dispatch(setCurrentPage(page));
  }

  return (
    <Dialog
      open={show}
      TransitionComponent={TransitionLeftZts}
      fullScreen
      disableEscapeKeyDown={true}
      scroll={"paper"}
      fullWidth={true}
      sx={{
        width: computedWidth,
        left: fullScreen ? 0 : "unset"
      }}
      className={className ? className : ''}
    >
      <DialogTitle>
        {announcementMsg &&
          <Box className='modal-announcement-banner'>
            <AnnouncementIcon/>&nbsp;
            <span className='modal-announcement-text'>{announcementMsg}</span>
          </Box>
        }
        <Stack
          direction={"row"}
          columnGap={2}
          alignItems={"center"}
          sx={{color: "#000"}}
        >
          <ArrowBack onClick={() => {setHideErrorMsg(false); onClose(false);}} cursor={"pointer"}/>
          { subTitle
            ? <Stack flexGrow={titleIcon ? 1 : 0}>
                <Typography variant={"h3"}>{title}</Typography>
                <Typography variant='body1'>{subTitle}</Typography>
              </Stack>

            : <Typography flexGrow={titleIcon ? 1 : 0} variant={"h3"}>{title}</Typography>
          }
          { titleIcon && <>{titleIcon}</> }

        </Stack>

      </DialogTitle>
      <DialogContent
        dividers={dividers}
        sx={{
          position: "relative"
        }}
      >

        {!hideErrorMsg && errorMsg && <Alert
          severity={"error"}
          variant={"filled"}
          sx={{
            mb: 4
          }}
          onClose={() => setHideErrorMsg(true)}>{errorMsg}</Alert>}

        <Loader show={saving}/>

        {props.children}

      </DialogContent>
      {showCta && <DialogActions>
        <Stack
          alignSelf={"flex-end"}
          direction={"row"}
          mt={4}
          columnGap={2.2}
        >
          <Button
            variant="contained"
            className={"cta-button"}
            disabled={saving}
            onClick={() => {
              setHideErrorMsg(false);
              LOG.trace('calling onClose(false) ...');
              onClose(false);
            }}>
            <Typography variant={"button"}>{t (hideSave ? "close" : "cancel")}</Typography>
          </Button>
          {!hideSave &&
            <Button
              variant="contained"
              className={"cta-button-primary"}
              disabled={saving}
              onClick={() => {
                setHideErrorMsg(false);
                LOG.trace('calling onClose(true) ...');
                onClose(true);
              }}
            >
              <Save sx={{fontSize: "1.1em"}}/>
              <Typography ml={1} variant={"button"}>{t("save")}</Typography>
            </Button>
          }
        </Stack>
      </DialogActions>}
    </Dialog>
  );
}
