import * as React from "react";
import {useEffect, useState} from "react";
import {TransitionLeftZts} from "../base/transition";
import {Dialog, DialogContent, DialogTitle, Divider, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import ExpNoteActivity from "./exp_note";
import ReportActivity from "./report";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ActivityLogInfoWithBatchDetail} from "./model";
import {getActivityLogsWithBatchDetail} from "./Service";
import {CheckExpensesActivity} from "./linking";
import {ImportCreCardMovActivity} from "./import";
import excel_icon from "../../assets/images/excel_icon_for_log.png";

interface ActivityProps {
  open: boolean,
  handleClose: () => void,
}

const Activities_register = ({open, handleClose}: ActivityProps) => {
  const [activityLogsWithBatchDetail, setActivityLogsWithBatchDetail] = useState<ActivityLogInfoWithBatchDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const {t} = useTranslation(['activity-register']);
  const {t: tErr} = useTranslation('api-error');

  const asyncGetAndSetActivityLogsWithBatchDetail = async (): Promise<void> => {
    setLoading(true);
    const activityLogsWithBatchDetail = await getActivityLogsWithBatchDetail();
    if (activityLogsWithBatchDetail) {
      setActivityLogsWithBatchDetail(activityLogsWithBatchDetail);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (open) {
      asyncGetAndSetActivityLogsWithBatchDetail().then();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionLeftZts}
      fullScreen
      scroll={"paper"}
      fullWidth={true}
      sx={{
        width: useMediaQuery(theme.breakpoints.down("md")) ? "100%" : "35%",
        left: useMediaQuery(theme.breakpoints.down("md")) ? 0 : "unset"
      }}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          columnGap={2}
          alignItems={"center"}
          sx={{color: "#000"}}
        >
          <ArrowBack onClick={handleClose}/>
          <Typography variant={"h3"}>{t('title')}</Typography>
        </Stack>
      </DialogTitle>

      {
        !loading && activityLogsWithBatchDetail.length > 0 ?
          <DialogContent>
            <Box overflow={'auto'}>
              <Grid container spacing={2} overflow={'hidden'}>
                {!loading &&
                  (
                    activityLogsWithBatchDetail.map((activity, index) => {
                      if (activity.batchName === 'ExpenseReport' || activity.batchName === 'ReconcilReport') {
                        return (
                          <Grid item key={index} width={'100%'}>
                            <Divider sx={{marginBottom: '8px'}}/>
                            <ReportActivity
                              title={activity.activityTitleBundleKey}
                              jobId={activity.batchJobId.toString()}
                              timeStamp={activity.insertTime}
                              state={activity.activityState}
                              datesRange={activity.activitySubtitleBundleParams}
                              fileDownloadName={activity.fileDownloadName}
                              errorMessage={activity.infoMessageBundleKey ? tErr(activity.infoMessageBundleKey, {code: activity.infoMessageBundleParams ? activity.infoMessageBundleParams[0] : ''}) : ''}
                            />
                          </Grid>
                        );
                      } else if (activity.batchName && activity.batchName.startsWith('Exp')) {
                        return (
                          <Grid item key={index} width={'100%'}>
                            <Divider sx={{marginBottom: '8px'}}/>
                            <ExpNoteActivity
                              title={activity.activityTitleBundleKey}
                              jobId={activity.batchJobId.toString()}
                              timeStamp={activity.insertTime}
                              expNoteCode={activity.activitySubtitleBundleParams ? activity.activitySubtitleBundleParams[0] : ''}
                              state={activity.activityState}
                              fileDownloadName={activity.fileDownloadName}
                              errorMessage={activity.infoMessageBundleKey ? tErr(activity.infoMessageBundleKey, {code: activity.infoMessageBundleParams ? activity.infoMessageBundleParams[0] : ''}) : ''}
                              icon={activity.batchName === 'ExpNoteExport' ? <img src={excel_icon} alt="Excel icon" width={20} height={20} /> : undefined}
                            />
                          </Grid>
                        );
                      } else if (activity.batchName && activity.batchName === 'LinkCreCardMovJob') {
                        return (
                          <Grid item key={index} width={'100%'}>
                            <Divider sx={{marginBottom: '8px'}}/>
                            <CheckExpensesActivity
                              title={activity.activityTitleBundleKey}
                              timeStamp={activity.insertTime}
                              state={activity.activityState}
                              datesRange={activity.activitySubtitleBundleParams}
                              errorMessage={activity.infoMessageBundleKey ? tErr(activity.infoMessageBundleKey, {code: activity.infoMessageBundleParams ? activity.infoMessageBundleParams[0] : ''}) : ''}
                            />
                          </Grid>
                        );
                      } else if (activity.batchName && activity.batchName === 'ImportCreCardMovJob') {
                        return (
                          <Grid item key={index} width={'100%'}>
                            <Divider sx={{marginBottom: '8px'}}/>
                            <ImportCreCardMovActivity
                              title={activity.activityTitleBundleKey}
                              timeStamp={activity.insertTime}
                              state={activity.activityState}
                              errorMessage={activity.infoMessageBundleKey ? tErr(activity.infoMessageBundleKey, {code: activity.infoMessageBundleParams ? activity.infoMessageBundleParams[0] : ''}) : ''}
                            />
                          </Grid>
                        );
                      }
                      return null;
                    })
                  )
                }
              </Grid>
            </Box>
          </DialogContent> :
          (
            <Typography variant={'h4'} fontWeight={400} textAlign={'center'} mt={3}>{t('emptyState')}</Typography>
          )
      }
      <Box flexGrow={1}/>
      {
        activityLogsWithBatchDetail.length > 0 &&
        <Box alignSelf={"center"} mt={2} mb={2} onClick={() => {
          handleClose();
          navigate('/activities_list')
        }} sx={{cursor: 'pointer'}}>
          <Typography variant={"h5"}>{t('button.showFullList')}</Typography>
        </Box>
      }
    </Dialog>
  );
}

export default Activities_register;
