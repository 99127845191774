import {Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {PageTitle} from "../../../layout/page-title";
import {FileUpload} from "../../file-upload";
import {Image} from "@mui/icons-material";
import {FileUploadReq} from "../../../model";
import React, {useRef, useState} from "react";
import AvatarEditor from 'react-avatar-editor';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

type ImageEditorProps = {
  setImage: (image: Blob | string | null) => void, //onImageChange
  open: boolean,
  openDialog: (flag: boolean) => void, // onModalClose
  uploadImage: (request: FileUploadReq, progressHandler: (progressEvent: any) => void) => void,
  dialogTitle?: string,
  dialogLabel?: string
}

export const ImageEditor = ({setImage, uploadImage, dialogTitle, dialogLabel, openDialog, open}: ImageEditorProps) => {
  const [scale, setScale] = useState(1);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const editor = useRef(null);
  const theme = useTheme();
  const {t} = useTranslation('base', {keyPrefix: 'images'})

  const handleScale = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newScale = parseFloat(e.target.value);
    setScale(newScale);
  }

  const handleUpload = (file: File) => {
    setErrorMsg(null);
    if (!file) {
      return;
    }
    setFileName(file.name);
    setUploadedImage(new File([file], 'uploadedFile'));
  }

  const handleSave = () => {
    if (editor.current) {

      // https://github.com/mosch/react-avatar-editor/blob/main/packages/lib/src/index.ts riga 298
      // restituisce sempre un HTMLCanvasElement
      // @ts-ignore
      const htmlCanvas: HTMLCanvasElement = editor.current.getImage();

      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement
      const imageDataURL: string = htmlCanvas.toDataURL('image/jpeg', 0.9);

      const trimPrefix = ';base64,';
      const trimIndex = imageDataURL.indexOf(trimPrefix);
      const imageData = imageDataURL.substring(trimIndex + trimPrefix.length);

      // TODO serve ???
      const newFile = new File([imageData], fileName ? fileName : 'croppedImage');

      const request: FileUploadReq = {
        activateOcr: false,
        diskName: fileName,
        diskSize: newFile.size,
        diskTime: newFile.lastModified,
        mimeType: 'image/jpeg',
        rawData: imageData
      }
      uploadImage(request, () => {});
      setImage(imageDataURL);
      setUploadedImage(null);
      setScale(1);
      openDialog(false);
    }
  }

  return (
    <Dialog
      open={open}
      fullWidth
      sx={{width: '100%', height: '80%'}}
    >
      <DialogTitle>
        <PageTitle title={dialogTitle ? dialogTitle : t('image')} backClickHandler={() => openDialog(false)}/>
      </DialogTitle>

      <DialogContent>
        <Box>
          {
            uploadedImage ? (
              <Stack alignItems={'center'}>
                <AvatarEditor ref={editor} image={uploadedImage} scale={scale} width={250} height={250} border={10} borderRadius={250}/>
                <Typography>Zoom</Typography>
                <input
                  type={'range'}
                  min={'0.1'}
                  max={"2"}
                  step={"0.01"}
                  defaultValue={"1"}
                  onChange={handleScale}
                />
              </Stack>
            ) : (
              <Stack alignItems={'center'}>
                <Avatar
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: `250px`,
                    height: `250px`,
                    fontSize: '2.25rem',
                    marginBottom: '16px'
                  }}
                >
                  <Typography>{dialogLabel ? dialogLabel : t('image')}</Typography>
                </Avatar>
                <FileUpload
                  onFileUploaded={handleUpload}
                  pdfEnabled={false}
                  renderElement={(errorMsg) => {
                    return (
                      <>
                        <Box className={"edit-picture"} mb={2}>
                          <Image fontSize={"small"}/>
                          <Typography variant={"h6"}>{t('add')} {dialogLabel ? dialogLabel : t('image')}</Typography>
                        </Box>
                        {
                          errorMsg &&
                          <Typography variant={"body2"} mt={2} className={"text-danger"}>{errorMsg}</Typography>
                        }
                      </>
                    );
                  }}
                />
              </Stack>
            )
          }
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleSave()}>
          {t('confirm')}
        </Button>
        <Button
          onClick={() => {
            setImage(null);
            setUploadedImage(null);
            setScale(1);
            openDialog(false)
          }}
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
