import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const TpEnableProjectSwitchPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile abilitare una travel policy al collegamento con le commesse o i progetti per i quali
          possono essere rendicontate le note spese. Configurando correttamente le commesse, <strong>queste potranno
            essere associate alle spese direttamente da app mobile</strong> e verranno poi <strong>riportate
            nell&rsquo;export contabile di ZTravel Smart</strong> e nei <strong>report</strong> presenti nella
          soluzione. Aumenta il controllo sulle spese effettuate per i progetti e importa in contabilit&agrave; i costi
          sostenuti per una determinata commessa. Maggiore controllo, maggiore velocit&agrave; nel processo di
          fatturazione al cliente e meno errori.</p>
      );
    default:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile abilitare una travel policy al collegamento con le commesse o i progetti per i quali
          possono essere rendicontate le note spese. Configurando correttamente le commesse, <strong>queste potranno
            essere associate alle spese direttamente da app mobile</strong> e verranno poi <strong>riportate
            nell&rsquo;export contabile di ZTravel Smart</strong> e nei <strong>report</strong> presenti nella
          soluzione. Aumenta il controllo sulle spese effettuate per i progetti e importa in contabilit&agrave; i costi
          sostenuti per una determinata commessa. Maggiore controllo, maggiore velocit&agrave; nel processo di
          fatturazione al cliente e meno errori.</p>
      );
  }
}
