import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {ExpensesReports, ReportType} from "../model";
import {ExpenseReportCard} from "../component/ExpenseReportCard";
import {getAllReports} from "../Service";
import {useTranslation} from "react-i18next";

export function ReportExpenses() {
  const [expensesReports, setExpensesReports] = useState<ExpensesReports[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const {t} = useTranslation('report');

  const asyncGetAndSetExpensesReport = async (): Promise<void> => {
    try {
      const allReports = await getAllReports();
      const expReports = allReports.filter((report) => {
        if (report.repType === ReportType.SP) {
          return report;
        }
      });
      // @ts-ignore
      setExpensesReports(expReports);
    } catch (err: any) {
      setErrorMsg(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    asyncGetAndSetExpensesReport().then();
    setLoading(false);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {
          !loading && expensesReports &&
          expensesReports.map((report, index) => {
            return (
              <ExpenseReportCard key={index} cardLabel={report.name} desc={report.description} reportId={report.id} isPro={report.pro}/>
            );
          })

        }
      </Grid>
    </>
  );
}
