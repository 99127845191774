//https://mui.com/blog/lab-date-pickers-to-mui-x/
import {DatePicker, DatePickerProps} from '@mui/x-date-pickers/DatePicker';
import {TextField} from "@mui/material";
import React from "react";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {getInputDateFormat} from "../../../util/LocalizationUtil";

type ComponentProps = {
  label: string,
  field?: any,
  errorMsg?: any,
  onChangeHandler?: Function,
  disableHelperText?: boolean,
  width?: string
  required?: boolean
  disabledValue?: Date | null
}

//TODO: trovare il modo di eliminare il placeholder dd/mm/yyyy
export const DatePickerZts = ({
                                label,
                                field,
                                errorMsg,
                                onChangeHandler,
                                disableHelperText,
                                width,
                                required,
                                disabledValue,
                                ...otherProps
                              }: ComponentProps & Partial<DatePickerProps<any, any>>) => {

  let helperText = '';
  if (!disableHelperText) {
    helperText = errorMsg || ' ';
  }

  // Serve a gestire il caso di disabilitato e mostrare il valore senza controller
  let disabledFlag = false;
  if (otherProps.disabled) {
    disabledFlag = true;
    let newField;
    if (disabledValue) {
      newField = {
        value: disabledValue,
        ...field
      };
    } else {
      newField = {
        value: '',
        ...field
      };
    }
    field = newField;
  }

  const {userLocale} = useLoggedUser();

  const configDateField: DatePickerProps<any, any> = {
    mask: "",
    value: field.value,
    inputFormat: getInputDateFormat(userLocale),
    OpenPickerButtonProps: {
      'color': 'primary'
    },
    label,
    disabled: disabledFlag,
    onChange: (value: Date) => {
      if (onChangeHandler) {
        onChangeHandler(value);
      }
    },
    renderInput: (props) => (
      <TextField
        required={required}
        {...props}
        sx={{
          width: width ? width : '150px'
        }}
        InputLabelProps={{
          sx: {maxWidth: "calc(100% - 60px)"}
        }}
        variant={"filled"}
        size={"small"}
        helperText={helperText}
        error={Boolean(errorMsg)}
      />
    ),
    ...otherProps
  }

  return (
    <DatePicker
      {...field}
      {...configDateField}
    />
  );
}
