import { ExpNoteFilter } from '../../model';
import { ExpNoteListTravellerFilter } from '../../../../reducers/ExpNoteList';

export const convertTravellerPersistedFilterToApiFilter = (filter?: ExpNoteListTravellerFilter): ExpNoteFilter => {
  const apiFilter: ExpNoteFilter = {
    searchText: null,
    startDate: null,
    contabDate: null
  };
  if (filter) {
    if (filter.searchText) {
      apiFilter.searchText = filter.searchText;
    }
    if (filter.startDate) {
      apiFilter.startDate = filter.startDate;
    }
  }
  return apiFilter;
}

export const convertTravellerApiFilterToPersistedFilter = (apiFilter: ExpNoteFilter): ExpNoteListTravellerFilter => {
  const persistedFilter: ExpNoteListTravellerFilter = {};
  if (apiFilter.searchText) {
    persistedFilter.searchText = apiFilter.searchText;
  }
  if (apiFilter.startDate) {
    persistedFilter.startDate = apiFilter.startDate;
  }
  return persistedFilter;
}
