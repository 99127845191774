import {ExpNoteArchive, ExpNoteSortColumn, ExpNoteWithStaff} from "../../model";
import {ZtsTableColumn} from "../../../base/table/model";
import {GreyColor} from "./model";
import {Button, Typography} from "@mui/material";
import {ZtsTable} from "../../../base/table";
import React, {useEffect, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {useExpNoteList} from "../../hooks/useExpNoteList";
import {useLoggedUser} from "../../../../hooks/useLoggedUser";
import {formatDate} from "../../../../util/DateUtil";
import {formatAmountZero} from "../../../../util/NumberUtil";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  expNotes: ExpNoteArchive[],
  selectedItems?: number[],
  setSelectedItems?: Function,
  ctaLabel?: string,
  showReimb?: boolean,
  showPayDate?: boolean,
  showContabDate?: boolean,
  showArchiveDate?: boolean,
  sortExternal?: boolean,
  rowClickHandler?: Function
}

export const ExpNoteBaseList = (props: ComponentProps) => {

  const {
    expNotes,
    showReimb,
    showPayDate,
    showContabDate,
    showArchiveDate,
    selectedItems,
    setSelectedItems,
    ctaLabel,
    sortExternal,
    rowClickHandler
  } = props;

  const {
    adminList: {
      sorting,
    },
    updateAdminSorting,
  } = useExpNoteList();

  const {userLocale: locale, companyDecimalNum: decimalNum, companyCurrencyCode} = useLoggedUser();

  const {t} = useTranslation("exp-note", {keyPrefix: "list"});
  const {t: tCommon} = useTranslation("common");

  const [allChecked, setAllChecked] = useState(false);

  const toggleAllCheck = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (setSelectedItems) {
      if (allChecked) {
        setAllChecked(false);
        setSelectedItems([]);
      } else {
        setAllChecked(true);
        setSelectedItems(expNotes.map(e => e.id));
      }
    }
  }

  useEffect(() => {
    if (selectedItems) {
      if (selectedItems.length === 0) {
        setAllChecked(false);
      } else if (selectedItems.length === expNotes.length) {
        setAllChecked(true);
      }
    }
  }, [expNotes.length, selectedItems]);

  const onToggleSelection = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    e.stopPropagation();
    if (selectedItems && setSelectedItems) {
      if (selectedItems.some(i => i === id)) {
        setSelectedItems(prevState => prevState.filter(i => i !== id));
      } else {
        setSelectedItems(prevState => [...prevState, id]);
      }
    }
  }

  const columns: ZtsTableColumn[] = [];

  const onRowClicked = (id) => {
    if (rowClickHandler) {
      rowClickHandler(id)
    }
  }

  if (selectedItems && setSelectedItems) {
    columns.push({
      id: 'check',
      label: '',
      unsortable: true,
      header: <Checkbox
        disableRipple
        sx={{padding: 0, paddingLeft: "5px"}}
        checked={allChecked}
        onClick={(e) => {
          toggleAllCheck(e);
        }}
      />,
      align: 'left',
      style: {maxWidth: '42px'},
      formatter: (id) => {
        return (
          <Checkbox
            disableRipple
            sx={{paddingLeft: "5px"}}
            checked={selectedItems.some(i => i === id)}
            onClick={(e) => onToggleSelection(e, id)}
          />
        )
      }
    });
  }

  // colonne comuni
  columns.push(
    {id: 'id', label: '#', typography: 'caption', cellColor: GreyColor},
    {id: 'staff', label: t("traveller")},
    {id: 'description', label: tCommon("description")},
    {id: 'startDate', label: t("start"), typography: 'caption', cellColor: GreyColor, formatter: (value) => formatDate(value, locale)},
    {id: 'endDate', label: t("end"), cellColor: GreyColor, typography: 'caption', formatter: (value) => formatDate(value, locale)},
    {id: 'total', label: t("total"), typography: 'caption', formatter: (value) => formatAmountZero(value, locale, decimalNum, companyCurrencyCode)}
  );

  if (showReimb) {
    columns.push({id: 'reimb', label: t("reimbursable"), typography: 'caption', formatter: (value) => formatAmountZero(value, locale, decimalNum, companyCurrencyCode)});
  }

  if (showPayDate) {
    columns.push({
      id: 'payDate',
      label: t("payed"),
      cellColor: GreyColor,
      typography: 'caption',
      formatter: (value) => formatDate(value, locale)
    });
  }

  if (showContabDate) {
    columns.push({
      id: 'contabDate',
      label: t("contab"),
      cellColor: GreyColor,
      typography: 'caption',
      formatter: (value) => formatDate(value, locale)
    });
  }

  if (showArchiveDate) {
    columns.push({id: 'archivingDate', label: t("archived"), typography: 'caption', formatter: (value) => formatDate(value, locale)});
  }

  columns.push({
    id: 'cta', label: '', unsortable: true, displayOnHover: () => true, align: 'right', formatter: (r) => {
      return <Button
        variant="contained"
        className={"cta-list-button"}>
        <Typography variant={"button"}>{ctaLabel ? ctaLabel : t("show")}</Typography>
      </Button>
    }
  });

  const convertedExpNotes = expNotes.map(expNote =>
    ({
      check: expNote.id,
      id: expNote.code,
      staff: expNote.traveller,
      description: expNote.description,
      startDate: expNote.startDate,
      endDate: expNote.endDate,
      total: expNote.expTotal,
      reimb: expNote.debCreStaff,
      payDate: expNote.payDate,
      contabDate: expNote.contabDate,
      archivingDate: expNote.archivingDate,
    }));

  const extraClassName = new Map<number, string>();
  if (selectedItems) {
    selectedItems.forEach(id => extraClassName.set(id, "selected-row"));
  }

  const onSortChanged = (orderBy: string, orderDir: 'asc' | 'desc') => {
    const sortColumn: ExpNoteSortColumn | undefined = Object.entries(ExpNoteSortColumn).find(([key, val]) => val === orderBy)?.[1];
    if (sortColumn) {
      updateAdminSorting({orderBy: sortColumn, orderDir});
    }
  }

  return (
    <Box
      flexGrow={1}
      mb={2}
      overflow={"auto"}
    >
      {convertedExpNotes && <ZtsTable
        extraClassName={extraClassName}
        columns={columns}
        rows={convertedExpNotes}
        forcedSort={sorting}
        notifySortChanged={onSortChanged}
        sortExternal={sortExternal}
        rowClickHandler={rowClickHandler}
      />}
    </Box>
  );

}
