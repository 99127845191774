import { StaffTravelPolicyExpenseItem } from '../components/users/model';
import { DocumentType, PayMode } from '../components/exp_note/expense/model';

enum ExpenseFormActionType {
  SET_PENDING = 'EXPENSE_FORM/SET_PENDING',
  SET_ALL_EXP_ITEMS = 'EXPENSE_FORM/SET_ALL_EXP_ITEMS',
  SET_EXP_ITEM = 'EXPENSE_FORM/SET_EXP_ITEM',
  DELETE_EXP_ITEM = 'EXPENSE_FORM/DELETE_EXP_ITEM',
  SET_DOC_TYPE = 'EXPENSE_FORM/SET_DOC_TYPE',
  SET_PAY_MODE = 'EXPENSE_FORM/SET_PAY_MODE',
  CLEAR = 'EXPENSE_FORM/CLEAR',
}

export interface ExpenseForm {
  pending: boolean,
  expenseItems: StaffTravelPolicyExpenseItem[] | null,
  docType: DocumentType | null,
  payMode: PayMode | null
}

export interface ExpenseFormAction {
  type: ExpenseFormActionType,
  pending?: boolean,
  expenseItems?: StaffTravelPolicyExpenseItem[] | null,
  singleExpItem?: {
    expenseItem?: StaffTravelPolicyExpenseItem,
    index: number
  }
  docType?: DocumentType | null,
  payMode?: PayMode | null
}

const InitState = {
  pending: false,
  expenseItems: null,
  docType: null,
  payMode: null
};

const reducer = (
  state: ExpenseForm = InitState,
  action: ExpenseFormAction) => {
  switch (action.type) {
    case ExpenseFormActionType.SET_PENDING:
      return {...state, pending: action.pending}
    case ExpenseFormActionType.SET_ALL_EXP_ITEMS:
      return {...state, expenseItems: action.expenseItems}
    case ExpenseFormActionType.SET_EXP_ITEM:
      let newExpenseItems: StaffTravelPolicyExpenseItem[] | null = null;
      newExpenseItems = state.expenseItems ? [...state.expenseItems] : [];
      if (action.singleExpItem && action.singleExpItem.expenseItem) {
        newExpenseItems[action.singleExpItem.index] = action.singleExpItem.expenseItem;
      }
      return {...state, expenseItems: newExpenseItems}
    case ExpenseFormActionType.DELETE_EXP_ITEM:
      if (state.expenseItems && action.singleExpItem) {
        const newElements = [...state.expenseItems];
        newElements.splice(action.singleExpItem?.index, 1);
        return {...state, expenseItems: newElements};
      } else {
        return state;
      }
    case ExpenseFormActionType.SET_DOC_TYPE:
      return {...state, docType: action.docType}
    case ExpenseFormActionType.SET_PAY_MODE:
      return {...state, payMode: action.payMode}
    case ExpenseFormActionType.CLEAR:
      return InitState
  }
  return state;
}

// action creator
export const setPending = (pending: boolean): ExpenseFormAction => ({
  type: ExpenseFormActionType.SET_PENDING,
  pending
});

export const setAllExpenseItems = (expenseItems: StaffTravelPolicyExpenseItem[]): ExpenseFormAction => ({
  type: ExpenseFormActionType.SET_ALL_EXP_ITEMS,
  expenseItems
});

export const setSingleExpenseItem = (expenseItem: StaffTravelPolicyExpenseItem, index: number): ExpenseFormAction => ({
  type: ExpenseFormActionType.SET_EXP_ITEM,
  singleExpItem: {
    expenseItem,
    index
  }
});

export const deleteSingleExpenseItem = (index: number): ExpenseFormAction => ({
  type: ExpenseFormActionType.DELETE_EXP_ITEM,
  singleExpItem: {
    index
  }
});

export const setDocumentType = (docType: DocumentType | null): ExpenseFormAction => ({
  type: ExpenseFormActionType.SET_DOC_TYPE,
  docType
});

export const setPayMode = (payMode: PayMode | null): ExpenseFormAction => ({
  type: ExpenseFormActionType.SET_PAY_MODE,
  payMode
});

export const clear = (): ExpenseFormAction => ({
  type: ExpenseFormActionType.CLEAR
});

// utility
export const isPending = (state: { ExpenseForm: ExpenseForm }): boolean => {
  return state.ExpenseForm ? state.ExpenseForm.pending : false;
}

export const getExpenseItems = (state: { ExpenseForm: ExpenseForm }): StaffTravelPolicyExpenseItem[] | null => {
  return state.ExpenseForm ? state.ExpenseForm.expenseItems : null;
}

export const getDocumentType = (state: { ExpenseForm: ExpenseForm }): DocumentType | null => {
  return state.ExpenseForm ? state.ExpenseForm.docType : null;
}

export const getPayMode = (state: { ExpenseForm: ExpenseForm }): PayMode | null => {
  return state.ExpenseForm ? state.ExpenseForm.payMode : null;
}

export default reducer;
