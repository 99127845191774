import {SearchResult} from "../../../component";
import {Stack, Typography} from "@mui/material";
import {HelpPageName} from "../../../../../reducers/Help";
import React from "react";
import {useTranslation} from "react-i18next";

interface ComponentProps {
  index?: number,
  result: SearchResult,
  onResultClick: () => void
}

export const HelpSearchResult = ({result, index, onResultClick}: ComponentProps) => {
  const {t} = useTranslation('help');

  return (
    <Stack key={index ? index : 0} rowGap={0.5}>
      <li>
        <Typography
          variant={"h5"} sx={{cursor: "pointer"}}
          onClick={onResultClick}
          display={'inline'}
        >
          {HelpPageName[result.pageName] ? t('label.helpTitle', {pageName: t(`pageName.${HelpPageName[result.pageName]}`)}) : t('label.helpTitle', {pageName: ''})}
        </Typography>
      </li>
      <Typography>
        <div
          dangerouslySetInnerHTML={{
            __html: result.pageContent
          }}
        >
        </div>
      </Typography>
    </Stack>
  );
}
