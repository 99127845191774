import {FormControlLabel, Stack, Typography} from "@mui/material";
import React from "react";
import {User} from "../model";
import {IOSSwitch} from "../../base/switch";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  user: User,
  enabled?: boolean,
  isAdmin?: boolean,
  handleToggleAdmin?: Function,
  isTraveller?: boolean,
  handleToggleTraveller?: Function
}

export const Role = (props: ComponentProps) => {

  const {t} = useTranslation(['user', 'common']);

  const {
    user,
    enabled,
    isAdmin,
    handleToggleAdmin,
    isTraveller,
    handleToggleTraveller
  } = props;

  return (
    <Stack
      direction={"column"}
      columnGap={2}
      mt={2.5}
      width={"100%"}
    >
      <Typography variant="h6" textTransform={"uppercase"}>{t("user:detail.roles")}</Typography>
      <Stack
        direction={{
          xs: "column",
          sm: "row"
        }}
        columnGap={2}
        mt={2}
      >
        {enabled ? (
          <>
            {handleToggleTraveller &&
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
              >
                <FormControlLabel
                  label={
                    <Typography variant="body1">{t("common:role.traveller")}</Typography>
                  }
                  labelPlacement={"end"}
                  control={
                    <IOSSwitch
                      size={"medium"}
                      name="approverActive"
                      disabled={true}
                      checked={isTraveller}
                      onChange={(e) => handleToggleTraveller(e.target.checked)}
                    />
                  }
                />
              </Stack>}
            {handleToggleAdmin &&
              <Stack
                direction={"row"}
                spacing={2}
                mr={2}
                mb={{
                  xs: 1,
                  sm: 0
                }}
                alignItems={"center"}
              >
                <FormControlLabel
                  label={
                    <Typography variant="body1">{t("common:role.administrator")}</Typography>
                  }
                  labelPlacement={"end"}
                  control={
                    <IOSSwitch
                      checked={isAdmin}
                      onChange={(e) => handleToggleAdmin(e.target.checked)}/>
                  }
                />
              </Stack>}
          </>
        ) : (
          <>
            {isTraveller && <Typography variant="body1">{t("common:role.traveller")}</Typography>}
            {isAdmin && <Typography variant="body1">{t("common:role.administrator")}</Typography>}
          </>
        )}
      </Stack>
    </Stack>
  );
}
