import {Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import imgSrc1 from "../../../../assets/images/welcomeContent2Img1.png";

export const WelcomeContent2 = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} p={2}>
      <Stack flexDirection={'row'}>
        <Typography variant={'h3'} textAlign={'left'} mb={3}>La tua Home Page</Typography>
        <Box flexGrow={1} />
        <Typography variant={'h5'} fontWeight={500}>02/04</Typography>
      </Stack>
      <Box display={'flex'} width={'100%'} maxHeight={'50vh'} overflow={'hidden'} columnGap={2}>
        <Box display={'flex'} flexGrow={1} maxWidth={'30%'} height={'100%'} mt={1.5}>
          <img src={imgSrc1} alt={'welcomeContent2 Img1'} style={{backgroundSize: 'cover'}}/>
        </Box>
        <Box display={'flex'} flexDirection={'column'} overflow={'auto'} alignItems={'left'} maxWidth={'70%'}>
          <p style={{textAlign: 'left', fontWeight: 500, fontSize: 20}}>
            <strong>La tua home page principale è quella amministrativa</strong>
          </p>
          <p style={{textAlign: 'left'}}>
            Nel menù amministratore hai accesso a tutte le funzionalità di gestione della nota spese:
          </p>
          <ul>
            <li style={{textAlign: 'left'}}>il <strong style={{color: '#3c3c3c'}}>processo di controllo e rimborso</strong>,</li>
            <li style={{textAlign: 'left'}}>la definizione delle policy di spesa,</li>
            <li style={{textAlign: 'left'}}>abilitare i tuoi collaboratori a fare le note spese,</li>
            <li style={{textAlign: 'left'}}>analisi dei dati e reportistica</li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
}
