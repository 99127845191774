import { ExpenseCategory } from '../../../../../expenses/model';
import { getAllCategoryOptions } from '../../../../../expenses/utils';
import { TFunction } from 'react-i18next';

interface ChartDataDaily {
  VT?: number,
  VR?: number,
  MT?: number,
  AL?: number,
  AP?: number
}

export interface ChartData {
  name: string,
  daily?: ChartDataDaily,
  total: number,
  exceed: number
}

interface ChartCategory {
  value: ExpenseCategory,
  label: string,
  color: string,
  order: number
}

const getCategoryColor = (category: ExpenseCategory): string => {
  switch (category) {
    case "VT":
      return "#ffa700";
    case "VR":
      return "#dc2e6a";
    case "AL":
      return "#28bc6d";
    case "MT":
      return "#14c6d5";
    case "AP":
      return "#482fdb";
    default:
      return "";
  }
}

export const getCategoryOrder = (category: ExpenseCategory): number => {
  switch (category) {
    case "VT":
      return 1;
    case "VR":
      return 3;
    case "AL":
      return 2;
    case "MT":
      return 4;
    case "AP":
      return 5;
    default:
      return 6;
  }
}

export const getCategories = (t: TFunction): ChartCategory[] => {
  let categories: ChartCategory[] = getAllCategoryOptions(t)
    .map(c => ({
      value: c.value as ExpenseCategory,
      label: c.label ? c.label : '',
      color: getCategoryColor(c.value as unknown as ExpenseCategory),
      order: getCategoryOrder(c.value as unknown as ExpenseCategory)
    }));
  return categories.sort((c1, c2) => c1.order - c2.order)
}
