import {
  ColumnDataType,
  ColumnPosition,
  computeRowIndex,
  ImportConfigurationSeparators,
  isSkipRowsSet, isSkipRowsSetByFilter
} from "../../model";
import {ZtsTableColumn} from "../../../../base/table/model";
import {useLoggedUser} from "../../../../../hooks/useLoggedUser";
import {useTranslation} from "react-i18next";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import {Box, Stack, Step, StepLabel, Stepper, TableCell, TableRow, Typography} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from '@mui/icons-material/Close';
import {ZtsTable} from "../../../../base/table";
import {TextFieldZts} from "../../../../base/text-field";
import React, {useEffect, useState} from "react";
import {FileUploadResp} from "../../../../model";
import {
  getArrayIndexByColumnLabel,
  getColumnLabelByArrayIndex,
  getUploadedFileRows,
  validateColumn
} from "../../Service";
import {formatDate} from "../../../../../util/DateUtil";
import {Loader} from "../../../../base/loader";
import {ResetColumnKeys} from "../../index";

interface ComponentsProps {
  open: boolean,
  configurationSeparators: ImportConfigurationSeparators,
  uploadedFile: FileUploadResp,
  previousPositions?: ColumnPosition,
  setPositions: (positions: ColumnPosition | null) => void,
  setFinalColumns: (columns: ZtsTableColumn[]) => void,
  setFinalRows: (rows: object[]) => void,
  previewHeader: JSX.Element | undefined,
  resetColumnPosition: (columnKey: ResetColumnKeys) => void
}

interface FormValues {
  creCardNumColumn: string,
  payDateColumn: string,
  compAmountColumn: string,
  currencyColumn: string,
  currAmountColumn: string,
  exchangeColumn: string,
  creCardMovReferenceColumn: string,
  creCardMovDescColumn: string,
  catMercColumn: string
}

enum RequiredFields {
  EXCHANGE = 'exchangeColumn',
  REFERENCE = 'creCardMovReferenceColumn',
  CATEGORY = 'catMercColumn'
}

export const ImportConfigurerThirdTab = ({
                                           open,
                                           configurationSeparators,
                                           uploadedFile,
                                           previousPositions,
                                           setPositions,
                                           setFinalColumns,
                                           setFinalRows,
                                            previewHeader,
                                           resetColumnPosition
                                         }: ComponentsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [initialColumns, setInitialColumns] = useState<ZtsTableColumn[]>([]);
  const [columns, setColumns] = useState<ZtsTableColumn[]>([]);
  const [rows, setRows] = useState<object[]>([]);

  const {userLocale} = useLoggedUser();
  const {t} = useTranslation('cre-card-mov', {keyPrefix: 'configurer'});

  const defaultPositions = {
    creCardNumColumn: {dataType: ColumnDataType.NONE},
    payDateColumn: {dataType: ColumnDataType.DATE},
    compAmountColumn: {dataType: ColumnDataType.CURRENCY},
    currencyColumn: {dataType: ColumnDataType.NONE},
    currAmountColumn: {dataType: ColumnDataType.CURRENCY},
    exchangeColumn: {dataType: ColumnDataType.EXCHANGE},
    creCardMovReferenceColumn: {dataType: ColumnDataType.NONE},
    creCardMovDescColumn: {dataType: ColumnDataType.NONE},
    catMercColumn: {dataType: ColumnDataType.NONE}
  };

  const [columnsPositions, setColumnsPositions] = useState<ColumnPosition>(previousPositions ? previousPositions : defaultPositions);

  let formDefaultValues: FormValues = {
    creCardNumColumn: columnsPositions?.creCardNumColumn.index !== undefined ? getColumnLabelByArrayIndex(columnsPositions.creCardNumColumn.index) : '',
    payDateColumn: columnsPositions?.payDateColumn.index !== undefined ? getColumnLabelByArrayIndex(columnsPositions.payDateColumn.index) : '',
    compAmountColumn: columnsPositions?.compAmountColumn.index !== undefined ? getColumnLabelByArrayIndex(columnsPositions.compAmountColumn.index) : '',
    currencyColumn: columnsPositions?.currencyColumn.index !== undefined ? getColumnLabelByArrayIndex(columnsPositions.currencyColumn.index) : '',
    currAmountColumn: columnsPositions?.currAmountColumn.index !== undefined ? getColumnLabelByArrayIndex(columnsPositions.currAmountColumn.index) : '',
    exchangeColumn: columnsPositions?.exchangeColumn.index !== undefined ? getColumnLabelByArrayIndex(columnsPositions.exchangeColumn.index) : '',
    creCardMovReferenceColumn: columnsPositions?.creCardMovReferenceColumn.index !== undefined ? getColumnLabelByArrayIndex(columnsPositions.creCardMovReferenceColumn.index) : '',
    creCardMovDescColumn: columnsPositions?.creCardMovDescColumn.index !== undefined ? getColumnLabelByArrayIndex(columnsPositions.creCardMovDescColumn.index) : '',
    catMercColumn: columnsPositions?.catMercColumn.index !== undefined ? getColumnLabelByArrayIndex(columnsPositions.catMercColumn.index) : ''
  }

  const formMethods = useForm<FormValues>({
    defaultValues: formDefaultValues,
    mode: "onBlur",
    resolver: yupResolver(Yup.object({
      creCardNumColumn: Yup.string(),
      payDateColumn: Yup.string(),
      compAmountColumn: Yup.string(),
      currencyColumn: Yup.string(),
      currAmountColumn: Yup.string(),
      exchangeColumn: Yup.string(),
      creCardMovReferenceColumn: Yup.string(),
      creCardMovDescColumn: Yup.string(),
      catMercColumn: Yup.string()
    }))
  });

  useEffect(() => {
    if (open) {
      if (previousPositions) {
        valCols(previousPositions).then();
      } else {
        asyncGetAndSetRows().then()
      }
    }
  }, [open]);

  const buildZtsTableColumn = (index: number, dataType?: ColumnDataType, highlightColor?: string): ZtsTableColumn => {
    const label = getColumnLabelByArrayIndex(index);
    return {
      id: index.toString(),
      label: label,
      align: 'left',
      unsortable: true,
      rowsStyle: highlightColor ? {
        borderTop: '1px red solid !important',
        borderColor: 'gray',
        backgroundColor: highlightColor
      } : {borderStyle: '1px solid', borderColor: 'gray'},
      formatter: (id, value, columnName = label, dataT = dataType) => {
        const columnValue = value[columnName];
        let newColValue = value[columnName];
        if (dataT) {
          let numericValue: number;
          switch (dataT) {
            case ColumnDataType.DATE:
              newColValue = formatDate(new Date(Date.parse(columnValue)), userLocale);
              break;
            case ColumnDataType.CURRENCY:
              numericValue = columnValue;
              newColValue = numericValue.toLocaleString(userLocale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
              break;
            case ColumnDataType.EXCHANGE:
              numericValue = columnValue;
              newColValue = numericValue.toLocaleString(userLocale, {
                minimumFractionDigits: 6,
                maximumFractionDigits: 6
              });
              break;
          }
        }
        return <Typography fontFamily={'monospace'} whiteSpace={'nowrap'} fontSize={12}>{newColValue}</Typography>;
      }
    };
  }

  const asyncGetAndSetRows = async (): Promise<void> => {
    setLoading(true);
    try {
      const remoteRows = await getUploadedFileRows({
        uploadKey: uploadedFile.uploadKey,
        fileType: uploadedFile.mimeType,
        filter: {
          csvSeparator: configurationSeparators.csvSeparator ? configurationSeparators.csvSeparator : undefined,
          rowsToSkip: configurationSeparators.rowsToSkip ? configurationSeparators.rowsToSkip : undefined,
          dateFormat: configurationSeparators.dateFormat ? configurationSeparators.dateFormat : undefined,
          decimalSeparator: configurationSeparators.decimalSeparator ? configurationSeparators.decimalSeparator : undefined
        },
      });
      if (remoteRows && remoteRows.length > 0) {
        const isSkipRows = isSkipRowsSet(configurationSeparators.rowsToSkip || 0);

        const len = Object.keys(remoteRows[0]).length;
        const remoteColumns = Array.from(Array(len)).map((e, i) => {
          return buildZtsTableColumn(i);
        });

        const indexColumn = {
          id: 'index',
          label: '',
          unsortable: true,
          headerStyle: {border: 'none !important'},
          rowsStyle: {borderRight: '1px solid #808080 !important'},
          formatter: (id, value) => {
            const val = computeRowIndex(value.index, isSkipRows);
            return <Typography fontFamily={'monospace'} whiteSpace={'nowrap'} fontSize={12}>{val}</Typography>;
          }
        };

        setColumns([indexColumn, ...remoteColumns]);
        setInitialColumns([indexColumn, ...remoteColumns]);

        const r = remoteRows.map((row, i) => {
          const result = {
            index: i + 1
          };

          row.forEach((column, idx) => {
            result[getColumnLabelByArrayIndex(idx)] = column;
          });

          return result;
        });

        const startIndex = isSkipRows ? 1 : 0;
        setRows(r.slice(startIndex, startIndex + 4));
      }
    } catch (err: any) {
      //TODO
    } finally {
      setLoading(false);
    }
  }

  const valCols = async (prevCols?: ColumnPosition): Promise<void> => {
    setLoading(true);
    try {
      let colPos = {
        creCardNumColumn: columnsPositions.creCardNumColumn.index !== undefined ? columnsPositions.creCardNumColumn.index : null,
        payDateColumn: columnsPositions.payDateColumn.index !== undefined ? columnsPositions.payDateColumn.index : null,
        compAmountColumn: columnsPositions.compAmountColumn.index !== undefined ? columnsPositions.compAmountColumn.index : null,
        currencyColumn: columnsPositions.currencyColumn.index !== undefined ? columnsPositions.currencyColumn.index : null,
        currAmountColumn: columnsPositions.currAmountColumn.index !== undefined ? columnsPositions.currAmountColumn.index : null,
        exchangeColumn: columnsPositions.exchangeColumn.index !== undefined ? columnsPositions.exchangeColumn.index : null,
        creCardMovReferenceColumn: columnsPositions.creCardMovReferenceColumn?.index !== undefined ? columnsPositions.creCardMovReferenceColumn.index : null,
        creCardMovDescColumn: columnsPositions.creCardMovDescColumn?.index !== undefined ? columnsPositions.creCardMovDescColumn.index : null,
        catMercColumn: columnsPositions.catMercColumn?.index !== undefined ? columnsPositions.catMercColumn.index : null
      };

      if (prevCols) {
        Object.keys(prevCols).forEach(key => {
          if (prevCols[key].index !== undefined) {
            colPos[key] = prevCols[key].index;
          }
        })
      } else {
        const formValues = formMethods.getValues();
        Object.keys(formValues).forEach(key => {
          if (formValues[key].length > 0) {
            colPos[key] = getArrayIndexByColumnLabel(formValues[key]);
          } else {
            colPos[key] = null;
          }
        });
      }

      const resp = await validateColumn({
        uploadKey: uploadedFile.uploadKey,
        fileType: uploadedFile.mimeType,
        filter: {
          csvSeparator: configurationSeparators.csvSeparator ? configurationSeparators.csvSeparator : undefined,
          rowsToSkip: configurationSeparators.rowsToSkip ? configurationSeparators.rowsToSkip : undefined,
          dateFormat: configurationSeparators.dateFormat ? configurationSeparators.dateFormat : undefined,
          decimalSeparator: configurationSeparators.decimalSeparator ? configurationSeparators.decimalSeparator : undefined
        },
        columnsPosition: colPos
      });

      const validatedRows = resp.rows;
      const validatedCols = resp.validation;


      if (validatedCols && validatedRows && validatedRows.length > 0) {
        const len = validatedRows[0].length;

        const newColumns: ZtsTableColumn[] = Array.from(Array(len)).map((col, index) => {
          const found = Object.keys(validatedCols).find(key => colPos[key] === index);
          if (found && validatedCols[found]) {
            return buildZtsTableColumn(index, columnsPositions[found].dataType, 'yellow');
          }
          return buildZtsTableColumn(index);
        });

        const isSkipRows = isSkipRowsSet(configurationSeparators.rowsToSkip || 0);

        const indexColumn = {
          id: 'index',
          label: '',
          unsortable: true,
          headerStyle: {border: 'none !important'},
          rowsStyle: {borderRight: '1px solid #808080 !important'},
          formatter: (id, value) => {
            return <Typography fontFamily={'monospace'} whiteSpace={'nowrap'} fontSize={12}>
              {value.index}
            </Typography>;
          }
        };

        const newRows = validatedRows
          .slice(isSkipRows ? 1 : 0)
          .map((row, i) => {
          const result = {
            index: i + 1
          };
          row.forEach((column, idx) => {
            result[getColumnLabelByArrayIndex(idx)] = column;
          });
          return result;
        }).slice(0, 4);

        setColumns([indexColumn, ...newColumns]);
        setInitialColumns([indexColumn, ...newColumns]);
        setRows(newRows);
        let newColPos: ColumnPosition = columnsPositions;
        Object.keys(validatedCols).forEach(key => {
          newColPos[key] = {
            index: colPos[key],
            isValid: validatedCols[key],
            dataType: columnsPositions[key].dataType
          };
        })
        setColumnsPositions(newColPos)
        const validFields = Object.values(columnsPositions).filter(obj => obj.isValid);

        if (validFields.length === Object.keys(columnsPositions).length) {
          setFinalColumns([indexColumn, ...newColumns]);
          setFinalRows(newRows);
          setPositions(newColPos);
        } else if (validFields.length >= Object.keys(columnsPositions).length - 3) {
          const invalid = Object.keys(columnsPositions).filter(key => !columnsPositions[key].isValid);
          const required = Object.values(RequiredFields).map(value => value);
          let err = 0;
          invalid.forEach(value => {
            if (required.find(r => r === value)) {
              err++;
            }
          })

          if (err === invalid.length) {
            setFinalColumns([indexColumn, ...newColumns]);
            setFinalRows(newRows);
            setPositions(newColPos);
          } else {
            setFinalColumns([]);
            setFinalRows([]);
            setPositions(null);
          }
        } else {
          setFinalColumns([]);
          setFinalRows([]);
          setPositions(null);
        }
      }

    } catch (err: any) {
      //TODO
      throw err;
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      overflow={'hidden'}
      maxHeight={'65vh'}
    >

      <Loader show={loading}/>

      <Stepper activeStep={1}>
        <Step key={1}>
          <StepLabel className={"stepLabel"}>Generale</StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel className={"stepLabel"}>Dati</StepLabel>
        </Step>
        <Step key={3}>
          <StepLabel className={"stepLabel"}>Conferma</StepLabel>
        </Step>
      </Stepper>

      <Typography fontWeight={500} color={'#3315d6'} mt={2}>{t('tabs.3.labels.2')}</Typography>

      <Box
        maxHeight={'40%'}
        overflow={'auto'}
        flexShrink={0}
        mb={2}
        className={'importPreviewTableContainer'}
      >
        <ZtsTable columns={columns} rows={rows} additionalHeader={previewHeader}/>
      </Box>

      <Box display='flex' overflow={'hidden'} flexGrow={1} flexDirection={'row'}>


        <Box display='flex' flexDirection={'column'} width={'50%'} overflow={'auto'} mt={3.2} p={2}>
          <FormProvider {...formMethods}>
            <Stack overflow={'auto'} pr={2}>
              <Controller
                render={({field, fieldState}) =>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'} columnGap={1}>
                    <TextFieldZts
                      size={'small'}
                      required
                      {...field}
                      errorMsg={fieldState.error?.message}
                      label={t('tabs.3.labels.creCardNumColumn')}
                      value={field.value.toUpperCase()}
                      onBlur={() => {
                        if (field.value.length > 0) {
                          valCols().then()
                        } else {
                          setColumnsPositions(prevState => {
                            return {
                              ...prevState,
                              creCardNumColumn: {...prevState.creCardNumColumn, index: undefined, isValid: undefined}
                            }
                          })
                          setFinalColumns([]);
                          setFinalRows([]);
                          setPositions(null);
                          setFinalColumns([]);
                          setFinalRows([]);
                          setPositions(null);
                        }
                      }
                      }
                    />
                    {
                      columnsPositions.creCardNumColumn.isValid === false &&
                      <CloseIcon fontSize={'small'} sx={{color: 'red'}}/>
                    }
                    {
                      columnsPositions.creCardNumColumn.isValid === true &&
                      <CheckIcon fontSize={'small'} sx={{color: 'green'}}/>
                    }
                  </Box>
                }
                name="creCardNumColumn"
              />
              <Controller
                render={({field, fieldState}) =>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'} columnGap={1}>
                    <TextFieldZts
                      size={'small'}
                      required
                      {...field}
                      errorMsg={fieldState.error?.message}
                      value={field.value.toUpperCase()}
                      label={t('tabs.3.labels.payDateColumn')}
                      onBlur={() => {
                        if (field.value.length > 0) {
                          valCols().then()
                        } else {
                          setColumnsPositions(prevState => {
                            return {
                              ...prevState,
                              payDateColumn: {...prevState.payDateColumn, index: undefined, isValid: undefined}
                            }
                          })
                          setFinalColumns([]);
                          setFinalRows([]);
                          setPositions(null);
                        }
                      }}
                    />
                    {
                      columnsPositions.payDateColumn.isValid === false &&
                      <CloseIcon fontSize={'small'} sx={{color: 'red'}}/>
                    }
                    {
                      columnsPositions.payDateColumn.isValid === true &&
                      <CheckIcon fontSize={'small'} sx={{color: 'green'}}/>
                    }
                  </Box>
                }
                name="payDateColumn"
              />
              <Controller
                render={({field, fieldState}) =>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'} columnGap={1}>
                    <TextFieldZts
                      size={'small'}
                      required
                      {...field}
                      errorMsg={fieldState.error?.message}
                      label={t('tabs.3.labels.currAmountColumn')}
                      value={field.value.toUpperCase()}
                      onBlur={() => {
                        if (field.value.length > 0) {
                          valCols().then()
                        } else {
                          setColumnsPositions(prevState => {
                            return {
                              ...prevState,
                              currAmountColumn: {...prevState.currAmountColumn, index: undefined, isValid: undefined}
                            }
                          })
                          setFinalColumns([]);
                          setFinalRows([]);
                          setPositions(null);
                        }
                      }}
                    />
                    {
                      columnsPositions.currAmountColumn.isValid === false &&
                      <CloseIcon fontSize={'small'} sx={{color: 'red'}}/>
                    }
                    {
                      columnsPositions.currAmountColumn.isValid === true &&
                      <CheckIcon fontSize={'small'} sx={{color: 'green'}}/>
                    }
                  </Box>
                }
                name="currAmountColumn"
              />
              <Controller

                render={({field, fieldState}) =>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'} columnGap={1}>
                    <TextFieldZts
                      size={'small'}
                      required
                      {...field}
                      errorMsg={fieldState.error?.message}
                      label={t('tabs.3.labels.currencyColumn')}
                      value={field.value.toUpperCase()}
                      onBlur={() => {
                        if (field.value.length > 0) {
                          valCols().then()
                        } else {
                          setColumnsPositions(prevState => {
                            return {
                              ...prevState,
                              currencyColumn: {...prevState.currencyColumn, index: undefined, isValid: undefined}
                            }
                          })
                          setFinalColumns([]);
                          setFinalRows([]);
                          setPositions(null);
                        }
                      }}
                    />
                    {
                      columnsPositions.currencyColumn.isValid === false &&
                      <CloseIcon fontSize={'small'} sx={{color: 'red'}}/>
                    }
                    {
                      columnsPositions.currencyColumn.isValid === true &&
                      <CheckIcon fontSize={'small'} sx={{color: 'green'}}/>
                    }
                  </Box>
                }
                name="currencyColumn"
              />
              <Controller
                render={({field, fieldState}) =>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'} columnGap={1}>
                    <TextFieldZts
                      size={'small'}
                      required
                      {...field}
                      errorMsg={fieldState.error?.message}
                      label={t('tabs.3.labels.compAmountColumn')}
                      value={field.value.toUpperCase()}
                      onBlur={() => {
                        if (field.value.length > 0) {
                          valCols().then()
                        } else {
                          setColumnsPositions(prevState => {
                            return {
                              ...prevState,
                              compAmountColumn: {...prevState.compAmountColumn, index: undefined, isValid: undefined}
                            }
                          })
                          setFinalColumns([]);
                          setFinalRows([]);
                          setPositions(null);
                        }
                      }}
                    />
                    {
                      columnsPositions.compAmountColumn.isValid === false &&
                      <CloseIcon fontSize={'small'} sx={{color: 'red'}}/>
                    }
                    {
                      columnsPositions.compAmountColumn.isValid === true &&
                      <CheckIcon fontSize={'small'} sx={{color: 'green'}}/>
                    }
                  </Box>
                }
                name="compAmountColumn"
              />
              <Controller
                render={({field, fieldState}) =>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'} columnGap={1}>
                    <TextFieldZts
                      size={'small'}
                      {...field}
                      errorMsg={fieldState.error?.message}
                      label={t('tabs.3.labels.exchangeColumn')}
                      value={field.value.toUpperCase()}
                      onBlur={() => {
                        if (field.value.length > 0) {
                          valCols().then()
                        } else {
                          setColumnsPositions(prevState => {
                            return {
                              ...prevState,
                              exchangeColumn: {...prevState.exchangeColumn, index: -1, isValid: true}
                            }
                          });
                          resetColumnPosition("EXCHANGE");
                        }
                      }}
                    />
                    {
                      columnsPositions.exchangeColumn.isValid === false &&
                      <CloseIcon fontSize={'small'} sx={{color: 'red'}}/>
                    }
                    {
                      columnsPositions.exchangeColumn.isValid === true &&
                      <CheckIcon fontSize={'small'} sx={{color: 'green'}}/>
                    }
                  </Box>
                }
                name="exchangeColumn"
              />
              <Controller
                render={({field, fieldState}) =>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'} columnGap={1}>
                    <TextFieldZts
                      size={'small'}
                      {...field}
                      errorMsg={fieldState.error?.message}
                      label={t('tabs.3.labels.creCardMovReferenceColumn')}
                      value={field.value.toUpperCase()}
                      onBlur={() => {
                        if (field.value.length > 0) {
                          valCols().then()
                        } else {
                          setColumnsPositions(prevState => {
                            return {
                              ...prevState,
                              creCardMovReferenceColumn: {
                                ...prevState.creCardMovReferenceColumn,
                                index: undefined,
                                isValid: undefined
                              }
                            }
                          });
                          resetColumnPosition("REFERENCE");
                        }
                      }}
                    />
                    {
                      columnsPositions.creCardMovReferenceColumn.isValid === false &&
                      <CloseIcon fontSize={'small'} sx={{color: 'red'}}/>
                    }
                    {
                      columnsPositions.creCardMovReferenceColumn.isValid === true &&
                      <CheckIcon fontSize={'small'} sx={{color: 'green'}}/>
                    }
                  </Box>

                }
                name="creCardMovReferenceColumn"
              />
              <Controller
                render={({field, fieldState}) =>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'} columnGap={1}>
                    <TextFieldZts
                      size={'small'}
                      required
                      {...field}
                      errorMsg={fieldState.error?.message}
                      label={t('tabs.3.labels.creCardMovDescColumn')}
                      value={field.value.toUpperCase()}
                      onBlur={() => {
                        if (field.value.length > 0) {
                          valCols().then()
                        } else {
                          setColumnsPositions(prevState => {
                            return {
                              ...prevState,
                              creCardMovDescColumn: {
                                ...prevState.creCardMovDescColumn,
                                index: undefined,
                                isValid: undefined
                              }
                            }
                          })
                          setFinalColumns([]);
                          setFinalRows([]);
                          setPositions(null);
                        }
                      }}
                    />
                    {
                      columnsPositions.creCardMovDescColumn.isValid === false &&
                      <CloseIcon fontSize={'small'} sx={{color: 'red'}}/>
                    }
                    {
                      columnsPositions.creCardMovDescColumn.isValid === true &&
                      <CheckIcon fontSize={'small'} sx={{color: 'green'}}/>
                    }
                  </Box>
                }
                name="creCardMovDescColumn"
              />
              <Controller
                render={({field, fieldState}) =>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'} columnGap={1}>
                    <TextFieldZts
                      size={'small'}
                      {...field}
                      errorMsg={fieldState.error?.message}
                      label={t('tabs.3.labels.catMercColumn')}
                      value={field.value.toUpperCase()}
                      onBlur={() => {
                        if (field.value.length > 0) {
                          valCols().then()
                        } else {
                          setColumnsPositions(prevState => {
                            return {
                              ...prevState,
                              catMercColumn: {...prevState.catMercColumn, index: undefined, isValid: undefined}
                            }
                          });
                          resetColumnPosition("CAT_MERC");
                        }
                      }}
                    />
                    {
                      columnsPositions.catMercColumn.isValid === false &&
                      <CloseIcon fontSize={'small'} sx={{color: 'red'}}/>
                    }
                    {
                      columnsPositions.catMercColumn.isValid === true &&
                      <CheckIcon fontSize={'small'} sx={{color: 'green'}}/>
                    }
                  </Box>
                }
                name="catMercColumn"
              />
            </Stack>
          </FormProvider>

        </Box>
        <Box display='flex' overflow={'auto'} width={'50%'} flexDirection={'column'} p={2}>
          <Typography fontWeight={500} color={'#3315d6'}>Passo 2</Typography>
          <Typography>{t('tabs.3.labels.7')}</Typography>
          <Typography>{t('tabs.3.labels.8')}</Typography>
          <Typography>{t('tabs.3.labels.9')}</Typography>
          <Typography>{t('tabs.3.labels.10')}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
