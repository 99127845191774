// React
import React from "react";
import {Controller} from "react-hook-form";
import {Control, FieldPath} from "react-hook-form/dist/types";

// MUI
import { FormControlLabel } from "@mui/material";

// Custom
import {TravelPolicyExpenseFormValues} from "./TravelPolicyExpenseModal";
import {IOSSwitch} from "../../base/switch";

type TravelPolicyExpenseSwitchProps = {
  name: FieldPath<TravelPolicyExpenseFormValues>
  label: string
  editable: boolean
}

export const TravelPolicyExpenseSwitch: React.FC<TravelPolicyExpenseSwitchProps> = ({
  name,
  label,
  editable
}) => {

  const toBoolean = (value: any): boolean => {
    if (value === undefined || value === null ){
      return false;
    }
    return value;
  }

  return (
    <Controller
      name={name}

      render={ ({field}) => <FormControlLabel
        control={
          <IOSSwitch
            checked = {toBoolean(field.value)}
            onChange={ e => field.onChange(e) }
            onBlur={ () => field.onBlur() }
            disabled={!editable}
          />
        }
        label={label}
        labelPlacement='end' />}
    />
  );
}
