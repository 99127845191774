import {useTranslation} from "react-i18next";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {useTheme} from "@mui/material/styles";
import {TransitionLeftZts} from "../../base/transition";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack, TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import * as React from "react";
import {SyntheticEvent, useEffect, useState} from "react";
import {JobType} from "../../../reducers/CurrentJobs";
import {useSnackbar} from "notistack";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {useCurrentJobs} from "../../../hooks/useCurrentJobs";
import {ReconciliationReport, ReportType} from "../../report/model";
import {getAllReports} from "../../report/Service";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS, CreCardMovPerOperationCounterRequest, CreCardMovState} from "../model";
import {getPerOperationCounters} from "../Service";
import Box from "@mui/material/Box";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {formatDate} from "../../../util/DateUtil";
import {ENGLISH_GB} from "../../../util/LocalizationUtil";
import moment from "moment";
import {Loader} from "../../base/loader";

interface ComponentProps {
  open: boolean,
  handleClose: () => void
}

interface ReconciliationReportForm {
  period: AutocompleteGenericOption | null
}

export const CreCardMovReconciliationPopup = ({open, handleClose}: ComponentProps) => {
  const {t} = useTranslation('cre-card-mov');
  const {t: tPopup} = useTranslation('cre-card-mov', {keyPrefix: 'popup.reconciliation'});
  const {t: tNotifier} = useTranslation('notifier');
  const {userLocale} = useLoggedUser();
  const theme = useTheme();
  const {convertError} = useErrorMessage();
  const {enqueueSnackbar} = useSnackbar();
  const {getRunningJobsState, insertToRunJob} = useCurrentJobs();
  const runningJobs = getRunningJobsState();

  const formMethods = useForm<ReconciliationReportForm>({defaultValues: {period: null}});

  const [reconciliationReport, setReconciliationReport] = useState<ReconciliationReport>();
  const [options, setOptions] = useState<AutocompleteGenericOption[]>([]);
  const [optionDates, setOptionDates] = useState<{ id: number, year: number, month: number }[]>([]);
  const [loadingPeriods, setLoadingPeriods] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      asyncGetAndSetReconciliationReport().then();
      asyncGetAndSetOptions().then();
    }
  }, [open]);


  const asyncGetAndSetReconciliationReport = async (): Promise<void> => {
    try {
      const allReports = await getAllReports();
      const recReport = allReports.find(report => report.repType === ReportType.RC);
      if (recReport) {
        setReconciliationReport(recReport);
      }
    } catch (err: any) {
      convertError(createError(err))
        .then(msg => {
          enqueueSnackbar(msg,
            {
              variant: "error",
              autoHideDuration: 1000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top"
              }
            });
        })
    }
  }

  const asyncGetAndSetOptions = async (): Promise<void> => {
    setLoadingPeriods(true);
    let request: CreCardMovPerOperationCounterRequest = {};
    const endDate = new Date();
    const startDate = moment(endDate).add(-CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS, 'days').toDate();

    request.expDateFrom = formatDate(startDate, ENGLISH_GB);
    request.expDateTo = formatDate(endDate, ENGLISH_GB);
    request.state = CreCardMovState.ALL;

    try {
      const resp = await getPerOperationCounters(request);
      if (resp.elements) {
        const autocompleteOptions = resp.elements.map((value, index) => {
          const code = t(`months.${value.month}`) + " " + value.year;

          return createAutocompleteGenericOption(index + 1, code, code);
        });
        const optionDates = resp.elements.map((value, index) => {
          return {
            id: index + 1,
            year: value.year,
            month: value.month
          };
        });

        setOptions(autocompleteOptions);
        setOptionDates(optionDates);
      }
    } catch (err: any) {
      convertError(createError(err))
        .then(msg => {
          enqueueSnackbar(msg,
            {
              variant: "error",
              autoHideDuration: 1000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top"
              }
            });
        });
    } finally {
      setLoadingPeriods(false);
    }
  }

  const getYear = () => {
    const value = formMethods.getValues('period');
    if (value) {
      const period = optionDates.find(date => date.id === value.id);
      if (period) {
        return period.year;
      }
    }
  }

  const getMonth = () => {
    const value = formMethods.getValues('period');
    if (value) {
      const period = optionDates.find(date => date.id === value.id);
      if (period) {
        return period.month;
      }
    }
  }

  const handlePrint = () => {
    setLoadingPeriods(true);
    if (runningJobs.length >= 3) {
      enqueueSnackbar(tNotifier('maxOpsReached'), {
        variant: "error",
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
    } else if (reconciliationReport?.id) {
      insertToRunJob({
        type: JobType.RECONCILIATION_REPORT,
        id: reconciliationReport.id,
        parameters: [getYear(), getMonth()],
        locale: userLocale
      });
      handleClose();
    }
    setLoadingPeriods(false);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionLeftZts}
      fullScreen
      scroll={"paper"}
      fullWidth={true}
      sx={{
        width: useMediaQuery(theme.breakpoints.down("md")) ? "100%" : "35%",
        left: useMediaQuery(theme.breakpoints.down("md")) ? 0 : "unset"
      }}
    >
      <Loader show={loadingPeriods}/>
      <DialogTitle>
        <Stack
          direction={"row"}
          columnGap={2}
          alignItems={"center"}
          sx={{color: "#000"}}
        >
          <ArrowBack onClick={handleClose}/>
          <Typography variant={"h3"}>{tPopup('title')}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <form noValidate>
            <Box>
              <Typography mb={1}>{tPopup('autocompleteLabel')}</Typography>
              <Controller
                name='period'
                render={({field, fieldState}) =>
                  <Autocomplete
                    options={options}
                    isOptionEqualToValue={(option, value) => option.desc === value.desc}
                    getOptionLabel={option => option.desc}
                    onChange={(e: SyntheticEvent<Element, Event>, value: AutocompleteGenericOption | null) => {
                      field.onChange(value);
                    }}
                    renderInput={(params) => (<TextField {...params} size={"small"}/>)}
                  />
                }
              />
            </Box>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={"cta-button-primary"}
          onClick={handlePrint}
        >
          <Typography ml={1} variant={"button"}>{tPopup("button.print")}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
