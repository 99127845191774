import { activeStateCode, StateType } from '../model';

export interface Supplier {
  id: number,
  vatId: string,
  description: string,
  erpCode: String,
  address: String,
  country: String,
  notes: String,
  lastUpdateNum: number,
  state: StateType
}

export interface SaveSupplier {
  id: number | null,
  vatId: string,
  description: string,
  erpCode: String,
  address: String,
  country: String,
  notes: String,
  lastUpdateNum: number,
  state: string
}
export enum ListFilterType {
  SEARCH_TEXT,
  ENABLED
}

export interface ListFilter {
  type: ListFilterType,
  enabled: boolean,
  label: string,
  value: any,
  hidden?: boolean
}
export const ListFilters: ListFilter[] = [
  {
    type: ListFilterType.SEARCH_TEXT,
    label: '',
    enabled: true,
    hidden: true,
    value: ''
  },
  // {
  //   type: ListFilterType.ENABLED,
  //   label: 'Attivi',
  //   enabled: false,
  //   value: null
  // },
]



export const getSupplierCodeAndDescription = (supplier: Supplier): string => {
  return supplier ? `${supplier.vatId} ${supplier.description}` : '';
}

export const isActiveSupplier = (supplier: Supplier) => {
  return supplier && activeStateCode === supplier.state;
}
