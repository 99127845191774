// React
import React from "react";

// MUI
import {Box, Card, CardContent, Menu, MenuItem, Stack, Typography} from "@mui/material";

// Libs
import { Logger } from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// Custom
import {Expense} from "../model";
import {MoreVert} from "@mui/icons-material";
import {ExpenseImg} from "./ExpenseImg";
import {useTranslation} from "react-i18next";


export type ExpenseCardActionType = 'open' | 'edit' | 'select' | 'delete';

export type ExpenseCardActionEvent = {
  action: ExpenseCardActionType,
  expense: Expense
}

export type ExpenseCardActionHandler = (event: ExpenseCardActionEvent) => void;

type ExpenseCardProps = {
  expense: Expense,
  openable?: boolean,
  editable?: boolean,
  selectable?: boolean,
  onAction: ExpenseCardActionHandler
}

export const ExpenseCard: React.FC<ExpenseCardProps> = ({
  expense,
  openable,
  editable,
  selectable,
  onAction
}) => {

  const LOG: Slf4jsLog = Logger.of('ZTS.Expenses.ExpenseCard');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {t} = useTranslation('expense', {keyPrefix: 'menu.label'});


  const onMenuAction = (action: ExpenseCardActionType) => {
    LOG.trace('onMenuAction', action);
    setAnchorEl(null);
    const event: ExpenseCardActionEvent = {
      action: action,
      expense: expense
    }
    onAction(event);
  }

  const toBool = (flag: boolean | undefined): boolean => {
    if (flag===undefined) { return false; }
    return flag;
  }

  const hasShort: boolean = toBool(openable) || toBool(selectable);
  const hasMenu: boolean = hasShort || toBool(editable);


  const menuOpenLbl = t('open');
  const menuEditLbl = t('edit');
  const menuSelectLbl = t('enable');
  const menuDeleteLbl = t('delete');

  return (
    <>
      <Card className='exp-card' >
        <CardContent sx={{position: 'relative'}}>
          <Stack direction='row' spacing={1} alignItems={'center'}>
            <ExpenseImg
              img={expense.expenseIcon}
              label={expense.description}
            />
            <Typography variant='body2' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'  className='tp-exp-title' style={{lineHeight: 'unset'}}>
              {expense.description}
            </Typography>
          </Stack>

          {hasMenu &&
            <Box
              style={{
                top: '5px',
                right: '5px',
                position: 'absolute',
                zIndex: 20
              }}
            >
              <Box
                onClick={e => setAnchorEl(e.currentTarget)}
                textAlign={"center"}
                mt={1}
                mr={1}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}>
                <MoreVert cursor={"pointer"}/>
              </Box>
            </Box>
          }

          {hasShort &&
            <Box
              style={{
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
                position: 'absolute',
                zIndex: 10,
                cursor: "pointer"
              }}
              onClick={() => selectable ? onMenuAction('select') : onMenuAction('open')}
            >
              {/*
              Area trasparente per la selezione
              Va posizionata dopo il menu e con uno z-index minore
              */}
            </Box>
          }

        </CardContent>
      </Card>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {openable && <MenuItem onClick={() => onMenuAction('open')}>{menuOpenLbl}</MenuItem> }
        {editable && <MenuItem onClick={() => onMenuAction('edit')}>{menuEditLbl}</MenuItem> }
        {selectable && <MenuItem onClick={() => onMenuAction('select')}>{menuSelectLbl}</MenuItem> }
        <MenuItem onClick={() => onMenuAction('delete')}>{menuDeleteLbl}</MenuItem>
      </Menu>
    </>
  );

}
