import {CommonDialog} from "../../base/common-dialog";
import {ExpNote, ExpNoteStateFE, getExpNoteFEState, SaveExpNote} from "../model";
import * as Yup from "yup";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {
  yupMaxNullableString,
  yupMinMaxRequiredString,
  yupRequiredDate,
  yupRequiredEndDate
} from "../../../util/YupUtil";
import {useTranslation} from "react-i18next";
import {NewExpNoteFormValues} from "./model";
import React, {useEffect, useState} from "react";
import {saveExpNote} from "../Service";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {Grid, Skeleton} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import {DatePickerZts} from "../../base/date-picker";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {useExpNoteDetail} from "../detail/hooks/useExpNoteDetail";
import moment from "moment";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {getActiveLevels, getLevelById} from "../../levels/Service";

import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {AutocompleteZts} from "../../base/autocomplete";
import {PremiumIcon} from "../../base/premium-icon";
import {Level} from "../../levels/model";
import {getUserById} from "../../users/Service";
import {activeStateCode} from "../../model";
import {useLicense} from "../../../hooks/useLicense";

type ComponentProps = {
  show: boolean,
  onClose: (res: number | ExpNote | null) => void,
  expNote?: ExpNote
}

export const EditExpNote = ({show, onClose, expNote}: ComponentProps) => {

  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [dateFilter, setDateFilter] = useState<{ min: Date, max: Date } | null>(null);
  const [costCenterOpts, setCostCenterOpts] = useState<AutocompleteGenericOption[]>([]);
  const [loadingCostCenter, setLoadingCostCenter] = useState(false);

  const {t} = useTranslation(['validation']);
  const {t: tCommon} = useTranslation(['common', 'exp-note']);
  const {
    convertError
  } = useErrorMessage();

  const {user: loggedUser} = useLoggedUser();

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const expNoteState: ExpNoteStateFE | null = expNote ? getExpNoteFEState(expNote.state) : null;

  const isCdcEditable = loggedUser &&
    loggedUser.isAdmin &&
    expNoteState &&
    expNoteState === ExpNoteStateFE.DA_CONTROLLARE;

  const {
    expenses
  } = useExpNoteDetail();

  const validationSchema = Yup.object({
    description: yupMinMaxRequiredString(0, 50, t),
    startDate: yupRequiredDate(t),
    endDate: yupRequiredEndDate(t, 'startDate'),
    notes: yupMaxNullableString(500, t),
    costCenter: Yup.object().nullable()
  });

  const formMethods = useForm<NewExpNoteFormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  useEffect(() => {
    const initForm = async () => {
      setErrorMsg(null);
      setDateFilter(null);
      if (expNote) {

        if (expenses && expenses.length > 0) {
          const minDate = expenses
            .map(exp => exp.compStartDate ? exp.compStartDate : exp.compDate)
            .sort((a, b) => a.toString().localeCompare(b.toString()))[0];
          const maxDate = expenses
            .map(exp => exp.compEndDate ? exp.compEndDate : exp.compDate)
            .sort((a, b) => b.toString().localeCompare(a.toString()))[0];
          setDateFilter({min: minDate, max: maxDate});
        }

        let costCenter: Level | null = null;
        if (expNote.costCenterId) {
          if (isCdcEditable) {
            costCenter = await getLevelById(expNote.costCenterId.toString());
          } else {
            // se il cdc non è editabile non chiamo il BE perchè
            // al salvataggio mi serve solo l'id che ho già in expNote
            costCenter = {
              id: expNote.costCenterId,
              code: "",
              description: "",
              lastUpdateNum: 0,
              state: activeStateCode
            }
          }
        }

        formMethods.reset({
          description: expNote.description,
          startDate: expNote.startDate,
          endDate: expNote.endDate,
          notes: expNote.notes,
          costCenter: costCenter ? createAutocompleteGenericOption(costCenter.id, costCenter.code, costCenter.description) : null
        });
      } else {
        formMethods.reset({
          description: '',
          startDate: null,
          endDate: null,
          notes: '',
          costCenter: null
        });
      }
    }
    initForm();
  }, [expNote, show]);

  useEffect(() => {
    if (isCdcEditable) {
      if (!free) {
        const tmpGetLevels = async () => {
          try {
            setLoadingCostCenter(true);
            const traveller = expNote ? await getUserById(expNote.staffId.toString()) : null;
            const levels = await getActiveLevels();
            if (levels && levels.length > 0) {
              setCostCenterOpts(levels
                .sort((l1, l2) => {
                  if (traveller && traveller.costCenter) {
                    if (l1.id === traveller.costCenter.id && l2.id !== traveller.costCenter.id) {
                      return -1;
                    } else if (l1.id !== traveller.costCenter.id && l2.id === traveller.costCenter.id) {
                      return 1;
                    }
                  }
                  return l1.code.localeCompare(l2.code);
                })
                .map(l => createAutocompleteGenericOption(l.id, l.code, l.description)));
              //console.log('costCenterOpts', costCenterOpts)
            }
          } catch (error) {
            console.log('Fetch levels error', error);
          }
          setLoadingCostCenter(false);
        }
        tmpGetLevels();
      } else {
        const tmpCostCenterOpts: AutocompleteGenericOption[] = [];
        setCostCenterOpts(tmpCostCenterOpts);
      }
    }
  }, [isCdcEditable, free, expNote]);

  const handleCloseDialog = (save) => {
    if (save) {
      formMethods.handleSubmit(handleSaveData)();
    } else {
      onClose(null);
    }
  }

  const handleSaveData = async (values) => {
    if (loggedUser) {
      //console.log('handleSaveData', values)
      setErrorMsg(null);
      setSaving(true);

      const data: SaveExpNote = {
        id: expNote && expNote.id !== -1 ? expNote.id : null,
        description: values.description,
        startDate: values.startDate,
        endDate: values.endDate,
        notes: values.notes,
        lastUpdateNum: expNote ? expNote.lastUpdNum : 0,
        staffId: loggedUser.id,
        costCenterId: values.costCenter?.id
      }
      try {
        const res = await saveExpNote(data);
        onClose(res);
      } catch (error) {
        convertError(error as Error).then(res => setErrorMsg(res));
      } finally {
        setSaving(false);
      }
    }
  }

  const title = expNote ? t("exp-note:edit.titleUpdate") : t("exp-note:edit.titleNew");

  const renderCostCenter = (): JSX.Element => {
    if (!isCdcEditable) {
      return <></>;
    }

    return <Grid item xs={6}>
      {!free ? (
        <>
          {loadingCostCenter && <Box flexGrow={1}><Skeleton height={46}/></Box>}

          {!loadingCostCenter && costCenterOpts &&
            <Controller
              name="costCenter"
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <AutocompleteZts
                  id={field.name}
                  options={costCenterOpts}
                  label={t("exp-note:edit.costCenter")}
                  selectedValue={field.value}
                  setValue={(_, value) => {
                    field.onChange(value);
                  }}
                  codeAndDesc={true}
                  errorMsg={fieldState.error?.message}
                />
              }}
            />
          }
        </>
      ) : (
        <TextFieldZts
          disabled={true}
          label={t("exp-note:edit.costCenter")}
          value={formMethods.getValues("costCenter")?.selectedLabel}
          InputProps={{
            endAdornment: (
              free ? <PremiumIcon/> : null
            )
          }}
        />
      )}
    </Grid>
  }

  return (
    <CommonDialog
      show={show}
      title={title}
      widths={[
        {breakpoint: "lg", width: "750px"},
      ]}
      saving={saving}
      onClose={handleCloseDialog}
      errorMsg={errorMsg}
    >
      <FormProvider {...formMethods}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="description"
              render={({field, fieldState}) =>
                <TextFieldZts
                  {...field}
                  required
                  label={tCommon("common:description")}
                  errorMsg={fieldState.error?.message}
                />
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="startDate"
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <DatePickerZts
                  label={tCommon("common:startDate")}
                  field={field}
                  errorMsg={fieldState.error?.message}
                  width={"100%"}
                  onChangeHandler={(value: Date) => field.onChange(value)}
                  required
                  maxDate={dateFilter ? moment(dateFilter.min) : undefined}
                />
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="endDate"
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <DatePickerZts
                  label={tCommon("common:endDate")}
                  field={field}
                  errorMsg={fieldState.error?.message}
                  width={"100%"}
                  onChangeHandler={(value: Date) => field.onChange(value)}
                  required
                  minDate={dateFilter ? moment(dateFilter.max) : undefined}
                />
              }}
            />
          </Grid>

          {renderCostCenter()}

          <Grid item xs={12}>
            <Controller
              name='notes'
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <TextFieldZts
                  multiline
                  minRows={3}
                  maxRows={3}
                  label={tCommon("common:notes")}
                  {...field}
                  errorMsg={fieldState.error?.message}
                  fullWidth
                />
              }}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </CommonDialog>
  );
}
