import {Route, Routes} from "react-router-dom";
import {LevelDetail} from "./detail/LevelDetail";
import {LevelEdit} from "./edit/LevelEdit";
import {FilterableList} from "./list/FilterableList";
import {ProtectedRoute} from "../base/protected-routes";
import {useHelp} from "../help/hook";
import {DETAIL_URL, EDIT_URL, LEVEL_URL, NEW_URL} from "../model";
import {HelpCurrentPage} from "../../reducers/Help";
import {useCurrentList} from "../../hooks/useCurrentList";
import {useEffect} from "react";
import {PremiumWrapper} from "../base/premium-wrapper";
import {PopupContentType} from "../license-popup/model";

export const Level = () => {

  const {clearListFilters} = useCurrentList();

  useEffect(() => {
    return () => clearListFilters()
  }, []);

  useHelp({
    paths: [
      {
        path: `${LEVEL_URL}${NEW_URL}`,
        page: HelpCurrentPage.LEVEL_NEW,
        exactMatch: true
      },
      {
        path: `${LEVEL_URL}${EDIT_URL}`,
        page: HelpCurrentPage.LEVEL_EDIT
      },

      {
        path: `${LEVEL_URL}${DETAIL_URL}`,
        page: HelpCurrentPage.LEVEL_DETAIL
      },
      {
        path: LEVEL_URL,
        page: HelpCurrentPage.LEVEL_LIST
      }
    ]
  });

  return (
    <PremiumWrapper type={PopupContentType.LEVELS}>
      <ProtectedRoute isAdmin>
        <Routes>
          <Route path="/" element={<FilterableList/>}/>
          <Route path="/detail/:id" element={<LevelDetail/>}/>
          <Route path="/edit/:id" element={<LevelEdit/>}/>
        </Routes>
      </ProtectedRoute>
    </PremiumWrapper>
  );
}
