import Dropzone, {DropzoneOptions, DropzoneRef, FileRejection} from "react-dropzone";
import React, {createRef, ReactChild, useState} from "react";
import {FileType, MAX_FILE_NUM, MAX_FILE_SIZE, MimeType} from "./model";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

type ComponentProps = {
  onFileUploaded?: Function,
  renderElement: (errorMsg: string | null, loading: boolean) => ReactChild,
  maxFileSize?: number,
  imageEnabled?: boolean,
  pdfEnabled?: boolean,
  htmlEnabled?: boolean,
  excelEnabled?: boolean,
  csvEnabled?: boolean
}

export const FileUpload = (
  {
    onFileUploaded,
    renderElement,
    maxFileSize = MAX_FILE_SIZE,
    imageEnabled = true,
    pdfEnabled = true,
    htmlEnabled,
    excelEnabled,
    csvEnabled
  }: ComponentProps) => {

  const {t} = useTranslation(['file-upload']);
  const {t: tErr} = useTranslation('api-error');

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const fileTypes: Map<FileType, MimeType> = new Map();

  if (imageEnabled) {
    fileTypes.set(FileType.JPEG, MimeType.JPEG);
    fileTypes.set(FileType.JPG, MimeType.JPG);
    fileTypes.set(FileType.PNG, MimeType.PNG);
  }

  if (pdfEnabled) {
    fileTypes.set(FileType.PDF, MimeType.PDF);
  }

  if (htmlEnabled) {
    fileTypes.set(FileType.HTML, MimeType.HTML);
  }

  if (excelEnabled) {
    fileTypes.set(FileType.EXCEL, MimeType.EXCEL)
    fileTypes.set(FileType.EXCEL_XLSX, MimeType.EXCEL_XLSX)
  }

  if (csvEnabled) {
    fileTypes.set(FileType.CSV, MimeType.CSV);
    fileTypes.set(FileType.TXT, MimeType.TXT);
  }

  const onDropHandler = acceptedFiles => {
    setLoading(false);
    setErrorMsg(null);
    if (onFileUploaded) {
      onFileUploaded(acceptedFiles[0]);
    }
  }

  const onDropRejectedHandler = (fileRejections: FileRejection[]) => {
    setLoading(false);
    if (fileRejections && fileRejections.length > 0) {
      const params = {};
      switch (fileRejections[0].errors[0].code) {
        case 'file-too-large': {
          params['fileSize'] = `${(fileRejections[0].file.size / (1000 * 1000)).toFixed(2)}MB`;
          params['maxFileSize'] = `${(maxFileSize / (1000 * 1000)).toFixed(2)}MB`;
          break;
        }
        case 'file-invalid-type': {
          params['formats'] = Array.from(fileTypes.keys()).join(", ");
          break;
        }
        case 'too-many-files': {
          params['max'] = MAX_FILE_NUM;
          params['count'] = MAX_FILE_NUM;
          break;
        }
      }
      setErrorMsg(t(`error.${fileRejections[0].errors[0].code}`, params));
    } else {
      setErrorMsg(null);
    }
  }

  const dropzoneOpts: DropzoneOptions = {
    accept: `${Array.from(fileTypes.values()).join(",")}`,
    maxFiles: MAX_FILE_NUM,
    maxSize: maxFileSize,
    onFileDialogOpen: () => setLoading(true),
    onFileDialogCancel: () => setLoading(false),
    onDrop: onDropHandler,
    onDropRejected: onDropRejectedHandler,
    noClick: true,
    noKeyboard: true
  }

  const dropzoneRef = createRef<DropzoneRef>();

  const clickHandler = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  }

  return (
    <Dropzone ref={dropzoneRef} {...dropzoneOpts}>
      {({getRootProps, getInputProps}) => {
        return (
          <Box onClick={clickHandler} height="100%">
            <Box {...getRootProps()} height="100%" display="flex">
              <input {...getInputProps()} />
              {renderElement(errorMsg ? tErr(errorMsg) : errorMsg, loading)}
            </Box>
          </Box>
        );
      }}
    </Dropzone>
  );
}
