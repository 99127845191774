import { ReactChild } from 'react';

export interface AlertDialogProps {
  title?: ReactChild,
  body: ReactChild | null,
  label?: string,
  onClick: Function,
  variant?: 'standard' | 'delete',
  dismissable?: boolean
}

export interface AlertDialogState extends AlertDialogProps {
  open: boolean
}

export interface AlertDialogAction extends AlertDialogProps {
  type: AlertDialogActionType
}

export enum AlertDialogActionType {
  OPEN = 'ALERT_DIALOG/SHOW_ALERT',
  CLOSE = 'ALERT_DIALOG/HIDE_ALERT',
}

const InitState: AlertDialogProps = {
  title: undefined,
  body: null,
  label: undefined,
  onClick: () => {
  },
  dismissable: false
}

const reducer = (
  state: AlertDialogState = {
    ...InitState,
    open: false
  },
  action: AlertDialogAction) => {
  switch (action.type) {
    case AlertDialogActionType.OPEN:
      const variant = action.variant || 'standard';
      return {
        ...state,
        open: true,
        ...action,
        variant
      };
    case AlertDialogActionType.CLOSE:
      return {...state, ...InitState, open: false}
  }
  return state;
}

// action creator
export const setAlertDialogOpen = (props: AlertDialogProps): AlertDialogAction => ({
  type: AlertDialogActionType.OPEN,
  ...props
});

export const setAlertDialogClose = (): AlertDialogAction => ({
  type: AlertDialogActionType.CLOSE,
  body: null,
  onClick: () => {
  }
});

// utility
export const getAlertDialogState = (state: { AlertDialog: AlertDialogState }): AlertDialogState => {
  return state.AlertDialog;
}

export default reducer;
