import {ConfirmationNumber, Description, NotInterested, Receipt} from "@mui/icons-material";
import {GreyColor} from "../admin/list/model";
import React, {ReactNode} from "react";
import {DocumentType} from "../expense/model";

export const DocumentTypeIcon = ({docType}: { docType: string }) => {

  const getIcon = (): ReactNode => {
    switch (docType) {
      case DocumentType.RECEIPT:
        return <Receipt fontSize={"small"} sx={{color: GreyColor}}/>
      case DocumentType.INVOICE:
        return <Description fontSize={"small"} sx={{color: GreyColor}}/>
      case DocumentType.TICKET:
        return <ConfirmationNumber fontSize={"small"} sx={{color: GreyColor}}/>
      case DocumentType.NONE:
        return <NotInterested fontSize={"small"} sx={{color: GreyColor}}/>
      default:
        // mantiene l'allineamento tra le righe mettendo una cella non visibile
        return <Receipt fontSize={"small"} sx={{opacity: 0}}/>
    }
  }

  return (
    <>
      {getIcon()}
    </>
  )
}
