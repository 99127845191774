import { createTheme } from '@mui/material/styles';
import typography from './typography';
import breakpoints from './breakpoints';

import vars from '../assets/core/_variables-mui.scss';

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: String(vars.primary)
    },
    grey: {
      300: String(vars.inheritDefault1),
      A100: String(vars.inheritDefault2)
    },
    secondary: {
      main: String(vars.secondary)
    },
    error: {
      main: String(vars.red)
    },
    success: {
      main: String(vars.green)
    },
    warning: {
      main: String(vars.orange)
    },
    helpers: {
      primary: String(vars.blue),
      main: 'rgba(25, 46, 91, .035)'
    },
    subtitle1: {
      main: String(vars.subtitle1)
    },
    h6: {
      main: String(vars.h6)
    },
    greenPrimary: {
      main: String(vars.greenPrimary)
    },
    contrastThreshold: 3,
    tonalOffset: 0.1
  },
  shape: {
    borderRadius: 6
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: String(vars.secondary),
        padding: '8px 16px',
        fontSize: '13px'
      },
      arrow: {
        color: String(vars.secondary)
      }
    }
  },
  typography,
  breakpoints
});

export default MuiTheme;
