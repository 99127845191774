import {useExpNoteList} from "../../hooks/useExpNoteList";
import {ExpNoteListTravellerFilter} from "../../../../reducers/ExpNoteList";
import Box from "@mui/material/Box";
import {Alert, Button, Paper, Skeleton, Stack, Typography} from "@mui/material";
import {PageTitle} from "../../../layout/page-title";
import React, {useEffect, useState} from "react";
import {DateFormat, GenericPagedList} from "../../../model";
import {ALL_EXPNOTE_DEFAULT_PAGE_SIZE, ExpNoteListFilter, ExpNoteListFilterType} from "../../admin/list/model";
import {
  convertTravellerPersistedFilterToApiFilter,
  HistoricalExpNote,
  HistoricalExpNoteFilter,
} from "./model";
import {HistoricalExpNoteFilters} from "./filter";
import {HistoricalExpNoteBaseList} from "./BaseList";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {downloadZipFile, getAllHistoricalExpNotes, getCsvExpNotes, getFileZipName} from "./Service";
import {useErrorMessage} from "../../../../util/ErrorUtil";

const InitApiFilter: HistoricalExpNoteFilter = {
  searchText: null,
  startDate: null,
  contabDate: null
}

export const HistoricalExpNoteList = () => {

  const {
    travellerList: {
      filter,
      sorting,
      pagination
    },
    updateTravellerFilter,
    updateTravellerPagination,
  } = useExpNoteList();

  const [allExpNotes, setAllExpNotes] = useState<HistoricalExpNote[]>([]);
  const [expNotes, setExpNotes] = useState<HistoricalExpNote[]>([]);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [apiFilter, setApiFilter] = useState<HistoricalExpNoteFilter>(filter ? convertTravellerPersistedFilterToApiFilter(filter) : InitApiFilter);
  const [totalPages, setTotalPages] = useState(1);


  const {t} = useTranslation('historical-exp-note');
  const {
    convertError
  } = useErrorMessage();

  const navigate = useNavigate();

  const fetchExpNotes = (fetchFunction: (pageSize: number, page: number) => Promise<GenericPagedList<HistoricalExpNote>>, pageSize = ALL_EXPNOTE_DEFAULT_PAGE_SIZE, page = 1, expNotes: HistoricalExpNote[] = []): Promise<HistoricalExpNote[]> => {
    return fetchFunction(pageSize, page)
      .then(res => {
        expNotes.push(...res.elements);
        if (res.totalItems === expNotes.length) {
          return expNotes;
        }
        return fetchExpNotes(fetchFunction, pageSize, res.page + 1, expNotes);
      });
  }

  useEffect(() => {
    //Inizializzo la lista delle note spese
    loadExpenseNotes();
  }, []);

  const loadExpenseNotes = () =>{
    let fetchFunction: ((pageSize: number, page: number) => Promise<GenericPagedList<HistoricalExpNote>>) | null;
    let getExpNotes: Promise<HistoricalExpNote[]> | null;
    //Ottengo tramite API l'elenco delle note spese storiche del db
    fetchFunction = getAllHistoricalExpNotes;

    if (fetchFunction) {
      getExpNotes = fetchExpNotes(fetchFunction);
      setLoading(true);
      getExpNotes
        .then(res => {
          setAllExpNotes(res)
        })
        .catch(error => convertError(error).then(msg => setErrorMsg(msg)))
        .finally(() => setLoading(false));
    } else {
      setAllExpNotes([]);
    }
  }

  //Chiama la api che inserisce su db le note spese lette da csv
  const loadCsvExpNotes = ()  => {
    //Carico i dati dal csv
    getCsvExpNotes()
      .then(response => {
        if(response){
            //poi re-inizializzo la lista delle note spese
            loadExpenseNotes();
        }
      })
      .catch(error => convertError(error).then(msg => setErrorMsg(msg)))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    setExpNotes(allExpNotes);
  }, [allExpNotes]);


  const getExpNoteCounter = (): number => {
    return allExpNotes ? allExpNotes.length : 0;
  }


  const getFilterName = (filterType: ExpNoteListFilterType): string => {
    switch (filterType) {
      case ExpNoteListFilterType.SEARCH_TEXT:
        return 'searchText';
      case ExpNoteListFilterType.START_DATE:
        return 'startDate';
    }
    return '';
  }

  const getFilterValues = (filter: ExpNoteListFilter): any[] | null => {
    switch (filter.type) {
      case ExpNoteListFilterType.SEARCH_TEXT:
        return filter.values;
      case ExpNoteListFilterType.START_DATE:
        if (filter.values) {
          return filter.values.map(v => {
            if (!v) {
              return null;
            }
            try {
              if (v.isValid()) {
                return v.format(DateFormat);
              }
            } catch (error) {
              // quando riapro la lista e imposto il filtro salvato in redux
              // la data è già formattata come stringa quindi non è disponibile
              // la funzione isValid() nè la funzione format() ma posso assumere
              // che il valore sia valido proprio perchè era già stato formattato
              // in precedenza
              return v;
            }
            return null;
          });
        }
        return null;
    }
    return null;
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    updateTravellerPagination({currentPage: page});
  }

  const isFilterChanged = (prevFilter: HistoricalExpNoteFilter, newFilter: HistoricalExpNoteFilter): boolean => {
    //console.log(prevFilter, newFilter)

    let changed = false;
    Object.values(ExpNoteListFilterType).forEach(ft => {
      // @ts-ignore
      const filterName = getFilterName(ft);
      let prevValue = prevFilter[filterName];
      if (!prevValue) {
        if (ft === ExpNoteListFilterType.START_DATE) {
          prevValue = [null, null];
        } else {
          prevValue = [''];
        }
      }

      let newValue = newFilter[filterName];
      if (!newValue) {
        if (ft === ExpNoteListFilterType.START_DATE) {
          newValue = [null, null];
        } else {
          newValue = [''];
        }
      }

      if (ft === ExpNoteListFilterType.START_DATE) {
        if (prevValue[0] !== newValue[0] || prevValue[1] !== newValue[1]) {
          changed = true;
        }
      } else {
        if (prevValue[0] !== newValue[0]) {
          changed = true;
        }
      }
    });
    return changed;
  }

  const handleFilterChange = (filters: ExpNoteListFilter[]) => {
    let newExpNotes = allExpNotes;
    if (newExpNotes && newExpNotes.length > 0 && filters) {
      const persistedFilter: ExpNoteListTravellerFilter = {};
      filters
        .filter(f => f.enabled && f.values)
        .forEach(filter => {
          persistedFilter[filter.type] = filter.values;
          switch (filter.type) {
            case ExpNoteListFilterType.SEARCH_TEXT:
              const filterValue = filter.values[0].toUpperCase();
              newExpNotes = newExpNotes.filter(e =>
                e.description.toUpperCase().indexOf(filterValue) !== -1
                || e.code.toUpperCase().indexOf(filterValue) !== -1);
              break;
            case ExpNoteListFilterType.START_DATE:
              newExpNotes = newExpNotes.filter(e => {
                if (!filter.values) {
                  return true;
                }
                const startDate = filter.values[0];
                const endDate = filter.values[1];

                return (!startDate || !startDate.isValid() || startDate.isSameOrBefore(e.startDate, 'day')) &&
                  (!endDate || !endDate.isValid() || endDate.isSameOrAfter(e.endDate, 'day'))
              });
              break;
          }
        });

      updateTravellerFilter(persistedFilter);
    }
    setExpNotes(newExpNotes);

  }

  const getFilterElement = (): JSX.Element | null => {
    if (loading) {
      return <Box mt={2} width={"300px"} height={"40px"}><Skeleton variant={"rectangular"} height={"100%"}/></Box>;
    }

    return (
      <Stack>
        <HistoricalExpNoteFilters
          onFilterChange={handleFilterChange}
        />
        <Button
          id={'LOAD_DATA_FROM_CSV'}
          onClick={(e) => {loadCsvExpNotes();  }}>
          LOAD DATA FROM CSV
        </Button>

      </Stack>
    );
  }

  const selectRow = (id) => {
    //Chiedo il nome del file zip da scaricare in base al id nota spese
    getFileZipName(id)
      .then(response => {
        const fileZipName = response;

        //provo a scaricare il file zip
        if (id && fileZipName) {
          downloadZipFile(id)
            .then(resp => {
              const url = URL.createObjectURL(resp);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileZipName);
              document.body.appendChild(link);
              link.click();
              URL.revokeObjectURL(url);
            })
            .catch((error) => {
              convertError(error as Error).then(res => setErrorMsg(res));
            })
        }
      })
  }


  /*const functionDownload = async (fileZipData) => {
    if (fileZipData.id) {
      try {
        const response = await downloadZipFile(fileZipData.id);
        const url = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileZipData.fileZipName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        console.log("entra qui?");
        convertError(error as Error).then(res => setErrorMsg(res));
      } finally {
        setFileZipData(InitFileZipData);
      }
    }
  }*/


  const getListElement = (): JSX.Element | null => {
    if (loading) {
      return <Box mt={2} width={"100%"} height={"100px"}><Skeleton variant={"rectangular"} height={"100%"}/></Box>;
    }

    return (
      <>
        <HistoricalExpNoteBaseList
          ctaLabel={t("list.download")}
          expNotes={expNotes}
          rowClickHandler={selectRow}
          emptyState={t('list.emptyState')}
        />
      </>
    )
  }


  return (
    <>
      <Box
        width={"100%"}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        mx={"auto"}
      >

        {errorMsg && <Alert
          severity={"error"}
          variant={"filled"}
          sx={{mb: 4}}
          onClose={() => setErrorMsg(null)}>{errorMsg}</Alert>}


        {/* Titolo */}
        <Box flexGrow={1}>
          <PageTitle
            title={
              <Typography variant={"h3"} mr={3}>{t("list.title")}</Typography>
            }
          />
        </Box>

        {/*Liste*/}
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            mt: 2,
            px: 3.5,
            py: 2.5,
            flexGrow: 1,
            height: "100%"
          }}
        >

          {/* Filtri */}
          <Box mt={3}>
            {getFilterElement()}
          </Box>

          {/*Lista*/}
          {getListElement()}

        </Paper>

      </Box>
    </>
  );
}
