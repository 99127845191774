import React, {ChangeEventHandler} from "react";
import {InputAdornment, TextField} from "@mui/material";
import {Search} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  filterText: string,
  onFilterChange: ChangeEventHandler,
  placeHolder?: string,
  extraClassName?: string,
}

export const SearchBar = ({filterText, onFilterChange, placeHolder, extraClassName}: ComponentProps) => {
  const {t: tCommon} = useTranslation('common');

  return (

    <TextField
      variant={"outlined"}
      size={"small"}
      placeholder={placeHolder ? placeHolder : tCommon('list.search')}
      value={filterText}
      className={`text-field-bg-white ${extraClassName ? extraClassName : ""}`}
      onChange={onFilterChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search fontSize={"small"} />
          </InputAdornment>
        ),
      }}
    />
  );
}
