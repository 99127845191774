import {Box, Link, Stack, Typography} from "@mui/material";
import {Clear, Help} from "@mui/icons-material";
import React from "react";
import {useTranslation} from "react-i18next";
import {saveOnboardingDismiss} from "../../../model";
import {setHelpOpen} from "../../../../../reducers/Help";
import {useDispatch} from "react-redux";
import {useAlert} from "../../../../../hooks/useAlert";

type ComponentProps = {
  dismissHandler: () => void,
}

export const DashboardOnboardingCommon = ({dismissHandler}: ComponentProps) => {

  const {t} = useTranslation('dashboard', {keyPrefix: 'admin.onboarding.common'});

  const {
    alert
  } = useAlert();

  const dispatch = useDispatch();

  const handleShowProcedure = () => {
    dispatch(setHelpOpen(true));
  }

  const handleDismiss = () => {
    alert({
      variant: "delete",
      onClick: () => {
        dismissHandler();
        saveOnboardingDismiss();
      },
      title: t("dismissTitle"),
      body: (
        <>
          <Typography variant="body1" display="inline" mr={0.5}>
            {t("dismissWarning")}
          </Typography>
          <Help sx={{verticalAlign: "bottom"}}/>
        </>
      ),
      dismissable: true
    });
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      position="relative"
    >
      <Stack
        height="100%"
        rowGap={2}
        justifyContent="center"
      >
        <Typography variant="h4">{t("message1")}</Typography>
        <Typography variant="h4">{t("message2")}</Typography>
        <Typography variant="h4">
          {t("message3")} <Link onClick={handleShowProcedure} sx={{cursor: "pointer"}}>{t("message4")}</Link>
        </Typography>
      </Stack>
      <Box
        className="dismiss-button"
        onClick={handleDismiss}
      >
        <Clear fontSize="small"/>
      </Box>
    </Box>
  );
}
