import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { getCalendarWeekDaysShort, Locale } from './LocalizationUtil';

// Personalizzazione per evitare che la stringa venga lasciata così com'è
// e non venga formattata prendendo solo il primo carattere e tutto in maiuscolo
// Es.: se l'etichetta è lu senza questa classe diventa L
class CustomString extends String {
  charAt(_: number): any {
    return {
      toUpperCase: () => this.valueOf()
    }
  }
}

export class ZtsDateAdapter extends AdapterMoment {

  getWeekdays = (): string[] => {
    return getCalendarWeekDaysShort(this.locale as Locale)
      .map((day) => new CustomString(day) as string);
  }

}
