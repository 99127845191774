import {useDispatch, useSelector} from "react-redux";
import {
  clear,
  deleteSingleExpenseItem,
  getDocumentType,
  getExpenseItems,
  getPayMode,
  isPending,
  setAllExpenseItems,
  setDocumentType,
  setPayMode,
  setPending,
  setSingleExpenseItem
} from "../reducers/ExpenseForm";
import {StaffTravelPolicyExpenseItem} from "../components/users/model";
import {useEffect, useState} from "react";
import {DocumentType, ExpenseItemType, PayMode} from "../components/exp_note/expense/model";

export const useExpenseForm = () => {

  const [payModes, setPayModes] = useState<PayMode[]>([]);
  const [docTypes, setDocTypes] = useState<DocumentType[]>([]);

  const payMode: PayMode | null = useSelector(getPayMode);
  const docType: DocumentType | null = useSelector(getDocumentType);

  const isFormStatePending = useSelector(isPending);

  const expenseItems: StaffTravelPolicyExpenseItem[] | null = useSelector(getExpenseItems);
  const dispatch = useDispatch();

  useEffect(() => {
    computeEnabledDocTypes();
    computeEnabledPayModes();
  }, [expenseItems]);

  const isDocumentTypeEnabled = (expenseItem: StaffTravelPolicyExpenseItem): boolean => {
    if (!docType) {
      return true;
    }

    switch (docType) {
      case DocumentType.INVOICE:
        return expenseItem.docInvoice;
      case DocumentType.RECEIPT:
        return expenseItem.docReceipt;
      case DocumentType.TICKET:
        return expenseItem.docTicket;
    }
    return false;
  }

  const isPayModeEnabled = (expenseItem: StaffTravelPolicyExpenseItem): boolean => {
    if (!payMode) {
      return true;
    }

    switch (payMode) {
      case PayMode.TRAVELLER:
        return expenseItem.payCollab;
      case PayMode.CARD:
        return expenseItem.payCard;
      case PayMode.COMPANY:
        return expenseItem.payCompany;
    }
    return false;
  }

  const filterExpenseItems = (tmpExpItems: StaffTravelPolicyExpenseItem[]) => {
    // Questa funzione filtra le voci spesa utilizzabili in un documento composto da più spese.
    // Le voci disponibili sono quelle che ammettono il giustificativo e la modalità di pagamento
    // che sono state selezionate.

    //console.log('filterExpenseItems', payMode, docType)

    return tmpExpItems.filter(expItem =>
      ExpenseItemType.PIE_DI_LISTA === expItem.expenseType &&
      isDocumentTypeEnabled(expItem) &&
      isPayModeEnabled(expItem));
  }

  const computeEnabledDocTypes = () => {
    // Serve per ricalcolare i tipi giustificativi ammessi al variare delle voci spesa presenti nel documento
    let invoiceEnb = expenseItems && expenseItems.length > 0;
    let receiptEnb = invoiceEnb;
    let ticketEnb = invoiceEnb;

    expenseItems?.forEach(item => {
      if (!item || !item.docInvoice) {
        invoiceEnb = false;
      }
      if (!item || !item.docReceipt) {
        receiptEnb = false;
      }
      if (!item || !item.docTicket) {
        ticketEnb = false;
      }
    });

    const docTypes: DocumentType[] = [];
    if (invoiceEnb) {
      docTypes.push(DocumentType.INVOICE);
    }
    if (receiptEnb) {
      docTypes.push(DocumentType.RECEIPT);
    }
    if (ticketEnb) {
      docTypes.push(DocumentType.TICKET);
    }
    setDocTypes(docTypes);
  }

  const computeEnabledPayModes = () => {
    // Serve per ricalcolare i tipi pagamento ammessi al variare delle voci spesa presenti nel documento

    let collabEnb = expenseItems && expenseItems.length > 0;
    let cardEnb = collabEnb;
    let companyEnb = collabEnb;

    expenseItems?.forEach(item => {
      if (!item || !item.payCollab) {
        collabEnb = false;
      }
      if (!item || !item.payCard) {
        cardEnb = false;
      }
      if (!item || !item.payCompany) {
        companyEnb = false;
      }
    });

    const payModes: PayMode[] = [];
    if (collabEnb) {
      payModes.push(PayMode.TRAVELLER);
    }
    if (cardEnb) {
      payModes.push(PayMode.CARD);
    }
    if (companyEnb) {
      payModes.push(PayMode.COMPANY);
    }
    setPayModes(payModes);
  }

  const updateSingleExpenseItem = (expenseItem: StaffTravelPolicyExpenseItem, index: number) => {
    dispatch(setSingleExpenseItem(expenseItem, index));
  }

  const removeSingleExpenseItem = (index: number) => {
    dispatch(deleteSingleExpenseItem(index));
  }

  const updateExpenseItems = (expenseItems: StaffTravelPolicyExpenseItem[]) => {
    dispatch(setAllExpenseItems(expenseItems));
  }

  const updateFormState = (pending: boolean) => {
    dispatch(setPending(pending));
  }

  const updatePayMode = (payMode: PayMode | null) => {
    dispatch(setPayMode(payMode));
  }

  const updateDocType = (docType: DocumentType | null) => {
    dispatch(setDocumentType(docType));
  }

  const clearFormState = () => {
    dispatch(clear());
  }

  return {
    isFormStatePending,
    docTypes,
    payModes,
    updateExpenseItems,
    updateSingleExpenseItem,
    removeSingleExpenseItem,
    updateDocType,
    updatePayMode,
    filterExpenseItems,
    updateFormState,
    clearFormState
  }

}
