import {useExpNoteDetail} from "../../hooks/useExpNoteDetail";
import React, {ChangeEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import {Currency} from "../../../../model";
import {RadioGroupZts} from "../../../../base/radio-group";
import {Loader} from "../../../../base/loader";
import {ExpNoteCreditCardList} from "./list";
import {ExpNoteCreditCardHeader} from "./header";

type ComponentProps = {
  currencies: Currency[],
}

export const ExpNoteCreditCard = ({
                                    currencies
                                  }: ComponentProps) => {

  const {t} = useTranslation("exp-note", {keyPrefix: "creCardMovs.list"});

  const {
    creditCardMovs
  } = useExpNoteDetail();

  const [groupBy, setGroupBy] = useState<string>('ALL');

  const [loading, setLoading] = useState(false);

  const filteredMovs = creditCardMovs?.filter(mov => groupBy === "ALL" || !mov.docNum);

  const handleGroupByChange = (event: ChangeEvent<HTMLInputElement>) => {
    const groupBy = event.target.value;
    setGroupBy(groupBy);
  }

  return (
    <>
      <Box px={2} py={1}>

        <Loader show={loading}/>

        <ExpNoteCreditCardHeader/>

        <Typography
          variant={"h6"}
          mt={creditCardMovs && creditCardMovs.length > 0 ? 6 : 0}
          mb={2}
        >
          {t("filter.grouping")}
        </Typography>
        <RadioGroupZts
          selectedValue={groupBy}
          options={[
            {
              value: 'ALL',
              label: t('filter.all')
            },
            {
              value: 'NOT_LINKED',
              label: t('filter.notLinked')
            }
          ]}
          handleChange={handleGroupByChange}
          displayOnRow={true}
        />

        <ExpNoteCreditCardList
          rows={filteredMovs}
          currencies={currencies}
          setLoading={setLoading}
        />

      </Box>
    </>
  );
}
