import {Box, Button, Typography} from "@mui/material";
import checkAzzurro from "../../../../../assets/images/check_icon_azzurra.png";
import React from "react";
import {useTranslation} from "react-i18next";

interface ComponentProps {
  triggerImport: () => void
}

export const ImportConfigurerFifthTab = ({triggerImport}: ComponentProps) => {
  const {t} = useTranslation('cre-card-mov', {keyPrefix: 'configurer'});

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      overflow={'hidden'}
      maxHeight={'65vh'}
      px={4}
      py={7}
      rowGap={2}
    >
      <Box display={'flex'} flexDirection={'row'} alignSelf={'center'} alignItems={'center'} columnGap={2}>
        <img src={checkAzzurro} width={'100px'} height={'100px'} alt={'check icon azzurra'}/>
        <Typography variant={'h2'}>{t('tabs.5.labels.1')}</Typography>
      </Box>
      <Button
        variant="contained"
        className={"cta-button-primary"}
        sx={{width: 'fit-content', alignSelf: 'center'}}
        onClick={triggerImport}
      >
        <Typography ml={1} fontSize={'20px'}>{t('tabs.5.labels.2')}</Typography>
      </Button>
    </Box>
  );
}
