export interface ImportConfigurationSeparators {
  csvSeparator?: string | null,
  rowsToSkip: number | null,
  dateFormat: string | null,
  decimalSeparator: string | null
}

export interface CompleteConfiguration {
  separators: ImportConfigurationSeparators,
  positions: ColumnPosition
}

export const enum ColumnDataType {
  DATE = 'DATE',
  CURRENCY = 'CURRENCY',
  EXCHANGE = 'EXCHANGE',
  NONE = 'NONE'
}

export enum CsvSeparators {
  SEMICOLON = ';',
  COMMA = ',',
  TABULATION = '\t',
  PIPE = '|',
  PARAGRAPH = '§'
}

export enum DateFormats {
  ddMMyyyySlash = 'dd/MM/yyyy',
  ddMMyyyyHyphen = 'dd-MM-yyyy',
  ddMMyyyyDot = 'dd.MM.yyyy',
  MMddyyyySlash = 'MM/dd/YYYY',
  MMddyyyyHyphen = 'MM-dd-YYYY',
  MMddyyyyDot = 'MM.dd.YYYY'
}

export enum DecimalSeparators {
  COMMA = ',',
  DOT = '.'
}

export interface ParseFileFilter {
  rowsToSkip?: number,
  decimalSeparator?: string,
  dateFormat?: string,
  csvSeparator?: string
}

export interface ColumnPosition {
  creCardNumColumn: { index?: number, isValid?: boolean, dataType: ColumnDataType },
  payDateColumn: { index?: number, isValid?: boolean, dataType: ColumnDataType },
  compAmountColumn: { index?: number, isValid?: boolean, dataType: ColumnDataType },
  currencyColumn: { index?: number, isValid?: boolean, dataType: ColumnDataType },
  currAmountColumn: { index?: number, isValid?: boolean, dataType: ColumnDataType },
  exchangeColumn: { index?: number, isValid?: boolean, dataType: ColumnDataType },
  creCardMovReferenceColumn: { index?: number, isValid?: boolean, dataType: ColumnDataType },
  creCardMovDescColumn: { index?: number, isValid?: boolean, dataType: ColumnDataType },
  catMercColumn: { index?: number, isValid?: boolean, dataType: ColumnDataType }
}

export interface ParseAndFilterUploadedFileRequest {
  uploadKey: string,
  fileType: string,
  filter: ParseFileFilter
}

export interface ValidateColumPos {
  creCardNumColumn: number | null,
  payDateColumn: number | null,
  compAmountColumn: number | null,
  currencyColumn: number | null,
  currAmountColumn: number | null,
  exchangeColumn: number | null,
  creCardMovReferenceColumn: number | null,
  creCardMovDescColumn: number | null,
  catMercColumn: number | null
}

export interface ColumPosValidRes {
  creCardNumColumn: boolean,
  payDateColumn: boolean,
  compAmountColumn: boolean,
  currencyColumn: boolean,
  currAmountColumn: boolean,
  exchangeColumn: boolean,
  creCardMovReferenceColumn: boolean,
  creCardMovDescColumn: boolean,
  catMercColumn: boolean
}

export interface ValidateColumnRequest {
  uploadKey: string,
  fileType: string,
  filter: ParseFileFilter,
  columnsPosition: ValidateColumPos
}

export interface ValidateColumnResp {
  rows: string[][],
  validation: ColumPosValidRes
}

export interface SaveImportConfigRequest {
  fileType: string,
  rowsToSkip: number,
  dateFormat: string,
  decimalSep: string,
  csvSep?: string,
  posCreCardNum: number,
  posPayDate: number,
  posCompAmount: number,
  posCurrency: number,
  posCurrAmount: number,
  posExchange: number,
  posReference: number,
  posDescription: number,
  posCatMerc: number
}

export interface SaveImportConfigResponse {
  success: boolean
}

export interface ImportConfigDto {
  id: number,
  fileType: string,
  rowsToSkip: number,
  dateFormat: string,
  decimalSep: string,
  csvSep: string,
  posCreCardNum: number,
  posPayDate: number,
  posCompAmount: number,
  posCurrency: number,
  posCurrAmount: number,
  posExchange: number,
  posReference: number,
  posDescription: number,
  posCatMerc: number
}

export interface ImportCreCardMovRequest {
  fileName: string,
  uploadKey: string,
  fileType: string,
  filter: ParseFileFilter,
  locale: string
}

export interface LinkPostImportCreCardMovRequest {
  completedBatchJobId: number,
  locale: string
}

export const isSkipRowsSetByFilter = (filter?: ParseFileFilter) => filter && isSkipRowsSet(filter.rowsToSkip);

export const isSkipRowsSet = (rowsToSkip?: number): boolean => rowsToSkip !== undefined && rowsToSkip > 0;

export const computeRowIndex = (index: number, isSkipRows?: boolean): string => {
  // se ci sono righe da saltare il BE mi restituisce sempre
  // una riga in più (ipotizzando sia la testata) quindi per
  // impostare l'indice sottraggo 1
  if (isSkipRows) {
    return (index -1).toString();
  } else {
    return index.toString();
  }
}
