// React
import React from "react";
import {Route, Routes} from "react-router-dom";

// Zucchetti
import {LoginForm} from "./LoginForm";
import {LoginInfo} from "./LoginInfo";


export const Login = () => {
  return (
    <Routes>
      <Route exact path="/" element={<LoginForm/>}/>
      <Route exact path="/info" element={<LoginInfo/>}/>
    </Routes>
  );
}

export default Login;
