// React
import React, {lazy, Suspense, useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate,} from "react-router-dom";
import {useSelector} from "react-redux";

// MUI
import {Alert} from "@mui/material";

// Libs
import axios from "axios";
import {Logger} from 'react-logger-lib';
import SimpleJsLog from "../util/Logger";

// Pages
import {Dashboard} from "./dashboard";
import {Users} from "./users";
import {Level} from "./levels";
import {AppLoading} from "../AppLoading";
import {getErrorState} from "../reducers/ErrorHandler";
import {useErrorHandler} from "../util/ErrorUtil";
import {Demo} from "./demo";
import {HelpCurrentPage} from "../reducers/Help";
import {useHelp} from "./help/hook";
import {getUserByToken, getZtsJwtPayload} from "../config/token";
import {ExpNoteAdmin} from "./exp_note/admin";
import {ExpNoteTraveller} from "./exp_note/traveller";
import {Accounts} from "./accounts";
import {Settings} from "./settings";
import {Projects} from "./projects";
import {Report} from "./report";

// models
import {
  ACCOUNT_URL,
  ACTIVITIES_LIST_URL,
  ALLEXPNOTE_URL,
  APPROVEEXPNOTE_URL, CRE_CARD_MOV_URL,
  DASHBOARD_PATH,
  EXPNOTE_URL,
  HISTORICAL_EXPNOTE_URL,
  HOME_ADMIN_URL,
  HOME_TRAVELLER_URL,
  LEVEL_URL,
  MOBILE_PAGE_URL,
  PROFILE_URL,
  PROJECT_URL,
  REACT_TRAVEL_POLICIES_BASE_URL,
  REPORT_URL,
  SETTINGS_URL,
  SUPPLIER_URL,
  USER_URL
} from "./model";
import {UserWithTenant} from "./users/model";
import {useLoggedUser} from "../hooks/useLoggedUser";
import {ExpNoteApprover} from "./exp_note/approver";
import {Profile} from "./profile";
import {Supplier} from "./suppliers";
import TravelPolicies from "./travel_policies";
import {useLicense} from "../hooks/useLicense";
import {HistoricalExpNoteList} from "./exp_note/admin/list_historical_archive";
import Login from "./login";
import {useMobileBreakpoint} from "../hooks/useMobileBreakpoint";
import {MobilePage} from "./mobile-page/MobilePage";
import {ActivitiesList} from "./activities_register/list";
import {Impersonate} from "./impersonate/Impersonate";
import {CreditCardMov} from "./credit-card-mov";
import {ProtectedRoute} from "./base/protected-routes";


const LOG: SimpleJsLog = Logger.of('ZTS.AppRoutes');

export const AppRoutes = () => {

  // Lazy

  //const TravelPolicies = lazy(() => import('./travel_policies'));
  // ATTENZIONE: il caricamento lazy sembra non far funzionare il cleanUp effect
  //             in src/components/travel_policies/index.tsx (riga 21) che serve per resettare
  //             la modalità cestino a false quando si esce dalla gestione delle Travel Policy

  // const Login = lazy(() => import('./login'));   // non è lazy per mostrare subito la form di login
  const RegistrationIndex = lazy(() => import('./registration'));
  const PasswordIndex = lazy(() => import('./password'));

  const location = useLocation();
  const navigate = useNavigate();

  const {setProfessional, clearProfessional} = useLicense();

  const {
    setUser,
    user
  } = useLoggedUser();

  const {
    isMobileBreakpoint
  } = useMobileBreakpoint();

  const isAdmin = user && user.isAdmin;

  const hasCreditCardModule = (): boolean => {
    return !!getZtsJwtPayload()?.mod?.includes("cc");
  }

  useHelp({
    paths: [
      {
        path: USER_URL,
        page: HelpCurrentPage.USER_LIST,
        exactMatch: true
      },
      {
        path: LEVEL_URL,
        page: HelpCurrentPage.LEVEL_LIST,
        exactMatch: true
      },
      {
        path: ACCOUNT_URL,
        page: HelpCurrentPage.ACCOUNT_LIST,
        exactMatch: true
      },
      {
        path: REACT_TRAVEL_POLICIES_BASE_URL,
        page: HelpCurrentPage.TRAVEL_POLICIES_LIST,
        exactMatch: true
      },
      {
        path: SETTINGS_URL,
        page: HelpCurrentPage.SETTINGS,
        exactMatch: true
      },
      {
        path: REPORT_URL,
        page: HelpCurrentPage.REPORT,
        exactMatch: true
      },
      {
        path: CRE_CARD_MOV_URL,
        page: HelpCurrentPage.CRE_CARD_MOV,
        exactMatch: true
      },
    ]
  });

  const [authError, setAuthError] = useState(false);

  const isNoAuthPath =
    location.pathname.startsWith('/login') ||
    location.pathname.startsWith('/registration') ||
    location.pathname.startsWith('/password') ||
    location.pathname.startsWith('/impersonate')
  ;

  // se l'utente non è autenticato forzo una redirect alla pagina di login
  axios.interceptors.response.use(
    resp => {
      return resp;
    },
    err => {
      if (err.response && err.response.status && err.response.status === 401) {
        const url: string = err.request.responseURL;
        let myFlag = true;
        if (url.indexOf('api/help') > 0) {
          myFlag = false;
          console.log('skip help auth error');
        }
        if (url.indexOf(':9590') > 0) {
          myFlag = false;
          console.log('skip login portal error');
        }
        if (myFlag) {
          setAuthError(true);
        }
      }
      return Promise.reject(err);
    }
  );

  const loadUserByToken = async () => {
    LOG.trace('loadUserByToken [async]');
    try {
      const user: UserWithTenant | null = await getUserByToken(null);
      if (user === null) {
        navigate('/login');
      } else {
        setUser(user);
      }
    } catch (e) {
      LOG.error('Error loading user', e);
    }
  }

  useEffect(() => {
    LOG.trace('useEffect []');
    const path = location.pathname;

    let loadUserFlag = true;

    if (path.indexOf('/registration') >= 0) {
      LOG.trace('skip loadUser by jwt. Go to registration');
      loadUserFlag = false;
    }

    if (path.indexOf('/password') >= 0) {
      LOG.trace('skip loadUser by jwt. Go to password recovery or update');
      loadUserFlag = false;
    }

    if (path.indexOf('/welcome') >= 0) {
      // Pagina di benvenuto decide lei cosa fare!
      LOG.trace('skip loadUser by jwt. Go to welcome page');
      loadUserFlag = false;
    }

    if (path.indexOf('/login') >= 0) {
      // Pagina di login
      LOG.trace('skip loadUser by jwt. Go to login page');
      loadUserFlag = false;
    }

    if (path.indexOf('/impersonate')>=0) {
      // Pagina intermedia per accedere nei panni di
      LOG.trace('skip loadUser by jwt. Go to impersonate page');
      loadUserFlag = false;
    }

    if (loadUserFlag) {
      const jwtBody = getZtsJwtPayload();
      if (jwtBody!=null && jwtBody.lic==='pro') {
        setProfessional();
      } else {
        clearProfessional();
      }
      loadUserByToken()
        .then(() => LOG.trace('loadUserByToken end.'));
    }

  }, []); // eslint-disable-line

  useEffect(() => {
    LOG.trace('useEffect [authError, navigate]', authError);
    if (authError) {
      setAuthError(false);
      setUser(null);
      navigate('/login');
    }
  }, [authError, navigate]);

  useEffect(() => {
    if (authError || isNoAuthPath || location.pathname === '/') {
      return;
    }

    if (isMobileBreakpoint &&
      !location.pathname.startsWith(MOBILE_PAGE_URL)) {
      navigate(MOBILE_PAGE_URL, {replace: true});
    }
  }, [isMobileBreakpoint, authError, location, navigate, isNoAuthPath]);

  const errorState = useSelector(getErrorState);

  const {clearError} = useErrorHandler();

  return (
    <>
      {!user && !isNoAuthPath && !location.pathname.startsWith(MOBILE_PAGE_URL) ? (
        <AppLoading/>
      ) : (

        <Suspense fallback={<AppLoading/>}>

          {errorState && errorState.show && <Alert
            severity={"error"}
            variant={"filled"}
            sx={{
              mb: 4
            }}
            onClose={clearError}>{errorState.errorData}</Alert>
          }

          <Routes>
            <Route path="/" element={<Navigate to={isAdmin ? HOME_ADMIN_URL : HOME_TRAVELLER_URL}/>}/>
            <Route path={`/${DASHBOARD_PATH}/*`} element={<Dashboard/>}/>
            <Route path={`${USER_URL}/*`} element={<Users/>}/>
            <Route path={`${LEVEL_URL}/*`} element={<Level/>}/>
            <Route path={`${ACCOUNT_URL}/*`} element={<Accounts/>}/>
            <Route path={`${PROJECT_URL}/*`} element={<Projects/>}/>
            <Route path={`${SUPPLIER_URL}/*`} element={<Supplier/>}/>
            <Route path={`${ALLEXPNOTE_URL}/*`} element={<ExpNoteAdmin/>}/>
            <Route path={`${EXPNOTE_URL}/*`} element={<ExpNoteTraveller/>}/>
            <Route path={`${APPROVEEXPNOTE_URL}/*`} element={<ExpNoteApprover/>}/>
            <Route path="/login/*" element={<Login/>}/>
            <Route path="/registration/*" element={<RegistrationIndex/>}/>
            <Route path="/password/*" element={<PasswordIndex/>}/>
            <Route path="/demo/*" element={<Demo/>}/>
            <Route path='/impersonate' element={<Impersonate/>} />
            <Route path={`${REACT_TRAVEL_POLICIES_BASE_URL}/*`} element={<TravelPolicies/>}/>
            <Route path={`${SETTINGS_URL}/*`} element={<Settings/>}/>
            <Route path={`${REPORT_URL}/*`} element={<Report/>}/>
            <Route path={`${PROFILE_URL}/*`} element={<Profile/>}/>
            <Route path={`${ACTIVITIES_LIST_URL}/*`} element={<ActivitiesList/>}/>
            <Route path={`${HISTORICAL_EXPNOTE_URL}/*`} element={<HistoricalExpNoteList/>}/>
            <Route path={MOBILE_PAGE_URL} element={<MobilePage />}/>
            <Route path={CRE_CARD_MOV_URL} element={<ProtectedRoute customCondition={hasCreditCardModule}><CreditCardMov/></ProtectedRoute> }/>
            <Route
              path="*"
              element={
                <main style={{padding: "1rem"}}>
                  <p>The page requested cannot be found!</p>
                </main>
              }
            />
          </Routes>
        </Suspense>
      )}
    </>
  );
}
