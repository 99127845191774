import {AvailableAmountToReturn, ExpNotePayment, getPaymentCausalDesc, PaymentCausal} from "../../../../model";
import {useExpNoteDetail} from "../../../hooks/useExpNoteDetail";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {NewPaymentFormValues} from "../validation";

// https://mui.com/blog/lab-date-pickers-to-mui-x/
//https://mui.com/x/react-date-pickers/getting-started/
import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../../../../base/autocomplete/model";
import {Currency, DateFormat, ExchangeDecimalNum} from "../../../../../model";
import {getCurrencyExchange} from "../../../../../../util/BaseService";
import {TextFieldZts} from "../../../../../base/text-field";
import {useTranslation} from "react-i18next";
import {AutocompleteZts} from "../../../../../base/autocomplete";
import {DatePickerZts} from "../../../../../base/date-picker";
import moment, {Moment} from "moment/moment";
import {formatNumber, parseNumber} from "../../../../../../util/NumberUtil";
import {createAutocompleteCurrencyOption} from "../../../../expense/model";
import {useLoggedUser} from "../../../../../../hooks/useLoggedUser";
import {getAvailableAmountsToReturn} from "../../../../Service";
import {formatDate} from "../../../../../../util/DateUtil";

type ComponentProps = {
  payment: ExpNotePayment | null,
  readonly?: boolean,
  currencyItems: Currency[] | null
}

export const PaymentEdit = ({payment, readonly, currencyItems}: ComponentProps) => {

  const {t} = useTranslation('exp-note', {keyPrefix: 'payment'});
  const {t: tCommon} = useTranslation("common");
  const {
    expNote
  } = useExpNoteDetail();

  const {userLocale: locale, companyCurrencyCode, companyDecimalNum} = useLoggedUser();

  const formMethods = useFormContext<NewPaymentFormValues>();

  const [causalItems, setCausalItems] = useState<AutocompleteGenericOption[]>([]);

  const [amountChangeTO, setAmountChangeTO] = useState<any>(null);

  const [exchange, setExchange] = useState(payment ? formatNumber(payment.exchange, locale, ExchangeDecimalNum) : '');
  const [convertedExchange, setConvertedExchange] = useState(payment ? formatNumber(payment.convertedExchange, locale, ExchangeDecimalNum) : '');
  const [compAmount, setCompAmount] = useState<number>(payment ? payment.compAmount * (payment.causal === PaymentCausal.ADVANCE ? 1 : -1) : 0);

  const [availableAmounts, setAvailableAmounts] = useState<AvailableAmountToReturn[]>([]);

  const editableAndReadonly = !!payment && readonly;

  const causalItem: AutocompleteGenericOption | null = useWatch({name: 'causal'});
  const currencyItem = useWatch({name: 'currency'});
  const operDate = useWatch({name: 'operDate'});
  const amount = useWatch({name: 'amount'});
  const convertedAmount = useWatch({name: 'convertedAmount'});

  const [disableWatch, setDisableWatch] = useState(!!payment);

  useEffect(() => {
    if (payment && !editableAndReadonly) {
      if (payment.causal === PaymentCausal.ADVANCE) {
        setAvailableAmounts([]);
      } else if (expNote && operDate) {
        getAvailableAmountsToReturn(expNote.id, operDate, payment.id)
          .then(res => {
            setAvailableAmounts(res ? res : []);
            const availableAmountToReturn = res.find(a => a.currency === payment.currencyCode);
            if (availableAmountToReturn) {
              formMethods.setValue('maxAmount', availableAmountToReturn.amount);
            }
          });
      }
    }
  }, [payment]);

  useEffect(() => {
    const items: AutocompleteGenericOption[] = [];
    items.push(createAutocompleteGenericOption(1, PaymentCausal.ADVANCE, getPaymentCausalDesc(t, PaymentCausal.ADVANCE)));
    items.push(createAutocompleteGenericOption(2, PaymentCausal.CHANGE_CURRENCY, getPaymentCausalDesc(t, PaymentCausal.CHANGE_CURRENCY)));
    items.push(createAutocompleteGenericOption(3, PaymentCausal.RETURN_CURRENCY, getPaymentCausalDesc(t, PaymentCausal.RETURN_CURRENCY)));
    setCausalItems(items);
  }, []);

  useEffect(() => {
    if (disableWatch) {
      return;
    }

    if (currencyItem && causalItem &&
      (causalItem.code === PaymentCausal.CHANGE_CURRENCY || causalItem.code === PaymentCausal.RETURN_CURRENCY)) {
      const availableAmountToReturn = availableAmounts.find(a => a.currency === currencyItem.code);
      if (availableAmountToReturn) {
        formMethods.setValue('maxAmount', availableAmountToReturn.amount);
        formMethods.setValue('amount', formatNumber(availableAmountToReturn.amount, locale, currencyItem.decimalNum));
        setExchange(formatNumber(availableAmountToReturn.exchange, locale, ExchangeDecimalNum));
      }
      return;
    }

    if (currencyItem && operDate) {

      if (currencyItem.code === companyCurrencyCode) {
        return;
      }

      let searchDate: Date | null;
      if (operDate instanceof moment) {
        searchDate = (operDate as Moment).isValid() ? (operDate as Moment).format(DateFormat) as unknown as Date : null;
      } else {
        searchDate = moment(operDate).format(DateFormat) as unknown as Date;
      }
      //console.log('searchDate', searchDate)

      if (searchDate) {
        getCurrencyExchange(companyCurrencyCode, currencyItem.code, searchDate)
          .then(res => {
              if (res) {
                setExchange(formatNumber(res.exchangeRate, locale, ExchangeDecimalNum));
              }
            }
          );
      }
    }
  }, [causalItem, currencyItem, operDate]);

  useEffect(() => {
    if (disableWatch) {
      return;
    }

    if (causalItem && causalItem.code === PaymentCausal.CHANGE_CURRENCY) {
      if (convertedAmount && compAmount) {
        const convertedAmountNum = parseNumber(convertedAmount, locale);
        if (convertedAmountNum) {
          const exchange = convertedAmountNum / compAmount;
          setConvertedExchange(formatNumber(exchange, locale, ExchangeDecimalNum));
          return;
        }
      }
    }
    setConvertedExchange('');
  }, [causalItem, convertedAmount, compAmount]);

  useEffect(() => {
    if (disableWatch) {
      return;
    }

    setCompCurrAmount();
  }, [exchange]);

  useEffect(() => {
    if (disableWatch) {
      return;
    }

    if (causalItem) {
      if (causalItem.code === PaymentCausal.ADVANCE) {
        setAvailableAmounts([]);
        if (!currencyItem || !currencyItem.code) {
          const currOpt = getCurrencyOptions().find(c => c.code === companyCurrencyCode);
          if (currOpt) {
            formMethods.setValue("currency", createAutocompleteCurrencyOption(currOpt.id ? currOpt.id : 0, currOpt.code, companyDecimalNum));
          }
        }
      } else if (expNote && operDate) {
        getAvailableAmountsToReturn(expNote.id, operDate, payment?.id)
          .then(res => setAvailableAmounts(res ? res : []));
      }
    }
  }, [causalItem, operDate]);

  useEffect(() => {
    setDisableWatch(false);
  }, []);

  const onAmountChangeHandler = (value: string) => {
    if (amountChangeTO) {
      clearTimeout(amountChangeTO);
    }
    setAmountChangeTO(setTimeout(() => {
      doAfterAmountChanged(value)
    }, 400));
  }

  const doAfterAmountChanged = (value: string) => {
    setCompCurrAmount(value);
  }

  const setCompCurrAmount = (value?: string) => {
    const localAmount = value ? value : amount;
    if (currencyItem && currencyItem.code === companyCurrencyCode) {
      const parsedAmount = parseNumber(localAmount, locale)
      if (parsedAmount) {
        setCompAmount(parsedAmount);
      } else {
        setCompAmount(0);
      }
    } else if (exchange && localAmount) {
      const parsedExchange = parseNumber(exchange, locale);
      const parsedAmount = parseNumber(localAmount, locale);
      if (parsedExchange && parsedAmount) {
        setCompAmount(parsedAmount / parsedExchange);
      } else {
        setCompAmount(0);
      }
    } else {
      setCompAmount(0);
    }
  }

  const getCurrencyOptions = (): AutocompleteGenericOption[] => {
    if (causalItem && causalItem.code === PaymentCausal.ADVANCE) {
      return currencyItems ? currencyItems?.map(c => createAutocompleteCurrencyOption(c.id ? c.id : 0, c.code, c.decimalNum)) : [];
    }

    return availableAmounts.map(a => {
      const currItem = currencyItems?.find(c => c.code === a.currency);
      if (currItem) {
        return createAutocompleteCurrencyOption(currItem.id ? currItem.id : 0, currItem.code, currItem.decimalNum);
      }
      return createAutocompleteCurrencyOption(0, '', 0);
    });
  }

  return (
    <>
      <Grid container spacing={3}>

        <Grid item xs={6}>
          {/*Causale*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              label={t('causal.label')}
              value={formMethods.getValues('causal')?.selectedLabel}
            />
          ) : (
            <Controller
              name='causal'
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <AutocompleteZts
                  id={field.name}
                  label={t('causal.label')}
                  options={causalItems}
                  selectedValue={field.value}
                  setValue={(id, value) => {
                    field.onChange(value);
                  }}
                  codeAndDesc={false}
                  errorMsg={fieldState.error?.message}
                />
              }}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          {/*Data operazione*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              sx={{width: "100%"}}
              label={t('edit.operDate')}
              value={formatDate(payment?.operDate, locale)}
            />
          ) : (
            <Controller
              name={'operDate'}
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <DatePickerZts
                  label={t("edit.operDate")}
                  field={field}
                  errorMsg={fieldState.error?.message}
                  width={"100%"}
                  onChangeHandler={(value: Date) => {
                    field.onChange(value);
                  }}
                  defaultCalendarMonth={expNote ? moment(expNote.startDate) : null}
                />
              }}
            />
          )}
        </Grid>
        <Grid item xs={3}/>

        <Grid item xs={3}>
          {/*Divisa*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              label={tCommon('currency')}
              value={formMethods.getValues('currency')?.selectedLabel}
            />
          ) : (
            <Controller
              name='currency'
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <AutocompleteZts
                  id={field.name}
                  label={tCommon('currency')}
                  options={getCurrencyOptions()}
                  selectedValue={field.value}
                  setValue={(id, value) => {
                    field.onChange(value);
                  }}
                  codeAndDesc={false}
                  matchEqualByCode
                  errorMsg={fieldState.error?.message}
                />
              }}
            />
          )}
        </Grid>

        <Grid item xs={3}>
          {/*Importo*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              align="right"
              label={tCommon('amount')}
              value={formMethods.getValues('amount')}
            />
          ) : (
            <Controller
              name='amount'
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <TextFieldZts
                  label={tCommon('amount')}
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value);
                    onAmountChangeHandler(value);
                  }}
                  errorMsg={fieldState.error?.message}
                  align={'right'}
                  fullWidth
                />
              }}
            />
          )}
        </Grid>

        {(currencyItem?.code !== companyCurrencyCode || causalItem?.code !== PaymentCausal.ADVANCE) && <>
          <Grid item xs={3}>
            {/*Cambio*/}
            <TextFieldZts
              disabled={true}
              label={tCommon('exchange')}
              value={exchange}
              inputProps={{
                style: {textAlign: 'right'}
              }}
            />
          </Grid>

          <Grid item xs={3}>
            {/*Controvalore*/}
            <TextFieldZts
              label={companyCurrencyCode}
              disabled={true}
              value={formatNumber(compAmount, locale, companyDecimalNum)}
              inputProps={{
                style: {textAlign: 'right'}
              }}
            />
          </Grid>
        </>}
      </Grid>

      {causalItem && causalItem.code === PaymentCausal.CHANGE_CURRENCY && (
        <Grid container spacing={3} mt={0}>

          <Grid item xs={3}>
            {/*Divisa convertita*/}
            {editableAndReadonly ? (
              <TextFieldZts
                disabled={true}
                label={t('edit.convertedCurrencyCode')}
                value={formMethods.getValues('convertedCurrency')?.selectedLabel}
              />
            ) : (
              <Controller
                name='convertedCurrency'
                control={formMethods.control}
                render={({field, fieldState}) => {
                  return <AutocompleteZts
                    id={field.name}
                    label={t('edit.convertedCurrencyCode')}
                    options={currencyItems ? currencyItems?.map(c => createAutocompleteCurrencyOption(c.id ? c.id : 0, c.code, c.decimalNum)) : []}
                    selectedValue={field.value}
                    setValue={(id, value) => {
                      field.onChange(value);
                    }}
                    codeAndDesc={false}
                    matchEqualByCode
                    errorMsg={fieldState.error?.message}
                  />
                }}
              />
            )}
          </Grid>

          <Grid item xs={3}>
            {/*Importo convertito*/}
            {editableAndReadonly ? (
              <TextFieldZts
                disabled={true}
                align="right"
                label={t('edit.convertedAmount')}
                value={formMethods.getValues('convertedAmount')}
              />
            ) : (
              <Controller
                name='convertedAmount'
                control={formMethods.control}
                render={({field, fieldState}) => {
                  return <TextFieldZts
                    label={t('edit.convertedAmount')}
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value);
                      //onAmountChangeHandler(value);
                    }}
                    errorMsg={fieldState.error?.message}
                    align={'right'}
                    fullWidth
                  />
                }}
              />
            )}
          </Grid>

          <Grid item xs={3}>
            {/*Cambio*/}
            <TextFieldZts
              disabled={true}
              label={tCommon('exchange')}
              value={convertedExchange}
              inputProps={{
                style: {textAlign: 'right'}
              }}
            />
          </Grid>

          <Grid item xs={3}>
            {/*Controvalore*/}
            <TextFieldZts
              label={companyCurrencyCode}
              disabled={true}
              value={formatNumber(compAmount, locale, companyDecimalNum)}
              inputProps={{
                style: {textAlign: 'right'}
              }}
            />
          </Grid>

        </Grid>
      )}

    </>
  );
}
