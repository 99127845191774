export interface Project {
  id: number,
  code: string,
  description: string,
  startDate: Date,
  endDate: Date,
  state: string,
  lastUpdateNum: number
}

export interface StaffProjectResponse {
  id: number,
  staffId: number,
  projectId: number,
  projectCode: string,
  projectDesc: string,
  startDate: Date,
  endDate: Date
}

export interface StaffByProjectIdResponse {
  //ID dello staff
  id: number,
  tenantId: number,
  firstName: string,
  lastName: string
}

export interface SaveProject {
  id: number | null,
  code: string,
  description: string,
  startDate: Date,
  endDate: Date,
  state: string,
  lastUpdateNum: number
}

export const getProjectCodeAndDescription = (project: Project | null): string => {
  return project ? `${project.code} ${project.description}` : '';
}


export enum ListFilterType {
  SEARCH_TEXT,
  ENABLED
}

export interface ListFilter {
  type: ListFilterType,
  enabled: boolean,
  label: string,
  value: any,
  hidden?: boolean
}

export const ListFilters: ListFilter[] = [
  {
    type: ListFilterType.SEARCH_TEXT,
    label: '',
    enabled: true,
    hidden: true,
    value: ''
  },
  // {
  //   type: ListFilterType.ENABLED,
  //   label: 'Attivi',
  //   enabled: false,
  //   value: null
  // },
]



