import React, {ReactChild} from "react";
import {Typography} from "@mui/material";
import '../base.scss';
import Box from "@mui/material/Box";
import {UserItemType} from "../model";
import {PremiumIcon} from "../../base/premium-icon";
import {useLicense} from "../../../hooks/useLicense";

type ComponentProps = {
  onItemClick: Function,
  icon: ReactChild,
  title: string,
  disabled?: boolean,
  type: UserItemType
}

export const UserItem = ({onItemClick, icon, title, disabled, type}: ComponentProps) => {

  const getClassNameByType = (): string => {
    let className = '';
    switch (type) {
      case UserItemType.TRAVEL_POLICY:
        className = 'travel-policy-user-item';
        break;
      case UserItemType.PROJECT:
        className = 'project-user-item';
        break;
      case UserItemType.TARIF:
        className = 'tarif-user-item';
        break;
      case UserItemType.CARD:
        className = 'credit-card-user-item';
        break;
    }

    if (disabled) {
      className += ' user-item-disabled';
    }

    return className;
  }

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const isPremiumFeature = () => {
    return type === UserItemType.PROJECT || type === UserItemType.CARD;
  }

  return (
    <div
      onClick={(_) => disabled ? {} : onItemClick()}
      className={getClassNameByType()}
    >
      <Box mx={2}>
        {icon}
      </Box>
      <Typography variant="h5" flexGrow={1}>{title}</Typography>
      {isPremiumFeature() && free && <PremiumIcon/>}
    </div>
  );
}
