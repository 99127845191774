import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {SelectOption} from "./model";

type ComponentProps = {
  id?: string,
  label: string,
  width: string | number,
  disabled?: boolean,
  placeholder?: string,
  items: SelectOption[],
  selectedValue: any,
  setValue: Function,
  errorMsg?: string,
  disableHelperText?: boolean,
  required?: boolean,
  simplifiedLayout?: boolean
}

export const SelectZts = ({
                            id,
                            label,
                            width,
                            disabled,
                            items,
                            selectedValue,
                            setValue,
                            errorMsg,
                            disableHelperText,
                            required,
                            simplifiedLayout
                          }: ComponentProps) => {

  const labelId = `${id}-zts-select-label`;

  return (
    <FormControl
      disabled={disabled}
      variant={simplifiedLayout ? "standard" : "filled"}
      className={"select-form-control"}
      sx={{width: width}}
      error={!!errorMsg}
    >
      {label && <InputLabel required={required} id={labelId}>{label}</InputLabel>}
      <Select
        disableUnderline={simplifiedLayout}
        labelId={labelId}
        id={id}
        value={selectedValue}
        onChange={(e) => setValue(e.target.value)}
      >
        {items.map(item =>
          <MenuItem
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        )}
      </Select>
      {(errorMsg || !disableHelperText) && <FormHelperText>{errorMsg ? errorMsg : ' '}</FormHelperText>}
    </FormControl>
  );
}
