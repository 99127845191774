// React
import React from "react";
import {Controller} from "react-hook-form";
import {FieldPath} from "react-hook-form/dist/types";

// MUI
import { Checkbox, FormControlLabel } from "@mui/material";

// Custom
import {TravelPolicyExpenseFormValues} from "./TravelPolicyExpenseModal";

type TravelPolicyExpenseCheckboxProps = {
  name: FieldPath<TravelPolicyExpenseFormValues>
  label: string
  editable: boolean
}

export const TravelPolicyExpenseCheckbox: React.FC<TravelPolicyExpenseCheckboxProps> = ({
  name,
  label,
  editable
}) => {

  const toBoolean = (value: any): boolean => {
    if (value === undefined || value === null ){
      return false;
    }
    return value;
  }

  return (
    <Controller
      name={name}

      render={ ({field}) => <FormControlLabel
        control={
          <Checkbox
            checked={toBoolean(field.value)}
            {...field}
            disabled={!editable}
          />
        }
        label={label}
        labelPlacement='end' />
      }
    />
  );
}
