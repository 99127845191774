export const convertBlobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (reader.result == null) {
        reject('Unable to load file');
      } else {
        resolve(reader.result as string);
      }
    };
    reader.readAsDataURL(blob);
  });
