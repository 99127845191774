import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const TPCostAccountPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>Attraverso questa funzionalit&agrave; &egrave; possibile abilitare la gestione contabile delle note spese e
          gestire gli aspetti legati alla contabilit&agrave; generale. I conti di contabilit&agrave; generale devono
          essere censiti nell&rsquo;anagrafica &ldquo;Conti contabili&rdquo; e successivamente associati ai <strong>dipendenti
            collaboratori</strong>, alle <strong>carte di credito</strong> aziendali e alle <strong>voci di
            spesa</strong>. Attivando la versione Professional di ZTravel Smart &egrave; possibile ottenere un file con
          tutti i dati relativi alle spese di viaggio da importare in qualsiasi ERP aziendale.</p>
      );
    default:
      return (
        <p>Attraverso questa funzionalit&agrave; &egrave; possibile abilitare la gestione contabile delle note spese e
          gestire gli aspetti legati alla contabilit&agrave; generale. I conti di contabilit&agrave; generale devono
          essere censiti nell&rsquo;anagrafica &ldquo;Conti contabili&rdquo; e successivamente associati ai <strong>dipendenti
            collaboratori</strong>, alle <strong>carte di credito</strong> aziendali e alle <strong>voci di
            spesa</strong>. Attivando la versione Professional di ZTravel Smart &egrave; possibile ottenere un file con
          tutti i dati relativi alle spese di viaggio da importare in qualsiasi ERP aziendale.</p>
      );
  }
}
