import React, {SyntheticEvent, useState} from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, IconButton, Popover,
  Stack, TextField,
  Typography, useMediaQuery
} from "@mui/material";
import {FormProvider} from "react-hook-form";
import {ArrowBack, Favorite} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import {PremiumIcon} from "../../base/premium-icon";
import {DatePickerZts} from "../../base/date-picker";
import {Controller, useForm} from "react-hook-form";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {TransitionLeftZts} from "../../base/transition";
import {useTheme} from "@mui/material/styles";
import {defaultReportCardValues, ExpenseReportCardProps, PeriodParam, ReportCardForm} from "../model";
import {TooltipZts} from "../../base/tooltip";
import {TextFieldZts} from "../../base/text-field";
import {addPreference, deletePreference} from "../Service";
import {createError} from "../../../util/ErrorUtil";
import {useCurrentJobs} from "../../../hooks/useCurrentJobs";
import {useSnackbar} from "notistack";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {JobType} from "../../../reducers/CurrentJobs";
import {useLicense} from "../../../hooks/useLicense";
import {useTranslation} from "react-i18next";
import {MultipleSelectionsAutocompleteZts} from "../../base/autocomplete/multiple-selections";

export function ExpenseReportCard({cardLabel, desc, expCat, isPro, reportId}: ExpenseReportCardProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [customReport, setCustomReport] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<{ id: number, code: string, label: string }[]>([]);

  const {t: tParams} = useTranslation('report', {keyPrefix: 'parameter'});
  const periodOptions: AutocompleteGenericOption[] = Object.values(PeriodParam).map((value, index) => {
    return createAutocompleteGenericOption(index + 1, value, tParams(`period.${value}`));
  });

  const [preference, setPreference] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const {getRunningJobsState, insertToRunJob} = useCurrentJobs();
  const runningJobs = getRunningJobsState();
  const {enqueueSnackbar} = useSnackbar();
  const {userLocale} = useLoggedUser();
  const {isProfessional} = useLicense();
  const free = !isProfessional();
  const {t} = useTranslation('report');
  const {t: tNotifier} = useTranslation('notifier');

  const formMethods = useForm<ReportCardForm>({
    defaultValues: defaultReportCardValues,
    //resolver: yupResolver(reportValidationSchema),
    mode: "onChange"
  });
  const control = formMethods.control;

  const theme = useTheme();

  const closeModal = () => {
    setOpenModal(false);
    formMethods.reset(defaultReportCardValues);
    setCustomReport(false);
  }

  const handleClickOpenModal = () => {
    if (!isPro) {
      setAnchorEl(null);
      setOpenModal(true);
    } else {
      if (!free) {
        setAnchorEl(null);
        setOpenModal(true);
      }
    }
  }

  const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const setNewPeriod = (value: AutocompleteGenericOption | null) => {
    const now = new Date(Date.now());
    formMethods.reset();
    if (value) {
      switch (value.code) {
        case PeriodParam.DA_INIZIO_ANNO:
          setCustomReport(false);
          setSelectedOption(value.code);
          formMethods.reset();
          const year = now.getFullYear();
          const startDate1 = new Date(0).setFullYear(year);
          formMethods.setValue("startDate", new Date(startDate1));
          formMethods.setValue("endDate", now);
          return;
        case PeriodParam.ULTIMI_6_MESI:
          setCustomReport(false);
          setSelectedOption(value.code);
          formMethods.reset();
          const startDate2 = new Date(now);
          startDate2.setMonth(now.getMonth() - 6);
          startDate2.setDate(1);
          formMethods.setValue("startDate", new Date(startDate2));
          formMethods.setValue("endDate", now);
          return;
        case PeriodParam.ULTIMO_ANNO:
          setCustomReport(false);
          setSelectedOption(value.code);
          formMethods.reset();
          const startDate = new Date(now);
          startDate.setFullYear(startDate.getFullYear() - 1);
          startDate.setDate(1);
          formMethods.setValue("startDate", startDate);
          formMethods.setValue("endDate", now);
          return;
        case PeriodParam.PERSONALIZZATO:
          setCustomReport(true);
          setSelectedOption(value.code);
          formMethods.reset(defaultReportCardValues);
          return;
      }
    }
  }

  const handleNewInputChange = (e: SyntheticEvent<Element, Event>, value: AutocompleteGenericOption | null) => {
    setNewPeriod(value);
  }

  const buildStateFilterString = (states: { id: number, code: string, label: string }[]): string[] => {
    let result: string[] = [];
    if (states.length > 0) {
      states.forEach((value) => {
        result.push(value.code);
      })
    }
    return result;
  }

  const handleSave = () => {
    if (runningJobs.length >= 3) {
      enqueueSnackbar(tNotifier('maxOpsReached'), {
        variant: "error",
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
    } else {
      insertToRunJob({
        type: JobType.EXPENSE_REPORT,
        id: reportId,
        startDate: formMethods.getValues("startDate"),
        endDate: formMethods.getValues("endDate"),
        parameters: {stateFilter: buildStateFilterString(selectedOptions)},
        locale: userLocale
      });
      closeModal();
    }
  }

  const removePreference = async (): Promise<void> => {
    try {
      await deletePreference({reportName: control._formValues.reportName, reportIdReference: reportId});
      setAnchorEl(null);
      setPreference(false);
    } catch (err: any) {
      setErrorMsg(createError(err));
    }
  }

  const getExpNoteStatesPreferences = (states: { id: number, code: string, label: string }[]): string => {
    let result: string = '';
    if (states.length > 0) {
      result += "[";
      states.forEach((value) => {
        result += `\\"${value.code}\\",`;
      })
      result = result.slice(0, -1);
      result += "]";
    }
    return result;
  }

  const insertPreference = async (): Promise<void> => {
    if (!preference) {
      if (selectedOption) {
        try {
          await addPreference({
            reportName: control._formValues.reportName,
            reportIdReference: reportId,
            parameters: customReport ? `{"period": "${selectedOption}", "startDate": "${new Date(Date.parse(control._formValues.startDate))}", "endDate": "${new Date(Date.parse(control._formValues.endDate))}", "expNoteStates": "${getExpNoteStatesPreferences(selectedOptions)}"}` : `{"period": "${selectedOption}", "expNoteStates": "${getExpNoteStatesPreferences(selectedOptions)}"}`
          });
          setAnchorEl(null);
          setTimeout(() => setPreference(true), 500);
        } catch (err: any) {
          setErrorMsg(createError(err));
        }
      } else {
        enqueueSnackbar('Per poter aggiungere il report ai Preferiti è necessario compilare tutti i campi', {
          variant: 'warning',
          anchorOrigin: {
            horizontal: "left",
            vertical: "top"
          }
        })
      }
    }
  }

  return (
    <Grid item xs={6} xl={4}>
      <Card sx={{
        paddingLeft: '8px',
        minHeight: '170px',
        maxHeight: '170px',
        position: 'relative',
        cursor: (isPro && !free) || (!isPro) ? 'pointer' : 'default'
      }} onClick={() => handleClickOpenModal()}>
        <CardContent sx={{position: 'relative', maxHeight: '100%'}}>
          <Stack
            direction="column"
            overflow={"hidden"}
            spacing={1}
            mt={2}
            maxWidth={"90%"}
          >
            <Typography
              variant={"h5"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              fontWeight={"550"}
            >
              {cardLabel}
            </Typography>
            {
              desc &&
              <Typography textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"}>{desc}</Typography>
            }
          </Stack>
          <Stack direction={"row"} mt={1} columnGap={1.5} maxWidth={"80%"} overflow={"visible"}>
            {
              expCat && expCat.map((category) => {
                return (
                  <Box
                    sx={{backgroundColor: '#e7f9fb', borderRadius: '10px'}}
                  >
                    <Typography
                      variant={"subtitle1"}
                      textOverflow={"ellipsis"}
                      pl={1}
                      pr={1}
                      pt={0.5}
                      pb={0.5}
                    >
                      {category}
                    </Typography>
                  </Box>
                );
              })
            }
          </Stack>
          <Box
            style={{
              top: '5px',
              right: '5px',
              position: 'absolute'
            }}
          >
            {/*<Box*/}
            {/*  onClick={(e) => {*/}
            {/*    handleMenuClick(e)*/}
            {/*  }}*/}
            {/*  textAlign={"center"}*/}
            {/*  mt={1}*/}
            {/*  mr={1}*/}
            {/*  display={"flex"}*/}
            {/*  alignItems={"center"}*/}
            {/*  justifyContent={"center"}*/}
            {/*>*/}
            {/*  <MoreVert cursor={"pointer"}/>*/}
            {/*</Box>*/}
            {
              isPro && free &&
              <PremiumIcon m={1} fontSize={"small"}/>
            }
          </Box>
        </CardContent>
        <Button
          sx={{
            position: 'absolute',
            bottom: '10px',
            right: '10px'
          }}
          variant="contained"
          className="card-open-button"
          disabled={isPro && free}
          onClick={handleClickOpenModal}>
          <Typography variant={"button"}>{t('configureReport')}</Typography>
        </Button>
      </Card>
      {/*<Menu*/}
      {/*  anchorEl={anchorEl}*/}
      {/*  keepMounted*/}
      {/*  open={Boolean(anchorEl)}*/}
      {/*  onClose={handleMenuClose}*/}
      {/*>*/}
      {/*  <MenuItem onClick={handleClickOpenModal}>*/}
      {/*    Modifica*/}
      {/*  </MenuItem>*/}
      {/*</Menu>*/}

      <Dialog
        open={openModal}
        TransitionComponent={TransitionLeftZts}
        fullScreen
        disableEscapeKeyDown={true}
        scroll={"paper"}
        fullWidth={true}
        sx={{
          width: useMediaQuery(theme.breakpoints.down("md")) ? "100%" : "35%",
          left: useMediaQuery(theme.breakpoints.down("md")) ? 0 : "unset"
        }}
      >
        <DialogTitle>
          <Stack
            direction={"row"}
            columnGap={2}
            alignItems={"center"}
            sx={{color: "#000"}}
          >
            <ArrowBack onClick={() => {
              setPreference(false);
              closeModal();
            }}/>
            <Typography variant={"h3"}>{cardLabel}</Typography>
            <Box flexGrow={1}/>
            <TooltipZts title={t('addToPreference')} placement={'bottom'} enterDelay={400}>
              <IconButton onClick={handleMenuClick}>
                <Favorite fontSize={"small"} sx={{color: preference ? "#14c6d5" : 'gray'}}/>
              </IconButton>
            </TooltipZts>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <FormProvider {...formMethods}>
            <form noValidate>
              <Box ml={3} overflow={"hidden"}>
                <Typography>{desc}</Typography>

                <Typography variant={"h6"} textTransform={"uppercase"} mt={3} mb={2}>{t('period')}</Typography>

                <Controller
                  control={control}
                  name="categories"
                  render={
                    () => {
                      return <Autocomplete
                        options={periodOptions}
                        autoHighlight={true}
                        isOptionEqualToValue={(option, value) => option.desc === value.desc}
                        getOptionLabel={(option) => option.desc}
                        onChange={handleNewInputChange}
                        renderInput={(params) => (<TextField {...params} size={"small"}/>)}
                      />
                    }}
                />

                {
                  customReport &&
                  <Stack direction={"row"} spacing={2} mt={2}>
                    <Controller
                      name="startDate"
                      control={control}
                      render={({field, fieldState}) => {
                        return <DatePickerZts
                          required
                          field={field}
                          label={t('startDate')}
                          errorMsg={fieldState.error?.message}
                          onChangeHandler={(value: Date) => {
                            field.onChange(value);
                          }}
                        />
                      }}
                    />
                    <Controller
                      name="endDate"
                      control={control}
                      render={({field, fieldState}) => {
                        return <DatePickerZts
                          field={field}
                          label={t('endDate')}
                          errorMsg={fieldState.error?.message}
                          onChangeHandler={(value: Date) => {
                            field.onChange(value);
                          }}
                        />
                      }}
                    />
                  </Stack>
                }

                <MultipleSelectionsAutocompleteZts setSelectedValues={setSelectedOptions}/>

                <Popover open={Boolean(anchorEl)} keepMounted anchorEl={anchorEl} onClose={handleMenuClose}
                         anchorOrigin={{horizontal: "center", vertical: "bottom"}}>
                  {
                    !preference ? (
                      <Box p={2} minWidth={'300px'} position={'relative'}>
                        <IconButton onClick={handleMenuClose} sx={{position: 'absolute', top: '5px', right: '5px'}}>
                          <CloseIcon fontSize={'small'} sx={{width: '15px', height: '15px'}}/>
                        </IconButton>
                        <Typography ml={1} variant={"h4"}>{t('addToPreference')}</Typography>
                        <Stack flexDirection={'column'} mt={2}>
                          <Controller
                            name="reportName"
                            control={control}
                            render={({field, fieldState}) => {
                              return (
                                <TextFieldZts
                                  {...field}
                                  required
                                  label={"Nome report"}
                                  errorMsg={fieldState.error?.message}
                                />
                              )
                            }}

                          />
                          <Button onClick={insertPreference}>{t('add')}</Button>
                        </Stack>
                      </Box>
                    ) : (
                      <Box display={'flex'} flexDirection={'column'} p={2} alignItems={'center'}>
                        <Typography variant={"h5"}>{t('removeMessage')}</Typography>
                        <Box mt={1}>
                          <Button onClick={removePreference}>{t('yes')}</Button>
                          <Button onClick={handleMenuClose}>{t('no')}</Button>
                        </Box>
                      </Box>
                    )
                  }
                </Popover>
              </Box>
            </form>
          </FormProvider>

        </DialogContent>

        <DialogActions>
          <Stack
            alignSelf={"flex-end"}
            direction={"row"}
            mt={4}
            columnGap={2.2}
          >
            <Button
              variant="contained"
              className={"cta-button-primary"}
              disabled={saving}
              onClick={handleSave}
            >
              <Typography variant={"h6"} sx={{color: "white"}}>{t('runReport')}</Typography>
            </Button>
          </Stack>
        </DialogActions>


      </Dialog>


    </Grid>
  );
}
