// React
import React from "react";

// MUI
import {Box, Skeleton} from "@mui/material";

// Custom
import {TravelPolicy} from "../model";
import {TravelPolicyCardWrapper} from "./TravelPolicyCardWrapper";
import {TravelPolicyCard} from "./TravelPolicyCard";
import {InfiniteScrollZts} from "../../base/infinite-scroll";


type TravelPolicyListProps = {
  items: TravelPolicy[]
  selectedItems: TravelPolicy[]
  loading: boolean
  onOpen: (item: TravelPolicy) => void
  onEdit: (item: TravelPolicy) => void
  onToggleSelect: (item: TravelPolicy) => void
}

export const TravelPolicyList: React.FC<TravelPolicyListProps> = ({items, selectedItems, loading, onOpen, onEdit, onToggleSelect}) => {
  return (
    <>
      {
        loading &&
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          mt={3}
          maxWidth={"1200px"}
        >
          {[1, 2].map(idx => {
            return <TravelPolicyCardWrapper
              key={idx}
              card={
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height="160px"/>
              }
            />
          })}
        </Box>
      }

      {
        !loading &&
        <InfiniteScrollZts<TravelPolicy>
          scrollableTargetId={"infinite-scroll-target"}
          elements={items}
          renderElement={(tp: TravelPolicy) => {
            return <TravelPolicyCardWrapper
              key={tp.id}
              card={
                <TravelPolicyCard
                  travelPolicy={tp}
                  onOpen={onOpen}
                  onEdit={onEdit}
                  onToggleSelect={onToggleSelect}
                  selected={selectedItems.some(i => i.id === tp.id)}
                  selectMode={selectedItems.length > 0}
                />
              }
            />
          }}
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "12px",
            maxWidth: "1900px"
          }}
        />
      }
    </>
  );
}
