import React, {useEffect, useState} from "react";
import {Avatar} from "@mui/material";
import {stringAvatar, User} from "./model";
import {useTheme} from "@mui/material/styles";
import {getUserPicture} from "./Service";
import {downloadTempFile} from "../exp_note/Service";
import {AvatarViewer} from "../base/avatar-viewer";

export enum Size {
  LIST,
  DETAIL
}

type ComponentProps = {
  user: User,
  size: Size,
  edit?: boolean,
  userId?: number,
  pictureId?: string | null,
  tempPicture?: boolean
}

export const UserPicture = ({user, size, pictureId, tempPicture, edit}: ComponentProps) => {

  const [userImage, setUserImage] = useState<Blob | null>(null);

  const theme = useTheme();

  const width = size === Size.LIST ? 50 : 100;
  const height = size === Size.LIST ? 50 : 100;

  useEffect(() => {
    if (edit) {
      if (!pictureId) {
        setUserImage(null);
      } else {
        if (tempPicture) {
          downloadTempFile(pictureId, () => {
          })
            .then(res => setUserImage(res.file));
        } else {
          getUserPicture(user.id, pictureId)
            .then(res => setUserImage(res));
        }
      }
      return;
    } else if (user) {
      if (user.pictureId) {
        getUserPicture(user.id, user.pictureId)
          .then(res => setUserImage(res));
      } else {
        setUserImage(null);
      }
    }
  }, [user, pictureId, tempPicture]);

  return (
    <>
      {userImage ? (
        <Avatar
          alt={user ? user.firstName : ''}
          sx={{
            width: `${width}px`,
            height: `${height}px`
          }}>
          <AvatarViewer
            avatar={userImage}
          />
        </Avatar>
      ) : (
        <Avatar
          {...stringAvatar(user ? user.firstName : '', user ? user.lastName : '')}
          sx={{
            bgcolor: theme.palette.secondary.main,
            width: `${width}px`,
            height: `${height}px`,
            fontSize: size === Size.LIST ? '1.25rem' : '2.25rem'
          }}
        />
      )}
    </>
  );
}
