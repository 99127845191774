import {StaffTravelPolicyExpenseItem} from "../../users/model";
import React, {useEffect} from "react";
import {Grid} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import {TarifDecimalNum} from "../../model";
import {formatNumber, parseNumber} from "../../../util/NumberUtil";
import {CarRoutes} from "./CarRoutes";
import {useTranslation} from "react-i18next";
import {Controller, useFormContext} from "react-hook-form";
import {NewExpenseFormValues} from "./validation";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {CompanyCurrencyAutocompleteOption} from "./model";

import SimpleJsLog from "../../../util/Logger";
import {Logger} from "react-logger-lib";

type ComponentProps = {
  currentExpItem: StaffTravelPolicyExpenseItem | undefined;
  editableAndReadonly?: boolean,
}

const LOG: SimpleJsLog = Logger.of('ZTS.expnote.Tariffa');

export const Tariffa = ({currentExpItem, editableAndReadonly}: ComponentProps) => {

  const {userLocale: locale, companyCurrencyCode, companyDecimalNum: decimalNum} = useLoggedUser();

  const formMethods = useFormContext<NewExpenseFormValues>();

  const {t} = useTranslation('exp-note', {keyPrefix: 'expense.edit'});

  const expItem = formMethods.getValues('expenseItem');

  useEffect(() => {
    LOG.trace('useEffect [formMethods, expItem]');
    const currentCurrency = formMethods.getValues('currency');
    if (currentCurrency===undefined || currentCurrency===null){
      formMethods.setValue('currency', CompanyCurrencyAutocompleteOption(companyCurrencyCode, decimalNum));
    }

    if (!editableAndReadonly && expItem && !formMethods.getValues('tarif')) {
      const tarif = currentExpItem ? currentExpItem.tarif : 0;
      formMethods.setValue('tarif', formatNumber(tarif, locale, TarifDecimalNum));
      setAmount(formMethods.getValues('quantity'));
    }
  }, [formMethods, expItem]);

  const setAmount = (quantity: string) => {
    let value = '';
    if (quantity) {
      const parsedTarif = parseNumber(formMethods.getValues('tarif'), locale);
      const parsedQuantity = parseNumber(quantity, locale);
      if (parsedTarif && parsedQuantity) {
        value = formatNumber(parsedTarif * parsedQuantity, locale, decimalNum);
      }
    }
    formMethods.setValue('amount', value);
  }

  return (
    <>

      {/*Percorsi auto*/}
      {expItem && expItem.routes && <CarRoutes
        onKmChange={setAmount}
        editableAndReadonly={editableAndReadonly}
      />}

      <Grid container columnSpacing={3} mt={2}>
        <Grid item xs={3}>
          {/*Quantità*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              label={expItem ? expItem.measUnit : ''}
              value={formMethods.getValues('quantity')}
              align="right"
            />
          ) : (
            <Controller
              name='quantity'
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <TextFieldZts
                  label={expItem ? expItem.measUnit : ''}
                  errorMsg={fieldState.error?.message}
                  fullWidth
                  align={'right'}
                  disabled={!!expItem && expItem.routes}
                  {...field}
                  onChange={(e) => {
                    const quantity = e.target.value;
                    formMethods.setValue(field.name, quantity);
                    setAmount(quantity);
                  }
                  }
                />
              }}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          {/*Tariffa*/}
          <Controller
            name='tarif'
            control={formMethods.control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                label={t('tarif')}
                {...field}
                errorMsg={fieldState.error?.message}
                disabled={true}
                align={'right'}
                fullWidth
              />
            }}
          />
        </Grid>
        <Grid item xs={3}>
          {/*EUR*/}
          <Controller
            name='amount'
            control={formMethods.control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                label={companyCurrencyCode}
                {...field}
                errorMsg={fieldState.error?.message}
                disabled={true}
                fullWidth
                align="right"
              />
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
