import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

// TODO spostare
// https://mui.com/blog/lab-date-pickers-to-mui-x/
//https://mui.com/x/react-date-pickers/getting-started/

// MUI
import {Alert, Box, Button, IconButton, Paper, Stack, Typography} from "@mui/material";
import {Check, DeleteForever, DoDisturbAlt, Edit, EventBusy, WarningAmber} from "@mui/icons-material";

// Libs
import {Logger} from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// Custom
import {TravelPolicyExpense, TravelPolicyWithExpenses} from "../model";
import {disableTravelPolicies, enableTravelPolicies, getTravelPolicyWithExpensesById} from "../Service";
import {EDIT_URL, REACT_TRAVEL_POLICIES_BASE_URL} from "../../model";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {PageTitle} from "../../layout/page-title";
import {TravelPolicyMainData} from "../commons/TravelPolicyMainData";
import {TravelPolicyOptions} from "../commons/TravelPolicyOptions";
import {TravelPolicyExpenses} from "../commons/TravelPolicyExpenses";
import {
  TravelPolicyExpenseCardActionEvent,
  TravelPolicyExpenseCardActionHandler,
  TravelPolicyExpenseCardActionType
} from "../commons/TravelPolicyExpenseCard";
import {
  TravelPolicyExpenseModal,
  TravelPolicyExpenseModalClosedEvent,
  TravelPolicyExpenseModalClosedHandler
} from "../edit/TravelPolicyExpenseModal";
import {
  getTravelPolicyDateStateCssClass,
  getTravelPolicyDateStateLabel,
  getTravelPolicyDateStateType,
  getTravelPolicyStateCssClass,
  getTravelPolicyStateLabel,
  getTravelPolicyStateType,
  TravelPolicyDateStateType,
  TravelPolicyStateType
} from "../utils";
import {useTranslation} from "react-i18next";
import {TravelPolicyEditSkeleton} from "../commons/TravelPolicyEditSkeleton";
import {useLicense} from "../../../hooks/useLicense";


export const TravelPolicyDetails: React.FC = () => {

  const LOG: Slf4jsLog = Logger.of('ZTS.TravelPolicies.TravelPolicyDetails');

  const {id} = useParams();
  const navigate = useNavigate();

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [travelPolicy, setTravelPolicy] = useState<TravelPolicyWithExpenses | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [selectedTravelPolicyExpense, setSelectedTravelPolicyExpense] = useState<TravelPolicyExpense | null>(null);

  const {convertError} = useErrorMessage();

  const showError = async (e: Error | null) => {
    LOG.trace('showError [async]', e);
    if (e === null) {
      setErrorMsg(null);
    } else {
      const msg = await convertError(e as Error);
      setErrorMsg(msg);
    }
  }

  const backToList = () => {
    LOG.trace('backToList');
    navigate(REACT_TRAVEL_POLICIES_BASE_URL);
  }
  const handleEditClicked = () => {
    if (travelPolicy) {
      navigate(`..${EDIT_URL}/${travelPolicy.id}`);
    }
  }

  const handleDisableClicked = async () => {
    if (travelPolicy) {
      await disableTravelPolicies([travelPolicy.id]);
      backToList();
    }
  }

  const onCardAction: TravelPolicyExpenseCardActionHandler = (event: TravelPolicyExpenseCardActionEvent) => {
    LOG.trace('onCardAction', event);
    const action: TravelPolicyExpenseCardActionType = event.action;
    switch (action) {
      case 'edit':
      case 'delete':
      case 'editExpense':
        setErrorMsg(tDetail('forbiddenUpdateError'));
        break;
      case 'open':
        setSelectedTravelPolicyExpense(event.expense);
        break;
      default:
        throw new Error('Unexpected action: ' + action);
    }
  }

  const onTravelPolicyExpenseModalClose: TravelPolicyExpenseModalClosedHandler = (event: TravelPolicyExpenseModalClosedEvent) => {
    LOG.trace('onTravelPolicyExpenseModalClose', event);
    setSelectedTravelPolicyExpense(null);
    // XXX Disabilitare il salva sulla modale
  }

  const onUndelete = async () => {
    LOG.trace('onUndelete [async]');
    if (travelPolicy) {
      setLoadingData(true);
      const id = travelPolicy.id;
      await enableTravelPolicies([id]);
      navigate(`..${EDIT_URL}/${id}`);
    }
  }


  useEffect(() => {
    LOG.trace('useEffect [id]', id);
    if (id === undefined || id === null) {
      LOG.error('Empty id param');
      return;
    }
    const parsedId = parseInt(id);
    if (isNaN(parsedId)) {
      LOG.error('Invalid id param');
      return;
    }

    const asyncInt = async (travelPolicyId: number): Promise<void> => {
      LOG.trace('asyncInt [async]', travelPolicyId);
      try {
        const tp = await getTravelPolicyWithExpensesById(travelPolicyId);
        setTravelPolicy(tp);
      } catch (e) {
        LOG.error('Error retrieving data', e);
        await showError(e as Error);
      } finally {
        setLoadingData(false);
      }
    }

    asyncInt(parsedId)
      .then(() => LOG.trace('asyncInt end.'));

  }, [id]);

  let stateType: TravelPolicyStateType = 'success';
  let dateStateType: TravelPolicyDateStateType = 'regular';
  let activeFlag: boolean = true;

  if (travelPolicy) {
    stateType = getTravelPolicyStateType(travelPolicy);
    dateStateType = getTravelPolicyDateStateType(travelPolicy);
    activeFlag = travelPolicy.state === 'EF';
  }

  const {t} = useTranslation(['validation']);
  const {t: tDetail} = useTranslation('travel-policy', {keyPrefix: 'detail'});

  return (
    <>
      <Box overflow="auto">
        <Paper elevation={0} style={{paddingTop: '10px', paddingRight: '10px', paddingBottom: '10px'}}>

          {loadingData && <TravelPolicyEditSkeleton/>}

          {!loadingData && travelPolicy &&
            <>
              {errorMsg &&
                <Alert
                  sx={{mx: 2}}
                  severity={"error"}
                  onClose={() => setErrorMsg(null)}
                >{errorMsg}</Alert>
              }

              {/* titolo + pulsanti */}
              <Box padding={"10px 30px 10px 20px"}>
              <PageTitle
                backClickHandler={backToList}
                title={
                  <Stack
                    direction={{xs: "column", sm: "row"}}
                    alignItems={{xs: "flex-start", sm: "center"}}
                    justifyContent={{xs: "center", sm: "flex-start"}}
                    columnGap={1.5}
                    flexGrow={"1"}
                  >
                    <Typography
                      variant={"h3"}
                      mr={3}
                      className={"detail-title-wrapper"}
                    >{travelPolicy.description}</Typography>

                    <Box mt={{xs: 1, sm: 0}} style={{height: '28px'}}
                         className={'status-pill ' + getTravelPolicyStateCssClass(stateType)}
                    >
                      {stateType === 'success' && <Check fontSize={"small"}/>}
                      {stateType !== 'success' && <DoDisturbAlt fontSize={"small"}/>}
                      &nbsp;
                      <Typography variant='h5' textTransform='uppercase'>
                        {getTravelPolicyStateLabel(stateType, t)}
                      </Typography>
                      &nbsp;
                    </Box>

                    {stateType === 'success' && dateStateType !== 'regular' &&
                      <Box mt={{xs: 1, sm: 0}} style={{height: '28px'}}
                           className={'status-pill ' + getTravelPolicyDateStateCssClass(dateStateType)}
                      >
                        {dateStateType === 'expired' && <EventBusy fontSize={"small"}/>}
                        {dateStateType === 'future' && <WarningAmber fontSize={"small"}/>}
                        &nbsp;
                        <Typography variant='h5' textTransform='uppercase'>
                          {getTravelPolicyDateStateLabel(dateStateType, t)}
                        </Typography>
                        &nbsp;
                      </Box>
                    }

                    {!activeFlag &&
                      <Button
                        sx={{ml: 4}}
                        variant="contained"
                        className="restore-button"
                        onClick={onUndelete}>
                        <Typography variant={"button"}>{tDetail('button.restore')}</Typography>
                      </Button>
                    }

                  </Stack>
                }
                actions={
                  <>
                    {activeFlag &&
                      <IconButton
                        color="primary"
                        className={"delete-button"}
                        onClick={handleDisableClicked}
                      >
                        <DeleteForever/>
                      </IconButton>
                    }
                    <IconButton
                      color="primary"
                      className={"edit-button"}
                      sx={{
                        opacity: activeFlag ? 1 : 0.4,
                        cursor: activeFlag ? 'pointer' : 'default',
                        pointerEvents: activeFlag ? 'auto' : 'none'
                      }}
                      onClick={handleEditClicked}
                    >
                      <Edit fontSize={"small"}/>
                    </IconButton>
                  </>
                }
              />
              </Box>

              <Stack rowGap={3} style={{margin: "0 30px 20px"}}>
                <TravelPolicyMainData
                  editable={false}
                  travelPolicy={travelPolicy}
                />
                <TravelPolicyOptions
                  travelPolicy={travelPolicy}
                  hasController={false}
                  updatable={false}
                  proLicense={!free}
                />
              </Stack>

            </>}
        </Paper>

        <Paper elevation={0} style={{marginTop: '15px'}}>
          {!loadingData && travelPolicy &&
            <Box style={{margin: "20px"}}>
              <TravelPolicyExpenses
                editable={false}
                travelPolicy={travelPolicy}
                onCardAction={onCardAction}
              />
            </Box>
          }
        </Paper>
      </Box>

      {
        selectedTravelPolicyExpense && travelPolicy &&
        <TravelPolicyExpenseModal
          travelPolicy={travelPolicy}
          expense={selectedTravelPolicyExpense}
          onClose={onTravelPolicyExpenseModalClose}
          editable={false}
        />
      }
    </>
  );

}
