// React
import React, {useEffect, useState} from "react";

// MUI
import {Box, Stack, Button, Typography, MenuItem, Menu} from "@mui/material";
import {Add} from "@mui/icons-material";

// Libs
import { Logger } from 'react-logger-lib';
import SimpleJsLog from '../../util/Logger';
import {useTranslation} from "react-i18next";



type Props = {
  visible: string[]
  openFilter: (name: string) => void
}

const LOG: SimpleJsLog = Logger.of('ZTS.Filters.CollapsibleFilterGroup');

export const CollapsibleFilterGroup: React.FC<Props> = ({visible, openFilter, children}) => {

  const {t} = useTranslation('filter');
  const [displayArray, setDisplayArray] = useState<Array<any>>([]);
  const [hiddenArray, setHiddenArray] = useState<Array<any>>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    LOG.trace('handleFilterClick', e);
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  // TODO lanciare un errore se i figli non sono corretti !!!
  useEffect(() => {
    LOG.trace('useEffect [visible, children]', visible, children);
    let visibleArray: Array<any> = [];
    let hiddenArray: Array<any> = [];
    if (children && children instanceof Array) {
      let childrenArray = children as Array<any>;

      childrenArray.forEach(o => {
        const visibleFlag = visible.indexOf(o.props.name) >= 0;
        if (visibleFlag) {
          visibleArray.push(o);
        } else {
          hiddenArray.push(o);
        }
      })
    }
    if (children && !( children instanceof Array)) {
      const o: any = children;
      const visibleFlag = visible.indexOf(o.props.name) >= 0;
      if (visibleFlag) {
        visibleArray.push(o);
      } else {
        hiddenArray.push(o);
      }
    }

    let display: Array<any> = [];
    if (visibleArray.length < 2) {
      display = visibleArray.slice(0);
    } else {
      visible.forEach(s => {
        display.push(visibleArray.find(el => el.props.name === s));
      })
    }

    setDisplayArray(display);
    setHiddenArray(hiddenArray);
    },
    [visible, children]);

  return (
    <>
      <Box
        flexGrow={1}
        flexBasis={"auto"}
        overflow={"hidden"}
        mt={1}
        order={{
          xs: 2,
          lg: 1
        }}
      >
        <Stack
          // ml={{
          //   xs: 0,
          //   lg: 2
          // }}
          flexGrow={1}
          direction={"row"}
          alignItems={"center"}
          columnGap={2}
        >

          {
            displayArray.map(value => <React.Fragment key={value.key}>{value}</React.Fragment>)
          }

          {
            hiddenArray.length > 0 &&
            <Button
              disableRipple={true}
              variant="contained"
              className={"filter-button"}
              onClick={(e) => {
                handleFilterClick(e)
              }}>
              <Typography variant={"button"} mr={2}>{t('button.filter')}</Typography>
              <Add fontSize={"small"}/>
            </Button>
          }

        </Stack>
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl !== null }
        onClose={() => setAnchorEl(null)}
      >
        {hiddenArray.map(o => {
          return <MenuItem
            key={o.key}
            onClick={() => {
              openFilter(o.props.name);
              setAnchorEl(null);
            }}
          >
            {o.props.label}
          </MenuItem>
        })}
      </Menu>
    </>
  );

}
