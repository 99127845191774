// React
import React, {SyntheticEvent, useState} from "react";

// MUI
import {Box, Button, Card, CardContent, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {Check, DoDisturbAlt, EventBusy, MoreVert, WarningAmber} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";

// I18N
import {useTranslation} from "react-i18next";

// Custom
import {TravelPolicy} from "../model";
import {
  getTravelPolicyDateStateCssClass,
  getTravelPolicyDateStateLabel,
  getTravelPolicyDateStateType,
  getTravelPolicyStateCssClass,
  getTravelPolicyStateLabel,
  getTravelPolicyStateType,
  TravelPolicyDateStateType,
  TravelPolicyStateType
} from "../utils";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {formatDate} from "../../../util/DateUtil";
import {useCurrentList} from "../../../hooks/useCurrentList";


export type TravelPolicyCardProps = {
  travelPolicy: TravelPolicy
  selectMode: boolean
  selected: boolean
  onOpen: (value: TravelPolicy) => void
  onEdit: (value: TravelPolicy) => void
  onToggleSelect: (value: TravelPolicy) => void
}

export const TravelPolicyCard: React.FC<TravelPolicyCardProps> = ({
                                                                    travelPolicy,
                                                                    selectMode,
                                                                    selected,
                                                                    onOpen,
                                                                    onEdit,
                                                                    onToggleSelect
                                                                  }) => {

  const {t} = useTranslation(['travel-policy']);

  const {userLocale} = useLoggedUser();

  const {binMode} = useCurrentList();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const stateType: TravelPolicyStateType = getTravelPolicyStateType(travelPolicy);
  const dateStateType: TravelPolicyDateStateType = getTravelPolicyDateStateType(travelPolicy);

  let className = "tp-card";
  if (stateType !== 'success') {
    className += " disabled-card";
  }
  if (selected) {
    className += " selected-card";
  }

  const handleMenuClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onSelectItem = (e: SyntheticEvent) => {
    e.preventDefault();
    onToggleSelect(travelPolicy);
    setAnchorEl(null);
  };

  const theme = useTheme();

  {/*@ts-ignore*/}
  const sub1Color = theme.palette.subtitle1.main;
  {/*@ts-ignore*/}
  const dateLabelColor = theme.palette.h6.main;

  //i18n
  const startDateLbl = t('travel-policy.start-date');
  const endDateLbl = t('travel-policy.end-date');
  const openCardLbl = t('travel-policy.open-card');
  const selectCardLbl = t('travel-policy.select-card');
  const unselectCardLbl = t('travel-policy.unselect-card');

  return (
    <>
      <Card
        className={className}
        sx={{
          cursor: selectMode ? "pointer" : "default"
        }}
        onClick={() => selectMode ? onToggleSelect(travelPolicy) : {}}
      >
        <CardContent sx={{position: 'relative'}}>

          <Box pt={1} overflow='hidden'>
            <Typography variant='h5' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
              {travelPolicy.description}
            </Typography>

            {/*
            <Typography variant='subtitle1' style={{color: sub1Color}}>{travelPolicy.code}</Typography>
            */}

            <Stack direction={"row"} spacing={1} mt={1}>

              <Box
                className={'status-pill ' + getTravelPolicyStateCssClass(stateType)}
              >
                {stateType === 'success' && <Check fontSize={"small"}/>}
                {stateType !== 'success' && <DoDisturbAlt fontSize={"small"}/>}
                &nbsp;
                <Typography variant='subtitle2' textTransform='uppercase'>
                  {getTravelPolicyStateLabel(stateType, t)}
                </Typography>
                &nbsp;
              </Box>

              {stateType === 'success' && dateStateType !== 'regular' &&
                <Box mt={1}
                     className={'status-pill ' + getTravelPolicyDateStateCssClass(dateStateType)}
                >
                  {dateStateType === 'expired' && <EventBusy fontSize={"small"}/>}
                  {dateStateType === 'future' && <WarningAmber fontSize={"small"}/>}
                  &nbsp;
                  <Typography variant='subtitle2' textTransform='uppercase'>
                    {getTravelPolicyDateStateLabel(dateStateType, t)}
                  </Typography>
                  &nbsp;
                </Box>
              }
            </Stack>

          </Box>

          <Stack mt={3} direction='row' overflow='hidden' spacing={2} width='100%'>

            <Box>
              <Typography variant='h6' textTransform='uppercase' style={{color: dateLabelColor}}>
                {startDateLbl}
              </Typography>
              <Typography
                mt={0.5}
                variant={"body1"}
                overflow={"hidden"}
                maxHeight={"22px"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
              >
                {formatDate(travelPolicy.startDate, userLocale)}
              </Typography>
            </Box>

            <Box>
              <Typography variant='h6' textTransform='uppercase' style={{color: dateLabelColor}}>
                {endDateLbl}
              </Typography>
              <Typography
                mt={0.5}
                variant={"body1"}
                overflow={"hidden"}
                maxHeight={"22px"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
              >
                {formatDate(travelPolicy.endDate, userLocale)}
              </Typography>
            </Box>

          </Stack>

          {!binMode && <Box
            style={{
              top: '5px',
              right: '5px',
              position: 'absolute'
            }}
          >
            <Box
              onClick={(e) => handleMenuClick(e)}
              textAlign={"center"}
              mt={1}
              mr={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}>
              <MoreVert cursor={"pointer"}/>
            </Box>
          </Box>}

          <Box
            style={{
              bottom: '13px',
              right: '15px',
              position: 'absolute'
            }}
          >
            <Button
              variant="contained"
              className="card-open-button"
              onClick={() => onOpen(travelPolicy)}>
              <Typography variant={"button"}>{openCardLbl}</Typography>
            </Button>
          </Box>

        </CardContent>
      </Card>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl != null}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={(e) => onSelectItem(e)}>
          {selected ? unselectCardLbl : selectCardLbl}
        </MenuItem>
        {/*<MenuItem onClick={() => onEdit(travelPolicy)} >Modifica</MenuItem>*/}
      </Menu>
    </>
  );
}
