import {Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import imgSrc1 from "../../../../assets/images/welcomeContent4Img1.png";

export const WelcomeContent4 = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} p={2}>
      <Stack flexDirection={'row'}>
        <Typography variant={'h3'} textAlign={'left'} mb={3}>Compila la tua prima nota spese</Typography>
        <Box flexGrow={1} />
        <Typography variant={'h5'} fontWeight={500}>04/04</Typography>
      </Stack>
      <Box display={'flex'} width={'100%'} maxHeight={'50vh'} overflow={'hidden'} columnGap={2}>
        <Box display={'flex'} flexGrow={1} maxWidth={'30%'} height={'100%'} mt={1.5}>
          <img src={imgSrc1} alt={'welcomeContent4 Img1'} style={{backgroundSize: 'cover'}}/>
        </Box>
        <Box display={'flex'} flexDirection={'column'} overflow={'auto'} alignItems={'left'} maxWidth={'70%'}>
          <p style={{textAlign: 'left', fontWeight: 500, fontSize: 20}}>
            <strong>Utilizza la procedura di Avvio veloce per cominciare</strong>
          </p>
          <p style={{textAlign: 'left'}}>
            <strong>Dal menù Viaggiatore</strong>:
          </p>
          <ul>
            <li style={{textAlign: 'left'}}>Crea una nota spese,</li>
            <li style={{textAlign: 'left'}}>Aggiungi una spesa,</li>
            <li style={{textAlign: 'left'}}>Invia la tua nota spese al controllo.</li>
          </ul>
          <p style={{textAlign: 'left'}}>
            <strong>Dal menù Amministratore</strong>:
          </p>
          <ul>
            <li style={{textAlign: 'left'}}>Controlla la nota spese,</li>
            <li style={{textAlign: 'left'}}>Crea i rimborsi e scarica l'export per le paghe,</li>
            <li style={{textAlign: 'left'}}>Concludi il flusso e archivia la nota spese.</li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
}
