import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {
  getExpNote,
  getExpNoteCreCardMovs,
  getExpNoteExpenses,
  getExpNotePayments,
  sendExpNoteDeleted
} from "../Service";
import {
  ExpNote,
  ExpNoteCreCardMov,
  ExpNotePayment,
  ExpNoteStateFE,
  ExpNoteWithStaff,
  getExpNoteFEState,
  getExpNoteStateFELabel
} from "../model";
import {PageTitle} from "../../layout/page-title";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {Paper, Stack, Typography} from "@mui/material";
import {DeleteForever, Edit, Print, Rule} from "@mui/icons-material";
import {getTabCounter, getTabLabel, Tab, TabBar} from "../../base/tab-bar";
import {ExpNoteDetailTab, getExpNoteTabLabel, isReadonlyExpNote} from "./model";
import IconButton from "@mui/material/IconButton";
import {ExpNoteDashboard} from "./tabs/dashboard";
import {ExpNoteExpenses} from "./tabs/expenses";
import {useHelp} from "../../help/hook";
import {HelpCurrentPage, setCurrentPage} from "../../../reducers/Help";
import {useExpNoteDetail} from "./hooks/useExpNoteDetail";
import {ExpNotePayments} from "./tabs/payments";
import {getCurrencies} from "../../../util/BaseService";
import {Currency} from "../../model";
import {useTranslation} from "react-i18next";
import {EditExpNote} from "../edit";
import {useExpNoteList} from "../hooks/useExpNoteList";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {useCurrentJobs} from "../../../hooks/useCurrentJobs";
import {JobType} from "../../../reducers/CurrentJobs";
import {useSnackbar} from "notistack";
import {TooltipZts} from "../../base/tooltip";
import {useDispatch} from "react-redux";
import {useConfirm} from "../../../hooks/useConfirm";
import {ExpNoteListRole} from "../../../reducers/ExpNoteList";
import {isCreditCardModuleEnabled} from "../../../config/token";
import {ExpNoteCreditCard} from "./tabs/creditcard";
import {ProtectedRoute} from "../../base/protected-routes";
import {ExpNoteExpenseFull} from "../expense/model";
import {CreCardMovState} from "../../credit-card-mov/model";
import printWithAttach from "../../../assets/images/print_w_attach.png";
import excelIcon from "../../../assets/images/excel_icon_for_button.png";


interface HelpPageTypeProps {
  isAdmin?: boolean
}

export const ExpNoteDetail = ({isAdmin}: HelpPageTypeProps) => {

  const {t} = useTranslation('exp-note');
  const {t: tNotifier} = useTranslation('notifier');
  const {t: tCommon} = useTranslation('common');
  const {t: tDetail} = useTranslation("exp-note", {keyPrefix: "detail"});

  const [currentTab, setCurrentTab] = useState<ExpNoteDetailTab>(ExpNoteDetailTab.DASHBOARD);

  const [editExpNote, setEditExpNote] = useState(false);

  const {enqueueSnackbar} = useSnackbar();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const {
    expNote,
    expenses,
    payments,
    creditCardMovs,
    updateData,
    reloadExpNote,
    updateCreditCardMovs,
    clearExpNote
  } = useExpNoteDetail();

  const {
    currentRole
  } = useExpNoteList();

  const isTraveller = currentRole && currentRole === ExpNoteListRole.TRAVELLER;

  const {id} = useParams();

  const {userLocale} = useLoggedUser();

  const {convertError} = useErrorMessage();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [currencies, setCurrencies] = useState<Currency[]>([]);

  const {confirm} = useConfirm();

  const feState = expNote ? getExpNoteFEState(expNote.state) : null;

  const isCreCardModuleEnabled = isCreditCardModuleEnabled();

  const isCreditCardTabVisible = !!isAdmin && isAdmin && isCreCardModuleEnabled && !!feState;

  useHelp({
    paths: [
      {
        path: '/expenses',
        page: HelpCurrentPage.EXP_NOTE_EXPENSES,
      },
      {
        path: '/payments',
        page: HelpCurrentPage.EXP_NOTE_PAYMENTS,
      },
      {
        path: '/creditcard',
        page: HelpCurrentPage.EXP_NOTE_CRE_CARD_MOV
      },
      {
        path: '/',
        page: isAdmin ? HelpCurrentPage.EXP_NOTE_DASHBOARD_ADMIN : HelpCurrentPage.EXP_NOTE_DASHBOARD_TRAVELLER
      }
    ]
  });

  useEffect(() => {
    getCurrencies().then(res => setCurrencies((res)));
  }, []);

  useEffect(() => {
    if (id) {
      const expNoteId = Number(id);

      const promises: Promise<ExpNoteWithStaff | ExpNoteExpenseFull[] | ExpNotePayment[] | ExpNoteCreCardMov[]>[] = [
        getExpNote(expNoteId),
        getExpNoteExpenses(expNoteId),
        getExpNotePayments(expNoteId)
      ];

      const fetchCreCardMovs = !!isAdmin && isAdmin && isCreCardModuleEnabled;

      if (fetchCreCardMovs) {
        promises.push(getExpNoteCreCardMovs(expNoteId));
      }

      Promise.all(promises)
        .then(res => {
          const tmpExpNote = res[0] as ExpNoteWithStaff;
          const tmpFEState = tmpExpNote ? getExpNoteFEState(tmpExpNote.state) : null;
          const creditCardMovs = fetchCreCardMovs ? res[3] as ExpNoteCreCardMov[] : null;
          updateData(tmpExpNote, res[1] as ExpNoteExpenseFull[], res[2] as ExpNotePayment[], creditCardMovs);
        })
    } else {

    }
  }, [id]);

  useEffect(() => {
    let url = '';
    switch (currentTab) {
      case ExpNoteDetailTab.EXPENSES:
        url = 'expenses';
        break;
      case ExpNoteDetailTab.PAYMENTS:
        url = 'payments';
        break;
      case ExpNoteDetailTab.CREDIT_CARD:
        url = 'creditcard';
        break;
      case ExpNoteDetailTab.DASHBOARD:
        dispatch(setCurrentPage(isAdmin ? HelpCurrentPage.EXP_NOTE_DASHBOARD_ADMIN : HelpCurrentPage.EXP_NOTE_DASHBOARD_TRAVELLER));
        break;
      // case ExpNoteDetailTab.ACCOUNTING:
      //   url = 'accounting';
      //   break;
      // case ExpNoteDetailTab.HISTORY:
      //   url = 'history';
      //   break;
    }
    navigate(url);
  }, [currentTab]);

  const backToList = () => {
    clearExpNote();
    navigate('..');
  }

  const getExpNoteTabCounter = (tab: ExpNoteDetailTab): number => {
    switch (tab) {
      case ExpNoteDetailTab.EXPENSES:
        return expenses ? expenses.length : 0;
      case ExpNoteDetailTab.PAYMENTS:
        return payments ? payments.length : 0;
      case ExpNoteDetailTab.CREDIT_CARD:
        return creditCardMovs ? creditCardMovs.length : 0;
      // case ExpNoteDetailTab.ACCOUNTING:
      //   return 0;
      // case ExpNoteDetailTab.HISTORY:
      //   return 0;
    }
    return 0;
  }

  const getTabs = (): Tab[] => {
    return Object.values(ExpNoteDetailTab)
      .filter(tab => tab !== ExpNoteDetailTab.CREDIT_CARD || isCreditCardTabVisible)
      .map(tab => ({
        key: tab,
        render: (_) => {
          const count = getExpNoteTabCounter(tab);
          return <>
            {count > 0 && getTabCounter(count)}
            {getTabLabel(getExpNoteTabLabel(tab, t))}
          </>
        }
      }));
  }

  const handleTabSelected = (tab: string) => {
    // @ts-ignore
    setCurrentTab(Object.values(ExpNoteDetailTab).find(e => e === tab));
  }

  const closeEdit = async (res: number | ExpNote | null) => {
    setEditExpNote(false);
    if (res && expNote) {
      // in edit sono sicuro che venga passato un ExpNote, il number si ha in inserimento di una nuova NS
      reloadExpNote({...res as ExpNote, traveller: expNote.traveller});
      if (isCreditCardTabVisible) {
        const creCardMovs = await getExpNoteCreCardMovs(expNote.id);
        updateCreditCardMovs(creCardMovs);
      }
    }
  }

  const isReadonly = isReadonlyExpNote(expNote, currentRole);

  const isDeletable = !isReadonly &&
    feState &&
    (feState === ExpNoteStateFE.DA_COMPLETARE || feState === ExpNoteStateFE.DA_RIVEDERE) &&
    isTraveller;

  const {getRunningJobsState, insertToRunJob} = useCurrentJobs();
  const runningJobs = getRunningJobsState();

  const handlePrintClick = (zipWithAttach?: boolean) => {
    if (runningJobs.length >= 3) {
      enqueueSnackbar(tNotifier('maxOpsReached'), {
        variant: "error",
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
    } else {
      insertToRunJob({
        type: zipWithAttach ? JobType.PRINT_WITH_ATTACH : JobType.PRINT,
        id: id ? parseInt(id) : -1,
        locale: userLocale
      });
    }
  }

  const handleExportClick = () => {
    if (runningJobs.length >= 3) {
      enqueueSnackbar(tNotifier('maxOpsReached'), {
        variant: "error",
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
    } else {
      insertToRunJob({type: JobType.EXP_NOTE_EXPORT, id: id ? parseInt(id) : -1, locale: userLocale});
    }
  }

  const handleDeleteClick = () => {
    if (!expNote) {
      return;
    }

    confirm({
      body: (
        <>
          <Typography variant="body1">{tDetail("deleteWarning")}</Typography>
          <Typography variant="body1">{tCommon("confirmDialog.continueQuestion")}</Typography>
        </>
      ),
      variant: "delete",
      onConfirm: async () => {
        try {
          await sendExpNoteDeleted(expNote.id, {lastUpdateNum: expNote.lastUpdNum});
          doAfterDeleteExpNote(tDetail("stateChange.deleted"));
        } catch (error: any) {
          convertError(error).then(msg => setErrorMsg(msg));
        }
      }
    });
  }

  const doAfterDeleteExpNote = (message: string) => {
    enqueueSnackbar(message,
      {
        variant: "success",
        autoHideDuration: 3500,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
    clearExpNote();
    navigate('/expnotes');
  }

  const handleDisconnectMovement = (docNum?: number) => {
    if (!isCreditCardTabVisible || !creditCardMovs || !docNum) {
      return;
    }

    // se il documento cancellato era collegato ad un movimento
    // il legame è stato rimosso quindi aggiorno il movimento
    // per annullare il docNum
    const movToUpdate = creditCardMovs.find(mov => mov.docNum === docNum);
    if (movToUpdate) {
      const newCreditCardMovs = creditCardMovs.map(mov => mov.docNum === movToUpdate.docNum ? {
        ...mov,
        docNum: 0,
        creCardMovState: CreCardMovState.DISCONNECTED
      } : mov);
      updateCreditCardMovs(newCreditCardMovs);
    }
  }

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          flexGrow: 1,
          height: "100%"
        }}
      >
        {expNote ? (
          <>
            <PageTitle
              title={
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                >
                  {/*Titolo*/}
                  <Typography variant={"h3"} mr={4}>{`#${expNote.code}`}</Typography>
                  {/*Barra dei tab*/}
                  <TabBar
                    selected={currentTab}
                    tabs={getTabs()}
                    onSelect={handleTabSelected}
                    extraClassName="tab-bar-exp-note"
                  />
                </Stack>
              }
              flexTitle
              backClickHandler={backToList}
              actions={
                <>
                  {
                    isDeletable &&
                    <TooltipZts
                      title={tDetail('button.delete')}
                      placement={"bottom"}
                      enterDelay={400}
                    >
                      <IconButton
                        color="primary"
                        className={"delete-button"}
                        onClick={handleDeleteClick}
                      >
                        <DeleteForever/>
                      </IconButton>
                    </TooltipZts>
                  }
                  {
                    !isReadonly &&
                    <TooltipZts
                      title={t('detail.button.modify')}
                      placement={'bottom'}
                      enterDelay={400}
                    >
                      <IconButton
                        color="primary"
                        className={"edit-button"}
                        onClick={() => setEditExpNote(true)}
                      >
                        <Edit/>
                      </IconButton>
                    </TooltipZts>
                  }
                  <TooltipZts
                    title={t('detail.button.runExport')}
                    placement={'bottom'}
                    enterDelay={400}
                  >
                    <IconButton
                      color="primary"
                      className={"edit-button"}
                      onClick={handleExportClick}
                    >
                      <img
                        src={excelIcon}
                        alt="Icona Excel"
                        style={{
                          width: '22px',
                          height: '22px',
                          marginRight: '2px'
                        }}
                      />
                    </IconButton>
                  </TooltipZts>

                  <TooltipZts
                    title={t('detail.button.startPrint')}
                    placement={'bottom'}
                    enterDelay={400}
                  >
                    <IconButton
                      color="primary"
                      className={"edit-button"}
                      onClick={() => handlePrintClick(false)}
                    >
                      <Print/>
                    </IconButton>
                  </TooltipZts>
                  <TooltipZts
                    title={t('detail.button.startPrintWithAttach')}
                    placement={'bottom'}
                    enterDelay={400}
                  >
                    <IconButton
                      color="primary"
                      className={"edit-button"}
                      onClick={() => handlePrintClick(true)}
                    >
                      <img src={printWithAttach} alt=""/>
                    </IconButton>
                  </TooltipZts>
                </>
              }
            />

            {/*Stato*/}
            {feState && <Box
              ml={{
                xs: 2,
                md: 5
              }}
              // width={'50px'}
              className={'status-pill-exp-note-to-check'}
            >
              <Rule fontSize={"small"}/>
              <Typography
                variant={"subtitle2"}
                textTransform={"uppercase"}
              >{getExpNoteStateFELabel(feState, t, true)}</Typography>
            </Box>}

            <Box
              mt={{
                xs: 13,
                lg: 3
              }}
              flexGrow={1}
              overflow={"auto"}
            >
              <Routes>
                <Route path="/" element={<ExpNoteDashboard/>}/>
                <Route path="/expenses"
                       element={
                         <ExpNoteExpenses
                           currencies={currencies}
                           onDisconnectMovement={(docNum) => handleDisconnectMovement(docNum)}
                         />
                       }
                />
                <Route path="/payments" element={<ExpNotePayments currencies={currencies}/>}/>
                <Route
                  path="/creditcard"
                  element={
                    <ProtectedRoute
                      customCondition={() => isCreditCardTabVisible}>
                      <ExpNoteCreditCard
                        currencies={currencies}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<h2>{tCommon('notFound')}</h2>}/>
              </Routes>
            </Box>

            <Outlet/>

          </>
        ) : (
          // TODO: Skeleton
          <></>
        )}
      </Paper>

      {!isReadonly && expNote && <EditExpNote
        show={editExpNote}
        onClose={closeEdit}
        expNote={expNote}
      />}
    </>
  );
}
