import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {NewExpenseFormValues} from "./validation";
import {SingleAdditionalExpense} from "./SingleAdditionalExpense";
import {ExpenseItemType, ExpNoteExpenseFull} from "./model";
import {StaffTravelPolicyExpenseItem} from "../../users/model";
import {ExpNoteWithStaff} from "../model";
import Box from "@mui/material/Box";
import {Add} from "@mui/icons-material";
import {Typography} from "@mui/material";
import {PremiumIcon} from "../../base/premium-icon";
import {useExpenseForm} from "../../../hooks/useExpenseForm";
import {useTranslation} from "react-i18next";
import {useLicense} from "../../../hooks/useLicense";
import {PopupContentType} from "../../license-popup/model";


type ComponentProps = {
  expNote: ExpNoteWithStaff | null,
  expenses: ExpNoteExpenseFull[] | null,
  currentExpItems: StaffTravelPolicyExpenseItem[],
  loadingExpItem: boolean,
  readonly?: boolean
}

export const AdditionalExpenses = (
  {
    expNote,
    expenses,
    currentExpItems,
    loadingExpItem,
    readonly
  }: ComponentProps
) => {

  const formMethods = useFormContext<NewExpenseFormValues>();

  const {fields, append, replace} = useFieldArray({
    name: "additionalExpenses",
    control: formMethods.control
  });

  const {t} = useTranslation("exp-note", {keyPrefix: "expense.edit"});

  const expItem = formMethods.getValues("expenseItem");
  const validExpense = expItem && ExpenseItemType.PIE_DI_LISTA === expItem.type;

  const isAddExpenseEnabled = !readonly && validExpense;

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const additionalExpenses = useWatch({name: "additionalExpenses", exact: true});

  const {
    removeSingleExpenseItem
  } = useExpenseForm();

  const handleAddNewExpense = () => {
    if (free) {
      return;
    }

    if ((formMethods.formState.errors && formMethods.formState.errors.additionalExpenses) ||
      (additionalExpenses.length > 0 && additionalExpenses.find(f => f.expenseItem == null))) {
      return;
    }

    append({
      expenseItem: null,
      compDate: null,
      compStartDate: null,
      compEndDate: null,
      amount: '',
      colleagues: [],
      guests: []
    });
  }

  const handleDelete = (index: number) => {
    if (additionalExpenses) {
      replace(additionalExpenses.filter((_, idx) => index !== idx));
      removeSingleExpenseItem(index+1);
    }
  }

  return (
    <>

      <Box mt={4}>
        {fields.map((item, index) =>
          <SingleAdditionalExpense
            expNote={expNote}
            editExpense={expenses?.find(e => e.id === item._id)}
            key={item.id}
            index={index}
            readonly={readonly}
            currentExpItems={currentExpItems}
            loadingExpItem={loadingExpItem}
            handleDelete={handleDelete}
          />
        )}
      </Box>

      {isAddExpenseEnabled ? (
        <Box
          onClick={handleAddNewExpense}
          className={`additional-expense-button ${free ? "additional-expense-button-disabled" : ""}`}
        >
          <Add sx={{mr: 2}}/>
          <Typography sx={{flexGrow: 1}} variant={"body1"}>{t("addOtherExpense")}</Typography>
          {free && <PremiumIcon type={PopupContentType.NEW_EXPENSE_PILL} clickable={true} />}
        </Box>
      ) : <></>}

    </>
  );
}
