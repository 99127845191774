export const ADD_ERROR = 'ERROR/ADD_ERROR';
export const REMOVE_ERROR = 'ERROR/REMOVE_ERROR';

// reducer
export default function reducer(
  state = {
    show: false,
    data: null
  },
  action
) {
  switch (action.type) {
    case ADD_ERROR:
      return {
        ...state,
        show: true,
        errorData: action.errorData
      };
    case REMOVE_ERROR:
      return {
        ...state,
        show: false,
        errorData: null
      };
    default:
      break;
  }
  return state;
}

// Action creator
export const setErrorState = (show, data) => {
  if (show) {
    return {
      type: ADD_ERROR,
      errorData: data
    }
  } else {
    return {
      type: REMOVE_ERROR
    }
  }
}

export const getErrorState = state => {
  return state.ErrorHandler;
}

export const isErrorToShow = (state) => {
  return state.show;
}
