import {SearchBar} from "../../base/search-bar";
import Box from "@mui/material/Box";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {ListFilter, ListFilters, ListFilterType} from "../model";
import {Menu, MenuItem, Stack} from "@mui/material";
type SupplierFilterProps = {
  onFilterChange: Function,
  handleTrashToggle: Function
}

type ComponentProps = {
  props: SupplierFilterProps
}

export const Filters = ({props}: ComponentProps) => {
  // State
  //------
  const [filters, setFilters] = useState<ListFilter[]>(ListFilters); // State: lista di filtri
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // State: riferimento all'elemento al quale il Menù si andrà ad ancorare


  // Use effects
  //------------
  useEffect(() => {
    props.onFilterChange(filters);
  }, [filters]);

  // Functions
  //----------
  const searchTextChangeHandler = (e: SyntheticEvent) => {
    let target = e.target as HTMLInputElement;

    setFilters((prevFilters: ListFilter[]) =>
      prevFilters.map((filter: ListFilter) => filter.type === ListFilterType.SEARCH_TEXT ? ({
        ...filter,
        value: target.value
      }) : filter));
  }

  const handleFilterClose = () => {
    setAnchorEl(null);
  }

  const showFilter = (type: ListFilterType) => {
    setFilters(prevFilters =>
      prevFilters.map(filter => filter.type === type ? ({
        ...filter,
        enabled: true,
        value: (filter.type === ListFilterType.ENABLED ? '1' : null)
      }) : filter)
    );
    handleFilterClose();
  }

  // React Component
  //----------
  return (
    <Box
      flexGrow={{
        xs: 1,
        lg: 0
      }}
      flexBasis={{
        xs: "calc(100% - 60px)",
        lg: "auto"
      }}
    >
      <SearchBar
        filterText={
          filters
            .filter(filter => filter.type === ListFilterType.SEARCH_TEXT)[0].value
        }
        onFilterChange={searchTextChangeHandler}
      />

    </Box>
    // <>
    //   <Stack flexDirection={"row"}>
    //
    //   </Stack>
    //
    //   <Box
    //     flexGrow={1}
    //     flexBasis={"auto"}
    //     overflow={"hidden"}
    //     mt={{
    //       xs: 1,
    //       lg: 0
    //     }}
    //     order={{
    //       xs: 2,
    //       lg: 1
    //     }}
    //   >
    //   </Box>
    //
    //   <Menu
    //     anchorEl={anchorEl}
    //     keepMounted
    //     open={Boolean(anchorEl)}
    //     onClose={handleFilterClose}
    //   >
    //     {filters
    //       .filter(f => !f.hidden && !f.enabled)
    //       .map((filter) => {
    //         return <MenuItem
    //           key={filter.type}
    //           onClick={() => showFilter(filter.type)}
    //         >
    //           {filter.label}
    //         </MenuItem>
    //       })}
    //   </Menu>
    //
    // </>
  );
}
