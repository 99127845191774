// Dev Tools
import {DevTool} from '@hookform/devtools';

// React
import React, {useEffect, useState} from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";

// YUP
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

// Libs
import {useTranslation} from "react-i18next";
import getSymbolFromCurrency from 'currency-symbol-map'
import {Logger} from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// MUI
import {Box, FormControlLabel, Grid, InputAdornment, Skeleton, Slider, Stack, Typography} from "@mui/material";

// Custom
import {
  CreateTravelPolicyExpenseRequest,
  MaximumColleagueGuestType,
  MaximumType,
  TravelPolicy,
  TravelPolicyExpense,
  TravelPolicyHeader,
  UpdateTravelPolicyExpenseRequest
} from "../model";
import {TextFieldZts} from "../../base/text-field";
import {CommonDialog} from "../../base/common-dialog";
import {HelpCurrentPage} from "../../../reducers/Help";
import {TravelPolicyExpenseCheckbox} from "./TravelPolicyExpenseCheckbox";
import {TravelPolicyExpenseSwitch} from "./TravelPolicyExpenseSwitch";
import {SelectZts} from "../../base/select";
import {SelectOption} from "../../base/select/model";
import {AutocompleteZts} from "../../base/autocomplete";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {getActiveAccounts, NewAccountObject, readAccount} from "../../accounts/Service";
import {NewAccountModal, NewAccountModalClosedEvent} from "../../accounts/commons/NewAccountModal";
import {Account} from "../../accounts/model";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {createTravelPolicyExpense, updateTravelPolicyExpense} from "../Service";
import {ExpenseCategory, ExpenseMeasureUnit, ExpenseType} from "../../expenses/model";
import {getExpenseCategoryLabel, getExpenseTypeLabel} from "../../expenses/utils";
import {yupNullableString, yupRequiredAmount} from "../../../util/YupUtil";
import {ExpenseImg} from "../../expenses/commons/ExpenseImg";
import {PremiumIcon} from "../../base/premium-icon";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {formatNumber, parseNumber} from "../../../util/NumberUtil";
import {useLicense} from "../../../hooks/useLicense";
import {PopupContentType} from "../../license-popup/model";

// i dati minimi che mi servono per creare una nuova voce nella travel policy
// in sintesi mi serve la spesa da cui partire
export type MinTravelPolicyExpense = {
  // Collegamento alla spesa
  expenseId: number,
  expenseCode: string,
  expenseDescription: string,           // pranzo
  expenseIcon: string,                  // panino.svg
  expenseCategory: ExpenseCategory,     // vitto
  expenseType: ExpenseType,             // piè di lista
  measureUnit?: ExpenseMeasureUnit | null,
  compPeriod: boolean,
}

// La form
export type TravelPolicyExpenseFormValues =
  Required<TravelPolicyExpense>
  & Omit<TravelPolicyExpense, 'tarif' | 'maximum'>
  & {
  tariffStr: string;
  maximumStr: string;
  maximumEnb: boolean;
  vatEnb: boolean;
  contableAccount: AutocompleteGenericOption | null;
};

const defaultExpValues: Required<TravelPolicyExpense> = {
  id: -1,

  // Collegamento alla spesa
  expenseId: -1,
  expenseCode: '',
  expenseDescription: '',
  expenseIcon: '',
  expenseCategory: 'VT',
  expenseType: 'PL',
  measureUnit: null,
  compPeriod: false,
  compPeriodStartLabel: '',
  compPeriodEndLabel: '',

  // Modalità di pagamanto
  payCollab: true,
  payCard: false,
  payCompany: false,

  // Giustificativo
  docInvoice: false,
  docReceipt: false,
  docTicket: false,

  // Colleghi ed ospiti
  colleagueEnb: false,
  guestEnb: false,

  // Gestione massimale
  maximumType: 'NO',
  maximumCollGuestType: 'FS',
  maximum: null,

  tarif: null,

  // Opzioni
  attachRequired: false,
  localityRequired: false,
  notesRequired: false,
  projectRequired: false,
  carRouteEnb: false,
  contableAccountId: null,

  // Gestione iva
  vatRate: null,
  deducPerc: null
}


export type TravelPolicyExpenseModalClosedModeType = 'closed' | 'updated' | 'created';

export type TravelPolicyExpenseModalClosedEvent = {
  action: TravelPolicyExpenseModalClosedModeType
  travelPolicy: TravelPolicy
  expense: TravelPolicyExpense | null
}

export type TravelPolicyExpenseModalClosedHandler = (event: TravelPolicyExpenseModalClosedEvent) => void;


// TODO parametro di conf nei setting
const maxVat = 35;

type TravelPolicyExpenseModalProps = {
  travelPolicy: TravelPolicy,
  expense: TravelPolicyExpense | MinTravelPolicyExpense | null,
  onClose: TravelPolicyExpenseModalClosedHandler
  editable: boolean
}


export const TravelPolicyExpenseModal: React.FC<TravelPolicyExpenseModalProps> = ({
                                                                                    travelPolicy,
                                                                                    expense,
                                                                                    onClose,
                                                                                    editable
                                                                                  }) => {

  const LOG: Slf4jsLog = Logger.of('ZTS.TravelPolicies.TravelPolicyExpenseModal');

  const {userLocale, companyDecimalNum, companyCurrencyCode} = useLoggedUser();
  const currencySymbol = getSymbolFromCurrency(companyCurrencyCode);

  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [account, setAccount] = useState<Partial<NewAccountObject> | null>(null);

  const {convertError} = useErrorMessage();

  const {t} = useTranslation(['travel-policy', 'expense', 'validation']);
  const {t: tModalLabel} = useTranslation('travel-policy', {keyPrefix: 'edit.modal.label'});
  const {t: tModalTitle} = useTranslation('travel-policy', {keyPrefix: 'edit.modal.title'});
  const {t: tType} = useTranslation('travel-policy', {keyPrefix: 'edit.type'});

  const maximumTypeOptions: SelectOption[] = [
    {value: 'SS', label: tType('maximumTypeOptions.ss')},
    {value: 'GG', label: tType('maximumTypeOptions.gg')}
  ];

  const maximumCollGuestOptions: SelectOption[] = [
    {value: 'FS', label: tType('maximumCollGuestOptions.fs')},
    {value: 'ML', label: tType('maximumCollGuestOptions.ml')},
  ];

  const validationSchema = Yup.object({
    tariffStr: yupNullableString()
      .when('expenseType', {
        is: (value) => value === 'TR' || value === 'IF',
        then: yupRequiredAmount(t, companyDecimalNum, userLocale)
      }),
    maximumStr: yupNullableString()
      .when('maximumEnb', {
        is: (value) => value,
        then: yupRequiredAmount(t, companyDecimalNum, userLocale)
      }),
  }).test({
    // Serve ad impedire la submit della form
    name: "payModeRequired",
    test: function (obj) {
      const exp: TravelPolicyExpenseFormValues = obj as TravelPolicyExpenseFormValues;
      if (exp.expenseType !== 'PL') {
        return true;
      }

      // Almeno 1 flag a true
      const isValid = exp.payCollab || exp.payCard || exp.payCompany;

      if (!isValid) {
        // Errore
        LOG.trace('Missing payMode');
        return this.createError({
          path: 'payMode',
          message: "Modalità di pagamento obbligatoria",
        });
      }

      return true;
    }
  }).test({
    // Serve ad impedire la submit della form
    name: "docTypeRequired",
    test: function (obj) {
      const exp: TravelPolicyExpenseFormValues = obj as TravelPolicyExpenseFormValues;
      if (exp.expenseType !== 'PL') {
        return true;
      }

      // Almeno 1 flag a true
      const isValid = exp.docReceipt || exp.docInvoice || exp.docTicket;

      if (!isValid) {
        // Errore
        LOG.trace('Missing docType');
        return this.createError({
          path: 'docType',
          message: "Giustificativo obbligatorio",
        });
      }

      return true;
    }
  })

  // Serve solo per inizializzare la form nel modo corretto
  const defaultFormValues: TravelPolicyExpenseFormValues = {
    ...defaultExpValues,
    tariffStr: '',
    maximumStr: '',
    payCollab: false,
    maximumType: 'SS',
    maximumEnb: defaultExpValues.maximumType !== 'NO',
    vatEnb: defaultExpValues.vatRate !== null,
    contableAccount: null, //async init
  };

  const formMethods = useForm<TravelPolicyExpenseFormValues>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  // Controllo i valori di questi campi
  const [maximumEnbFlag, vatEnbFlag] = formMethods.watch(['maximumEnb', 'vatEnb']);
  const [payCollabFlag, payCardFlag, payCompanyFlag] = formMethods.watch(['payCollab', 'payCard', 'payCompany']);
  const [docReceiptFlag, docTicketFlag, docInvoiceFlag] = formMethods.watch(['docReceipt', 'docTicket', 'docInvoice']);
  const [vatRateValue] = formMethods.watch(['vatRate']);

  const onAction = (flag: boolean) => {
    LOG.trace('onAction', flag);
    if (flag) {
      // submit della form
      formMethods.handleSubmit(onFormValid, onFormError)();
    } else {
      closeModal();
    }
  }

  const onFormValid = (data: TravelPolicyExpenseFormValues) => {
    LOG.trace('onFormValid');
    saveTravelPolicyExpense(data)
      .then(() => LOG.trace('saveTravelPolicyExpense end'));
  }

  const saveTravelPolicyExpense = async (data: TravelPolicyExpenseFormValues) => {
    LOG.trace('[async] saveTravelPolicyExpense', data);
    setErrorMsg(null);
    setSaving(true);

    try {
      const expType: ExpenseType = data.expenseType;
      let body: UpdateTravelPolicyExpenseRequest;
      switch (expType) {
        case 'PL':
          body = getOutOfThePocketBody(data);
          break;
        case 'TR':
          body = getTarifBody(data);
          break;
        case 'IF':
          body = getFixedImportBody(data);
          break;
        default:
          throw new Error('Unsupported ExpenseType: ' + expType);
      }
      const header: TravelPolicyHeader = {
        travelPolicyId: travelPolicy.id,
        lastUpdNum: travelPolicy.lastUpdNum
      }

      let action: TravelPolicyExpenseModalClosedModeType = 'updated';
      let resultExp: TravelPolicyExpense;
      if (data.id === -1) {
        // Creo una nuova voce nella travel policy
        action = 'created';
        const createReq: CreateTravelPolicyExpenseRequest = {
          ...body,
          expenseId: data.expenseId
        }
        resultExp = await createTravelPolicyExpense(header, createReq);
      } else {
        resultExp = await updateTravelPolicyExpense(data.id, header, body);
      }

      setSaving(false);

      if (expense) {
        onClose({
          action: action,
          travelPolicy: travelPolicy,
          expense: resultExp
        });
      }

    } catch (e) {
      const msg = await convertError(e as Error);
      setErrorMsg(msg);
      setSaving(false);
    }

  }

  // salva piè di lista
  const getOutOfThePocketBody = (data: TravelPolicyExpenseFormValues): UpdateTravelPolicyExpenseRequest => {
    let maximumType: MaximumType = 'NO';
    let maximumCollGuestType: MaximumColleagueGuestType | null = null;
    let maximum: number | null = null;
    let vatRate: number | null = null;
    let deducPerc: number | null = null;


    if (data.maximumEnb) {
      maximumType = data.maximumType;
      maximumCollGuestType = data.maximumCollGuestType;
      maximum = parseNumber(data.maximumStr, userLocale);
    }
    if (data.vatEnb) {
      vatRate = data.vatRate;
      deducPerc = data.deducPerc;
    }

    let contableAccountId: number | null = null;
    if (data.contableAccount) {
      contableAccountId = data.contableAccount.id;
    }

    return {
      payCollab: data.payCollab,
      payCard: data.payCard,
      payCompany: data.payCompany,

      docInvoice: data.docInvoice,
      docReceipt: data.docReceipt,
      docTicket: data.docTicket,

      colleagueEnb: data.colleagueEnb,
      guestEnb: data.guestEnb,

      maximumType: maximumType,
      maximumCollGuestType: maximumCollGuestType,
      maximum: maximum,

      tarif: null,

      attachRequired: data.attachRequired,
      localityRequired: data.localityRequired,
      notesRequired: data.notesRequired,
      projectRequired: data.projectRequired,
      carRouteEnb: false,
      contableAccountId: contableAccountId,

      vatRate: vatRate,
      deducPerc: deducPerc,
    }
  }

  const getTarifBody = (data: TravelPolicyExpenseFormValues): UpdateTravelPolicyExpenseRequest => {
    let contableAccountId: number | null = null;
    if (data.contableAccount) {
      contableAccountId = data.contableAccount.id;
    }

    let carRouteEnb = false;
    if (data.measureUnit !== undefined && data.measureUnit !== null && data.measureUnit === 'KM') {
      carRouteEnb = data.carRouteEnb;
    }

    return {
      payCollab: false,
      payCard: false,
      payCompany: false,

      docInvoice: false,
      docReceipt: false,
      docTicket: false,

      colleagueEnb: false,
      guestEnb: false,

      maximumType: null,
      maximumCollGuestType: null,
      maximum: null,

      tarif: parseNumber(data.tariffStr, userLocale),

      attachRequired: data.attachRequired,
      localityRequired: data.localityRequired,
      notesRequired: data.notesRequired,
      projectRequired: data.projectRequired,
      carRouteEnb: carRouteEnb,
      contableAccountId: contableAccountId,

      vatRate: null,
      deducPerc: null,
    }
  }

  const getFixedImportBody = (data: TravelPolicyExpenseFormValues): UpdateTravelPolicyExpenseRequest => {
    let contableAccountId: number | null = null;
    if (data.contableAccount) {
      contableAccountId = data.contableAccount.id;
    }

    return {
      payCollab: false,
      payCard: false,
      payCompany: false,

      docInvoice: false,
      docReceipt: false,
      docTicket: false,

      colleagueEnb: false,
      guestEnb: false,

      maximumType: null,
      maximumCollGuestType: null,
      maximum: null,

      tarif: parseNumber(data.tariffStr, userLocale),

      attachRequired: data.attachRequired,
      localityRequired: data.localityRequired,
      notesRequired: data.notesRequired,
      projectRequired: data.projectRequired,
      carRouteEnb: false,
      contableAccountId: contableAccountId,

      vatRate: null,
      deducPerc: null,
    }
  }

  const onFormError = (errors) => {
    LOG.trace('onFormError', errors);
  }

  const closeModal = () => {
    onClose({
      action: 'closed',
      travelPolicy: travelPolicy,
      expense: null
    });
  }

  const showFlag: boolean = expense !== null;
  let title = tModalTitle('viewExpense');
  if (expense && editable) {
    // @ts-ignore
    const expId = expense.id;
    if (expId === undefined || expId === null) {
      title = tModalTitle('enableExpense');
    } else {
      title = tModalTitle('expenseSettings');
    }
  }

  // pie di list
  const isPL = expense !== null && expense.expenseType === 'PL';

  const ivaMarks = [
    {value: 0, label: '0'},
    {value: 4, label: '4'},
    {value: 10, label: '10'},
    {value: 22, label: '22'},
  ];

  const deducMarks = [
    {value: 0, label: '0'},
    {value: 40, label: '40'},
    {value: 50, label: '50'},
    {value: 100, label: '100'},
  ];

  const ivaLabel = (value: number): string => {
    if (value === 0) {
      return tModalLabel('vatFree');
    }
    return tModalLabel('vatRate', {value: value});
  }

  const deducLabel = (value: number): string => {
    if (value === 0) {
      return tModalLabel('nonDeduct');
    }
    if (value === 100) {
      return tModalLabel('fullyDeduct');
    }
    return tModalLabel('deductPercentage', {value: value});
  }

  const [accountOpts, setAccountOpts] = useState<AutocompleteGenericOption[]>([]);
  const [loadingAccount, setLoadingAccount] = useState<boolean | null>(null);


  const {isProfessional, setProfessional} = useLicense();
  const free = !isProfessional();

  const createAccountOption = (text: string): AutocompleteGenericOption => {
    const label = tModalLabel('createAccountOption', {text: text});
    return {
      id: -1,
      code: '',
      desc: text,
      icon: null,
      selectedLabel: text,
      listLabel: label
    };
  }
  const onAccountModalClosed = (event: NewAccountModalClosedEvent) => {
    LOG.trace('onAccountModalClosed', event);
    setAccount(null);
    if (event.mode === 'saved') {
      let newOpts = accountOpts.slice(0);
      const l: Account = event.account as Account;
      let newOption: AutocompleteGenericOption = createAutocompleteGenericOption(l.id, l.code, l.description);
      newOpts.push(newOption);
      setAccountOpts(newOpts);
      formMethods.setValue('contableAccount', newOption);
    }
  }

  const getMeasureLabel = (mu: ExpenseMeasureUnit | undefined | null) => {
    if (mu === undefined || mu === null) return '';
    switch (mu) {
      case 'GG':
        return tModalLabel('day');
      case 'KM':
        return tModalLabel('kilometer');
      case 'NR':
        return tModalLabel('unit');
    }
    throw new Error('Unexpected ExpenseMeasureUnit: ' + mu);
  }

  useEffect(() => {
    LOG.trace('useEffect [expense]', expense);

    const asyncInitFn = async (exp: Partial<TravelPolicyExpense>) => {
      LOG.trace('asyncInitFn [async]', exp);
      // Inizializzazione async
      const hasAccountFlag = exp.contableAccountId !== undefined && exp.contableAccountId !== null;
      if (!free || hasAccountFlag) {
        setLoadingAccount(true);
        let accountList: Account[] = [];
        if (!free) {
          accountList = await getActiveAccounts();
        }
        // Filtro ed ordino
        accountList = accountList
          .sort((a, b) => a.description.localeCompare(b.description));

        // Cerco il conto nella lista
        const options: AutocompleteGenericOption[] = accountList.map(l => createAutocompleteGenericOption(l.id, l.code, l.description));

        let accountOption: AutocompleteGenericOption | null = null;
        let accountError = false;
        if (hasAccountFlag) {
          // @ts-ignore
          const id: number = exp.contableAccountId;
          // Cerco la mia opzione nella lista
          let currentOption = options.find(o => o.id === id);
          if (currentOption === undefined || currentOption === null) {
            const account: Account = await readAccount(id);
            currentOption = createAutocompleteGenericOption(account.id, account.code, account.description);
            accountError = true;
            //options.push(currentOption);
          }
          accountOption = currentOption;
        }
        setAccountOpts(options);
        if (accountOption) {
          formMethods.setValue('contableAccount', accountOption);
        }
        if (accountError) {
          formMethods.setError('contableAccount', {message: 'Il campo non è valido'});
        }
        setLoadingAccount(false);
      }
    }

    if (expense) {
      const startValues: Required<TravelPolicyExpense> = {
        ...defaultExpValues,
        ...expense
      }

      if (startValues.contableAccountId !== null) {
        setLoadingAccount(true);
      }

      const initVal: TravelPolicyExpenseFormValues = {
        ...startValues,
        tariffStr: '',
        maximumStr: '',
        maximumEnb: startValues.maximumType !== 'NO',
        vatEnb: startValues.vatRate !== null,
        contableAccount: null
      };

      if (startValues.tarif) {
        initVal.tariffStr = formatNumber(startValues.tarif, userLocale, companyDecimalNum);
      }
      if (startValues.maximum) {
        initVal.maximumStr = formatNumber(startValues.maximum, userLocale, companyDecimalNum);
      }

      // Forzio giornaliero come valore iniziale nel caso abilito
      if (!initVal.maximumEnb) {
        initVal.maximumType = 'GG';
      }

      if (!initVal.vatEnb) {
        initVal.vatRate = 22;
        if (!initVal.deducPerc) {
          initVal.deducPerc = 100;
        }
      }
      formMethods.reset(initVal);

      asyncInitFn(expense)
        .then(() => LOG.trace('asyncInitFn end.'));
    }
  }, [expense]);


  return (
    <CommonDialog
      show={showFlag}
      title={title}
      subTitle={travelPolicy.description}
      widths={[
        {breakpoint: "lg", width: isPL ? '850px' : "545px"},
      ]}
      saving={saving}
      errorMsg={errorMsg}
      page={HelpCurrentPage.TRAVEL_POLICIES_EDIT}
      onClose={onAction}
      hideSave={!editable}
    >
      <>
        <NewAccountModal
          title='Nuovo Conto di Costo'
          account={account}
          onClose={onAccountModalClosed}
        />
        <FormProvider {...formMethods}>
          <DevTool control={formMethods.control} placement="top-left"/>
          <form noValidate>
            <Box mx={1}>
              <Stack direction='row' spacing={6}>

                <Stack direction='row' spacing={1}>
                  <ExpenseImg
                    img={expense?.expenseIcon}
                    label={expense?.expenseDescription || ''}
                    size='medium'
                  />
                  <Typography variant='h3'>{expense?.expenseDescription}</Typography>
                </Stack>

                <Stack>
                  <Typography variant='h6' textTransform='uppercase'>{tModalLabel('type')}</Typography>
                  <Typography variant='body1'>{getExpenseTypeLabel(expense?.expenseType, t)}</Typography>
                </Stack>

                <Stack>
                  <Typography variant='h6' textTransform='uppercase'>{tModalLabel('category')}</Typography>
                  <Typography variant='body1'>{getExpenseCategoryLabel(expense?.expenseCategory, t)}</Typography>
                </Stack>

              </Stack>

              {expense?.expenseType !== 'PL' &&
                <Grid container
                      mt={4}
                      spacing={1}
                >
                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <Typography variant='h6' textTransform='uppercase'>{tModalLabel('refund')}</Typography>
                      {expense?.expenseType === 'TR' &&
                        <Typography variant='body1'>{tModalLabel('forEachMeasure', {measureLabel: getMeasureLabel(expense?.measureUnit)})}</Typography>
                      }
                      {expense?.expenseType === 'IF' &&
                        <Typography variant='body1'>{tModalLabel('total')}</Typography>
                      }
                      <Controller
                        name="tariffStr"
                        render={({field, fieldState}) =>
                          <TextFieldZts
                            InputProps={{
                              endAdornment: <InputAdornment position='end'
                                                            style={{marginTop: '18px'}}>{currencySymbol}</InputAdornment>,
                            }}
                            {...field}
                            value={field.value ? field.value : ''}
                            required
                            disabled={!editable}
                            label={tModalLabel('amount')}
                            errorMsg={fieldState.error?.message}
                            align='right'
                          />
                        }
                      />
                    </Stack>
                  </Grid>

                </Grid>
              }

              {expense?.expenseType === 'PL' &&
                <Grid container
                      mt={4}
                      rowSpacing={2}
                      columnSpacing={3.5}
                >
                  <Grid item xs={4}>
                    <Stack spacing={2}>
                      <Typography variant='h6' textTransform='uppercase'>{tModalLabel('payMode')}</Typography>

                      <TravelPolicyExpenseCheckbox editable={editable} name='payCollab'
                                                   label={tModalLabel('payCollab')}/>
                      <TravelPolicyExpenseCheckbox editable={editable} name='payCard' label={tModalLabel('payCard')}/>
                      <TravelPolicyExpenseCheckbox editable={editable} name='payCompany' label={tModalLabel('payCompany')}/>

                      {
                        isPL && !payCollabFlag && !payCardFlag && !payCompanyFlag &&
                        <Typography color="error">
                          {tModalLabel('selectPayMode')}
                        </Typography>
                      }
                    </Stack>
                  </Grid>

                  <Grid item xs={4}>
                    <Stack spacing={2}>
                      <Typography variant='h6' textTransform='uppercase'>{tModalLabel('justification')}</Typography>
                      <TravelPolicyExpenseCheckbox editable={editable} name='docInvoice' label={tModalLabel('docInvoice')}/>
                      <TravelPolicyExpenseCheckbox editable={editable} name='docReceipt' label={tModalLabel('docReceipt')}/>
                      <TravelPolicyExpenseCheckbox editable={editable} name='docTicket' label={tModalLabel('docTicket')}/>

                      {
                        isPL && !docReceiptFlag && !docInvoiceFlag && !docTicketFlag &&
                        <Typography color="error">
                          {tModalLabel('selectJustification')}
                        </Typography>
                      }
                    </Stack>
                  </Grid>

                  <Grid item xs={4}>
                    <Stack spacing={2}>
                      <Typography variant='h6' textTransform='uppercase'>{tModalLabel('colleaguesAndGuests')}</Typography>
                      <TravelPolicyExpenseCheckbox editable={editable} name='colleagueEnb' label={tModalLabel('allowColleague')}/>
                      <TravelPolicyExpenseCheckbox editable={editable} name='guestEnb' label={tModalLabel('allowGuest')}/>
                    </Stack>
                  </Grid>
                </Grid>
              }

              <Grid container
                    mt={4}
                    rowSpacing={2}
                    columnSpacing={3.5}
              >
                {expense?.expenseType === 'PL' &&
                  <Grid item xs={4}>
                    <Stack spacing={2} rowGap={2} justifyContent={"space-between"} height="360px">
                      <Typography variant='h6' textTransform='uppercase'>{tModalLabel('maximumManagement')}</Typography>
                      <TravelPolicyExpenseSwitch editable={editable} name='maximumEnb' label={tModalLabel('enableMaximum')}/>


                      <Controller
                        name="maximumType"
                        render={({field, fieldState}) =>
                          <SelectZts
                            disabled={!maximumEnbFlag || !editable}
                            errorMsg={fieldState.error?.message}
                            label={tModalLabel('maximumType')}
                            width='100%'
                            items={maximumTypeOptions}
                            selectedValue={!maximumEnbFlag ? '' : field.value ? field.value : ''}
                            setValue={(value) => {
                              field.onChange(value)
                            }}
                          />
                        }
                      />

                      <Controller
                        name="maximumCollGuestType"
                        render={({field, fieldState}) =>
                          <SelectZts
                            disabled={!maximumEnbFlag || !editable}
                            errorMsg={fieldState.error?.message}
                            label={tModalLabel('maximumCollGuestType')}
                            width='100%'
                            items={maximumCollGuestOptions}
                            selectedValue={!maximumEnbFlag ? '' : field.value ? field.value : ''}
                            setValue={(value) => {
                              field.onChange(value)
                            }}
                          />
                        }
                      />

                      <Controller
                        name="maximumStr"
                        render={({field, fieldState}) => <TextFieldZts
                          InputProps={{
                            endAdornment: <InputAdornment position='end'
                                                          style={{marginTop: '18px'}}>{maximumEnbFlag ? currencySymbol : ''}</InputAdornment>,
                          }}
                          {...field}
                          value={!maximumEnbFlag ? '' : field.value ? field.value : ''}
                          disabled={!maximumEnbFlag || !editable}
                          required={maximumEnbFlag}
                          label={maximumEnbFlag ? tModalLabel('maxAmount') : tModalLabel('unlimitedAmount')}
                          errorMsg={fieldState.error?.message}
                          helperText={fieldState.error?.message && maximumEnbFlag ? null : ' '}
                          align='right'
                        />
                        }
                      />

                    </Stack>
                  </Grid>
                }
                <Grid item xs={expense?.expenseType === 'PL' ? 4 : 6}>
                  <Stack spacing={2} rowGap={2} justifyContent={"space-between"} height="360px">
                    <Typography variant='h6' textTransform='uppercase'>{tModalLabel('options')}</Typography>
                    {expense?.expenseType === 'TR' &&
                      expense?.measureUnit === 'KM' &&
                      <TravelPolicyExpenseSwitch editable={editable} name='carRouteEnb' label={tModalLabel('carRouteEnb')}/>
                    }
                    <TravelPolicyExpenseSwitch editable={editable} name='attachRequired' label={tModalLabel('attachRequired')}/>
                    <TravelPolicyExpenseSwitch editable={editable} name='localityRequired'
                                               label={tModalLabel('localityRequired')}/>
                    <TravelPolicyExpenseSwitch editable={editable} name='notesRequired' label={tModalLabel('notesRequired')}/>
                    {free &&
                      <Stack direction='row' spacing={1} style={{
                        marginLeft: '10px',
                        alignItems: 'center',
                        maxWidth: '120px'
                      }}>
                        <TravelPolicyExpenseSwitch editable={false} name='projectRequired'
                                                   label={tModalLabel('projectRequired')}/>
                        <PremiumIcon type={PopupContentType.TP_MANDATORY_PROJECT_SWITCH} clickable={true}/>
                      </Stack>
                    }
                    {
                      !free &&
                      <TravelPolicyExpenseSwitch editable={editable} name='projectRequired'
                                                 label={tModalLabel('projectRequired')}/>
                    }

                    {loadingAccount && <Skeleton variant="rectangular" height={52}/>}

                    {!loadingAccount &&
                      <Box position="relative" mt={0.5}>

                        <Controller
                          name="contableAccount"
                          render={({field, fieldState}) => {
                            return <AutocompleteZts
                              id={field.name}
                              disabled={!editable || (free && field.value === null)}
                              options={accountOpts}
                              label={tModalLabel('contableAccount')}
                              selectedValue={field.value}
                              setValue={(_, value) => {
                                field.onChange(value);
                                if (value && value.id === -1) {
                                  field.onChange(null);
                                  const obj: Partial<NewAccountObject> = {
                                    description: value.desc
                                  }
                                  setAccount(obj);
                                }
                              }}
                              codeAndDesc={true}
                              errorMsg={fieldState.error?.message}
                              appendNewValue={createAccountOption}
                            />
                          }}
                        />
                        {free && <PremiumIcon style={{position: 'absolute', top: '18px', right: '65px'}}
                                              type={PopupContentType.TP_COST_ACCOUNT_PILL} clickable={true}/>}
                      </Box>
                    }


                  </Stack>
                </Grid>

                {expense?.expenseType === 'PL' &&
                  <Grid item xs={4}>
                    <Stack spacing={2} rowGap={2}>
                      <Stack direction='row' spacing={1}>
                        <Typography variant='h6' textTransform='uppercase'>{tModalLabel('vatManagement')}</Typography>
                        {free && <PremiumIcon style={{marginTop: '-4px'}} type={PopupContentType.TP_VAT_MANAGEMENT_PILL}
                                              clickable={true}/>}
                      </Stack>
                      <TravelPolicyExpenseSwitch editable={editable && !free} name='vatEnb'
                                                 label={tModalLabel('enableVatManagement')}/>

                      <Controller
                        name='vatRate'
                        render={({field}) =>
                          <FormControlLabel
                            label={ivaLabel(vatEnbFlag ? field.value : 0)}
                            labelPlacement='top'
                            sx={{alignItems: 'flex-start'}}
                            style={{marginLeft: '10px', marginRight: '10px'}}
                            control={
                              <Slider
                                {...field}
                                value={vatEnbFlag ? field.value : 0}
                                disabled={!vatEnbFlag || !editable || free}
                                min={0}
                                max={maxVat}
                                valueLabelDisplay="auto"
                                marks={ivaMarks}
                              />
                            }
                          />
                        }
                      />

                      <Controller
                        name='deducPerc'
                        render={({field}) =>
                          <FormControlLabel
                            label={deducLabel((!vatEnbFlag || vatRateValue === 0) ? 0 : field.value)}
                            labelPlacement='top'
                            sx={{alignItems: 'flex-start'}}
                            style={{marginLeft: '10px', marginRight: '10px'}}
                            control={
                              <Slider
                                {...field}
                                value={(!vatEnbFlag || vatRateValue === 0) ? 0 : field.value}
                                disabled={!vatEnbFlag || vatRateValue === 0 || !editable || free}
                                min={0}
                                max={100}
                                valueLabelDisplay="auto"
                                marks={deducMarks}
                              />
                            }
                          />
                        }
                      />

                    </Stack>
                  </Grid>
                }
              </Grid>
            </Box>
          </form>
        </FormProvider>
      </>
    </CommonDialog>
  );

}
