import {useExpNoteDetail} from "../../../hooks/useExpNoteDetail";
import {CommonDialog} from "../../../../../base/common-dialog";
import React, {useEffect, useState} from "react";
import {useErrorMessage} from "../../../../../../util/ErrorUtil";
import * as Yup from "yup";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {MaximumExpensesFormValue, MaximumExpensesFormValues} from "./model";
import {SingleMaximum} from "./SingleMaximum";
import {Divider, Stack} from "@mui/material";
import {updateExpNoteMaximum} from "../../../../Service";
import {UpdateExpNoteMaximumRequest} from "../../../../model";
import {ApprovalState} from "../../../../expense/model";
import {TooltipZts} from "../../../../../base/tooltip";
import IconButton from "@mui/material/IconButton";
import {Check, Clear} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  show: boolean,
  onClose: Function
}

export const ExpNoteMaximum = ({show, onClose}: ComponentProps) => {

  const [saving, setSaving] = useState(false);
  const [massiveApproval, setMassiveApproval] = useState<ApprovalState | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const {t} = useTranslation("exp-note", {keyPrefix: "detail.maximumApproval"});
  const {t: tValidation} = useTranslation("validation");

  const {
    expNote,
    expenses
  } = useExpNoteDetail();

  const {
    convertError
  } = useErrorMessage();

  const validationSchema = Yup.object({
    rows: Yup.array(
      Yup.object({
        approvalState: Yup.mixed()
          .oneOf([ApprovalState.APPROVED, ApprovalState.REJECTED])
          .required(tValidation("required")),
        maxExcExp: Yup.number(),
        maxExcAut: Yup.number(),
        maximum: Yup.number()
      })
    )
  });

  const formMethods = useForm<MaximumExpensesFormValues>({
    defaultValues: {rows: []},
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  const {fields} = useFieldArray({
    name: "rows",
    control: formMethods.control
  });

  useEffect(() => {
    if (expenses && show) {
      const rows: MaximumExpensesFormValue[] = expenses
        .filter(e => e.maxExcExp > 0 || e.approvalState === ApprovalState.APPROVED)
        .map(e => ({
          _id: e.id,
          approvalState: e.approvalState,
          maxExcExp: e.compAmount - e.origMaximum,
          maxExcAut: e.maxExcAut,
          maximum: e.maximum
        }));
      formMethods.reset({rows});
    }

    if (!show) {
      setMassiveApproval(null);
    }
  }, [expenses, show]);

  const handleCloseDialog = (save) => {
    if (save) {
      formMethods.handleSubmit(handleSaveData)();
    } else {
      onClose(false);
    }
  }

  const handleSaveData = async (values: MaximumExpensesFormValues) => {
    if (expNote && values.rows) {
      //console.log('handleSaveData', values)
      setErrorMsg(null);
      setSaving(true);

      const data: UpdateExpNoteMaximumRequest = {
        lastUpdateNum: expNote.lastUpdNum,
        expenses: values.rows.map(row => {
          let maximum = row.maximum;
          if (!maximum) {
            maximum = 0;
          }
          return {expenseId: row._id, maximum, approvalState: row.approvalState};
        })
      }

      try {
        await updateExpNoteMaximum(expNote, data);
        onClose(true);
      } catch (error) {
        convertError(error as Error).then(res => setErrorMsg(res));
      } finally {
        setSaving(false);
      }
    }
  }

  return (
    <CommonDialog
      show={show}
      title={t("title")}
      titleIcon={
        <Stack
          direction="row"
          columnGap={2}
        >
          <TooltipZts
            title={t("approveAll")}
            placement={"bottom"}
            enterDelay={400}
          >
            <IconButton
              color="primary"
              className={"approve-all-button"}
              onClick={() => setMassiveApproval(ApprovalState.APPROVED)}
            >
              <Check/>
            </IconButton>
          </TooltipZts>
          <TooltipZts
            title={t("rejectAll")}
            placement={"bottom"}
            enterDelay={400}
          >
            <IconButton
              color="primary"
              className={"reject-all-button"}
              onClick={() => setMassiveApproval(ApprovalState.REJECTED)}
            >
              <Clear/>
            </IconButton>
          </TooltipZts>
        </Stack>
      }
      widths={[
        {breakpoint: "md", width: "750px"},
      ]}
      saving={saving}
      onClose={handleCloseDialog}
      errorMsg={errorMsg}
    >
      <FormProvider {...formMethods}>
        <form noValidate>
          {fields && fields.length > 0 ? (
            fields.map((item, index) => (
              <React.Fragment key={item.id}>
                <SingleMaximum
                  index={index}
                  expense={expenses?.find(e => e.id === item._id)}
                  forcedApproval={massiveApproval ? massiveApproval : undefined}
                />
                {index < fields.length - 1 && <Divider sx={{mt: {xs: 4, md: 2}, mb: 3}} light/>}
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </form>
      </FormProvider>
    </CommonDialog>
  );
}
