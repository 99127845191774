import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const SuppliersPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile inserire l&rsquo;elenco dei propri fornitori relativi alle spese di trasferta.
          Quando viene fatta la foto dello scontrino, il nostro OCR, cattura data, importo e <strong>partita IVA del
            fornitore</strong>, confrontandola in questo Data Base verr&agrave; aggiunta come dettaglio all&rsquo;export
          contabile di ZTravel Smart in aggiunta all&rsquo;importo che &egrave; possibile detrarre da una determinata
          spesa. <strong>Calcola automaticamente l&rsquo;IVA detraibile delle spese di trasferta e recuperala dalle tue
            note spese</strong>.</p>
      );
    default:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile inserire l&rsquo;elenco dei propri fornitori relativi alle spese di trasferta.
          Quando viene fatta la foto dello scontrino, il nostro OCR, cattura data, importo e <strong>partita IVA del
            fornitore</strong>, confrontandola in questo Data Base verr&agrave; aggiunta come dettaglio all&rsquo;export
          contabile di ZTravel Smart in aggiunta all&rsquo;importo che &egrave; possibile detrarre da una determinata
          spesa. <strong>Calcola automaticamente l&rsquo;IVA detraibile delle spese di trasferta e recuperala dalle tue
            note spese</strong>.</p>
      );
  }
}
