import {Alert, Box, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {PageTitle} from "../../layout/page-title";
import {Account, ListFilter, ListFilterType} from "../model";
import IconButton from "@mui/material/IconButton";
import {Add, UploadFile} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {getAccounts, restoreAccount} from "../Service";
import {AccountList} from "./AccountList";
import {Filters} from "./Filters";
import {TooltipZts} from "../../base/tooltip";
import {useTranslation} from "react-i18next";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {ImportPage, TemplateFileType} from "../../import/ImportPage";

export const FilterableList = () => {

  const {t} = useTranslation(['account'], {keyPrefix: 'list'});
  const {t: tImport} = useTranslation('import');

  //------
  // State
  //------

  const [allAccounts, setAllAccounts] = useState<Account[]>([]); // State: lista di tutti gli account: getAllAccounts
  const [accounts, setAccounts] = useState<Account[]>([]); // State: lista degli account da renderizzare
  const [disabledAccounts, setDisabledAccounts] = useState<Account[]>([]); // State: lista degli account 'eliminati'
  const [loading, setLoading] = useState<boolean>(false); // State: flag utile alla gestione del rendering in fase di caricamento
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [showActive, setShowActive] = useState<boolean>(true); // State: flag utile a visualizzare solo certi accounts (attivi/eliminati)
  const [toggleClicked, setToggleClicked] = useState("lista");

  const [openImportPage, setOpenImportPage] = useState(false);
  const [successfulImport, setSuccessfulImport] = useState<boolean>(false);

  const navigate = useNavigate();
  const {convertError} = useErrorMessage();

  //-----------
  // useEffects
  //-----------

  // Rendering: al primo render, fetch di tutti gli Accounts
  useEffect(() => {
    fetchAccounts();
  }, []);

  //----------
  // Functions
  //----------

  const fetchAccounts = () => {
    let activeAccounts: Account[] = [];
    let disabled: Account[] = [];
    setLoading(true);
    getAccounts()
      .then(response => {
        if (response.data.elements) {
          response.data.elements.filter((account: Account) => {
            if (account.state === 'EF') {
              activeAccounts.push(account);
            }
            else if (account.state === 'AA'){
              disabled.push(account);
            }
          });
          setAllAccounts(response.data.elements);
          setAccounts(activeAccounts);
          setDisabledAccounts(disabled);
        }
      })
      .catch((error) => {
        convertError(createError(error))
          .then((msg) => setFetchError(msg))
      })
      .finally(() => setLoading(false))
  }

  const handleNewAccountClicked = () => {
    if (showActive) {
      navigate('edit/-1'); // Stessa route dell'editing, però con account code = -1
    }
  }

  const handleImportAccountClicked = () => {
    setOpenImportPage(true);
  }

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView !== null) {
      setToggleClicked(nextView);
      setShowActive(!showActive);
    }
  }

  // Handler: apertura pagina dettaglio singolo Account
  const openItemHandler = (id: number) => {
    navigate(`/accounts/detail/${id}`);
  }

  const onRestoreAccountHandler = (id: number) => {
    if (id) {
      restoreAccount(id)
        .then(() => {
          fetchAccounts();
        })
    }
  }

  const handleTrashToggle = () => {
    setShowActive(!showActive);
  }

  // Handler: gestione cambiamenti dei filtri -> per ogni cambiamento ai filtri, handleFilterChange(filters: ListFilter[])
  const handleFilterChange = (filters: ListFilter[]) => {
    let newAccounts = allAccounts;

    if (newAccounts && newAccounts.length > 0) {
      filters
        .filter((filter: ListFilter) => filter.enabled && filter.value)
        .forEach((filter: ListFilter) => {
          switch (filter.type) {
            case ListFilterType.SEARCH_TEXT:
              newAccounts = newAccounts.filter((account: Account) =>
                account.code.toUpperCase().indexOf(filter.value.toUpperCase()) !== -1 ||
                account.description.toUpperCase().indexOf(filter.value.toUpperCase()) !== -1);
              break;
          }
        });
    }
    let filteredDisabledAccounts = newAccounts.filter((account) => account.state === 'AA');
    let filteredActiveAccounts = newAccounts.filter((account) => account.state === 'EF');
    setAccounts(filteredActiveAccounts);
    setDisabledAccounts(filteredDisabledAccounts);
  }

  const handleImportPageClose = () => {
    setOpenImportPage(false);
    if (successfulImport) {
      fetchAccounts();
    }
  }

  //----------------
  // React Component
  //----------------

  return (
    <Box
      width={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      mx={"auto"}
    >
      {/*Alert nel caso in cui si incontrino problemi durante la fase di fetching dei dati*/}
      {
        fetchError &&
        <Alert
          severity={"error"}
          variant={"filled"}
          sx={{mb: 4}}
          onClose={() => setFetchError(null)}
        >
          {
            fetchError
          }
        </Alert>
      }

      {/*Titolo pagina*/}
      <PageTitle
        title={
          <Stack direction={"row"} alignItems={"center"}>
            {
              showActive ?
                (
                  <>
                    <Typography variant={"h3"} mr={1}>{t("title")}</Typography>
                    <Box className={"counter-primary"} m={1}>
                      <Typography variant={"button"}>{accounts ? accounts.length : 0}</Typography>
                    </Box>
                  </>
                ) :
                (
                  <>
                    <Typography variant={"h3"} mr={1}>{t("title")}</Typography>
                    <Typography variant={"h4"} children={`(${t("titleParam")})`} sx={{color: "#14c6d5"}}/>
                    <Box className={"counter-primary"} m={1}>
                      <Typography variant={"button"}>{disabledAccounts ? disabledAccounts.length : 0}</Typography>
                    </Box>
                  </>
                )
            }
            <Box flexGrow={1}/>
            <ToggleButtonGroup
              exclusive={true}
              value={toggleClicked}
              size={"small"}
              onChange={handleChange}
              color={"primary"}
            >
              <ToggleButton
                value={"lista"}
                className={"ztsToggle"}
              >
                <TooltipZts
                  title={t('button.showList')}
                  placement={"bottom"}
                  enterDelay={400}
                >
                  <ViewModuleIcon fontSize={"small"}/>
                </TooltipZts>

              </ToggleButton>
              <ToggleButton
                value={"cestino"}
                className={"ztsToggle"}
              >
                <TooltipZts
                  title={t('button.showTrash')}
                  placement={"bottom"}
                  enterDelay={400}
                >
                  <DeleteOutlineIcon fontSize={"small"}/>
                </TooltipZts>

              </ToggleButton>

            </ToggleButtonGroup>
          </Stack>
        }
      />

      <Box
        mt={1}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        columnGap={1}
      >
        {/*Filtri*/}
        <Filters onFilterChange={handleFilterChange}/>

        <Box flexGrow={1}/>

        <TooltipZts
          title={t('button.import')}
          placement={"bottom"}
          enterDelay={400}
        >
          <IconButton
            color="primary"
            className={"edit-button"}
            id={"import-button"}
            sx={{
              opacity: showActive ? 1 : 0.4,
              cursor: showActive ? "pointer" : "default"
            }}
            disabled={!showActive}
            onClick={handleImportAccountClicked}
          >
            <UploadFile/>
          </IconButton>
        </TooltipZts>

        <TooltipZts
          title={t('button.newAccount')}
          placement={"bottom"}
          enterDelay={400}
        >
          <IconButton
            color="primary"
            className={"edit-button"}
            id={"new-acc-button"}
            sx={{
              opacity: showActive ? 1 : 0.4,
              cursor: showActive ? "pointer" : "default"
            }}
            disabled={!showActive}
            onClick={handleNewAccountClicked}
          >
            <Add/>
          </IconButton>
        </TooltipZts>
      </Box>

      {/*Lista dei conti contabili*/}
      <Box
        id={"infinite-scroll-target"}
        overflow={"auto"}
        flexGrow={1}
      >
        {
          showActive ?
            (
              <AccountList props={{
                accounts: accounts,
                loading: loading,
                onOpen: openItemHandler,
                onRestore: onRestoreAccountHandler,
                active: true
              }}/>
            ) :
            (
              <AccountList props={{
                accounts: disabledAccounts,
                loading: loading,
                onOpen: openItemHandler,
                onRestore: onRestoreAccountHandler,
                active: false
              }}/>
            )
        }
      </Box>

      <ImportPage
        title={tImport('list.titleImport.accounts')}
        onClose={handleImportPageClose}
        show={openImportPage}
        save={false}
        onImport={null}
        onDownload={null}
        templateFileType={TemplateFileType.GENERAL_ACC}
        notifySuccessfulImport={() => setSuccessfulImport(true)}
      />

    </Box>
  );
}
