// React
import React from "react";

import {Expense, ExpenseType} from "../model";
import {Grid, Typography} from "@mui/material";
import {ExpenseCard, ExpenseCardActionEvent, ExpenseCardActionHandler} from "./ExpenseCard";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {NewExpenseCard, NewExpenseCardActionHandler} from "./NewExpenseCard";
import {getExpenseTypeLabel} from "../utils";
import {useTranslation} from "react-i18next";



type ExpensesGridProps = {
  expenses: Expense[],
  expenseType: ExpenseType,
  title?: string,
  editable?: boolean,
  sx?: SxProps<Theme>,
  onExpense: ExpenseCardActionHandler,
  onNewExpense: NewExpenseCardActionHandler,
  premium: boolean
}

export const ExpensesGrid: React.FC<ExpensesGridProps> = ({
  title,
  expenses,
  expenseType,
  editable,
  sx,
  onExpense,
  onNewExpense,
  premium
}) => {

  const {t} = useTranslation(['expense']);

  const titleLbl = title ? title : getExpenseTypeLabel(expenseType, t);

  return (
    <Grid container spacing={2} sx={sx}>
      <Grid item xs={12}>
        <Typography variant='h6' textTransform='uppercase'>{titleLbl}</Typography>
      </Grid>

      { expenses.filter(exp => exp.expenseType===expenseType).map(
        exp =>
          <Grid key={exp.id} item xs={6}>
            <ExpenseCard
              expense={exp}
              onAction={onExpense}
              selectable={editable}
              editable={editable}
            />
          </Grid>
        )
      }

      {editable &&
        <Grid item xs={6}>
          <NewExpenseCard
            premium={premium}
            expenseType={expenseType}
            onAction={onNewExpense}/>
        </Grid>
      }

    </Grid>
  );

}
