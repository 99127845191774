// React
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

// Libs
import { Logger } from 'react-logger-lib';
import SimpleJsLog from "../../util/Logger";
import {useTranslation} from "react-i18next";

const LOG: SimpleJsLog = Logger.of('ZTS.Login.LoginInfo');

export const LoginInfo: React.FC = () => {

  const [searchParams] = useSearchParams();

  const tenantId = searchParams.get('tenantId');

  const {t} = useTranslation('login', {keyPrefix: 'info'});

  const [visibleTab, setVisibleTab] = useState<string>('loading');
  const [errorMsg, setErrorMessage] = useState<string>('');

  useEffect( ()=>{
    LOG.trace('useEffect []');
    if(tenantId===undefined || tenantId==null) {
      setVisibleTab('NoQueryParam');
      return;
    }
    if (tenantId==='none' || tenantId==='multi') {
      setVisibleTab(tenantId);
      return;
    }
    //const tenantName = searchParams.get('name');
    const tenantState = searchParams.get('state');

    setVisibleTab('single');
    let errorMsg = t('error.unexpected');
    if (tenantState === 'DISABLED') {
      errorMsg = t('error.disabled');
    }
    if (tenantState === 'NETWORK_ERROR') {
      errorMsg = t('error.networkError');
    }
    setErrorMessage(errorMsg);

  }, []);

  return (
    <>
      {visibleTab === 'loading' &&
        <div>...</div>
      }
      {visibleTab === 'NoQueryParam' &&
        <div>{t('message1')}</div>
      }
      {visibleTab === 'none' &&
        <div>
          {t('message2')} <br/>
          {t('message3')} <br/>
          {t('message4')}
        </div>
      }
      {visibleTab === 'multi' &&
        <div>
          {t('message5')} <br/>
           <br/>
          {t('message6')}
        </div>
      }
      {visibleTab === 'single' &&
        <div>{errorMsg}</div>
      }
    </>
  );
}
