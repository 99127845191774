import {ZtsTable} from "../../../../../base/table";
import React, {useEffect, useState} from "react";
import {ZtsTableColumn} from "../../../../../base/table/model";
import {ExpNoteExpense, ExpNoteExpenseFull} from "../../../../expense/model";
import {arrayGroupByProperty} from "../../../model";
import moment from "moment";
import {Columns} from "../index";
import {EmptyStateExpNote} from "../../../../empty-state";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  columns: ZtsTableColumn[],
  expenses: ExpNoteExpenseFull[],
  openExpDetailHandler: (docNum: number) => void
}

export const ExpNoteExpenseList = (props: ComponentProps) => {

  const {
    columns,
    expenses,
    openExpDetailHandler
  } = props;

  const [localRows, setLocalRows] = useState<ExpNoteExpenseFull[]>([]);

  const [order, setOrder] = useState<{ orderBy: string, orderDir: 'asc' | 'desc' }>({
    orderBy: 'docNum',
    orderDir: 'asc'
  });

  const {t} = useTranslation("exp-note");

  useEffect(() => {
    // if (order.orderBy === 'docNum') {
    const expByDocument: Map<number, ExpNoteExpenseFull[]> = expenses && expenses.length > 0 ? arrayGroupByProperty(expenses, 'docNum') : new Map<number, ExpNoteExpenseFull[]>();

    let convertedExpenses: any[] = [];
    if (expByDocument && expByDocument.size > 0) {
      expByDocument.forEach((rows, docNum) => {
        if (rows) {
          if (rows.length === 1) {
            convertedExpenses.push({...rows[0], check: rows[0].docNum});
          } else {
            const aggregateRow = {
              id: `aggregateRow_docNum_${docNum}`,
              docNum,
              check: docNum,
              childrenRows: getChildren(rows, Number(docNum))
            }
            aggregateRow[Columns.TRAVEL_POLICY_DESC] = rows[0].travelPolicyDesc;
            aggregateRow[Columns.LOCALITY] = rows[0].locality;
            aggregateRow[Columns.PROJECT_DESC] = rows[0].projectDesc;
            convertedExpenses.push(aggregateRow);
          }
        }
      });
    }

    // aggiunge una riga vuota per il problema dell'allineamento quando esistono righe raggruppate per numero documento
    // quindi una riga che contiene più righe con una innerTable, per questa riga verrà poi impostata la classe 'document-hidden-row'
    // che mette l'altezza a 0px in modo da non mostrare la riga
    let emptyRow = convertedExpenses.find((_, index) => index === 0);
    if (emptyRow) {
      emptyRow = {...emptyRow, id: -1, check: -1, docNum: -1, childrenRows: null};
      convertedExpenses = [emptyRow, ...convertedExpenses];
    }

    setLocalRows(convertedExpenses);
    // } else {
    //   setLocalRows(expenses);
    // }
  }, [expenses]);

  const onSortChanged = (orderBy: string, orderDir: 'asc' | 'desc') => {
    setOrder({orderBy, orderDir});
  }

  const getChildren = (expenses: ExpNoteExpense[], docNum: number) => {
    const firstRowId = expenses[0].id;

    const orderedDates: Date[] = expenses
      .map(e => e.compDate)
      .sort((d1, d2) => moment(d2).diff(d1));

    const totalAmount = expenses
      .map(e => e.currAmount)
      .reduce((sum, amount) => sum += amount);

    let groupedRows = expenses.map(r => ({...r, hideAggregate: true}));

    const firstRow = expenses.find(r => r.id === firstRowId);
    if (firstRow) {
      const heading = {...firstRow, id: -10 * docNum, hideAggregate: false};
      heading[Columns.EXPENSE_DESC] = '';
      heading[Columns.COMP_DATE] = orderedDates[0];
      heading[Columns.CURR_AMOUNT] = totalAmount;
      groupedRows = [heading, ...groupedRows];
    }

    return <ZtsTable
      showHeader={false}
      columns={columns}
      rows={groupedRows}
      rowClickHandler={() => {
      }}
    />
  }

  return (
    <>
      <ZtsTable
        forcedSort={order}
        extraClassName={new Map().set(-1, 'document-hidden-row')}
        notifySortChanged={onSortChanged}
        columns={columns}
        rows={localRows}
        rowClickHandler={openExpDetailHandler}
      />
      {localRows.length === 0 && (
        <EmptyStateExpNote
          message={t("expense.list.emptyList")}
        />
      )}
    </>
  );
}
