// React
import React, {useState} from "react";

// Libs
import { Logger } from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// MUI


import {TravelPolicyWithExpenses} from "../model";
import {Expense} from "../../expenses/model";
import {CommonDialog} from "../../base/common-dialog";
import {ExpensesGrid} from "../../expenses/commons/ExpensesGrid";
import {ExpenseCardActionEvent, ExpenseCardActionHandler} from "../../expenses/commons/ExpenseCard";
import {NewExpenseCardActionEvent, NewExpenseCardActionHandler} from "../../expenses/commons/NewExpenseCard";
import {LicensePopup} from "../../license-popup";
import {PopupContentType} from "../../license-popup/model";
import {useTranslation} from "react-i18next";


export type TravelPolicyAvailableExpenseModalActionType = 'select' | 'edit' | 'create' | 'close' | 'delete';

export type ExpenseActionEvent = {
  action: TravelPolicyAvailableExpenseModalActionType
  expense?: Partial<Expense>
}

export type ExpenseActionHandler = (event: ExpenseActionEvent) => void;


type TravelPolicyAvailableExpensesModalProps = {
  show: boolean
  travelPolicy: TravelPolicyWithExpenses
  expenses: Expense[]
  proLicense: boolean
  onAction: ExpenseActionHandler
}

export const TravelPolicyAvailableExpensesModal: React.FC<TravelPolicyAvailableExpensesModalProps> = ({
  show,
  travelPolicy,
  expenses,
  proLicense,
  onAction
}) => {

  const LOG: Slf4jsLog = Logger.of('ZTS.TravelPolicies.TravelPolicyAvailableExpensesModal');

  const {t: tLicense} = useTranslation('license', {keyPrefix: 'pro.menu'});
  const {t: tCommon} = useTranslation('travel-policy', {keyPrefix: 'common.label'});
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const openPopup = () => {
    setPopupOpen(true);
  }
  const closePopup = () => {
    setPopupOpen(false);
  }

  const onlyNotUsedFilter = (exp: Expense): boolean => {
    return !travelPolicy.expenses.some(e => e.expenseId===exp.id);
  }

  const availableExp = expenses.filter(onlyNotUsedFilter);

  const onExpenseAction: ExpenseCardActionHandler = (event: ExpenseCardActionEvent) => {
    LOG.trace('onExpenseAction', event);
    //ExpenseActionType = 'selected' | 'edit' | 'create';
    let action: TravelPolicyAvailableExpenseModalActionType = 'select';
    switch (event.action) {
      case 'select':
        action = 'select';
        break;
      case 'open':
        // TODO
        LOG.error('TO DO');
        break;
      case 'edit':
        action = 'edit';
        break;
      case 'delete':
        action = 'delete';
        break;
      default:
        throw new Error('Unexpected action: ' + event.action);
    }
    const myEvent: ExpenseActionEvent = {
      action: action,
      expense: event.expense
    }
    onAction(myEvent);
  }

  const onNewExpenseAction: NewExpenseCardActionHandler = (event: NewExpenseCardActionEvent) => {
    LOG.trace('onNewExpenseAction', event);

    if (proLicense) {
      const partial: Partial<Expense> = {
        expenseType: event.expenseType
      };
      const myEvent: ExpenseActionEvent = {
        action: 'create',
        expense: partial
      }
      onAction(myEvent);
    } else {
      openPopup();
    }

  }

  const onModalClosed = () => {
    onAction({action:'close'});
  }

  const titleLbl = tCommon('availableExp');

  return (
    <>
      <CommonDialog
        show={show}
        title={titleLbl}
        widths={[
          {breakpoint: "lg", width: "600px"},
        ]}
        onClose={onModalClosed}
        errorMsg={null}
        saving={false}
        hideSave={true}
      >
        <>
          <ExpensesGrid
            premium={proLicense}
            editable={true}
            expenseType='PL'
            expenses={availableExp}
            onExpense={onExpenseAction}
            onNewExpense={onNewExpenseAction}
          />
          <ExpensesGrid
            premium={proLicense}
            editable={true}
            sx={{marginTop:'10px'}}
            expenseType='TR'
            expenses={availableExp}
            onExpense={onExpenseAction}
            onNewExpense={onNewExpenseAction}
          />
          <ExpensesGrid
            premium={proLicense}
            editable={true}
            sx={{marginTop:'10px'}}
            expenseType='IF'
            expenses={availableExp}
            onExpense={onExpenseAction}
            onNewExpense={onNewExpenseAction}
          />
          <LicensePopup open={popupOpen} isPro={proLicense} closePopup={closePopup}
                        titlePro={tLicense(`${PopupContentType.NEW_EXPENSE_BUTTON.toString()}.title`)}
                        type={PopupContentType.NEW_EXPENSE_BUTTON}/>
        </>
      </CommonDialog>

    </>
  );
}
