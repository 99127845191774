import {Box, Stack, Step, StepLabel, Stepper, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLoggedUser} from "../../../../../hooks/useLoggedUser";
import {formatDate} from "../../../../../util/DateUtil";
import {ZtsTableColumn} from "../../../../base/table/model";
import {getColumnLabelByArrayIndex} from "../../Service";
import {ZtsTable} from "../../../../base/table";
import {ColumnPosition} from "../../model";
import {Loader} from "../../../../base/loader";

interface ComponentsProps {
  open: boolean,
  initialColumns: ZtsTableColumn[],
  initialRows: object[],
  columnsPositions: ColumnPosition,
  previewHeader: JSX.Element | undefined
}

export const ImportConfigurerFourthTab = ({open, initialColumns, initialRows, columnsPositions, previewHeader}: ComponentsProps) => {
  const {t} = useTranslation('cre-card-mov', {keyPrefix: 'configurer'});
  const {userLocale} = useLoggedUser();

  const [loading, setLoading] = useState<boolean>(false);

  const [columns, setColumns] = useState<ZtsTableColumn[]>([]);
  const [rows, setRows] = useState<object[]>([]);
  const [selectedRow, setSelectedRow] = useState<object>();

  const [creCardNum, setCreCardNum] = useState<string | undefined>();
  const [payDate, setPayDate] = useState<string | undefined>();
  const [compAmount, setCompAmount] = useState<string | undefined>();
  const [currency, setCurrency] = useState<string | undefined>();
  const [currAmount, setCurrAmount] = useState<string | undefined>();
  const [exchange, setExchange] = useState<string | undefined>();

  useEffect(() => {
    setLoading(true);
    setColumns(initialColumns);
    const r = initialRows.map((row, index) => {
      return {...row, check: index}
    });
    setRows(r);
    setSelectedRow(r[0]);

    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading) {
      Object.keys(columnsPositions).forEach(key => console.log(key, getColumnLabelByArrayIndex(columnsPositions[key].index)))
      if (columnsPositions.creCardNumColumn.index !== undefined) {
        setCreCardNum(getColumnLabelByArrayIndex(columnsPositions.creCardNumColumn.index));
      }
      if (columnsPositions.payDateColumn.index !== undefined) {
        setPayDate(getColumnLabelByArrayIndex(columnsPositions.payDateColumn.index));
      }

      if (columnsPositions.compAmountColumn.index !== undefined) {
        setCompAmount(getColumnLabelByArrayIndex(columnsPositions.compAmountColumn.index))
      }
      if (columnsPositions.currencyColumn.index !== undefined) {
        setCurrency(getColumnLabelByArrayIndex(columnsPositions.currencyColumn.index))
      }
      if (columnsPositions.currAmountColumn.index !== undefined) {
        setCurrAmount(getColumnLabelByArrayIndex(columnsPositions.currAmountColumn.index))
      }
      if (columnsPositions.exchangeColumn.index !== undefined) {
        setExchange(getColumnLabelByArrayIndex(columnsPositions.exchangeColumn.index));
      }
    }
  }, [loading]);

  const onRowSelected = (id: number) => {
    setSelectedRow(rows[id]);
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      overflow={'hidden'}
      maxHeight={'65vh'}
    >
      <Loader show={loading}/>

      <Stepper activeStep={2}>
        <Step key={1}>
          <StepLabel className={"stepLabel"}>Generale</StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel className={"stepLabel"}>Dati</StepLabel>
        </Step>
        <Step key={3}>
          <StepLabel className={"stepLabel"}>Conferma</StepLabel>
        </Step>
      </Stepper>

      <Typography fontWeight={500} color={'#3315d6'} mt={2}>{t('tabs.2.labels.2')}</Typography>

      <Box
        maxHeight={'40%'}
        overflow={'auto'}
        flexShrink={0}
        mb={2}
        className={'importPreviewTableContainer'}
      >
        <ZtsTable columns={columns} rows={rows} rowClickHandler={onRowSelected} additionalHeader={previewHeader}/>
      </Box>

      <Box display='flex' overflow={'hidden'} flexGrow={1} flexDirection={'row'}>
        <Box display='flex' flexDirection={'column'} width={'50%'} overflow={'auto'} mt={3.2} p={2}>
          {
            selectedRow &&
            <Stack overflow={'auto'} width={'100%'}>

              <Box display={'flex'}>
                <Typography fontWeight={500}>{t('tabs.4.labels.creCardNumColumn')}</Typography>
                <Box flexGrow={1}/>
                {
                  creCardNum &&
                  <Typography>{selectedRow[creCardNum]}</Typography>
                }
              </Box>

              <Box display={'flex'}>
                <Typography fontWeight={500}>{t('tabs.4.labels.payDateColumn')}</Typography>
                <Box flexGrow={1}/>
                {
                  payDate &&
                  <Typography>{formatDate(new Date(Date.parse(selectedRow[payDate])), userLocale)}</Typography>
                }
              </Box>

              <Box display={'flex'}>
                <Typography fontWeight={500}>{t('tabs.4.labels.compAmountColumn')}</Typography>
                <Box flexGrow={1}/>
                {
                  compAmount &&
                  <Typography>{selectedRow[compAmount]}</Typography>
                }
              </Box>

              <Box display={'flex'}>
                <Typography fontWeight={500}>{t('tabs.4.labels.currencyColumn')}</Typography>
                <Box flexGrow={1}/>
                {
                  currency &&
                  <Typography>{selectedRow[currency]}</Typography>
                }
              </Box>

              <Box display={'flex'}>
                <Typography fontWeight={500}>{t('tabs.4.labels.exchangeColumn')}</Typography>
                <Box flexGrow={1}/>
                {
                  exchange &&
                  <Typography>{selectedRow[exchange]}</Typography>
                }
              </Box>

              <Box display={'flex'}>
                <Typography fontWeight={500}>{t('tabs.4.labels.currAmountColumn')}</Typography>
                <Box flexGrow={1}/>
                {
                  currAmount &&
                  <Typography>{selectedRow[currAmount]}</Typography>
                }
              </Box>

            </Stack>
          }
        </Box>


        <Box display='flex' overflow={'auto'} width={'50%'} flexDirection={'column'} p={2}>
          <Typography fontWeight={500} color={'#3315d6'}>Passo 3</Typography>
          <Typography>{t('tabs.4.labels.10')}</Typography>
          <Typography>{t('tabs.4.labels.11')}</Typography>
        </Box>

      </Box>

    </Box>
  );
}
