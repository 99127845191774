import React, {useEffect, useState} from "react";

import SimpleJsLog from "../../util/Logger";
import {Logger} from "react-logger-lib";
import {useNavigate, useSearchParams} from "react-router-dom";
import {TenantCredentials, UserLoginResponse} from "../login/model";
import {ImpersonateLoginResponse, impersonateUser} from "./ImpersonateService";
import {getUserByToken, getZtsJwtPayload, saveLoginPortalJwt, saveToken} from "../../config/token";
import {saveApiUrl} from "../../config/environment";
import {useLicense} from "../../hooks/useLicense";
import {useLoggedUser} from "../../hooks/useLoggedUser";

const LOG: SimpleJsLog = Logger.of('ZTS.impersonate.Impersonate');

type ImpersonatePageState = 'loading' | 'invalidUrl' | 'invalidToken';

export const Impersonate: React.FC = () => {

  const [searchParams] = useSearchParams();

  const [pageState, setPageState] = useState<ImpersonatePageState>('loading');
  const [nextUrl, setNextUrl] = useState<string|null>(null);

  const {setProfessional, clearProfessional} = useLicense();

  const {
    setUserByImpersonate
  } = useLoggedUser();

  const navigate = useNavigate();

  const loginByToken = async (token: string): Promise<void> => {
    const loginResp: ImpersonateLoginResponse|null = await impersonateUser(token);
    if (loginResp===null){
      setPageState('invalidToken');
      return;
    }

    // Salvo il token jwt per il portale di login
    saveLoginPortalJwt(loginResp.loginCredentials.accessToken);
    const list: TenantCredentials[] = loginResp.tenantsCredentials as TenantCredentials[];
    const tc = list[0];

    const apiUrl = tc.url + '/api';
    saveApiUrl(apiUrl);
    saveToken(tc.accessToken);

    const jwtBody = getZtsJwtPayload();
    if (jwtBody !== null && jwtBody.lic === 'pro') {
      setProfessional();
    } else {
      clearProfessional();
    }

    getUserByToken(tc.accessToken).then(
      user => {
        setUserByImpersonate(user, loginResp.adminUser);
        setNextUrl('/');
      }
    );


  }

  useEffect(()=>{
    LOG.trace('useEffect [nextUrl]', nextUrl);
    if (nextUrl){
      LOG.trace('Going to ... ', nextUrl);
      navigate(nextUrl);
    }
  }, [nextUrl]);

  useEffect(()=>{
    LOG.trace('useEffect [searchParams]', searchParams);
    const tokenStr = searchParams.get('token');
    if (tokenStr===null || tokenStr.length!==32) {
      setPageState('invalidUrl');
      return;
    }
    setPageState('loading');
    loginByToken(tokenStr)
      .then(()=>LOG.trace('loginByToken promise success'))
  }, [searchParams]);

  return (
    <>
      {pageState==='loading' && <div>Loading ...</div> }
      {pageState==='invalidUrl' && <div>Invalid URL!!!</div>}
      {pageState==='invalidToken' && <div>Token is expired</div>}
    </>
  );

}

