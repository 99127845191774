import {useExpNoteDetail} from "../../hooks/useExpNoteDetail";
import Box from "@mui/material/Box";
import {ExpNotePaymentList} from "./list";
import {ZtsTableColumn} from "../../../../base/table/model";
import {GreyColor} from "../../../admin/list/model";
import {formatNumber} from "../../../../../util/NumberUtil";
import {Currency, ExchangeDecimalNum} from "../../../../model";
import {Button, Typography} from "@mui/material";
import React, {useState} from "react";
import {ExpNotePayment, getPaymentCausalDesc, PaymentCausal} from "../../../model";
import {Add} from "@mui/icons-material";
import {getExpNote, getExpNoteExpenses, getExpNotePayments} from "../../../Service";
import {useExpNoteList} from "../../../hooks/useExpNoteList";
import {isReadonlyExpNote} from "../../model";
import {ExpNotePaymentDetail} from "./detail";
import {useLoggedUser} from "../../../../../hooks/useLoggedUser";
import {formatDate} from "../../../../../util/DateUtil";
import {useTranslation} from "react-i18next";

export enum Columns {
  CAUSAL_DESC = 'causal',
  OPER_DATE = 'operDate',
  CURR_AMOUNT = 'currAmount',
  CURRENCY = 'currencyCode',
  EXCHANGE = 'exchange',
  COMP_AMOUNT = 'compAmount'
}

type ComponentProps = {
  currencies: Currency[]
}

export const ExpNotePayments = ({currencies}: ComponentProps) => {

  const {
    expNote,
    payments,
    updatePayments
  } = useExpNoteDetail();

  const {
    currentRole
  } = useExpNoteList();

  const {userLocale: locale, companyCurrencyCode, companyDecimalNum} = useLoggedUser();

  const {t} = useTranslation("exp-note", {keyPrefix: "payment"});
  const {t: tCommon} = useTranslation("common");

  const columns: ZtsTableColumn[] = [];

  columns.push(
    {
      id: Columns.CAUSAL_DESC,
      label: t("causal.label"),
      style: {width: '160px'},
      hideOnAggregate: true,
      formatter: (causal: PaymentCausal) => getPaymentCausalDesc(t, causal)
    },
    {
      id: Columns.OPER_DATE,
      label: tCommon("date"),
      style: {width: '80px', maxWidth: '80px'},
      typography: 'caption',
      cellColor: GreyColor,
      hideOnAggregate: true,
      formatter: (value) => formatDate(value, locale)
    },
    {
      id: Columns.CURR_AMOUNT,
      label: tCommon("amount"),
      align: 'right',
      style: {width: '100px', maxWidth: '100px'},
      formatter: (amount, row) => {
        const currency = currencies.find(curr => curr.code === row.currencyCode);
        return formatNumber(amount, locale, currency ? currency.decimalNum : companyDecimalNum)
      },
    },
    {
      id: Columns.CURRENCY,
      label: tCommon("currency"),
      style: {width: '70px', maxWidth: '70px'},
      typography: 'caption',
      cellColor: GreyColor,
    },
    {
      id: Columns.EXCHANGE,
      label: tCommon("exchange"),
      align: 'right',
      style: {width: '100px', maxWidth: '100px'},
      formatter: (amount) => formatNumber(amount, locale, ExchangeDecimalNum),
    },
    {
      id: Columns.COMP_AMOUNT,
      label: companyCurrencyCode,
      align: 'right',
      style: {width: '100px', maxWidth: '100px'},
      formatter: (amount) => formatNumber(amount, locale, companyDecimalNum),
    },
    {
      id: 'cta',
      label: '',
      unsortable: true,
      displayOnHover: () => true,
      align: 'right',
      style: {width: '100px', maxWidth: '100px'},
      hideOnAggregate: true,
      formatter: () => {
        return <Button
          variant="contained"
          className={"cta-list-button"}>
          <Typography variant={"button"}>{t("list.details")}</Typography>
        </Button>
      }
    }
  );

  const [createNewPayment, setCreateNewPayment] = useState(false);
  const [detailPayment, setDetailPayment] = useState<ExpNotePayment | null>(null);

  const isReadonly = isReadonlyExpNote(expNote, currentRole);

  const openPayDetailHandler = (id: number) => {
    if (payments) {
      const payment = payments.find(p => p.id === id);
      setDetailPayment(payment ? payment : null);
    }
  }

  const handleNewPaymentClicked = () => {
    setCreateNewPayment(true);
  }

  const handleClosePaymentDetail = async (updated: boolean) => {
    setDetailPayment(null);
    setCreateNewPayment(false);
    if (updated && expNote) {
      const result = await Promise.all([
        getExpNote(expNote.id),
        getExpNoteExpenses(expNote.id),
        getExpNotePayments(expNote.id)
      ]);
      //TODO: da valutare: è possibile (in certi casi) evitare di aggiornare le spese??
      updatePayments(result[0], result[2], result[1]);
    }
  }

  return (
    <>

      <Box mx={2}>

        {!isReadonly && (
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              className={"exp-note-detail-add-item-button"}
              onClick={handleNewPaymentClicked}
              sx={{
                maxWidth: "175px"
              }}
              startIcon={
                <Add fontSize={"small"}/>
              }
            >
              {t("list.addItem")}
            </Button>
          </Box>
        )}

        <Box mt={3}>
          <ExpNotePaymentList
            columns={columns}
            payments={payments ? payments.map(p => ({...p, check: p.id})) : []}
            openPayDetailHandler={openPayDetailHandler}
          />
        </Box>

      </Box>

      <ExpNotePaymentDetail
        show={!!detailPayment || createNewPayment}
        onClose={handleClosePaymentDetail}
        payment={detailPayment}
        create={createNewPayment}
        isReadonly={isReadonly}
      />

    </>
  );
}
