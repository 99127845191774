import React, {useEffect, useState} from "react";
import {useParams} from "react-router";

// Libs
import {Logger} from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// Custom
import {TravelPolicy, TravelPolicyExpense, TravelPolicyHeader, TravelPolicyWithExpenses} from "../model";
import {
  deleteTravelPolicyExpense,
  getTravelPolicyWithExpensesById,
  isExpenseItemUsedInExpenses,
  loadTravelPolicyExpenseById
} from "../Service";
import {Alert, Box, Paper, Stack, Typography} from "@mui/material";
import {Loader} from "../../base/loader";
import {TravelPolicyEditSkeleton} from "../commons/TravelPolicyEditSkeleton";
import {TravelPolicyEditForm, TravelPolicyUpdateActionHandler} from "./TravelPolicyEditForm";
import {activeStateCode} from "../../model";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {TravelPolicyExpenses} from "../commons/TravelPolicyExpenses";
import {
  TravelPolicyExpenseCardActionEvent,
  TravelPolicyExpenseCardActionHandler,
  TravelPolicyExpenseCardActionType
} from "../commons/TravelPolicyExpenseCard";
import {
  MinTravelPolicyExpense,
  TravelPolicyExpenseModal,
  TravelPolicyExpenseModalClosedEvent,
  TravelPolicyExpenseModalClosedHandler,
  TravelPolicyExpenseModalClosedModeType
} from "./TravelPolicyExpenseModal";
import {
  deleteExpenseItem,
  getActiveExpenses,
  getTravelPoliciesUsingExpense,
  loadExpenseById
} from "../../expenses/Service";
import {Expense} from "../../expenses/model";
import {ExpenseModal, ExpenseModalActionType, ExpenseModalEvent} from "../../expenses/edit/ExpenseModal";
import {
  ExpenseActionEvent,
  ExpenseActionHandler,
  TravelPolicyAvailableExpensesModal
} from "../commons/TravelPolicyAvailableExpensesModal";
import {useNavigate} from "react-router-dom";
import {useLicense} from "../../../hooks/useLicense";
import {useAlert} from "../../../hooks/useAlert";
import {FiberManualRecord} from "@mui/icons-material";
import {useTranslation} from "react-i18next";


export type TravelPolicyExpenseActionHandler = (event: TravelPolicyExpenseActionEvent) => void;

export type TravelPolicyExpenseActionType = 'created' | 'updated' | 'deleted';

export type TravelPolicyExpenseActionEvent = {
  action: TravelPolicyExpenseActionType,
  travelPolicyId: number,
  updatedVersion: number, // Il prossimo lastupdate num sulla travel policy
  expense: TravelPolicyExpense // la singola spesa che ha subito l'azione
}


export const TravelPolicyEdit: React.FC = () => {

  const LOG: Slf4jsLog = Logger.of('ZTS.TravelPolicies.TravelPolicyEdit');

  const {id} = useParams();

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [travelPolicy, setTravelPolicy] = useState<TravelPolicyWithExpenses | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [selectedTravelPolicyExpense, setSelectedTravelPolicyExpense] = useState<TravelPolicyExpense | MinTravelPolicyExpense | null>(null);
  const [selectedExpense, setSelectedExpense] = useState<Partial<Expense>|null>(null);

  const [expenseList, setExpenseList] = useState<Expense[]>([]);
  const [showExpensesModal, setShowExpensesModal] = useState<boolean>(false);


  const [saving, setSaving] = useState<boolean>(false);

  const {convertError} = useErrorMessage();
  const {t} = useTranslation('travel-policy', {keyPrefix: 'edit'});

  const {
    alert,
    closeAlert
  } = useAlert();

  const emptyTravelPolicy = ():TravelPolicyWithExpenses => {
    return {
      id: -1,
      code: '',
      description: '',
      startDate: new Date(),
      endDate: null,
      foreignCurrEnb: false,
      projectEnb: false,
      state: activeStateCode,
      lastUpdNum: 0,
      expenses: []
    };
  }

  const onTravelPolicyUpdate: TravelPolicyUpdateActionHandler = (tp: TravelPolicy): void => {
    LOG.trace('onTravelPolicyUpdate', tp);
    let expList: TravelPolicyExpense[] = [];
    if (travelPolicy) {
      expList = travelPolicy.expenses;
    }
    const newTp: TravelPolicyWithExpenses = {
      ...tp,
      expenses: expList
    }
    setTravelPolicy(newTp);
  }

  const onExpenseModalAction = (event: ExpenseModalEvent) => {
    LOG.trace('onExpenseModalAction', event);
    setSelectedExpense(null);
    const action: ExpenseModalActionType = event.action;
    if (action==='closed') {
      // L'utente ha chiuso la modale senza salvare
      return;
    }

    // L'utente ha fatto delle modifiche
    const modalExp: Expense = event.expense as Expense;
    let newExpList: Expense[] = expenseList.slice(0);
    if (action==='created') {
      // Nuova voce
      newExpList.push(modalExp);
    } else {
      // Sostituisco la voce precedente
      const idx = expenseList.findIndex(e => e.id === modalExp.id);
      newExpList[idx] = modalExp;
    }
    setExpenseList(newExpList);

    if (travelPolicy===null) {
      throw new Error('Impossible');
    }

    // Attenzione se la voce è usata nella travel policy devo ricaricare l'elemento usato
    const usedTravelPolicyExp = travelPolicy.expenses.find(ex => ex.expenseId===modalExp.id);
    if (usedTravelPolicyExp!==undefined) {
      updateTravelPolicyExpenseById(usedTravelPolicyExp.id)
        .then(()=>LOG.trace('updateTravelPolicyExpenseById end.'));
    }
  }

  const updateTravelPolicyExpenseById = async (travelPolicyExpenseId: number): Promise<void> => {
    LOG.trace('updateTravelPolicyExpenseById [async]', travelPolicyExpenseId);
    if (travelPolicy===null) {
      throw new Error('Impossible');
    }
    try{
      const exp: TravelPolicyExpense = await loadTravelPolicyExpenseById(travelPolicyExpenseId, travelPolicy.id);
      const updEvent: TravelPolicyExpenseActionEvent = {
        action: 'updated',
        travelPolicyId: travelPolicy.id,
        updatedVersion: travelPolicy.lastUpdNum,
        expense: exp
      }
      onTravelPolicyExpenseAction(updEvent);
    } catch (e) {
      LOG.trace('Errore', e);
      await showError(e as Error);
    }
  }

  const createNewTravelPolicyExpense = (exp: Expense) => {
    LOG.trace('createNewTravelPolicyExpense', exp);
    // al momento solo select
    const minTpExp: MinTravelPolicyExpense = {
      // Collegamento alla spesa
      expenseId: exp.id,
      expenseCode: exp.code,
      expenseDescription: exp.description,
      expenseIcon: exp.expenseIcon,
      expenseCategory: exp.expenseCategory,
      expenseType: exp.expenseType,
      measureUnit: exp.measureUnit,
      compPeriod: exp.compPeriod
    }
    setSelectedTravelPolicyExpense(minTpExp);
  }

  const onTravelPolicyExpenseAction: TravelPolicyExpenseActionHandler = (event: TravelPolicyExpenseActionEvent) => {
    LOG.trace('onTravelPolicyExpenseAction', event);

    // La travelPolicy è stata modificata in qualche modo
    const newTp: TravelPolicyWithExpenses = {
      ...travelPolicy
    } as TravelPolicyWithExpenses

    let newValues: TravelPolicyExpense[];
    const newExp: TravelPolicyExpense = event.expense as TravelPolicyExpense;

    const action: TravelPolicyExpenseActionType = event.action;

    switch (action) {
      case 'created':
        newValues= newTp.expenses.slice(0);
        newValues.push(newExp);
        break;
      case 'updated':
        newValues= newTp.expenses.slice(0);
        let idx = newValues.findIndex(v => v.id === newExp.id);
        newValues[idx]=newExp;
        break;
      case 'deleted':
        newValues = newTp.expenses.filter(v => v.id !== newExp.id);
        break;
      default:
        throw new Error('Unexpected action: '+action);
    }

    newTp.lastUpdNum = event.updatedVersion;
    newTp.expenses = newValues;
    setTravelPolicy(newTp);
  }



  const showError = async (e: Error | null) => {
    LOG.trace('showError', e);
    if (e===null) {
      setErrorMsg(null);
    } else {
      const msg = await convertError(e as Error);
      setErrorMsg(msg);
    }
  }

  const showLoader = (flag: boolean) => {
    LOG.trace('showLoader', flag);
    setSaving(flag);
  }

  const onExpenseCardAction: TravelPolicyExpenseCardActionHandler = async (event: TravelPolicyExpenseCardActionEvent) => {
    LOG.trace('onExpenseCardAction', event);
    const action: TravelPolicyExpenseCardActionType = event.action;
    switch (action) {
      case 'edit':
        setSelectedTravelPolicyExpense(event.expense);
        break;
      case 'open':
        //TODO ???
        break;
      case 'delete':
        let isUsed = true;
        if (id) {
          try {
            const resp = await isExpenseItemUsedInExpenses(+id, event.expense.id);
            isUsed = resp.used;
          } catch (err) {
            LOG.error(`Error reading expense used. tpId: ${id}, expId: ${event.expense.id}`);
          }
        }

        if (isUsed) {
          alert({
            body: (
              <Typography variant="body1" display="inline" mr={0.5}>
                {t('message1')}
              </Typography>
            ),
            onClick: closeAlert
          });
        } else {
          deleteExpense(event.expense)
            .then(()=>LOG.trace('deleteExpense end.'));
        }
        break;
      case 'editExpense':
        editExpenseById(event.expense.expenseId)
          .then(()=>LOG.trace('editExpense end.'))
        break;
      case 'create':
        associateNewTravelPolicyExpense()
          .then( () => LOG.trace('associateNewTravelPolicyExpense end.'));
        break;
      default:
        throw new Error('Unexpected action: ' + action);
    }
  }

  const associateNewTravelPolicyExpense = async () => {
    LOG.trace('associateNewTravelPolicyExpense [async]')
    // Se la lista delle spese possibili non è stata caricata la carico
    if (expenseList.length===0){
      try {
        // Caricare la lista di tutte le Spese disponibili
        const list: Expense[] = await getActiveExpenses();
        setExpenseList(list);
      } catch (e) {
        await showError(e as Error);
        return;
      }
    }
    setShowExpensesModal(true);
  }

  const editExpenseById = async (expenseId: number): Promise<void> => {
    LOG.trace('editExpense [async]', expenseId);
    try {
      const exp = await loadExpenseById(expenseId);
      setSelectedExpense(exp);
    } catch(e) {
      await showError(e as Error);
    }
  }

  const deleteExpense = async (exp: TravelPolicyExpense) => {
    LOG.trace('deleteExpense [async]', exp);
    if (travelPolicy===null) {
      throw new Error('Impossible');
    }
    try {
      const tpH: TravelPolicyHeader = {
        travelPolicyId: travelPolicy.id,
        lastUpdNum: travelPolicy.lastUpdNum
      }
      await deleteTravelPolicyExpense(exp.id, tpH);
      const myEvent: TravelPolicyExpenseActionEvent = {
        action: 'deleted',
        travelPolicyId: travelPolicy.id,
        updatedVersion: travelPolicy.lastUpdNum + 1,
        expense: exp
      }
      onTravelPolicyExpenseAction(myEvent);
    } catch(e) {
      await showError(e as Error);
    }
  }

  const onTravelPolicyExpenseModalClose: TravelPolicyExpenseModalClosedHandler = (event: TravelPolicyExpenseModalClosedEvent) => {
    LOG.trace('onTravelPolicyExpenseModalClose', event);
    setSelectedTravelPolicyExpense(null);
    const action: TravelPolicyExpenseModalClosedModeType = event.action;
    if (action==='closed') {
      // Utente ha selezionato annulla
      return;
    }
    setShowExpensesModal(false); // chiudo la modale se è aperta
    let myAction: TravelPolicyExpenseActionType = action as TravelPolicyExpenseActionType;
    if (travelPolicy===null) {
      throw new Error('Impossible');
    }
    const myEvent: TravelPolicyExpenseActionEvent = {
      action: myAction,
      travelPolicyId: travelPolicy.id,
      updatedVersion: travelPolicy.lastUpdNum + 1,
      expense: event.expense as TravelPolicyExpense
    }
    onTravelPolicyExpenseAction(myEvent);
  }

  const onExpenseAction: ExpenseActionHandler = async (event: ExpenseActionEvent) => {
    LOG.trace('onExpenseAction', event);
    const action = event.action;
    switch (event.action) {
      case 'select':
        createNewTravelPolicyExpense(event.expense as Expense);
        break;
      case 'edit':
      case 'create':
        if (event.expense) {
          setSelectedExpense(event.expense);
        }
        break;
      case 'close':
        setShowExpensesModal(false);
        break;
      case 'delete':
        if (event.expense && event.expense.id) {
          let travelPolicies: TravelPolicy[] = [];
          try {
            travelPolicies = await getTravelPoliciesUsingExpense(event.expense.id);
          } catch (err) {
            LOG.error(`Error reading travelPolicies using expense. expId: ${event.expense.id}`);
          }

          if (travelPolicies && travelPolicies.length > 0) {
            alert({
              body: (
                <Typography variant="body1" display="inline" mr={0.5}>
                  {t('message2')}
                  {travelPolicies.map(tp => (
                    <Stack
                      direction="row"
                      ml={1.5}
                      columnGap={1}
                      alignItems="center"
                    >
                      <FiberManualRecord fontSize="small" sx={{fontSize: "9px"}} />
                      {tp.description}
                    </Stack>
                  ))}
                </Typography>
              ),
              onClick: closeAlert
            });
          } else {
            try {
              await deleteExpenseItem(+event.expense.id);
              // @ts-ignore
              setExpenseList(list => list.filter(exp => exp.id !== event.expense.id));
            } catch (err) {
              const msg = await convertError(err as Error);
              setErrorMsg(msg);
            }
          }
        }

        break;
      default:
        throw new Error('Unexpected action: ' + action);
    }
  }

  const navigate = useNavigate();

  useEffect(() => {
    LOG.trace('useEffect [id]', id);

    const asyncInt = async (travelPolicyId: number): Promise<void> => {
      LOG.trace('asyncInt [async]', travelPolicyId);
      try{
        const tp = await getTravelPolicyWithExpensesById(travelPolicyId);
        if (tp.state==='AA'){
          LOG.error('Unable to edit disabled travel policy');
          navigate(`../detail/${id}`);
        }
        setTravelPolicy(tp);
      } catch (e) {
        LOG.error('Error retrieving data', e);
        await showError(e as Error);
      } finally {
        setLoadingData(false);
      }
    }

    let parsedId = parseInt(id || '');
    if (isNaN(parsedId)) {
      parsedId = -1;
    }
    if (parsedId===-1 ) {
      const tp = emptyTravelPolicy();
      setTravelPolicy(tp);
      setLoadingData(false);
    } else {
      asyncInt(parsedId)
        .then(()=>LOG.trace('asyncInt end.'));
    }
  }, [id]);

  return (
    <>
      <Box overflow="auto">
        <Paper elevation={0} style={{borderRadius: '15px', marginRight:'10px'}}>
          <Stack height='100%' overflow='auto' padding='20px 30px 20px 20px'>
            <Loader show={saving}/>

            {/* banner con errori */}
            {errorMsg && <Alert severity='error' onClose={() => setErrorMsg(null)}>{errorMsg}</Alert>}

            {/* Skeleton*/}
            {loadingData && <TravelPolicyEditSkeleton />}

            {!loadingData && travelPolicy &&
              <TravelPolicyEditForm
                travelPolicy={travelPolicy}
                onTravelPolicyUpdate={onTravelPolicyUpdate}
                professional={!free}
                showError={showError}
                showLoader={showLoader}
              />
            }
          </Stack>
        </Paper>

        <Paper elevation={0} style={{marginTop: '25px', borderRadius: '15px', marginRight:'10px'}}>

          {!loadingData && travelPolicy &&
            <Box style={{margin: "20px"}}>
              <TravelPolicyExpenses
                editable={true}
                travelPolicy={travelPolicy}
                onCardAction={onExpenseCardAction}
              />
            </Box>
          }
        </Paper>

      </Box>
      <Loader show={saving}/>
      { selectedTravelPolicyExpense && travelPolicy &&
        <TravelPolicyExpenseModal
          travelPolicy={travelPolicy}
          expense={selectedTravelPolicyExpense}
          onClose={onTravelPolicyExpenseModalClose}
          editable={true}
        />
      }
      { selectedExpense && travelPolicy &&
        <ExpenseModal
          expense={selectedExpense}
          onAction={onExpenseModalAction}
        />
      }
      { travelPolicy && expenseList &&
        <TravelPolicyAvailableExpensesModal
          show={showExpensesModal}
          travelPolicy={travelPolicy}
          expenses={expenseList}
          proLicense={!free}
          onAction={onExpenseAction}
        />
      }
    </>

  );

}
