import React, {useEffect, useState} from "react";
import * as Yup from "yup";

import {activeStateCode, getStateCode} from "../../model";
import {Alert, Button, Paper, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {getLevelById, saveLevel} from "../Service";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {PageTitle} from "../../layout/page-title";
import {Loader} from "../../base/loader";
import {LevelEditLoader} from "./LevelEditLoader";
import {Level, SaveLevel} from "../model";
import {GeneralData} from "../detail/GeneralData";
import {Save} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

type LevelEditFormValues = {
  code: string;
  description: string;
}

export const LevelEdit = () => {
  const {t} = useTranslation('level', {keyPrefix: 'edit'});

  const [loadingData, setLoadingData] = useState(true);
  const [level, setLevel] = useState<Level | null>(null);
  const [active, setActive] = useState(true);
  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const {convertError} = useErrorMessage();


  let {id} = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setLoadingData(true);
      if (id === '-1') {
        setLevel({
          id: -1,
          code: '',
          description: '',
          lastUpdateNum: 0,
          state: activeStateCode
        });
        setLoadingData(false);
      } else {
        getLevelById(id)
          .then(u => setLevel(u))
          .catch(err => setErrorMsg(`Error retrieving data. ${err}`))
          .finally(() => setLoadingData(false));
      }
    }
  }, [id]);

  const validationSchema = Yup.object({
    code: Yup.string()
      .max(20, 'Must be 20 characters or less')
      .min(3, 'Must be at least 3 characters')
      .required('Required'),
    description: Yup.string()
      .max(50, 'Must be 50 characters or less')
      .min(3, 'Must be at least 3 characters')
      .required('Required')
  });

  const initVal = {
    code: level ? level.code : '',
    description: level ? level.description : '',
  };

  const formMethods = useForm<LevelEditFormValues>({
    defaultValues: {},
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  useEffect(() => {
    if (level) {
      formMethods.reset(initVal);
    }
  }, [level]);


  const handleSave = async (values) => {
    setErrorMsg(null);
    setSaving(true);

    // console.log(values)

    const updLevel: SaveLevel = {
      id: level && level.id !== -1 ? level.id : null,
      state: getStateCode(active),
      code: values.code,
      description: values.description,
      lastUpdateNum: level ? level.lastUpdateNum : 0
    };

    try {
      await saveLevel(updLevel);
      handleNavigateBack();
    } catch (error: any) {
      convertError(error).then(msg => setErrorMsg(msg));
    } finally {
      setSaving(false);
    }
  }

  const handleSaveClicked = () => {
    formMethods.handleSubmit(handleSave)();
  }

  const handleNavigateBack = () => {
    if (id === '-1') {
      navigate(`/levels`);
    } else {
      navigate(`/levels/detail/${id}`);
    }
  }

  return (
    <Box
      overflow={"hidden"}
      flexGrow={1}
    >

      <Paper
        elevation={0}
        sx={{
          height: "100%"
        }}
      >

        <Stack
          height={"100%"}
          overflow={"auto"}
          padding={"20px 30px 20px 20px"}
        >

          <Loader show={saving}/>

          {/* Skeleton */}
          {loadingData && <LevelEditLoader/>}

          {/* banner con errori */}
          {errorMsg && <Alert
            severity={"error"}
            onClose={() => setErrorMsg(null)}
          >{errorMsg}</Alert>}

          {/* titolo + pulsanti */}
          {level && <PageTitle
            backClickHandler={handleNavigateBack}
            title={
              <Stack
                direction={{xs: "column", sm: "row"}}
                alignItems={{xs: "flex-start", sm: "center"}}
                justifyContent={{xs: "center", sm: "flex-start"}}
                flexGrow={"1"}
              >
                <Typography variant={"h3"} mr={3}>{t("titleNew")}</Typography>
              </Stack>
            }
          />}

          {/* body */}
          {level && <Box flexGrow={1}>
            <FormProvider {...formMethods}>
              <form noValidate>

                {/* parte sx */}
                <Stack
                  mt={3}
                  flexGrow={1}
                  direction={{
                    xs: "column-reverse",
                    lg: "row"
                  }}
                  columnGap={2}
                  width={"100%"}
                >

                  {/* Dati generali */}
                  <GeneralData
                    level={level}
                    enabled={true}
                  />

                </Stack>

              </form>
            </FormProvider>
          </Box>}

          {/* Pulsanti (Annulla, Salva) */}
          {level && <Stack
            alignSelf={"flex-end"}
            direction={"row"}
            mt={4}
            columnGap={2.2}
          >
            <Button
              variant="contained"
              className={"cta-button"}
              disabled={saving}
              onClick={() => {
                setErrorMsg(null);
                handleNavigateBack();
              }}>
              <Typography variant={"button"}>{t('button.cancel')}</Typography>
            </Button>
            <Button
              variant="contained"
              className={"cta-button-primary"}
              disabled={saving}
              onClick={() => {
                setErrorMsg(null);
                handleSaveClicked()
              }}
            >
              <Save sx={{fontSize: "1.1em"}}/>
              <Typography ml={1} variant={"button"}>{t('button.save')}</Typography>
            </Button>
          </Stack>}

        </Stack>
      </Paper>

    </Box>
  );
}
