import React, {Suspense} from 'react';
import './App.css';
import './assets/base.scss';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {Main} from "./components/layout/main";
import MuiTheme from "./theme";
import {setAuthInterceptor} from "./config/authInterceptor";
import configureStore from "./config/configureStore";
import {Provider} from 'react-redux';
import {SnackbarProvider} from "notistack";
import {AppLoading} from "./AppLoading";

import {
  initDevUrl,
  initLoggerLevel, initLoginMode
} from "./config/environment";

import moment from 'moment-timezone';
moment.tz.setDefault("Etc/UTC");


initLoggerLevel();
initLoginMode();
initDevUrl();



setAuthInterceptor();

const store = configureStore();

function App() {

  return (
    <Suspense fallback={<AppLoading/>}>
      <Provider store={store}>
        <CssBaseline/>
        <ThemeProvider theme={MuiTheme}>
          <SnackbarProvider>
            <Main/>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
