import {Stack} from "@mui/material";
import React, {ReactChild} from "react";
import IconButton from "@mui/material/IconButton";
import {ArrowBack} from "@mui/icons-material";
import Box from "@mui/material/Box";

type ComponentProps = {
  backClickHandler?: Function,
  title: ReactChild,
  actions?: ReactChild,
  flexTitle?: boolean
}

export const PageTitle = ({backClickHandler, title, actions, flexTitle}: ComponentProps) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      my={0.6}
    >
      {backClickHandler && <IconButton
        disableRipple={true}
        sx={{
          flexBasis: '40px',
          justifyContent: 'flex-start'
        }}
        size={"small"}
        onClick={() => backClickHandler()}
      >
        <ArrowBack fontSize={"small"} />
      </IconButton>}

      {flexTitle ? (
        <Box
          flexGrow={1}
          display="flex"
          overflow="hidden"
        >
          {title}
        </Box>
      ) : (
        <Box flexGrow={1}>
          {title}
        </Box>
      )}


      {actions && <Stack
        direction={"row"}
        ml={1}
        spacing={2}
      >
        {actions}
      </Stack>}
    </Stack>
  );
}
