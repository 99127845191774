import Box from "@mui/material/Box";
import React, {ReactChild} from "react";
import {Typography} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

export interface Tab {
  key: string,
  render: (isSelected: boolean) => ReactChild,
  isError?: boolean
}

export const getTabCounter = (count: number, isActive?: boolean) => {
  return <Box className={`tab-counter ${isActive ? "tab-selected" : ""}`} mr={1}>
    <Typography variant={"button"} fontSize="10px">{count > 99 ? "+" : ""}</Typography>
    <Typography variant={"button"}>{count > 99 ? "99" : count}</Typography>
  </Box>
}

export const getTabLabel = (label: string) => {
  return <Typography variant={"h5"} lineHeight={1}>
    {label}
  </Typography>
}

type ComponentProps = {
  selected: string,
  tabs: Tab[],
  onSelect: (key: string) => void,
  extraClassName?: string,
  notSelectableKeys?: string[]
}

export const TabBar = (props: ComponentProps) => {

  const {
    selected,
    tabs,
    onSelect,
    extraClassName,
    notSelectableKeys
  } = props;

  function tabRender(tab: Tab, isSelected: boolean): JSX.Element {
    return (
      <>
        {
          tab.isError &&
          <Box sx={{position: "absolute", top: "-4px", right: "-3px"}}>
            <ErrorIcon
              sx={{color: "red", height: "16px", width: "16px", backgroundColor: "white", borderRadius: "50%"}}
            />
          </Box>
        }
        {tab.render(isSelected)}
      </>
    );
  }

  return (
    <Box className={`tab-bar-selector ${extraClassName ? extraClassName : ''}`}>
      {tabs.map(tab => {
          const isSelected = selected === tab.key;
          const isSelectable = notSelectableKeys === undefined || notSelectableKeys.findIndex(k => k === tab.key) === -1;
          let className = "";
          if (!isSelectable) {
            className = "tab-not-selectable";
          } else if (isSelected) {
            className = "tab-selected";
          } else if (tab.isError) {
            className = "tab-error";
          }
          const clickFunc = isSelectable ? () => onSelect(tab.key) : () => {};
          return (
            <React.Fragment key={tab.key}>
              <Box
                onClick={clickFunc}
                className={className}
                sx={{position: "relative"}}
              >
                {tabRender(tab, isSelected)}
              </Box>
            </React.Fragment>
          )
        }
      )}
    </Box>
  );
}
