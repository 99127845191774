import { ExpNoteState } from '../exp_note/model';
import { getApiUrl, getLoginPortalUrl, isRemoteLogin } from '../../config/environment';
import axios, { AxiosRequestConfig } from 'axios';
import {
  DashboardExpenseTotalByCategoryMonth,
  DashboardExpenseTotalByMonth,
  DashboardExpenseTotalByStaffPolicy,
  DashboardExpenseTotalRange,
  DashboardExpNoteCount,
  DashboardLicense, DashBoardTravellerLastExpense
} from './model';
import { Moment } from 'moment/moment';
import moment from 'moment';
import { getLoginPortalJwt } from '../../config/token';

export const countAdminExpNotes = async (states: ExpNoteState[], excludeZero = false): Promise<DashboardExpNoteCount[]> => {
  const queryString = new URLSearchParams();
  queryString.append('state', states.join(','));
  queryString.append('excludeZero', excludeZero.toString());
  const apiUrl = getApiUrl();
  const resp = await axios.get<DashboardExpNoteCount[]>(`${apiUrl}/dashboard/admin/count`, {params: queryString});
  //console.log(resp);
  return resp.data;
}

export const countTravellerExpNotes = async (states: ExpNoteState[], excludeZero = false): Promise<DashboardExpNoteCount[]> => {
  const queryString = new URLSearchParams();
  queryString.append('state', states.join(','));
  queryString.append('excludeZero', excludeZero.toString());
  const apiUrl = getApiUrl();
  const resp = await axios.get<DashboardExpNoteCount[]>(`${apiUrl}/dashboard/traveller/count`, {params: queryString});
  return resp.data;
}

export const countToApproveExpNotes = async (): Promise<number> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/expnotes/approver/count`);
  return resp.data;
}

export const getLastTravellerExpenses = async (): Promise<DashBoardTravellerLastExpense[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/dashboard/traveller/lastExpenses`);
  return resp.data;
}

export const getExpenseTotalsByMonth = async (range: DashboardExpenseTotalRange): Promise<DashboardExpenseTotalByMonth[]> => {
  const queryString = new URLSearchParams();
  queryString.append('range', range);
  const apiUrl = getApiUrl();
  const resp = await axios.get<DashboardExpenseTotalByMonth[]>(`${apiUrl}/dashboard/admin/expbymonth`, {params: queryString});
  //console.log(resp);
  return resp.data;
}

export const getExpenseTotalsByCategoryMonth = async (range: DashboardExpenseTotalRange): Promise<DashboardExpenseTotalByCategoryMonth[]> => {
  const queryString = new URLSearchParams();
  queryString.append('range', range);
  const apiUrl = getApiUrl();
  const resp = await axios.get<DashboardExpenseTotalByCategoryMonth[]>(`${apiUrl}/dashboard/admin/expbycategorymonth`, {params: queryString});
  //console.log(resp);
  return resp.data;
}

export const getExpenseToCompleteTotalsByStaffPolicy = async (): Promise<DashboardExpenseTotalByStaffPolicy[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<DashboardExpenseTotalByStaffPolicy[]>(`${apiUrl}/dashboard/admin/expbystaffpolicy`);
  //console.log(resp);
  return resp.data;
}

export const getLicenseData = async (tenantId: string): Promise<DashboardLicense> => {
  if (isRemoteLogin()) {
    const loginApiUrl = getLoginPortalUrl();
    const loginJwt = getLoginPortalJwt();
    const config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${loginJwt}`
      }
    }
    const url = `${loginApiUrl}tenant/${tenantId}/license`;
    const resp = await axios.get<DashboardLicense>(url, config);
    return resp.data;
  } else {
    return new Promise<DashboardLicense>(res =>
      setTimeout(() => {
        const expireDate: Moment = moment('2030-10-24');
        res({
          type: 'PROFESSIONAL',
          expirationDate: expireDate.toDate(),
          activeUsers: 7,
          maxUsers: 9,
          companyName: 'DEFAULT',
          companyVat: 'DEFAULT',
          storeType: 'ZS',
          storeUrl: ''
        });
      }, 2000));
  }
}
