// React
import React from "react";

// MUI
import {Box, Button, Card, CardContent, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {MoreVert} from "@mui/icons-material";

// Libs
import {useTranslation} from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map";
import { Logger } from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// Custom
import {TravelPolicyExpense} from "../model";
import {ExpenseMeasureUnit} from "../../expenses/model";
import {getExpenseTypeLabel} from "../../expenses/utils";
import {ExpenseImg} from "../../expenses/commons/ExpenseImg";
import {getColleagueGuestIcon, getRouteIcon} from "../../expenses/icons";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {formatNumber} from "../../../util/NumberUtil";


export type TravelPolicyExpenseCardActionType = 'edit' | 'open' | 'delete' | 'editExpense' | 'create';


export type TravelPolicyExpenseCardActionEvent = {
  action: TravelPolicyExpenseCardActionType,
  expense: TravelPolicyExpense
}

export type TravelPolicyExpenseCardActionHandler = (event: TravelPolicyExpenseCardActionEvent) => void;

type TravelPolicyExpenseCardProps = {
  expense: TravelPolicyExpense,
  editable: boolean
  onAction: TravelPolicyExpenseCardActionHandler
}
export const TravelPolicyExpenseCard: React.FC<TravelPolicyExpenseCardProps> = ({
  expense,
  editable,
  onAction
}) => {

  const LOG: Slf4jsLog = Logger.of('ZTS.TravelPolicies.TravelPolicyExpenseCard');

  const {userLocale, companyDecimalNum, companyCurrencyCode} = useLoggedUser();

  const selectMode = false;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const {t} = useTranslation(['travel-policy', 'expense']);
  const {t: tCommon} = useTranslation('travel-policy', {keyPrefix: 'common.label'});

  const onToggleSelect = (expense: TravelPolicyExpense) => {
    LOG.trace('onToggleSelect', expense);
  }

  const theme = useTheme();
  {/*@ts-ignore*/}
  const sub1Color = theme.palette.subtitle1.main;

  //TODO capire cosa mostrare e le icone

  const onMenuAction = (action: TravelPolicyExpenseCardActionType) => {
    LOG.trace('onMenuAction', action);
    setAnchorEl(null);
    const event: TravelPolicyExpenseCardActionEvent = {
      action: action,
      expense: expense
    }
    onAction(event);
  }

  const currencySymbol = getSymbolFromCurrency(companyCurrencyCode);

  const getTarifLabel = (exp: TravelPolicyExpense): string => {
    let valusStr = '';
    if (exp.tarif) {
      valusStr = formatNumber(exp.tarif, userLocale, companyDecimalNum);
    }
    if (exp.expenseType==='IF') {
      return `${valusStr} ${currencySymbol}`;
    }
    if (exp.expenseType==='TR') {
      const suffix = getMeasureLabel(exp.measureUnit);
      return `${valusStr} ${currencySymbol}/${suffix}`;
    }
    return '';
  }

  const getMeasureLabel = (mu: ExpenseMeasureUnit | undefined | null): string => {
    if (mu===undefined || mu===null) return '';
    switch (mu) {
      case 'GG': return tCommon('day');
      case 'KM': return tCommon('kilometer');
      case 'NR': return tCommon('unit');
    }
    throw new Error('Unexpected ExpenseMeasureUnit: '+mu);
  }


  const btnAction: TravelPolicyExpenseCardActionType = editable ? 'edit' : 'open';
  const btnLabel = editable ? tCommon('settings') : tCommon('view');
  const menuDeleteLbl = tCommon('delete');
  const menuEditLbl = tCommon('settings');
  const menuEditExpLbl = tCommon('editExpense');

  let maximum = 0;
  if (expense?.maximum) {
    maximum = expense.maximum;
  }
  const maximumStr = formatNumber(maximum, userLocale, companyDecimalNum);


  return (
    <>
      <Card
        className='tp-exp-card'
        sx={{
          cursor: selectMode ? "pointer" : "default"
        }}
        onClick={() => selectMode ? onToggleSelect(expense) : {}}
      >
        <CardContent sx={{position: 'relative'}}>
          <Stack direction='row' spacing={1}>
            <ExpenseImg
              img={expense.expenseIcon}
              label={expense.expenseDescription || ''}
            />
            <Typography variant='body2' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'  className='tp-exp-title'>
              {expense.expenseDescription}
            </Typography>
          </Stack>

          <Stack mt={1} direction='row' overflow='hidden' spacing={2} width='100%'>
            <Typography variant='subtitle1' style={{color: sub1Color}}>{getExpenseTypeLabel(expense.expenseType, t)}</Typography>

            {
              (expense.expenseType === 'TR' || expense.expenseType === 'IF') &&
              <Typography variant='subtitle1' style={{color: sub1Color}}>{getTarifLabel(expense)}</Typography>
            }
            {
              expense.maximumType !== 'NO' &&
              <Typography variant='subtitle1' style={{color: sub1Color}}>{tCommon('maximumStr', {str: maximumStr, currencySymbol: currencySymbol})}</Typography>
            }

          </Stack>

          {
            expense.expenseType === 'PL' &&
            <>
              <Stack mt={1} direction='row' spacing={1}>
                {/*
                <Stack direction='row' spacing={0}>
                  {expense.payCollab && getPayByTravelerIcon()}
                  {expense.payCard && getPayByCardIcon()}
                  {expense.payCompany && getPayByCompanyIcon()}
                </Stack>
                <Stack direction='row' spacing={0}>
                  {expense.docInvoice && getReceiptIcon()}
                  {expense.docReceipt && getInvoiceIcon()}
                  {expense.docTicket && getTicketIcon()}
                  {!expense.docInvoice && !expense.docReceipt && !expense.docTicket && getNoDocumentIcon()}
                </Stack>
                */}
                {(expense.colleagueEnb || expense.guestEnb) && getColleagueGuestIcon()}
              </Stack>

            </>
          }
          {
            expense.expenseType === 'TR' &&
            <Stack direction='row' spacing={1}>
              {expense.measureUnit === 'KM' && expense.carRouteEnb && getRouteIcon()}
              {/*expense.measureUnit === 'KM' && !expense.carRouteEnb && getNoRouteIcon()*/}
            </Stack>
          }

          <Box
            style={{
              bottom: '6px',
              right: '10px',
              position: 'absolute'
            }}
          >
            <Button
              variant="contained"
              size="small"
              className="card-open-button"
              onClick={() => onMenuAction(btnAction)}
            >
              <Typography variant={"button"}>{btnLabel}</Typography>
            </Button>
          </Box>

          {editable &&
            <Box
              style={{
                top: '5px',
                right: '5px',
                position: 'absolute'
              }}
            >
              <Box
                onClick={e => setAnchorEl(e.currentTarget)}
                textAlign={"center"}
                mt={1}
                mr={1}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}>
                <MoreVert cursor={"pointer"}/>
              </Box>
            </Box>
          }

        </CardContent>
      </Card>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => onMenuAction('delete')} className="text-danger">{menuDeleteLbl}</MenuItem>
        <MenuItem onClick={() => onMenuAction('edit')}>{menuEditLbl}</MenuItem>
        <MenuItem onClick={() => onMenuAction('editExpense')}>{menuEditExpLbl}</MenuItem>
      </Menu>
    </>
  );

}
