import {Grid, Typography} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import React from "react";
import {Level} from "../model";
import {Controller, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  level: Level,
  enabled?: boolean
}

export const GeneralData = ({level, enabled}: ComponentProps) => {
  const {t} = useTranslation('level', {keyPrefix: 'generalData'});
  let control;
  if (enabled) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formMethods = useFormContext();
    control = formMethods.control;
  }

  return (
    <Grid container maxWidth={{xs: "100%", lg: "50%"}} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" textTransform={"uppercase"}>{t('title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        {level.id === -1 ? (
          <Controller
            name="code"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                required
                label={t('label.code')}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        ) : (
          <TextFieldZts
            disabled={true}
            label={t('label.code')}
            value={level.code}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {enabled ? (
          <Controller
            name="description"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                required
                label={t('label.description')}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        ) : (
          <TextFieldZts
            disabled={true}
            label={t('label.description')}
            value={level.description}
          />
        )}
      </Grid>
    </Grid>
  );
}
