import {Card, Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import {Account} from "../model";
import {AccountCardContent} from "./AccountCardContent";
import {useNavigate} from "react-router-dom";
import {canceledStateCode} from "../../model";
import {useTranslation} from "react-i18next";

type AccountCardProps = {
  account: Account,
  onOpen: Function,
  onRestore: Function,
}

type ComponentProps = {
  props: AccountCardProps
}

export const AccountCard = ({props}: ComponentProps) => {

  const {t} = useTranslation(['account'], {keyPrefix: 'detail.button'});

  // State
  //------

  const [accountId, setAccountId] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  let className = "account-card";
  if (props.account.state === canceledStateCode) {
    className += " disabled-card";
  }

  // Functions
  //----------

  const handleMenuClick = (accountId: number, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAccountId(accountId);
    setAnchorEl(e.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAccountId(0);
  }

  const onEditItem = (accountId: number) => {
    navigate(`/accounts/edit/${accountId}`);
  }

  // React Component
  //----------------

  return (
    <>
      <Card
        className={className}>
        <AccountCardContent props={
          {
            account: props.account,
            handleMenuClick: handleMenuClick,
            onOpen: props.onOpen,
            onRestore: props.onRestore
          }
        }
        />

      </Card>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => onEditItem(accountId)}>
          {t("modify")}
        </MenuItem>
      </Menu>

    </>
  );
}
