import {Route, Routes} from "react-router-dom";
import {FilterableList} from "./list/FilterableList";
import {UserDetail} from "./detail/UserDetail";
import {UserEdit} from "./edit/UserEdit";
import {useHelp} from "../help/hook";
import {HelpCurrentPage} from "../../reducers/Help";
import {activeStateCode, DETAIL_URL, EDIT_URL, NEW_URL, USER_URL} from "../model";
import {ProtectedRoute} from "../base/protected-routes";
import {useEffect} from "react";
import {useCurrentList} from "../../hooks/useCurrentList";
import {SaveUser, User} from "./model";
import {disableUsers, saveUser} from "./Service";
import {ITALIAN} from "../../util/LocalizationUtil";

export const Users = () => {

  const {clearListFilters} = useCurrentList();

  useEffect(() => {
    return () => clearListFilters()
  }, []);

  useHelp({
    paths: [
      {
        path: `${USER_URL}${NEW_URL}`,
        page: HelpCurrentPage.USER_NEW,
        exactMatch: true
      },
      {
        path: DETAIL_URL,
        page: HelpCurrentPage.USER_DETAIL
      },
      {
        path: EDIT_URL,
        page: HelpCurrentPage.USER_EDIT
      }
    ]
  });

  const onEnableUser = (user: User): Promise<User> => {
    const updUser: SaveUser = {
      id: user.id,
      state: activeStateCode,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      fiscalCode: user.fiscalCode,
      matricNumber: user.matricNumber,
      address: user.address,
      location: user.location,
      cap: user.cap,
      costCenterId: user.costCenter ? user.costCenter.id : null,
      approverId: user.approver ? user.approver.id : null,
      accountId: user.account ? user.account.id : null,
      lastUpdateNum: user.lastUpdateNum,
      isAdmin: user.isAdmin,
      isTraveller: user.isTraveller,
      pictureId: user.pictureId ? user.pictureId : null,
      locale: user.locale ? user.locale : ITALIAN
    };
    return saveUser(updUser);
  }

  const onDisableUser = (userId: number): Promise<any> => {
    return disableUsers([userId]);
  }

  return (
    <ProtectedRoute isAdmin>
      <Routes>
        <Route path="/" element={<FilterableList onEnableUser={onEnableUser} onDisableUser={onDisableUser}/>}/>
        <Route path={`${DETAIL_URL}/:id`} element={<UserDetail onEnableUser={onEnableUser} onDisableUser={onDisableUser}/>}/>
        <Route path={`${EDIT_URL}/:id`} element={<UserEdit/>}/>
      </Routes>
    </ProtectedRoute>
  );
}
