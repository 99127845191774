// React
import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";

// Custom
import {ProtectedRoute} from "../base/protected-routes";
import {TravelPolicyFilterableList} from "./list/TravelPolicyFilterableList";
import {DETAIL_URL, EDIT_URL, REACT_TRAVEL_POLICIES_BASE_URL} from "../model";
import {TravelPolicyEdit} from "./edit/TravelPolicyEdit";
import {useHelp} from "../help/hook";
import {HelpCurrentPage} from "../../reducers/Help";
import {TravelPolicyDetails} from "./detail/TravelPolicyDetails";
import {useCurrentList} from "../../hooks/useCurrentList";
import {useTranslation} from "react-i18next";


const TravelPolicies: React.FC = () => {
  const {t} = useTranslation('common');
  const {setBinMode} = useCurrentList();

  useEffect(() => {
    return () => {
      setBinMode(false);
    }
  }, []);

  useHelp({
    paths: [
      {
        path: `${REACT_TRAVEL_POLICIES_BASE_URL}${EDIT_URL}/-1`,
        page: HelpCurrentPage.TRAVEL_POLICIES_NEW,
        exactMatch: true
      },
      {
        path: DETAIL_URL,
        page: HelpCurrentPage.TRAVEL_POLICIES_DETAILS
      },
      {
        path: EDIT_URL,
        page: HelpCurrentPage.TRAVEL_POLICIES_EDIT
      }
    ]
  });

  return (
    <ProtectedRoute isAdmin>
      <Routes>
        <Route path="/" element={<TravelPolicyFilterableList/>}/>
        <Route path={`${EDIT_URL}/:id`} element={<TravelPolicyEdit/>}/>
        <Route path={`${DETAIL_URL}/:id`} element={<TravelPolicyDetails/>}/>
        <Route
          path="*"
          element={
            <main style={{padding: "1rem"}}>
              <p>{t('notFound')}</p>
            </main>
          }
        />
      </Routes>
    </ProtectedRoute>
  );

}

export default TravelPolicies;
