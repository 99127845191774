import {ExpNoteSortColumn} from "../../model";
import {ZtsTableColumn} from "../../../base/table/model";
import {Button, Typography} from "@mui/material";
import {ZtsTable} from "../../../base/table";
import React from "react";
import Box from "@mui/material/Box";
import {GreyColor} from "../../admin/list/model";
import {useExpNoteList} from "../../hooks/useExpNoteList";
import {useLoggedUser} from "../../../../hooks/useLoggedUser";
import {formatDate} from "../../../../util/DateUtil";
import {useTranslation} from "react-i18next";
import {HistoricalExpNote} from "./model";

type ComponentProps = {
  expNotes: HistoricalExpNote[],
  ctaLabel?: string,
  sortExternal?: boolean,
  rowClickHandler?: Function,
  emptyState?: string
}

export const HistoricalExpNoteBaseList = (props: ComponentProps) => {

  const {
    expNotes,
    ctaLabel,
    sortExternal,
    rowClickHandler,
    emptyState
  } = props;

  const {
    travellerList: {
      sorting,
    },
    updateTravellerSorting,
  } = useExpNoteList();

  const {userLocale: locale } = useLoggedUser();

  const {t} = useTranslation("historical-exp-note", {keyPrefix: "list"});
  const {t: tCommon} = useTranslation("common");

  const columns: ZtsTableColumn[] = [];

  // colonne comuni
  columns.push(
    {id: 'code', label: '#', typography: 'caption', cellColor: GreyColor},
    {id: 'description', label: tCommon("description"), typography: 'caption', cellColor: GreyColor},
    {id: 'staff', label: t("staff"), typography: 'caption', cellColor: GreyColor},
    {id: 'startDate', label: t("start"), typography: 'caption', cellColor: GreyColor, formatter: (value) => formatDate(value, locale)},
    {id: 'endDate', label: t("end"), cellColor: GreyColor, typography: 'caption', formatter: (value) => formatDate(value, locale)}
  );


  columns.push({
    id: 'cta', label: '', unsortable: true, displayOnHover: () => true, align: 'right', formatter: (r) => {
      return <Button
        variant="contained"
        className={"cta-list-button"}>
        <Typography variant={"button"}>{ctaLabel ? ctaLabel : t("show")}</Typography>
      </Button>
    }
  });

  const convertedExpNotes = expNotes.map(expNote =>
    ({
      check: expNote.id,
      code: expNote.code,
      description: expNote.description,
      staff: expNote.staffId + ' ' + expNote.staffDescription,
      startDate: expNote.startDate,
      endDate: expNote.endDate
    }));

  const onSortChanged = (orderBy: string, orderDir: 'asc' | 'desc') => {
    const sortColumn: ExpNoteSortColumn | undefined = Object.entries(ExpNoteSortColumn).find(([key, val]) => val === orderBy)?.[1];
    if (sortColumn) {
      updateTravellerSorting({orderBy: sortColumn, orderDir});
    }
  }

  return (
    <Box
      flexGrow={1}
      mb={2}
      overflow={"auto"}
    >
      {convertedExpNotes && <ZtsTable
        columns={columns}
        rows={convertedExpNotes}
        forcedSort={sorting}
        notifySortChanged={onSortChanged}
        sortExternal={sortExternal}
        rowClickHandler={rowClickHandler}
      />}
      {
        expNotes.length === 0 && emptyState &&
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "32px",
            maxWidth: "1900px"
          }}
          justifyContent={'center'}
        >

        </Box>
      }
    </Box>
  );

}
