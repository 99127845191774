// React
import React, {useEffect, useState} from "react";
import {FormProvider, Controller, useForm} from "react-hook-form";

// Yup
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

// MUI
import {Stack} from "@mui/material";

// Libs
import {useTranslation} from "react-i18next";
import {Logger} from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// Custom
import {TextFieldZts} from "../../base/text-field";
import {CommonDialog} from "../../base/common-dialog";
import {HelpCurrentPage} from "../../../reducers/Help";
import {yupMinMaxRequiredString} from "../../../util/YupUtil";
import {createAccount, NewAccountObject, readAccount} from "../Service";
import {Account} from "../model";
import {useErrorMessage} from "../../../util/ErrorUtil";


// Valori di default
const defAccount: NewAccountObject = {
  code: '',
  description: ''
}

export type NewAccountModalClosedModeType = 'closed' | 'saved';

export type NewAccountModalClosedEvent = {
  account: Account | null
  mode: NewAccountModalClosedModeType
}

export type NewAccountModalClosedHandler = (event: NewAccountModalClosedEvent) => void;

type NewAccountModalProps = {
  account: Partial<NewAccountObject> | null
  onClose: NewAccountModalClosedHandler
  title?: string
}

export const NewAccountModal: React.FC<NewAccountModalProps> = ({account, onClose, title}) => {

  const LOG: Slf4jsLog = Logger.of('ZTS.Accounts.NewAccountModal');

  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const {t} = useTranslation(['validation']);
  const {t: tAcc} = useTranslation('account');
  const {convertError} = useErrorMessage();

  const validationSchema = Yup.object({
    code: yupMinMaxRequiredString(1, 20, t),
    description: yupMinMaxRequiredString(1, 50, t)
  })

  const formMethods = useForm<NewAccountObject>({
    defaultValues: defAccount,
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  const saveAccount = async (data: NewAccountObject) => {
    LOG.trace('saveAccount', data);
    setErrorMsg(null);
    setSaving(true);

    try {
      const resp = await createAccount(data);
      const newAccount = await readAccount(resp.id);
      setSaving(false);
      onClose({
        account: newAccount,
        mode: 'saved'
      });
    } catch (e) {
      const msg = await convertError(e as Error);
      setErrorMsg(msg);
      setSaving(false);
    }
  }

  const closeModal = () => {
    LOG.trace('closeModal');
    if (account === null) {
      throw new Error('Unexpected saveExpense call with null account')
    }
    onClose({
      account: null,
      mode: 'closed'
    });
  }

  const onFormValid = (data: NewAccountObject) => {
    LOG.trace('onFormValid');
    saveAccount(data);
  }

  const onFormError = (errors) => {
    LOG.trace('onFormError', errors);
  }

  const onAction = (flag: boolean) => {
    LOG.trace('onAction', flag);
    if (flag) {
      // submit della form
      formMethods.handleSubmit(onFormValid, onFormError)();
    } else {
      closeModal();
    }
  }

  const showFlag: boolean = account !== null;
  const titleLbl = title ? title : tAcc('list.creaAccount');

  const codeLbl = tAcc('list.code');
  const descLbl = tAcc('list.description');


  useEffect(() => {
    LOG.trace('useEffect [account]', account);
    if (account) {
      const initVal: NewAccountObject = {
        ...defAccount,
        ...account
      };
      LOG.trace('Reset form', initVal);
      formMethods.reset(initVal);
    }
  }, [account]);

  return (
    <CommonDialog
      show={showFlag}
      title={titleLbl}
      widths={[
        {breakpoint: "lg", width: "400px"},
      ]}
      saving={saving}
      errorMsg={errorMsg}
      page={HelpCurrentPage.TRAVEL_POLICIES_EDIT}
      onClose={onAction}
    >
      <FormProvider {...formMethods}>
        <form noValidate>
          <Stack direction='column' spacing={3}>

            <Controller
              name="code"
              render={({field, fieldState}) =>
                <TextFieldZts
                  {...field}
                  required
                  label={codeLbl}
                  errorMsg={fieldState.error?.message}
                />
              }
            />

            <Controller
              name="description"
              render={({field, fieldState}) =>
                <TextFieldZts
                  {...field}
                  required
                  label={descLbl}
                  errorMsg={fieldState.error?.message}
                />
              }
            />

          </Stack>
        </form>
      </FormProvider>
    </CommonDialog>
  );
}
