// React
import React, {SyntheticEvent, useEffect} from "react";

// MUI
import {Box} from "@mui/material";

// Libs
import { Logger } from 'react-logger-lib';
import SimpleJsLog from '../../util/Logger';

// Custom
import {SearchBar} from "../base/search-bar";
import {FilterFunction, FunctionEvent} from "./model";
import {identityFactory} from "./utils";


export type TextFilterChangeEvent = {
  name: string
  text: string
}

export type TextFilterValueEvent<T1> = {
  text: string
} & FunctionEvent<T1>


type TextFilterProps<T2> = {
  name: string,
  text: string,
  mapperFn: (arg: T2) => string
  onChange: (event: TextFilterChangeEvent) => void
  onValue?: (event: TextFilterValueEvent<T2>) => void
}

const LOG: SimpleJsLog = Logger.of('ZTS.Filters.TextFilter');

export const TextFilter = <T extends object>(props: TextFilterProps<T>) => {

  const { name, text, mapperFn, onChange, onValue } = props;

  const filterFactory = (value: string, mapper: typeof props.mapperFn): FilterFunction<T> => {
    LOG.trace('filterFactory', value, mapper);
    const match = value || '';
    const lowerMatch = match.toLowerCase();
    return (args: T[]) => {
      return args.filter(t => {
        const strValue = mapper(t) || '';
        const lower = strValue.toLowerCase();
        return lower.indexOf(lowerMatch)>=0;
      });
    }
  }

  const searchTextChangeHandler = (e: SyntheticEvent) => {
    LOG.trace('searchTextChangeHandler', e);
    const target = e.target as HTMLInputElement;
    const newValue: string = target.value || '';

    const changeEvent: TextFilterChangeEvent = {
      name: name,
      text: newValue
    }
    onChange(changeEvent);
  }

  useEffect( () => {
    LOG.trace('useEffect [text]', text);
    if (onValue === undefined || onValue === null) {
      LOG.trace('useEffect [text] -> end. No onValue defined');
      return;
    }

    const emptyValue: boolean = text === '';

    let filterFn: FilterFunction<T>
    if (emptyValue) {
      filterFn = identityFactory();
    } else {
      filterFn = filterFactory(text, mapperFn);
    }

    const changeEvent: TextFilterValueEvent<T> = {
      name: name,
      text: text,
      isIdentity: emptyValue,
      filterFn: filterFn
    }

    onValue(changeEvent);

  }, [text]);


  return (
    <Box
      flexGrow={{
        xs: 1,
        lg: 0
      }}
      flexBasis={{
        xs: "calc(100% - 60px)",
        lg: "auto"
      }}
      order={1}
    >
      <SearchBar
        filterText={text}
        onFilterChange={searchTextChangeHandler}
      />
    </Box>
  );

}


