import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const ModulePopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>
          Questa funzionalità, disponibile come add-on alla versione Professional di ZTravel Smart permette di importare
          all’interno delle note spese i movimenti delle carte aziendali dei collaboratori.
          <br/>
          L’amministrazione, una volta caricati i movimenti di carta, può associarli alle spese che i collaboratori
          hanno inserito verificando correttezza della spesa, tassi di cambio ecc. Al termine del processo è possibile
          estrarre un report che evidenzia quali movimenti non sono presenti in nota spese per verificare e gestire le
          eventuali anomalie.
          <br/>
          ZTravel Smart digitalizza e automatizza la riconciliazione tra spese e movimenti elettronici eliminando
          attività manuali e inutili sprechi di tempo.
        </p>
      );
    default:
      return (
        <p>
          Questa funzionalità, disponibile come add-on alla versione Professional di ZTravel Smart permette di importare
          all’interno delle note spese i movimenti delle carte aziendali dei collaboratori.
          L’amministrazione, una volta caricati i movimenti di carta, può associarli alle spese che i collaboratori
          hanno inserito verificando correttezza della spesa, tassi di cambio ecc. Al termine del processo è possibile
          estrarre un report che evidenzia quali movimenti non sono presenti in nota spese per verificare e gestire le
          eventuali anomalie.
          ZTravel Smart digitalizza e automatizza la riconciliazione tra spese e movimenti elettronici eliminando
          attività manuali e inutili sprechi di tempo.
        </p>
      );
  }
}
