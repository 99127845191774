import { UserWithTenant } from '../components/users/model';
import { UpdateProfileRequest } from '../components/profile/model';

export const SET_USER = 'LOGGED/SET_LOGGED_USER';
export const UPDATE_PROFILE = 'LOGGED/UPDATE_PROFILE';
const IMPERSONATE = 'LOGGED/IMPERSONATE';

// reducer
const reducer = (
  state: {
    user: UserWithTenant | null,
    adminUser: string | null
  } = {
    user: null,
    adminUser: null
  },
  action
) => {
  switch (action.type) {
    case SET_USER:
      return {...state, user: action.user, adminUser: null}
    case UPDATE_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          locale: action.profile.locale,
          pictureId: action.profile.pictureId
        }
      }
    case IMPERSONATE:
      return {...state, user: action.user, adminUser: action.adminUser }
  }
  return state;
}

// action creator
export const setLoggedUser = (user: UserWithTenant | null) => ({
  type: SET_USER,
  user: user
});

export const impersonateUserAction = (user: UserWithTenant | null, admin: string) => ({
  type: IMPERSONATE,
  user: user,
  adminUser: admin
})

export const updateLoggedProfile = (updateProfile: UpdateProfileRequest | null) => ({
  type: UPDATE_PROFILE,
  profile: updateProfile
});

// utility
export const getLoggedUser = (state: { Logged: { user: UserWithTenant; }; }) => {
  return state.Logged ? state.Logged.user : null;
}

export const getZtsAdminUser = (state: { Logged: { adminUser: string }; }) => {
  return state.Logged ? state.Logged.adminUser : null;
}

export default reducer;
