import {Avatar, Box, IconButton, Typography} from "@mui/material";
import {AvatarViewer} from "../../avatar-viewer";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {downloadTempFile} from "../../../exp_note/Service";
import {ImageEditor} from "../editor";
import {FileUploadReq} from "../../../model";
import {TooltipZts} from "../../tooltip";
import {useTranslation} from "react-i18next";


type ImageViewerProps = {
  imageId?: string | null,
  tempImage?: boolean,
  label?: string,
  title?: string,
  width?: number,
  height?: number,
  uploadImage: (request: FileUploadReq, progressHandler: (progressEvent: any) => void) => void,
  deleteImage: () => void,
  getImage: (imageId: string) => Promise<Blob>
}

export const ImageViewer = ({
                       imageId,
                       tempImage,
                       label,
                       title,
                       width,
                       height,
                       uploadImage,
                       deleteImage,
                       getImage
                     }: ImageViewerProps) => {
  const [logo, setLogo] = useState<Blob | string | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const theme = useTheme();
  const {t} = useTranslation('base', {keyPrefix: 'images'});

  const asyncDownloadImageAndSetLogo = async (uuid: string): Promise<void> => {
    try {
      const res = await getImage(uuid);
      if (res) {
        setLogo(new Blob([res], {type: 'image/png'}));
      }
    } catch (err){
      setLogo(null);
      console.log(err); // TODO: aggiungere error
    }
  }

  const asyncDownloadTempFileAndSetLogo = async (uuid: string): Promise<void> => {
    try {
      const res = await downloadTempFile(uuid, () => {});
      setLogo(res.file);
    } catch (err) {
      setLogo(null);
      console.log('download error', err); // TODO: aggiungere error
    }
  }

  useEffect(() => {
    if (imageId) {
      if (tempImage) {
        asyncDownloadTempFileAndSetLogo(imageId).then();
      } else {
        asyncDownloadImageAndSetLogo(imageId).then();
      }
    }
  }, [imageId, tempImage])


  return (
    <Box width={'fit-content'} position={'relative'}>
      <Avatar
        sx={{
          bgcolor: theme.palette.secondary.main,
          width: `${width ? width : '100'}px`,
          height: `${height ? height : '100'}px`,
          fontSize: '2.25rem'
        }}
      >
        {
          logo ? (
            <AvatarViewer avatar={logo}/>
          ) : (
            <Typography>{label ? label : 'Logo'}</Typography>
          )
        }
      </Avatar>
      <IconButton sx={{position: 'absolute', top: '80px', right: '0px', backgroundColor: 'white'}}>
        {
          logo ? (
            <TooltipZts
              title={label ? `${t('remove')} ${label}` : t('removeImage')}
              placement={'bottom'}
              enterDelay={400}
            >
              <DeleteIcon fontSize={'small'} onClick={() => {
                deleteImage();
                setLogo(null);
              }}/>
            </TooltipZts>

          ) : (
            <TooltipZts
              title={label ? `${t('add')} ${label}` : t('addImage')}
              placement={'bottom'}
              enterDelay={400}
            >
              <AddAPhotoIcon fontSize={'small'} onClick={() => setOpen(true)}/>
            </TooltipZts>
          )
        }
      </IconButton>
      <ImageEditor setImage={setLogo} uploadImage={uploadImage} dialogLabel={label} dialogTitle={title ? title : t('modifyImage')} open={open} openDialog={setOpen}/>
    </Box>
  );
}
