import {Dialog, DialogActions, DialogContent, Stack, Typography} from "@mui/material";
import {Carousel} from "react-responsive-carousel";
import Box from "@mui/material/Box";
import React, {ReactChild, useEffect, useState} from "react";
import {WelcomeContent1} from "./contents/1";
import {WelcomeContent2} from "./contents/2";
import {WelcomeContent3} from "./contents/3";
import {WelcomeContent4} from "./contents/4";
import {useLoggedUser} from "../../hooks/useLoggedUser";
import {isWelcomeDismissed, saveWelcomeDismiss} from "../dashboard/model";
import {isProfessionalLicense} from "../../reducers/License";
import {useSelector} from "react-redux";

export const WelcomePopup = () => {
  const {
    user
  } = useLoggedUser();

  const [show, setShow] = useState<boolean>(false);
  const isPro = useSelector(isProfessionalLicense);

  useEffect(() => {
    if (user && !isPro) {
      setShow(!isWelcomeDismissed(user.tenant));
    } else {
      setShow(false);
    }
  }, [user]);

  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [contents, setContents] = useState<ReactChild[]>([<WelcomeContent1/>, <WelcomeContent2/>, <WelcomeContent3/>, <WelcomeContent4/>]);

  const handleShowPrevSlide = () => {
    setCurrentSlide(prev => prev - 1);
  }

  const handleShowNextSlide = () => {
    setCurrentSlide(prev => prev + 1);
  }

  const dismiss = () => {
    saveWelcomeDismiss();
    setShow(false);
  }

  return (
    <>
      <Dialog open={show} fullWidth maxWidth={'md'} onClose={() => setShow(false)} className={'license-popup'}>
        <DialogContent sx={{overflow: 'hidden'}}>
          <Carousel
            autoPlay={false}
            infiniteLoop={false}
            interval={6000}
            onChange={setCurrentSlide}
            selectedItem={currentSlide}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            stopOnHover={true}
            transitionTime={500}
          >
            {contents}
          </Carousel>
        </DialogContent>
        <DialogActions>
          <Stack flexDirection={'row-reverse'} alignItems={'center'} width={'100%'} columnGap={2} p={2}>
            {
              currentSlide + 1 !== contents.length ?
                (<Box sx={{
                  color: 'white',
                  backgroundColor: '#14c6d5',
                  width: '120px',
                  minWidth: 'fit-content',
                  padding: '2px 10px 2px 10px',
                  cursor: 'pointer',
                  borderRadius: '10px'
                }} onClick={handleShowNextSlide}>
                  <Typography textAlign={'center'}>Avanti</Typography>
                </Box>) :
                (<Box sx={{
                  color: 'white',
                  backgroundColor: '#14c6d5',
                  width: '120px',
                  minWidth: 'fit-content',
                  padding: '2px 10px 2px 10px',
                  cursor: 'pointer',
                  borderRadius: '10px'
                }} onClick={() => setShow(false)}>
                  <Typography textAlign={'center'}>Fine</Typography>
                </Box>)
            }
            {
              currentSlide + 1 !== 1 &&
              <Box sx={{color: 'white', backgroundColor: '#d0cece', width: '120px', minWidth: 'fit-content', padding: '2px 10px 2px 10px', cursor: 'pointer', borderRadius: '10px'}} onClick={handleShowPrevSlide}>
                <Typography textAlign={'center'}>Indietro</Typography>
              </Box>
            }
            <Box flexGrow={1}/>
            <Typography fontWeight={500} sx={{cursor: 'pointer'}} onClick={dismiss}>Salta la guida, entra subito in ZTravel Smart</Typography>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
