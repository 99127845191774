import {Level} from "../model";
import {Card, Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import {canceledStateCode} from "../../model";
import {useNavigate} from "react-router-dom";
import {LevelCardContent} from "./LevelCardContent";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  level: Level,
  onOpen: Function,
  onRestore: Function,
}

export const LevelCard = ({level, onOpen, onRestore}: ComponentProps) => {

  const navigate = useNavigate();
  const {t} = useTranslation('level', {keyPrefix: 'detail.button'});

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [levelId, setLevelId] = useState<number>(0);

  const handleMenuClick = (levelId: number, e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setLevelId(levelId);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setLevelId(0);
  };

  const onEditItem = (levelId: number) => {
    navigate(`/levels/edit/${levelId}`);
  }

  let className = "user-card"; /*TODO vedere come impostar css per centri di costo */
  if (level.state === canceledStateCode) {
    className += " disabled-card";
  }

  return (
    <>
      <Card
        className={className}>
        <LevelCardContent
          level={level}
          handleMenuClick={handleMenuClick}
          onOpen={onOpen}
          onRestore={onRestore}
        />

      </Card>


      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => onEditItem(levelId)}
        >
          {t("modify")}
        </MenuItem>
      </Menu>

    </>
  );
}
