import {useEffect, useState} from "react";
import {DRAWER_GUTTER} from "../../model";
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";

export const useMenu = (): {
  menuDesktopOpen: boolean,
  toggleMenuDesktop: () => void,
  menuMobileOpen: boolean,
  toggleMenuMobile: () => void,
  drawerWidth: number,
  drawerGutter: number,
  isMobile: boolean
} => {

  const drawerOpenWidth = 300;
  const drawerClosedWidth = 86;
  const drawerGutter = DRAWER_GUTTER;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [menuMobileOpen, setMenuMobileOpen] = useState(false);
  const [menuDesktopOpen, setMenuDesktopOpen] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(drawerOpenWidth);

  useEffect(() => {
    if (isMobile) {
      setDrawerWidth(drawerOpenWidth);
    } else {
      setDrawerWidth(menuDesktopOpen ? drawerOpenWidth : drawerClosedWidth);
    }
  }, [isMobile, menuDesktopOpen]);

  const toggleMenuDesktop = () => {
    if (menuDesktopOpen) {
      setMenuDesktopOpen(false);
      //setDrawerWidth(drawerClosedWidth);
    } else {
      setMenuDesktopOpen(true);
      //setDrawerWidth(drawerOpenWidth);
    }
  }

  const toggleMenuMobile = () => {
    if (menuMobileOpen) {
      setMenuMobileOpen(false);
    } else {
      setMenuMobileOpen(true);
    }
  }

  return {
    menuDesktopOpen,
    toggleMenuDesktop,
    menuMobileOpen,
    toggleMenuMobile,
    drawerWidth,
    drawerGutter,
    isMobile
  };

}
