import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const TPMandatoryProjectSwitchPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile abilitare l&rsquo;inserimento obbligatorio di una commessa su una specifica voce di
          spesa. Configurando correttamente le commesse, <strong>queste potranno essere quindi associate alle spese
            direttamente da app mobile</strong> e verranno <strong>riportate nell&rsquo;export contabile di ZTravel
            Smart</strong> e nei <strong>report</strong> presenti nella soluzione. Maggiore controllo sui progetti,
          maggiore velocit&agrave; nel processo di fatturazione al cliente e meno errori.</p>
      );
    default:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile abilitare l&rsquo;inserimento obbligatorio di una commessa su una specifica voce di
          spesa. Configurando correttamente le commesse, <strong>queste potranno essere quindi associate alle spese
            direttamente da app mobile</strong> e verranno <strong>riportate nell&rsquo;export contabile di ZTravel
            Smart</strong> e nei <strong>report</strong> presenti nella soluzione. Maggiore controllo sui progetti,
          maggiore velocit&agrave; nel processo di fatturazione al cliente e meno errori.</p>
      );
  }
}
