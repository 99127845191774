import { ExpNoteExpenseFull } from '../components/exp_note/expense/model';
import { ExpNoteCreCardMov, ExpNotePayment, ExpNoteWithStaff } from '../components/exp_note/model';

enum ExpNoteDetailActionType {
  SET_DATA = 'EXP_NOTE_DETAIL/SET_DATA',
  SET_EXPENSES = 'EXP_NOTE_DETAIL/SET_EXPENSES',
  SET_PAYMENTS = 'EXP_NOTE_DETAIL/SET_PAYMENTS',
  SET_CRE_CARD_MOVS = 'EXP_NOTE_DETAIL/SET_CRE_CARD_MOVS',
  RELOAD_EXP_NOTE = 'EXP_NOTE_DETAIL/RELOAD_EXP_NOTE',
  CLEAR_EXP_NOTE = 'EXP_NOTE_DETAIL/CLEAR_EXP_NOTE',
}

export interface ExpNoteDetailState {
  expNote: ExpNoteWithStaff | null,
  expenses: ExpNoteExpenseFull[] | null,
  payments: ExpNotePayment[] | null,
  creditCardMovs: ExpNoteCreCardMov[] | null
}

export interface ExpNoteDetailAction {
  type: ExpNoteDetailActionType,
  expNote?: ExpNoteWithStaff | null,
  expenses?: ExpNoteExpenseFull[] | null,
  payments?: ExpNotePayment[] | null,
  creditCardMovs?: ExpNoteCreCardMov[] | null
}

const reducer = (
  state: ExpNoteDetailState = {
    expNote: null,
    expenses: null,
    payments: null,
    creditCardMovs: null
  },
  action: ExpNoteDetailAction) => {
  switch (action.type) {
    case ExpNoteDetailActionType.SET_DATA:
      return {...state, expNote: action.expNote, expenses: action.expenses, payments: action.payments, creditCardMovs: action.creditCardMovs}
    case ExpNoteDetailActionType.SET_EXPENSES:
      return {...state, expNote: action.expNote, expenses: action.expenses}
    case ExpNoteDetailActionType.SET_PAYMENTS:
      return {...state, expNote: action.expNote, expenses: action.expenses, payments: action.payments}
    case ExpNoteDetailActionType.SET_CRE_CARD_MOVS:
      return {...state, creditCardMovs: action.creditCardMovs}
    case ExpNoteDetailActionType.RELOAD_EXP_NOTE:
      return {...state, expNote: action.expNote}
    case ExpNoteDetailActionType.CLEAR_EXP_NOTE:
      return {...state, expNote: null, expenses: null, payments: null, creditCardMovs: null}
  }
  return state;
}

// action creator
export const setDataInExpNoteDetail = (expNote: ExpNoteWithStaff | null, expenses: ExpNoteExpenseFull[] | null, payments: ExpNotePayment[] | null, creditCardMovs: ExpNoteCreCardMov[] | null) => ({
  type: ExpNoteDetailActionType.SET_DATA,
  expNote,
  expenses,
  payments,
  creditCardMovs
});

export const reloadExpNoteInExpNoteDetail = (expNote: ExpNoteWithStaff) => ({
  type: ExpNoteDetailActionType.RELOAD_EXP_NOTE,
  expNote
});

export const setExpensesInExpNoteDetail = (expNote: ExpNoteWithStaff | null, expenses: ExpNoteExpenseFull[] | null) => ({
  type: ExpNoteDetailActionType.SET_EXPENSES,
  expNote,
  expenses
});

export const setPaymentsInExpNoteDetail = (expNote: ExpNoteWithStaff | null, payments: ExpNotePayment[] | null, expenses: ExpNoteExpenseFull[] | null) => ({
  type: ExpNoteDetailActionType.SET_PAYMENTS,
  expNote,
  expenses,
  payments
});

export const setCreditCardMovsInExpNoteDetail = (creditCardMovs: ExpNoteCreCardMov[] | null) => ({
  type: ExpNoteDetailActionType.SET_CRE_CARD_MOVS,
  creditCardMovs
});

export const clearExpNoteDetail = () => ({
  type: ExpNoteDetailActionType.CLEAR_EXP_NOTE
});

// utility
export const getExpensesInExpNoteDetail = (state: { ExpNoteDetail: ExpNoteDetailState }): ExpNoteExpenseFull[] | null => {
  return state.ExpNoteDetail ? state.ExpNoteDetail.expenses : null;
}

export const getPaymentsInExpNoteDetail = (state: { ExpNoteDetail: ExpNoteDetailState }): ExpNotePayment[] | null => {
  return state.ExpNoteDetail ? state.ExpNoteDetail.payments : null;
}

export const getCreditCardMovsInExpNoteDetail = (state: { ExpNoteDetail: ExpNoteDetailState }): ExpNoteCreCardMov[] | null => {
  return state.ExpNoteDetail ? state.ExpNoteDetail.creditCardMovs : null;
}

export const getExpNoteInExpNoteDetail = (state: { ExpNoteDetail: ExpNoteDetailState }): ExpNoteWithStaff | null => {
  return state.ExpNoteDetail ? state.ExpNoteDetail.expNote : null;
}

export default reducer;
