import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";
import * as React from "react";

export const ApproverPillPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>
          Attraverso questa funzionalità, disponibile nella versione Professional di ZTravel Smart, è possibile definire
          che le note spese del collaboratore debbano essere approvate da un superiore, area manager o altro prima
          del controllo dell’amministrazione. Aggiungi un livello di approvazione al tuo flusso di gestione note spese
          per aderire al tuo workflow aziendale
        </p>
      );
    default:
      return (
        <p>
          Attraverso questa funzionalità, disponibile nella versione Professional di ZTravel Smart, è possibile definire
          che le note spese del collaboratore debbano essere approvate da un superiore, area manager o altro prima
          del controllo dell’amministrazione. Aggiungi un livello di approvazione al tuo flusso di gestione note spese
          per aderire al tuo workflow aziendale
        </p>
      );
  }
}
