import {Alert, Button, Paper, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getUserFullName, isActiveUser, User} from "../model";
import {useParams} from "react-router";
import {getUserById, hasAvailableLicenses} from "../Service";
import {Size, UserPicture} from "../UserPicture";
import Box from "@mui/material/Box";
import {TravelPolicies} from "../edit/TravelPolicies";
import {CreditCardList} from "../edit/CreditCardList";
import {ExpenseList} from "../edit/ExpenseList";
import IconButton from "@mui/material/IconButton";
import {EDIT_URL, getStatusClass, getStatusLabel, USER_URL} from "../../model";
import {useLocation, useNavigate} from "react-router-dom";
import {GeneralData} from "./GeneralData";
import {Role} from "./Role";
import {PersonalData} from "./PersonalData";
import {AdministrativeData} from "./AdministrativeData";
import {Items} from "./Items";
import {PageTitle} from "../../layout/page-title";
import {UserDetailLoader} from "./UserDetailLoader";
import {Check, Edit, PersonOff} from "@mui/icons-material";
import ProjectsList from "../edit/ProjectsList";
import {TooltipZts} from "../../base/tooltip";
import {useTranslation} from "react-i18next";
import {useLicense} from "../../../hooks/useLicense";
import {LicensePopup} from "../../license-popup";
import {useCheckDisableUser} from "../hooks/useCheckDisableUser";
import {PopupContentType} from "../../license-popup/model";
import {useErrorMessage} from "../../../util/ErrorUtil";

type ComponentProps = {
  onEnableUser: (user: User) => Promise<User>,
  onDisableUser: (userId: number) => Promise<any>
}

export const UserDetail = ({onEnableUser, onDisableUser}: ComponentProps) => {

  const [loadingData, setLoadingData] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [userUpdated, setUserUpdated] = useState<Date | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [openCreditCard, setOpenCreditCard] = useState(false);
  const [openExpense, setOpenExpense] = useState(false);
  const [openProjects, setOpenProjects] = useState(false);
  const [openTravelPolicies, setOpenTravelPolicies] = useState(false);

  const [premiumFeatureMsg, setPremiumFeatureMsg] = useState('');

  const {t} = useTranslation("user");
  const {t: tCommon} = useTranslation("common");
  const {t: tLicense} = useTranslation("license");

  const navigate = useNavigate();

  let {id} = useParams();
  const location = useLocation();

  const {isProfessional} = useLicense();
  const free = !isProfessional();
  const [openLicensePopup, setOpenLicensePopup] = useState<boolean>(false);
  const [popupType, setPopupType] = useState<PopupContentType>();
  const [popupTitle, setPopupTitle] = useState<string | null>(null);
  const openPopup = (type: PopupContentType) => {
    setPopupType(type);
    setPopupTitle(tLicense(`pro.menu.${type.toString()}.title`));
    setOpenLicensePopup(true);
  }
  const closePopup = () => {
    setOpenLicensePopup(false);
    setPopupTitle(null);
    setErrorMsg(null);
  }

  const {
    canDisableUser
  } = useCheckDisableUser();

  const {convertError} = useErrorMessage();

  useEffect(() => {
    if (location.state && (location.state as { openTp: boolean }).openTp) {
      setOpenTravelPolicies(true);
    }
  }, [location]);

  useEffect(() => {
    if (id) {
      setLoadingData(true);

      getUserById(id)
        .then(u => setUser(u))
        .catch(err => setErrorMsg(`Error retrieving data. ${err}`))
        .finally(() => setLoadingData(false))
    }
  }, [userUpdated, id]);

  const handleEditClicked = () => {
    if (user && isActive) {
      navigate(`..${EDIT_URL}/${user.id}`);
    }
  }

  const handleDisableClicked = async () => {
    if (user) {
      const canDisable = await canDisableUser(user.id);
      if (!canDisable) {
        return;
      }
      await onDisableUser(user.id);
      setUserUpdated(new Date());
    }
  }

  const handleEnableClicked = async () => {
    if (!user) {
      return;
    }
    setLoadingData(true); //TODO meglio qualkosa ke impedisce solo di interagire con la pagina
    /*const flag = await hasAvailableLicenses();
    if (flag) {
      await onEnableUser(user);
      setUserUpdated(new Date());
    }else {
      openPopup(PopupContentType.USER_ADD);
    }
     */
    try {
      await onEnableUser(user);
      setUserUpdated(new Date());
    }catch (error: any){
      const msg = await convertError(error);
      if (msg==='error.loginportal.no_licenses'){
        openPopup(PopupContentType.USER_ADD);
      } else {
        throw error;
      }
    }

    setLoadingData(false);
  }

  const handleCreditCardOpen = () => {
    if (free) {
      //setPremiumFeatureMsg('Gestisci le carte di credito in ZTS!');
      openPopup(PopupContentType.USER_CRECARD);
    } else {
      setOpenCreditCard(true);
    }
  }

  const handleCreditCardClose = (updated: boolean) => {
    setOpenCreditCard(false);
    if (updated && user) {
      setUser({...user, lastUpdateNum: user.lastUpdateNum + 1});
    }
  }

  const handleExpenseOpen = () => {
    setOpenExpense(true);
  }

  const handleExpenseClose = (updated: boolean) => {
    setOpenExpense(false);
    if (updated && user) {
      setUser({...user, lastUpdateNum: user.lastUpdateNum + 1});
    }
  }

  const handleProjectsOpen = () => {
    //console.log('projects open')
    if (free) {
      openPopup(PopupContentType.USER_PROJECTS);
      //setPremiumFeatureMsg('Gestisci le commesse in ZTS!');
    } else {
      setOpenProjects(true);
    }
  }

  const handleProjectsClose = (updated: boolean) => {
    setOpenProjects(false);
    if (updated && user) {
      setUser({...user, lastUpdateNum: user.lastUpdateNum + 1});
    }
  }

  const handleTravelPoliciesOpen = () => {
    setOpenTravelPolicies(true);
  }

  const handleTravelPoliciesClose = (updated: boolean) => {
    setOpenTravelPolicies(false);
    if (updated && user) {
      setUser({...user, lastUpdateNum: user.lastUpdateNum + 1});
    }
  }

  const backToList = () => {
    navigate(USER_URL);
  }

  const handlePremiumFeatureClose = () => {
    setPremiumFeatureMsg('');
  }

  const isActive = user && isActiveUser(user);

  const notActiveOpacity = 0.4;

  return (
    <>

      <Box
        overflow={"hidden"}
        flexGrow={1}
        mx={"auto"}
        width={loadingData ? "100%" : "auto"}
      >

        {/* Skeleton */}
        {loadingData && <UserDetailLoader/>}

        {!loadingData && user && <>
          <Paper
            elevation={0}
            sx={{
              height: "100%"
            }}
          >

            <Box
              height={"100%"}
              overflow={"auto"}
              padding={"20px 30px 20px 20px"}
            >

              {errorMsg && <Alert
                sx={{my: 1}}
                severity={"error"}
                onClose={() => setErrorMsg(null)}
              >{errorMsg}</Alert>}

              {/* titolo + pulsanti */}
              <PageTitle
                backClickHandler={backToList}
                title={
                  <Stack
                    direction={{xs: "column", sm: "row"}}
                    alignItems={{xs: "flex-start", sm: "center"}}
                    justifyContent={{xs: "center", sm: "flex-start"}}
                    columnGap={1.5}
                    flexGrow={"1"}
                  >
                    <Typography
                      variant={"h3"}
                      mr={3}
                      className={"detail-title-wrapper"}
                    >{getUserFullName(user)}</Typography>
                    <Box
                      mt={{xs: 1, sm: 0}}
                      className={'status-pill-detail-header ' + getStatusClass(user.state)}
                    >
                      {isActive && <Check fontSize={"small"}/>}
                      <Typography variant={"h5"}>{getStatusLabel(user.state, t)}</Typography>
                    </Box>

                    {!isActive && (
                      <Button
                        sx={{ml: 4}}
                        variant="contained"
                        className="restore-button"
                        onClick={handleEnableClicked}>
                        <Typography variant={"button"}>{t("detail.reactivate")}</Typography>
                      </Button>
                    )}

                  </Stack>
                }
                actions={
                  <>
                    {isActive && (
                      <TooltipZts
                        title={t("detail.disableUser")}
                        placement={"bottom"}
                        enterDelay={400}
                      >
                        <IconButton
                          color="primary"
                          className={"delete-button"}
                          onClick={handleDisableClicked}
                        >
                          <PersonOff/>
                        </IconButton>
                      </TooltipZts>
                    )}
                    <TooltipZts
                      title={tCommon("edit")}
                      placement={"bottom"}
                      enterDelay={400}
                    >
                      <IconButton
                        color="primary"
                        className={"edit-button"}
                        sx={{
                          opacity: isActive ? 1 : notActiveOpacity,
                          cursor: isActive ? "pointer" : "default"
                        }}
                        disableRipple={!isActive}
                        onClick={handleEditClicked}
                      >
                        <Edit fontSize={"small"}/>
                      </IconButton>
                    </TooltipZts>

                  </>
                }
              />

              {/* dettagli (sx) + elementi collegati (Travel policy, commesse, ecc..) (dx) */}
              <Stack
                mt={2}
                direction={{
                  xs: "column",
                  lg: "row"
                }}
                columnGap={2}
              >

                {/* parte sx */}
                <Stack
                  flexGrow={1}
                  direction={{
                    xs: "column-reverse",
                    sm: "row"
                  }}
                  columnGap={2}
                  width={"100%"}
                >
                  <Box
                    width={'100%'}
                    ml={{
                      xs: 0,
                      lg: 4.8
                    }}
                  >

                    {/* Dati generali */}
                    <GeneralData user={user}/>

                    {/* Ruoli */}
                    <Role
                      isTraveller={user.isTraveller}
                      isAdmin={user.isAdmin}
                      user={user}
                    />

                    {/* Dati anagrafici */}
                    <PersonalData user={user}/>

                    {/* Dati amministrativi */}
                    <AdministrativeData user={user}/>

                  </Box>

                  {/* parte dx */}
                  <Box
                    pl={{
                      xs: 0,
                      sm: 3
                    }}
                    sx={{
                      flexBasis: {
                        xs: '140px',
                        lg: '200px'
                      }
                    }}
                  >
                    <UserPicture
                      user={user}
                      size={Size.DETAIL}
                    />
                  </Box>

                </Stack>

                {/* elementi collegati */}
                <Items
                  handleCreditCardOpen={handleCreditCardOpen}
                  handleProjectsOpen={handleProjectsOpen}
                  handleTravelPoliciesOpen={handleTravelPoliciesOpen}
                  handleExpenseOpen={handleExpenseOpen}
                  disabled={!isActive}
                />

              </Stack>

            </Box>
          </Paper>

        </>
        }

      </Box>

      <TravelPolicies
        show={openTravelPolicies}
        onClose={handleTravelPoliciesClose}
        user={user}
      />

      <ProjectsList
        show={openProjects}
        onClose={handleProjectsClose}
        user={user}
      />

      <CreditCardList
        show={openCreditCard}
        onClose={handleCreditCardClose}
        user={user}
      />

      <ExpenseList
        show={openExpense}
        onClose={handleExpenseClose}
        user={user}
      />

      {
        popupTitle &&
        popupType &&
        <LicensePopup open={openLicensePopup} isPro={!free} closePopup={closePopup} titlePro={popupTitle} type={popupType}/>
      }

    </>
  );
}
