import { SelectOption } from '../components/base/select/model';

export const ITALIAN = "it-IT";
export const ENGLISH_GB = "en-GB";

export type Locale = typeof ITALIAN | typeof ENGLISH_GB;

export const getAvailableLocales = (): Locale[] => {
  return [ITALIAN];
  /*
  return [
    ITALIAN,
    ENGLISH_GB
  ];*/
}

export const getSelectableLocales = (t): SelectOption[] => {
  return getAvailableLocales().map(l => ({value: l, label: getLocaleLabel(t, l)}));
}

export const getLocaleLabel = (t, locale: Locale | undefined) => {
  if (!locale) {
    return '';
  }

  switch (locale) {
    case ITALIAN:
      return t('locale.italian', {ns: 'common'});
    case ENGLISH_GB:
      return t('locale.english_gb', {ns: 'common'});
    default:
      return '';
  }
}

export const getInputDateFormat = (locale: Locale) => {
  switch (locale) {
    case ENGLISH_GB:
      return 'MM/DD/YYYY';
    case ITALIAN:
    default:
      return 'DD/MM/YYYY';
  }
}

export const getInputDateTimeFormat = (locale: Locale) => {
  switch (locale) {
    case ENGLISH_GB:
      return 'MM/DD/YYYY HH:mm:ss';
    case ITALIAN:
    default:
      return 'DD/MM/YYYY HH:mm:ss';
  }
}

export const getCurrencySymbol = (currencyCode: string): string => {
  switch (currencyCode) {
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'USD':
      return '$';
    default:
      return currencyCode;
  }
}

export const getAmountGroupingSeparator = (locale: Locale): string => {
  switch (locale) {
    case ENGLISH_GB:
      return ',';
    case ITALIAN:
    default:
      return '.';
  }
}

export const getAmountDecimalSeparator = (locale: Locale): string => {
  switch (locale) {
    case ENGLISH_GB:
      return '.';
    case ITALIAN:
    default:
      return ',';
  }
}

export const formatStringNumberByLocale = (numberString: string, locale: Locale): string => {
  switch (locale) {
    case ITALIAN:
      return numberString.replace('.', ',');
    case ENGLISH_GB:
    default:
      return numberString;
  }
}

export const parseStringNumberByLocale = (numberString: string, locale: Locale): string => {
  switch (locale) {
    case ITALIAN:
      return numberString.replace(',', '.');
    case ENGLISH_GB:
    default:
      return numberString;
  }
}

export const getCalendarWeekDaysShort = (locale: Locale): string[] => {
  switch (locale) {
    case ITALIAN:
      return ['Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa', 'Do'];
    case ENGLISH_GB:
    default:
      return ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  }
}
