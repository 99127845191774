import {ExpNoteArchive, ExpNoteSortColumn, ExpNoteWithStaff} from "../../model";
import {ZtsTableColumn} from "../../../base/table/model";
import {Button, Typography} from "@mui/material";
import {ZtsTable} from "../../../base/table";
import React from "react";
import Box from "@mui/material/Box";
import {GreyColor} from "../../admin/list/model";
import {useExpNoteList} from "../../hooks/useExpNoteList";
import {useLoggedUser} from "../../../../hooks/useLoggedUser";
import {formatDate} from "../../../../util/DateUtil";
import {formatAmountZero} from "../../../../util/NumberUtil";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  expNotes: ExpNoteArchive[],
  ctaLabel?: string,
  sortExternal?: boolean,
  rowClickHandler?: Function
}

export const ExpNoteBaseList = (props: ComponentProps) => {

  const {
    expNotes,
    ctaLabel,
    sortExternal,
    rowClickHandler
  } = props;

  const {
    approverList: {
      sorting,
    },
    updateApproverSorting,
  } = useExpNoteList();

  const {userLocale: locale, companyDecimalNum: decimalNum, companyCurrencyCode} = useLoggedUser();

  const {t} = useTranslation("exp-note", {keyPrefix: "list"});
  const {t: tCommon} = useTranslation("common");

  const columns: ZtsTableColumn[] = [];

  const onRowClicked = (id) => {
    if (rowClickHandler) {
      rowClickHandler(id)
    }
  }

  // colonne comuni
  columns.push(
    {id: 'id', label: '#', typography: 'caption', cellColor: GreyColor},
    {id: 'traveller', label: t("traveller")},
    {id: 'description', label: tCommon("description")},
    {
      id: 'startDate',
      label: t("start"),
      typography: 'caption',
      cellColor: GreyColor,
      formatter: (value) => formatDate(value, locale)
    },
    {
      id: 'endDate',
      label: t("end"),
      cellColor: GreyColor,
      typography: 'caption',
      formatter: (value) => formatDate(value, locale)
    },
    {
      id: 'total',
      label: t("total"),
      typography: 'caption',
      formatter: (value) => formatAmountZero(value, locale, decimalNum, companyCurrencyCode)
    }
  );

  columns.push({
    id: 'cta', label: '', unsortable: true, displayOnHover: () => true, align: 'right', formatter: (r) => {
      return <Button
        variant="contained"
        className={"cta-list-button"}>
        <Typography variant={"button"}>{ctaLabel ? ctaLabel : t("show")}</Typography>
      </Button>
    }
  });

  const convertedExpNotes = expNotes.map(expNote =>
    ({
      check: expNote.id,
      id: expNote.code,
      traveller: expNote.traveller,
      description: expNote.description,
      startDate: expNote.startDate,
      endDate: expNote.endDate,
      total: expNote.expTotal,
    }));

  const onSortChanged = (orderBy: string, orderDir: 'asc' | 'desc') => {
    const sortColumn: ExpNoteSortColumn | undefined = Object.entries(ExpNoteSortColumn).find(([key, val]) => val === orderBy)?.[1];
    if (sortColumn) {
      updateApproverSorting({orderBy: sortColumn, orderDir});
    }
  }

  return (
    <Box
      flexGrow={1}
      mb={2}
      overflow={"auto"}
    >
      {convertedExpNotes && <ZtsTable
        columns={columns}
        rows={convertedExpNotes}
        forcedSort={sorting}
        notifySortChanged={onSortChanged}
        sortExternal={sortExternal}
        rowClickHandler={rowClickHandler}
      />}
    </Box>
  );

}
