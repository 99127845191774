import {
  clearExpNoteDetail,
  ExpNoteDetailState,
  getCreditCardMovsInExpNoteDetail,
  getExpensesInExpNoteDetail,
  getExpNoteInExpNoteDetail,
  getPaymentsInExpNoteDetail,
  reloadExpNoteInExpNoteDetail, setCreditCardMovsInExpNoteDetail,
  setDataInExpNoteDetail,
  setExpensesInExpNoteDetail,
  setPaymentsInExpNoteDetail
} from "../../../../reducers/ExpNoteDetail";

import {useDispatch, useSelector} from "react-redux";
import {ExpNoteCreCardMov, ExpNotePayment, ExpNoteWithStaff} from "../../model";
import {ExpNoteExpenseFull} from "../../expense/model";

export const useExpNoteDetail = () => {

  const expNote: ExpNoteWithStaff | null = useSelector<ExpNoteDetailState>(getExpNoteInExpNoteDetail);
  const expenses: ExpNoteExpenseFull[] | null = useSelector<ExpNoteDetailState>(getExpensesInExpNoteDetail);
  const payments: ExpNotePayment[] | null = useSelector<ExpNoteDetailState>(getPaymentsInExpNoteDetail);
  const creditCardMovs: ExpNoteCreCardMov[] | null = useSelector<ExpNoteDetailState>(getCreditCardMovsInExpNoteDetail);

  const dispatch = useDispatch();

  const updateData = (expNote: ExpNoteWithStaff | null, expenses: ExpNoteExpenseFull[] | null, payments: ExpNotePayment[] | null, creditCardMovs: ExpNoteCreCardMov[] | null) => {
    dispatch(setDataInExpNoteDetail(expNote, expenses, payments, creditCardMovs));
  }

  const updateExpenses = (expNote: ExpNoteWithStaff | null, expenses: ExpNoteExpenseFull[] | null) => {
    dispatch(setExpensesInExpNoteDetail(expNote, expenses));
  }

  const updatePayments = (expNote: ExpNoteWithStaff | null, payments: ExpNotePayment[] | null, expenses: ExpNoteExpenseFull[] | null) => {
    dispatch(setPaymentsInExpNoteDetail(expNote, payments, expenses));
  }

  const updateCreditCardMovs = (creditCardMovs: ExpNoteCreCardMov[] | null) => {
    dispatch(setCreditCardMovsInExpNoteDetail(creditCardMovs));
  }

  const reloadExpNote = (expNote: ExpNoteWithStaff) => {
    dispatch(reloadExpNoteInExpNoteDetail(expNote));
  }

  const clearExpNote = () => {
    dispatch(clearExpNoteDetail());
  }

  return {
    expNote,
    expenses,
    payments,
    creditCardMovs,
    updateData,
    updateExpenses,
    updatePayments,
    updateCreditCardMovs,
    reloadExpNote,
    clearExpNote
  }

}
