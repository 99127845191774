import {isActiveUser, User} from "../model";
import {Card, Menu, MenuItem} from "@mui/material";
import React, {SyntheticEvent, useState} from "react";
import {canceledStateCode} from "../../model";
import {useNavigate} from "react-router-dom";
import {UserCardContent} from "./UserCardContent";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  user: User,
  onOpen: Function,
  onToggleSelect: Function,
  onEnable: Function,
  onDisable: Function,
  selected: boolean,
  selectMode: boolean
}

export const UserCard = ({user, onOpen, onToggleSelect, onEnable, onDisable, selected, selectMode}: ComponentProps) => {

  const navigate = useNavigate();

  const {t} = useTranslation(["user", "common"]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userId, setUserId] = useState<number>(0);

  const handleMenuClick = (userId: number, e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setUserId(userId);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setUserId(0);
  };

  const onSelectItem = (id: number, e: SyntheticEvent) => {
    onToggleSelect(id);
    e.preventDefault();
    setAnchorEl(null);
  }

  const onEditItem = (userId: number) => {
    navigate(`edit/${userId}`);
  }

  const onEnableDisableUser = (userId: number) => {
    if (isActive) {
      onDisable(userId);
    } else {
      onEnable(userId);
    }
    setAnchorEl(null);
  }

  let className = "user-card";
  if (user.state === canceledStateCode) {
    className += " disabled-card";
  }
  if (selected) {
    className += " selected-card";
  }

  const isActive = isActiveUser(user);

  return (
    <>
      <Card
        className={className}
        sx={{
          cursor: selectMode ? "pointer" : "default"
        }}
        onClick={() => selectMode ? onToggleSelect(user.id) : {}}
      >
        <UserCardContent
          user={user}
          handleMenuClick={handleMenuClick}
          onOpen={onOpen}
        />
      </Card>


      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={(e) => onSelectItem(userId, e)}
        >
          {t(selected ? "user:list.card.unselect" : "user:list.card.select")}
        </MenuItem>
        <MenuItem
          onClick={() => onEditItem(userId)}
        >
          {t("common:card.edit")}
        </MenuItem>
        <MenuItem
          onClick={() => onEnableDisableUser(userId)}
        >
          {t(isActive ? "user:list.card.disable" : "user:list.card.enable")}
        </MenuItem>
        {/*<MenuItem*/}
        {/*  className="text-danger"*/}
        {/*  onClick={() => handleShowDeleteModal(userId)}*/}
        {/*>*/}
        {/*  Elimina*/}
        {/*</MenuItem>*/}
      </Menu>

    </>
  );
}
