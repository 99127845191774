import {Tooltip, TooltipClasses, TooltipProps} from "@mui/material";

type ComponentProps = {
  extraClasses?: Partial<TooltipClasses>
}

export const TooltipZts = (props: ComponentProps & TooltipProps) => {

  const {
    extraClasses,
    ...tooltipProps
  } = props;

  let classes: Partial<TooltipClasses>;

  if (extraClasses) {
    classes = {
      ...extraClasses,
      tooltip: `tooltip-zts ${extraClasses.tooltip}`
    };
  } else {
    classes = {
      tooltip: 'tooltip-zts'
    };
  }

  const config: TooltipProps = {
    ...tooltipProps,
    classes
  }

  return (
    <Tooltip
      {...config}
    >
      {props.children}
    </Tooltip>
  );
}
