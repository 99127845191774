import {useExpNoteList} from "../../hooks/useExpNoteList";
import {ExpNoteListTravellerFilter} from "../../../../reducers/ExpNoteList";
import Box from "@mui/material/Box";
import {Alert, Paper, Skeleton, Stack, Typography} from "@mui/material";
import {PageTitle} from "../../../layout/page-title";
import IconButton from "@mui/material/IconButton";
import {ExitToApp} from "@mui/icons-material";
import {getTabCounter, getTabLabel, Tab, TabBar} from "../../../base/tab-bar";
import {
  ExpNoteArchive,
  ExpNoteFilter,
  ExpNoteStateFE,
  ExpNoteWithStaff,
  getApproverExpNoteStatesFE,
  getExpNoteStateFELabel
} from "../../model";
import React, {useEffect, useState} from "react";
import {useErrorMessage} from "../../../../util/ErrorUtil";
import {DateFormat, DETAIL_URL, GenericPagedList} from "../../../model";
import {getExpNotesToApprove} from "../../Service";
import {ALL_EXPNOTE_DEFAULT_PAGE_SIZE, ExpNoteListFilter, ExpNoteListFilterType} from "../../admin/list/model";
import {convertApproverApiFilterToPersistedFilter, convertApproverPersistedFilterToApiFilter,} from "./model";
import {ExpNoteFilters} from "./filter";
import {ExpNoteBaseList} from "./BaseList";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TooltipZts} from "../../../base/tooltip";

const InitApiFilter: ExpNoteFilter = {
  searchText: null,
  startDate: null,
  contabDate: null
}

export const ExpNoteApproverList = () => {

  const {t} = useTranslation('exp-note');

  const {
    approverList: {
      stateSelected,
      filter
    },
    updateApproverListType,
    updateApproverFilter,
  } = useExpNoteList();

  const [allExpNotes, setAllExpNotes] = useState<ExpNoteArchive[]>([]);
  const [expNotes, setExpNotes] = useState<ExpNoteArchive[]>([]);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [apiFilter, setApiFilter] = useState<ExpNoteFilter>(filter ? convertApproverPersistedFilterToApiFilter(filter) : InitApiFilter);

  const [filterChangeTO, setFilterChangeTO] = useState<any>(null);

  const {convertError} = useErrorMessage();

  const navigate = useNavigate();

  const fetchExpNotes = (fetchFunction: (pageSize: number, page: number) => Promise<GenericPagedList<ExpNoteArchive>>, pageSize = ALL_EXPNOTE_DEFAULT_PAGE_SIZE, page = 1, expNotes: ExpNoteArchive[] = []): Promise<ExpNoteArchive[]> => {
    return fetchFunction(pageSize, page)
      .then(res => {
        expNotes.push(...res.elements);
        if (res.totalItems === expNotes.length) {
          return expNotes;
        }
        return fetchExpNotes(fetchFunction, pageSize, res.page + 1, expNotes);
      });
  }

  useEffect(() => {
    let fetchFunction: ((pageSize: number, page: number) => Promise<GenericPagedList<ExpNoteArchive>>) | null;
    let getExpNotes: Promise<ExpNoteArchive[]> | null;
    switch (stateSelected) {
      case ExpNoteStateFE.DA_APPROVARE:
        fetchFunction = getExpNotesToApprove;
        break;
      default:
        fetchFunction = null;
    }

    if (fetchFunction) {
      getExpNotes = fetchExpNotes(fetchFunction);
      setLoading(true);
      getExpNotes
        .then(res => {
          setAllExpNotes(res)
        })
        .catch(err => setErrorMsg(err.message))
        .finally(() => setLoading(false));
    } else {
      setAllExpNotes([]);
    }
  }, [stateSelected]);

  useEffect(() => {
    if (stateSelected !== ExpNoteStateFE.ARCHIVIATE) {
      setExpNotes(allExpNotes);
    }
  }, [allExpNotes, stateSelected]);

  const getExpNoteCounter = (): number => {
    if (stateSelected === ExpNoteStateFE.ARCHIVIATE) {
      return 0;
    } else {
      return allExpNotes ? allExpNotes.length : 0;
    }
  }

  const getStatusTabs = (): Tab[] => {
    const count = getExpNoteCounter();

    return getApproverExpNoteStatesFE().map(state => ({
      key: state,
      render: (isSelected) => {
        return <>
          {isSelected && count > 0 && getTabCounter(count)}
          {getTabLabel(getExpNoteStateFELabel(state, t))}
        </>
      }
    }));
  }

  const handleStateSelected = (state: string) => {
    setAllExpNotes([]);
    // @ts-ignore
    updateApproverListType(Object.values(ExpNoteStateFE).find(e => e === state));
    setApiFilter(InitApiFilter);
  }

  const getFilterName = (filterType: ExpNoteListFilterType): string => {
    switch (filterType) {
      case ExpNoteListFilterType.SEARCH_TEXT:
        return 'searchText';
      case ExpNoteListFilterType.START_DATE:
        return 'startDate';
    }
    return '';
  }

  const getFilterValues = (filter: ExpNoteListFilter): any[] | null => {
    switch (filter.type) {
      case ExpNoteListFilterType.SEARCH_TEXT:
        return filter.values;
      case ExpNoteListFilterType.START_DATE:
        if (filter.values) {
          return filter.values.map(v => {
            if (!v) {
              return null;
            }
            try {
              if (v.isValid()) {
                return v.format(DateFormat);
              }
            } catch (error) {
              // quando riapro la lista e imposto il filtro salvato in redux
              // la data è già formattata come stringa quindi non è disponibile
              // la funzione isValid() nè la funzione format() ma posso assumere
              // che il valore sia valido proprio perchè era già stato formattato
              // in precedenza
              return v;
            }
            return null;
          });
        }
        return null;
    }
    return null;
  }

  const isFilterChanged = (prevFilter: ExpNoteFilter, newFilter: ExpNoteFilter): boolean => {
    //console.log(prevFilter, newFilter)

    let changed = false;
    Object.values(ExpNoteListFilterType).forEach(ft => {
      // @ts-ignore
      const filterName = getFilterName(ft);
      let prevValue = prevFilter[filterName];
      if (!prevValue) {
        if (ft === ExpNoteListFilterType.START_DATE) {
          prevValue = [null, null];
        } else {
          prevValue = [''];
        }
      }

      let newValue = newFilter[filterName];
      if (!newValue) {
        if (ft === ExpNoteListFilterType.START_DATE) {
          newValue = [null, null];
        } else {
          newValue = [''];
        }
      }

      if (ft === ExpNoteListFilterType.START_DATE) {
        if (prevValue[0] !== newValue[0] || prevValue[1] !== newValue[1]) {
          changed = true;
        }
      } else {
        if (prevValue[0] !== newValue[0]) {
          changed = true;
        }
      }
    });
    return changed;
  }

  const handleFilterChange = (filters: ExpNoteListFilter[]) => {
    if (stateSelected === ExpNoteStateFE.ARCHIVIATE) {
      if (filters) {
        const prevApiFilter = {...apiFilter};
        const newApiFilter = {...apiFilter};
        filters
          .forEach(filter => {
            const filterName = getFilterName(filter.type);
            const filterValue = filter.enabled ? getFilterValues(filter) : null;
            if (filterValue) {
              newApiFilter[filterName] = filterValue;
            } else {
              newApiFilter[filterName] = null;
            }
          });

        if (isFilterChanged(prevApiFilter, newApiFilter)) {
          // console.log('apiFilter', prevApiFilter, newApiFilter);
          // debounce
          if (filterChangeTO) {
            clearTimeout(filterChangeTO);
          }
          setFilterChangeTO(setTimeout(() => {
            setApiFilter(newApiFilter);
            updateApproverFilter(convertApproverApiFilterToPersistedFilter(newApiFilter));
          }, 400));
        }
      }
    } else {
      let newExpNotes = allExpNotes;
      if (newExpNotes && newExpNotes.length > 0 && filters) {
        const persistedFilter: ExpNoteListTravellerFilter = {};
        filters
          .filter(f => f.enabled && f.values)
          .forEach(filter => {
            persistedFilter[filter.type] = filter.values;
            switch (filter.type) {
              case ExpNoteListFilterType.SEARCH_TEXT:
                const filterValue = filter.values[0].toUpperCase();
                newExpNotes = newExpNotes.filter(e =>
                  e.traveller.toUpperCase().indexOf(filterValue) !== -1 ||
                  e.description.toUpperCase().indexOf(filterValue) !== -1);
                break;
              case ExpNoteListFilterType.START_DATE:
                newExpNotes = newExpNotes.filter(e => {
                  if (!filter.values) {
                    return true;
                  }
                  const startDate = filter.values[0];
                  const endDate = filter.values[1];

                  return (!startDate || !startDate.isValid() || startDate.isSameOrBefore(e.startDate, 'day')) &&
                    (!endDate || !endDate.isValid() || endDate.isSameOrAfter(e.endDate, 'day'))
                });
                break;
            }
          });

        updateApproverFilter(persistedFilter);
      }
      setExpNotes(newExpNotes);
    }
  }

  const getFilterElement = (): JSX.Element | null => {
    if (loading) {
      return <Box mt={2} width={"300px"} height={"40px"}><Skeleton variant={"rectangular"} height={"100%"}/></Box>;
    }

    return (
      <ExpNoteFilters
        onFilterChange={handleFilterChange}
      />
    );
  }

  const selectRow = (id) => {
    navigate(`..${DETAIL_URL}/${id}`);
  }

  const getListElement = (): JSX.Element | null => {
    if (loading) {
      return <Box mt={2} width={"100%"} height={"100px"}><Skeleton variant={"rectangular"} height={"100%"}/></Box>;
    }

    switch (stateSelected) {
      case ExpNoteStateFE.DA_APPROVARE:
        return (
          <ExpNoteBaseList
            ctaLabel={t("list.open")}
            expNotes={expNotes}
            rowClickHandler={selectRow}
          />
        )
    }

    return null;
  }

  return (
    <>
      <Box
        width={"100%"}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        mx={"auto"}
      >

        {errorMsg && <Alert
          severity={"error"}
          variant={"filled"}
          sx={{mb: 4}}
          onClose={() => setErrorMsg(null)}>{errorMsg}</Alert>}


        {/* Titolo */}
        <Box flexGrow={1}>
          <PageTitle
            title={
              <Typography variant={"h3"} mr={3}>{t('list.title')}</Typography>
            }
          />
        </Box>

        {/*Liste*/}
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            mt: 2,
            px: 3.5,
            py: 2.5,
            flexGrow: 1,
            height: "100%"
          }}
        >

          {/*Barra di selezione dello stato*/}
          <TabBar
            selected={stateSelected}
            tabs={getStatusTabs()}
            onSelect={handleStateSelected}
          />

          {/* Filtri */}
          <Box mt={3}>
            {getFilterElement()}
          </Box>

          {/*Lista*/}
          {getListElement()}

          {/*{stateSelected === ExpNoteStateFE.ARCHIVIATE && !loadingArchivedExpNotes && (
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Pagination
                count={totalPages}
                page={pagination.currentPage}
                onChange={handlePageChange}
                shape="rounded"/>
            </Box>
          )}*/}

        </Paper>

      </Box>

    </>
  );
}
