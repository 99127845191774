import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const TPVatManagementPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile abilitare la gestione dell&rsquo;IVA su una specifica voce di spesa. <strong>Calcola
            automaticamente l&rsquo;IVA detraibile delle spese di trasferta</strong> e recuperala dalle tue note spese.
        </p>
      );
    default:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile abilitare la gestione dell&rsquo;IVA su una specifica voce di spesa. <strong>Calcola
            automaticamente l&rsquo;IVA detraibile delle spese di trasferta</strong> e recuperala dalle tue note spese.
        </p>
      );
  }
}
