enum CurrentListActionType {
  SET_FILTER = 'CURRENT_LIST/SET_FILTER',
  CLEAR_FILTER = 'CURRENT_LIST/CLEAR_FILTER',
  SET_BIN_MODE = 'CURRENT_LIST/SET_BIN_MODE',
}

export interface CurrentListState {
  filters: any[] | null,
  binMode: boolean
}

export interface CurrentListAction {
  type: CurrentListActionType,
  filters?: any[],
  binMode?: boolean
}

const reducer = (
  state: CurrentListState = {
    filters: null,
    binMode: false
  },
  action: CurrentListAction) => {
  switch (action.type) {
    case CurrentListActionType.SET_FILTER:
      return {...state, filters: action.filters};
    case CurrentListActionType.CLEAR_FILTER:
      return {...state, filters: null}
    case CurrentListActionType.SET_BIN_MODE:
      return {...state, binMode: action.binMode}
  }
  return state;
}

// action creator
export const setCurrentListFilters = (filters: any[]): CurrentListAction => ({
  type: CurrentListActionType.SET_FILTER,
  filters
});
export const setCurrentListBinMode = (binMode: boolean): CurrentListAction => ({
  type: CurrentListActionType.SET_BIN_MODE,
  binMode
});

export const clearCurrentListFilters = (): CurrentListAction => ({
  type: CurrentListActionType.CLEAR_FILTER
});

// utility
export const getCurrentListFilters = (state: { CurrentList: CurrentListState }): any[] | null => {
  return state.CurrentList.filters;
}

export const getCurrentListBinMode = (state: { CurrentList: CurrentListState }): boolean => {
  return state.CurrentList.binMode;
}

export default reducer;













// enum CurrentListActionType {
//   SET_FILTER = 'CURRENT_LIST/SET_FILTER',
//   CLEAR_FILTER = 'CURRENT_LIST/CLEAR_FILTER',
// }
//
// export interface CurrentListFilter {
//   type: number,
//   value: any
// }
//
// export interface CurrentListState {
//   filters: CurrentListFilter[]
// }
//
// export interface CurrentListAction {
//   type: CurrentListActionType,
//   filters?: CurrentListFilter[]
// }
//
// const reducer = (
//   state: CurrentListState = {
//     filters: []
//   },
//   action: CurrentListAction) => {
//   switch (action.type) {
//     case CurrentListActionType.SET_FILTER:
//       return {...state, filters: action.filters};
//     case CurrentListActionType.CLEAR_FILTER:
//       return {...state, filters: []}
//   }
//   return state;
// }
//
// // action creator
// export const setCurrentListFilters = (filters: CurrentListFilter[]): CurrentListAction => ({
//   type: CurrentListActionType.SET_FILTER,
//   filters
// });
//
// export const clearCurrentListFilters = (): CurrentListAction => ({
//   type: CurrentListActionType.CLEAR_FILTER
// });
//
// // utility
// export const getCurrentListFilters = (state: { CurrentList: CurrentListState }): CurrentListFilter[] => {
//   return state.CurrentList.filters;
// }
//
// export default reducer;
