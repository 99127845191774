import Box from "@mui/material/Box";
import {Grid, Skeleton, Stack} from "@mui/material";
import React from "react";

export const AccountEditLoader = () => {
  return (
    <>
      <Box width={"100%"} mb={2}>
        <Skeleton variant="rectangular" width={"100%"} height={70}/>
      </Box>
      <Stack
        mt={2}
        direction={{
          xs: "column-reverse",
          lg: "row"
        }}
        columnGap={2}
      >
        <Grid container
              mt={4}
              spacing={2}
        >
          <Grid
            item
            xs={12}>
            <Skeleton variant="rectangular" height={20}/>
          </Grid>
          <Grid
            item
            xs={12} sm={6}>
            <Skeleton variant="rectangular" height={50}/>
          </Grid>
          <Grid
            item
            xs={12} sm={6}>
            <Skeleton variant="rectangular" height={50}/>
          </Grid>
          <Grid
            item
            xs={12} sm={6}>
            <Skeleton variant="rectangular" height={50}/>
          </Grid>
          <Grid
            item
            xs={12}>
            <Skeleton variant="rectangular" height={20}/>
          </Grid>
          <Grid
            item
            xs={12}>
            <Skeleton variant="rectangular" height={20}/>
          </Grid>
        </Grid>
        <Box>
          <Skeleton variant="circular" width={100} height={100}/>
        </Box>
      </Stack>
    </>
  );
}
