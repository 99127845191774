export interface AutocompleteGenericOption {
  id: number | null;
  code: string;
  desc: string;
  icon: string | null;
  listLabel: string;
  selectedLabel: string;
}

export interface AutocompleteCurrencyOption extends AutocompleteGenericOption {
  decimalNum: number;
}

export const autocompleteFilter = (text: string, option: AutocompleteGenericOption, codeAndDesc: boolean | undefined): boolean => {
  return (codeAndDesc && option.code.toLowerCase().indexOf(text) !== -1) ||
    option.desc.toLowerCase().indexOf(text) !== -1
}

export const createAutocompleteGenericOption = (id: number, code: string, description: string, icon?: string): AutocompleteGenericOption => {
  return {
    id,
    code,
    desc: description,
    icon: icon ? icon : null,
    selectedLabel: description,
    listLabel: `${code} - ${description}`
  };
}

