import { BatchJobState } from '../components/jobs/model';

enum CurrentJobsActionType {
  ENQUEUE_TO_RUN_JOB = 'CURRENT_JOBS/ENQUEUE_TO_RUN_JOB',
  DEQUEUE_TO_RUN_JOB = 'CURRENT_JOBS/DEQUEUE_TO_RUN_JOB',
  ENQUEUE_RUNNING_JOB = 'CURRENT_JOBS/ENQUEUE_RUNNING_JOB',
  DEQUEUE_RUNNING_JOB = 'CURRENT_JOBS/DEQUEUE_RUNNING_JOB',
  NOTIFY_COMPLETED_JOB = 'CURRENT_JOBS/NOTIFY_COMPLETED_JOB',
  REMOVE_COMPLETED_JOB_NOTIFY = 'CURRENT_JOBS/REMOVE_COMPLETED_JOB_NOTIFY'
}

export enum JobType {
  PRINT,
  EXPENSE_REPORT,
  EXP_NOTE_REPORT,
  RECONCILIATION_REPORT,
  PRINT_WITH_ATTACH,
  EXP_NOTE_EXPORT
}

export interface RunningJob {
  id: string,
  timeStamp: Date,
  notify?: boolean
}

export interface CompletedJobNotify {
  job: RunningJob,
  state: BatchJobState
}

export interface ToRunJob {
  type: JobType
  id: number,
  locale?: string,
  startDate?: Date,
  endDate?: Date,
  date?: Date,
  parameters?: object
}

export interface CurrentJobsState {
  runningJobs: RunningJob[]
  toRunJobs: ToRunJob[],
  completedJobNotifies: CompletedJobNotify[]
}

export interface CurrentJobsAction {
  type: CurrentJobsActionType,
  runningJob?: RunningJob,
  toRunJob?: ToRunJob,
  completedJobNotify?: CompletedJobNotify
}

const reducer = (state: CurrentJobsState = {
  runningJobs: [],
  toRunJobs: [],
  completedJobNotifies: []
}, action: CurrentJobsAction) => {
  if (action.type === CurrentJobsActionType.ENQUEUE_RUNNING_JOB) {
    if (state.runningJobs.length < 3) {
      const isInQueue = state.runningJobs.find(value => value.id === action.runningJob?.id && value.timeStamp === action.runningJob.timeStamp);
      if (!isInQueue) {
        return {...state, runningJobs: [...state.runningJobs, action.runningJob]};
      }
    }
    return state;
  } else if (action.type === CurrentJobsActionType.DEQUEUE_RUNNING_JOB) {
    if (state.runningJobs.length > 0) {
      const newJobsArray = state.runningJobs.filter(job => {
        if (action.runningJob) {
          return job.id !== action.runningJob.id;
        }
      })
      return {...state, runningJobs: newJobsArray};
    }
    return state;
  } else if (action.type === CurrentJobsActionType.ENQUEUE_TO_RUN_JOB) {
    if (state.toRunJobs.length < 3) {
      const isInQueue = state.toRunJobs.find(value => value.id === action.toRunJob?.id && value.type === action.toRunJob.type);
      if (!isInQueue) {
        return {...state, toRunJobs: [...state.toRunJobs, action.toRunJob]};
      }
    }
    return state;
  } else if (action.type === CurrentJobsActionType.DEQUEUE_TO_RUN_JOB) {
    if (state.toRunJobs.length > 0) {
      const newJobsArray = state.toRunJobs.filter(job => {
        if (action.toRunJob) {
          return job.id !== action.toRunJob.id;
        }
      })
      return {...state, toRunJobs: newJobsArray};
    }
  } else if (action.type === CurrentJobsActionType.NOTIFY_COMPLETED_JOB) {
    return {...state, completedJobNotifies: [...state.completedJobNotifies, action.completedJobNotify]};
  } else if (action.type === CurrentJobsActionType.REMOVE_COMPLETED_JOB_NOTIFY) {
    return {
      ...state,
      completedJobNotifies: state.completedJobNotifies
        .filter(cj => cj.job.id !== action.completedJobNotify?.job.id)
    };
  }
  return state;
}

export const enqueueToRunJob = (toRunJob: ToRunJob): CurrentJobsAction => ({
  type: CurrentJobsActionType.ENQUEUE_TO_RUN_JOB,
  toRunJob
})

export const dequeueToRunJob = (toRunJob: ToRunJob): CurrentJobsAction => ({
  type: CurrentJobsActionType.DEQUEUE_TO_RUN_JOB,
  toRunJob
})


export const enqueueRunningJob = (runningJob: RunningJob): CurrentJobsAction => ({
  type: CurrentJobsActionType.ENQUEUE_RUNNING_JOB,
  runningJob
})

export const dequeueRunningJob = (runningJob: RunningJob): CurrentJobsAction => ({
  type: CurrentJobsActionType.DEQUEUE_RUNNING_JOB,
  runningJob
})

export const notifyCompletedJob = (completedJobNotify: CompletedJobNotify): CurrentJobsAction => ({
  type: CurrentJobsActionType.NOTIFY_COMPLETED_JOB,
  completedJobNotify
});

export const removeCompletedJobNotify = (completedJobNotify: CompletedJobNotify): CurrentJobsAction => ({
  type: CurrentJobsActionType.REMOVE_COMPLETED_JOB_NOTIFY,
  completedJobNotify
});

export const getToRunJobs = (state) => {
  return state.CurrentJobs ? state.CurrentJobs.toRunJobs : null;
}

export const getRunningJobs = (state) => {
  return state.CurrentJobs ? state.CurrentJobs.runningJobs : null;
}

export const getCompletedJobNotifies = (state) => {
  return state.CurrentJobs ? state.CurrentJobs.completedJobNotifies : null;
}

export default reducer;
