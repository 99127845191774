import {Box, Dialog, Grid, Stack, Typography} from "@mui/material";
import proImage from "../../assets/images/go_to_pro.png";
import * as React from "react";
import {ReactChild, ReactElement, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLoggedUser} from "../../hooks/useLoggedUser";
import {getPopupcontent, PopupContentType} from "./model";
import {getStoreUrl} from "./Service";

interface LicensePopupProps {
  open: boolean,
  isPro: boolean,
  closePopup: () => void,
  titlePro: string,
  type: PopupContentType,
  children?: ReactChild,
  freeButtonText?: boolean
}

export const LicensePopup = ({open, isPro, closePopup, titlePro, type, children, freeButtonText}: LicensePopupProps) => {
  const {t} = useTranslation('license');
  const {user, userLocale} = useLoggedUser();
  const [storeUrl, setStoreUrl] = useState('');

  const asyncGetAndSetStoreUrl = async (): Promise<void> => {
    const url = await getStoreUrl(user);
    setStoreUrl(url);
  }

  useEffect(() => {
    asyncGetAndSetStoreUrl().then();
  }, []);

  let body: ReactElement = getPopupcontent(type, userLocale);

  const handleClick = () => {
    if (storeUrl) {
      window.open(storeUrl, '_blank');
      closePopup();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={closePopup}
      fullWidth
      maxWidth={isPro ? 'sm' : 'md'}
      className={'license-popup'}
    >
      {
        isPro && (
          <Grid container overflow={'hidden'}>
            <Grid item xs={12}>
              <Box display={'flex'} flexDirection={'column'} m={4} alignItems={'center'} rowGap={'20px'}>
                <Typography variant={'h3'} fontWeight={600} textTransform={"uppercase"}>{t(`free.menu.title`)}</Typography>
                <Box ml={3}>
                  <Stack alignItems={'center'} rowGap={1}>
                    <Typography align={'center'} variant={'h4'}>{t('free.menu.body.message1')}</Typography>
                    <Typography fontSize={'1rem'}>{t('free.menu.body.message2')}</Typography>
                  </Stack>
                </Box>
                <Box flexGrow={1}/>
                <Box
                  width={'fit-content'}
                  sx={{
                    border: '1px',
                    borderRadius: '25px',
                    backgroundColor: '#14c6d5',
                    cursor: 'pointer'
                  }}
                  px={2}
                  py={1}
                  onClick={() => {}}
                >
                  <Typography
                    variant={'button'}
                    color={'white'}
                    align={'center'}
                  >
                    {t('free.button')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )
      }
      {
        !isPro && body &&
        (
          <Grid container overflow={'hidden'}>
            <Grid item xs={9} p={2}>
              <Box display={'flex'} justifyContent={'center'} minHeight={'100%'}>
                <Box display={'flex'} flexDirection={'column'} m={2} alignItems={'center'} rowGap={'20px'}>
                  <Typography variant={'h3'} color={'#8414d5'} fontWeight={600} textTransform={"capitalize"}>{titlePro}</Typography>
                  <Box
                    ml={3}
                  >
                    {
                      children ? children : body
                    }
                  </Box>
                  <Box flexGrow={1} />
                  <Box
                    width={'fit-content'}
                    sx={{
                      border: '1px',
                      borderRadius: '25px',
                      backgroundColor: '#8414d5',
                      cursor: 'pointer'
                    }}
                    p={2}
                    onClick={handleClick}
                  >
                    <Typography
                      variant={'button'}
                      color={'white'}
                      align={'center'}
                    >
                      {freeButtonText ? t('free.button') : t('pro.button')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: '#8414d5'}}>
              <img src={proImage} alt={'Pro image'} style={{textAlign: 'center', width: '100%', padding: '25px'}}/>
            </Grid>
          </Grid>
        )
      }
    </Dialog>
  );
}
