import {Supplier} from "../model";
import {Card, Menu, MenuItem} from "@mui/material";
import React, {SyntheticEvent, useState} from "react";
import {canceledStateCode} from "../../model";
import {useNavigate} from "react-router-dom";
import {SupplierCardContent} from "./SupplierCardContent";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  supplier: Supplier,
  onOpen: Function,
  onRestore: Function
}

export const SupplierCard = ({supplier, onOpen, onRestore}: ComponentProps) => {
  const {t} = useTranslation('supplier', {keyPrefix: 'detail.button'});

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [supplierId, setSupplierId] = useState<number>(0);

  const handleMenuClick = (supplierId: number, e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setSupplierId(supplierId);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSupplierId(0);
  };

  const onSelectItem = (id: number, e: SyntheticEvent) => {
    // onToggleSelect(id);
    e.preventDefault();
    setAnchorEl(null);
  }

  const onEditItem = (supplierId: number) => {
    navigate(`/suppliers/edit/${supplierId}`);
  }

  let className = "user-card"; /*TODO vedere come impostar css per centri di costo */
  if (supplier.state === canceledStateCode) {
    className += " disabled-card";
  }

  return (
    <>
      <Card
        className={className}>
        <SupplierCardContent
          supplier={supplier}
          handleMenuClick={handleMenuClick}
          onOpen={onOpen}
          onRestore={onRestore}
        />

      </Card>


      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => onEditItem(supplierId)}
        >
          {t("modify")}
        </MenuItem>
      </Menu>

    </>
  );
}
