import { default as axios } from 'axios';
import { getApiUrl } from '../../config/environment';
import { createBlobError, createError } from '../../util/ErrorUtil';
import { FileUploadReq, FileUploadResp } from '../model';
import { FileImportRequest, FileImportResponse } from './model';
import { TemplateFileType } from './ImportPage';


export const downloadTemplateFile = async (templateFileType: string): Promise<Blob> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.get<Blob>(`${apiUrl}/import/${templateFileType}`, {
      responseType: 'blob'
    });
    return resp.data;
  } catch (err: any) {
    throw await createBlobError(err);
  }
}

export const importFileData = async (fileImportRequest: FileImportRequest): Promise<FileImportResponse> => {
  try {
    const apiUrl = getApiUrl();
    let subUrl: string = '';

    if (fileImportRequest.templateFileType === TemplateFileType.GENERAL_ACC) {
      subUrl = 'generalaccimport';
    } else if (fileImportRequest.templateFileType === TemplateFileType.LEVEL) {
      subUrl = 'level';
    } else if (fileImportRequest.templateFileType === TemplateFileType.PROJECT) {
      subUrl = 'project';
    } else if (fileImportRequest.templateFileType === TemplateFileType.USERS) {
      subUrl = 'users';
    }

    const resp = await axios.post(`${apiUrl}/import/${subUrl}`, fileImportRequest);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}
export const uploadFileExcel = async (request: FileUploadReq): Promise<FileUploadResp> => {
  try {
    const apiUrl = getApiUrl();
    //const resp = await axios.post(`${apiUrl}/import/uploadFile`, request);
    const resp = await axios.post(`${apiUrl}/uploads/uploadFileExcel`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}
