import {StaffCreditCardsSaveReq, User} from "../model";
import React, {useEffect, useState} from "react";

import * as Yup from "yup";
import {ValidationError} from "yup";
import {CommonDialog} from "../../base/common-dialog";
import {Button, Divider, Skeleton, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {SingleCreditCard} from "./SingleCreditCard";
import {getStaffCreditCards, saveStaffCreditCards} from "../Service";
import moment from "moment";
import {DateFormat} from "../../model";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Add} from "@mui/icons-material";
import {HelpCurrentPage} from "../../../reducers/Help";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {getActiveAccounts} from "../../accounts/Service";
import {useTranslation} from "react-i18next";
import {yupMaxNullableString, yupMinMaxRequiredString, yupRequiredDate} from "../../../util/YupUtil";
import {useHelp} from "../../help/hook";

type ComponentProps = {
  onClose: Function,
  show: boolean,
  user: User | null
}

type CardFormValue = {
  _id?: number,
  cardNum: string,
  cardDesc: string,
  expireDate: Date | null,
  account: AutocompleteGenericOption | null
}

export type CardsFormValues = {
  cards: Array<CardFormValue> | null;
};

export const CreditCardList = ({show, onClose, user}: ComponentProps) => {

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [accountOpts, setAccountOpts] = useState<AutocompleteGenericOption[]>([]);

  const {t} = useTranslation();

  useHelp({
    paths: [
      {
        path: '/modal',
        page: show ? HelpCurrentPage.USER_CARD : HelpCurrentPage.USER_DETAIL
      }
    ]
  });

  const validationSchema = Yup.object({
    cards: Yup.array(
      Yup.object({
        cardNum: yupMinMaxRequiredString(4, 20, t),
        cardDesc: yupMaxNullableString(50, t),
        expireDate: yupRequiredDate(t),
        account: Yup.object().nullable()
      })
    ).test((cards) => {
      // controllo che non esistano numeri duplicati
      if (cards) {
        let dupIndex = -1;
        cards.forEach((card1, index1) => {
          cards.forEach((card2, index2) => {
            if (index1 !== index2 && card1.cardNum === card2.cardNum) {
              dupIndex = index1;
            }
          })
        });

        if (dupIndex !== -1) {
          return new ValidationError(t('user:detail.items.creditCards.duplicateNum'), undefined, `cards.${dupIndex}.cardNum`);
        }
      }

      return true;
    }),
  });

  const formMethods = useForm<CardsFormValues>({
    defaultValues: {cards: []},
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  const {fields, append, replace} = useFieldArray({
    name: "cards",
    control: formMethods.control
  });

  useEffect(() => {
    if (show) {
      setLoading(true);

      Promise.all([
        getStaffCreditCards(user ? user.id : 0),
        getActiveAccounts()
      ])
        .then(res => {
          const cards = res[0];
          const accounts = res[1];

          let mappedCards: CardFormValue[] = [];
          if (cards && cards.length > 0) {
            mappedCards = cards.map(c => ({
              _id: c.id,
              cardNum: c.cardNum,
              cardDesc: c.cardDesc,
              expireDate: moment(c.expireDate, DateFormat).toDate(),
              account: c.account ?
                createAutocompleteGenericOption(c.account.id, c.account.code, c.account.description) :
                null,
            }))
          } else {
            mappedCards.push({cardNum: '', cardDesc: '', expireDate: null, account: null});
          }
          formMethods.reset({cards: mappedCards});

          if (accounts && accounts.length > 0) {
            setAccountOpts(accounts.map(l => createAutocompleteGenericOption(l.id, l.code, l.description)));
          }
        })
        .catch(err => console.log('Fetch credit cards error', err))
        .finally(() => setLoading(false));
    }
  }, [show, user]);

  const handleSaveData = async (values) => {
    //console.log('handleSaveData', values)

    if (user) {
      setErrorMsg(null);
      setSaving(true);

      const data: StaffCreditCardsSaveReq = {
        lastUpdNum: user.lastUpdateNum,
        creCards: values.cards.map(c => ({
          ...c,
          id: c._id,
          expireDate: c.expireDate,
          contableAccountId: c.account ? c.account.id : null
        }))
      }
      try {
        await saveStaffCreditCards(user.id, data);
        onClose(true);
      } catch (error) {
        setErrorMsg(`Saving error. ${error}`);
      } finally {
        setSaving(false);
      }
    }
  }

  const handleCloseDialog = (save) => {
    if (save) {
      formMethods.handleSubmit(handleSaveData)();
    } else {
      onClose(false);
    }
  }

  const isEmptyRow = (row): boolean => {
    return row.cardNum === '' &&
      row.cardDesc === '' &&
      !row.expireDate;
  }

  const handleAddNewCard = () => {
    const cards = formMethods.getValues('cards');
    const emptyRow = !cards || cards.find(c => isEmptyRow(c));
    if (emptyRow) {
      return;
    }
    append({cardNum: '', cardDesc: '', expireDate: null})
  }

  return (
    <CommonDialog
      show={show}
      title={t("user:detail.items.creditCards.title")}
      widths={[
        {breakpoint: "md", width: "900px"}
      ]}
      saving={saving}
      onClose={handleCloseDialog}
      errorMsg={errorMsg}
      page={show ? HelpCurrentPage.USER_CARD : HelpCurrentPage.USER_DETAIL}
    >
      <FormProvider {...formMethods}>
        <form noValidate>
          <>
            {fields && fields.length > 0 ? (
              fields.map((item, index) => (
                <React.Fragment key={item.id}>
                  {loading ? (<Skeleton
                      sx={{
                        my: 2
                      }}
                      className={"credit-card-skeleton"}
                      variant="rectangular"
                    />
                  ) : (
                    <>
                      <SingleCreditCard
                        accountOpts={accountOpts}
                        index={index}
                        remove={(idx) => {
                          const values = formMethods.getValues('cards');
                          if (values) {
                            replace(values.filter((_, index) => index !== idx));
                          }
                        }}
                      />
                      {index < fields.length - 1 && <Divider light/>}
                    </>
                  )}
                </React.Fragment>
              ))
            ) : (
              <></>
            )}

            {!loading && <Box mt={4}
                              sx={{
                                textAlign: {
                                  xs: 'center',
                                  md: 'left'
                                }
                              }}>
              {/*
                    usare un pulsante con icona e testo non funziona perchè l'allineamento verticale è errato,
                    quindi icona e testo sono state inserite come contenuto del Button e l'allineamento verticale
                    è stato impostato a flex-start
                    https://github.com/mui-org/material-ui/issues/19584
                  */}
              <Button
                variant="contained"
                className={"cta-button-primary-small"}
                disabled={saving}
                onClick={handleAddNewCard}
              >
                <Add/>
                <Typography ml={1} variant={"button"}>{t("user:detail.items.creditCards.newItem")}</Typography>
              </Button>

            </Box>
            }
          </>
        </form>
      </FormProvider>
    </CommonDialog>
  );
}
