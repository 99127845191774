import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import imgSrc from "../../../../assets/images/conservazione.png";
import {PremiumIcon} from "../../../base/premium-icon";
import React from "react";

type ComponentProps = {
  storeUrl: string
}

export const DigitalStorageCarouselContent = ({storeUrl}: ComponentProps) => {

  const handleClick = () => {
    window.open(storeUrl, '_blank');
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Box overflow={'hidden'} maxHeight={'250px'}>
          <Typography textAlign={'left'} variant={'h3'} mb={1}>
            CONSERVAZIONE DIGITALE
            <Box
              position="relative"
              display="inline-flex"
              marginLeft="15px"
            >
              <PremiumIcon
                position="absolute"
                bottom="0px"
                right="-30px"
              />
            </Box>
          </Typography>
          <Box maxHeight={'185px'} overflow={'auto'}>
            <p style={{textAlign: 'left', fontSize: 'small'}}>
              <strong>Attiva la conservazione digitale e liberati della carta delle note spese.</strong>
              <br/>
              ZTravel Smart si integra con il servizio di conservazione digitale Zucchetti e ti permette di eliminare le
              buste di scontrini dalle tue scrivanie e di migliorare il lavoro dei viaggiatori e
              dell’amministrazione. <strong>Scegli la versione Professional</strong> e acquista il servizio, in pochi
              passaggi il tuo processo
              note spese sarà completamente paperless.
            </p>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box display={'flex'} height={'100%'} flexDirection={'column'}>
          <Box maxHeight={'250px'}>
            <img src={imgSrc} alt={'conservazione image'} style={{backgroundSize: 'cover'}}/>
          </Box>
          <Box flexGrow={1}/>
          <Box mt={2} sx={{
            color: 'white',
            backgroundColor: '#14c6d5',
            width: 'fit-content',
            padding: '10px 10px',
            cursor: 'pointer'
          }} borderRadius={'10px'} onClick={handleClick}><Typography variant={'body2'}>Scopri di più</Typography></Box>
        </Box>
      </Grid>
    </Grid>
  );
}
