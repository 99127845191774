import {Controller, useFormContext} from "react-hook-form";
import {Grid} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import React from "react";
import {useTranslation} from "react-i18next";
import {StaffByProjectIdResponse} from "../model";

// TODO spostare
// https://mui.com/blog/lab-date-pickers-to-mui-x/
//https://mui.com/x/react-date-pickers/getting-started/

type StaffByProjectDataProps = {
  edit?: boolean,
  staff: StaffByProjectIdResponse ;
}

type ComponentProps = {
  props: StaffByProjectDataProps
}

export const StaffByProjectData = ({props}: ComponentProps) => {
  const {t} = useTranslation('project', {keyPrefix: 'generalData'});
  let control;
  const formMethods = useFormContext();

  control = props.edit ? formMethods.control : null;


  return (
    <>

          <Grid item xs={12}  >
            { props.edit
              ? ( <Controller
                name={`staff.${props.staff.id}.id`}
                control={control}
                render={({field, fieldState}) => {
                  return <TextFieldZts
                    {...field}
                    required
                    label={t('labelUsers.nameSurname')}
                    errorMsg={fieldState.error?.message}
                  />
                }}
              /> )
              :(
                <TextFieldZts
                  disabled={true}
                  label={t('labelUsers.nameSurname')}
                  value={props.staff.firstName + " " + props.staff.lastName}
                />
              )
            }
          </Grid>

    </>
  );
}
