import {Project} from "../model";
import Box from "@mui/material/Box";
import {Skeleton, Stack, Typography} from "@mui/material";
import {ProjectCardWrapper} from "./ProjectCardWrapper";
import {ProjectCard} from "./ProjectCard";
import {InfiniteScrollZts} from "../../base/infinite-scroll";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Add} from "@mui/icons-material";

type ProjectProps = {
  projects: Project[],
  loading: boolean,
  onOpen: Function,
  onRestore: Function,
  active: boolean
}

type ComponentProps = {
  props: ProjectProps
}

export const ProjectList = ({props}: ComponentProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation('project', {keyPrefix: 'list.emptyState'});

  return (
    <>
      {/*Skeleton*/}
      {props.loading &&
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          mt={3}
          maxWidth={"1200px"}
        >
          {
            [1, 2, 3].map(idx => {
              return (
                <ProjectCardWrapper
                  key={idx}
                  prop={
                    {
                      child:
                        (<Skeleton
                          animation="pulse"
                          variant="rectangular"
                          width="100%"
                          height="216px"
                        />)
                    }
                  }
                />
              );
            })
          }
        </Box>
      }

      {
        !props.loading &&
        <InfiniteScrollZts
          scrollableTargetId={"infinite-scroll-target"}
          elements={props.projects}
          renderElement={
            (project: Project) => {
              /* jsx */
              return (
                <ProjectCardWrapper key={project.id} prop={{
                  child:
                    (
                      <ProjectCard props={
                        {
                          project: project,
                          onOpen: props.onOpen,
                          onRestore: props.onRestore,
                        }}
                      />
                    )
                }}
                />
              );
            }
          }
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "12px",
            maxWidth: "1900px"
          }}
        />
      }
      {
        props.projects.length === 0 &&
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "12px",
            maxWidth: "1900px"
          }}

        >
          <Box flexGrow={1}/>
          <Stack flexDirection={'column'}>
            {
              props.active ? (
                <>
                  <Typography variant={'h3'} color={'#c2c0c2'} mb={1}>{t('pro.title')}</Typography>
                  <Box alignSelf={'center'} width={'fit-content'} borderRadius={'20px'}
                       sx={{backgroundColor: '#14c6d5', cursor: 'pointer'}}>
                    <Stack flexDirection={'row'} pl={1.5} pr={1.5} pt={0.5} pb={0.5}
                           onClick={() => navigate('/projects/edit/-1')}>
                      <Add fontSize={'small'} sx={{color: 'white'}}/>
                      <Typography pl={0.5} pr={0.5} color={'white'}>{t('pro.button1')}</Typography>
                    </Stack>
                  </Box>
                </>
              ) : (<Typography variant={'h3'} color={'#c2c0c2'} mb={1}>{t('free.title')}</Typography>)
            }
          </Stack>
          <Box flexGrow={1}/>
        </Box>
      }
    </>
  );
}
