import React, {useEffect, useState} from "react";
import * as Yup from "yup";

import {useTranslation} from 'react-i18next';

import {activeStateCode, getStateCode} from "../../model";
import {Alert, Button, Paper, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {getSupplierById, saveSupplier} from "../Service";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {PageTitle} from "../../layout/page-title";
import {Loader} from "../../base/loader";
import {SupplierEditLoader} from "./SupplierEditLoader";
import {Supplier, SaveSupplier} from "../model";
import {GeneralData} from "../detail/GeneralData";
import {Save} from "@mui/icons-material";


type SupplierEditFormValues = {
  vatId: string;
  description: string;
}

export const SupplierEdit = () => {

  const {t} = useTranslation(['validation', 'common']);
  const {t: tSupplier} = useTranslation('supplier', {keyPrefix: 'edit'});

  const [loadingData, setLoadingData] = useState(true);
  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const [active, setActive] = useState(true);
  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const {convertError} = useErrorMessage();


  let {id} = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setLoadingData(true);
      if (id === '-1') {
        setSupplier({
          id: -1,
          vatId: '',
          description: '',
          erpCode: '',
          address: '',
          country: '',
          notes: '',
          lastUpdateNum: 0,
          state: activeStateCode
        });
        setLoadingData(false);
      } else {
        getSupplierById(id)
          .then(u => setSupplier(u))
          .catch(err => setErrorMsg(`Error retrieving data. ${err}`))
          .finally(() => setLoadingData(false));
      }
    }
  }, [id]);

  const validationSchema = Yup.object({
    vatId: Yup.string()
      .max(20, 'Must be 20 characters or less')
      .min(3, 'Must be at least 3 characters')
      .required('Required'),
    description: Yup.string()
      .max(50, 'Must be 50 characters or less')
      .min(3, 'Must be at least 3 characters')
      .required('Required')
  });

  const initVal = {
    vatId: supplier ? supplier.vatId : '',
    description: supplier ? supplier.description : '',
    erpCode: supplier ? supplier.erpCode : '',
    address: supplier ? supplier.address : '',
    country: supplier ? supplier.country : '',
    notes: supplier ? supplier.notes : ''
  };

  const formMethods = useForm<SupplierEditFormValues>({
    defaultValues: {},
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  useEffect(() => {
    if (supplier) {
      formMethods.reset(initVal);
    }
  }, [supplier]);


  const handleSave = async (values) => {
    setErrorMsg(null);
    setSaving(true);

    // console.log(values)

    const updSupplier: SaveSupplier = {
      id: supplier && supplier.id !== -1 ? supplier.id : null,
      state: getStateCode(active),
      vatId: values.vatId,
      description: values.description,
      erpCode: values.erpCode,
      address: values.address,
      country: values.country,
      notes: values.notes,
      lastUpdateNum: supplier ? supplier.lastUpdateNum : 0
    };

    try {
      await saveSupplier(updSupplier);
      handleNavigateBack();
    } catch (error: any) {
      convertError(error).then(msg => setErrorMsg(msg));
    } finally {
      setSaving(false);
    }
  }

  const handleSaveClicked = () => {
    formMethods.handleSubmit(handleSave)();
  }

  const handleNavigateBack = () => {
    if (id === '-1') {
      navigate(`/suppliers`);
    } else {
      navigate(`/suppliers/detail/${id}`);
    }
  }

  return (
    <Box
      overflow={"hidden"}
      flexGrow={1}
    >

      <Paper
        elevation={0}
        sx={{
          height: "100%"
        }}
      >

        <Stack
          height={"100%"}
          overflow={"auto"}
          padding={"20px 30px 20px 20px"}
        >

          <Loader show={saving}/>

          {/* Skeleton */}
          {loadingData && <SupplierEditLoader/>}

          {/* banner con errori */}
          {errorMsg && <Alert
            severity={"error"}
            onClose={() => setErrorMsg(null)}
          >{errorMsg}</Alert>}

          {/* titolo + pulsanti */}
          {supplier && <PageTitle
            backClickHandler={handleNavigateBack}
            title={
              <Stack
                direction={{xs: "column", sm: "row"}}
                alignItems={{xs: "flex-start", sm: "center"}}
                justifyContent={{xs: "center", sm: "flex-start"}}
                flexGrow={"1"}
              >
                <Typography variant={"h3"} mr={3}>{tSupplier("titleNew")}</Typography>
              </Stack>
            }
          />}

          {/* body */}
          {supplier && <Box flexGrow={1}>
            <FormProvider {...formMethods}>
              <form noValidate>

                {/*<Stack*/}
                {/*  direction={"row"}*/}
                {/*  spacing={2}*/}
                {/*  mb={3}*/}
                {/*  mt={2}*/}
                {/*  ml={{*/}
                {/*    xs: 0,*/}
                {/*    lg: 4.8*/}
                {/*  }}*/}
                {/*  alignItems={"center"}*/}
                {/*>*/}
                {/*  <FormControlLabel*/}
                {/*    label={*/}
                {/*      <Typography variant="body1">Abilitato</Typography>*/}
                {/*    }*/}
                {/*    labelPlacement={"end"}*/}
                {/*    control={*/}
                {/*      <IOSSwitch*/}
                {/*        size={"medium"}*/}
                {/*        name="active"*/}
                {/*        checked={active}*/}
                {/*        onChange={(e) => setActive(e.target.checked)}*/}
                {/*      />*/}
                {/*    }*/}
                {/*  />*/}
                {/*</Stack>*/}

                {/* parte sx */}
                <Stack
                  mt={3}
                  flexGrow={1}
                  direction={{
                    xs: "column-reverse",
                    lg: "row"
                  }}
                  columnGap={2}
                  width={"100%"}
                >

                  {/* Dati generali */}
                  <GeneralData
                    supplier={supplier}
                    enabled={true}
                  />

                  {/* parte dx */}
                  {/*<Stack*/}
                  {/*  direction={{*/}
                  {/*    xs: 'row',*/}
                  {/*    md: 'column'*/}
                  {/*  }}*/}
                  {/*  alignItems={"center"}*/}
                  {/*  mb={{*/}
                  {/*    xs: 3,*/}
                  {/*    lg: 0*/}
                  {/*  }}*/}
                  {/*  pl={{*/}
                  {/*    xs: 0,*/}
                  {/*    md: 3*/}
                  {/*  }}*/}
                  {/*  sx={{*/}
                  {/*    flexBasis: {*/}
                  {/*      xs: '0',*/}
                  {/*      lg: '200px'*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*>*/}

                  {/*</Stack>*/}

                </Stack>

              </form>
            </FormProvider>
          </Box>}

          {/* Pulsanti (Annulla, Salva) */}
          {supplier && <Stack
            alignSelf={"flex-end"}
            direction={"row"}
            mt={4}
            columnGap={2.2}
          >
            <Button
              variant="contained"
              className={"cta-button"}
              disabled={saving}
              onClick={() => {
                setErrorMsg(null);
                handleNavigateBack();
              }}>
              <Typography variant={"button"}>{tSupplier('button.cancel')}</Typography>
            </Button>
            <Button
              variant="contained"
              className={"cta-button-primary"}
              disabled={saving}
              onClick={() => {
                setErrorMsg(null);
                handleSaveClicked()
              }}
            >
              <Save sx={{fontSize: "1.1em"}}  />
              <Typography ml={1} variant={"button"}>{tSupplier('button.save')}</Typography>
            </Button>
          </Stack>}

        </Stack>
      </Paper>

    </Box>
  );
}
