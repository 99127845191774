import React, {useState} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid, IconButton, Popover,
  Stack,
  Typography
} from "@mui/material";
import {Favorite} from "@mui/icons-material";
import {PremiumIcon} from "../../base/premium-icon";
import {useForm} from "react-hook-form";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {defaultReportCardValues, PeriodParam, PreferenceReportCardProps, ReportCardForm, ReportType} from "../model";
import {JobType} from "../../../reducers/CurrentJobs";
import {useCurrentJobs} from "../../../hooks/useCurrentJobs";
import {useSnackbar} from "notistack";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {useTranslation} from "react-i18next";

interface Parameters {
  period: PeriodParam,
  expNoteStates: string,
  startDate?: string,
  endDate?: string
}

export function PreferenceReportCard({
                                       reportName,
                                       reportId,
                                       reportParameters,
                                       reportType,
                                       desc,
                                       expCat,
                                       isPro,
                                       removePreference
                                     }: PreferenceReportCardProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const {t} = useTranslation('report');
  const {t: tParams} = useTranslation('report', {keyPrefix: 'parameter'});
  const {t: tNotifier} = useTranslation('notifier');

  const periodOptions: AutocompleteGenericOption[] = Object.values(PeriodParam).map((value, index) => {
    return createAutocompleteGenericOption(index + 1, value, tParams(`period.${value}`));
  });

  const parameters: Parameters | null = reportParameters ? JSON.parse(reportParameters) : null;

  const {getRunningJobsState, insertToRunJob} = useCurrentJobs();
  const runningJobs = getRunningJobsState();
  const {enqueueSnackbar} = useSnackbar();
  const {userLocale} = useLoggedUser();

  const formMethods = useForm<ReportCardForm>({
    defaultValues: defaultReportCardValues,
    //resolver: yupResolver(reportValidationSchema),
    mode: "onChange"
  });
  const setPeriod = (value: AutocompleteGenericOption | null, startDate?: Date, endDate?: Date) => {
    const now = new Date(Date.now());
    formMethods.reset();
    if (value) {
      switch (value.code) {
        case PeriodParam.DA_INIZIO_ANNO:
          formMethods.reset();
          const year = now.getFullYear();
          const startDate1 = new Date(0).setFullYear(year);
          formMethods.setValue("startDate", new Date(startDate1));
          formMethods.setValue("endDate", now);
          return;
        case PeriodParam.ULTIMI_6_MESI:
          formMethods.reset();
          const startDate2 = new Date(now);
          startDate2.setMonth(now.getMonth() - 6);
          startDate2.setDate(1);
          formMethods.setValue("startDate", new Date(startDate2));
          formMethods.setValue("endDate", now);
          return;
        case PeriodParam.ULTIMO_ANNO:
          formMethods.reset();
          const startDate3 = new Date(now);
          startDate3.setFullYear(startDate3.getFullYear() - 1);
          startDate3.setDate(1);
          formMethods.setValue("startDate", startDate3);
          formMethods.setValue("endDate", now);
          return;
        case PeriodParam.PERSONALIZZATO:
          if (startDate && endDate) {
            formMethods.reset();
            formMethods.setValue("startDate", startDate);
            formMethods.setValue("endDate", endDate);
          }
          break;
        default:
          throw Error('Selected parameter does not exist!');
      }
    }
  }

  const handleSave = () => {
    const period = periodOptions.find(value => value.code === parameters?.period);
    // const x = parameters?.expNoteStates;
    // console.log(parameters);
    // if (x) {
    //   const y = JSON.parse(x);
    //   console.log(y);
    // }

    const states = parameters && parameters.expNoteStates ? JSON.parse(parameters.expNoteStates) : null;
    const startDate = parameters && parameters.startDate ? new Date(parameters.startDate) : undefined;
    const endDate = parameters && parameters.endDate ? new Date(parameters.endDate) : undefined;
    if (period) {
      setPeriod(period, startDate, endDate);
      if (runningJobs.length < 3) {
        switch (reportType) {
          case ReportType.SP:
            insertToRunJob({
              type: JobType.EXPENSE_REPORT,
              id: reportId,
              startDate: formMethods.getValues("startDate"),
              endDate: formMethods.getValues("endDate"),
              parameters: {stateFilter: states ? states : []},
              locale: userLocale
            });
            break;
          case ReportType.NS:
            insertToRunJob({
              type: JobType.EXP_NOTE_REPORT,
              id: reportId,
              startDate: formMethods.getValues("startDate"),
              endDate: formMethods.getValues("endDate"),
              parameters: {stateFilter: states ? states : []},
              locale: userLocale
            });
            break;
        }
      } else {
        enqueueSnackbar(tNotifier('maxOpsReached'), {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top"
          }
        });
      }
    }
  }


  return (
    <Grid item xs={6} xl={4}>
      <Card sx={{paddingLeft: '8px', minHeight: '170px', maxHeight: '170px', position: 'relative'}}>
        <CardContent sx={{position: 'relative', maxHeight: '100%'}}>
          <Stack
            direction="column"
            overflow={"hidden"}
            spacing={1}
            mt={2}
            maxWidth={"90%"}
          >
            <Typography
              variant={"h5"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              fontWeight={"550"}
            >
              {reportName}
            </Typography>
            {
              desc &&
              <Typography textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"}>{desc}</Typography>
            }
          </Stack>
          <Stack direction={"row"} mt={1} columnGap={1.5} maxWidth={"80%"} overflow={"visible"}>
            {
              expCat && expCat.map((category) => {
                return (
                  <Box
                    sx={{backgroundColor: '#e7f9fb', borderRadius: '10px'}}
                  >
                    <Typography
                      variant={"subtitle1"}
                      textOverflow={"ellipsis"}
                      pl={1}
                      pr={1}
                      pt={0.5}
                      pb={0.5}
                    >
                      {category}
                    </Typography>
                  </Box>
                );
              })
            }
          </Stack>
          <Box
            style={{
              top: '22px',
              right: '10px',
              position: 'absolute'
            }}
          >
            <IconButton onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}>
              <Favorite fontSize={"small"} sx={{color: "#14c6d5"}}/>
            </IconButton>
            {
              isPro &&
              <PremiumIcon m={1} fontSize={"small"}/>
            }
          </Box>
        </CardContent>
        <Button
          sx={{
            position: 'absolute',
            bottom: '10px',
            right: '10px'
          }}
          variant="contained"
          className="card-open-button"
          onClick={handleSave}>
          <Typography variant={"button"}>{t('runReport')}</Typography>
        </Button>
      </Card>
      <Popover open={Boolean(anchorEl)} keepMounted anchorEl={anchorEl}
               anchorOrigin={{horizontal: "center", vertical: "bottom"}}>
        <Box display={'flex'} flexDirection={'column'} p={2} alignItems={'center'}>
          <Typography variant={"h5"}>{t('removeMessage')}</Typography>
          <Box mt={1}>
            <Button
              onClick={() => removePreference(reportName, reportId, setAnchorEl, setOpenModal)}>{t('yes')}</Button>
            <Button onClick={() => setAnchorEl(null)}>{t('no')}</Button>
          </Box>
        </Box>
      </Popover>
    </Grid>
  );
}
