import {useExpNoteList} from "../../hooks/useExpNoteList";
import {ExpNoteListTravellerFilter} from "../../../../reducers/ExpNoteList";
import Box from "@mui/material/Box";
import {Alert, Pagination, Paper, Skeleton, Stack, Typography} from "@mui/material";
import {PageTitle} from "../../../layout/page-title";
import IconButton from "@mui/material/IconButton";
import {Add} from "@mui/icons-material";
import {getTabCounter, getTabLabel, Tab, TabBar} from "../../../base/tab-bar";
import {
  ExpNote,
  ExpNoteFilter,
  ExpNoteState,
  ExpNoteStateFE, getExpNotesPayedStates,
  getExpNoteStateFELabel, getExpNotesToCompleteStates, getExpNotesToPayStates, getExpNotesToReviewStates,
  getTravellerExpNoteStatesFE
} from "../../model";
import React, {useEffect, useState} from "react";
import {useErrorMessage} from "../../../../util/ErrorUtil";
import {DateFormat, DETAIL_URL, GenericPagedList} from "../../../model";
import {getExpNotesToComplete, getExpNotesToReview, getTravellerExpNotesByState} from "../../Service";
import {
  ALL_EXPNOTE_DEFAULT_PAGE_SIZE,
  ARCHIVED_EXPNOTE_PAGE_SIZE,
  ExpNoteListFilter,
  ExpNoteListFilterType
} from "../../admin/list/model";
import {convertTravellerApiFilterToPersistedFilter, convertTravellerPersistedFilterToApiFilter,} from "./model";
import {ExpNoteFilters} from "./filter";
import {ExpNoteBaseList} from "./BaseList";
import {useNavigate} from "react-router-dom";
import {EditExpNote} from "../../edit";
import {useTranslation} from "react-i18next";
import {TooltipZts} from "../../../base/tooltip";
import {countTravellerExpNotes} from "../../../dashboard/Service";

const InitApiFilter: ExpNoteFilter = {
  searchText: null,
  startDate: null,
  contabDate: null
}

export const ExpNoteTravellerList = () => {

  const {
    travellerList: {
      stateSelected,
      filter,
      sorting,
      pagination
    },
    updateTravellerListType,
    updateTravellerFilter,
    updateTravellerPagination,
  } = useExpNoteList();

  const [stateCounters, setStateCounters] = useState<{state: ExpNoteStateFE, count: number}[]>([]);
  const [allExpNotes, setAllExpNotes] = useState<ExpNote[]>([]);
  const [expNotes, setExpNotes] = useState<ExpNote[]>([]);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingOtherExpNotes, setLoadingOtherExpNotes] = useState(false);

  const [apiFilter, setApiFilter] = useState<ExpNoteFilter>(filter ? convertTravellerPersistedFilterToApiFilter(filter) : InitApiFilter);
  const [totalPages, setTotalPages] = useState(1);

  const [filterChangeTO, setFilterChangeTO] = useState<any>(null);

  const [openNewExpNote, setOpenNewExpNote] = useState(false);

  const {convertError} = useErrorMessage();

  const {t} = useTranslation('exp-note');

  const navigate = useNavigate();

  const otherExpNoteStates: ExpNoteState[] = [
    ExpNoteState.DA_APPROVARE,
    ExpNoteState.DA_CONTROLLARE,
    ExpNoteState.DA_LIQUIDARE,
    ExpNoteState.DA_CONTABILIZZARE,
    ExpNoteState.DA_ARCHIVIARE,
    ExpNoteState.ARCHIVIATE,
  ]

  const fetchExpNotes = (fetchFunction: (pageSize: number, page: number) => Promise<GenericPagedList<ExpNote>>, pageSize = ALL_EXPNOTE_DEFAULT_PAGE_SIZE, page = 1, expNotes: ExpNote[] = []): Promise<ExpNote[]> => {
    return fetchFunction(pageSize, page)
      .then(res => {
        expNotes.push(...res.elements);
        if (res.totalItems === expNotes.length) {
          return expNotes;
        }
        return fetchExpNotes(fetchFunction, pageSize, res.page + 1, expNotes);
      });
  }

  const updateCounters = () => {
    const activeStates = getExpNotesToCompleteStates();
    const rejectedStates = getExpNotesToReviewStates();
    const toPayStates = getExpNotesToPayStates();
    const payedStates = getExpNotesPayedStates();

    let states: ExpNoteState[] = [];
    states = states.concat(activeStates);
    states = states.concat(rejectedStates);
    states = states.concat(toPayStates);
    states = states.concat(payedStates);

    countTravellerExpNotes(states).
      then((response) => {
        let activeNotesCount = 0;
        let rejectedNotesCount = 0;
        let toPayNotesCount = 0;
        response.forEach((expNoteCount) => {
          if (activeStates.find(state => state === expNoteCount.state)) {
            activeNotesCount += expNoteCount.count;
          } else if (rejectedStates.find(state => state === expNoteCount.state)) {
            rejectedNotesCount += expNoteCount.count;
          } else if (toPayStates.find(state => state === expNoteCount.state)) {
            toPayNotesCount += expNoteCount.count;
          }
        });

        setStateCounters([
          {state: ExpNoteStateFE.DA_COMPLETARE, count: activeNotesCount},
          {state: ExpNoteStateFE.DA_RIVEDERE, count: rejectedNotesCount},
          {state: ExpNoteStateFE.DA_LIQUIDARE, count: toPayNotesCount}
        ]);
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    updateCounters();
  }, []);

  useEffect(() => {
    let fetchFunction: ((pageSize: number, page: number) => Promise<GenericPagedList<ExpNote>>) | null;
    let getExpNotes: Promise<ExpNote[]> | null;
    switch (stateSelected) {
      case ExpNoteStateFE.DA_COMPLETARE:
        fetchFunction = getExpNotesToComplete;
        break;
      case ExpNoteStateFE.DA_RIVEDERE:
        fetchFunction = getExpNotesToReview;
        break;
      default:
        fetchFunction = null;
    }

    if (fetchFunction) {
      getExpNotes = fetchExpNotes(fetchFunction);
      setLoading(true);
      getExpNotes
        .then(res => {
          setAllExpNotes(res)
        })
        .catch(err => setErrorMsg(err.message))
        .finally(() => setLoading(false));
    } else {
      setAllExpNotes([]);
    }
  }, [stateSelected]);

  useEffect(() => {
    if (stateSelected !== ExpNoteStateFE.ARCHIVIATE) {
      setExpNotes(allExpNotes);
    }
  }, [allExpNotes, stateSelected]);

  useEffect(() => {
    if (stateSelected === ExpNoteStateFE.ARCHIVIATE && apiFilter) {
      // console.log('call api');
      setLoadingOtherExpNotes(true);
      getTravellerExpNotesByState(otherExpNoteStates, ARCHIVED_EXPNOTE_PAGE_SIZE, pagination.currentPage, apiFilter, {
        orderBy: sorting.orderBy,
        orderDir: sorting.orderDir !== 'asc'
      })
        .then(res => {

          if (res.totalPages && pagination.currentPage > res.totalPages) {
            // se i filtri hanno ridotto il numero delle pagine
            // resetto il numero pagina ad 1, questo forza una
            // nuova ricerca e verrà mostrata la prima pagina
            updateTravellerPagination({currentPage: 1});
          }

          setTotalPages(res.totalPages);
          setExpNotes(res.elements);
        })
        .finally(() => setLoadingOtherExpNotes(false));
    }
  }, [apiFilter, pagination, sorting, stateSelected]);

  const getExpNoteCounter = (state: ExpNoteStateFE): number => {
    if (state === ExpNoteStateFE.ARCHIVIATE) {
      return 0;
    } else {
      const stateCounter = stateCounters.find(sc => sc.state === state);
      return stateCounter ? stateCounter.count : 0;
    }
  }

  const getStatusTabs = (): Tab[] => {
    return getTravellerExpNoteStatesFE().map(state => {
      let tabLabel = '';
      if (state === ExpNoteStateFE.ARCHIVIATE) {
        tabLabel = t("state.allByTraveller");
      } else {
        tabLabel = getExpNoteStateFELabel(state, t);
      }
      return {
        key: state,
        render: (isSelected) => {
          const count = getExpNoteCounter(state);
          return <>
            {count > 0 && getTabCounter(count, isSelected)}
            {getTabLabel(tabLabel)}
          </>
        }
      }
    });
  }

  const handleStateSelected = (state: string) => {
    setAllExpNotes([]);
    // @ts-ignore
    updateTravellerListType(Object.values(ExpNoteStateFE).find(e => e === state));
    setApiFilter(InitApiFilter);
    setTotalPages(1);
  }

  const getFilterName = (filterType: ExpNoteListFilterType): string => {
    switch (filterType) {
      case ExpNoteListFilterType.SEARCH_TEXT:
        return 'searchText';
      case ExpNoteListFilterType.START_DATE:
        return 'startDate';
    }
    return '';
  }

  const getFilterValues = (filter: ExpNoteListFilter): any[] | null => {
    switch (filter.type) {
      case ExpNoteListFilterType.SEARCH_TEXT:
        return filter.values;
      case ExpNoteListFilterType.START_DATE:
        if (filter.values) {
          return filter.values.map(v => {
            if (!v) {
              return null;
            }
            try {
              if (v.isValid()) {
                return v.format(DateFormat);
              }
            } catch (error) {
              // quando riapro la lista e imposto il filtro salvato in redux
              // la data è già formattata come stringa quindi non è disponibile
              // la funzione isValid() nè la funzione format() ma posso assumere
              // che il valore sia valido proprio perchè era già stato formattato
              // in precedenza
              return v;
            }
            return null;
          });
        }
        return null;
    }
    return null;
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    updateTravellerPagination({currentPage: page});
  }

  const isFilterChanged = (prevFilter: ExpNoteFilter, newFilter: ExpNoteFilter): boolean => {
    //console.log(prevFilter, newFilter)

    let changed = false;
    Object.values(ExpNoteListFilterType).forEach(ft => {
      // @ts-ignore
      const filterName = getFilterName(ft);
      let prevValue = prevFilter[filterName];
      if (!prevValue) {
        if (ft === ExpNoteListFilterType.START_DATE) {
          prevValue = [null, null];
        } else {
          prevValue = [''];
        }
      }

      let newValue = newFilter[filterName];
      if (!newValue) {
        if (ft === ExpNoteListFilterType.START_DATE) {
          newValue = [null, null];
        } else {
          newValue = [''];
        }
      }

      if (ft === ExpNoteListFilterType.START_DATE) {
        if (prevValue[0] !== newValue[0] || prevValue[1] !== newValue[1]) {
          changed = true;
        }
      } else {
        if (prevValue[0] !== newValue[0]) {
          changed = true;
        }
      }
    });
    return changed;
  }

  const handleFilterChange = (filters: ExpNoteListFilter[]) => {
    if (stateSelected === ExpNoteStateFE.ARCHIVIATE) {
      if (filters) {
        const prevApiFilter = {...apiFilter};
        const newApiFilter = {...apiFilter};
        filters
          .forEach(filter => {
            const filterName = getFilterName(filter.type);
            const filterValue = filter.enabled ? getFilterValues(filter) : null;
            if (filterValue) {
              newApiFilter[filterName] = filterValue;
            } else {
              newApiFilter[filterName] = null;
            }
          });

        if (isFilterChanged(prevApiFilter, newApiFilter)) {
          // console.log('apiFilter', prevApiFilter, newApiFilter);
          // debounce
          if (filterChangeTO) {
            clearTimeout(filterChangeTO);
          }
          setFilterChangeTO(setTimeout(() => {
            setApiFilter(newApiFilter);
            updateTravellerFilter(convertTravellerApiFilterToPersistedFilter(newApiFilter));
          }, 400));
        }
      }
    } else {
      let newExpNotes = allExpNotes;
      if (newExpNotes && newExpNotes.length > 0 && filters) {
        const persistedFilter: ExpNoteListTravellerFilter = {};
        filters
          .filter(f => f.enabled && f.values)
          .forEach(filter => {
            persistedFilter[filter.type] = filter.values;
            switch (filter.type) {
              case ExpNoteListFilterType.SEARCH_TEXT:
                const filterValue = filter.values[0].toUpperCase();
                newExpNotes = newExpNotes.filter(e =>
                  e.description.toUpperCase().indexOf(filterValue) !== -1);
                break;
              case ExpNoteListFilterType.START_DATE:
                newExpNotes = newExpNotes.filter(e => {
                  if (!filter.values) {
                    return true;
                  }
                  const startDate = filter.values[0];
                  const endDate = filter.values[1];

                  return (!startDate || !startDate.isValid() || startDate.isSameOrBefore(e.startDate, 'day')) &&
                    (!endDate || !endDate.isValid() || endDate.isSameOrAfter(e.endDate, 'day'))
                });
                break;
            }
          });

        updateTravellerFilter(persistedFilter);
      }
      setExpNotes(newExpNotes);
    }
  }

  const getFilterElement = (): JSX.Element | null => {
    if (loading) {
      return <Box mt={2} width={"300px"} height={"40px"}><Skeleton variant={"rectangular"} height={"100%"}/></Box>;
    }

    return (
      <ExpNoteFilters
        onFilterChange={handleFilterChange}
      />
    );
  }

  const selectRow = (id) => {
    navigate(`..${DETAIL_URL}/${id}`);
  }

  const getListElement = (): JSX.Element | null => {
    if (loading || loadingOtherExpNotes) {
      return <Box mt={2} width={"100%"} height={"100px"}><Skeleton variant={"rectangular"} height={"100%"}/></Box>;
    }

    switch (stateSelected) {
      case ExpNoteStateFE.DA_COMPLETARE:
        return (
          <>
            <ExpNoteBaseList
              ctaLabel={t("list.open")}
              expNotes={expNotes}
              rowClickHandler={selectRow}
              emptyState={'Non ci sono Note Spese da completare in corso'}
              handleNewExpNoteClick={handleNewExpNote}
            />
          </>
        )
      case ExpNoteStateFE.DA_RIVEDERE:
        return (
          <ExpNoteBaseList
            expNotes={expNotes}
            rowClickHandler={selectRow}
          />
        )
      case ExpNoteStateFE.ARCHIVIATE:
        return (
          <ExpNoteBaseList
            expNotes={expNotes}
            sortExternal={true}
            showReimb={true}
            showStatus={true}
            rowClickHandler={selectRow}
          />
        )
    }

    return null;
  }

  const handleNewExpNote = () => {
    setOpenNewExpNote(true);
  }

  const handleNewExpNoteClose = (res: number | ExpNote | null) => {
    setOpenNewExpNote(false);

    if (res) {
      if (stateSelected !== ExpNoteStateFE.DA_COMPLETARE) {
        updateTravellerListType(ExpNoteStateFE.DA_COMPLETARE);
      } else {
        setLoading(true);
        fetchExpNotes(getExpNotesToComplete)
          .then(res => {
            setAllExpNotes(res);
            updateTravellerFilter(convertTravellerApiFilterToPersistedFilter({
              searchText: null,
              startDate: null,
              contabDate: null
            }));
          })
          .catch(err => setErrorMsg(err.message))
          .finally(() => setLoading(false));
      }
    }
  }

  return (
    <>
      <Box
        width={"100%"}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        mx={"auto"}
      >

        {errorMsg && <Alert
          severity={"error"}
          variant={"filled"}
          sx={{mb: 4}}
          onClose={() => setErrorMsg(null)}>{errorMsg}</Alert>}


        {/* Titolo */}
        <Box flexGrow={1}>
          <PageTitle
            title={
              <Typography variant={"h3"} mr={3}>{t("list.title")}</Typography>
            }
            actions={
              <Stack direction="row" columnGap={2}>
                <TooltipZts
                  title={t('edit.titleNew')}
                  placement={'bottom'}
                  enterDelay={400}
                >
                  <IconButton
                    color="primary"
                    className={"edit-button"}
                    onClick={handleNewExpNote}
                  >
                    <Add fontSize={"medium"}/>
                  </IconButton>
                </TooltipZts>
              </Stack>
            }
          />
        </Box>

        {/*Liste*/}
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            mt: 2,
            px: 3.5,
            py: 2.5,
            flexGrow: 1,
            height: "100%"
          }}
        >

          {/*Barra di selezione dello stato*/}
          <TabBar
            selected={stateSelected}
            tabs={getStatusTabs()}
            onSelect={handleStateSelected}
          />

          {/* Filtri */}
          <Box mt={3}>
            {getFilterElement()}
          </Box>

          {/*Lista*/}
          {getListElement()}

          {stateSelected === ExpNoteStateFE.ARCHIVIATE && !loadingOtherExpNotes && (
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Pagination
                count={totalPages}
                page={pagination.currentPage}
                onChange={handlePageChange}
                shape="rounded"/>
            </Box>
          )}

        </Paper>

      </Box>

      <EditExpNote
        show={openNewExpNote}
        onClose={handleNewExpNoteClose}
      />

    </>
  );
}
