import {Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {deletePreference, getAllPreferences} from "../Service";
import {createError} from "../../../util/ErrorUtil";
import {PreferenceReportCard} from "../component/PreferenceReportCard";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {ReportType} from "../model";

interface PreferenceReport {
  id: number,
  reportId: number,
  tenantId: number,
  favName: string,
  parameters: string,
  repType: string
}

export function ReportPreferences() {
  const [favoriteReports, setFavoriteReports] = useState<PreferenceReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const {t} = useTranslation('report');

  const asyncGetAndSetFavoriteReports = async (): Promise<void> => {
    setLoading(true);
    try {
      const reports = await getAllPreferences();
      setFavoriteReports(reports);
    } catch (err: any) {
      setErrorMsg(createError(err));
      setLoading(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    asyncGetAndSetFavoriteReports().then();
  }, []);

  const removePreference = async (reportName: string, reportIdReference: number, setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>, setOpenModal: React.Dispatch<React.SetStateAction<boolean>>): Promise<void> => {
    try {
      await deletePreference({reportName: reportName, reportIdReference: reportIdReference});
      setAnchorEl(null);
      setOpenModal(false);
      asyncGetAndSetFavoriteReports().then();
    } catch (err: any) {
      setErrorMsg(createError(err));
    }
  }

  return (
    <>
      {
        loading &&
        <div>
          Loading
        </div>
      }
      {
        !loading && favoriteReports && (
          <Grid container spacing={2}>
            {
              favoriteReports.map((report, index) => {
                return (
                  <PreferenceReportCard key={index} reportId={report.reportId} reportParameters={report.parameters} reportName={report.favName} reportType={ReportType[report.repType]} removePreference={removePreference}/>
                );
              })
            }
          </Grid>
        )
      }
      {
        !loading && favoriteReports.length === 0 &&
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "124px",
            maxWidth: "1900px"
          }}
        >
          <Box flexGrow={1}/>
          <Typography variant={'h3'} color={'#c2c0c2'} mb={1}>{t('preferenceEmptyState')}</Typography>
          <Box flexGrow={1}/>
        </Box>
      }
    </>
  );
}
