import {Button, CardContent, Stack, Typography} from "@mui/material";
import {Size, UserPicture} from "../UserPicture";
import Box from "@mui/material/Box";
import {getStatusClass, getStatusLabel} from "../../model";
import React from "react";
import {getRoleLabel, isActiveUser, User} from "../model";
import {useTheme} from "@mui/material/styles";
import {Check, MoreVert} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  user: User,
  handleMenuClick: Function,
  onOpen: Function
}

export const UserCardContent = ({user, handleMenuClick, onOpen}: ComponentProps) => {

  const theme = useTheme();

  const {t} = useTranslation("user");
  const {t: tCommon} = useTranslation("common");

  return (
    <CardContent
      sx={{
        position: 'relative'
      }}
    >

      <Stack
        direction="row"
        overflow={"hidden"}
        spacing={2}
        width={"100%"}
      >
        <UserPicture
          user={user}
          size={Size.LIST}
        />
        <Box pt={1} overflow={"hidden"}>
          <Typography
            variant={"h5"}
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
          >{user.firstName} {user.lastName}</Typography>
          {/*@ts-ignore*/}
          <Typography variant={"subtitle1"} style={{color: theme.palette.subtitle1.main}}>{user.email}</Typography>
          <Box
            mt={1}
            className={'status-pill ' + getStatusClass(user.state)}
          >
            {isActiveUser(user) && <Check fontSize={"small"} />}
            <Typography variant={"subtitle2"}>{getStatusLabel(user.state, t)}</Typography>
          </Box>
        </Box>
      </Stack>

      <Box mt={3}>
        {/*@ts-ignore*/}
        <Typography variant={"h6"} textTransform={"uppercase"} style={{color: theme.palette.h6.main}}>
          {t("list.card.role")}
        </Typography>
        <Typography
          mt={0.5}
          variant={"body1"}
          overflow={"hidden"}
          maxHeight={"22px"}
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          maxWidth={"calc(100% - 50px)"}
        >
          {getRoleLabel(user, tCommon)}
        </Typography>
      </Box>

      <Box
        style={{
          bottom: '15px',
          right: '15px',
          position: 'absolute'
        }}
      >
        <Button
          variant="contained"
          className="card-open-button"
          onClick={() => onOpen(user.id)}>
          <Typography variant={"button"}>{tCommon("card.open")}</Typography>
        </Button>
      </Box>

      <Box
        style={{
          top: '5px',
          right: '5px',
          position: 'absolute'
        }}
      >
        <Box
          onClick={(e) => handleMenuClick(user.id, e)}
          textAlign={"center"}
          mt={1}
          mr={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}>
          <MoreVert cursor={"pointer"} />
        </Box>
      </Box>

    </CardContent>
  );
}
