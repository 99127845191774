import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useSelector} from "react-redux";
import {useConfirm} from "../../../hooks/useConfirm";
import {getConfirmDialogState} from "../../../reducers/ConfirmDialog";
import {useTranslation} from "react-i18next";

export const ConfirmDialog = () => {
  const {t} = useTranslation('common', {keyPrefix: 'modal'});

  const {
    open,
    title,
    body,
    confirmLabel,
    cancelLabel,
    onConfirm,
    onCancel,
    noCloseAtConfirm,
    variant
  } = useSelector(getConfirmDialogState);

  const {
    closeConfirm
  } = useConfirm();

  const confirmHandler = () => {
    if (onConfirm) {
      onConfirm();
    }
    if (!noCloseAtConfirm) {
      closeConfirm();
    }
  }

  const cancelHandler = () => {
    closeConfirm();
    if (onCancel) {
      onCancel();
    }
  }

  if (!open) {
    return <></>
  }

  let confirmButtonClass = "";
  switch (variant) {
    case "delete":
      confirmButtonClass = "cta-button-danger-small";
      break;
    default:
      confirmButtonClass = "cta-button-primary-small";
  }

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle>
        {title ? title : <Typography variant="h3">{t('confirmTitle')}</Typography>}
      </DialogTitle>
      <DialogContent
        dividers={false}
        sx={{
          position: "relative"
        }}
      >

        {body}

      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={confirmButtonClass}
          onClick={confirmHandler}
          autoFocus
        >
          <Typography variant={"button"}>{confirmLabel ? confirmLabel : t('confirm')}</Typography>
        </Button>
        <Button
          variant="contained"
          className="cta-button-small"
          onClick={cancelHandler}
        >
          <Typography variant={"button"}>{cancelLabel ? cancelLabel : t('cancel')}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
