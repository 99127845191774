import { default as axios } from 'axios';
import {
  AvailableAmountToReturn,
  ExchangeRequest,
  ExchangeResponse,
  ExpenseDocumentSaveRequest, ExpenseDocumentSaveResponse,
  ExpNote, ExpNoteArchive, ExpNoteCreCardMov,
  ExpNoteFilter,
  ExpNoteLocality,
  ExpNotePayment,
  ExpNoteSort,
  ExpNoteState,
  ExpNoteStateChangeBulkRequest,
  ExpNoteStateChangeRequest,
  ExpNoteWithStaff,
  getExpNotesArchivedStates, getExpNotesInProgressStates,
  getExpNotesToApproveStates,
  getExpNotesToArchiveStates,
  getExpNotesToCheckStates,
  getExpNotesToCompleteStates,
  getExpNotesToContabStates,
  getExpNotesToPayStates,
  getExpNotesToReviewStates,
  getSortTypeParam,
  GOOGLE_PLACE_SUGGESTION_MIN_CHAR,
  GoogleDistanceResponse,
  GooglePlaceSuggestion,
  OcrResult,
  PaymentSaveRequest,
  SaveExpNote,
  SingleExpenseSaveRequest,
  UpdateExpNoteMaximumRequest
} from './model';
import { getApiUrl } from '../../config/environment';
import {
  DateFormat,
  FileUploadReq,
  FileUploadResp,
  GenericList,
  GenericPagedList,
  LinkCreCardMovToExpNoteDocRequest
} from '../model';
import { ExpNoteExpenseFull } from './expense/model';
import moment from 'moment';
import { parseNumber } from '../../util/NumberUtil';
import { createBlobError, createError } from '../../util/ErrorUtil';
import { Locale } from '../../util/LocalizationUtil';

export const getExpNotesToApprove = async (pageSize: number, page = 1): Promise<GenericPagedList<ExpNoteArchive>> => {
  return getExpNotesByApproverAndState(getExpNotesToApproveStates(), pageSize, page);
}

export const getExpNotesToCheck = async (pageSize: number, page = 1): Promise<GenericPagedList<ExpNoteArchive>> => {
  return getAllExpNotesByState(getExpNotesToCheckStates(), pageSize, page);
}

export const getExpNotesToPay = async (pageSize: number, page = 1): Promise<GenericPagedList<ExpNoteArchive>> => {
  return getAllExpNotesByState(getExpNotesToPayStates(), pageSize, page);
}

export const getExpNotesToContab = async (pageSize: number, page = 1): Promise<GenericPagedList<ExpNoteArchive>> => {
  return getAllExpNotesByState(getExpNotesToContabStates(), pageSize, page);
}

export const getExpNotesToArchive = async (pageSize: number, page = 1): Promise<GenericPagedList<ExpNoteArchive>> => {
  return getAllExpNotesByState(getExpNotesToArchiveStates(), pageSize, page);
}

export const getExpNotesInProgress = async (pageSize: number, page = 1): Promise<GenericPagedList<ExpNoteArchive>> => {
  return getAllExpNotesByState(getExpNotesInProgressStates(), pageSize, page);
}

export const getExpNotesArchived = async (pageSize: number, page = 1, filter: ExpNoteFilter, sort: ExpNoteSort): Promise<GenericPagedList<ExpNoteArchive>> => {
  return getAllExpNotesByState(getExpNotesArchivedStates(), pageSize, page, filter, sort);
}

export const getExpNotesToComplete = async (pageSize: number, page = 1): Promise<GenericPagedList<ExpNote>> => {
  return getTravellerExpNotesByState(getExpNotesToCompleteStates(), pageSize, page);
}

export const getExpNotesToReview = async (pageSize: number, page = 1): Promise<GenericPagedList<ExpNote>> => {
  return getTravellerExpNotesByState(getExpNotesToReviewStates(), pageSize, page);
}

export const getAllExpNotesByState = async (states: ExpNoteState[], pageSize: number, page: number, filter?: ExpNoteFilter, sort?: ExpNoteSort): Promise<GenericPagedList<ExpNoteArchive>> => {
  const queryString = getAllExpNotesQueryString(states, pageSize, page, filter, sort);

  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericPagedList<ExpNoteArchive>>(`${apiUrl}/expnotes/all`, {params: queryString});
  //console.log(resp);
  return resp.data;
}

export const getTravellerExpNotesByState = async (states: ExpNoteState[], pageSize: number, page: number, filter?: ExpNoteFilter, sort?: ExpNoteSort): Promise<GenericPagedList<ExpNote>> => {
  const queryString = getAllExpNotesQueryString(states, pageSize, page, filter, sort);

  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericPagedList<ExpNoteWithStaff>>(`${apiUrl}/expnotes`, {params: queryString});
  //console.log(resp);
  return resp.data;
}

export const getExpNotesByApproverAndState = async (states: ExpNoteState[], pageSize: number, page: number, filter?: ExpNoteFilter, sort?: ExpNoteSort): Promise<GenericPagedList<ExpNoteArchive>> => {
  const queryString = getAllExpNotesQueryString(states, pageSize, page, filter, sort);

  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericPagedList<ExpNoteArchive>>(`${apiUrl}/expnotes/approver`, {params: queryString});
  //console.log(resp);
  return resp.data;
}

export const getAllExpNotesQueryString = (states: ExpNoteState[], pageSize: number, page: number, filter?: ExpNoteFilter, sort?: ExpNoteSort) => {
  const queryString = new URLSearchParams();

  // filtro per stati
  queryString.append('state', states.join(','));

  // paginazione
  queryString.append('pageSize', pageSize.toString());
  queryString.append('page', page.toString());

  // filtri (descrizione, viaggiatore, date)
  if (filter) {
    Object.keys(filter).forEach(key => {
      const value = filter[key];
      if (value) {
        if (key === 'startDate') {
          if (value[0]) {
            queryString.append('startDate', value[0]);
          }
          if (value[1]) {
            queryString.append('endDate', value[1]);
          }
        } else if (key === 'contabDate') {
          if (value[0]) {
            queryString.append('contabStartDate', value[0]);
          }
          if (value[1]) {
            queryString.append('contabEndDate', value[1]);
          }
        } else {
          queryString.append(key, value);
        }
      }
    });
  }

  // ordinamento
  if (sort) {
    queryString.append('order', getSortTypeParam(sort.orderBy));
    if (sort.orderDir) {
      queryString.append('desc', String(sort.orderDir));
    }
  }

  return queryString;
}

export const getExpNote = async (id: number): Promise<ExpNoteWithStaff> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/expnotes/${id}`);
  //console.log(resp);
  return resp.data;
}

export const getLastExpNoteExpense = async (id: number): Promise<ExpNoteExpenseFull> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<ExpNoteExpenseFull>(`${apiUrl}/expnotes/${id}/expenses/lastexpense`);
  //console.log(resp);
  return resp.data;
}

export const getExpNoteExpenses = async (id: number): Promise<ExpNoteExpenseFull[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<ExpNoteExpenseFull>>(`${apiUrl}/expnotes/${id}/expenses`);
  //console.log(resp);
  return resp.data.elements;
}

export const getExpNotePayments = async (id: number): Promise<ExpNotePayment[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<ExpNotePayment>>(`${apiUrl}/expnotes/${id}/payments`);
  //console.log(resp);
  return resp.data.elements;
}

export const getExpNoteCreCardMovs = async (id: number): Promise<ExpNoteCreCardMov[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<ExpNoteCreCardMov>>(`${apiUrl}/expnotes/${id}/crecardmovs`);
  //console.log(resp);
  return resp.data.elements;
}

export const getExpNoteLocalities = async (id: number): Promise<ExpNoteLocality[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<ExpNoteLocality>>(`${apiUrl}/expnotes/${id}/localities`);
  //console.log(resp);
  return resp.data.elements;
}

export const getExpNoteGuests = async (id: number): Promise<string[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<string>>(`${apiUrl}/expnotes/${id}/guests`);
  //console.log(resp);
  return resp.data.elements;
}

export const uploadFile = async (request: FileUploadReq, onUploadProgressHandler: (progressEvent: any) => void): Promise<FileUploadResp> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/uploads`, request, {onUploadProgress: onUploadProgressHandler});
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const downloadTempFile = async (uploadKey: string, onDownloadProgressHandler: (progressEvent: any) => void): Promise<{ uploadKey: string, file: Blob }> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<Blob>(`${apiUrl}/uploads/${uploadKey}`, {
    responseType: 'blob',
    onDownloadProgress: onDownloadProgressHandler
  });
  return {uploadKey: uploadKey, file: resp.data};
}

export const downloadExpNoteAttachFile = async (expNoteId: number, attachId: number, onDownloadProgressHandler: (progressEvent: any) => void): Promise<Blob> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<Blob>(`${apiUrl}/expnotes/${expNoteId}/attachment/${attachId}`, {
    responseType: 'blob',
    onDownloadProgress: onDownloadProgressHandler
  });
  return resp.data;
}

export const deleteTempFile = async (uploadKey: string) => {
  const apiUrl = getApiUrl();
  await axios.delete(`${apiUrl}/uploads/${uploadKey}`);
}

export const getOcrResult = async (uploadKey: string): Promise<OcrResult> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<OcrResult>(`${apiUrl}/uploads/${uploadKey}/ocr`);
  //console.log(resp);
  return resp.data;
}

export const saveExpenseDocument = async (id: number, request: ExpenseDocumentSaveRequest): Promise<number> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post<number>(`${apiUrl}/expnotes/${id}/expenses/document`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const updateExpenseDocument = async (id: number, docNum: number, request: ExpenseDocumentSaveRequest): Promise<ExpenseDocumentSaveResponse> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.put<ExpenseDocumentSaveResponse>(`${apiUrl}/expnotes/${id}/expenses/document/${docNum}`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const saveSingleExpense = async (id: number, request: SingleExpenseSaveRequest): Promise<number> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post<number>(`${apiUrl}/expnotes/${id}/expenses`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const updateSingleExpense = async (expNoteId: number, expId: number, request: SingleExpenseSaveRequest): Promise<number> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.put<number>(`${apiUrl}/expnotes/${expNoteId}/expenses/${expId}`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const deleteSingleExpense = async (expNoteId: number, expId: number, lastUpdNum: number): Promise<void> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.delete<void>(`${apiUrl}/expnotes/${expNoteId}/expenses/${expId}?lastUpdNum=${lastUpdNum}`);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const deleteExpenseDocument = async (expNoteId: number, docNum: number, lastUpdNum: number): Promise<void> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.delete<void>(`${apiUrl}/expnotes/${expNoteId}/expenses/document/${docNum}?lastUpdNum=${lastUpdNum}`);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getExchange = async (expNoteId: number, req: ExchangeRequest, locale: Locale): Promise<ExchangeResponse> => {
  const mDate = moment(req.date);
  const date = mDate.format(`${DateFormat}`);
  if (mDate.isValid() && req.date && req.currencyCode) {
    const queryString = new URLSearchParams();
    queryString.append('currencyCode', req.currencyCode);
    queryString.append('date', date);
    if (req.payMode) {
      queryString.append('payMode', req.payMode);
    }
    if (req.amount) {
      const num = parseNumber(req.amount, locale);
      queryString.append('amount', num ? num.toString() : '0');
    }
    if (req.expId) {
      queryString.append('expId', req.expId.toString());
    }

    const apiUrl = getApiUrl();
    const resp = await axios.get<ExchangeResponse>(`${apiUrl}/expnotes/${expNoteId}/exchange`, {params: queryString});
    //console.log(resp);
    return resp.data;
  } else {
    return new Promise<ExchangeResponse>((res) => {
      res({
        exchange: 1,
        date: <Date>req.date,
        currency: <string>req.currencyCode
      })
    });
  }
}

export const countPayments = async (expNoteId: number): Promise<number> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<number>(`${apiUrl}/expnotes/${expNoteId}/payments/count`);
  //console.log(resp);
  return resp.data;
}

export const saveExpNote = async (expNote: SaveExpNote): Promise<number | ExpNote> => {
  try {
    if (expNote.id) {
      return await updateExpNote(expNote);
    } else {
      return await insertExpNote(expNote);
    }
  } catch (err: any) {
    throw createError(err);
  }
}

export const insertExpNote = async (expNote: SaveExpNote): Promise<number> => {
  const apiUrl = getApiUrl();
  const resp = await axios.post<number>(`${apiUrl}/expnotes`, expNote);
  //console.log(resp);
  return resp.data;
}

export const updateExpNote = async (expNote: SaveExpNote): Promise<ExpNote> => {
  const apiUrl = getApiUrl();
  const resp = await axios.put<ExpNote>(`${apiUrl}/expnotes/${expNote.id}`, expNote);
  //console.log(resp);
  return resp.data;
}

export const updateExpNoteMaximum = async (expNote: ExpNote, request: UpdateExpNoteMaximumRequest): Promise<void> => {
  const apiUrl = getApiUrl();
  await axios.put<void>(`${apiUrl}/expnotes/${expNote.id}/expenses/approvemaximum`, request);
  //console.log(resp);
}

export const createPayment = async (expNoteId: number, request: PaymentSaveRequest): Promise<number> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post<number>(`${apiUrl}/expnotes/${expNoteId}/payments`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const updatePayment = async (expNoteId: number, paymentId: number, request: PaymentSaveRequest): Promise<number> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.put<number>(`${apiUrl}/expnotes/${expNoteId}/payments/${paymentId}`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const deletePayment = async (expNoteId: number, paymentId: number, lastUpdNum: number): Promise<void> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.delete<void>(`${apiUrl}/expnotes/${expNoteId}/payments/${paymentId}?lastUpdNum=${lastUpdNum}`);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getGooglePlaceSuggestions = async (search: string): Promise<GooglePlaceSuggestion[]> => {
  if (!search || search.length < GOOGLE_PLACE_SUGGESTION_MIN_CHAR) {
    throw new Error(`Enter at least ${GOOGLE_PLACE_SUGGESTION_MIN_CHAR} characters`);
  }
  try {
    const apiUrl = getApiUrl();
    const queryString = new URLSearchParams();
    queryString.set('search', search);
    const resp = await axios.get<GenericList<GooglePlaceSuggestion>>(`${apiUrl}/places`, {params: queryString});
    return resp.data.elements;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getDistance = async (origin: string, destination: string): Promise<GoogleDistanceResponse> => {
  try {
    const apiUrl = getApiUrl();
    const queryString = new URLSearchParams();
    queryString.set('origin', origin);
    queryString.set('destination', destination);
    const resp = await axios.get<GoogleDistanceResponse>(`${apiUrl}/distance`, {params: queryString});
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const sendExpNoteDeleted = async (expNoteId: number, request: ExpNoteStateChangeRequest): Promise<ExpNote> => {
  return sendExpNoteTo(expNoteId, request, 'deleted');
}

export const sendExpNoteRejected = async (expNoteId: number, request: ExpNoteStateChangeRequest): Promise<ExpNote> => {
  return sendExpNoteTo(expNoteId, request, 'rejected');
}

export const sendExpNoteToApprove = async (expNoteId: number, request: ExpNoteStateChangeRequest): Promise<ExpNote> => {
  return sendExpNoteTo(expNoteId, request, 'toapprove');
}

export const sendExpNoteToCheck = async (expNoteId: number, request: ExpNoteStateChangeRequest): Promise<ExpNote> => {
  return sendExpNoteTo(expNoteId, request, 'tocheck');
}

export const checkExpNote = async (expNoteId: number, request: ExpNoteStateChangeRequest): Promise<ExpNote> => {
  return sendExpNoteTo(expNoteId, request, 'check');
}

export const payExpNotes = async (request: ExpNoteStateChangeBulkRequest): Promise<number> => {
  return sendBulkExpNoteTo(request, 'pay');
}

export const accountExpNotes = async (request: ExpNoteStateChangeBulkRequest): Promise<number> => {
  return sendBulkExpNoteTo(request, 'account');
}

export const archiveExpNotes = async (request: ExpNoteStateChangeBulkRequest): Promise<number> => {
  return sendBulkExpNoteTo(request, 'archive');
}

const sendExpNoteTo = async (expNoteId: number, request: ExpNoteStateChangeRequest, path: string): Promise<ExpNote> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.put<ExpNote>(`${apiUrl}/expnotes/${expNoteId}/statechange/${path}`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

const sendBulkExpNoteTo = async (request: ExpNoteStateChangeBulkRequest, path: string): Promise<number> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.put<number>(`${apiUrl}/expnotes/statechange/${path}`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const revertExpNoteState = async (expNoteId: number, request: ExpNoteStateChangeRequest): Promise<ExpNote> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post<ExpNote>(`${apiUrl}/expnotes/${expNoteId}/statechange/revert`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getAvailableAmountsToReturn = async (expNoteId: number, searchDate: Date, paymentId?: number): Promise<AvailableAmountToReturn[]> => {
  try {
    const apiUrl = getApiUrl();
    const queryString = new URLSearchParams();
    const mDate = moment(searchDate);
    const date = mDate.format(`${DateFormat}`);
    queryString.set('searchDate', date);
    if (paymentId) {
      queryString.set('paymentId', paymentId.toString());
    }
    const resp = await axios.get<GenericList<AvailableAmountToReturn>>(`${apiUrl}/expnotes/${expNoteId}/payments/currencies`, {params: queryString});
    return resp.data.elements;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getCarRouteMap = async (addresses: string[]): Promise<Blob> => {
  try {
    const apiUrl = getApiUrl();
    const queryString = new URLSearchParams();
    addresses.forEach(adr => queryString.append('addr', adr));
    const resp = await axios.get<Blob>(`${apiUrl}/map`, {
      responseType: 'blob',
      params: queryString
    });
    return resp.data;
  } catch (err: any) {
    throw await createBlobError(err);
  }
}

export const linkCreCardMovToDocument = async (expNoteId: number, request: LinkCreCardMovToExpNoteDocRequest): Promise<ExpNoteCreCardMov> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/expnotes/${expNoteId}/crecardmovs`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}
