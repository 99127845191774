import {Alert, Box, Button, Paper, Stack, Typography} from "@mui/material";
import {Loader} from "../../base/loader";
import React, {useEffect, useState} from "react";
import {PageTitle} from "../../layout/page-title";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import {GeneralData} from "../detail/GeneralData";
import {activeStateCode, getStateCode} from "../../model";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {Save} from "@mui/icons-material";
import {Project, SaveProject} from "../model";
import {getProjectById, insertProject, updateProject} from "../Service";
import {useTranslation} from "react-i18next";

type ProjectEditFormValues = {
  code: string,
  description: string,
  startDate: Date,
  endDate: Date,
  state: string
}

function ProjectEditLoader() {
  return null;
}

export const ProjectEdit = () => {
  const {t} = useTranslation('project', {keyPrefix: 'edit'});
  //------
  // State
  //------
  const [saving, setSaving] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [project, setProject] = useState<Project>();
  const [active, setActive] = useState<boolean>(true);
  const {convertError} = useErrorMessage();

  const navigate = useNavigate();

  let {id} = useParams();

  // Objects
  //--------

  const initialValues = {
    code: project ? project.code : '',
    description: project ? project.description : '',
    startDate: project ? project.startDate : new Date(),
    endDate: project ? project.endDate : new Date(),
    state: project ? project.state : getStateCode(false),
    lastUpdateNum: project ? project.lastUpdateNum : 0
  };

  const validationSchema = Yup.object({
    code: Yup.string()
      .max(20, 'Must be 20 characters or less')
      .min(3, 'Must be at least 3 characters')
      .required('Required'),
    description: Yup.string()
      .max(50, 'Must be 50 characters or less')
      .min(3, 'Must be at least 3 characters')
      .required('Required')
  });

  const formMethods = useForm<ProjectEditFormValues>({
    defaultValues: {
      code: "",
      description: "",
      startDate: new Date(),
      endDate: new Date(),
      state: ""
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange"
  });

  // Use effects
  //------------
  useEffect(() => {
    if (id && id !== '-1') {
      setLoadingData(true);

      getProjectById(id)
        .then(response => {
          const data = response.data;
          setProject(data);
          setActive(data.state === 'EF' ? true : false);
          setLoadingData(false);
        })
        .catch(error => setErrorMsg(`Error retrieving data. ${error}`));
    }
    else if (id && id === '-1') {
      setLoadingData(true);
      setActive(true);
      const newProject = {
        id: -1,
        code: '',
        description: '',
        startDate: new Date(),
        endDate: new Date(),
        state: activeStateCode,
        lastUpdateNum: 0
      };
      setProject(newProject);
      setLoadingData(false);
    }
  }, [id]);



  useEffect(() => {
    if (project) {
      formMethods.reset(initialValues);
    }
  }, [project]);

  // Functions
  //----------

  const handleNavigateBack = () => {
    if (id === '-1') {
      navigate('/projects');
    }
    else {
      navigate(`/projects/detail/${id}`);
    }
  }

  const handleSave = (values) => {
    setErrorMsg(null);
    setSaving(true);

    const toSave: SaveProject = {
      id: project ? project.id : null,
      state: getStateCode(active),
      code: values.code,
      description: values.description,
      startDate: values.startDate,
      endDate: values.endDate,
      lastUpdateNum: project ? project.lastUpdateNum : 0
    };

    if (id !== '-1') {
      updateProject(toSave)
        .then(() => {
          handleNavigateBack();
        })
        .catch(error => {
          convertError(error)
            .then(msg => setErrorMsg(msg))
        })
        .finally(() => setSaving(false))
    }
    else {
      insertProject(toSave)
        .then(() => {
          handleNavigateBack();
        })
        .catch(error => {
          convertError(error)
            .then(msg => setErrorMsg(msg))
        })
        .finally(() => setSaving(false))
    }

  }

  const handleSaveClicked = () => {
    formMethods.handleSubmit(handleSave)();
  }

  // Componente React
  //-----------------
  return (
    <Box
      overflow={"hidden"}
      flexGrow={1}
    >
      <Paper
        elevation={0}
        sx={{
          height: "100%"
        }}
      >
        <Stack
          height={"100%"}
          overflow={"auto"}
          padding={"20px 30px 20px 20px"}
        >
          <Loader show={saving}/>

          {/*Skeleton*/}
          {
            loadingData &&
            <ProjectEditLoader/>
          }

          {/*Banner errori*/}
          {
            errorMsg &&
            <Alert
              severity={"error"}
              onClose={() => setErrorMsg(null)}
            >
              {errorMsg}
            </Alert>
          }

          {/*Titolo pagina e pulsanti*/}
          {
            project &&
            <PageTitle
              backClickHandler={handleNavigateBack}
              title={
                  <Stack
                    direction={{xs: "column", sm: "row"}}
                    alignItems={{xs: "flex-start", sm: "center"}}
                    justifyContent={{xs: "center", sm: "flex-start"}}
                    flexGrow={"1"}
                  >
                    <Typography variant={"h3"} mr={3}>{t("titleNew")}</Typography>
                  </Stack>
              }
            />
          }


          {/*Body*/}
          {
            project &&
            <Box flexGrow={1}>
              <FormProvider {...formMethods}>
                <form noValidate>
                  {/*</Stack>*/}

                  <Stack
                    mt={3}
                    flexGrow={1}
                    direction={{
                      xs: "column-reverse",
                      lg: "row"
                    }}
                    columnGap={2}
                    width={"100%"}
                  >

                    {/* Dati generali */}
                    <GeneralData props={{project: project, edit: true, staffsList: []}} />

                    {/* parte dx */}
                    <Stack
                      direction={{
                        xs: 'row',
                        md: 'column'
                      }}
                      alignItems={"center"}
                      mb={{
                        xs: 3,
                        lg: 0
                      }}
                      pl={{
                        xs: 0,
                        md: 3
                      }}
                      sx={{
                        flexBasis: {
                          xs: '0',
                          lg: '200px'
                        }
                      }}
                    >

                    </Stack>

                  </Stack>

                </form>
              </FormProvider>
            </Box>
          }

          {/*Pulsanti (Annulla e Salva)*/}
          {
            project &&
            <Stack
              alignSelf={"flex-end"}
              direction={"row"}
              mt={4}
              columnGap={2.2}
            >
              <Button
                variant="contained"
                className={"cta-button"}
                disabled={saving}
                onClick={() => {
                  setErrorMsg(null);
                  handleNavigateBack();
                }}>
                <Typography variant={"button"}>{t('button.cancel')}</Typography>
              </Button>

              <Button
                variant="contained"
                className={"cta-button-primary"}
                disabled={saving}
                onClick={() => {
                  setErrorMsg(null);
                  handleSaveClicked()
                }}
              >
                <Save sx={{fontSize: "1.1em"}}  />
                <Typography ml={1} variant={"button"}>{t('button.save')}</Typography>
              </Button>

            </Stack>
          }

        </Stack>

      </Paper>

    </Box>
  );
}
