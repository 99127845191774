import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import {
  Stack,
  Typography,
} from "@mui/material";
import {DRAWER_GUTTER} from "../model";
import {Logout, Menu} from "@mui/icons-material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import {useNavigate} from "react-router-dom";
import {removeToken} from "../../../config/token";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {useTheme} from "@mui/material/styles";
import {getAllJobsByState} from "../../jobs/Service";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {BatchJobInfos} from "../../jobs/model";
import {useDispatch} from "react-redux";
import Activities_register from "../../activities_register";
import {setHelpOpen} from "../../../reducers/Help";
import {useLicense} from "../../../hooks/useLicense";
import {ActivityLogState} from "../../activities_register/model";
import {TooltipZts} from "../../base/tooltip";
import {useTranslation} from "react-i18next";
import {useConfirm} from "../../../hooks/useConfirm";
import {getEnvLabel} from "../../../config/environment";

type ComponentProps = {
  drawerWidth: number,
  toggleMenuMobile: Function,
  menuDesktopOpen: boolean,
  isMobile: boolean
}

// interface BatchNotification {
//   id: number,
//   desc: string,
//   state: string,
//   fileName?: string,
//   infoMessage?: string
//   timeStamp: Date
// }
//
// interface BatchResponse {
//   id: number,
//   tenantId: number,
//   userId: number,
//   batchName: string,
//   batchDescription: string,
//   batchProgression: number,
//   filePath: string,
//   fileUniqueName: string,
//   fileDownloadName: string,
//   batchState: string,
//   insertTime: Date,
//   lastUpdTime: Date
// }

export const Header = (props: ComponentProps) => {
  const [open, setOpen] = useState<boolean>(false); // flag relativo alla modale delle notifiche
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [notifications, setNotification] = useState<BatchJobInfos[]>([]);
  //const [batchNotifications, setBatchNotofications] = useState<BatchNotification[]>([{id: 10269, desc: 'Stampa nota spese NS0000000008', state: 'COMPLETED_SUCCESSFULLY', fileName: 'ExpNotePrint.pdf'}]);

  const [toRead, setToRead] = useState<number>();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {convertError} = useErrorMessage();
  const theme = useTheme();

  const envLabel: string | null = getEnvLabel();

  function getAllJobs() {
    const states = Object.keys(ActivityLogState);
    let ntfs: BatchJobInfos[] = [];
    states.forEach(value => {
      getAllJobsByState(value)
        .then(response => {
          ntfs.push(...response);
        })
        .catch(error => {
          convertError(error)
            .then(msg => {
              setErrorMsg(msg);
              setLoading(false);
            })
        })
    });
    const sorted = ntfs.sort((a, b) => {
      // @ts-ignore
      return a.lastUpdTime - b.lastUpdTime;
    });
    setNotification(sorted);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    getAllJobs();
  }, []);
  const {
    user,
    setUser,
    adminUser
  } = useLoggedUser();

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const {t} = useTranslation('common');

  const companyName = user && user.tenant ? user.tenant.description : "";

  const {
    drawerWidth,
    toggleMenuMobile,
    menuDesktopOpen,
    isMobile
  } = props;

  const {confirm} = useConfirm();

  const handleLogout = () => {
    confirm({
      body: (
        <Typography variant="body1" display="inline" mr={0.5}>
          {t('header.confirmLogout')}
        </Typography>
      ),
      onConfirm: () => {
        removeToken();
        setUser(null);
        navigate('/login');
      }
    });
  }

  const showHelp = () => {
    dispatch(setHelpOpen(true));
  }

  return (
    <AppBar
      position="fixed"
      className={"app-header"}
      sx={{
        width: {md: `calc(100% - ${drawerWidth + DRAWER_GUTTER}px)`},
        ml: {md: `${drawerWidth + DRAWER_GUTTER}px`},
        color: '#000',
        marginTop: '12px'
      }}
    >
      <Toolbar>

        {/*Pulsante per mostrare il menu Mobile*/}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          onClick={() => toggleMenuMobile()}
          sx={{mr: 1, display: {md: 'none'}}}
        >
          <Menu/>
        </IconButton>

        <Stack
          overflow={"hidden"}
          flexGrow={1}
          direction={{
            xs: "column",
            md: "row"
          }}
          alignItems={{
            xs: "flex-start",
            md: "center"
          }}
          spacing={{
            xs: 0,
            md: 2
          }}
        >

          <Typography
            variant={"h5"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            mr={2}
            maxWidth={{
              xs: "300px",
              md: "260px",
              lg: "450px"
            }}
            textOverflow="ellipsis"
          >
            {companyName}
          </Typography>
          <Box
            className={"edition-pill"}
          >
            <Typography variant={"subtitle2"}
                        textTransform={"uppercase"}>{`${free ? "personal" : "professional"} edition`}</Typography>
          </Box>

          {(adminUser || envLabel ) &&
            <div className='manager-wrapper'>
              {envLabel && <div className='env-wrapper'>{envLabel}</div> }
              {adminUser && <div className='admin-wrapper'>{adminUser} &rarr; {user?.firstName} {user?.lastName}</div> }
            </div>
          }

        </Stack>

        {/*Pulsanti in fondo a dx*/}
        <Box
          pr={{
            xs: 0,
            lg: 3
          }}
        >
          <Box
            display={{
              xs: 'none',
              md: 'inline-block'
            }}
          >
            <TooltipZts
              title={t('header.help')}
              placement={"bottom"}
              enterDelay={400}
            >
              <IconButton
                color="inherit"
                disableRipple={true}
                onClick={() => showHelp()}
                id={"help-button"}
              >
                <HelpOutlineOutlinedIcon/>
              </IconButton>
            </TooltipZts>
          </Box>
          <Box
            display={"inline-block"}
          >
            <TooltipZts
              title={t('header.activities')}
              placement={"bottom"}
              enterDelay={400}
            >
              <IconButton
                color="inherit"
                disableRipple={true}
                onClick={() => setOpen(!open)}
              >
                <Badge badgeContent={toRead ? toRead : 0} color="error">
                  <NotificationsNoneOutlinedIcon/>
                </Badge>
              </IconButton>
            </TooltipZts>
          </Box>
          <TooltipZts
            title={t('header.logout')}
            placement={"bottom"}
            enterDelay={400}
          ><IconButton
            color="inherit"
            disableRipple={true}
            onClick={handleLogout}
          >
            <Logout/>
          </IconButton>
          </TooltipZts>
        </Box>
      </Toolbar>

      <Activities_register open={open} handleClose={() => setOpen(false)}/>

    </AppBar>
  );
}
