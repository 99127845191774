/*
Funzioni da usare insieme a yup
 */

import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import moment from 'moment';
import { getAmountDecimalSeparator, Locale } from './LocalizationUtil';


type I18NKeyNs = {
  key: string,
  ns?: string
}

export type I18NOptions = {
  required: I18NKeyNs,
  min: I18NKeyNs,
  max: I18NKeyNs,
  length: I18NKeyNs,
  date: I18NKeyNs,
  endDate: I18NKeyNs,
  invalidNumber: I18NKeyNs,
  invalidEmail: I18NKeyNs,
  sameValue: I18NKeyNs,
};

const defaultOptions: I18NOptions = {
  required: {key: 'required', ns: 'validation'},
  min: {key: 'invalidMinLength', ns: 'validation'},
  max: {key: 'invalidMaxLength', ns: 'validation'},
  length: {key: 'invalidLength', ns: 'validation'},
  date: {key: 'invalidDate', ns: 'validation'},
  endDate: {key: 'invalidEndDate', ns: 'validation'},
  invalidNumber: {key: 'invalidNumber', ns: 'validation'},
  invalidEmail: {key: 'invalidEmail', ns: 'validation'},
  sameValue: {key: 'sameValue', ns: 'validation'},
}

const mergeWithDefault = (options?: Partial<I18NOptions>): I18NOptions => {
  if (options) {
    return {
      ...defaultOptions,
      ...options
    }
  }
  return defaultOptions;
}


type YupTransform = {
  (value: any, originalValue: any): any;
}


// Se il valore è la stringa vuota lo trasforma in null
const yupEmptyAsNull: YupTransform = (value: string | null, originalValue: string | null) => {
  if (value && value==='') {
    return null;
  }
  return value;
}

const yupNanAsNull = (value, originalValue) => {
  if (isNaN(value)){
    return null;
  }
  return value;
}

const getErrorMessage = (t, pair: I18NKeyNs, obj: object): string => {
  let localMsg;
  const ns = pair.ns;
  if (ns) {
    const options = {...obj, ns: ns};
    localMsg = t(pair.key, options);
  } else {
    localMsg = t(pair.key, obj);
  }
  return localMsg;
}

const getInvalidDateErrorMessage = (t, myOp: I18NOptions): string => {
  return getErrorMessage(t, myOp.date, {});
}

const getInvalidEndDateErrorMessage = (t, myOp: I18NOptions): string => {
  return getErrorMessage(t, myOp.endDate, {});
}

const getRequiredErrorMessage = (t, myOp: I18NOptions): string => {
  return getErrorMessage(t, myOp.required, {});
}

const getMinErrorMessage = (min: number, t, myOp: I18NOptions): string => {
  return getErrorMessage(t, myOp.min, {length: min});
}

const getSameValueErrorMessage = (field: string, t, myOp: I18NOptions): string => {
  return getErrorMessage(t, myOp.sameValue, {field: field});
}

const getMaxErrorMessage = (max: number, t, myOp: I18NOptions): string => {
  return getErrorMessage(t, myOp.max, {length: max});
}

const getLengthErrorMessage = (size: number, t, myOp: I18NOptions): string => {
  return getErrorMessage(t, myOp.length, {length: size});
}

const getNumberErrorMessage = (t, myOp: I18NOptions): string => {
  return getErrorMessage(t, myOp.invalidNumber, {});
}

const getInvalidEmailErrorMessage = (t, myOp: I18NOptions): string => {
  return getErrorMessage(t, myOp.invalidEmail, {});
}

// Un valore richiesto in un certo range
export const yupMinMaxRequiredString = (min: number, max: number, t, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const requiredErr = getRequiredErrorMessage(t, myOp);
  const minErr = getMinErrorMessage(min, t, myOp);
  const maxErr = getMaxErrorMessage(max, t, myOp);

  return Yup.string()
    .min(min, minErr)
    .max(max, maxErr)
    .required(requiredErr);
}

export const yupRequiredBoolean = (t: TFunction, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const requiredErr = getRequiredErrorMessage(t, myOp);

  return Yup.boolean().oneOf([true], requiredErr);
}

// Un valore richiesto di almeno un certo numeo di caratteri
export const yupMinRequiredString = (min: number, t, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const requiredErr = getRequiredErrorMessage(t, myOp);
  const minErr = getMinErrorMessage(min, t, myOp);

  return Yup.string()
    .min(min, minErr)
    .required(requiredErr);
}

export type YupFieldType = {
  name: string
  label: string
}

// Richiede che due valori siano uguali
export const yupStringSameValue = (field: YupFieldType, t, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const sameErrorMsg = getSameValueErrorMessage(field.label, t, myOp);

  //https://stackoverflow.com/questions/61862252/yup-schema-validation-password-and-confirmpassword-doesnt-work
  return Yup.string()
    .oneOf([Yup.ref(field.name)], sameErrorMsg)

}

export const yupRequiredString = (t: TFunction, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const requiredErr = getRequiredErrorMessage(t, myOp);

  return Yup.string()
    .transform(yupEmptyAsNull)
    .required(requiredErr);
}

// Un valore vuoto oppure un valore di una dimensione precisa, esempio codice fiscale
export const yupExactNullableString = (size: number, t, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const msg = getLengthErrorMessage(size, t, myOp);

  return Yup.string()
    .nullable()
    .transform(yupEmptyAsNull) // serve perchè la funzione min() altrimenti dà errore anche se c'è una stringa vuota
    .min(size, msg)
    .max(size, msg)
}

// Un valore vuoto oppure un valore in un certo range
export const yupMinMaxNullableString = (min: number, max: number, t, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const minErr = getMinErrorMessage(min, t, myOp);
  const maxErr = getMaxErrorMessage(max, t, myOp);
  return Yup.string()
    .nullable()
    .transform(yupEmptyAsNull) // serve perchè la funzione min() altrimenti dà errore anche se c'è una stringa vuota
    .min(min, minErr)
    .max(max, maxErr)
}

export const yupExactMatchNullableString = (length: number, t, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const err = getLengthErrorMessage(length, t, myOp);
  const stringRegex = `^(|.{${length},${length}})$`;
  const regex = new RegExp(stringRegex);
  console.log(regex);
  return Yup.string()
    .matches(regex, err)
}

// Un valore vuoto oppure un valore in un certo range
export const yupMaxNullableString = (max: number, t, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const maxErr = getMaxErrorMessage(max, t, myOp);
  return Yup.string()
    .nullable()
    .transform(yupEmptyAsNull)
    .max(max, maxErr)
}

// Un valore vuoto oppure un valore in un certo range
export const yupNullableString = () => {
  return Yup.string()
    .nullable()
    .transform(yupEmptyAsNull)
}

export const yupNullableNumber = () => {
  return Yup.number()
    .transform(yupNanAsNull)
    .nullable()
    .default(undefined);
}

export const yupMinRequiredNumber = (min: number, t: TFunction, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const minErr = getMinErrorMessage(min, t, myOp);
  return Yup.number()
    .required()
    .transform(yupNanAsNull)
    .nullable()
    .min(min, minErr)
    .default(undefined);
}

export const yupRequiredEmail = (t, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const requiredErr = getRequiredErrorMessage(t, myOp);
  const invalidEmailErr = getInvalidEmailErrorMessage(t, myOp);
  return Yup.string()
    .email(invalidEmailErr)
    .required(requiredErr);
}

// Una data obbligatoria
export const yupRequiredDate = (t, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const requiredErr = getRequiredErrorMessage(t, myOp);
  const invalidDateErr = getInvalidDateErrorMessage(t, myOp);

  // TODO provare senza nullable
  return Yup.date()
    .typeError(invalidDateErr)
    .nullable()
    .required(requiredErr)
    .default(undefined);
}

// Una data fine periodo obbligatoria (con controllo di validità rispetto alla data inizio periodo)
export const yupRequiredEndDate = (t, startDateFieldName: string, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const invalidEndDateErr = getInvalidEndDateErrorMessage(t, myOp);

  return Yup.date()
    .when(startDateFieldName, startDate => {
      if (startDate && moment(startDate).isValid()) {
        return yupRequiredDate(t).min(startDate, invalidEndDateErr)
      } else {
        return yupRequiredDate(t);
      }
    });
}

export const yupOptionalDate = (t, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const invalidDateErr = getInvalidDateErrorMessage(t, myOp);

  return Yup.date()
    .typeError(invalidDateErr)
    .nullable()
    .default(undefined);
}

export const yupRequiredAmount = (t, decimalNum: number, locale: Locale, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const requiredErr = getRequiredErrorMessage(t, myOp);
  const numberErr = getNumberErrorMessage(t, myOp);

  return Yup.string()
    .required(requiredErr)
    .matches(getNumberRegexByLocaleAndDecimalNumbers(decimalNum, locale), numberErr);
}

export const yupOptionalAmount = (t, decimalNum: number, locale: Locale, options?: Partial<I18NOptions>) => {
  const myOp = mergeWithDefault(options);
  const numberErr = getNumberErrorMessage(t, myOp);

  return Yup.string()
    .matches(getNumberRegexByLocaleAndDecimalNumbers(decimalNum, locale), numberErr);
}

const getNumberRegexByLocaleAndDecimalNumbers = (decimalNum: number, locale: Locale) => {
  const decimalSep = getAmountDecimalSeparator(locale);
  let decimals = "";
  if (decimalNum > 0) {
    decimals = `(\\${decimalSep}\\d{1,${decimalNum.toString()}})?`;
  }
  // la virgola è il separatore dei decimali
  // per ammettere un importo negativo dopo ^ mettere -?
  const pattern = `^\\d+${decimals}$`;
  //console.log('pattern: ', pattern)
  return new RegExp(pattern);
}
