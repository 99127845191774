import {getApproverDesc, User} from "../model";
import {Grid, Skeleton, Stack, Typography} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {AutocompleteZts} from "../../base/autocomplete";
import {AutocompleteGenericOption} from "../../base/autocomplete/model";
import Box from "@mui/material/Box";
import {PremiumIcon} from "../../base/premium-icon";
import {useTranslation} from "react-i18next";
import {useLicense} from "../../../hooks/useLicense";
import {PopupContentType} from "../../license-popup/model";


type ComponentProps = {
  user: User,
  enabled?: boolean,
  costCenterOpts?: AutocompleteGenericOption[],
  loadingCostCenter?: boolean,
  accountOpts?: AutocompleteGenericOption[],
  loadingAccount?: boolean,
  approverOpts?: AutocompleteGenericOption[],
  loadingApprover?: boolean
}

export const AdministrativeData = (props: ComponentProps) => {

  const {
    user,
    enabled,
    costCenterOpts,
    loadingCostCenter,
    accountOpts,
    loadingAccount,
    approverOpts,
    loadingApprover
  } = props;

  let control;
  if (enabled) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formMethods = useFormContext();
    control = formMethods.control;
  }

  const {t} = useTranslation('user', {keyPrefix: "detail.administrativeData"});

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  return (
    <Grid
      mt={2.5}
      spacing={2}
      container
    >
      <Grid item xs={12}>
        {free ? (
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
            >
              <Typography variant="h6" textTransform={"uppercase"}>{t("title")}</Typography>
              {/*<PremiumIcon/>*/}
            </Stack>
          ) :
          (
            <Typography variant="h6" textTransform={"uppercase"}>{t("title")}</Typography>
          )}
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Stack
          direction={{
            xs: "column",
            sm: "row"
          }}
          justifyContent={"stretch"}
          spacing={2}
        >

          {enabled && !free ? (
            <>
              {loadingApprover && <Box flexGrow={1}><Skeleton height={46}/></Box>}

              {!loadingApprover && approverOpts &&
                <Box flexGrow={1}>
                  <Controller
                    name="approver"
                    control={control}
                    render={({field, fieldState}) => {
                      return <AutocompleteZts
                        id={field.name}
                        options={approverOpts}
                        label={t("approver")}
                        selectedValue={field.value}
                        setValue={(_, value) => {
                          field.onChange(value);
                        }}
                        codeAndDesc={true}
                        errorMsg={fieldState.error?.message}
                      />
                    }}
                  />
                </Box>
              }
            </>
          ) : (
            <TextFieldZts
              disabled={true}
              label={t("approver")}
              value={getApproverDesc(user.approver)}
              InputProps={{
                endAdornment: (
                  free ? <PremiumIcon type={PopupContentType.APPROVER_PILL} clickable={true}/> : null
                )
              }}
            />
          )}

          {enabled && !free ? (
            <>
              {loadingAccount && <Box flexGrow={1}><Skeleton height={46}/></Box>}

              {!loadingAccount && accountOpts &&
                <Box flexGrow={1}>
                  <Controller
                    name="account"
                    control={control}
                    render={({field, fieldState}) => {
                      return <AutocompleteZts
                        id={field.name}
                        options={accountOpts}
                        label={t("account")}
                        selectedValue={field.value}
                        setValue={(_, value) => {
                          field.onChange(value);
                        }}
                        codeAndDesc={true}
                        errorMsg={fieldState.error?.message}
                      />
                    }}
                  />
                </Box>
              }
            </>
          ) : (
            <TextFieldZts
              disabled={true}
              label={t("account")}
              value={user.account?.description}
              InputProps={{
                endAdornment: (
                  free ? <PremiumIcon type={PopupContentType.ACCOUNT_PILL} clickable={true}/> : null
                )
              }}
            />
          )}

          {enabled && !free ? (
            <>
              {loadingCostCenter && <Box flexGrow={1}><Skeleton height={46}/></Box>}

              {!loadingCostCenter && costCenterOpts &&
                <Box flexGrow={1}>
                  <Controller
                    name="costCenter"
                    control={control}
                    render={({field, fieldState}) => {
                      return <AutocompleteZts
                        id={field.name}
                        options={costCenterOpts}
                        label={t("costCenter")}
                        selectedValue={field.value}
                        setValue={(_, value) => {
                          field.onChange(value);
                        }}
                        codeAndDesc={true}
                        errorMsg={fieldState.error?.message}
                      />
                    }}
                  />
                </Box>
              }
            </>
          ) : (
            <TextFieldZts
              disabled={true}
              label={t("costCenter")}
              value={user.costCenter?.description}
              InputProps={{
                endAdornment: (
                  free ? <PremiumIcon type={PopupContentType.LEVEL_PILL} clickable={true}/> : null
                )
              }}
            />
          )
          }

        </Stack>
      </Grid>
    </Grid>
  );
}
