import Box from "@mui/material/Box";
import {Stack, Typography} from "@mui/material";
import {RadioGroupZts, RadioOption} from "../../../../../base/radio-group";
import React, {ChangeEvent} from "react";
import {ExpenseFilter, ExpenseFilterType, ExpenseGroupingOption} from "../../../model";
import {CreditCard, Receipt, Warning} from "@mui/icons-material";
import {useExpNoteDetail} from "../../../hooks/useExpNoteDetail";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  groupBy: ExpenseGroupingOption,
  groupChangeHandler: (groupBy: ExpenseGroupingOption) => void,
  filters: ExpenseFilter[],
  filterChangeHandler: (filters: ExpenseFilter[]) => void
}

export const ExpNoteExpensesHeader = ({
                                        groupBy,
                                        groupChangeHandler,
                                        filters,
                                        filterChangeHandler
                                      }: ComponentProps) => {

  const {t} = useTranslation("exp-note", {keyPrefix: "expense.list"});

  const {expenses} = useExpNoteDetail();

  const groupingOptions: RadioOption[] = [];
  Object.values(ExpenseGroupingOption)
    .forEach(go => {
      let groupEnabled = false;
      switch (go) {
        case ExpenseGroupingOption.PROJECT:
          groupEnabled = expenses ? expenses.some(e => e.projectId) : false;
          break;
        case ExpenseGroupingOption.LOCALITY:
          groupEnabled = expenses ? expenses.some(e => e.locality) : false;
          break;
        case ExpenseGroupingOption.TRAVEL_POLICY:
          groupEnabled = !!expenses;
          break;
        case ExpenseGroupingOption.EXPENSE_TYPE:
          groupEnabled = !!expenses;
          break;
        case ExpenseGroupingOption.NONE:
          groupEnabled = true;
      }

      if (groupEnabled) {
        groupingOptions.push({value: go, label: t(`grouping.${go}`)});
      }
    });

  const handleGroupByChange = (event: ChangeEvent<HTMLInputElement>) => {
    groupChangeHandler(((event.target as HTMLInputElement).value) as ExpenseGroupingOption);
  }

  const handleToggleFilter = (filterType: ExpenseFilterType) => {
    filterChangeHandler(filters.map(f => f.type === filterType ? {...f, enabled: !f.enabled} : f));
  };

  const getFilterClass = (filterType: ExpenseFilterType, enabled?: boolean): string => {
    switch (filterType) {
      case ExpenseFilterType.MAXIMUM:
        return enabled ? "expense-filter-anomaly" : "expense-filter-disabled";
      case ExpenseFilterType.CREDIT_CARD:
        return enabled ? "expense-filter-creditcard" : "expense-filter-disabled";
      case ExpenseFilterType.ACCOUNTING:
        return enabled ? "expense-filter-accounting" : "expense-filter-disabled";
    }
    return '';
  }

  const getFilterIcon = (filterType: ExpenseFilterType): JSX.Element => {
    switch (filterType) {
      case ExpenseFilterType.MAXIMUM:
        return <Warning fontSize={"small"} sx={{fontSize: '15px'}}/>;
      case ExpenseFilterType.CREDIT_CARD:
        return <CreditCard fontSize={"small"} sx={{fontSize: '15px'}}/>;
      case ExpenseFilterType.ACCOUNTING:
        return <Receipt fontSize={"small"} sx={{fontSize: '15px'}}/>;
    }
    return <></>;
  }

  const positiveCountFilters = filters.filter(f => f.count > 0);

  return (
    <>
      <Stack
        rowGap={2}
        display="inline-flex"
        justifyContent="space-between"
      >
        <Typography variant={"h6"}>{t("grouping.title")}</Typography>
        <RadioGroupZts
          selectedValue={groupBy}
          options={groupingOptions}
          handleChange={handleGroupByChange}
          displayOnRow={true}
        />
      </Stack>

      {positiveCountFilters.length > 0 && <Stack
        display={{
          xs: "flex",
          lg: "inline-flex"
        }}
        justifyContent="space-between"
        rowGap={2}
        mt={{
          xs: 1.5,
          lg: 0
        }}
        ml={{
          xs: 0,
          lg: 3
        }}
        mr={3}
        mx={3}
        maxWidth="fit-content"
      >
        <Typography variant={"h6"}>{t("filter")}</Typography>
        <Box
          className={"expense-filter-wrapper"}
        >
          {positiveCountFilters
            .map((filter) => {
              return <Box
                key={filter.type}
                onClick={(e) => handleToggleFilter(filter.type)}
                className={getFilterClass(filter.type, filter.enabled)}
              >
                {getFilterIcon(filter.type)}
                <Typography sx={{ml: 0.5}}>{filter.count}</Typography>
              </Box>
            })}
        </Box>
      </Stack>}

    </>
  );
}
