import {Alert, Button, Grid, Stack, Typography} from "@mui/material";
import {ExpNoteStateFE, getExpNoteFEState, isExpNoteInProgress, isExpPayedByTraveller} from "../../../model";
import Box from "@mui/material/Box";
import {Add, Check, Clear, Warning, Undo} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {useExpNoteDetail} from "../../hooks/useExpNoteDetail";
import {ExpNoteMaximum} from "./maximum";
import {ExpNoteListRole} from "../../../../../reducers/ExpNoteList";
import {useExpNoteList} from "../../../hooks/useExpNoteList";
import {
  checkExpNote,
  getExpNote,
  getExpNoteExpenses, revertExpNoteState,
  sendExpNoteDeleted,
  sendExpNoteRejected,
  sendExpNoteToApprove,
  sendExpNoteToCheck
} from "../../../Service";
import {ExpNoteChart} from "./chart";
import {useConfirm} from "../../../../../hooks/useConfirm";
import {useLoggedUser} from "../../../../../hooks/useLoggedUser";
import {formatDate} from "../../../../../util/DateUtil";
import {formatAmount, formatAmountZero} from "../../../../../util/NumberUtil";
import {useTranslation} from "react-i18next";
import {useErrorMessage} from "../../../../../util/ErrorUtil";
import {useNavigate} from "react-router-dom";
import {getUserById} from "../../../../users/Service";
import {isCreditCardAnomaly, isReadonlyExpNote} from "../../model";
import {ExpNoteExpenseDetail} from "../expenses/detail";
import {useExpenseForm} from "../../../../../hooks/useExpenseForm";
import {useSnackbar} from "notistack";
import {isCreditCardModuleEnabled} from "../../../../../config/token";
import {CreCardMovState} from "../../../../credit-card-mov/model";
import {useLicense} from "../../../../../hooks/useLicense";

export const ExpNoteDashboard = () => {

  const {
    expNote,
    expenses,
    creditCardMovs,
    updateExpenses,
    clearExpNote
  } = useExpNoteDetail();

  const {
    currentRole
  } = useExpNoteList();

  const {user, userLocale: locale, companyDecimalNum: decimalNum, companyCurrencyCode} = useLoggedUser();

  const {isProfessional} = useLicense();

  const {t} = useTranslation("exp-note", {keyPrefix: "detail"});
  const {t: tExpList} = useTranslation("exp-note", {keyPrefix: "expense.list"});
  const {t: tCommon} = useTranslation("common");

  const [openMaximum, setOpenMaximum] = useState(false);
  const [expNoteHolderHasApprover, setExpNoteHolderHasApprover] = useState(false);

  const {confirm} = useConfirm();

  const {enqueueSnackbar} = useSnackbar();

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const {convertError} = useErrorMessage();

  const [createNewExpense, setCreateNewExpense] = useState(false);
  const {clearFormState} = useExpenseForm();

  const navigate = useNavigate();

  const balanceLabel = expNote && expNote.balance && expNote.balance < 0 ? t("balanceNeg") : t("balancePos");

  const isTraveller = currentRole && currentRole === ExpNoteListRole.TRAVELLER;
  const isAdmin = currentRole && currentRole === ExpNoteListRole.ADMIN;
  const isApprover = currentRole && currentRole === ExpNoteListRole.APPROVER;

  const isReadonly = isReadonlyExpNote(expNote, currentRole);

  const creditCardModuleEnabled = isCreditCardModuleEnabled();

  const state: ExpNoteStateFE | null = expNote ? getExpNoteFEState(expNote.state) : null;
  const isExpNoteToApprove = state === ExpNoteStateFE.DA_APPROVARE;
  const isExpNoteToCheck = state === ExpNoteStateFE.DA_CONTROLLARE;

  useEffect(() => {
    if (expNote) {
      const state: ExpNoteStateFE | null = getExpNoteFEState(expNote.state);
      if (state === ExpNoteStateFE.DA_CONTROLLARE) {
        getUserById(expNote.staffId.toString())
          .then(user => setExpNoteHolderHasApprover(!!user.approver));
      }
    }
  }, [expNote]);

  const totalPayedByTraveller = expenses ? expenses
    .filter(exp => isExpPayedByTraveller(exp))
    .reduce((total: number, exp) => total += exp.compAmount, 0) : 0;

  const totalPayedByCompany = expenses ? expenses
    .filter(exp => !isExpPayedByTraveller(exp))
    .reduce((total: number, exp) => total += exp.compAmount, 0) : 0;

  const handleOpenMaximum = () => {
    if (isAdmin || isApprover) {
      setOpenMaximum(true);
    }
  }

  const handleMaximumClose = async (updated) => {
    setOpenMaximum(false);

    if (updated && expNote) {
      const newExpNote = await getExpNote(expNote.id);
      const expenses = await getExpNoteExpenses(expNote.id);
      updateExpenses(newExpNote, expenses);
    }
  }

  const handleSaveExpNote = () => {
    if (isExpNoteToCheck && isAdmin && creditCardModuleEnabled) {
      const creCardMovAnomaly = creditCardMovs?.some(mov => mov.creCardMovState === CreCardMovState.DISCONNECTED);
      const creCardExpAnomaly = expenses?.some(e => isCreditCardAnomaly(e, creditCardMovs));
      if (creCardMovAnomaly || creCardExpAnomaly) {
        let message = '';
        if (creCardMovAnomaly && creCardExpAnomaly) {
          message = t("uncheckedCreCardAllWarning");
        } else if (creCardMovAnomaly) {
          message = t("uncheckedCreCardMovWarning");
        } else if (creCardExpAnomaly) {
          message = t("uncheckedCreCardExpWarning");
        }
        confirm({
          body: (
            <>
              <Typography variant="body1">{message}</Typography>
              <Typography variant="body1">{tCommon("confirmDialog.continueQuestion")}</Typography>
            </>
          ),
          onConfirm: async () => {
            await handleDoSaveExpNote();
          },
          noCloseAtConfirm: !!expenses && expenses.some(e => e.maxExcExp > 0 && !e.approvalState)
        });
      } else {
        handleDoSaveExpNote();
      }
    } else {
      handleDoSaveExpNote();
    }
  }

  const handleDoSaveExpNote = async () => {
    if (expNote) {
      // se sono all'approvazione o al controllo e ci sono eccedenze non
      // autorizzate allora impedisco il salvataggio e apro il popup dei massimali
      if ((isExpNoteToApprove || isExpNoteToCheck) &&
        expenses &&
        expenses.some(e => e.maxExcExp > 0 && !e.approvalState)) {
        confirm({
          body: (
            <>
              <Typography variant="body1">{t("saveWarning1")}</Typography>
              <Typography variant="body1">{t("saveWarning2")}</Typography>
            </>
          ),
          onConfirm: () => setOpenMaximum(true),
          noCloseAtConfirm: false
        });
        return;
      }

      let saveFunc: Function | null = null;
      let message = "";
      switch (state) {
        case ExpNoteStateFE.DA_COMPLETARE:
        case ExpNoteStateFE.DA_RIVEDERE:
          if (user && user.approver) {
            saveFunc = sendExpNoteToApprove;
            message = t("stateChange.sentToApprover");
          } else {
            saveFunc = sendExpNoteToCheck;
            message = t("stateChange.sentToControl");
          }
          break;
        case ExpNoteStateFE.DA_APPROVARE:
          saveFunc = sendExpNoteToCheck;
          message = t("stateChange.sentToControl");
          break;
        case ExpNoteStateFE.DA_CONTROLLARE:
          saveFunc = checkExpNote;
          message = t("stateChange.sentToPayment");
          break;
        case ExpNoteStateFE.DA_LIQUIDARE:
        case ExpNoteStateFE.DA_CONTABILIZZARE:
        case ExpNoteStateFE.DA_ARCHIVIARE:
          clearExpNote();
          navigate('../../', {
            state: {
              expNoteId: expNote?.id,
              lastUpdateNum: expNote?.lastUpdNum
            }
          });
          return;
      }

      if (saveFunc) {
        try {
          await saveFunc(expNote.id, {lastUpdateNum: expNote.lastUpdNum});
          doAfterSaveExpNote(message);
        } catch (error: any) {
          convertError(error).then(msg => setErrorMsg(msg));
        }
      }
    }
  }

  const handleSecondarySaveExpNote = async () => {
    if (expNote) {
      const state: ExpNoteStateFE | null = getExpNoteFEState(expNote.state);

      let saveFunc: Function | null = null;
      let toConfirm = false;
      let message = "";
      switch (state) {
        case ExpNoteStateFE.DA_LIQUIDARE:
          saveFunc = revertExpNoteState;
          message = t("stateChange.resentToControl");
          break;
        case ExpNoteStateFE.DA_CONTABILIZZARE:
          saveFunc = revertExpNoteState;
          message = t("stateChange.resentToPayment");
          break;
        case ExpNoteStateFE.DA_ARCHIVIARE:
          saveFunc = revertExpNoteState;
          message = isProfessional() ? t("stateChange.resentToContab") : t('stateChange.resentToPayment');
          break;
        case ExpNoteStateFE.DA_APPROVARE:
        case ExpNoteStateFE.DA_CONTROLLARE:
          saveFunc = sendExpNoteRejected;
          message = t("stateChange.rejected");
          break;
        default:
          saveFunc = sendExpNoteDeleted;
          message = t("stateChange.deleted");
          toConfirm = true;
      }

      if (saveFunc) {
        if (toConfirm) {
          confirm({
            body: (
              <>
                <Typography variant="body1">{t("deleteWarning")}</Typography>
                <Typography variant="body1">{tCommon("confirmDialog.continueQuestion")}</Typography>
              </>
            ),
            variant: "delete",
            onConfirm: async () => {
              if (saveFunc) {
                try {
                  await saveFunc(expNote.id, {lastUpdateNum: expNote.lastUpdNum});
                  doAfterSaveExpNote(t("stateChange.deleted"));
                } catch (error: any) {
                  convertError(error).then(msg => setErrorMsg(msg));
                }
              }
            }
          });
        } else {
          if (saveFunc) {
            try {
              await saveFunc(expNote.id, {lastUpdateNum: expNote.lastUpdNum});
              doAfterSaveExpNote(message);
            } catch (error: any) {
              convertError(error).then(msg => setErrorMsg(msg));
            }
          }
        }
      }
    }
  }

  const handleSendAgainToApprover = async () => {
    if (expNote) {
      try {
        await sendExpNoteToApprove(expNote.id, {lastUpdateNum: expNote.lastUpdNum});
        doAfterSaveExpNote(t("stateChange.resentToApprover"));
      } catch (error: any) {
        convertError(error).then(msg => setErrorMsg(msg));
      }
    }
  }

  const doAfterSaveExpNote = (message: string) => {
    enqueueSnackbar(message,
      {
        variant: "success",
        autoHideDuration: 3500,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
    clearExpNote();
    navigate('../../');
  }

  const getPrimaryCTA = (): JSX.Element | null => {
    if (expNote && user) {
      const state: ExpNoteStateFE | null = getExpNoteFEState(expNote.state);

      // se la NS ha superato il controllo non c'è più il pulsante
      if (!state || state === ExpNoteStateFE.ARCHIVIATE) {
        return null;
      }

      // se la NS è da completare il pulsante è visibile soltanto per il viaggiatore
      if (state === ExpNoteStateFE.DA_COMPLETARE && !isTraveller) {
        return null;
      }

      // se la NS è da approvare il pulsante è visibile soltanto per l'approvatore
      if (state === ExpNoteStateFE.DA_APPROVARE && !isApprover) {
        return null;
      }

      // se la NS è da controllare il pulsante è visibile soltanto per l'amministratore
      if (state === ExpNoteStateFE.DA_CONTROLLARE && !isAdmin) {
        return null;
      }

      if (isAdmin && isExpNoteInProgress(state)) { // Se la NS è "IN CORSO" il pulsante non è visibile all'amministratore
        return null;
      }

      let label;

      switch (state) {
        case ExpNoteStateFE.DA_LIQUIDARE:
          label = t('pay');
          break;
        case ExpNoteStateFE.DA_CONTABILIZZARE:
          label = t('contab');
          break;
        case ExpNoteStateFE.DA_APPROVARE:
          label = t('approve');
          break;
        case ExpNoteStateFE.DA_ARCHIVIARE:
          label = t('archive');
          break;
        case ExpNoteStateFE.DA_COMPLETARE:
        case ExpNoteStateFE.DA_RIVEDERE:
          label = user?.approver ? t("sendToApprover") : t("sendToControl");
          break;
        default:
          label = tCommon("confirm");
      }

      return (
        <Button
          variant="contained"
          className={"exp-note-detail-cta-primary-button"}
          onClick={handleSaveExpNote}
        >
          <Check fontSize={"small"}/>
          <Typography ml={1}>{label}</Typography>
        </Button>
      );
    }
    return null;
  }

  const getSecondaryCTA = (): JSX.Element | null => {
    if (expNote && user) {
      const state: ExpNoteStateFE | null = getExpNoteFEState(expNote.state);
      let label;
      let icon;

      // se la NS ha superato il controllo il pulsante è visibile???
      if (!state ||
        state === ExpNoteStateFE.ARCHIVIATE
      ) {
        return null;
      }

      // se la NS è da completare il pulsante è visibile soltanto per il viaggiatore
      if (state === ExpNoteStateFE.DA_COMPLETARE && !isTraveller) {
        return null;
      }

      // se la NS è da approvare il pulsante è visibile soltanto per l'approvatore
      if (state === ExpNoteStateFE.DA_APPROVARE && !isApprover) {
        return null;
      }

      // se la NS è da controllare il pulsante è visibile soltanto per l'amministratore
      if (state === ExpNoteStateFE.DA_CONTROLLARE && !isAdmin) {
        return null;
      }

      if (isAdmin && isExpNoteInProgress(state)) { // Se la NS è "IN CORSO" il pulsante non è visibile all'amministratore
        return null;
      }

      switch (state) {
        case ExpNoteStateFE.DA_LIQUIDARE:
          label = t('backToControl');
          icon = <Undo fontSize={'small'}/>;
          break;
        case ExpNoteStateFE.DA_CONTABILIZZARE:
          label = t('backToPay');
          icon = <Undo fontSize={'small'}/>;
          break;
        case ExpNoteStateFE.DA_ARCHIVIARE:
          label = isProfessional() ? t('backToContab') : t('backToPay');
          icon = <Undo fontSize={'small'}/>;
          break;
        case ExpNoteStateFE.DA_APPROVARE:
        case ExpNoteStateFE.DA_CONTROLLARE:
          label = tCommon("reject");
          icon = <Clear fontSize={'small'}/>;
          break;
        default:
          label = t("deleteExpNote");
      }

      return (
        <Button
          variant="contained"
          className={"exp-note-detail-cta-button"}
          onClick={handleSecondarySaveExpNote}
        >
          {icon !== undefined ? icon : <Check fontSize={'small'}/>}
          <Typography ml={1}>{label}</Typography>
        </Button>
      );
    }
    return null;
  }

  const getTertiaryCTA = (): JSX.Element | null => {
    if (expNote && user && isAdmin) {
      const state: ExpNoteStateFE | null = getExpNoteFEState(expNote.state);
      const label = t("resendToApprover");

      if (state === ExpNoteStateFE.DA_CONTROLLARE && expNoteHolderHasApprover) {
        return (
          <Button
            variant="contained"
            className={"exp-note-detail-cta-button"}
            onClick={handleSendAgainToApprover}
          >
            <Clear fontSize={"small"}/>
            <Typography ml={1}>{label}</Typography>
          </Button>
        );
      }
    }
    return null;
  }

  const handleCloseNewExpense = async (updated: boolean) => {
    setCreateNewExpense(false);
    if (updated && expNote) {
      const newExpNote = await getExpNote(expNote.id);
      const expenses = await getExpNoteExpenses(expNote.id);
      updateExpenses(newExpNote, expenses);
    }
    clearFormState();
  }

  const createExpenseHandler = () => {
    setCreateNewExpense(true);
  }

  return (
    <>
      <Box
        px={3}
        mt={{
          xs: 1,
          lg: 2.5
        }}
        display="flex"
        flexDirection="column"
        height={"calc(100% - 56px)"}
      >

        {errorMsg && <Alert
          severity={"error"}
          variant={"filled"}
          sx={{
            mb: 4
          }}
          onClose={() => setErrorMsg(null)}>{errorMsg}</Alert>}

        {expNote ? (
          <>
            <Grid container
                  columnSpacing={3}
                  rowSpacing={3}
                  className={"exp-note-dashboard"}
                  position={'relative'}
            >
              <Grid item xs={12} lg={3} container columnSpacing={3}>
                <Grid item xs={12} sm={6} lg={12}>
                  <Typography variant={"h6"}>{tCommon("description")}</Typography>
                  <Typography mt={1.5} variant={"h5"}>{expNote.description}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={12}>
                  <Typography mt={{xs: 3, sm: 0, lg: 3}} variant={"h6"}>{t("holder")}</Typography>
                  <Typography mt={1.5} variant={"h5"}>{expNote.traveller}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography mt={3} variant={"h6"}>{t("period")}</Typography>
                  <Typography mt={1.5}
                              variant={"h5"}>{`${formatDate(expNote.startDate, locale)} — ${formatDate(expNote.endDate, locale)}`}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>

                <Stack
                  direction="row"
                >
                  <span>
                    <Typography variant={"h6"}>{t("totalExpenses")}</Typography>
                    <Typography mt={1.5}
                                variant={"h5"}>{formatAmountZero(expNote.expTotal, locale, decimalNum, companyCurrencyCode)}</Typography>
                  </span>

                </Stack>

                <Typography mt={3} mb={1} variant={"h6"}>{tExpList('detail')}</Typography>
                <Box
                  className="exp-note-amount"
                >
                  <Typography variant={"body1"}>{t("totalPayedByTraveller")}</Typography>
                  <Typography
                    variant={"body1"}>{formatAmountZero(totalPayedByTraveller, locale, decimalNum, companyCurrencyCode)}</Typography>
                </Box>
                <Box
                  className="exp-note-amount"
                  mt={1}
                >
                  <Typography variant={"body1"}>{t("totalPayedByCompany")}</Typography>
                  <Typography
                    variant={"body1"}>{formatAmountZero(totalPayedByCompany, locale, decimalNum, companyCurrencyCode)}</Typography>
                </Box>
                {expNote.maxExcExp > 0 &&
                  <Box
                    className="exp-note-amount text-danger"
                    mt={1}
                    sx={{cursor: isAdmin ? 'pointer' : 'default'}}
                    onClick={handleOpenMaximum}
                  >
                    <Typography variant={"body1"}>{t("totalExceeding")}</Typography>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                    >
                      <Warning fontSize={"small"}/>
                      <Typography ml={1.5}
                                  variant={"body1"}>{formatAmount(expNote.maxExcExp, locale, decimalNum, companyCurrencyCode)}</Typography>
                    </Stack>
                  </Box>
                }
                {expNote.maxExcAut > 0 &&
                  <Box
                    className="exp-note-amount text-warning"
                    mt={1}
                    sx={{cursor: isAdmin ? 'pointer' : 'default'}}
                    onClick={handleOpenMaximum}
                  >
                    <Typography variant={"body1"}>{t("totalExceedingAuthorized")}</Typography>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                    >
                      <Typography ml={1.5}
                                  variant={"body1"}>{formatAmount(expNote.maxExcAut, locale, decimalNum, companyCurrencyCode)}</Typography>
                    </Stack>
                  </Box>
                }
                {expNote.anticSettle > 0 && <Box
                  className="exp-note-amount"
                  mt={1}
                >
                  <Typography variant={"body1"}>{t("totalPayments")}</Typography>
                  <Typography
                    variant={"body1"}>{formatAmount(expNote.anticSettle, locale, decimalNum, companyCurrencyCode)}</Typography>
                </Box>}
              </Grid>
              <Grid item xs={12} sm={3}>

                <Stack gap={'25px'}>
                  <Stack>
                    <Typography variant={"h6"}>{balanceLabel}</Typography>
                    <Typography mt={1.5}
                                variant={"h5"}
                    >{formatAmountZero(Math.abs(expNote.balance), locale, decimalNum, companyCurrencyCode)}</Typography>
                  </Stack>

                  {!isReadonly && (
                    <Button
                      className="exp-note-detail-add-item-button"
                      onClick={createExpenseHandler}
                    >
                      <Add fontSize={"small"}/>
                      <Typography ml={1}>{tExpList("addItem")}</Typography>
                    </Button>
                  )}
                </Stack>

              </Grid>
              <Grid item xs={12} sm={3}>
                <Box display={'flex'} justifyContent={'center'}>

                  <Stack
                    direction={"column"}
                    gap={1}
                    flexWrap="wrap"
                    justifyContent="flex-end"
                    maxWidth={'200px'}
                    minWidth={'fit-content'}
                    sx={{position: 'absolute', top: '34px'}}
                  >
                    {getPrimaryCTA()}
                    {getSecondaryCTA()}
                    {getTertiaryCTA()}
                  </Stack>
                </Box>

              </Grid>
            </Grid>

            <Box
              mt={6}
              flexGrow={1}
              className="exp-note-chart-wrapper"
            >
              <ExpNoteChart/>
            </Box>

          </>
        ) : (<></>)}
      </Box>

      <ExpNoteMaximum
        show={openMaximum}
        onClose={handleMaximumClose}
      />

      <ExpNoteExpenseDetail
        show={createNewExpense}
        onClose={handleCloseNewExpense}
        create={createNewExpense}
        expenses={null}
        isReadonly={false}
      />

    </>
  );
}
