import {Stack, Typography} from "@mui/material";
import {CheckCircleOutlined, WarningAmber} from "@mui/icons-material";
import React from "react";
import {CreCardMovState} from "../../../../../credit-card-mov/model";
import {useExpNoteDetail} from "../../../hooks/useExpNoteDetail";
import {useTranslation} from "react-i18next";

export const ExpNoteCreditCardHeader = () => {

  const {t} = useTranslation("exp-note", {keyPrefix: "creCardMovs.list"});

  const {
    creditCardMovs
  } = useExpNoteDetail();

  const notLinkedMovs = creditCardMovs?.filter(mov => mov.creCardMovState === CreCardMovState.DISCONNECTED);
  const discardedMovs = creditCardMovs?.filter(mov => mov.creCardMovState === CreCardMovState.EXCLUDED);

  return (
    <>
      {creditCardMovs && creditCardMovs.length > 0 &&
        <>
          <Typography variant={"h6"} mb={3}>{t("recap.title")}</Typography>

          {notLinkedMovs && notLinkedMovs.length > 0 ? (
            <Stack
              direction="row"
              columnGap={2}
            >
              <WarningAmber className="text-warning"/>
              <Typography
                variant={"body1"}
                fontWeight={500}
              >
                {t("recap.notAllChecked")}
              </Typography>
            </Stack>
          ) : (
            <Stack
              direction="row"
              columnGap={2}
            >
              <CheckCircleOutlined className="text-success"/>
              <Typography
                variant={"body1"}
                fontWeight={500}
              >
                {t("recap.allChecked")}
              </Typography>
            </Stack>
          )}

          {notLinkedMovs && notLinkedMovs.length > 0 &&
            <Typography
              mt={1}
              ml={5}
              variant={"body1"}
            >
              {t("recap.notLinked", {count: notLinkedMovs.length})}
            </Typography>}

          {discardedMovs && discardedMovs.length > 0 &&
            <Typography
              mt={1}
              ml={5}
              variant={"body1"}
            >
              {t("recap.discarded", {count: discardedMovs.length})}
            </Typography>}

        </>
      }
    </>
  );
}
