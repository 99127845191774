import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const UserAddPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <div>
          <p><strong>Hai raggiunto il massimo numero di utenti attivabili (5) per l'edizione Personal.</strong></p>
          <p>Puoi aggiungere altri utenti passando all'edizione Professional.</p>
          <p>L'edizione professional include anche numerose funzionalit&agrave; in pi&ugrave;:</p>
          <ul>
            <li>Un livello di approvazione,</li>
            <li>Possibilit&agrave; di inserire nuove voci di spesa personalizzate,</li>
            <li>Gestione contabile della nota spese,</li>
            <li>Report personalizzati,</li>
            <li>Gestione fornitori</li>
          </ul>
        </div>
      );
    default:
      return (
        <div>
          <p><strong>Hai raggiunto il massimo numero di utenti attivabili (5) per l'edizione Personal.</strong></p>
          <p>Puoi aggiungere altri utenti passando all'edizione Professional.</p>
          <p>L'edizione professional include anche numerose funzionalit&agrave; in pi&ugrave;:</p>
          <ul>
            <li>Un livello di approvazione,</li>
            <li>Possibilit&agrave; di inserire nuove voci di spesa personalizzate,</li>
            <li>Gestione contabile della nota spese,</li>
            <li>Report personalizzati,</li>
            <li>Gestione fornitori</li>
          </ul>
        </div>
      );
  }
}
