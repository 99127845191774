import {Account} from "../model";
import {Controller, useFormContext} from "react-hook-form";
import {Grid, Typography} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import React from "react";
import {useTranslation} from "react-i18next";

type GeneralDataProps = {
  account: Account,
  edit?: boolean,
  firstEnabled?: boolean
  secondEnabled?: boolean,
  thirdEnabled?: boolean
}

type ComponentProps = {
  props: GeneralDataProps
}

export const GeneralData = ({props}: ComponentProps) => {
  let control;
  const formMethods = useFormContext();
  const {t} = useTranslation('account', {keyPrefix: 'generalData'});

  control = props.edit ? formMethods.control : null;

  return (
    <Grid container maxWidth={{xs: "100%", lg: "50%"}} spacing={2}>

      <Grid item xs={12}>
        <Typography variant="h6" textTransform={"uppercase"}>{t('title')}</Typography>
      </Grid>

      <Grid item xs={12}>
        {
          props.account.id === -1 ?
            (
              <Controller
                name="code"
                control={control}
                render={({field, fieldState}) => {
                  return <TextFieldZts
                    {...field}
                    required
                    label={t('label.code')}
                    errorMsg={fieldState.error?.message}
                  />
                }}
              />
            ) :
            (
              <TextFieldZts
                disabled={true}
                label={t('label.code')}
                value={props.account.code}
              />
            )
        }
      </Grid>

      <Grid item xs={12}>
        {
          props.secondEnabled ?
            (
              <Controller
                name="description"
                control={control}
                render={({field, fieldState}) => {
                  return <TextFieldZts
                    {...field}
                    required
                    label={t('label.description')}
                    errorMsg={fieldState.error?.message}
                  />
                }}
              />
            ) :
            (
              <TextFieldZts
                disabled={true}
                label={t('label.description')}
                value={props.account.description}
              />
            )
        }
      </Grid>
    </Grid>
  );
}
