import {Grid} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {TextFieldZts} from "../base/text-field";
import {AutocompleteZts} from "../base/autocomplete";
import {DatePickerZts} from "../base/date-picker";
import React, {useEffect, useState} from "react";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../base/autocomplete/model";
import {FormValues} from "./model";
import {SelectZts} from "../base/select";

export const Step1 = () => {

  const {control} = useFormContext<FormValues>();

  const [jobItems, setJobItems] = useState<AutocompleteGenericOption[] | null>(null);

  useEffect(() => {
    const job = createAutocompleteGenericOption(2, '20', 'Software Engineer');
    setJobItems([
      createAutocompleteGenericOption(1, '10', 'Software Architect'),
      job
    ]);
    //setValue('job', job);
  }, []);

  return (
    <>
      <Grid item xs={6}>
        <Controller
          name="firstName"
          control={control}
          render={({field, fieldState}) => {
            return <TextFieldZts
              {...field}
              label={"Nome"}
              errorMsg={fieldState.error?.message}
            />
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="lastName"
          control={control}
          render={({field, fieldState}) => {
            return <TextFieldZts
              {...field}
              label={"Cognome"}
              errorMsg={fieldState.error?.message}
            />
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="email"
          control={control}
          render={({field, fieldState}) => {
            return <TextFieldZts
              {...field}
              label={"Email"}
              required
              errorMsg={fieldState.error?.message}
            />
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="address"
          control={control}
          render={({field, fieldState}) => {
            return <TextFieldZts
              {...field}
              label={"Indirizzo"}
              errorMsg={fieldState.error?.message}
            />
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="city"
          control={control}
          render={({field, fieldState}) => {
            return <TextFieldZts
              {...field}
              label={"Località"}
              errorMsg={fieldState.error?.message}
            />
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {jobItems &&
          <Controller
            name="job"
            control={control}
            render={({field, fieldState}) => {
              return <AutocompleteZts
                id={field.name}
                label="Job"
                options={jobItems}
                selectedValue={field.value}
                setValue={(name, value) => {
                  //console.log('setValue', name, value);
                  field.onChange(value);
                  //setValue(field.name, value, {shouldTouch: true})
                }}
                codeAndDesc={false}
                errorMsg={fieldState.error?.message}
              />
            }}
          />}
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="assumptionDate"
          control={control}
          render={({field, fieldState}) => {
            return <DatePickerZts
              label="Data assunzione"
              field={field}
              errorMsg={fieldState.error?.message}
              onChangeHandler={(value: Date) => {
                //formikProps.setFieldValue(field.name, value);
                field.onChange(value);
              }}
            />
          }}
        />
      </Grid>


      <Grid item xs={12}>
        <Controller
          name="gender"
          control={control}
          render={({field, fieldState}) => {
            return <SelectZts
              label="Sesso"
              id="gender"
              width={"100%"}
              selectedValue={field.value ? field.value : ''}
              errorMsg={fieldState.error?.message}
              setValue={(value: string) => {
                //formikProps.setFieldValue(field.name, value);
                field.onChange(value);
              }}
              items={[
                {
                  label: "Maschio",
                  value: "M"
                },
                {
                  label: "Femmina",
                  value: "F"
                }
              ]}
            />
          }}
        />
      </Grid>

    </>
  );
}
