import {useDispatch, useSelector} from "react-redux";
import {
  getLoggedUser,
  getZtsAdminUser,
  impersonateUserAction,
  setLoggedUser,
  updateLoggedProfile
} from "../reducers/Logged";
import {UserWithTenant} from "../components/users/model";
import {CompanyCurrency, CompanyDecimalNum, CompanyLocale} from "../components/model";
import {Locale} from "../util/LocalizationUtil";
import {useExpNoteList} from "../components/exp_note/hooks/useExpNoteList";
import {UpdateProfileRequest} from "../components/profile/model";

export const useLoggedUser = () => {

  const user: UserWithTenant | null = useSelector(getLoggedUser);
  const adminUser: string | null = useSelector(getZtsAdminUser);

  const {
    clearAll
  } = useExpNoteList();

  const userLocale: Locale = user && user.locale ? user.locale : CompanyLocale;
  const companyCurrencyCode: string = user && user.currency ? user.currency.code : CompanyCurrency;
  const companyCurrencyDesc: string = user && user.currency ? user.currency.description : CompanyCurrency;
  const companyDecimalNum: number = user && user.currency ? user.currency.decimalNum : CompanyDecimalNum;

  const dispatch = useDispatch();

  const setUser = (user: UserWithTenant | null) => {
    clearAll();
    dispatch(setLoggedUser(user))
  }

  const setUserByImpersonate = (user: UserWithTenant | null, adminUser: string): void => {
    clearAll();
    dispatch(impersonateUserAction(user,adminUser));
  }

  const updateUserProfile = (updateProfile: UpdateProfileRequest | null) => {
    dispatch(updateLoggedProfile(updateProfile))
  }

  return {
    user,
    userLocale,
    companyCurrencyCode,
    companyCurrencyDesc,
    companyDecimalNum,
    setUser,
    updateUserProfile,
    setUserByImpersonate,
    adminUser
  };

}
