import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";

export const useMobileBreakpoint = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileLandscape = useMediaQuery('(orientation: landscape) and (max-height: 600px)');

  const isMobileBreakpoint = isMobile || isMobileLandscape;

  return {
    isMobileBreakpoint
  }
}
