export const SET_LICENSE_TYPE = 'LICENSE/SET_TYPE';

export const PERSONAL_LICENSE = 'per';
export const PROFESSIONAL_LICENSE = 'pro';

// reducer
export default function reducer(
  state = {
    licenseType: PERSONAL_LICENSE
  },
  action
) {
  switch (action.type) {
    case SET_LICENSE_TYPE:
      return {
        ...state,
        licenseType: action.licenseType
      };
    default:
      break;
  }
  return state;
}

// Action creator
export const setPersonalLicense = () => ({
  type: SET_LICENSE_TYPE,
  licenseType: PERSONAL_LICENSE
});

export const setProfessionalLicense = () => ({
  type: SET_LICENSE_TYPE,
  licenseType: PROFESSIONAL_LICENSE
});

export const isProfessionalLicense = (state) => {
  if (state.License) {
    return state.License.licenseType === PROFESSIONAL_LICENSE;
  }
  return false;
}
