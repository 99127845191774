import { ExpNoteSortColumn, ExpNoteStateFE } from '../components/exp_note/model';


enum ExpNoteListActionType {
  SET_CURRENT_ROLE = 'EXP_NOTE_LIST/SET_CURRENT_ROLE',
  SET_LIST_TYPE = 'EXP_NOTE_LIST/SET_LIST_TYPE',
  SET_FILTER = 'EXP_NOTE_LIST/SET_FILTER',
  SET_SORTING = 'EXP_NOTE_LIST/SET_SORTING',
  SET_PAGINATION = 'EXP_NOTE_LIST/SET_PAGINATION',
  CLEAR = 'EXP_NOTE_LIST/CLEAR',
}

export enum ExpNoteListRole {
  ADMIN = 'ADMIN',
  APPROVER = 'APPROVER',
  TRAVELLER = 'TRAVELLER'
}

export interface ExpNoteListState {
  currentRole: ExpNoteListRole | null,
  admin: ExpNoteListAdminState,
  approver: ExpNoteListApproverState,
  traveller: ExpNoteListTravellerState
}

export interface ExpNoteListAdminFilter {
  searchText?: string[],
  startDate?: Date[],
  contabDate?: Date[],
}

export interface ExpNoteListTravellerFilter {
  searchText?: string[],
  startDate?: Date[],
}

export interface ExpNoteListApproverFilter {
  searchText?: string[],
  startDate?: Date[],
}

export interface ExpNoteListSorting {
  orderBy: ExpNoteSortColumn,
  orderDir: 'asc' | 'desc'
}

export interface ExpNoteListPagination {
  currentPage: number
}

export interface ExpNoteListAdminState {
  stateSelected: ExpNoteStateFE,
  filter?: ExpNoteListAdminFilter,
  sorting: ExpNoteListSorting,
  pagination: ExpNoteListPagination
}

export interface ExpNoteListTravellerState {
  stateSelected: ExpNoteStateFE,
  filter?: ExpNoteListTravellerFilter,
  sorting: ExpNoteListSorting,
  pagination: ExpNoteListPagination
}

export interface ExpNoteListApproverState {
  stateSelected: ExpNoteStateFE,
  filter?: ExpNoteListApproverFilter,
  sorting: ExpNoteListSorting,
  pagination: ExpNoteListPagination
}

export interface ExpNoteListAction {
  role: ExpNoteListRole,
  type: ExpNoteListActionType,
  stateSelected?: ExpNoteStateFE,
  filter?: ExpNoteListAdminFilter,
  sorting?: ExpNoteListSorting,
  pagination?: ExpNoteListPagination
}

const AdminInitState: ExpNoteListAdminState = {
  stateSelected: ExpNoteStateFE.DA_CONTROLLARE,
  sorting: {
    orderBy: ExpNoteSortColumn.CODE,
    orderDir: 'asc'
  },
  pagination: {
    currentPage: 1
  }
}

const TravellerInitState: ExpNoteListTravellerState = {
  stateSelected: ExpNoteStateFE.DA_COMPLETARE,
  sorting: {
    orderBy: ExpNoteSortColumn.CODE,
    orderDir: 'asc'
  },
  pagination: {
    currentPage: 1
  }
}

const ApproverInitState: ExpNoteListApproverState = {
  stateSelected: ExpNoteStateFE.DA_APPROVARE,
  sorting: {
    orderBy: ExpNoteSortColumn.CODE,
    orderDir: 'asc'
  },
  pagination: {
    currentPage: 1
  }
}

const reducer = (
  state: ExpNoteListState = {
    currentRole: null,
    admin: AdminInitState,
    traveller: TravellerInitState,
    approver: ApproverInitState
  },
  action: ExpNoteListAction) => {
  switch (action.type) {
    case ExpNoteListActionType.SET_CURRENT_ROLE:
      return {...state, currentRole: action.role};
    case ExpNoteListActionType.SET_LIST_TYPE:
      const newState = {...state};
      switch (action.role) {
        case ExpNoteListRole.ADMIN:
          if (action.stateSelected) {
            newState.admin = {
              ...state.admin,
              filter: {},
              stateSelected: action.stateSelected,
              // se cambia il tipo lista resetto ordinamento e paginazione
              sorting: {
                orderBy: ExpNoteSortColumn.CODE,
                orderDir: 'asc'
              },
              pagination: {
                currentPage: 1
              }
            };
          }
          break;
        case ExpNoteListRole.TRAVELLER:
          if (action.stateSelected) {
            newState.traveller = {
              ...state.traveller,
              filter: {},
              stateSelected: action.stateSelected,
              // se cambia il tipo lista resetto ordinamento e paginazione
              sorting: {
                orderBy: ExpNoteSortColumn.CODE,
                orderDir: action.stateSelected === ExpNoteStateFE.ARCHIVIATE ? 'desc' : 'asc'
              },
              pagination: {
                currentPage: 1
              }
            };
          }
          break;
        case ExpNoteListRole.APPROVER:
          if (action.stateSelected) {
            newState.approver = {
              ...state.approver,
              filter: {},
              stateSelected: action.stateSelected,
              // se cambia il tipo lista resetto ordinamento e paginazione
              sorting: {
                orderBy: ExpNoteSortColumn.CODE,
                orderDir: 'asc'
              },
              pagination: {
                currentPage: 1
              }
            };
          }
          break;
      }
      return newState;
    case ExpNoteListActionType.SET_FILTER:
      switch (action.role) {
        case ExpNoteListRole.ADMIN:
          return {...state, admin: {...state.admin, filter: action.filter}};
        case ExpNoteListRole.TRAVELLER:
          return {...state, traveller: {...state.traveller, filter: action.filter}};
        case ExpNoteListRole.APPROVER:
          return {...state, approver: {...state.approver, filter: action.filter}};
      }
      break;
    case ExpNoteListActionType.SET_SORTING:
      switch (action.role) {
        case ExpNoteListRole.ADMIN:
          return {...state, admin: {...state.admin, sorting: action.sorting}};
        case ExpNoteListRole.TRAVELLER:
          return {...state, traveller: {...state.traveller, sorting: action.sorting}};
        case ExpNoteListRole.APPROVER:
          return {...state, approver: {...state.approver, sorting: action.sorting}};
      }
      break;
    case ExpNoteListActionType.SET_PAGINATION:
      switch (action.role) {
        case ExpNoteListRole.ADMIN:
          return {...state, admin: {...state.admin, pagination: action.pagination}};
        case ExpNoteListRole.TRAVELLER:
          return {...state, traveller: {...state.traveller, pagination: action.pagination}};
        case ExpNoteListRole.APPROVER:
          return {...state, approver: {...state.approver, pagination: action.pagination}};
      }
      break;
    case ExpNoteListActionType.CLEAR:
      return {
        ...state,
        admin: AdminInitState,
        traveller: TravellerInitState,
        approver: ApproverInitState
      }
      break;
  }
  return state;
}

// action creator
export const setCurrentRole = (role: ExpNoteListRole): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_CURRENT_ROLE,
  role
});

export const setAdminListType = (stateSelected: ExpNoteStateFE): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_LIST_TYPE,
  role: ExpNoteListRole.ADMIN,
  stateSelected
});

export const setAdminFilter = (filter: ExpNoteListAdminFilter): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_FILTER,
  role: ExpNoteListRole.ADMIN,
  filter
});

export const setAdminSorting = (sorting: ExpNoteListSorting): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_SORTING,
  role: ExpNoteListRole.ADMIN,
  sorting
});

export const setAdminPagination = (pagination: ExpNoteListPagination): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_PAGINATION,
  role: ExpNoteListRole.ADMIN,
  pagination
});

export const clear = (): ExpNoteListAction => ({
  type: ExpNoteListActionType.CLEAR,
  role: ExpNoteListRole.ADMIN
});

export const setTravellerListType = (stateSelected: ExpNoteStateFE): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_LIST_TYPE,
  role: ExpNoteListRole.TRAVELLER,
  stateSelected
});

export const setTravellerFilter = (filter: ExpNoteListTravellerFilter): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_FILTER,
  role: ExpNoteListRole.TRAVELLER,
  filter
});

export const setTravellerSorting = (sorting: ExpNoteListSorting): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_SORTING,
  role: ExpNoteListRole.TRAVELLER,
  sorting
});

export const setTravellerPagination = (pagination: ExpNoteListPagination): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_PAGINATION,
  role: ExpNoteListRole.TRAVELLER,
  pagination
});

export const setApproverListType = (stateSelected: ExpNoteStateFE): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_LIST_TYPE,
  role: ExpNoteListRole.APPROVER,
  stateSelected
});

export const setApproverFilter = (filter: ExpNoteListApproverFilter): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_FILTER,
  role: ExpNoteListRole.APPROVER,
  filter
});

export const setApproverSorting = (sorting: ExpNoteListSorting): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_SORTING,
  role: ExpNoteListRole.APPROVER,
  sorting
});

export const setApproverPagination = (pagination: ExpNoteListPagination): ExpNoteListAction => ({
  type: ExpNoteListActionType.SET_PAGINATION,
  role: ExpNoteListRole.APPROVER,
  pagination
});

// utility
export const getAdminList = (state: { ExpNoteList: ExpNoteListState }): ExpNoteListAdminState => {
  return state.ExpNoteList.admin;
}

export const getTravellerList = (state: { ExpNoteList: ExpNoteListState }): ExpNoteListTravellerState => {
  return state.ExpNoteList.traveller;
}

export const getApproverList = (state: { ExpNoteList: ExpNoteListState }): ExpNoteListApproverState => {
  return state.ExpNoteList.approver;
}

export const getCurrentRole = (state: { ExpNoteList: ExpNoteListState }): ExpNoteListRole | null => {
  return state.ExpNoteList.currentRole;
}

export default reducer;
