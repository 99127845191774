import React from "react";
import {TransitionProps} from "@mui/material/transitions";
import {Slide} from "@mui/material";

export const TransitionLeftZts = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>,
) => {
  return <Slide ref={ref} {...props} direction={"left"} />;
});

export const TransitionUpZts = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>,
) => {
  return <Slide ref={ref} {...props} direction={"up"} />;
});
