import { getApiUrl } from '../../config/environment';
import { GenericList, getStateCode } from '../model';
import { Supplier, SaveSupplier } from './model';
import { createError } from '../../util/ErrorUtil';
import axios from 'axios';

export const getActiveSuppliers = async (): Promise<Supplier[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/suppliers?state=${getStateCode(true)}`);
  //console.log(resp);
  return resp.data.elements;
}

export const getSuppliers = async (): Promise<Supplier[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<Supplier>>(`${apiUrl}/suppliers`);
  //console.log(resp);
  return resp.data.elements;
}


export const saveSupplier = async (supplier: SaveSupplier) => {
  if (supplier.id) {
    return await updateSupplier(supplier);
  } else {
    return await insertSupplier(supplier);
  }
}


const insertSupplier = async (supplier: SaveSupplier) => {
  const apiUrl = getApiUrl();
  try {
    const resp = await axios.post(`${apiUrl}/suppliers`, supplier);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

const updateSupplier = async (supplier: SaveSupplier) => {
  const apiUrl = getApiUrl();
  try {
    const resp = await axios.put(`${apiUrl}/suppliers/${supplier.id}`, supplier);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getSupplierById = async (id: string): Promise<Supplier> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/suppliers/${id}`);
  return resp.data;
}

export const disableSupplier = (supplierId: number) => {
  const apiUrl = getApiUrl();
  return axios.put(`${apiUrl}/suppliers/disable`, {supplierId: supplierId});
}

export const restoreSupplier = (supplierId: number) => {
  const apiUrl = getApiUrl();
  return axios.put(`${apiUrl}/suppliers/restore`, {supplierId: supplierId});
}
