import {Skeleton, Stack, Typography} from "@mui/material";
import {Level} from "../model";
import Box from "@mui/material/Box";
import {InfiniteScrollZts} from "../../base/infinite-scroll";
import {LevelCardWrapper} from "./LevelCardWrapper";
import {LevelCard} from "./LevelCard";
import {useNavigate} from "react-router-dom";
import {Add} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  levels: Level[],
  loading: boolean,
  onOpen: Function,
  onRestore: Function,
  onDelete?: Function,
  active: boolean
}

export const LevelList = ({levels, loading, onOpen, onRestore, active}: ComponentProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation('level', {keyPrefix: 'list.emptyState'});

  return (
    <>

      {/*Skeleton*/}
      {loading && <Box
        display={"flex"}
        flexWrap={"wrap"}
        mt={3}
        maxWidth={"1200px"}
      >
        {[1, 2, 3].map(idx => {
          return <LevelCardWrapper
            key={idx}
            child={
              <Skeleton
                animation="pulse"
                variant="rectangular"
                width="100%"
                height="216px"/>
            }
          />
        })}
      </Box>}

      {!loading && <InfiniteScrollZts
        scrollableTargetId={"infinite-scroll-target"}
        elements={levels}
        renderElement={(level: Level) => {
          /* jsx */
          return <LevelCardWrapper
            key={level.id}
            child={
              <LevelCard
                level={level}
                onOpen={onOpen}
                onRestore={onRestore}
              />
            }
          />
        }}
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "12px",
          maxWidth: "1900px"
        }}
      />}
      {
        levels.length === 0 &&
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "12px",
            maxWidth: "1900px"
          }}
        >
          <Box flexGrow={1}/>
          <Stack flexDirection={'column'}>
            {
              active ? (
                <>
                  <Typography variant={'h3'} color={'#c2c0c2'} mb={1}>{t('pro.title')}</Typography>
                  <Box alignSelf={'center'} width={'fit-content'} borderRadius={'20px'}
                       sx={{backgroundColor: '#14c6d5', cursor: 'pointer'}}>
                    <Stack flexDirection={'row'} pl={1.5} pr={1.5} pt={0.5} pb={0.5}
                           onClick={() => navigate('/levels/edit/-1')}>
                      <Add fontSize={'small'} sx={{color: 'white'}}/>
                      <Typography pl={0.5} pr={0.5} color={'white'}>{t('pro.button1')}</Typography>
                    </Stack>
                  </Box>
                </>
              ) : (<Typography variant={'h3'} color={'#c2c0c2'} mb={1}>{t('free.title')}</Typography>)
            }
          </Stack>
          <Box flexGrow={1}/>
        </Box>
      }

    </>
  );
}
