// libs
import axios from 'axios';
import { Logger } from 'react-logger-lib';
import SimpleJsLog from '../../util/Logger';

// custom
import { getApiUrl } from '../../config/environment';
import { Expense } from './model';
import { activeStateCode, GenericList } from '../model';
import { createError } from '../../util/ErrorUtil';
import { CheckExpenseUsed, TravelPolicy } from '../travel_policies/model';

const LOG: SimpleJsLog = Logger.of('ZTS.Expenses.Service');


export const getActiveExpenses = async (): Promise<Expense[]> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/expenses?state=${activeStateCode}`;
  try {
    LOG.trace('getActiveExpenses. Calling API ...');
    const resp = await axios.get<GenericList<Expense>>(url);
    LOG.trace('getActiveExpenses API response status', resp.status);
    LOG.trace('getActiveExpenses API response data', resp.data);
    if (resp.data.size===0) {
      LOG.trace('Empty result');
      return [];
    }
    return resp.data.elements;
  } catch (err: any) {
    LOG.trace('API error', err);
    throw createError(err);
  }
}

// vedi CreateExpenseRequest.java
type CreateExpenseRequest = Omit<Expense, 'id'|'lastUpdNum'|'state'|'code'>

type CreateExpenseResponse = {
  id: number
}

export const createExpense = async (request: CreateExpenseRequest): Promise<number> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/expenses`;
  try {
    LOG.trace('createExpense. Calling API ...');
    const resp = await axios.post<CreateExpenseResponse>(url, request);
    LOG.trace('createExpense API response status', resp.status);
    LOG.trace('createExpense API response data', resp.data);
    return resp.data.id;
  } catch (err: any) {
    LOG.trace('API error', err);
    throw createError(err);
  }
}

export const loadExpenseById = async (id: number): Promise<Expense> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/expenses/${id}`;
  try {
    LOG.trace('loadExpenseById. Calling API ...');
    const resp = await axios.get<Expense>(url);
    LOG.trace('loadExpenseById API response status', resp.status);
    LOG.trace('loadExpenseById API response data', resp.data);
    return resp.data;
  } catch (err: any) {
    LOG.trace('API error', err);
    throw createError(err);
  }
}

type UpdateExpenseRequest = Omit<Expense, 'id'>;

export const updateExpense = async (exp: Expense): Promise<Expense> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/expenses/${exp.id}`;
  const body: UpdateExpenseRequest = exp;
  try {
    LOG.trace('updateExpense. Calling API ...');
    const resp = await axios.put<Expense>(url, body);
    LOG.trace('updateExpense API response status', resp.status);
    LOG.trace('updateExpense API response data', resp.data);
    return resp.data;
  } catch (err: any) {
    LOG.trace('API error', err);
    throw createError(err);
  }
}

export const loadExpenseIconKeys = async (): Promise<string[]> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/expenses/icons`;
  try {
    LOG.trace('loadExpenseIconKeys. Calling API ...');
    const resp = await axios.get<GenericList<string>>(url);
    LOG.trace('loadExpenseIconKeys API response status', resp.status);
    LOG.trace('loadExpenseIconKeys API response data', resp.data);
    if (resp.data.size===0) {
      return [];
    }
    return resp.data.elements;
  } catch (err: any) {
    LOG.trace('API error', err);
    throw createError(err);
  }
}

export const getTravelPoliciesUsingExpense = async (expenseId: number): Promise<TravelPolicy[]> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/expenses/${expenseId}/travelpolicies`;
  try {
    LOG.trace('getTravelPoliciesUsingExpense calling API ...');
    const resp = await axios.get<GenericList<TravelPolicy>>(url);
    LOG.trace('getTravelPoliciesUsingExpense API result', resp);
    return resp.data.elements;
  } catch (err: any) {
    throw createError(err);
  }
}

export const deleteExpenseItem = async (expenseId: number): Promise<void> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/expenses/${expenseId}`;
  try {
    LOG.trace('deleteExpense calling API ...');
    const resp = await axios.delete<void>(url);
    LOG.trace('deleteExpense API result', resp);
  } catch (err: any) {
    throw createError(err);
  }
}
