import { getLicenseData } from '../dashboard/Service';
import { UserWithTenant } from '../users/model';

export const getStoreUrl = async (user: UserWithTenant | null): Promise<string> => {
  if (user) {
    const tenantId = user.tenant.id.toString();
    const licenseData = await getLicenseData(tenantId ? tenantId : '0');
    if (licenseData && licenseData.storeUrl) {
      return licenseData.storeUrl;
    }
  }
  return '';
}
