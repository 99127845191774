import * as Yup from 'yup';
import { ValidationError } from 'yup';
import { AutocompleteCurrencyOption, AutocompleteGenericOption } from '../../base/autocomplete/model';
import { CarRouteKmDecimalNum, ExchangeDecimalNum } from '../../model';
import { ExpenseItemType, StaffTravelPolicyExpItem4Validation } from './model';
import {
  yupMaxNullableString,
  yupOptionalDate,
  yupRequiredAmount,
  yupRequiredDate,
  yupRequiredEndDate
} from '../../../util/YupUtil';
import { Locale } from '../../../util/LocalizationUtil';
import { CompleteCreCardMovDto } from '../../credit-card-mov/model';
import { TravelPolicy } from '../../travel_policies/model';

export type NewExpenseAttachFormValue = {
  _id?: number;
  uploadKey?: string;
  name?: string;
  type?: string;
}

export type NewExpenseColleagueFormValue = {
  _id?: number;
  colleague: AutocompleteGenericOption | null;
}

export type NewExpenseGuestFormValue = {
  _id?: number;
  guest: AutocompleteGenericOption | null;
}

export type NewExpenseRouteFormValue = {
  _id?: number;
  location: AutocompleteGenericOption | null;
  distance: string | null;
  expectedDistance: number;
}

export type NewExpenseAdditionalExpFormValue = {
  _id?: number;
  expenseItem: AutocompleteGenericOption & NewExpenseExpItem | null;
  compDate: Date | null,
  compStartDate: Date | null,
  compEndDate: Date | null,
  amount: string,
  colleagues: NewExpenseColleagueFormValue[],
  guests: NewExpenseGuestFormValue[],
}

export type NewExpenseExpItem = {
  type: string;
  colleagues: boolean;
  guests: boolean;
  routes: boolean;
  measUnit: string;
  compPeriod: boolean;
  compPeriodStartLabel?: string;
  compPeriodEndLabel?: string;
  attachRequired: boolean;
  localityRequired: boolean;
  notesRequired: boolean;
  projectRequired: boolean;
}

export type NewExpenseTravelPolicy = {
  foreignCurr: boolean;
  projectsEnabled: boolean;
}

export interface NewExpensePayCard {
  cardNum?: string;
}

export type NewExpenseFormValues = {
  id?: number;
  travelPolicy: AutocompleteGenericOption & NewExpenseTravelPolicy | null;
  expenseItem: AutocompleteGenericOption & NewExpenseExpItem | null;
  locality: AutocompleteGenericOption | null,
  project: AutocompleteGenericOption | null,
  compDate: Date | null,
  compStartDate: Date | null,
  compEndDate: Date | null,
  currency: AutocompleteGenericOption | null,
  amount: string,
  exchange: string,
  payModeItem: AutocompleteGenericOption & NewExpensePayCard | null,
  docTypeItem: AutocompleteGenericOption | null,
  notes: string,
  attachments: NewExpenseAttachFormValue[],
  quantity: string,
  tarif: string,
  supplier: AutocompleteGenericOption | null,
  invoiceNum: string,
  docDate: Date | null,
  colleagues: NewExpenseColleagueFormValue[],
  guests: NewExpenseGuestFormValue[],
  routes: NewExpenseRouteFormValue[],
  additionalExpenses: NewExpenseAdditionalExpFormValue[],
  creCardMov?: CompleteCreCardMovDto
}

export const newExpenseInitValues = {
  travelPolicy: null,
  expenseItem: null,
  locality: null,
  project: null,
  compDate: null,
  compStartDate: null,
  compEndDate: null,
  currency: null,
  amount: '',
  exchange: '',
  payModeItem: null,
  docTypeItem: null,
  notes: '',
  attachments: [],
  quantity: '',
  tarif: '',
  supplier: null,
  invoiceNum: '',
  docDate: null,
  colleagues: [],
  guests: [],
  routes: [],
  additionalExpenses: []
};

const requiredObjectSchema = (required: string) => Yup.object()
  .nullable()
  .required(required);

const notRequiredObjectSchema = Yup.object()
  .nullable();

const requiredDateSchema = (required: string, invalidDate: string) => Yup.date()
  .typeError(invalidDate)
  .nullable()
  .required(required)
  .default(undefined);

const notRequiredDateSchema = (invalidDate: string) => Yup.date()
  .typeError(invalidDate)
  .nullable()
  .default(undefined);

const compPerValidationSchema = (t: any) => Yup.date()
  .when('expenseItem', {
    is: (value: StaffTravelPolicyExpItem4Validation) => value && value.compPeriod,
    then: yupRequiredDate(t),
    otherwise: yupOptionalDate(t)
  });

const compPerEndValidationSchema = (t: any) => Yup.date()
  .when('expenseItem', {
    is: (value: StaffTravelPolicyExpItem4Validation) => value && value.compPeriod,
    then: yupRequiredEndDate(t, 'compStartDate'),
    otherwise: yupOptionalDate(t)
  });

const colleaguesValidationSchema = (t) => Yup.array()
  .test((colleagues, context) => {
    // controllo che non esistano colleghi duplicati
    if (colleagues) {
      const errors = new Map<number, Map<number, ValidationError>>();
      colleagues.forEach((colleague1, index1) => {
        colleagues.forEach((colleague2, index2) => {
          if (index1 !== index2 &&
            colleague1 &&
            colleague1.colleague &&
            colleague2 &&
            colleague2.colleague &&
            colleague1.colleague.id === colleague2.colleague.id) {
            if (!errors.has(colleague1.colleague.id)) {
              errors.set(colleague1.colleague.id, new Map<number, ValidationError>());
            }
            //@ts-ignore
            errors.get(colleague1.colleague.id).set(index1,
              new ValidationError(t('expense.error.colleagueDuplicated'), undefined, `${context.path}.${index1}.colleague`));
          }
        })
      });

      if (errors.size > 0) {
        return buildValidationError(errors);
      }
    }

    return true;
  });

const guestsValidationSchema = (t) => Yup.array()
  .test((guests, context) => {
    // controllo che non esistano ospiti duplicati
    if (guests) {
      const errors = new Map<string, Map<number, ValidationError>>();
      guests.forEach((guest1, index1) => {
        if (guest1 && guest1.guest) {
          const name = guest1.guest.desc.toLowerCase();
          guests.forEach((guest2, index2) => {
            if (index1 !== index2 &&
              guest2 &&
              guest2.guest &&
              name === guest2.guest.desc.toLowerCase()) {
              if (!errors.has(name)) {
                errors.set(name, new Map<number, ValidationError>());
              }
              //@ts-ignore
              errors.get(name).set(index1, new ValidationError(t('expense.error.guestDuplicated'), undefined, `${context.path}.${index1}.guest`));
            }
          })
        }
      });

      if (errors.size > 0) {
        return buildValidationError(errors);
      }
    }

    return true;
  });

export const expenseValidationSchema = (t, locale: Locale, companyDecimalNum: number, companyCurrencyCode: string) => {
  const required = t('required', {ns: 'validation'});
  const invalidDate = t('invalidDate', {ns: 'validation'});
  const notesMaxLength = 1000;

  let currencyTmp: any;

  return Yup.object({
    travelPolicy: Yup.object().nullable(),
    expenseItem: Yup.object()
      .nullable()
      .required(required),
    locality: Yup.object()
      .when(['expenseItem', 'additionalExpenses'], {
        is: (value: StaffTravelPolicyExpItem4Validation, additionalExpenses: NewExpenseAdditionalExpFormValue[]) => (value && value.localityRequired) || additionalExpenses.find(e => e.expenseItem && e.expenseItem.localityRequired),
        then: requiredObjectSchema(required),
        otherwise: notRequiredObjectSchema
      }),
    project: Yup.object()
      .when(['travelPolicy', 'expenseItem', 'additionalExpenses'], {
        is: (travelPolicy: AutocompleteGenericOption & NewExpenseTravelPolicy | null, value: StaffTravelPolicyExpItem4Validation, additionalExpenses: NewExpenseAdditionalExpFormValue[]) => {
          // se la Travel Policy non gestisce le commesse viene ignorato il flag "Commessa obbligatoria" nella voce spesa
          if (!travelPolicy?.projectsEnabled) {
            return false;
          }

          return (value && value.projectRequired) ||
            additionalExpenses.find(e => e.expenseItem && e.expenseItem.projectRequired);
        },
        then: requiredObjectSchema(required),
        otherwise: notRequiredObjectSchema
      }),
    compDate: Yup.date()
      .when('expenseItem', {
        is: (value: StaffTravelPolicyExpItem4Validation) => value && !value.compPeriod,
        then: requiredDateSchema(required, invalidDate),
        otherwise: notRequiredDateSchema(invalidDate)
      }),
    compStartDate: compPerValidationSchema(t),
    compEndDate: compPerEndValidationSchema(t),
    currency: Yup.object()
      .when('expenseItem', {
        is: (value: StaffTravelPolicyExpItem4Validation) => value && value.type === ExpenseItemType.PIE_DI_LISTA,
        then: Yup.object()
          .nullable()
          .required(required)
      })
      .test('currencyTmp', 'assign value to variable', (value) => {
        currencyTmp = value;
        return true;
      }),
    amount: Yup.string()
      .when('currency', (currency: AutocompleteCurrencyOption) => {
        const decimalNum = currency ? currency.decimalNum : companyDecimalNum;
        return yupRequiredAmount(t, decimalNum, locale);
      }),
    exchange: Yup.string()
      .when('currency', {
        is: (value: AutocompleteGenericOption) => value && value.code !== companyCurrencyCode,
        then: Yup.string()
          .nullable()
          .transform((o, c) => o === '' ? null : c)
          .when('currency', () => yupRequiredAmount(t, ExchangeDecimalNum, locale)),
        otherwise: Yup.string()
      }),
    payModeItem: Yup.object()
      .when('expenseItem', {
        is: (value: StaffTravelPolicyExpItem4Validation) => value && value.type === ExpenseItemType.PIE_DI_LISTA,
        then: Yup.object()
          .nullable()
          .required(required),
        otherwise: notRequiredObjectSchema
      }),
    docTypeItem: Yup.object()
      .when('expenseItem', {
        is: (value: StaffTravelPolicyExpItem4Validation) => value && value.type === ExpenseItemType.PIE_DI_LISTA,
        then: Yup.object()
          .nullable()
          .required(required),
        otherwise: notRequiredObjectSchema
      }),
    notes: Yup.string()
      .when(['expenseItem', 'additionalExpenses'], {
        is: (value: StaffTravelPolicyExpItem4Validation, additionalExpenses: NewExpenseAdditionalExpFormValue[]) => (value && value.notesRequired) || additionalExpenses.find(e => e.expenseItem && e.expenseItem.notesRequired),
        then: Yup.string().required(required),
        otherwise: Yup.string()
      })
      .max(notesMaxLength, t('expense.error.notesLength', {length: notesMaxLength})),
    attachments: Yup.array()
      .when(['expenseItem', 'additionalExpenses'], {
        is: (value: StaffTravelPolicyExpItem4Validation, additionalExpenses: NewExpenseAdditionalExpFormValue[]) => (value && value.attachRequired) || additionalExpenses.find(e => e.expenseItem && e.expenseItem.attachRequired),
        then: Yup.array()
          .min(1, t('expense.error.attachmentRequired'))
      }),
    quantity: Yup.string()
      .when('expenseItem', {
        is: (value: StaffTravelPolicyExpItem4Validation) => value && value.type === ExpenseItemType.TARIFFA,
        then: Yup.string()
          .when('currency', () => yupRequiredAmount(t, 2, locale))
      }),
    tarif: Yup.string()
      .when('expenseItem', {
        is: (value: StaffTravelPolicyExpItem4Validation) => value && value.type === ExpenseItemType.TARIFFA,
        then: Yup.string()
          .when('currency', () => yupRequiredAmount(t, 6, locale))
      }),
    supplier: Yup.object()
      .nullable(),
    invoiceNum: yupMaxNullableString(50, t),
    docDate: yupOptionalDate(t),
    colleagues: colleaguesValidationSchema(t),
    guests: guestsValidationSchema(t),
    routes: Yup.array()
      .of(
        Yup.object().shape({
          location: Yup.object()
            .nullable()
            .when('distance', {
              is: (value) => value && value.trim() !== '',
              then: Yup.object()
                .nullable()
                .required(required)
            }),
          distance: Yup.string()
            .when('location', {
              is: (value) => !!value,
              then: yupRequiredAmount(t, CarRouteKmDecimalNum, locale, {invalidNumber: {key: 'expense.error.kmInvalid'}})
            }),
          expectedDistance: Yup.number()
        }, [['location', 'distance']])
      ),
    additionalExpenses: Yup.array()
      .of(
        Yup.object().shape({
          expenseItem: Yup.object()
            .nullable()
            .required(required),
          compDate: Yup.date()
            .when('expenseItem', {
              is: (value: StaffTravelPolicyExpItem4Validation) => value && !value.compPeriod,
              then: requiredDateSchema(required, invalidDate),
              otherwise: notRequiredDateSchema(invalidDate)
            }),
          compStartDate: compPerValidationSchema(t),
          compEndDate: compPerEndValidationSchema(t),
          amount: Yup.string()
            .when('currencyTmp', () => {
              const decimalNum = currencyTmp ? currencyTmp.decimalNum : companyDecimalNum;
              return yupRequiredAmount(t, decimalNum, locale);
            }),
          colleagues: colleaguesValidationSchema(t),
          guests: guestsValidationSchema(t),
        })
      )
  });
}

const buildValidationError = (errors: Map<string | number, Map<number, ValidationError>>): ValidationError => {
  const finalErrors: ValidationError[] = [];
  errors.forEach((data) => {
    // calcolo l'indice minore in modo da non mostrare l'errore sulla prima riga in cui l'ospite è stato inserito
    let min = -1;
    data.forEach((error, key) => {
      if (min === -1 || key < min) {
        min = key;
      }
    })

    data.forEach((error, key) => {
      if (key !== min) {
        finalErrors.push(error);
      }
    })
  });
  return new ValidationError(finalErrors);
}
