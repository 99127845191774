import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {TodoItem} from "../todo-item";
import {APPROVEEXPNOTE_URL, EXPNOTE_URL} from "../../model";
import {
  ExpNoteState, ExpNoteStateFE,
  getExpNotesPayedStates, getExpNotesToCompleteStates,
  getExpNotesToPayStates, getExpNotesToReviewStates
} from "../../exp_note/model";
import React, {useEffect, useState} from "react";
import {countToApproveExpNotes, countTravellerExpNotes, getLastTravellerExpenses} from "../Service";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {getStaffCreditCards, getStaffExpenses, getStaffTravelPolicies} from "../../users/Service";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {DashBoardTravellerLastExpense} from "../model";
import {Suitcase} from "../../base/svg-icons";
import {StaffTravelPolicy} from "../../users/model";
import {CreditCard, Euro} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {ZtsTable} from "../../base/table";
import {ZtsTableColumn} from "../../base/table/model";
import appStoreLogo from "../../../assets/images/appStoreLinkLogo.svg";
import googlePlayLogo from "../../../assets/images/googlePlayLinkLogo.png";
import {formatDate} from "../../../util/DateUtil";
import {ExpenseImg} from "../../expenses/commons/ExpenseImg";
import {formatAmountZero} from "../../../util/NumberUtil";

export const DashboardTraveller = () => {
  const {user, userLocale: locale, companyDecimalNum: decimalNum, companyCurrencyCode} = useLoggedUser();
  const {t} = useTranslation('dashboard', {keyPrefix: 'traveller'});
  const {convertError} = useErrorMessage();
  const navigate = useNavigate();

  const [counters, setCounters] = useState<number[]>([]);
  const [loadingTodo, setLoadingTodo] = useState<boolean>(false);
  const [loadingPersonalSettings, setLoadingPersonalSettings] = useState<boolean>(false);
  const [errosMsg, setErrorMsg] = useState<string | null>(null);

  const [isApprover, setIsApprover] = useState<boolean>(false);
  const [approverTodoLabel, setApproverTodoLabel] = useState<string | null>(null);
  const [lastExpenses, setLastExpenses] = useState<DashBoardTravellerLastExpense[]>([]);
  const [travellerTravelPolicies, setTravellerTravelPolicies] = useState<StaffTravelPolicy[]>([]);
  const [travellerTravelPoliciesString, setTravellerTravelPoliciesString] = useState<string | null>(null);
  const [travellerCreditCardsString, setTravellerCreditCardsString] = useState<string | null>(null);
  const [travellerTarifExpensesString, setTravellerTarifExpensesString] = useState<string | null>(null);

  const columns: ZtsTableColumn[] = [];

  columns.push(
    {
      id: 'insertTime',
      label: '',
      formatter: (value) => formatDate(value, locale)
    },
    {
      id: 'expense',
      label: '',
      formatter: (desc, row) => {
        return (
          <Stack direction="row" columnGap={1} alignItems={'center'}>
            {desc && <ExpenseImg img={row.expenseIcon} label=""/>}
            <Typography textAlign={'center'}>{desc}</Typography>
          </Stack>
        );
      }
    },
    {
      id: 'expNote',
      label: '',
      formatter: (value) => `${value}`
    },
    {
      id: 'currencyAmount',
      label: '',
      formatter: (value) => formatAmountZero(value, locale, decimalNum, companyCurrencyCode),
      align: 'right'
    }
  );

  const asynGetAndSetLastExpenses = async (): Promise<void> => {
    getLastTravellerExpenses()
      .then(response => {
        setLastExpenses(response);
      })
      .catch(error => {
        setErrorMsg(createError(convertError(error)));
      })
  }

  const asyncGetAndSetTravelPolicies = async (): Promise<void> => {
    setLoadingPersonalSettings(true);
    if (user) {
      getStaffTravelPolicies(user.id)
        .then(resp => {
          const list = resp.map(value => value.description);
          setTravellerTravelPoliciesString(list.join(', '));
          setTravellerTravelPolicies(resp);
        })
        .catch(error => {
          setErrorMsg(createError(convertError(error)));
        })
    }
    setLoadingPersonalSettings(false);
  }

  const asyncGetAndSetCreditCards = async (): Promise<void> => {
    setLoadingPersonalSettings(true);
    if (user) {
      getStaffCreditCards(user.id)
        .then(resp => {
          const list = resp.map(value => value.cardDesc);
          setTravellerCreditCardsString(list.join(', '));
          //setTravellerCreditCards(resp);
        })
        .catch(error => {
          setErrorMsg(createError(convertError(error)));
        })
    }
    setLoadingPersonalSettings(false);
  }

  const asyncGetAndSetTarifExpenses = async (): Promise<void> => {
    setLoadingPersonalSettings(true);
    if (user) {
      getStaffExpenses(user.id)
        .then(resp => {
          const list = resp.map(value => value.expenseDesc);
          setTravellerTarifExpensesString(list.join(', '));
          //setTravellerTarifExpenses(resp);
        })
        .catch(error => {
          setErrorMsg(createError(convertError(error)));
        })
    }
    setLoadingPersonalSettings(false);
  }

  useEffect(() => {
    setLoadingTodo(true);

    asynGetAndSetLastExpenses().then();
    asyncGetAndSetTravelPolicies().then();
    asyncGetAndSetCreditCards().then();
    asyncGetAndSetTarifExpenses().then();

    let toApproveCount;
    countToApproveExpNotes().then(resp => {
      if (resp > 0) {
        setIsApprover(true);
        toApproveCount = resp;
        resp === 1 ? setApproverTodoLabel(t("todo.toApproveSingle", {expNotesNumber: resp})) : setApproverTodoLabel(t("todo.toApproveMultiple", {expNotesNumber: resp}));
      }
    });

    const activeStates = getExpNotesToCompleteStates();
    const rejectedStates = getExpNotesToReviewStates();
    const toPayStates = getExpNotesToPayStates();
    const payedStates = getExpNotesPayedStates();

    let states: ExpNoteState[] = [];
    states = states.concat(activeStates);
    states = states.concat(rejectedStates);
    states = states.concat(toPayStates);
    states = states.concat(payedStates);

    countTravellerExpNotes(states).
      then((response) => {
        let activeNotesCount = 0;
        let rejectedNotesCount = 0;
        let toPayNotesCount = 0;
        let payedNotesCount = 0;
        response.forEach((expNoteCount) => {
          if (activeStates.find(state => state === expNoteCount.state)) {
            activeNotesCount += expNoteCount.count;
          } else if (rejectedStates.find(state => state === expNoteCount.state)) {
            rejectedNotesCount += expNoteCount.count;
          } else if (toPayStates.find(state => state === expNoteCount.state)) {
            toPayNotesCount += expNoteCount.count;
          } else if (payedStates.find(state => state === expNoteCount.state)) {
            payedNotesCount += expNoteCount.count;
          }
        });

        if (isApprover) {
          setCounters([activeNotesCount, rejectedNotesCount, toPayNotesCount, payedNotesCount, toApproveCount]);
        } else {
          setCounters([activeNotesCount, rejectedNotesCount, toPayNotesCount, payedNotesCount]);
        }
    })
      .catch(err => console.log(err))
      .finally(() => setLoadingTodo(false));
  }, []);

  const navigateToApproveExpNotes = () => {
    navigate(APPROVEEXPNOTE_URL);
  }

  return (
    <Box overflow="auto" height="100vh">
      <Typography variant="h2">{t("title")}</Typography>

      <Grid
        container
        columnSpacing={3.5}
        rowSpacing={2}
        mt={1.5}
        className="dashboard"
      >
        {/*colonna sx*/}
        <Grid item xs={12} md={6}>
          <Grid container rowGap={2}>

            <Box sx={{backgroundColor: '#fff4d5', borderRadius: '10px'}} p={2}>
              <Stack rowGap={1.5}>
                <Typography variant={'h4'} textAlign={'center'}>Il modo più facile per fare le note spese è tramite l'App Mobile!</Typography>
                <Typography variant={'h4'} textAlign={'center'} fontWeight={'400'}>Scaricala adesso dallo store del tuo dispositivo.</Typography>
              </Stack>
              <Box display={'flex'} flexDirection={'row'} mt={2} columnGap={2} alignItems={'center'}>
                <Box flexGrow={1}/>
                <Typography variant={'caption'}>DISPONIBILE SU</Typography>
                <Box columnGap={1}>
                  <img src={appStoreLogo} width={80}/>
                  &nbsp;
                  <img src={googlePlayLogo} width={80}/>
                </Box>
              </Box>
            </Box>
            {
              isApprover &&
              <Box width={'100%'} sx={{backgroundColor: '#fff4d5', borderRadius: '10px', cursor: 'pointer'}} p={1.5} onClick={navigateToApproveExpNotes}>
                <Typography variant={'h3'} ml={2}>{approverTodoLabel}</Typography>
              </Box>
            }
            <Grid container spacing={2}>
              <Grid item xs={6} md={6} lg={3}>
                <TodoItem
                  label={t("todo.current")}
                  count={counters[0]}
                  className="to-check"
                  navigation={{
                    url: EXPNOTE_URL,
                    data: {
                      state: ExpNoteStateFE.DA_COMPLETARE
                    }
                  }}
                  loading={loadingTodo}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <TodoItem
                  label={t("todo.rejected")}
                  count={counters[1]}
                  className="to-pay"
                  navigation={{
                    url: EXPNOTE_URL,
                    data: {
                      state: ExpNoteStateFE.DA_RIVEDERE
                    }
                  }}
                  loading={loadingTodo}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <TodoItem
                  label={t("todo.toPay")}
                  count={counters[2]}
                  className="to-account"
                  navigation={{
                    url: EXPNOTE_URL,
                    data: {
                      state: ExpNoteStateFE.ARCHIVIATE
                    }
                  }}
                  loading={loadingTodo}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <TodoItem
                  label={t("todo.payed")}
                  count={counters[3]}
                  className="to-archive"
                  loading={loadingTodo}
                  navigation={{
                    url: EXPNOTE_URL,
                    data: {
                      state: ExpNoteStateFE.ARCHIVIATE
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Box display={'flex'} flexDirection={'column'} width={'100%'} p={2} sx={{backgroundColor: 'white', borderRadius: '15px'}}>
              <Typography variant={'h4'}>{t('settings.title')}</Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Suitcase style={{backgroundColor: '#d4f6e4', borderRadius: '16px', padding: '10px', width: '42px', height: '42px'}} fontSize={"small"} />
                  </ListItemIcon>
                  <ListItemText primary={<Typography textOverflow={'ellipsis'} textTransform={'uppercase'} variant={'h5'}>{t('settings.travelPolicy.title')}</Typography>} secondary={travellerTravelPoliciesString && travellerTravelPoliciesString.length > 0 ? travellerTravelPoliciesString : t('settings.travelPolicy.emptyState')}></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CreditCard sx={{backgroundColor: '#d9d4f7', borderRadius: '16px', padding: '10px', width: '42px', height: '42px'}} fontSize={"small"} />
                  </ListItemIcon>
                  <ListItemText primary={<Typography textOverflow={'ellipsis'} textTransform={'uppercase'} variant={'h5'}>{t('settings.creditCard.title')}</Typography>} secondary={travellerCreditCardsString && travellerCreditCardsString.length > 0 ? travellerCreditCardsString : t('settings.creditCard.emptyState')}></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Euro sx={{backgroundColor: '#e7f0f8', borderRadius: '16px', padding: '10px', width: '42px', height: '42px'}} fontSize={"small"} />
                  </ListItemIcon>
                  <ListItemText primary={<Typography textOverflow={'ellipsis'} textTransform={'uppercase'} variant={'h5'}>{t('settings.tarif.title')}</Typography>} secondary={travellerTarifExpensesString && travellerTarifExpensesString.length > 0 ? travellerTarifExpensesString : t('settings.tarif.emptyState')}></ListItemText>
                </ListItem>
              </List>
            </Box>

            <Box p={2} sx={{backgroundColor: 'white', borderRadius: '15px'}} width={'100%'}>
              <Typography variant={'h4'}>{t('lastExpenses.title')}</Typography>
              <Stack
                justifyContent="space-between"
                flexGrow={1}
              >
                <ZtsTable
                  showHeader={false}
                  columns={columns}
                  rows={lastExpenses}
                  forcedSort={{orderBy: 'insertTime', orderDir: 'desc'}}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
