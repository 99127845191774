import {Alert, Box, Paper, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ProjectDetailLoader} from "./ProjectDetailLoader";
import {PageTitle} from "../../layout/page-title";
import {useNavigate} from "react-router-dom";
import {getProjectCodeAndDescription, Project, StaffByProjectIdResponse} from "../model";
import IconButton from "@mui/material/IconButton";
import {DeleteForever, Edit} from "@mui/icons-material";
import {GeneralData} from "./GeneralData";
import {useParams} from "react-router";
import {disableProjects, getProjectById, getStaffsByProjectId} from "../Service";
import {TooltipZts} from "../../base/tooltip";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {useTranslation} from "react-i18next";

export const ProjectDetail = () => {
  const {t} = useTranslation('project', {keyPrefix: 'detail.button'});
  //------
  // State
  //------

  const [loadingData, setLoadingData] = useState<boolean>(true); // flag utile al rendering della loading page
  const [errorMsg, setErrorMsg] = useState<string | null>(null); // stringa contenente eventuali messaggi di errore
  const [project, setProject] = useState<Project | null>(null); // project da renderizzare
  const [staffsList, setStaffsList] = useState<StaffByProjectIdResponse[] | []>([]); // userList da renderizzare

  let {id} = useParams();
  const navigate = useNavigate();
  const {convertError} = useErrorMessage();

  //------------
  // Use effects
  //------------

  useEffect(() => {
    if (id) {
      setLoadingData(true);

      getProjectById(id)
        .then(response => {
          const data = response.data;
          setProject(data);
        })
        .catch(error => setErrorMsg(`Error retrieving project data. ${error}`));


      //Lista utenti a cui è assegnata la commessa
      getStaffsByProjectId(id)
        .then(response => {
          const elements = response;
          setStaffsList(elements);
        })
        .catch(error => setErrorMsg(`Error retrieving users data. ${error}`))
        .finally(() => setLoadingData(false));
    }
  }, [id]);

  //----------
  // Functions
  //----------

  // Handler: gestione pulsante 'Go back'
  const backToList = () => {
    navigate('/projects');
  }

  // Handler: gestione pulsante 'Edit'
  const handleEditClicked = () => {
    if (project) {
      navigate(`/projects/edit/${project.id}`);
    }
  }

  // Handler: gestione pulsante 'Elimina'
  const handleDisableClicked = () => {
    setLoadingData(true);
    if (project) {
      disableProjects(project.id)
        .then(() => {
          setLoadingData(false);
          navigate('/projects');
        })
        .catch(async error => {
          setLoadingData(false);
          const msg = await convertError(createError(error));
          setErrorMsg(msg);
        })
    }
  }

  //----------------
  // React Component
  //----------------

  return (
    <>
      <Box
        overflow={"hidden"}
        flexGrow={1}
        mx={"auto"}
        width={loadingData ? "100%" : "100%"}
      >
        {/*Skeleton*/}
        {
          loadingData &&
          <ProjectDetailLoader/>
        }

        {/*Page Content*/}
        {
          !loadingData &&
          <>
            <Paper
              elevation={0}
              sx={{
                height: "100%"
              }}
            >
              <Box
                height={"100%"}
                overflow={"auto"}
                padding={"20px 30px 20px 20px"}
              >
                {/*Display Errori*/}
                {
                  errorMsg &&
                  <Alert
                    sx={{my: 1}}
                    severity={"error"}
                    onClose={() => setErrorMsg(null)}
                  >
                    {errorMsg}
                  </Alert>
                }

                {/*Titolo Pagina*/}
                <PageTitle
                  backClickHandler={backToList}
                  title={
                    <Stack
                      direction={{xs: "column", sm: "row"}}
                      alignItems={{xs: "flex-start", sm: "center"}}
                      justifyContent={{xs: "center", sm: "flex-start"}}
                      columnGap={1.5}
                      flexGrow={"1"}
                    >
                      <Typography
                        variant={"h3"}
                        mr={3}
                        className={"detail-title-wrapper"}
                      >
                        {getProjectCodeAndDescription(project)}
                      </Typography>
                    </Stack>
                  }
                  actions={
                    <>
                      <TooltipZts
                        title={t('disable')}
                        placement={"bottom"}
                        enterDelay={400}
                      >
                        <IconButton
                          color="primary"
                          className={"delete-button"}
                          onClick={handleDisableClicked}
                        >
                          <DeleteForever/>
                        </IconButton>
                      </TooltipZts>

                      <TooltipZts
                        title={t('modify')}
                        placement={"bottom"}
                        enterDelay={400}
                      >
                        <IconButton
                          color="primary"
                          className={"edit-button"}
                          onClick={handleEditClicked}
                        >
                          <Edit fontSize={"small"}/>

                        </IconButton>
                      </TooltipZts>
                    </>
                  }
                />

                {/*Dettagli*/}
                <Stack
                  mt={2}
                  direction={{
                    xs: "column",
                    lg: "row"
                  }}
                  columnGap={2}
                >
                  {/*Parte sx*/}
                  <Stack
                    flexGrow={1}
                    direction={{
                      xs: "column-reverse",
                      sm: "row"
                    }}
                    columnGap={2}
                    width={"100%"}
                  >
                    {/*General Data*/}
                    {
                      project &&
                      <GeneralData props={{project: project, edit: false, staffsList: staffsList}}/>
                    }
                  </Stack>
                </Stack>
              </Box>
            </Paper>
          </>
        }
      </Box>
    </>
  );
}
