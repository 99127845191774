// React
import React from "react";
import {Controller} from "react-hook-form";
import {FieldPath} from "react-hook-form/dist/types";

// MUI
import { FormControlLabel } from "@mui/material";

// Custom
import {IOSSwitch} from "../../base/switch";
import {ExpenseModalFormValues} from "./ExpenseModal";

type ExpenseSwitchProps = {
  name: FieldPath<ExpenseModalFormValues>
  label: string
  disabled?: boolean
}

export const ExpenseSwitch: React.FC<ExpenseSwitchProps> = ({name, label, disabled}) => {

  const toBoolean = (value: any): boolean => {
    if (value === undefined || value === null ){
      return false;
    }
    return value;
  }

  return (
    <Controller
      name={name}

      render={ ({field}) => <FormControlLabel
        control={
          <IOSSwitch
            disabled={disabled}
            checked = {disabled ? false : toBoolean(field.value)}
            onChange={ e => field.onChange(e) }
            onBlur={ () => field.onBlur() }
          />
        }
        label={label}
        labelPlacement='end' />}
    />
  );
}
