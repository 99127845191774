// React
import React, {ReactChild} from "react";

// MUI
import {Box} from "@mui/material";


export type TravelPolicyCardWrapperProps = {
  card: ReactChild
}

export const TravelPolicyCardWrapper: React.FC<TravelPolicyCardWrapperProps> = ({card}) => {

  return (
    <Box
      className='tp-card-wrapper'
      mr={{
        sm: 2
      }}
      mt={{
        xs: "0",
        sm: "unset"
      }}
      mb={2}
    >
      {card}
    </Box>
  );

}
