import {
  CompleteCreCardMovByIdRequest,
  CompleteCreCardMovDto,
  CreCardMovActionType,
  CreCardMovPerUploadCounterRequest,
  CreCardMovState,
  Group,
  GROUPED_CRE_CARD_MOV_PAGE_SIZE,
  ListFilter,
  ListFilterType
} from "../model";
import {useTranslation} from "react-i18next";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import React, {useEffect, useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Pagination, Stack, Typography} from "@mui/material";
import {formatDate} from "../../../util/DateUtil";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  cancelUploadById,
  confirmCreCardMov,
  discardCreCardMov,
  disconnectCreCardMov,
  getCreCardMovByUploadId,
  getPerUploadCounters
} from "../Service";
import {Add, KeyboardBackspace, ManageSearch, Remove} from "@mui/icons-material";
import {ENGLISH_GB} from "../../../util/LocalizationUtil";
import {CreCardMovInfoPopup} from "../popup/CreCardMovInfoPopup";
import {Loader} from "../../base/loader";
import {useConfirm} from "../../../hooks/useConfirm";
import {CreCardMovActionPopup} from "../popup/CreCardMovActionPopup";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {useSnackbar} from "notistack";
import {CommonList} from "./CommonList";
import {ContextMenu} from "./ContextMenu";
import {Currency} from "../../model";

interface ComponentProps {
  filters: ListFilter[],
  update: boolean,
  resetUpdate: () => void,
  currencies: Currency[]
}

export const PerUploadList = ({filters, update, resetUpdate, currencies}: ComponentProps) => {
  const {t} = useTranslation('cre-card-mov');
  const {userLocale} = useLoggedUser();
  const {confirm} = useConfirm();
  const {convertError} = useErrorMessage();
  const {enqueueSnackbar} = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [rows, setRows] = useState<CompleteCreCardMovDto[]>([]);
  const [selectedRow, setSelectedRow] = useState<CompleteCreCardMovDto | null>(null);
  const [pagedRows, setPagedRows] = useState<CompleteCreCardMovDto[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [expanded, setExpanded] = useState<Group | null>(null);
  const [updateAccordionHead, setUpdateAccordionHead] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loadingGroups, setLoadingGroups] = useState<boolean>(false);
  const [loadingRows, setLoadingRows] = useState<boolean>(false);

  const [infoPopup, setInfoPopup] = useState<{ id: number, open: boolean }>({id: -1, open: false});
  const [actionPopupInfo, setActionPopupInfo] = useState<{
    open: boolean,
    popupType: CreCardMovActionType | null,
    checked?: boolean,
    discarded?: boolean,
    checkedNotes?: string,
    discardedNotes?: string
  }>({
    open: false,
    popupType: null
  });

  const reset = () => {
    setCurrentPage(1);
    setTotalPages(1);
    setRows([]);
    setSelectedRow(null);
    setExpanded(null);
  }

  useEffect(() => {
    asyncGetAndSetGroups().then();
  }, [filters]);

  useEffect(() => {
    if (update) {
      asyncGetAndSetGroups().then(() => resetUpdate());
    }
  }, [update]);

  useEffect(() => {
    if (expanded) {
      handleListExpand(expanded, true);
    }
  }, [groups])

  const asyncGetAndSetGroups = async (): Promise<void> => {
    setLoadingGroups(true);
    let request: CreCardMovPerUploadCounterRequest = {};
    filters.forEach(filter => {
      switch (filter.type) {
        case ListFilterType.SEARCH_TEXT:
          request.searchText = filter.value;
          request.locale = userLocale;
          break;
        case ListFilterType.STATE:
          request.state = CreCardMovState[filter.value];
          break;
        case ListFilterType.UPLOAD_DATE_FROM:
          request.uploadDateFrom = formatDate(filter.value, ENGLISH_GB);
          break;
        case ListFilterType.UPLOAD_DATE_TO:
          request.uploadDateTo = formatDate(filter.value, ENGLISH_GB);
          break;
      }
    });
    let resp;
    try {
      resp = await getPerUploadCounters(request);
    } catch (err: any) {
      convertError(createError(err))
        .then(msg => {
          setLoadingGroups(false);
          enqueueSnackbar(msg,
            {
              variant: "error",
              autoHideDuration: 1000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top"
              }
            });
        });
      return;
    }
    if (resp.size === 0) {
      setGroups([]);
      setLoadingGroups(false);
      return;
    }
    const list = resp.elements;
    setGroups(list.map((c) => {
      if (expanded && expanded.id === c.id) {
        setExpanded({
          id: c.id,
          day: c.day,
          month: c.month,
          year: c.year,
          count: c.counter
        });
      }
      return {
        id: c.id,
        day: c.day,
        month: c.month,
        year: c.year,
        count: c.counter
      };
    }));
    setLoadingGroups(false);
  }

  const asynGetAndSetRows = async (request: CompleteCreCardMovByIdRequest): Promise<void> => {
    setLoadingRows(true);
    const resp = await getCreCardMovByUploadId(request);
    setRows(resp.elements);
    setPagedRows(resp.elements.slice(0, GROUPED_CRE_CARD_MOV_PAGE_SIZE));
    setTotalPages(resp.totalPages);
    setLoadingRows(false);
  }

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>, creCardMov: CompleteCreCardMovDto) => {
    setSelectedRow(creCardMov);
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    if (page === totalPages) {
      setPagedRows(rows.slice((page - 1) * GROUPED_CRE_CARD_MOV_PAGE_SIZE));
    } else {
      setPagedRows(rows.slice((page - 1) * GROUPED_CRE_CARD_MOV_PAGE_SIZE, (page - 1) * GROUPED_CRE_CARD_MOV_PAGE_SIZE + GROUPED_CRE_CARD_MOV_PAGE_SIZE));
    }
  }

  const handleListExpand = (group: Group, keepExpanded?: boolean) => {
    if (updateAccordionHead) {
      setUpdateAccordionHead(false);
      return;
    } else if ((expanded?.id !== group.id) || keepExpanded) {
      setExpanded(group);
      let newRequest: CompleteCreCardMovByIdRequest = {
        page: currentPage,
        pageSize: GROUPED_CRE_CARD_MOV_PAGE_SIZE,
        uploadId: group.id
      }
      filters.forEach(filter => {
        switch (filter.type) {
          case ListFilterType.SEARCH_TEXT:
            newRequest.searchText = filter.value;
            newRequest.locale = userLocale;
            break;
          case ListFilterType.STATE:
            newRequest.state = CreCardMovState[filter.value];
            break;
        }
      });
      asynGetAndSetRows(newRequest).then();
    } else {
      reset();
    }
  }

  const openInfoPopup = async () => {
    if (selectedRow) {
      setInfoPopup({id: selectedRow.creCardId, open: true});
    }
  }

  const closeInfoPopup = () => {
    setInfoPopup({id: -1, open: false});
    setSelectedRow(null);
  }

  const openActionPopup = (type: CreCardMovActionType) => {
    if (selectedRow && selectedRow.creCardId) {
      setActionPopupInfo({
        open: true,
        popupType: type,
        checked: selectedRow.checked,
        discarded: selectedRow.discarded,
        checkedNotes: selectedRow.checkedNotes,
        discardedNotes: selectedRow.discardedNotes
      });
    }
  }

  const closeActionPopup = () => {
    setActionPopupInfo({open: false, popupType: null});
    setSelectedRow(null);
  }

  const updateList = (updatedCreCardMov: CompleteCreCardMovDto) => {
    if (updatedCreCardMov && selectedRow && selectedRow.creCardId) {
      let removeRow = false;
      const currentFilterState = filters.find(f => f.type === ListFilterType.STATE);
      if (currentFilterState?.value === 'CONNECTED' && updatedCreCardMov.creCardMovState !== CreCardMovState.CONNECTED) {
        removeRow = true;
      } else if (currentFilterState?.value === 'DISCONNECTED' && updatedCreCardMov.creCardMovState !== CreCardMovState.DISCONNECTED) {
        removeRow = true;
      } else if (currentFilterState?.value === 'EXCLUDED' && updatedCreCardMov.creCardMovState !== CreCardMovState.EXCLUDED) {
        removeRow = true;
      }
      if (removeRow) {
        setPagedRows(pagedRows.filter(row => row.creCardId !== selectedRow.creCardId));
        if (expanded) {
          setGroups(prevGroups => {
            return prevGroups.map(g => {
              if (g.id === expanded.id) {
                return {...g, count: expanded.count - 1};
              }
              return g;
            })
          });
        }
      }
      const updatedPagedRows = pagedRows.map(row => {
        if (row.creCardId === selectedRow.creCardId) {
          return updatedCreCardMov;
        }
        return row;
      });
      setPagedRows(updatedPagedRows);
    }
  }

  const handleActionPopupActions = async (actionType: CreCardMovActionType | null, notes?: string): Promise<void> => {
    if (selectedRow && selectedRow.creCardId && actionType != null) {
      let resp;
      switch (actionType) {
        case CreCardMovActionType.CHECK_MOV:
          resp = await confirmCreCardMov(selectedRow.creCardId, true, notes);
          if (resp) {
            updateList(resp);
          }
          return;
        case CreCardMovActionType.UNCHECK_MOV:
          resp = await confirmCreCardMov(selectedRow.creCardId, false, notes);
          if (resp) {
            updateList(resp);
          }
          return;
        case CreCardMovActionType.DISCARD_MOV:
          resp = await discardCreCardMov(selectedRow.creCardId, true, notes);
          if (resp) {
            updateList(resp);
          }
          return;
        case CreCardMovActionType.UNDISCARD_MOV:
          resp = await discardCreCardMov(selectedRow.creCardId, false, notes);
          if (resp) {
            updateList(resp);
          }
          return;
      }
    }
  }

  const disconnectMov = () => {
    confirm({
      body: (
        <Typography variant="body1" display="inline" mr={0.5}>
          {t('popup.confirmDisconnect')}
        </Typography>
      ),
      onConfirm: async () => {
        if (selectedRow && selectedRow.creCardId) {
          setLoadingRows(true);
          try {
            const resp = await disconnectCreCardMov(selectedRow.creCardId);
            if (resp) {
              updateList(resp);
            }
          } catch (err: any) {
            convertError(createError(err))
              .then(msg => {
                enqueueSnackbar(msg,
                  {
                    variant: "error",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top"
                    }
                  });
              })
          } finally {
            setLoadingRows(false);
          }
        }
      }
    });
  }

  const cancelEntireUploadById = (uploadId: number) => {
    confirm({
      body: (
        <Typography variant="body1" display="inline" mr={0.5}>
          {t('popup.confirmCancelEntireUploadById')}
        </Typography>
      ),
      onConfirm: async () => {
        setLoadingGroups(true);
        try {
          const resp = await cancelUploadById(uploadId);
          if (resp) {
            asyncGetAndSetGroups().then();
          }
        } catch (err: any) {
          convertError(createError(err))
            .then(msg => {
              enqueueSnackbar(msg,
                {
                  variant: "error",
                  autoHideDuration: 1000,
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "top"
                  }
                });
            });
        } finally {
          setLoadingGroups(false);
        }
      }
    });
  }

  const handleOpenRowDetail = (id: number) => {
    setInfoPopup({id, open: true});
  }

  return (
    <Box className="cre-card-mov-per-upload-wrapper">
      {
        loadingGroups ? (
          <Loader show={true}/>
        ) : (
          groups.map((e, i) => {
            return <Accordion
              key={i}
              disableGutters
              expanded={e.id === expanded?.id}
              className={"exp-note-expense-list-group"}
            >
              <AccordionSummary>
                <Stack
                  direction={"row"}
                  columnGap={2}
                  alignItems={"center"}
                >
                  <Box
                    className={"expand-button"}
                    onClick={() => handleListExpand(e)}
                  >
                    {e.id === expanded?.id ? <KeyboardBackspace/> : <ManageSearch fontSize="medium"/>}
                  </Box>
                  <Typography variant={"h5"}>#{e.id} {e.day} {t(`months.${e.month}`)} {e.year} ({e.count})</Typography>
                </Stack>
                <Box flexGrow={1}/>
                <Box
                  className={'action-button'}
                  columnGap={0.5}
                  onClick={() => cancelEntireUploadById(e.id)}
                >
                  <DeleteForeverIcon fontSize={'small'}/>
                  <Typography variant={'h5'}>{t('deleteUploadButton')}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <CommonList
                  loadingRows={loadingRows}
                  pagedRows={pagedRows}
                  grouped
                  handleMenuClick={(e, row) => handleMenuClick(e, row)}
                  currencies={currencies}
                  openRowDetail={(id: number) => handleOpenRowDetail(id)}
                />
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  mt={1}
                >
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    shape="rounded"/>
                </Box>
              </AccordionDetails>
            </Accordion>
          })
        )
      }

      <ContextMenu
        anchorEl={anchorEl}
        closeMenu={() => setAnchorEl(null)}
        selectedRow={selectedRow}
        openActionPopup={openActionPopup}
        openInfoPopup={openInfoPopup}
        disconnectMov={disconnectMov}
      />

      <CreCardMovInfoPopup open={infoPopup.open} id={infoPopup.id} handleClose={closeInfoPopup} currencies={currencies}/>

      <CreCardMovActionPopup id={selectedRow?.creCardId} info={actionPopupInfo} handleActions={handleActionPopupActions}
                             handleClose={closeActionPopup}/>
    </Box>
  );
}
