import React, {useEffect, useState} from "react";
import {StaffTravelPoliciesSaveReq, StaffTravelPolicyItem, User} from "../model";
import {getStaffTravelPolicies, saveStaffTravelPolicies} from "../Service";
import {getAllTravelPolicies} from "../../travel_policies/Service";
import {CommonDialog} from "../../base/common-dialog";
import {TransferList, TransferListItem} from "../../base/transfer-list";
import {HelpCurrentPage} from "../../../reducers/Help";
import {useTranslation} from "react-i18next";
import {activeStateCode} from "../../model";
import {useHelp} from "../../help/hook";
import moment from "moment";

type ComponentProps = {
  show: boolean,
  onClose: Function,
  user: User | null
}

export const TravelPolicies = ({show, onClose, user}: ComponentProps) => {

  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [leftItems, setLeftItems] = useState<TransferListItem[] | null>(null);
  const [rightItems, setRightItems] = useState<TransferListItem[] | null>(null);

  const {t} = useTranslation('user', {keyPrefix: "detail.items.tp"});

  useHelp({
    paths: [
      {
        path: '/modal',
        page: show ? HelpCurrentPage.USER_TRAVEL_POLICY : HelpCurrentPage.USER_DETAIL
      }
    ]
  });

  useEffect(() => {
    if (show) {
      Promise.all([
        getStaffTravelPolicies(user ? user.id : 0),
        getAllTravelPolicies()
      ])
        .then(values => {
            const userTps = values[0];
            const allTps = values[1];
            if (userTps && userTps.length > 0) {
              setLeftItems(userTps.map(c => ({id: c.travelPolicyId, label: c.description, recordId: c.id})));
            } else {
              setLeftItems([]);
            }

            if (allTps && allTps.length > 0) {
              setRightItems(allTps
                .filter(tp => tp.state === activeStateCode || (userTps && userTps.some(userTp => userTp.travelPolicyId === tp.id)))
                .map(c => {
                  let suffix: string | undefined;
                  if (c.state !== activeStateCode) {
                      suffix = t("notValid");
                  } else if (moment(c.endDate).isBefore(moment(), 'days')) {
                    suffix = t("outdated");
                  }
                  return {
                    id: c.id,
                    label: c.description,
                    suffix
                  };
                }));
            }
          }
        )
        .catch(err => console.log('Fetch travel policies error', err))
    } else {
      setLeftItems(null);
      setRightItems(null);
    }
  }, [show, user]);

  const handleActionButtonClicked = (save) => {
    if (save) {
      handleSave();
    } else {
      onClose(false);
    }
  }

  const createStaffTp = (item: TransferListItem): StaffTravelPolicyItem => {
    let obj: StaffTravelPolicyItem = {travelPolicyId: item.id};
    if (item.recordId) {
      obj = {...obj, id: item.recordId};
    }
    return obj;
  }

  const handleSave = async () => {
    if (user) {
      setErrorMsg(null);
      setSaving(true);

      const data: StaffTravelPoliciesSaveReq = {
        lastUpdNum: user.lastUpdateNum,
        travelPolicies: leftItems ? leftItems.map(item => createStaffTp(item)) : []
      }

      //console.log(data);

      try {
        await saveStaffTravelPolicies(user.id, data);
        onClose(true);
      } catch (error) {
        setErrorMsg(`Saving error. ${error}`);
      } finally {
        setSaving(false);
      }
    }
  }

  return (
    <CommonDialog
      show={show}
      title={t("assigned")}
      widths={[
        {breakpoint: "lg", width: "600px"},
      ]}
      saving={saving}
      onClose={handleActionButtonClicked}
      errorMsg={errorMsg}
      page={show ? HelpCurrentPage.USER_TRAVEL_POLICY : HelpCurrentPage.USER_DETAIL}
    >
      <TransferList
        selectedItems={leftItems}
        setSelectedItems={setLeftItems}
        allItems={rightItems}
      />
    </CommonDialog>
  );

}
