import { ExpNoteExpenseFull, PayMode } from './expense/model';
import { AutocompleteGenericOption, createAutocompleteGenericOption } from '../base/autocomplete/model';
import { Locale } from '../../util/LocalizationUtil';
import { CreCardMovState } from '../credit-card-mov/model';

export const OCR_MAX_RETRY = 10;
export const OCR_INTERVAL_TIMER = 500;

export interface ExpNote {
  id: number;
  code: string;
  description: string;
  staffId: number;
  costCenterId: number;
  startDate: Date;
  endDate: Date;
  totalDaysNumber: number;
  expTotalCount: number;
  expTotal: number;
  maxExcExp: number;
  maxExcAut: number;
  debCreStaff: number;
  cogeCost: number;
  anticSettle: number;
  balance: number;
  state: ExpNoteState;
  lastUpdNum: number;
  approverId?: number;
  approvalDate: Date;
  payDate: Date;
  contabDate: Date;
  archivingDate: Date;
  lastUpdateNum: number;
  notes: string;
}

export interface ExpNoteWithStaff extends ExpNote {
  traveller: string;
}

export interface ExpNoteArchive extends ExpNote {
  traveller: string;
  staffApproverId?: number;
  expNoteApproverId?: number;
  expNoteApprover: string;
}

export enum ExpNoteState {
  DA_COMPLETARE = 'DRF',
  DA_APPROVARE = 'APR',
  DA_CONTROLLARE = 'CHK',
  DA_RIVEDERE = 'RJC',
  ANNULLATA = 'CNC',
  DA_LIQUIDARE = 'LQD',
  DA_CONTABILIZZARE = 'ACN',
  DA_ARCHIVIARE = 'TAR',
  ARCHIVIATE = 'ARC',
}

export enum ExpNoteStateFE {
  DA_COMPLETARE = 'DRAFT',
  DA_APPROVARE = 'TO_APPROVE',
  DA_RIVEDERE = 'REJECTED',
  DA_CONTROLLARE = 'TO_CHECK',
  DA_LIQUIDARE = 'TO_PAY',
  DA_CONTABILIZZARE = 'TO_CONTAB',
  DA_ARCHIVIARE = 'TO_ARCHIVE',
  ARCHIVIATE = 'ARCHIVED'
}

export const getAdminExpNoteStatesFE = (): ExpNoteStateFE[] => {
  return [
    ExpNoteStateFE.DA_COMPLETARE, // N.B: Si riferisce al tab Note Spese "In corso" (stati associati: 'DA_COMPLETARE', 'DA_APPROVARE' e 'DA_RIVEDERE')
    ExpNoteStateFE.DA_CONTROLLARE,
    ExpNoteStateFE.DA_LIQUIDARE,
    ExpNoteStateFE.DA_CONTABILIZZARE,
    ExpNoteStateFE.DA_ARCHIVIARE,
    ExpNoteStateFE.ARCHIVIATE,
  ];
}

export const getTravellerExpNoteStatesFE = (): ExpNoteStateFE[] => {
  return [
    ExpNoteStateFE.DA_COMPLETARE,
    ExpNoteStateFE.DA_RIVEDERE,
    ExpNoteStateFE.ARCHIVIATE,
  ];
}

export const getApproverExpNoteStatesFE = (): ExpNoteStateFE[] => {
  return [
    ExpNoteStateFE.DA_APPROVARE
  ];
}

export const getExpNoteStateFELabel = (state: ExpNoteStateFE, t, singular = false): string => {
  return t(`state.${state}`, {count: singular ? 1 : 0});
}

export const getExpNoteFEState = (expNoteState: ExpNoteState): ExpNoteStateFE | null => {
  let found = getExpNotesToCompleteStates().find(s => s === expNoteState);
  if (found) {
    return ExpNoteStateFE.DA_COMPLETARE;
  }
  found = getExpNotesToApproveStates().find(s => s === expNoteState);
  if (found) {
    return ExpNoteStateFE.DA_APPROVARE;
  }
  found = getExpNotesToReviewStates().find(s => s === expNoteState);
  if (found) {
    return ExpNoteStateFE.DA_RIVEDERE;
  }
  found = getExpNotesToCheckStates().find(s => s === expNoteState);
  if (found) {
    return ExpNoteStateFE.DA_CONTROLLARE;
  }
  found = getExpNotesToPayStates().find(s => s === expNoteState);
  if (found) {
    return ExpNoteStateFE.DA_LIQUIDARE;
  }
  found = getExpNotesToContabStates().find(s => s === expNoteState);
  if (found) {
    return ExpNoteStateFE.DA_CONTABILIZZARE;
  }
  found = getExpNotesToArchiveStates().find(s => s === expNoteState);
  if (found) {
    return ExpNoteStateFE.DA_ARCHIVIARE;
  }
  found = getExpNotesArchivedStates().find(s => s === expNoteState);
  if (found) {
    return ExpNoteStateFE.ARCHIVIATE;
  }
  return null;
}

export const isExpNoteInProgress = (state: ExpNoteStateFE): boolean => {
  return state === ExpNoteStateFE.DA_COMPLETARE ||
    state === ExpNoteStateFE.DA_RIVEDERE ||
    state === ExpNoteStateFE.DA_APPROVARE;
}

export enum OcrState {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

export interface OcrResult {
  expDate: Date;
  expAmount: number;
  currCode: string;
  stateCode: OcrState;
}

export interface ExpNoteLocality {
  locality: string;
}

export interface ExpenseDocumentSaveRequest {
  docType: string;
  payMode: string;
  cardId?: number;
  currencyCode: string;
  exchange: number;
  locality?: string;
  projectId?: number;
  supplierId?: number;
  invoiceNum?: string;
  docDate?: Date;
  lastUpdNum: number;
  attachments: ExpenseDocumentAttachment[];
  expenses: ExpenseDocumentSingleExp[];
  skipExchRecalc: boolean;
}

export interface ExpenseDocumentSaveResponse {
  movementDisconnected: boolean
}

export interface ExpenseDocumentSingleExp {
  id?: number;
  tpExpenseId: number;
  compDate?: Date;
  compStartDate: Date | null;
  compEndDate: Date | null;
  currAmount: number;
  notes: string;
  colleagues: ExpenseDocumentColleague[];
  guests: ExpenseDocumentGuest[];
}

export interface ExpenseDocumentColleague {
  staffId: number;
}

export interface ExpenseDocumentGuest {
  guestName: string;
}

export interface ExpenseDocumentAttachment {
  uuid?: string;
  attachId?: number;
}

export interface ExpenseRoute {
  id?: number;
  stepNum: number;
  startAddress: string;
  startAddressPlaceId?: string;
  endAddress: string;
  endAddressPlaceId?: string;
  distance: number;
  expectedDistance?: number;
  notes?: string;
}

export interface SingleExpenseSaveRequest {
  locality?: string;
  projectId?: number;
  attachments: ExpenseDocumentAttachment[];
  quantity: number;
  tarif: number;
  lastUpdNum: number;
  tpExpenseId: number;
  compDate: Date;
  compStartDate: Date | null;
  compEndDate: Date | null;
  notes: string;
  currAmount: number;
  routes?: ExpenseRoute[];
}

export interface ExchangeRequest {
  expId?: number;
  currencyCode?: string;
  date: Date | null;
  payMode?: string;
  amount: string;
}

export interface ExchangeResponse {
  date: Date;
  exchange: number;
  currency: string;
}

export interface UpdateExpNoteMaximumRequest {
  lastUpdateNum: number,
  expenses: UpdateExpNoteSingleMaximum[]
}

export interface UpdateExpNoteSingleMaximum {
  expenseId: number,
  maximum: number
}

export enum PaymentCausal {
  ADVANCE = 'A',
  CHANGE_CURRENCY = 'C',
  RETURN_CURRENCY = 'R'
}

export const getPaymentCausalDesc = (t: any, causal?: PaymentCausal): string => {
  if (causal) {
    return t(`causal.${causal}`);
  }
  return '';
}

export interface ExpNotePayment {
  id: number,
  causal: PaymentCausal,
  operDate: Date,
  currAmount: number,
  currencyCode: string,
  exchange: number,
  compAmount: number,
  convertedCurrAmount: number,
  convertedCurrencyCode: string,
  convertedExchange: number,
  convertedCompAmount: number,
  contableAccountId: number,
  payDate: Date,
  recDate: Date,
  libroUnicoDate: Date,
  insertUser: number,
  insertTime: Date,
  lastUpdUser: number,
  lastUpdTime: Date,
}

export interface PaymentSaveRequest {
  causal: PaymentCausal,
  operDate: Date,
  currAmount: number,
  currencyCode: string,
  lastUpdNum: number,
  convertedCurrAmount?: number,
  convertedCurrencyCode?: string
}

export interface ExpNoteFilter {
  searchText: string[] | null,
  startDate: Date[] | null,
  contabDate: Date[] | null,
}

export enum ExpNoteSortColumn {
  CODE = 'id',
  TRAVELLER = 'staff',
  DESCRIPTION = 'description',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TOTAL_AMOUNT = 'total',
  PAY_DATE = 'payDate',
  CONTAB_DATE = 'contabDate',
  ARCHIVING_DATE = 'archivingDate',
}

export const getSortTypeParam = (sortColumn: ExpNoteSortColumn): string => {
  switch (sortColumn) {
    case ExpNoteSortColumn.CODE:
      return 'code';
    case ExpNoteSortColumn.TRAVELLER:
      return 'traveller';
    case ExpNoteSortColumn.DESCRIPTION:
      return 'description';
    case ExpNoteSortColumn.START_DATE:
      return 'startDate';
    case ExpNoteSortColumn.END_DATE:
      return 'endDate';
    case ExpNoteSortColumn.TOTAL_AMOUNT:
      return 'totalAmount';
    case ExpNoteSortColumn.PAY_DATE:
      return 'payDate';
    case ExpNoteSortColumn.CONTAB_DATE:
      return 'contabDate';
    case ExpNoteSortColumn.ARCHIVING_DATE:
      return 'archDate';
  }
  return '';
}

export interface ExpNoteSort {
  orderBy: ExpNoteSortColumn,
  orderDir?: boolean;
}

export const getExpNotesToCompleteStates = (): ExpNoteState[] => {
  return [ExpNoteState.DA_COMPLETARE];
}

export const getExpNotesToApproveStates = (): ExpNoteState[] => {
  return [ExpNoteState.DA_APPROVARE];
}

export const getExpNotesToReviewStates = (): ExpNoteState[] => {
  return [ExpNoteState.DA_RIVEDERE];
}

export const getExpNotesToCheckStates = (): ExpNoteState[] => {
  return [ExpNoteState.DA_CONTROLLARE];
}

export const getExpNotesToPayStates = (): ExpNoteState[] => {
  return [ExpNoteState.DA_LIQUIDARE];
}

export const getExpNotesToContabStates = (): ExpNoteState[] => {
  return [ExpNoteState.DA_CONTABILIZZARE];
}

export const getExpNotesToArchiveStates = (): ExpNoteState[] => {
  return [ExpNoteState.DA_ARCHIVIARE];
}

export const getExpNotesArchivedStates = (): ExpNoteState[] => {
  return [ExpNoteState.ARCHIVIATE];
}

export const getExpNotesPayedStates = (): ExpNoteState[] => {
  return [...getExpNotesToContabStates(), ...getExpNotesToArchiveStates(), ...getExpNotesArchivedStates()];
}

export const getExpNotesInProgressStates = (): ExpNoteState[] => {
  return [...getExpNotesToCompleteStates(), ...getExpNotesToApproveStates(), ...getExpNotesToReviewStates()];
}

export const isExpPayedByTraveller = (expense: ExpNoteExpenseFull): boolean => {
  return expense.payMode && expense.payMode === PayMode.TRAVELLER;
}

export const isExpPayedByCompany = (expense: ExpNoteExpenseFull): boolean => {
  return expense.payMode && expense.payMode !== PayMode.TRAVELLER;
}

export const SkeletonRowHeight = 80;

export interface SaveExpNote {
  id: number | null,
  description: string,
  startDate: Date,
  endDate: Date,
  notes: string,
  lastUpdateNum: number,
  staffId: number,
  costCenterId: number
}

export interface ExpNoteStateChangeRequest {
  lastUpdateNum: number
}

export interface ExpNoteStateChangeSingleRequest {
  expNoteId: number,
  lastUpdateNum: number
}

export interface ExpNoteStateChangeBulkRequest {
  expNotes: ExpNoteStateChangeSingleRequest[],
  locale: Locale
}

export const GOOGLE_PLACE_SUGGESTION_MIN_CHAR = 3;

export interface GooglePlaceSuggestion {
  formattedAddress: string;
  placeId: string;
}

export interface GoogleDistanceResponse {
  valid: boolean;
  distance: number;
}

export const createAutocompletePlaceOption = (suggestion: GooglePlaceSuggestion): AutocompleteGenericOption => {
  return createAutocompleteGenericOption(-1, suggestion.placeId, suggestion.formattedAddress);
}

export interface AvailableAmountToReturn {
  currency: string,
  amount: number,
  exchange: number
}

export interface ExpNoteCreCardMov {
  movId: number;
  description: string;
  docNum: number;
  creCardNum: string;
  payDate: Date;
  currAmount: number;
  currency: string;
  compAmount: number;
  discarded: boolean;
  creCardMovState: CreCardMovState
}
