import * as Yup from 'yup';

export type ImportFormValues = {
  id?: number;
}

export const newImportFormValues = {
  id: 1
}

export const importValidationSchema = () => {
  return Yup.object({
    id: Yup.object().nullable()
  });
}
