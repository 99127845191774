// libs
import axios from 'axios';
import { Logger } from 'react-logger-lib';
import SimpleJsLog from '../../util/Logger';

// custom
import { getApiUrl } from '../../config/environment';
import {
  CheckExpenseUsed,
  CreateTravelPolicyExpenseApiRequest, CreateTravelPolicyExpenseRequest, CreateTravelPolicyExpenseResponse,
  TravelPolicy, TravelPolicyExpense,
  TravelPolicyHeader,
  TravelPolicyWithExpenses,
  UpdateTravelPolicyExpenseApiRequest,
  UpdateTravelPolicyExpenseRequest
} from './model';
import { DateFormat, GenericList, getStateCode } from '../model';
import { createError } from '../../util/ErrorUtil';
import moment from 'moment/moment';
import { Level } from '../levels/model';


// Vedi DisableTravelPoliciesRequest.java
type DisableTravelPoliciesRequest = {
  travelPoliciesId: number[]
}

const LOG: SimpleJsLog = Logger.of('ZTS.TravelPolicies.Service');

// TravelPolicy Service

export const getActiveTravelPolicies = async (): Promise<TravelPolicy[]> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicies?state=${getStateCode(true)}`;
  const resp = await axios.get<GenericList<TravelPolicy>>(url);
  LOG.info('API response status', resp.status);
  LOG.trace('API response', resp);
  if (resp.data.size===0) {
    LOG.trace('Empty result');
    return [];
  }
  return resp.data.elements;
}

export const getActiveTravelPoliciesByDate = async (searchDate: Date): Promise<TravelPolicy[]> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicies?state=${getStateCode(true)}&searchDate=${moment(searchDate).format(DateFormat)}`;
  const resp = await axios.get<GenericList<TravelPolicy>>(url);
  LOG.info('API response status', resp.status);
  LOG.trace('API response', resp);
  if (resp.data.size===0) {
    LOG.trace('Empty result');
    return [];
  }
  return resp.data.elements;
}

export const getAllTravelPolicies = async (): Promise<TravelPolicy[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<TravelPolicy>>(`${apiUrl}/travelpolicies`);
  LOG.info('API response status', resp.status);
  LOG.trace('API response', resp);
  if (resp.data.size===0) {
    LOG.trace('Empty result');
    return [];
  }
  return resp.data.elements;
}

export const getAllLevels = async (): Promise<Level[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<Level>>(`${apiUrl}/levels`);
  LOG.info('API response status', resp.status);
  LOG.trace('API response', resp);
  if (resp.data.size===0) {
    LOG.trace('Empty result');
    return [];
  }
  return resp.data.elements;
}

export const disableTravelPolicies = async (ids: number[]): Promise<void> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicies/disable`;
  const body: DisableTravelPoliciesRequest = {
    travelPoliciesId: ids
  }
  try {
    LOG.trace('disableTravelPolicies. Calling API ...');
    const resp = await axios.put(url, body);
    LOG.trace('disableTravelPolicies API result', resp);
  } catch (err: any) {
    throw createError(err);
  }
}

export const enableTravelPolicies = async (ids: number[]): Promise<void> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicies/enable`;
  const body: DisableTravelPoliciesRequest = {
    travelPoliciesId: ids
  }
  try {
    LOG.trace('enableTravelPolicies. Calling API ...');
    const resp = await axios.put(url, body);
    LOG.trace('enableTravelPolicies API result', resp);
  } catch (err: any) {
    throw createError(err);
  }
}

export const getTravelPolicyById = async (id: number): Promise<TravelPolicy> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicy/${id}`;
  try {
    LOG.trace('getTravelPolicyById calling API ...');
    const resp = await axios.get(url);
    LOG.trace('getTravelPolicyById API result', resp);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}


export const getTravelPolicyWithExpensesById = async (id: number): Promise<TravelPolicyWithExpenses> => {
  const apiUrl = getApiUrl();
  const urls: string[] = [
    `${apiUrl}/travelpolicy/${id}`,
    `${apiUrl}/travelpolicy/${id}/expenses`
  ];
  try {
    LOG.trace('getTravelPolicyWithExpensesById calling API ...');
    const result = await Promise.all( urls.map((url) => axios.get(url)))
      .then(([{data: tp}, {data: exps}] )=> {
        let expenses = exps.elements;
        if (exps.size===0){
          expenses = [];
        }
      return {
        ...tp,
        expenses: expenses
      }

    });
    LOG.trace('getTravelPolicyWithExpensesById API result:', result);
    return result;
  } catch (err: any) {
    throw createError(err);
  }
}

// Aggiornamento di una singola riga
export const updateTravelPolicyExpense = async (
  id: number, header: TravelPolicyHeader, request: UpdateTravelPolicyExpenseRequest
): Promise<TravelPolicyExpense> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicy/${header.travelPolicyId}/expenses/${id}`;
  const body: UpdateTravelPolicyExpenseApiRequest = {
    ...request,
    lastUpdNum: header.lastUpdNum
  }
  try {
    LOG.trace('updateUpdateTravelPolicyExpense calling API ...');
    const resp = await axios.put<TravelPolicyExpense>(url, body);
    LOG.trace('updateUpdateTravelPolicyExpense API result:', resp);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

// Creazione nuova voce spesa nella tp
export const createTravelPolicyExpense = async (
  header: TravelPolicyHeader, request: CreateTravelPolicyExpenseRequest
): Promise<TravelPolicyExpense> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicy/${header.travelPolicyId}/expenses`;
  const body: CreateTravelPolicyExpenseApiRequest = {
    ...request,
    lastUpdNum: header.lastUpdNum
  }
  try {
    LOG.trace('createTravelPolicyExpense calling API ...');
    const respId = await axios.post<CreateTravelPolicyExpenseResponse>(url, body);
    LOG.trace('createTravelPolicyExpense API result:', respId);
    const loadUrl = `${url}/${respId.data.id}`;
    const resp = await axios.get<TravelPolicyExpense>(loadUrl);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

// Delete di una voce spese nella tp
export const deleteTravelPolicyExpense = async (
  id: number, header: TravelPolicyHeader
): Promise<void> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicy/${header.travelPolicyId}/expenses/${id}?lastUpdNum=${header.lastUpdNum}`;
  try {
    LOG.trace('deleteTravelPolicyExpense calling API ...');
    const resp = await axios.delete<void>(url);
    LOG.trace('deleteTravelPolicyExpense API result:', resp);
  } catch (err: any) {
    throw createError(err);
  }
}

// Ricarico una singola spesa della travel policy (perchè aggiornata la spesa collagata)
export const loadTravelPolicyExpenseById = async (
  id: number, travelPolicyId: number
): Promise<TravelPolicyExpense> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicy/${travelPolicyId}/expenses/${id}`;
  try {
    LOG.trace('loadTravelPolicyExpenseById calling API ...');
    const resp = await axios.get<TravelPolicyExpense>(url);
    LOG.trace('loadTravelPolicyExpenseById API result:', resp);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}


//vedi CreateTravelPolicyRequest.java {
export interface CreateTravelPolicyRequest {
  // code: string, rimosso per auto generate
  description: string,
  startDate: Date,
  masterTravelPolicyId: number | null
}

interface CreateTravelPolicyResponse {
  id: number
}

export const createTravelPolicy = async (
  request: CreateTravelPolicyRequest
): Promise<number> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicies`;
  try {
    LOG.trace('createTravelPolicy calling API ...');
    const resp = await axios.post<CreateTravelPolicyResponse>(url, request);
    LOG.trace('createTravelPolicy API result:', resp);
    return resp.data.id;
  } catch (err: any) {
    throw createError(err);
  }
}

// vedi UpdateTravelPolicyRequest.java
export type UpdateTravelPolicyRequest = Omit<TravelPolicy, 'id'|'code'|'lastUpdNum'> & {
  lastUpdateNum: number;
}

export const updateTravelPolicy = async (id: number, body: UpdateTravelPolicyRequest): Promise<TravelPolicy> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicy/${id}`;
  try {
    LOG.trace('updateTravelPolicy calling API ...');
    const resp = await axios.put<TravelPolicy>(url, body);
    LOG.trace('updateTravelPolicy API result:', resp);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getDefaultTravelPolicy = async (): Promise<TravelPolicy> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicy/default`;
  try {
    LOG.trace('getDefaultTravelPolicy calling API ...');
    const resp = await axios.get(url);
    LOG.trace('getDefaultTravelPolicy API result', resp);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const isExpenseItemUsedInExpenses = async (travelPolicyId: number, expenseId: number): Promise<CheckExpenseUsed> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/travelpolicy/${travelPolicyId}/expenses/${expenseId}/checkuse`;
  try {
    LOG.trace('isExpenseItemUsedInExpenses calling API ...');
    const resp = await axios.get<CheckExpenseUsed>(url);
    LOG.trace('isExpenseItemUsedInExpenses API result', resp);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}
