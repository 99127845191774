import {useDispatch, useSelector} from "react-redux";
import {
  clearCurrentListFilters, getCurrentListBinMode,
  getCurrentListFilters,
  setCurrentListBinMode,
  setCurrentListFilters
} from "../reducers/CurrentList";

export const useCurrentList = () => {

  const binMode: boolean = useSelector(getCurrentListBinMode);
  const filters: any[] | null = useSelector(getCurrentListFilters);
  const dispatch = useDispatch();

  const setListFilters = (filters: any[]) => {
    dispatch(setCurrentListFilters(filters));
  }

  const setBinMode = (binMode: boolean) => {
    dispatch(setCurrentListBinMode(binMode));
  }

  const clearListFilters = () => {
    dispatch(clearCurrentListFilters());
  }

  return {
    binMode,
    filters,
    setListFilters,
    setBinMode,
    clearListFilters
  }

}
