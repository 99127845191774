import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";

type ComponentProps = {
  show: boolean
}

export const Loader = ({show}: ComponentProps) => {
  return (
    <Backdrop
      className="loader"
      open={show}
    >
      <CircularProgress/>
    </Backdrop>
  );
}
