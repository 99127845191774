import {Box, Button, Grid, Paper, Skeleton, Stack, Typography} from "@mui/material";
import {PageTitle} from "../layout/page-title";
import {useLoggedUser} from "../../hooks/useLoggedUser";
import {Controller, FormProvider, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {Save} from "@mui/icons-material";
import PasswordIcon from '@mui/icons-material/Password';
import {PersonalData} from "../users/detail/PersonalData";
import {FileUploadReq} from "../model";
import {uploadLogo} from "../settings/Service";
import {useErrorMessage} from "../../util/ErrorUtil";
import {useTranslation} from "react-i18next";
import {TextFieldZts} from "../base/text-field";
import {SelectZts} from "../base/select";
import {getSelectableLocales, ITALIAN} from "../../util/LocalizationUtil";
import {useNavigate} from "react-router-dom";
import {getUserById, getUserPicture, updateProfile} from "../users/Service";
import {ImageViewer} from "../base/image/viewer";
import {ProfileFormValues, UpdateProfileRequest} from "./model";

import {Logger} from 'react-logger-lib';
import Slf4jsLog from "../../util/Logger";
import {Loader} from "../base/loader";
import {ChangePassword} from "./change-password";

const LOG: Slf4jsLog = Logger.of('ZTS.Profile');

export const Profile = () => {
  const {
    user,
    updateUserProfile
  } = useLoggedUser();
  const {convertError} = useErrorMessage();
  const formMethods = useForm<ProfileFormValues>();
  const control = formMethods.control;
  const theme = useTheme();
  const {t, i18n} = useTranslation(['user']);
  const {t: tProfile} = useTranslation('profile');
  const {t: tBase} = useTranslation('base', {keyPrefix: 'images'});
  const navigate = useNavigate();

  const [tempImage, setTempImage] = useState<boolean>(false);

  const [changePassOpen, setChangePassOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [lastUpdateNum, setLastUpdateNum] = useState(0);

  useEffect(() => {
    if (user) {
      setLoading(true);
      getUserById(user.id.toString())
        .then(staff => {
          setLastUpdateNum(staff.lastUpdateNum);
          const userLocale = user.locale;
          formMethods.reset({
            pictureUniqueName: user.pictureId,
            locale: userLocale,
          });
        })
        .finally(() => setLoading(false));
    } else {
      formMethods.reset({
        pictureUniqueName: '',
        locale: ITALIAN
      });
    }
  }, [user]);

  const handleSaveClicked = () => {
    formMethods.handleSubmit(handleSave)();
  }

  const handleSave = async (values: ProfileFormValues) => {
    const req: UpdateProfileRequest = {
      pictureId: values.pictureUniqueName || undefined,
      locale: values.locale,
      lastUpdateNum
    }

    try {
      setSaving(true);
      await updateProfile(req);
      i18n.changeLanguage(values.locale);
      updateUserProfile(req);
      navigate("/");
    } catch (err) {
      LOG.error('Error updating profile', err);
    } finally {
      setSaving(false);
    }
  }

  const onPictureRemove = () => {
    formMethods.setValue('pictureUniqueName', "");
  }

  const onPictureUpload = async (request: FileUploadReq, onUploadProgressHandler: (progressEvent: any) => void) => {
    try {
      const resp = await uploadLogo(request, onUploadProgressHandler);
      formMethods.setValue("pictureUniqueName", resp.uploadKey);
      setTempImage(true);
    } catch (err: any) {
      const error = await convertError(err);
      setErrorMsg(error);
      formMethods.setValue("pictureUniqueName", null);
      setTempImage(false);
    }
  }

  const getPicture = async (pictureUniqueName: string): Promise<Blob> => {
    return await getUserPicture(user ? user.id : -1, pictureUniqueName);
  }


  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        mt: 2,
        px: 3.5,
        py: 2.5,
        flexGrow: 1,
        height: "100%"
      }}
    >
      <Box>
        <PageTitle
          title={<Typography variant={"h3"} className={"detail-title-wrapper"}>{tProfile('title')}</Typography>}
          backClickHandler={() => {
            navigate('/')
          }}
        />

        <Loader show={saving}/>

        <FormProvider {...formMethods}>
          <Box ml={5} mt={3}>
            {
              user &&
              <Grid container flexDirection={"row-reverse"}>

                <Grid item xs={12} lg={3}>
                  <Box ml={3}>
                    <Stack alignItems={"center"}>
                      <ImageViewer
                        label={tBase('image')}
                        imageId={formMethods.getValues("pictureUniqueName")}
                        tempImage={tempImage}
                        uploadImage={onPictureUpload}
                        deleteImage={onPictureRemove}
                        getImage={getPicture}
                      />
                      <Box
                        className={"edit-picture"}
                        onClick={() => setChangePassOpen(true)}
                        alignSelf={"center"}
                        sx={{backgroundColor: "#d9d4f7"}}
                        mt={2}
                      >
                        <PasswordIcon fontSize={"small"}/>
                        <Typography variant={"h6"} ml={1}>{tProfile('button.changePassword')}</Typography>
                      </Box>

                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={12} lg={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6"
                                  textTransform={"uppercase"}>{t("user:detail.generalData.title")}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextFieldZts
                        disabled={true}
                        label={t("user:detail.generalData.firstName")}
                        value={user.firstName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldZts
                        disabled={true}
                        label={t("user:detail.generalData.lastName")}
                        value={user.lastName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldZts
                        disabled={true}
                        label={t("user:detail.generalData.email")}
                        value={user.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {loading &&
                        <Skeleton height={50} sx={{transform: "none"}}/>
                      }

                      {!loading &&
                        <Controller
                          name="locale"
                          control={control}
                          render={({field, fieldState}) => {
                            return <SelectZts
                              label={t("user:detail.generalData.locale")}
                              width="100%"
                              disabled
                              items={getSelectableLocales(t)}
                              selectedValue={field.value ? field.value : ''}
                              errorMsg={fieldState.error?.message}
                              setValue={(value: string) => {
                                field.onChange(value);
                              }}
                            />
                          }}
                        />
                      }
                    </Grid>
                  </Grid>
                  <PersonalData user={user}/>
                </Grid>
              </Grid>
            }
          </Box>

        </FormProvider>


      </Box>

      <Box flexGrow={1}/>

      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        columnGap={2.2}
        mt={4}
        mr={2}
      >
        <Button key={2}
                variant="contained"
                className={"cta-button"}
                onClick={() => {
                  navigate('/');
                }}>
          <Typography variant={"button"}>{tProfile('button.cancel')}</Typography>
        </Button>
        <Button key={1}
                variant="contained"
                className={"cta-button-primary"}
                onClick={() => {
                  handleSaveClicked();
                }}
        >
          <Save sx={{fontSize: "1.1em"}}/>
          <Typography ml={1} variant={"button"}>{tProfile('button.save')}</Typography>
        </Button>
      </Stack>

      <ChangePassword
        show={changePassOpen}
        onClose={() => setChangePassOpen(false)}
      />

    </Paper>
  );
}
