import {Route, Routes} from "react-router-dom";
import {ProtectedRoute} from "../base/protected-routes";
import {useHelp} from "../help/hook";
import {APPROVEEXPNOTE_URL, DETAIL_URL} from "../model";
import {HelpCurrentPage} from "../../reducers/Help";
import {ExpNoteDetail} from "./detail";
import {useExpNoteList} from "./hooks/useExpNoteList";
import {ExpNoteListRole} from "../../reducers/ExpNoteList";
import {useLoggedUser} from "../../hooks/useLoggedUser";
import {useEffect, useState} from "react";
import {isApprover as isApproverFunc} from "../users/Service";
import {ExpNoteApproverList} from "./approver/list";

export const ExpNoteApprover = () => {

  const [isApprover, setIsApprover] = useState(false);

  useHelp({
    paths: [
      {
        path: `${APPROVEEXPNOTE_URL}`,
        page: HelpCurrentPage.EXP_NOTE_APPROVER,
        exactMatch: true
      }
    ]
  });

  const {user: loggedUser} = useLoggedUser();

  const {
    updateCurrentRole
  } = useExpNoteList();

  useEffect(() => {
    isApproverFunc()
      .then(res => setIsApprover(res));
  }, []);

  useEffect(() => {
    if (loggedUser && isApprover) {
      updateCurrentRole(ExpNoteListRole.APPROVER);
    }
  }, [loggedUser, isApprover]);

  return (
    <ProtectedRoute customCondition={() => isApprover}>
      <Routes>
        <Route path="/" element={<ExpNoteApproverList/>}/>
        <Route path={`${DETAIL_URL}/:id/*`} element={<ExpNoteDetail isAdmin={false}/>}/>
      </Routes>
    </ProtectedRoute>
  );
}
