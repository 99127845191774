import { ExpNoteListAdminFilter } from '../../../../reducers/ExpNoteList';
import { ExpNoteFilter } from '../../model';

export const GreyColor = '#7991AA';

export const ALL_EXPNOTE_DEFAULT_PAGE_SIZE = 200;
export const ARCHIVED_EXPNOTE_PAGE_SIZE = 7;

export enum ExpNoteListFilterType {
  SEARCH_TEXT = 'searchText',
  START_DATE = 'startDate',
  CONTAB_DATE = 'contabDate',
}

export interface ExpNoteListFilter {
  type: ExpNoteListFilterType,
  enabled: boolean,
  label: string,
  values: any[],
  hidden?: boolean,
  order?: number
}

export const ExpNoteToCheckListFilters: ExpNoteListFilter[] = [
  {
    type: ExpNoteListFilterType.SEARCH_TEXT,
    label: '',
    enabled: true,
    hidden: true,
    values: ['']
  },
  {
    type: ExpNoteListFilterType.START_DATE,
    label: 'list.filter.date',
    enabled: false,
    values: [null, null]
  }
];

export const ExpNoteToApproveListFilters: ExpNoteListFilter[] = [
  ...ExpNoteToCheckListFilters,
];

export const ExpNoteToContabListFilters: ExpNoteListFilter[] = [
  ...ExpNoteToCheckListFilters,
];

export const ExpNoteToArchiveListFilters: ExpNoteListFilter[] = [
  ...ExpNoteToContabListFilters,
];

export const ExpNoteArchivedListFilters: ExpNoteListFilter[] = [
  ...ExpNoteToArchiveListFilters,
  {
    type: ExpNoteListFilterType.CONTAB_DATE,
    label: 'list.filter.contabDate',
    enabled: false,
    values: [null, null]
  }
];

export const convertAdminPersistedFilterToApiFilter = (filter?: ExpNoteListAdminFilter): ExpNoteFilter => {
  const apiFilter: ExpNoteFilter = {
    searchText: null,
    startDate: null,
    contabDate: null
  };
  if (filter) {
    if (filter.searchText) {
      apiFilter.searchText = filter.searchText;
    }
    if (filter.startDate) {
      apiFilter.startDate = filter.startDate;
    }
    if (filter.contabDate) {
      apiFilter.contabDate = filter.contabDate;
    }
  }
  return apiFilter;
}

export const convertAdminApiFilterToPersistedFilter = (apiFilter: ExpNoteFilter): ExpNoteListAdminFilter => {
  const persistedFilter: ExpNoteListAdminFilter = {};
  if (apiFilter.searchText) {
    persistedFilter.searchText = apiFilter.searchText;
  }
  if (apiFilter.startDate) {
    persistedFilter.startDate = apiFilter.startDate;
  }
  if (apiFilter.contabDate) {
    persistedFilter.contabDate = apiFilter.contabDate;
  }
  return persistedFilter;
}


