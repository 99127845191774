import Box from "@mui/material/Box";
import {Alert, Button, Pagination, Paper, Skeleton, Typography} from "@mui/material";
import {PageTitle} from "../../../layout/page-title";
import React, {useEffect, useState} from "react";
import {Check} from "@mui/icons-material";
import {
  ExpNoteArchive,
  ExpNoteFilter,
  ExpNoteState,
  ExpNoteStateChangeBulkRequest, ExpNoteStateChangeSingleRequest,
  ExpNoteStateFE,
  getAdminExpNoteStatesFE, getExpNotesInProgressStates,
  getExpNoteStateFELabel,
  getExpNotesToArchiveStates,
  getExpNotesToCheckStates,
  getExpNotesToContabStates,
  getExpNotesToPayStates
} from "../../model";
import {
  accountExpNotes,
  archiveExpNotes, getExpNotesInProgress,
  getExpNotesArchived,
  getExpNotesToArchive,
  getExpNotesToCheck,
  getExpNotesToContab,
  getExpNotesToPay,
  payExpNotes
} from "../../Service";
import {ExpNoteFilters} from "./filter";
import {
  ALL_EXPNOTE_DEFAULT_PAGE_SIZE,
  ARCHIVED_EXPNOTE_PAGE_SIZE,
  convertAdminApiFilterToPersistedFilter,
  convertAdminPersistedFilterToApiFilter,
  ExpNoteListFilter,
  ExpNoteListFilterType
} from "./model";
import '../../base.scss';
import {MultipleSelection} from "../../../base/multiple-selection";
import {useErrorMessage} from "../../../../util/ErrorUtil";
import {ExpNoteBaseList} from "./BaseList";
import {DateFormat, DETAIL_URL, GenericPagedList} from "../../../model";
import {useLocation, useNavigate} from "react-router-dom";
import {getTabCounter, getTabLabel, Tab, TabBar} from "../../../base/tab-bar";
import {useExpNoteList} from "../../hooks/useExpNoteList";
import {ExpNoteListAdminFilter} from "../../../../reducers/ExpNoteList";
import {useTranslation} from "react-i18next";
import {useCurrentJobs} from "../../../../hooks/useCurrentJobs";
import {useLoggedUser} from "../../../../hooks/useLoggedUser";
import {useSnackbar, VariantType} from "notistack";
import {useLicense} from "../../../../hooks/useLicense";
import {PremiumIcon} from "../../../base/premium-icon";
import {LicensePopup} from "../../../license-popup";
import {PopupContentType} from "../../../license-popup/model";
import {countAdminExpNotes} from "../../../dashboard/Service";
import {isDigitalStorageModuleEnabled} from "../../../../config/token";

const InitApiFilter: ExpNoteFilter = {
  searchText: null,
  startDate: null,
  contabDate: null
}

export const ExpNoteAdminList = () => {

  const {t} = useTranslation('exp-note');

  const {
    adminList: {
      stateSelected,
      filter,
      sorting,
      pagination
    },
    updateAdminListType,
    updateAdminFilter,
    updateAdminPagination
  } = useExpNoteList();

  const {
    userLocale
  } = useLoggedUser();

  const {
    completedJobNotifies,
    removeNotifyCompletedJob
  } = useCurrentJobs();

  const {enqueueSnackbar} = useSnackbar();

  const {
    isProfessional
  } = useLicense();

  const professional = isProfessional();

  const [elabJobId, setElabJobId] = useState<string | null>(null);
  const [stateCounters, setStateCounters] = useState<{ state: ExpNoteStateFE, count: number }[]>([]);
  const [allExpNotes, setAllExpNotes] = useState<ExpNoteArchive[]>([]);
  const [expNotes, setExpNotes] = useState<ExpNoteArchive[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingArchivedExpNotes, setLoadingArchivedExpNotes] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [apiFilter, setApiFilter] = useState<ExpNoteFilter>(filter ? convertAdminPersistedFilterToApiFilter(filter) : InitApiFilter);

  const [totalPages, setTotalPages] = useState(1);

  const [filterChangeTO, setFilterChangeTO] = useState<any>(null);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const {convertError} = useErrorMessage();

  const {t: tLicense} = useTranslation('license', {keyPrefix: 'pro.menu'});
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  const openPopup = () => {
    setPopupOpen(true);
  }

  const closePopup = () => {
    setPopupOpen(false);
  }


  const {
    insertRunningJob
  } = useCurrentJobs();

  const isDigitalStorage = isDigitalStorageModuleEnabled();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const currentState: ExpNoteStateChangeSingleRequest = location.state as ExpNoteStateChangeSingleRequest;
      onSingleExpNoteElaboration(currentState);
    }
  }, [location])

  const fetchExpNotes = (fetchFunction: (pageSize: number, page: number) => Promise<GenericPagedList<ExpNoteArchive>>, pageSize = ALL_EXPNOTE_DEFAULT_PAGE_SIZE, page = 1, expNotes: ExpNoteArchive[] = []): Promise<ExpNoteArchive[]> => {
    return fetchFunction(pageSize, page)
      .then(res => {
        expNotes.push(...res.elements);
        if (res.totalItems === expNotes.length) {
          return expNotes;
        }
        return fetchExpNotes(fetchFunction, pageSize, res.page + 1, expNotes);
      });
  }

  const handleStateSelected = (state: string) => {
    if (!professional) {
      if (state === ExpNoteStateFE.DA_CONTABILIZZARE) {
        openPopup();
      } else {
        setAllExpNotes([]);
        setSelectedItems([]);
        // @ts-ignore
        updateAdminListType(Object.values(ExpNoteStateFE).find(e => e === state));
        setApiFilter(InitApiFilter);
        setTotalPages(1);
      }
    } else {
      setAllExpNotes([]);
      setSelectedItems([]);
      // @ts-ignore
      updateAdminListType(Object.values(ExpNoteStateFE).find(e => e === state));
      setApiFilter(InitApiFilter);
      setTotalPages(1);
    }
  }

  const fetchData = () => {
    let fetchFunction: ((pageSize: number, page: number) => Promise<GenericPagedList<ExpNoteArchive>>) | null;
    let getExpNotes: Promise<ExpNoteArchive[]> | null;
    switch (stateSelected) {
      case ExpNoteStateFE.DA_CONTROLLARE:
        fetchFunction = getExpNotesToCheck;
        break;
      case ExpNoteStateFE.DA_LIQUIDARE:
        fetchFunction = getExpNotesToPay;
        break;
      case ExpNoteStateFE.DA_CONTABILIZZARE:
        fetchFunction = getExpNotesToContab;
        break;
      case ExpNoteStateFE.DA_ARCHIVIARE:
        fetchFunction = getExpNotesToArchive;
        break;
      case ExpNoteStateFE.DA_COMPLETARE:
        fetchFunction = getExpNotesInProgress;
        break;
      default:
        fetchFunction = null;
    }

    if (fetchFunction) {
      getExpNotes = fetchExpNotes(fetchFunction);
      setLoading(true);
      getExpNotes
        .then(res => {
          setAllExpNotes(res)
        })
        .catch(err => setErrorMsg(err.message))
        .finally(() => setLoading(false));
    } else {
      setAllExpNotes([]);
    }
  }

  const updateCounters = () => {
    const toCheckStates = getExpNotesToCheckStates();
    const toPayStates = getExpNotesToPayStates();
    const toAccountStates = getExpNotesToContabStates();
    const toArchiveStates = getExpNotesToArchiveStates();
    const inProgressStates = getExpNotesInProgressStates();

    let states: ExpNoteState[] = [];
    states = states.concat(toCheckStates);
    states = states.concat(toPayStates);
    states = states.concat(toAccountStates);
    states = states.concat(toArchiveStates);
    states = states.concat(inProgressStates);

    countAdminExpNotes(states)
      .then(res => {
        let toCheckNotes = 0;
        let toPayNotes = 0;
        let toAccountNotes = 0;
        let toArchiveNotes = 0;
        let inProgressNotes = 0;
        res.forEach(r => {
          if (toCheckStates.find(s => s === r.state)) {
            toCheckNotes += r.count;
          } else if (toPayStates.find(s => s === r.state)) {
            toPayNotes += r.count;
          } else if (toAccountStates.find(s => s === r.state)) {
            toAccountNotes += r.count;
          } else if (toArchiveStates.find(s => s === r.state)) {
            toArchiveNotes += r.count;
          } else if (inProgressStates.find(s => s === r.state)) {
            inProgressNotes += r.count;
          }
        });
        setStateCounters([
          {state: ExpNoteStateFE.DA_CONTROLLARE, count: toCheckNotes},
          {state: ExpNoteStateFE.DA_LIQUIDARE, count: toPayNotes},
          {state: ExpNoteStateFE.DA_CONTABILIZZARE, count: toAccountNotes},
          {state: ExpNoteStateFE.DA_ARCHIVIARE, count: toArchiveNotes},
          {state: ExpNoteStateFE.DA_COMPLETARE, count: inProgressNotes}, // N.B: Si riferisce al tab Note Spese "In corso" (stati associati: 'DA_COMPLETARE', 'DA_APPROVARE' e 'DA_RIVEDERE')
        ]);
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    updateCounters();
  }, []);

  useEffect(() => {
    if (completedJobNotifies &&
      elabJobId) {
      const notify = completedJobNotifies.find(cj => cj.job.id === elabJobId);
      if (notify) {
        setElabJobId(null);
        removeNotifyCompletedJob(notify);
        updateCounters();
      }
    }
  }, [elabJobId, completedJobNotifies]);

  useEffect(() => {
    fetchData();
  }, [stateSelected]);

  useEffect(() => {
    if (stateSelected !== ExpNoteStateFE.ARCHIVIATE) {
      setExpNotes(allExpNotes);
    }
  }, [allExpNotes, stateSelected]);

  useEffect(() => {
    if (stateSelected === ExpNoteStateFE.ARCHIVIATE && apiFilter) {
      // console.log('call api');
      setLoadingArchivedExpNotes(true);
      getExpNotesArchived(ARCHIVED_EXPNOTE_PAGE_SIZE, pagination.currentPage, apiFilter, {
        orderBy: sorting.orderBy,
        orderDir: sorting.orderDir !== 'asc'
      })
        .then(res => {

          if (res.totalPages && pagination.currentPage > res.totalPages) {
            // se i filtri hanno ridotto il numero delle pagine
            // resetto il numero pagina ad 1, questo forza una
            // nuova ricerca e verrà mostrata la prima pagina
            updateAdminPagination({currentPage: 1});
          }

          setTotalPages(res.totalPages);
          setExpNotes(res.elements);
        })
        .finally(() => setLoadingArchivedExpNotes(false));
    }
  }, [apiFilter, pagination, sorting, stateSelected]);

  const getFilterName = (filterType: ExpNoteListFilterType): string => {
    switch (filterType) {
      case ExpNoteListFilterType.SEARCH_TEXT:
        return 'searchText';
      case ExpNoteListFilterType.START_DATE:
        return 'startDate';
      case ExpNoteListFilterType.CONTAB_DATE:
        return 'contabDate';
    }
    return '';
  }

  const getFilterValues = (filter: ExpNoteListFilter): any[] | null => {
    switch (filter.type) {
      case ExpNoteListFilterType.SEARCH_TEXT:
        return filter.values;
      case ExpNoteListFilterType.START_DATE:
      case ExpNoteListFilterType.CONTAB_DATE:
        if (filter.values) {
          return filter.values.map(v => {
            if (!v) {
              return null;
            }
            try {
              if (v.isValid()) {
                return v.format(DateFormat);
              }
            } catch (error) {
              // quando riapro la lista e imposto il filtro salvato in redux
              // la data è già formattata come stringa quindi non è disponibile
              // la funzione isValid() nè la funzione format() ma posso assumere
              // che il valore sia valido proprio perchè era già stato formattato
              // in precedenza
              return v;
            }
            return null;
          });
        }
        return null;
    }
    return null;
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    updateAdminPagination({currentPage: page});
  }

  const isFilterChanged = (prevFilter: ExpNoteFilter, newFilter: ExpNoteFilter): boolean => {
    //console.log(prevFilter, newFilter)

    let changed = false;
    Object.values(ExpNoteListFilterType).forEach(ft => {
      // @ts-ignore
      const filterName = getFilterName(ft);
      let prevValue = prevFilter[filterName];
      if (!prevValue) {
        if (ft === ExpNoteListFilterType.START_DATE || ft === ExpNoteListFilterType.CONTAB_DATE) {
          prevValue = [null, null];
        } else {
          prevValue = [''];
        }
      }

      let newValue = newFilter[filterName];
      if (!newValue) {
        if (ft === ExpNoteListFilterType.START_DATE || ft === ExpNoteListFilterType.CONTAB_DATE) {
          newValue = [null, null];
        } else {
          newValue = [''];
        }
      }

      if (ft === ExpNoteListFilterType.START_DATE || ft === ExpNoteListFilterType.CONTAB_DATE) {
        if (prevValue[0] !== newValue[0] || prevValue[1] !== newValue[1]) {
          changed = true;
        }
      } else {
        if (prevValue[0] !== newValue[0]) {
          changed = true;
        }
      }
    });
    return changed;
  }

  const handleFilterChange = (filters: ExpNoteListFilter[]) => {
    if (stateSelected === ExpNoteStateFE.ARCHIVIATE) {
      if (filters) {
        const prevApiFilter = {...apiFilter};
        const newApiFilter = {...apiFilter};
        filters
          .forEach(filter => {
            const filterName = getFilterName(filter.type);
            const filterValue = filter.enabled ? getFilterValues(filter) : null;
            if (filterValue) {
              newApiFilter[filterName] = filterValue;
            } else {
              newApiFilter[filterName] = null;
            }
          });

        if (isFilterChanged(prevApiFilter, newApiFilter)) {
          // console.log('apiFilter', prevApiFilter, newApiFilter);
          // debounce
          if (filterChangeTO) {
            clearTimeout(filterChangeTO);
          }
          setFilterChangeTO(setTimeout(() => {
            setApiFilter(newApiFilter);
            updateAdminFilter(convertAdminApiFilterToPersistedFilter(newApiFilter));
          }, 400));
        }
      }
    } else {
      let newExpNotes = allExpNotes;
      if (newExpNotes && newExpNotes.length > 0 && filters) {
        const persistedFilter: ExpNoteListAdminFilter = {};
        filters
          .filter(f => f.enabled && f.values)
          .forEach(filter => {
            persistedFilter[filter.type] = filter.values;
            switch (filter.type) {
              case ExpNoteListFilterType.SEARCH_TEXT:
                const filterValue = filter.values[0].toUpperCase();
                newExpNotes = newExpNotes.filter(e =>
                  e.description.toUpperCase().indexOf(filterValue) !== -1 ||
                  e.traveller.toUpperCase().indexOf(filterValue) !== -1);
                break;
              case ExpNoteListFilterType.START_DATE:
                newExpNotes = newExpNotes.filter(e => {
                  if (!filter.values) {
                    return true;
                  }
                  const startDate = filter.values[0];
                  const endDate = filter.values[1];

                  return (!startDate || !startDate.isValid() || startDate.isSameOrBefore(e.startDate, 'day')) &&
                    (!endDate || !endDate.isValid() || endDate.isSameOrAfter(e.endDate, 'day'))
                });
                break;
              case ExpNoteListFilterType.CONTAB_DATE:
                newExpNotes = newExpNotes.filter(e => {
                  if (!filter.values) {
                    return true;
                  }
                  const startDate = filter.values[0];
                  const endDate = filter.values[1];
                  return (!startDate || !startDate.isValid() || startDate.isSameOrBefore(e.contabDate, 'day')) &&
                    (!endDate || !endDate.isValid() || endDate.isSameOrAfter(e.contabDate, 'day'))
                });
                break;
            }
          });

        updateAdminFilter(persistedFilter);
      }
      setExpNotes(newExpNotes);
    }
  }

  const getFilterElement = (): JSX.Element | null => {
    if (loading) {
      return <Box mt={2} width={"300px"} height={"40px"}><Skeleton variant={"rectangular"} height={"100%"}/></Box>;
    }

    return (
      <ExpNoteFilters
        onFilterChange={handleFilterChange}
      />
    );
  }

  const selectRow = (id) => {
    navigate(`..${DETAIL_URL}/${id}`);
  }

  const getListElement = (): JSX.Element | null => {
    if (loading || loadingArchivedExpNotes) {
      return <Box mt={2} width={"100%"} height={"100px"}><Skeleton variant={"rectangular"} height={"100%"}/></Box>;
    }

    switch (stateSelected) {
      case ExpNoteStateFE.DA_CONTROLLARE:
        return (
          <ExpNoteBaseList
            ctaLabel={t("list.control")}
            expNotes={expNotes}
            rowClickHandler={selectRow}
          />
        )
      case ExpNoteStateFE.DA_LIQUIDARE:
        return (
          <ExpNoteBaseList
            expNotes={expNotes}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            rowClickHandler={selectRow}
            showReimb={false}
          />
        )
      case ExpNoteStateFE.DA_CONTABILIZZARE:
        return (
          <ExpNoteBaseList
            expNotes={expNotes}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            rowClickHandler={selectRow}
            showReimb={false}
            showPayDate={true}
          />
        )
      case ExpNoteStateFE.DA_ARCHIVIARE:
        return (
          <ExpNoteBaseList
            expNotes={expNotes}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            rowClickHandler={selectRow}
            showReimb={false}
            showPayDate={true}
            showContabDate={true}
          />
        )
      case ExpNoteStateFE.ARCHIVIATE:
        return (
          <ExpNoteBaseList
            expNotes={expNotes}
            sortExternal={true}
            rowClickHandler={selectRow}
            showReimb={false}
            showPayDate={true}
            showContabDate={true}
            showArchiveDate={true}
          />
        )
      case ExpNoteStateFE.DA_COMPLETARE:
        return (
          <ExpNoteBaseList
            expNotes={expNotes}
            sortExternal={true}
            rowClickHandler={selectRow}
            showReimb={false}
            showPayDate={false}
            showContabDate={false}
            showArchiveDate={false}
          />
        );
    }

    return null;
  }

  const elaborateExpNotes = async (request: ExpNoteStateChangeBulkRequest) => {
    try {
      let changeStatePromise: Promise<number> | null = null;
      let message = "";
      let variant: VariantType = "info";
      if (stateSelected === ExpNoteStateFE.DA_LIQUIDARE) {
        changeStatePromise = payExpNotes(request);
        message = t("detail.stateChange.sentToContab");
      } else if (stateSelected === ExpNoteStateFE.DA_CONTABILIZZARE) {
        changeStatePromise = accountExpNotes(request);
        message = t("detail.stateChange.sentToArchive");
      } else if (stateSelected === ExpNoteStateFE.DA_ARCHIVIARE) {
        changeStatePromise = archiveExpNotes(request);
        message = t(`detail.stateChange.${isDigitalStorage ? 'sentToDigitalStorage' : 'archived'}`);
        variant = isDigitalStorage ? "info" : "success";
      }

      if (changeStatePromise) {
        const jobId = await changeStatePromise;
        enqueueSnackbar(message,
          {
            variant,
            autoHideDuration: 3500,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top"
            }
          });
        fetchData();

        if (jobId) {
          setElabJobId(jobId.toString());
          insertRunningJob({
            id: jobId.toString(),
            timeStamp: new Date(),
            notify: true
          });
        } else {
          // se le note sono state liquidate o contabilizzate
          // allora l'aggiornamento dei contatori avviene al
          // termine del batch, se le note sono state archiviate
          // allora posso aggiornare subito (non c'è un batch)
          // fa eccezione il caso in cui sia attivo il modulo della conservazione
          // (in questo caso c'è un batch)
          updateCounters();
        }

      }
    } catch (error: any) {
      convertError(error).then(msg => setErrorMsg(msg));
    }
  }

  const onElaborateExpNotes = async () => {
    if (selectedItems) {
      const req: ExpNoteStateChangeBulkRequest = {expNotes: [], locale: userLocale};
      if (selectedItems.length > 0) {
        selectedItems.forEach(id => {
          const expNote = expNotes.find(e => e.id === id);
          if (expNote) {
            req.expNotes.push({
              expNoteId: id,
              lastUpdateNum: expNote.lastUpdNum,
            });
          }
        });
      }
      await elaborateExpNotes(req);
      setSelectedItems([]);
    }
  }

  const onSingleExpNoteElaboration = async (expNoteStateChangeRequest: ExpNoteStateChangeSingleRequest) => {
    const req: ExpNoteStateChangeBulkRequest = {
      expNotes: [expNoteStateChangeRequest],
      locale: userLocale
    }
    await elaborateExpNotes(req);
  }

  const getExpNoteCounter = (state: ExpNoteStateFE): number => {
    if (state === ExpNoteStateFE.ARCHIVIATE) {
      return 0;
    } else {
      const stateCounter = stateCounters.find(sc => sc.state === state);
      return stateCounter ? stateCounter.count : 0;
    }
  }

  const getSelectionLabel = () => {
    switch (stateSelected) {
      case ExpNoteStateFE.DA_LIQUIDARE:
        return t('list.multiple-selection.executePay');
      case ExpNoteStateFE.DA_CONTABILIZZARE:
        return t('list.multiple-selection.executeContab');
      case ExpNoteStateFE.DA_ARCHIVIARE:
        return t('list.multiple-selection.executeArch');
    }
    return '';
  }

  const getStatusTabs = (): Tab[] => {
    return getAdminExpNoteStatesFE().map(state => ({
      key: state,
      render: (isSelected) => {
        const count = getExpNoteCounter(state);
        return <>
          {count > 0 && getTabCounter(count, isSelected)}
          {getTabLabel(state === ExpNoteStateFE.DA_COMPLETARE ? t(`state.IN_CORSO`) : getExpNoteStateFELabel(state, t))}
          {state === ExpNoteStateFE.DA_CONTABILIZZARE && !professional ? <PremiumIcon ml={1}/> : null}
        </>
      }
    }));
  }

  return (
    <>
      <LicensePopup open={popupOpen} isPro={professional} closePopup={closePopup}
                    titlePro={tLicense(`${PopupContentType.EXP_NOTE_TO_ACCOUNT_TAB.toString()}.title`)}
                    type={PopupContentType.EXP_NOTE_TO_ACCOUNT_TAB}/>
      <Box
        width={"100%"}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        mx={"auto"}
      >

        {errorMsg && <Alert
          severity={"error"}
          variant={"filled"}
          sx={{mb: 4}}
          onClose={() => setErrorMsg(null)}>{errorMsg}</Alert>}


        {/* Titolo */}
        <Box flexGrow={1}>
          <PageTitle
            title={
              <Typography variant={"h3"} mr={3}>{t('list.title')}</Typography>
            }
          />
        </Box>

        {/*Liste*/}
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            mt: 2,
            px: 3.5,
            py: 2.5,
            flexGrow: 1,
            height: "100%"
          }}
        >
          {/*Barra di selezione dello stato*/}
          <TabBar
            selected={stateSelected}
            tabs={getStatusTabs()}
            onSelect={handleStateSelected}
            //notSelectableKeys={professional ? [] : [ExpNoteStateFE.DA_CONTABILIZZARE]}
          />

          {/* Filtri */}
          <Box mt={3}>
            {getFilterElement()}
          </Box>

          {/*Lista*/}
          {getListElement()}

          {stateSelected === ExpNoteStateFE.ARCHIVIATE && !loadingArchivedExpNotes && (
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Pagination
                count={totalPages}
                page={pagination.currentPage}
                onChange={handlePageChange}
                shape="rounded"/>
            </Box>
          )}
        </Paper>
      </Box>

      <MultipleSelection
        size={selectedItems.length}
        clearItems={() => setSelectedItems([])}
        ctaButton={
          <Button
            variant="contained"
            className={"cta-button-primary"}
            sx={{
              margin: {
                xs: "0 auto",
                sm: "none"
              }
            }}
            onClick={() => onElaborateExpNotes()}
          >
            <Check fontSize={"small"}/>
            <Typography ml={1} variant={"button"}>{getSelectionLabel()}</Typography>
          </Button>
        }
      />

    </>
  );
}
