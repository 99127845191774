import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";
import * as React from "react";

export const UserCreditCardPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>
          Attraverso questa funzionalità, disponibile nella versione Professional di ZTravel Smart, è possibile
          associare al dipendente una carta di credito aziendale. In questo modo potrà essere usata per registrare le
          spese che accettano la carta come metodo di pagamento, creando spese aziendali che non devono essere
          rimborsate ma che facilitano la riconciliazione con gli estratti
          conto della banca.
        </p>
      );
    default:
      return (
        <p>
          Attraverso questa funzionalità, disponibile nella versione Professional di ZTravel Smart, è possibile
          associare al dipendente una carta di credito aziendale. In questo modo potrà essere usata per registrare le
          spese che accettano la carta come metodo di pagamento, creando spese aziendali che non devono essere
          rimborsate ma che facilitano la riconciliazione con gli estratti
          conto della banca.
        </p>
      );
  }
}
