import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {FileUpload} from "../../../../base/file-upload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {ZtsTable} from "../../../../base/table";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ZtsTableColumn} from "../../../../base/table/model";
import {useErrorMessage} from "../../../../../util/ErrorUtil";
import {MimeType} from "../../../../base/file-upload/model";
import {FileUploadReq} from "../../../../import/model";
import {
  getColumnLabelByArrayIndex,
  getUploadedFileRows,
  parseCsvAndFindSep,
  uploadCreCardMovConfig,
  uploadFileCsv
} from "../../Service";
import {FileUploadResp} from "../../../../model";
import {uploadFileExcel} from "../../../../import/Service";

import { Logger } from 'react-logger-lib';
import SimpleJsLog from "../../../../../util/Logger";
import {convertBlobToBase64} from "../../../../../util/FileUtil";

const LOG: SimpleJsLog = Logger.of('ZTS.ImportConfigurerFirstTab');

interface ComponentsProps {
  file?: FileUploadResp,
  setFile: (resp: FileUploadResp) => void,
  setCsvSeparator: (separator: string) => void
}

export const ImportConfigurerFirstTab = ({file, setFile, setCsvSeparator}: ComponentsProps) => {
  const {convertError} = useErrorMessage();
  const {t} = useTranslation('cre-card-mov', {keyPrefix: 'configurer.tabs.1'});
  const formMethods = useForm();

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [uploadedFile, setUploadedFile] = useState<FileUploadResp | null>(null);

  const [columns, setColumns] = useState<ZtsTableColumn[]>([]);
  const [rows, setRows] = useState<object[]>([]);

  useEffect(() => {
    initTable().then(() => {
      if (file) {
        setUploadedFile(file);
        asyncGetAndSetRows(file, true).then();
      }
    });
  }, []);

  const buildColumn = (index: number): ZtsTableColumn => {
    const label = getColumnLabelByArrayIndex(index);
    return {
      id: index.toString(),
      label: label,
      align: 'left',
      unsortable: true,
      headerStyle: {borderStyle: '1px solid', borderColor: 'gray'},
      rowsStyle: {
        borderStyle: '1px solid',
        borderColor: 'gray'
      },
      formatter: (id, value, colName = label) => {
        return <Typography fontFamily={'monospace'} whiteSpace={'nowrap'} fontSize={12}>{value[colName]}</Typography>;
      }
    };
  }

  const initTable = async (): Promise<void> => {
    setLoading(true);
    const startingCols = 8;
    const rowsNum = 4;

    const cols = Array.from(Array(startingCols)).map((e, i) => {
      return buildColumn(i);
    });

    const indexColumn = {
      id: 'index',
      label: '',
      unsortable: true,
      rowsStyle: {borderStyle: '1px solid', borderColor: 'gray'},
      formatter: (id, value) => {
        return value.index;
      }
    };

    setColumns([indexColumn, ...cols]);

    let r: {}[] = [];

    for (let i = 0; i < rowsNum; ++i) {
      const result = {
        index: i + 1
      };

      for (let j = 0; j < startingCols; ++j) {
        result[getColumnLabelByArrayIndex(j)] = '';
      }

      r.push(result);
    }
    setRows(r);
    setLoading(false);
  }

  const isCsv = (mimeType: string): boolean => {
    return mimeType === MimeType.TXT || mimeType === MimeType.CSV;
  }

  const fileUploadedHandler = async (file: File) => {
    try {
      setLoading(true);
      setErrorMsg(null);

      if (!file) {
        LOG.error('file is null!!');
        return;
      }
      LOG.trace('file', file)

      const base64 = await convertBlobToBase64(file);
      LOG.trace('base64', base64.slice(0,80) + '....')

      const req: FileUploadReq = {
        activateOcr: false,
        diskName: file.name,
        diskSize: file.size,
        diskTime: file.lastModified,
        mimeType: file.type === MimeType.CSV ? MimeType.TXT : file.type,
        rawData: base64
      }
      LOG.trace('request', req)

      const resp: FileUploadResp = await uploadCreCardMovConfig(req);
      LOG.trace('response', resp)

      setUploadedFile(resp);
      setFile(resp);
      const firstTime = isCsv(resp.mimeType);
      await asyncGetAndSetRows(resp, firstTime);
    } catch (err) {
      LOG.error('Error uploading file', err);
      convertError(err as unknown as Error).then(msg => setErrorMsg(msg));
    } finally {
      setLoading(false);
    }
  }

  const asyncGetAndSetRows = async (resp: FileUploadResp, firstTime?: boolean): Promise<void> => {
    try {
      let remoteRows;
      if (firstTime && isCsv(resp.mimeType)) {
        const r = await parseCsvAndFindSep(resp.uploadKey);
        if (r) {
          remoteRows = r.rows;
          setCsvSeparator(r.separator);
        }
      } else {
        remoteRows = await getUploadedFileRows({
          uploadKey: resp.uploadKey,
          fileType: resp.mimeType,
          filter: {}
        });
      }

      if (remoteRows && remoteRows.length > 0) {
        const len = Object.keys(remoteRows[0]).length;
        const remoteColumns = Array.from(Array(len)).map((e, i) => {
          return buildColumn(i);
        });

        const indexColumn = {
          id: 'index',
          label: '',
          unsortable: true,
          rowsStyle: {borderStyle: '1px solid', borderColor: 'gray'},
          formatter: (id, value) => {
            return value.index;
          }
        };

        setColumns([indexColumn, ...remoteColumns]);

        const r = remoteRows.map((row, i) => {
          const result = {
            index: i + 1
          };

          row.forEach((column, idx) => {
            result[getColumnLabelByArrayIndex(idx)] = column;
          });

          return result;
        });

        setRows(r.slice(0, 4));
      }
    } catch (err: any) {
      //TODO
    }
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      height={'100%'}
    >

      <Stack
        width={'100%'}
        mb={3}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          rowGap={1}
          mb={2}
        >
          <Typography color={'#3315d6'}>{t('labels.1')}</Typography>
          <Typography>{t('labels.2')}</Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          rowGap={1}
        >
          <Typography color={'#3315d6'}>{t('labels.3')}</Typography>
          <Typography>{t('labels.4a')}</Typography>
          <>
            <Typography>{t('labels.4b')}<br/> {t('labels.4c')}</Typography>

          </>
          <Typography>{t('labels.4d')}</Typography>
        </Box>
      </Stack>
      <FormProvider {...formMethods}>
        <Stack
          width={'100%'}
          rowGap={1}
          alignItems={'center'}
        >
          <FileUpload
            excelEnabled={true}
            imageEnabled={false}
            pdfEnabled={false}
            htmlEnabled={false}
            csvEnabled={true}
            onFileUploaded={(file: File) => fileUploadedHandler(file)}
            renderElement={(errMsg) => {
              return (
                <Stack direction={'column'} alignItems="center">
                  {
                    loading ? (
                      <CircularProgress/>
                    ) : (
                      <>
                        <Stack direction={'row'} display={'flex'} width={'fit-content'}
                               sx={{backgroundColor: '#3315d6', borderRadius: '5px', cursor: 'pointer'}} px={2} py={1}
                               spacing={1}>
                          <FileUploadIcon sx={{color: 'white'}} fontSize={'small'}/>
                          <Typography color={'white'}>{t('labels.3')}</Typography>
                        </Stack>
                        {
                          (errorMsg || errMsg) &&
                          <Typography variant={"body2"} mt={2} className={"text-danger"}>
                            {errorMsg || errMsg}
                          </Typography>
                        }
                      </>
                    )
                  }
                </Stack>
              );
            }}
          />
          {
            uploadedFile &&
            <>
              <Typography>{t('labels.5', {fileName: uploadedFile.filename})}</Typography>
              <Typography>{t('labels.6', {fileExtension: isCsv(uploadedFile.mimeType) ? 'csv' : 'excel'})}</Typography>
            </>
          }
        </Stack>
      </FormProvider>
      <Typography color={'#3315d6'}>{t('labels.7')}</Typography>
      <Box
        flexShrink={0}
        className={'importPreviewTableContainer'}
        overflow={'hidden'}
        display={'flex'}
      >
        <ZtsTable columns={columns} rows={rows}/>
      </Box>
    </Box>
  );
}
