import {Box, Button, Link, Stack, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {ArrowBack} from "@mui/icons-material";
import React from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {AutocompleteZts} from "../../../base/autocomplete";
import {TextFieldZts} from "../../../base/text-field";
import {Locale} from "../../../../util/LocalizationUtil";
import {useTranslation} from "react-i18next";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../../base/autocomplete/model";
import {useSnackbar} from "notistack";

import {Logger} from 'react-logger-lib';
import Slf4jsLog from "../../../../util/Logger";
import {askForQuestion, QuestionRequest} from "../../Service";
import {useLoggedUser} from "../../../../hooks/useLoggedUser";

interface SupportFormProps {
  locale?: Locale,
  onClose: () => void
}

enum Role {
  ADMIN = 'admin',
  TRAVELLER = 'traveller',
  APPROVER = 'approver'
}

enum RequestType {
  ERROR = 'error',
  PARTICULAR_FUNC = 'particularFunction',
  NOT_FOUND_FUNC = 'notFoundFunction',
  HARD_TO_USE = 'hardToUse'
}

enum OpArea {
  PORTAL_ACCESS = 'portalAccess',
  PHOTO_OCR = 'photoOcr',
  MANAGE_USERS = 'manageUsers',
  INSERT_EXPENSE = 'insertExpense',
  PRINTS = 'prints',
  TP_AND_MAXIMUM = 'tpAndMaximum',
  ACCOUNTING_AND_VAT = 'accountingAndVat',
  OTHER_OPS = 'otherOperations'
}

interface SupportFormValues {
  role: AutocompleteGenericOption | null,
  requestType: AutocompleteGenericOption | null,
  opArea: AutocompleteGenericOption | null,
  requestSubject: string,
  applicationType: string
}

const LOG: Slf4jsLog = Logger.of('ZTS.help.SupportForm');

export const SupportForm = ({locale, onClose}: SupportFormProps) => {
  const {t} = useTranslation('help', {keyPrefix: 'support'});
  const {enqueueSnackbar} = useSnackbar();

  const {user} = useLoggedUser()

  const formMethods = useForm<SupportFormValues>({
    defaultValues: {
      role: null,
      requestType: null,
      opArea: null,
      requestSubject: '',
      applicationType: ''
    },
    mode: "onTouched"
  });

  let lang;
  let supportLink;
  switch (locale) {
    default:
      lang = 'IT';
      supportLink = 'https://help.zucchetti.it/cms/kb/soluzioni/gestione-del-personale/ztravel-smart/faq?pKBCODICE=zhelpkb';
      break;
  }

  const roleOpts = Object.values(Role).map((role, index) => {
    return createAutocompleteGenericOption(index + 1, `enum.role.${role}`, t(`enum.role.${role}`));
  });
  const requestTypeOpts = Object.values(RequestType).map((type, index) => {
    return createAutocompleteGenericOption(index + 1, `enum.request.${type}`, t(`enum.request.${type}`));
  });
  const opAreaOpts = Object.values(OpArea).map((area, index) => {
    return createAutocompleteGenericOption(index + 1, `enum.opArea.${area}`, t(`enum.opArea.${area}`));
  });

  const handleSave = () => {
    LOG.trace('handleSave');
    formMethods.handleSubmit(onFormValid)();
  }

  const onFormValid = async (data: SupportFormValues) => {
    // TODO chiamata api

    const questionRequest: QuestionRequest = {
      application: 'ZTravel Smart WEB',
      companyName: user?.tenant.description,
      user: user?.email,
      tenantId: user?.tenant.id,
      role: data.role?.desc,
      requestType: data.requestType?.desc,
      operationArea: data.opArea?.desc,
      message: data.requestSubject,
    }
    const okFlag = await askForQuestion(questionRequest);

    if (okFlag) {
      enqueueSnackbar(t('onSuccessMessage'), {
        variant: "success",
        autoHideDuration: 3500,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
      onClose();
    } else {
      enqueueSnackbar(t('onErrorMessage'), {
        variant: "error",
        autoHideDuration: 3500,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
    }



  }

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        alignContent={"center"}
        my={0.6}
      >
        {onClose && <IconButton
          disableRipple={true}
          sx={{
            flexBasis: '40px',
            justifyContent: 'flex-start'
          }}
          size={"small"}
          onClick={() => onClose()}
        >
          <ArrowBack fontSize={"small"}/>
        </IconButton>}
        <Typography variant={"h4"} mb={1} mt={0.5} noWrap={true}>{t('title')}</Typography>
      </Stack>
      <Box overflow={'auto'} height={"calc(100% - 55px)"}>
        <Box sx={{maxWidth: '100%'}} m={1.5} mb={1}>
          <Typography>{t('message1')}</Typography>
          <Link
            href={supportLink}
            target={'_blank'}>{t('label.supportLink', {locale: lang})}</Link>
          <Typography>{t('message2')}</Typography>
        </Box>
        <Box
          flexDirection={'row'}
          flexGrow={1}
          overflow={"auto"}
          sx={{cursor: "default"}}
          m={1.5}
          className={"no-drag-area"}
        >
          <Typography>{t('message3')}</Typography>
          <FormProvider {...formMethods}>
            <Typography mt={1} mb={0.5}>{t('label.roleTitle')}</Typography>
            <Controller
              name="role"
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <AutocompleteZts
                  disablePortal={true}
                  id={field.name}
                  options={roleOpts}
                  label={t('label.role')}
                  selectedValue={field.value ? field.value : null}
                  setValue={(_, value) => {
                    field.onChange(value);
                  }}
                  codeAndDesc={false}
                  errorMsg={fieldState.error?.message}
                />
              }}
            />
            <Typography mb={0.5}>{t('label.requestTypeTitle')}</Typography>
            <Controller
              name="requestType"
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <AutocompleteZts
                  disablePortal={true}
                  id={field.name}
                  options={requestTypeOpts}
                  label={t('label.requestType')}
                  selectedValue={field.value}
                  setValue={(_, value) => {
                    field.onChange(value);
                  }}
                  codeAndDesc={false}
                  errorMsg={fieldState.error?.message}
                />
              }}
            />
            <Typography mb={0.5}>{t('label.opAreaTitle')}</Typography>
            <Controller
              name="opArea"
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <AutocompleteZts
                  disablePortal={true}
                  id={field.name}
                  options={opAreaOpts}
                  label={t('label.opArea')}
                  selectedValue={field.value}
                  setValue={(_, value) => {
                    field.onChange(value);
                  }}
                  codeAndDesc={false}
                  errorMsg={fieldState.error?.message}
                />
              }}
            />
            <Typography mb={0.5}>{t('label.requestSubjectTitle')}</Typography>
            <Controller
              name="requestSubject"
              control={formMethods.control}
              render={({field, fieldState}) => {
                return (
                  <TextFieldZts
                    {...field}
                    variant="filled"
                    multiline={true}
                    minRows={3}
                    label={t('label.requestSubject')}
                    errorMsg={fieldState.error?.message}
                  />
                );
              }}
            />
            <Button fullWidth onClick={handleSave}>{t('label.send')}</Button>
          </FormProvider>
        </Box>
      </Box>
    </>
  );
}
