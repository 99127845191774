import {ExpNote, ExpNoteSortColumn, ExpNoteStateFE, getExpNoteFEState, getExpNoteStateFELabel} from "../../model";
import {ZtsTableColumn} from "../../../base/table/model";
import {Button, Stack, Typography} from "@mui/material";
import {ZtsTable} from "../../../base/table";
import React from "react";
import Box from "@mui/material/Box";
import {GreyColor} from "../../admin/list/model";
import {useExpNoteList} from "../../hooks/useExpNoteList";
import {useLoggedUser} from "../../../../hooks/useLoggedUser";
import {formatDate} from "../../../../util/DateUtil";
import {formatAmountZero} from "../../../../util/NumberUtil";
import {useTranslation} from "react-i18next";
import {Add, Rule} from "@mui/icons-material";

type ComponentProps = {
  expNotes: ExpNote[],
  ctaLabel?: string,
  showReimb?: boolean,
  showStatus?: boolean,
  sortExternal?: boolean,
  rowClickHandler?: Function,
  emptyState?: string,
  handleNewExpNoteClick?: () => void
}

export const ExpNoteBaseList = (props: ComponentProps) => {

  const {
    expNotes,
    ctaLabel,
    showReimb,
    showStatus,
    sortExternal,
    rowClickHandler,
    emptyState,
    handleNewExpNoteClick
  } = props;

  const {
    travellerList: {
      sorting,
    },
    updateTravellerSorting,
  } = useExpNoteList();

  const {userLocale: locale, companyDecimalNum: decimalNum, companyCurrencyCode} = useLoggedUser();

  const {t} = useTranslation("exp-note", {keyPrefix: "list"});
  const {t: tCommon} = useTranslation("common");
  const {t: tStatus} = useTranslation("exp-note");

  const columns: ZtsTableColumn[] = [];

  const onRowClicked = (id) => {
    if (rowClickHandler) {
      rowClickHandler(id)
    }
  }

  // colonne comuni
  columns.push(
    {id: 'id', label: '#', typography: 'caption', cellColor: GreyColor},
    {id: 'description', label: tCommon("description")},
    {id: 'startDate', label: t("start"), typography: 'caption', cellColor: GreyColor, formatter: (value) => formatDate(value, locale)},
    {id: 'endDate', label: t("end"), cellColor: GreyColor, typography: 'caption', formatter: (value) => formatDate(value, locale)},
    {id: 'total', label: t("total"), typography: 'caption', formatter: (value) => formatAmountZero(value, locale, decimalNum, companyCurrencyCode)}
  );

  if (showReimb) {
    columns.push({id: 'reimb', label: t("reimbursable"), typography: 'caption', formatter: (value) => formatAmountZero(value, locale, decimalNum, companyCurrencyCode)});
  }

  const getClassNameByFeState = (feState: ExpNoteStateFE) => {
    let className;
    switch (feState) {
      case ExpNoteStateFE.DA_CONTABILIZZARE:
        className = 'status-pill-exp-note-checked';
        break;
      case ExpNoteStateFE.DA_ARCHIVIARE:
        className = 'status-pill-exp-note-checked';
        break;
      case ExpNoteStateFE.ARCHIVIATE:
        className = 'status-pill-exp-note-checked';
        break;
      default:
        className = 'status-pill-exp-note-to-check';
        break;
    }
    return className;
  }

  if (showStatus) {
    columns.push({
      id: "status",
      label: t("status"),
      formatter: (value) => {
        const feState = getExpNoteFEState(value);
        const className = feState ? getClassNameByFeState(feState) : 'status-pill-exp-note-to-check';
        return feState ?
          <Box className={className}>
            <Rule fontSize={"small"}/>
            <Typography
              variant={"subtitle2"}
              textTransform={"uppercase"}
            >{getExpNoteStateFELabel(feState, tStatus, true)}</Typography>
          </Box>
          : value;
      }
    })
  }

  columns.push({
    id: 'cta', label: '', unsortable: true, displayOnHover: () => true, align: 'right', formatter: (r) => {
      return <Button
        variant="contained"
        className={"cta-list-button"}>
        <Typography variant={"button"}>{ctaLabel ? ctaLabel : t("show")}</Typography>
      </Button>
    }
  });

  const convertedExpNotes = expNotes.map(expNote =>
    ({
      check: expNote.id,
      id: expNote.code,
      description: expNote.description,
      startDate: expNote.startDate,
      endDate: expNote.endDate,
      total: expNote.expTotal,
      reimb: expNote.debCreStaff,
      status: expNote.state
    }));

  const onSortChanged = (orderBy: string, orderDir: 'asc' | 'desc') => {
    const sortColumn: ExpNoteSortColumn | undefined = Object.entries(ExpNoteSortColumn).find(([key, val]) => val === orderBy)?.[1];
    if (sortColumn) {
      updateTravellerSorting({orderBy: sortColumn, orderDir});
    }
  }

  return (
    <Box
      flexGrow={1}
      mb={2}
      overflow={"auto"}
    >
      {convertedExpNotes && <ZtsTable
        columns={columns}
        rows={convertedExpNotes}
        forcedSort={sorting}
        notifySortChanged={onSortChanged}
        sortExternal={sortExternal}
        rowClickHandler={rowClickHandler}
      />}
      {
        expNotes.length === 0 && emptyState &&
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "32px",
            maxWidth: "1900px"
          }}
          justifyContent={'center'}
        >
          <Stack flexDirection={'column'} rowGap={1}>
            <Typography variant={'h3'} color={'#c2c0c2'} mb={1}>{emptyState}</Typography>
            <Box alignSelf={'center'} width={'fit-content'} borderRadius={'20px'} sx={{backgroundColor: '#14c6d5', cursor: 'pointer'}}>
              <Stack flexDirection={'row'} pl={1.5} pr={1.5} pt={0.5} pb={0.5} onClick={handleNewExpNoteClick}>
                <Add fontSize={'small'} sx={{color: 'white'}}/>
                <Typography pl={0.5} pr={0.5} color={'white'}>{tStatus('edit.titleNew')}</Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>
      }
    </Box>
  );

}
