import {Controller, useFormContext} from "react-hook-form";
import {FormHelperText, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {TextFieldZts} from "../../../../../base/text-field";
import React, {useEffect} from "react";
import {MaximumExpensesFormValues} from "./model";
import {ApprovalState, ExpNoteExpenseFull} from "../../../../expense/model";
import {formatAmount, formatNumberZero} from "../../../../../../util/NumberUtil";
import {Check, Clear} from "@mui/icons-material";
import {useLoggedUser} from "../../../../../../hooks/useLoggedUser";
import {formatDate} from "../../../../../../util/DateUtil";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  expense?: ExpNoteExpenseFull,
  index: number,
  forcedApproval?: ApprovalState
}

export const SingleMaximum = ({expense, index, forcedApproval}: ComponentProps) => {

  const {control, setValue} = useFormContext<MaximumExpensesFormValues>();

  const {userLocale: locale, companyDecimalNum: decimalNum, companyCurrencyCode} = useLoggedUser();

  const {t} = useTranslation("exp-note", {keyPrefix: "detail.maximumApproval"});
  const {t: tCommon} = useTranslation("common");

  useEffect(() => {
    if (forcedApproval) {
      handleApprovalChange(null, forcedApproval);
    }
  }, [forcedApproval]);

  const onApprove = () => {
    if (expense) {
      setValue(`rows.${index}.maximum`, expense.compAmount);
      setValue(`rows.${index}.maxExcAut`, expense.compAmount - expense.origMaximum);
    }
  }

  const onReject = () => {
    if (expense) {
      setValue(`rows.${index}.maximum`, expense.origMaximum);
      setValue(`rows.${index}.maxExcAut`, 0);
    }
  }

  const handleApprovalChange = (event, value) => {
    if (value !== null) {
      setValue(`rows.${index}.approvalState`, value, {shouldValidate: true});

      if (value === ApprovalState.APPROVED) {
        onApprove();
      } else if (value === ApprovalState.REJECTED) {
        onReject();
      }
    }
  }

  return (
    <>
      {expense &&
        <>

          <Stack
            direction="row"
            alignItems="center"
          >

            <Grid
              container
              spacing={2}
              marginTop="-10px"
            >

              <Grid item xs={12} md={6}>
                <TextFieldZts
                  disabled={true}
                  label={tCommon("expenseItem")}
                  value={expense.expenseDesc}
                />
              </Grid>

              {expense.compStartDate ? (
                <>
                  <Grid item xs={12} md={3}>
                    <TextFieldZts
                      disabled={true}
                      label={tCommon("startDate")}
                      value={formatDate(expense.compStartDate, locale)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextFieldZts
                      disabled={true}
                      label={tCommon("endDate")}
                      value={formatDate(expense.compEndDate, locale)}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={3}>
                    <TextFieldZts
                      disabled={true}
                      label={tCommon("date")}
                      value={formatDate(expense.compDate, locale)}
                    />
                  </Grid>
                  <Grid item display={{xs: 'none', md: 'block'}} xs={12} md={3}></Grid>
                </>
              )}

              <Grid item xs={12} md={3}>
                <TextFieldZts
                  disabled={true}
                  align={"right"}
                  label={tCommon("amount")}
                  value={formatAmount(expense.compAmount, locale, decimalNum, companyCurrencyCode)}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextFieldZts
                  disabled={true}
                  align={"right"}
                  label={t("maximum")}
                  value={formatNumberZero(expense.origMaximum, locale, decimalNum)}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Controller
                  name={`rows.${index}.maxExcExp`}
                  control={control}
                  render={({field}) => {
                    return <TextFieldZts
                      disabled={true}
                      {...field}
                      value={formatNumberZero(field.value, locale, decimalNum)}
                      align={"right"}
                      label={t("exceeding")}
                    />
                  }}/>
              </Grid>

              <Grid item xs={12} md={3}>
                <Controller
                  name={`rows.${index}.maxExcAut`}
                  control={control}
                  render={({field}) => {
                    return <TextFieldZts
                      disabled={true}
                      {...field}
                      value={formatNumberZero(field.value, locale, decimalNum)}
                      align={"right"}
                      label={t("exceedingAuthorized")}
                    />
                  }}/>
              </Grid>

            </Grid>

            <Stack
              ml={2}
              alignItems="center"
              justifySelf={"center"}
            >
              <Typography variant="h5" sx={{mb: 0.5}}>{t("approveReject")}</Typography>
              <Controller
                name={`rows.${index}.approvalState`}
                control={control}
                render={({field, fieldState}) => {
                  return (
                    <>
                      <ToggleButtonGroup
                        orientation="horizontal"
                        className="maximum-approval"
                        {...field}
                        exclusive
                        onChange={handleApprovalChange}
                        size="small"
                      >
                        <ToggleButton
                          className="maximum-approved"
                          value={ApprovalState.APPROVED}
                        >
                          <Check fontSize="small"/>
                        </ToggleButton>
                        <ToggleButton
                          className="maximum-rejected"
                          value={ApprovalState.REJECTED}
                        >
                          <Clear fontSize="small"/>
                        </ToggleButton>
                      </ToggleButtonGroup>

                      {fieldState.error ? (
                        <FormHelperText error sx={{mt: 1}}>{fieldState.error.message}</FormHelperText>
                      ) : (
                        <FormHelperText sx={{mt: 1}}> </FormHelperText>
                      )}

                    </>
                  )
                }}
              />
            </Stack>

          </Stack>

        </>
      }
    </>
  );
}
