import {ListItemButton, Menu, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {CompleteCreCardMovDto, CreCardMovActionType, CreCardMovState} from "../model";
import RuleIcon from "@mui/icons-material/Rule";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import UndoIcon from "@mui/icons-material/Undo";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  anchorEl: Element | ((element: Element) => Element) | null,
  closeMenu: () => void,
  selectedRow: CompleteCreCardMovDto | null,
  openActionPopup: (type: CreCardMovActionType) => void,
  openInfoPopup: () => void,
  disconnectMov: () => void
}

export const ContextMenu = ({
                              anchorEl,
                              closeMenu,
                              selectedRow,
                              openActionPopup,
                              openInfoPopup,
                              disconnectMov
                            }: ComponentProps) => {

  const {t} = useTranslation('cre-card-mov');

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={anchorEl != null}
      onClose={closeMenu}
    >
      {selectedRow &&
        <Box
          onClick={(e) => {
            e.preventDefault();
            closeMenu();
          }}
        >
          {
            selectedRow.creCardMovState !== CreCardMovState.EXCLUDED && (selectedRow?.checked === undefined || selectedRow?.checked === true) &&
            <ListItemButton onClick={() => openActionPopup(CreCardMovActionType.UNCHECK_MOV)}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                columnGap={0.5}
                alignItems={'center'}
              >
                <RuleIcon fontSize={'small'}/>
                <Typography>{t('menu.confirm')}</Typography>
              </Box>
            </ListItemButton>

          }
          {
            selectedRow.creCardMovState !== CreCardMovState.EXCLUDED && selectedRow?.checked === false &&
            <ListItemButton onClick={() => openActionPopup(CreCardMovActionType.CHECK_MOV)}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                columnGap={0.5}
                alignItems={'center'}
              >
                <RuleIcon fontSize={'small'}/>
                <Typography>{t('menu.confirm')}</Typography>
              </Box>
            </ListItemButton>

          }
          {
            selectedRow.creCardMovState === CreCardMovState.CONNECTED &&
            <ListItemButton onClick={disconnectMov}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                columnGap={0.5}
                alignItems={'center'}
              >
                <LinkOffIcon fontSize={'small'} sx={{color: '#d32d66'}}/>
                <Typography>{t('menu.disconnect')}</Typography>
              </Box>
            </ListItemButton>
          }
          {
            selectedRow.creCardMovState !== CreCardMovState.EXCLUDED && (
              <ListItemButton onClick={() => openActionPopup(CreCardMovActionType.DISCARD_MOV)}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  columnGap={0.5}
                  alignItems={'center'}
                >
                  <PlaylistRemoveIcon fontSize={'small'} sx={{color: '#ffa700'}}/>
                  <Typography>{t('menu.exclude')}</Typography>
                </Box>
              </ListItemButton>
            )
          }
          {
            selectedRow.creCardMovState === CreCardMovState.EXCLUDED && !selectedRow.notValid && (
              <ListItemButton onClick={() => openActionPopup(CreCardMovActionType.UNDISCARD_MOV)}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  columnGap={0.5}
                  alignItems={'center'}
                >
                  <UndoIcon fontSize={'small'} sx={{color: '#ffa700'}}/>
                  <Typography>{t('menu.include')}</Typography>
                </Box>
              </ListItemButton>
            )
          }
          {
            <ListItemButton onClick={openInfoPopup}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                columnGap={0.5}
                alignItems={'center'}
              >
                <InfoOutlinedIcon fontSize={'small'} sx={{color: 'gray'}}/>
                <Typography>{t('menu.info')}</Typography>
              </Box>
            </ListItemButton>
          }
        </Box>
      }
    </Menu>

  );
}
