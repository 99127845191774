import {getApiUrl} from "../../config/environment";
import axios from 'axios';
import {SaveSettings, TestCoDiRequest} from "./model";
import {FileUploadReq, FileUploadResp} from "../model";
import {createError} from "../../util/ErrorUtil";

export function getSettings() {
  const apiUrl = getApiUrl();
  return axios.get(`${apiUrl}/settings`);
}

// Valida anche per la insert
export function updateSettings(newSettings: SaveSettings) {
  const apiUrl = getApiUrl();
  return axios.put(`${apiUrl}/settings`, newSettings);
}

export const uploadLogo = async (request: FileUploadReq, onUploadProgressHandler: (progressEvent: any) => void): Promise<FileUploadResp> => {
  const apiUrl = getApiUrl();
  const resp = await axios.post(`${apiUrl}/uploads/logo`, request, {onUploadProgress: onUploadProgressHandler});
  return resp.data;
}

export const getCompanyLogo = async (uuid: string): Promise<Blob> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.get<Blob>(`${apiUrl}/settings/logo?uuid=${uuid}`, {responseType: "blob"});
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const testCoDi = async (request: TestCoDiRequest): Promise<any> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/settings/digitalstorage`, request);
    return resp;
  } catch (err: any) {
    throw createError(err);
  }
}
