import moment from 'moment/moment';
import { getInputDateFormat, getInputDateTimeFormat, Locale } from './LocalizationUtil';

export const formatDate = (d: Date | null | undefined, locale: Locale) => {
  if (d === undefined || d == null) {
    return '';
  }
  return moment(d).format(getInputDateFormat(locale));
}

export const formatDateTime = (d: Date | null | undefined, locale: Locale) => {
  if (d === undefined || d == null) {
    return '';
  }

  // formatto l'orario in base al TimeZone dell'utente (utilizza Internationalization API)
  // va utilizzato quando dal BE arriva l'orario perchè FE e BE si parlano in UTC
  const timeZone = moment.tz.guess();
  const timeZoneMoment = moment(d).tz(timeZone);
  return timeZoneMoment.format(getInputDateTimeFormat(locale));
}
