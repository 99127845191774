import { FilterFunction } from './model';

// Restituisce una funziona che non fa nulla
export function identityFactory<T>(): FilterFunction<T> {
  return (args: T[]) => {
    return args;
  }
}

export function chainFactory<T>(filters: FilterFunction<T>[]): FilterFunction<T> {
  if (filters.length === 0){
    return identityFactory();
  }

  if (filters.length===1) {
    return filters[0];
  }

  // Applico tutti i filtri uno dopo l'altro
  return (args: T[]) => {
    let tmp: T[] = args;
    for(let i=0; i<filters.length; ++i) {
      if (tmp.length === 0) { return tmp; }
      let step = filters[i];
      tmp = step(tmp);
    }
    return tmp;
  }
}

