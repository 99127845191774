import {SearchBar} from "../../base/search-bar";
import Box from "@mui/material/Box";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {ListFilter, ListFilters, ListFilterType} from "../model";

type AccountFilterProps = {
  onFilterChange: Function
}

export const Filters = ({onFilterChange}: AccountFilterProps) => {
  // State
  //------
  const [filters, setFilters] = useState<ListFilter[]>(ListFilters); // State: lista di filtri

  // Use effects
  //------------
  useEffect(() => {
    onFilterChange(filters);
  }, [filters]);

  // Functions
  //----------
  const searchTextChangeHandler = (e: SyntheticEvent) => {
    let target = e.target as HTMLInputElement;

    setFilters((prevFilters: ListFilter[]) =>
      prevFilters.map((filter: ListFilter) => filter.type === ListFilterType.SEARCH_TEXT ? ({
        ...filter,
        value: target.value
      }) : filter));
  }

  // React Component
  //----------
  return (
    <Box
      flexGrow={{
        xs: 1,
        lg: 0
      }}
      flexBasis={{
        xs: "calc(100% - 60px)",
        lg: "auto"
      }}
    >
      <SearchBar
        filterText={
          filters.filter(filter => filter.type === ListFilterType.SEARCH_TEXT)[0].value
        }
        onFilterChange={searchTextChangeHandler}
      />

    </Box>
  );
}
