import { getApiUrl } from '../../config/environment';
import { default as axios } from 'axios';
import { createError } from '../../util/ErrorUtil';
import { BatchJobInfos, ExpNoteExportRequest, ExpNotePrintRequest, LinkCreCardMovRequest } from './model';
import { ReconciliationReportRequest, ReportRequest } from '../report/model';


export const launchExpNotePrint = async (request: ExpNotePrintRequest): Promise<string> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/reports/expnoteprint`, {
      expNoteId: request.expNoteId,
      locale: request.locale,
      zipWithAttach: request.zipWithAttach
    });
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const launchExpNoteExport = async (request: ExpNoteExportRequest): Promise<string> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/reports/expnoteexport`, {
      expNoteId: request.expNoteId,
      locale: request.locale,
    });
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const launchExpenseReport = async (request: ReportRequest): Promise<string> => {
  try {
    const apiUrl = getApiUrl();
    const response = await axios.post(`${apiUrl}/reports/expenses`, request);
    return response.data;
  } catch (error: any) {
    throw createError(error);
  }
}

export const launchExpNoteReport = async (request: ReportRequest): Promise<string> => {
  try {
    const apiUrl = getApiUrl();
    const response = await axios.post(`${apiUrl}/reports/expnote`, request);
    return response.data;
  } catch (error: any) {
    throw createError(error);
  }
}

export const launchReconciliationReport = async (request: ReconciliationReportRequest): Promise<string> => {
  try {
    const apiUrl = getApiUrl();
    const response = await axios.post(`${apiUrl}/creCardMov/report/reconciliation`, request);
    return response.data;
  } catch (error: any) {
    throw createError(error);
  }
}

export const linkCreCardMov = async (request: LinkCreCardMovRequest): Promise<string> => {
  try {
    const apiUrl = getApiUrl();
    const url = `${apiUrl}/creCardMov/link`;

    const resp = await axios.post(url, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getJobsStateByIdList = async (ids: string | string[] | number[]): Promise<BatchJobInfos[]> => {
  let jobsIdList = '';
  if (Array.isArray(ids)) {
    if (ids.length > 1) {
      jobsIdList += `${ids[0]}`;
      for (let i = 1; i < ids.length; i++) {
        jobsIdList += `,${ids[i]}`;
      }
    } else if (ids.length === 1) {
      jobsIdList += `${ids[0]}`;
    }
  } else {
    jobsIdList = ids;
  }
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.get(`${apiUrl}/batches/${jobsIdList}`);
    return resp.data.elements;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getAllJobsByState = async (state: string): Promise<BatchJobInfos[]> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.get(`${apiUrl}/batches/all?state=${state}`);
    return resp.data.elements;
  } catch (err: any) {
    throw createError(err);
  }
}

export const downloadJobResult = async (ids: string | string[] | number[]) => {
  let jobsIdList = '';
  if (Array.isArray(ids)) {
    if (ids.length > 1) {
      jobsIdList += `${ids[0]}`;
      for (let i = 1; i < ids.length; i++) {
        jobsIdList += `,${ids[i]}`;
      }
    } else if (ids.length === 1) {
      jobsIdList += `${ids[0]}`;
    }
  } else {
    jobsIdList = ids;
  }
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.get(`${apiUrl}/batches/download/${jobsIdList}`, {responseType: 'blob'});
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}
