// React
import React, {useState} from "react";

// TODO spostare
// https://mui.com/blog/lab-date-pickers-to-mui-x/
//https://mui.com/x/react-date-pickers/getting-started/

import moment from "moment";

// Libs
import {Logger} from 'react-logger-lib';

// Custom
import {TextFilter, TextFilterChangeEvent, TextFilterValueEvent} from "../../filters/TextFilter";
import {TravelPolicy} from "../model";
import {EnabledFilterChangeEvent, EnabledFilterValueEvent} from "../../filters/EnabledFilter";
import {CollapsibleFilterGroup} from "../../filters/CollapsibleFilterGroup";
import {
  DataRangeFilterValueEvent,
  DateRangeFilter,
  DateRangeFilterChangeEvent,
  DateRangeValue
} from "../../filters/DateRangeFilter";

import {FilterFunction, FunctionEvent} from "../../filters/model";
import {chainFactory} from "../../filters/utils";
import SimpleJsLog from "../../../util/Logger";
import {activeStateCode} from "../../model";
import {Box, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";


type FilterStepMap = Map<string, FilterFunction<TravelPolicy>>;

export type TravelPolicyFiltersProps = {
  onFilterChange: (fn: FilterFunction<TravelPolicy>) => void
}

const LOG: SimpleJsLog = Logger.of('ZTS.TravelPolicies.TravelPolicyFilters');

export const TravelPolicyFilters: React.FC<TravelPolicyFiltersProps> = ({onFilterChange}) => {
  const {t} = useTranslation('travel-policy', {keyPrefix: 'list.label'});
  const [filters] = useState<FilterStepMap>(new Map());

  const [visibleFilters, setVisibleFilters] = useState<string[]>([]);

  const hideFilter = (name: string) => {
    LOG.trace('hideFilter', name);
    // Rimuovo il filtro dalla lista
    filters.delete(name);
    // Per pulizia allineo lo stato
    switch (name) {
      case 'enabled':
        setEnabledFlag(false);
        break;
      case 'date':
        setDateRange({from: null, to: null});
        break;
    }

    // Rimuovo il filtro da quelli visibili
    let newValues: string[] = visibleFilters.filter(s => s !== name);

    // Forzo il disegno
    setVisibleFilters(newValues);

    // Notifico al padre che la funzione di filtro è cambiata
    fireFilterUpdateEvent();
  }

  const showFilter = (name: string) => {
    LOG.trace('showFilter', name);
    // Forzo lo stato per il filtro
    switch (name) {
      case 'enabled': {
        setEnabledFlag(true);
      }
    }

    const newValues: string[] = visibleFilters.slice(0);
    if (visibleFilters.indexOf(name) < 0) {
      newValues.push(name);
    }

    setVisibleFilters(newValues);
  }

  // Filtri
  const [text, setText] = useState<string>('');
  const [enabledFlag, setEnabledFlag] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRangeValue>({from: null, to: null});

  // Devo forzare il CollassableGroup a ridisegnarsi
  // TODO soluzione migliore ?
  const forceUpdate = () => {
    LOG.trace('forceUpdate');
    const newValues = visibleFilters.slice(0);
    setVisibleFilters(newValues);
  }

  const updateFilters = (event: FunctionEvent<TravelPolicy>) => {
    LOG.trace('updateFilters', event);
    filters.delete(event.name);
    if (!event.isIdentity) {
      filters.set(event.name, event.filterFn);
    }
    fireFilterUpdateEvent();
  }

  const fireFilterUpdateEvent = () => {
    LOG.trace('fireFilterUpdateEvent');
    const values: FilterFunction<TravelPolicy>[] = [];
    filters.forEach(fn => values.push(fn));
    const fullFilter: FilterFunction<TravelPolicy> = chainFactory(values);
    onFilterChange(fullFilter);
  }

  const textMapper = (tp: TravelPolicy) => {
    LOG.trace('textMapper', tp);
    return tp.code + '|' + tp.description;
  }

  const onTextChanged = (event: TextFilterChangeEvent) => {
    LOG.trace('onTextChanged', event);
    setText(event.text);
  }

  const onTextValue = (event: TextFilterValueEvent<TravelPolicy>) => {
    LOG.trace('onTextValue', event);
    updateFilters(event);
  }

  const enabledMapper = (tp: TravelPolicy): boolean => {
    LOG.trace('enabledMapper', tp);
    const m = moment(new Date());
    return tp && tp.state === activeStateCode && m.isAfter(tp.startDate) && (tp.endDate === undefined || m.isBefore(tp.endDate));
  }

  const onEnabledChange = (event: EnabledFilterChangeEvent) => {
    LOG.trace('onEnabledChange', event);
    setEnabledFlag(event.flag);
    forceUpdate();
  }

  const onEnabledValue = (event: EnabledFilterValueEvent<TravelPolicy>) => {
    LOG.trace('onEnabledValue', event);
    updateFilters(event);
  }

  const startDateMapper = (tp: TravelPolicy): Date => {
    LOG.trace('startDateMapper', tp);
    return tp.startDate;
  }

  const endDateMapper = (tp: TravelPolicy): Date | null => {
    LOG.trace('endDateMapper', tp);
    return tp.endDate || null;
  }

  const onDateRangeChange = (event: DateRangeFilterChangeEvent) => {
    LOG.trace('onDateRangeChange', event);
    setDateRange(event.value);
    forceUpdate();
  }

  const onDateRangeValue = (event: DataRangeFilterValueEvent<TravelPolicy>) => {
    LOG.trace('onDateRangeValue', event);
    updateFilters(event);
  }

  return (
    <Stack flexWrap={"wrap"}>
      <TextFilter name='text' text={text} mapperFn={textMapper} onChange={onTextChanged} onValue={onTextValue}/>

      <CollapsibleFilterGroup visible={visibleFilters} openFilter={showFilter}>
        {/*<EnabledFilter key='1' name='enabled' label='Filtra abilitate' innerLabel='Solo abilitate' flag={enabledFlag} mapperFn={enabledMapper} onChange={onEnabledChange} onValue={onEnabledValue} onClose={hideFilter}/>*/}
        <DateRangeFilter key='2' name='date' label={t('dateFilter')} innerLabel={t('date')} range={dateRange}
                         fromMapperFn={startDateMapper} toMapperFn={endDateMapper} onChange={onDateRangeChange}
                         onClose={hideFilter} onValue={onDateRangeValue}/>
      </CollapsibleFilterGroup>
    </Stack>

  );

}
