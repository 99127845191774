import { ReactElement } from 'react';
import { AccountsPopupContent } from './contents/AccountsPopupContent';
import { LevelsPopupContent } from './contents/LevelsPopupContent';
import { ProjectsPopupContent } from './contents/ProjectsPopupContent';
import { SuppliersPopupContent } from './contents/SuppliersPopupContent';
import { UserCreditCardPopupContent } from './contents/UserCreditCardPopupContent';
import { UserAddPopupContent } from './contents/UserAddPopupContent';
import { ApproverPillPopupContent } from './contents/ApproverPillPopupContent';
import { TpEnableProjectSwitchPopupContent } from './contents/TPEnableProjectSwitchPopupContent';
import * as React from 'react';
import { Locale } from '../../util/LocalizationUtil';
import {
  TPMandatoryProjectSwitchPopupContent
} from "./contents/TPMandatoryProjectSwitchPopupContent";
import {TPCostAccountPopupContent} from "./contents/TPCostAccountPopupContent";
import {TPVatManagementPopupContent} from "./contents/TPVatManagementPopupContent";
import {SettingAccountsPopupContent} from "./contents/SettingAccountsPopupContent";
import {SettingsDigitStorePopupContent} from "./contents/SettingsDigitStorePopupContent";
import {CustomReportsPopupContent} from "./contents/CustomReportsPopupContent";
import {NewExpensePopupContent} from "./contents/NewExpensePopupContent";
import {ExpNoteToAccountPopupContent} from "./contents/ExpNoteToAccountPopupContent";
import {InvoicePopupContent} from "./contents/InvoicePopupContent";
import {ProjectPillPopupContent} from "./contents/ProjectPillPopupContent";
import {NewExpensePillPopupContent} from "./contents/NewExpensePillPopupContent";
import {ModulePopupContent} from "./contents/ModulePopupContent";

export interface PopupContentProps {
  locale: string
}

export enum PopupContentType {
  DEFAULT = 'default',
  ACCOUNTS = 'accounts',
  LEVELS = 'levels',
  PROJECTS = 'projects',
  SUPPLIERS = 'suppliers',
  USER_ADD = 'user.add',
  USER_PROJECTS = 'user.projects',
  USER_CRECARD = 'user.creditCards',
  APPROVER_PILL = 'pill.approver',
  ACCOUNT_PILL = 'pill.account',
  LEVEL_PILL = 'pill.level',
  TP_COST_ACCOUNT_PILL = 'pill.travelPolicy.costAccount',
  TP_VAT_MANAGEMENT_PILL = 'pill.travelPolicy.vatManagement',
  PROJECT_PILL = 'pill.project',
  INVOICE_PILL = 'pill.invoice',
  NEW_EXPENSE_PILL = 'pill.newExpense',
  EXP_NOTE_TO_ACCOUNT_TAB = 'tab.expNote.toAccount',
  SETTINGS_ACCOUNT_TAB = 'tab.settings.account',
  SETTINGS_DIGITSTORE_TAB = 'tab.settings.digitStore',
  CUSTOM_REPORTS_TAB = 'tab.reports.custom',
  TP_ENABLE_PROJECT_SWITCH = 'switch.travelPolicy.enableProject',
  TP_MANDATORY_PROJECT_SWITCH = 'switch.travelPolicy.mandatoryProject',
  NEW_EXPENSE_BUTTON = 'button.newExpense',
  CREDIT_CARD_MODULE = 'module.creditCard',
}

export const getPopupcontent = (type: PopupContentType, locale: Locale): ReactElement => {
  switch (type) {
    case PopupContentType.ACCOUNTS:
      return <AccountsPopupContent locale={locale} />;
    case PopupContentType.LEVELS:
      return <LevelsPopupContent locale={locale} />;
    case PopupContentType.PROJECTS:
      return <ProjectsPopupContent locale={locale} />;
    case PopupContentType.SUPPLIERS:
      return <SuppliersPopupContent locale={locale} />;
    case PopupContentType.USER_PROJECTS:
      return <ProjectsPopupContent locale={locale} />;
    case PopupContentType.USER_CRECARD:
      return <UserCreditCardPopupContent locale={locale} />;
    case PopupContentType.USER_ADD:
      return <UserAddPopupContent locale={locale} />;
    case PopupContentType.APPROVER_PILL:
      return <ApproverPillPopupContent locale={locale} />;
    case PopupContentType.ACCOUNT_PILL:
      return <AccountsPopupContent locale={locale} />;
    case PopupContentType.LEVEL_PILL:
      return <LevelsPopupContent locale={locale} />;
    case PopupContentType.INVOICE_PILL:
      return <InvoicePopupContent locale={locale} />;
    case PopupContentType.PROJECT_PILL:
      return <ProjectPillPopupContent locale={locale} />;
    case PopupContentType.NEW_EXPENSE_PILL:
      return <NewExpensePillPopupContent locale={locale} />;
    case PopupContentType.EXP_NOTE_TO_ACCOUNT_TAB:
      return <ExpNoteToAccountPopupContent locale={locale} />;
    case PopupContentType.TP_ENABLE_PROJECT_SWITCH:
      return <TpEnableProjectSwitchPopupContent locale={locale} />;
    case PopupContentType.TP_MANDATORY_PROJECT_SWITCH:
      return <TPMandatoryProjectSwitchPopupContent locale={locale} />;
    case PopupContentType.TP_COST_ACCOUNT_PILL:
      return <TPCostAccountPopupContent locale={locale} />;
    case PopupContentType.TP_VAT_MANAGEMENT_PILL:
      return <TPVatManagementPopupContent locale={locale} />;
    case PopupContentType.SETTINGS_ACCOUNT_TAB:
      return <SettingAccountsPopupContent locale={locale} />;
    case PopupContentType.SETTINGS_DIGITSTORE_TAB:
      return <SettingsDigitStorePopupContent locale={locale} />;
    case PopupContentType.CUSTOM_REPORTS_TAB:
      return <CustomReportsPopupContent locale={locale} />;
    case PopupContentType.NEW_EXPENSE_BUTTON:
      return <NewExpensePopupContent locale={locale} />;
    case PopupContentType.CREDIT_CARD_MODULE:
      return <ModulePopupContent locale={locale} />;
    default:
      return <p>Default content</p>;
  }
}
