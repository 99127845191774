import { AutocompleteGenericOption } from '../base/autocomplete/model';
import * as yup from 'yup';

export type ProgramLanguage = {
  language: AutocompleteGenericOption | null;
  ackDate: Date | null;
  rating: string;
}

export type FormValues = {
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  address: string;
  city: string;
  job: AutocompleteGenericOption | null;
  languages: Array<ProgramLanguage> | null;
  assumptionDate: Date | null;
};

export const schema = yup.object({
  firstName: yup.string()
    .min(2, 'Minimo 2 caratteri')
    .max(15, 'Massimo 15 caratteri'),
  lastName: yup.string()
    .min(2, 'Minimo 2 caratteri')
    .max(35, 'Massimo 5 caratteri'),
  gender: yup.string()
    .required('Obbligatorio'),
  email: yup.string()
    .email()
    .required('Obbligatorio'),
  address: yup.string()
    .max(35, 'Massimo 5 caratteri'),
  city: yup.string()
    .max(35, 'Massimo 5 caratteri'),
  job: yup.object().shape({
    id: yup.number(),
    desc: yup.string()
  })
    .nullable()
    .required('Obbligatorio'),
  languages: yup.array().of(
    yup.object()
      .shape({
        language: yup.object().shape({
          id: yup.number(),
          desc: yup.string()
        }),
        ackDate: yup.date()
          .typeError('Invalid date')
          .required('Required')
          .nullable()
          .default(undefined),
        rating: yup.string()
          .required('Required')
          .matches(new RegExp('^\\d+(\\,\\d{1,1})?$'), 'Positive rating required')
      })
  ),
  assumptionDate: yup.date()
    .typeError('Invalid date')
    .required('Required')
    .nullable()
    .default(undefined)
});
