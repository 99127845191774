import {useDispatch} from "react-redux";
import {ConfirmDialogProps, setConfirmDialogClose, setConfirmDialogOpen} from "../reducers/ConfirmDialog";

export const useConfirm = () => {

  const dispatch = useDispatch();

  const confirm = (props: ConfirmDialogProps) => {
    dispatch(setConfirmDialogOpen(props));
  }

  const closeConfirm = () => {
    dispatch(setConfirmDialogClose());
  }

  return {
    confirm,
    closeConfirm
  }
}
