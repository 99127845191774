import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const LevelsPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile inserire i centri di costo aziendali. Una volta configurati potranno essere
          associati all&rsquo;utente nelle schermate di gestione delle anagrafiche dei collaboratori e saranno a
          disposizione per <strong>l&rsquo;export contabile relativo alle note spese di ZTravel Smart che
            verr&agrave; popolato anche dai dati di contabilit&agrave; analitica e per la creazione di report sulle
            spese di trasferta legate ai centri di costo</strong>.</p>
      );
    default:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile inserire i centri di costo aziendali. Una volta configurati potranno essere
          associati all&rsquo;utente nelle schermate di gestione delle anagrafiche dei collaboratori e saranno a
          disposizione per <strong>l&rsquo;export contabile relativo alle note spese di ZTravel Smart che
            verr&agrave; popolato anche dai dati di contabilit&agrave; analitica e per la creazione di report sulle
            spese di trasferta legate ai centri di costo</strong>.</p>
      );
  }
}
