import {Accordion, AccordionDetails, Box, Pagination, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {formatDate} from "../../../util/DateUtil";
import {confirmCreCardMov, discardCreCardMov, disconnectCreCardMov, getNonGroupedCreCardMov} from "../Service";
import {
  CompleteCreCardMovDto,
  CompleteCreCardMovRequest,
  CreCardMovActionType,
  CreCardMovState,
  ListFilter,
  ListFilterType,
  NON_GROUPED_CRE_CARD_MOV_PAGE_SIZE
} from "../model";
import {ENGLISH_GB} from "../../../util/LocalizationUtil";
import {CreCardMovInfoPopup} from "../popup/CreCardMovInfoPopup";
import {CreCardMovActionPopup} from "../popup/CreCardMovActionPopup";
import {useConfirm} from "../../../hooks/useConfirm";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {useSnackbar} from "notistack";
import {CommonList} from "./CommonList";
import {ContextMenu} from "./ContextMenu";
import {Currency} from "../../model";

interface ComponentProps {
  filters: ListFilter[],
  update: boolean,
  resetUpdate: () => void,
  currencies: Currency[]
}

export const NonGroupedList = ({filters, update, resetUpdate, currencies}: ComponentProps) => {
  const {t} = useTranslation('cre-card-mov');
  const {userLocale} = useLoggedUser();
  const {confirm} = useConfirm();
  const {convertError} = useErrorMessage();
  const {enqueueSnackbar} = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [rows, setRows] = useState<CompleteCreCardMovDto[]>([]);
  const [selectedRow, setSelectedRow] = useState<CompleteCreCardMovDto | null>(null);
  const [pagedRows, setPagedRows] = useState<CompleteCreCardMovDto[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState<boolean>(false);

  const [infoPopup, setInfoPopup] = useState<{ id: number, open: boolean }>({id: -1, open: false});
  const [actionPopupInfo, setActionPopupInfo] = useState<{
    open: boolean,
    popupType: CreCardMovActionType | null,
    checked?: boolean,
    discarded?: boolean,
    checkedNotes?: string,
    discardedNotes?: string
  }>({
    open: false,
    popupType: null
  });

  useEffect(() => {
    asynGetAndSetRows().then();
  }, [filters]);

  useEffect(() => {
    if (update) {
      asynGetAndSetRows().then(() => resetUpdate());
    }
  }, [update]);

  const asynGetAndSetRows = async (): Promise<void> => {
    setLoading(true);
    let request: CompleteCreCardMovRequest = {
      page: currentPage,
      pageSize: NON_GROUPED_CRE_CARD_MOV_PAGE_SIZE
    };
    filters.forEach(filter => {
      switch (filter.type) {
        case ListFilterType.SEARCH_TEXT:
          request.searchText = filter.value;
          request.locale = userLocale;
          break;
        case ListFilterType.STATE:
          request.state = CreCardMovState[filter.value];
          break;
        case ListFilterType.UPLOAD_DATE_FROM:
          request.uploadDateFrom = formatDate(filter.value, ENGLISH_GB);
          break;
        case ListFilterType.UPLOAD_DATE_TO:
          request.uploadDateTo = formatDate(filter.value, ENGLISH_GB);
          break;
        case ListFilterType.EXP_DATE_FROM:
          request.expDateFrom = formatDate(filter.value, ENGLISH_GB);
          break;
        case ListFilterType.EXP_DATE_TO:
          request.expDateTo = formatDate(filter.value, ENGLISH_GB);
          break;
      }
    });
    let resp;
    try {
      resp = await getNonGroupedCreCardMov(request);
    } catch (err: any) {
      convertError(createError(err))
        .then(msg => {
          setLoading(false);
          enqueueSnackbar(msg,
            {
              variant: "error",
              autoHideDuration: 1000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top"
              }
            });
        });
      return;
    }
    if (resp.totalItems === 0) {
      setRows([]);
      setPagedRows([]);
      setTotalPages(0);
      setLoading(false);
      return;
    }
    setRows(resp.elements);
    setPagedRows(resp.elements.slice(0, NON_GROUPED_CRE_CARD_MOV_PAGE_SIZE));
    setTotalPages(resp.totalPages);
    setLoading(false);
  }

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>, creCardMov: CompleteCreCardMovDto) => {
    setSelectedRow(creCardMov);
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    if (page === totalPages) {
      setPagedRows(rows.slice((page - 1) * NON_GROUPED_CRE_CARD_MOV_PAGE_SIZE));
    } else {
      setPagedRows(rows.slice((page - 1) * NON_GROUPED_CRE_CARD_MOV_PAGE_SIZE, (page - 1) * NON_GROUPED_CRE_CARD_MOV_PAGE_SIZE + NON_GROUPED_CRE_CARD_MOV_PAGE_SIZE));
    }
  }

  const openInfoPopup = async () => {
    if (selectedRow) {
      setInfoPopup({id: selectedRow.creCardId, open: true});
    }
  }

  const closeInfoPopup = () => {
    setInfoPopup({id: -1, open: false});
    setSelectedRow(null);
  }

  const openActionPopup = (type: CreCardMovActionType) => {
    if (selectedRow && selectedRow.creCardId) {
      setActionPopupInfo({
        open: true,
        popupType: type,
        checked: selectedRow.checked,
        discarded: selectedRow.discarded,
        checkedNotes: selectedRow.checkedNotes,
        discardedNotes: selectedRow.discardedNotes
      });
    }
  }

  const closeActionPopup = () => {
    setActionPopupInfo({open: false, popupType: null});
    setSelectedRow(null);
  }

  const updateList = (updatedCreCardMov: CompleteCreCardMovDto) => {
    if (updatedCreCardMov && selectedRow && selectedRow.creCardId) {
      let removeRow = false;
      const currentFilterState = filters.find(f => f.type === ListFilterType.STATE);
      if (currentFilterState?.value === 'CONNECTED' && updatedCreCardMov.creCardMovState !== CreCardMovState.CONNECTED) {
        removeRow = true;
      } else if (currentFilterState?.value === 'DISCONNECTED' && updatedCreCardMov.creCardMovState !== CreCardMovState.DISCONNECTED) {
        removeRow = true;
      } else if (currentFilterState?.value === 'EXCLUDED' && updatedCreCardMov.creCardMovState !== CreCardMovState.EXCLUDED) {
        removeRow = true;
      }
      if (removeRow) {
        setPagedRows(pagedRows.filter(row => row.creCardId !== selectedRow.creCardId));
      }
      const updatedPagedRows = pagedRows.map(row => {
        if (row.creCardId === selectedRow.creCardId) {
          return updatedCreCardMov;
        }
        return row;
      });
      setPagedRows(updatedPagedRows);
    }
  }

  const handleActionPopupActions = async (actionType: CreCardMovActionType | null, notes?: string): Promise<void> => {
    if (selectedRow && selectedRow.creCardId && actionType !== null) {
      let resp;
      switch (actionType) {
        case CreCardMovActionType.CHECK_MOV:
          resp = await confirmCreCardMov(selectedRow.creCardId, true, notes);
          if (resp) {
            updateList(resp);
          }
          return;
        case CreCardMovActionType.UNCHECK_MOV:
          resp = await confirmCreCardMov(selectedRow.creCardId, false, notes);
          if (resp) {
            updateList(resp);
          }
          return;
        case CreCardMovActionType.DISCARD_MOV:
          resp = await discardCreCardMov(selectedRow.creCardId, true, notes);
          if (resp) {
            updateList(resp);
          }
          return;
        case CreCardMovActionType.UNDISCARD_MOV:
          resp = await discardCreCardMov(selectedRow.creCardId, false, notes);
          if (resp) {
            updateList(resp);
          }
          return;
      }
    }
  }

  const disconnectMov = () => {
    confirm({
      body: (
        <Typography variant="body1" display="inline" mr={0.5}>
          {t('popup.confirmDisconnect')}
        </Typography>
      ),
      onConfirm: async () => {
        if (selectedRow && selectedRow.creCardId) {
          setLoading(true);
          try {
            const resp = await disconnectCreCardMov(selectedRow.creCardId);
            if (resp) {
              updateList(resp);
            }
          } catch (err: any) {
            convertError(createError(err))
              .then(msg => {
                enqueueSnackbar(msg,
                  {
                    variant: "error",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top"
                    }
                  });
              })
          } finally {
            setLoading(false);
          }
        }
      }
    });
  }

  const handleOpenRowDetail = (id: number) => {
    setInfoPopup({id, open: true});
  }

  return (
    <>
      <Box className="cre-card-mov-ungrouped-wrapper">
        <Accordion
          disableGutters
          expanded={true}
          className={"exp-note-expense-list-group"}
        >
          <AccordionDetails>
            <CommonList
              loadingRows={loading}
              pagedRows={pagedRows}
              handleMenuClick={(e, row) => handleMenuClick(e, row)}
              currencies={currencies}
              openRowDetail={(id: number) => handleOpenRowDetail(id)}
            />

            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              mt={1}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"/>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      <ContextMenu
        anchorEl={anchorEl}
        closeMenu={() => setAnchorEl(null)}
        selectedRow={selectedRow}
        openActionPopup={openActionPopup}
        openInfoPopup={openInfoPopup}
        disconnectMov={disconnectMov}
      />

      <CreCardMovInfoPopup open={infoPopup.open} id={infoPopup.id} handleClose={closeInfoPopup} currencies={currencies}/>

      <CreCardMovActionPopup id={selectedRow?.creCardId} info={actionPopupInfo} handleActions={handleActionPopupActions}
                             handleClose={closeActionPopup}/>

    </>
  );
}
