// React
import React, {CSSProperties} from "react";

// Libs
import { Logger } from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";


export type ExpenseImgSize = 'small' | 'medium' | 'large';

type ExpenseImgProps = {
  img?: string
  label: string
  size?: ExpenseImgSize
  style?: CSSProperties
}

export const ExpenseImg: React.FC<ExpenseImgProps> = ({
  img,
  label,
  size,
  style
}) => {

  const LOG: Slf4jsLog = Logger.of('ZTS.Expenses.ExpenseImg');

  const unknownScrPath = '/expenses/0.png';
  const srcPath = `/expenses/${img}.png`;
  const cssSuffix = size ? size : 'small';
  const imgCssClass = `exp-img-${cssSuffix}`;

  return (
    <>
      {img &&
        <img
          src={srcPath}
          className={imgCssClass}
          alt={label}
          style={style}
          loading='lazy'
          onError={({currentTarget}) => {
            LOG.trace('Missing expense image', srcPath);
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = unknownScrPath;
          }}
        />
      }
    </>
  );
}
