import {Route, Routes} from "react-router-dom";
import {ProtectedRoute} from "../base/protected-routes";
import {useHelp} from "../help/hook";
import {HOME_ADMIN_URL, HOME_TRAVELLER_URL} from "../model";
import {HelpCurrentPage} from "../../reducers/Help";
import {DashboardAdmin} from "./admin";
import {DashboardTraveller} from "./traveller";

export const Dashboard = () => {
  useHelp({
    paths: [
      {
        path: HOME_ADMIN_URL,
        page: HelpCurrentPage.HOME_ADMIN,
        exactMatch: true
      },
      {
        path: HOME_TRAVELLER_URL,
        page: HelpCurrentPage.HOME_TRAVELLER,
        exactMatch: true
      },
    ]
  });

  return (
    <Routes>
      <Route path="/admin" element={<ProtectedRoute isAdmin><DashboardAdmin/></ProtectedRoute>}/>
      <Route path="/traveller" element={<DashboardTraveller/>}/>
    </Routes>
  );
}
