import React, {useEffect, useState} from "react";
import {
  Box, Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RecommendIcon from '@mui/icons-material/Recommend';
import {useTranslation} from "react-i18next";
import {ActivityLogInfo, ActivityLogState} from "../model";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {getActivityLogs, getActivityLogsCount} from "../Service";
import {PageTitle} from "../../layout/page-title";
import {formatDateTime} from "../../../util/DateUtil";
import excel_icon from "../../../assets/images/excel_icon_for_log.png";

// interface ActivitiesRow {
//   type: JobType,
//   state: string,
//   timeStamp: Date,
//   desc: string,
// }


export function ActivitiesList() {
  const [activities, setActivities] = useState<ActivityLogInfo[]>([]);
  // const activitiesStates = Object.keys(ActivityLogState);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const PAGE_SIZE = 10;

  const {t} = useTranslation(['activity-register']);

  const headerCols = [t('tableHeader.type'), t('tableHeader.state'), t('tableHeader.date'), t('tableHeader.description'), ''];

  useEffect(() => {
    asyncGetAndSetAllActivities(currentPage);
  }, [currentPage]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  }

  const {userLocale} = useLoggedUser();

  useEffect(() => {
    asyncGetAndSetActivityLogsCount().then();
    asyncGetAndSetAllActivities().then();
  }, []);

  const asyncGetAndSetActivityLogsCount = async (): Promise<void> => {
    const count = await getActivityLogsCount();
    setTotalPages(Math.floor(count / PAGE_SIZE) + (count % PAGE_SIZE ? 1 : 0));
  }

  const asyncGetAndSetAllActivities = async (page?: number): Promise<void> => {
    setLoading(true);
    let activityLogInfos: ActivityLogInfo[] = [];
    const jobs = await getActivityLogs(page ? {pageSize: PAGE_SIZE, page: page} : {});
    if (jobs) {
      activityLogInfos.push(...jobs);
    }
    setActivities(activityLogInfos);
    setLoading(false);
  }

  // const handleOpen = (jobId: number, fileDownloadName: string) => {
  //   downloadJobResult(jobId.toString())
  //     .then(response => {
  //       const url = URL.createObjectURL(response);
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', fileDownloadName);
  //       document.body.appendChild(link);
  //       link.click();
  //       URL.revokeObjectURL(url);
  //     })
  // }

  return (
    <>
      <Box
        width={"100%"}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        mx={"auto"}
      >
        <PageTitle title={<Typography variant={"h3"} mr={3}>Ultime attività</Typography>}/>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            mt: 2,
            px: 3.5,
            py: 2.5,
            flexGrow: 1,
            height: "100%"
          }}
        >
          <Box mt={3}>

          </Box>
          <Box
            flexGrow={1}
            mb={2}
            overflow={"auto"}
          >
            <TableContainer>
              <Table size={"medium"}>
                <TableHead>
                  <TableRow>
                    {
                      headerCols.map((colName, index) => {
                        return (
                          <TableCell
                            key={index}
                            align="left"
                            valign={'middle'}
                          >
                            <Typography>{colName}</Typography>
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                </TableHead>
                {
                  !loading &&
                  activities.length > 0 &&
                  <TableBody>
                    {
                      activities.map((activity, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {
                                activity.activityTitleBundleKey === 'report.expNote.print.batchTitle' &&
                                <PrintIcon sx={{color: '#14c6d5'}}/>
                              }
                              {
                                activity.activityTitleBundleKey === 'report.expNote.export.batchTitle' &&
                                <img src={excel_icon} width={20} height={20} alt="Excel icon" />
                              }
                              {
                                activity.activityTitleBundleKey.startsWith('expNote.stateChange') &&
                                <ChangeCircleIcon sx={{color: '#14c6d5'}}/>
                              }
                              {
                                activity.activityTitleBundleKey.startsWith('report') &&
                                activity.activityTitleBundleKey !== 'report.expNote.export.batchTitle' &&
                                activity.activityTitleBundleKey !== 'report.expNote.print.batchTitle' &&
                                <SummarizeIcon sx={{color: '#14c6d5'}}/>
                              }
                              {
                                activity.activityTitleBundleKey.startsWith('import') &&
                                <FileUploadIcon sx={{color: '#14c6d5'}}/>
                              }
                              {
                                activity.activityTitleBundleKey === 'linkCreCardMov.batchTitle' &&
                                <RecommendIcon sx={{color: '#14c6d5'}}/>
                              }
                            </TableCell>
                            <TableCell>
                              {
                                ActivityLogState[activity.activityState] === ActivityLogState.CS &&
                                <CheckIcon sx={{color: '#2DD37A'}}/>
                              }
                              {
                                ActivityLogState[activity.activityState] === ActivityLogState.CW &&
                                <WarningIcon sx={{color: 'orange'}}/>
                              }
                              {
                                ActivityLogState[activity.activityState] === ActivityLogState.CE &&
                                <ErrorIcon sx={{color: 'red'}}/>
                              }
                            </TableCell>
                            <TableCell><Typography>{formatDateTime(activity.insertTime, userLocale)}</Typography></TableCell>
                            <TableCell><Typography>{t(activity.activityTitleBundleKey)}</Typography></TableCell>
                            {/*<TableCell>{ActivityLogState[activity.activityState] === ActivityLogState.CS ? <Button onClick={() => handleOpen(activity.id, activity.fileDownloadName)}>Apri</Button> : <Button>Dettagli</Button>}</TableCell>*/}
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                }
              </Table>
            </TableContainer>

          </Box>

          <Box
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              shape="rounded"/>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
