export interface FilePreview {
  name: string;
  preview: any;
  type: string;
}

export enum MimeType {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  HTML = 'text/html',
  EXCEL = 'application/vnd.ms-excel',
  EXCEL_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',
  TXT = 'text/plain'
}

export enum FileType {
  JPEG = 'JPEG',
  JPG = 'JPG',
  PNG = 'PNG',
  PDF = 'PDF',
  HTML = 'HTML',
  EXCEL = 'EXCEL',
  EXCEL_XLSX = 'EXCEL_XLSX',
  CSV = 'CSV',
  TXT = 'TXT'
}

export const getMimeType = (type: FileType): MimeType | null => {
  switch (type) {
    case FileType.JPEG:
      return MimeType.JPEG;
    case FileType.JPG:
      return MimeType.JPG;
    case FileType.PNG:
      return MimeType.PNG;
    case FileType.PDF:
      return MimeType.PDF;
    case FileType.HTML:
      return MimeType.HTML;
    case FileType.EXCEL:
      return MimeType.EXCEL;
    case FileType.EXCEL_XLSX:
      return MimeType.EXCEL_XLSX;
  }
  return null;
}

export const MAX_FILE_NUM = 1;

export const MAX_FILE_SIZE = 20 * 1000 * 1000;
