import axios from 'axios';
import { Currency, CurrencyExchange, GenericElement, GenericList } from '../components/model';
import { getApiUrl } from '../config/environment';

export const getCurrencies = async (): Promise<Currency[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<Currency>>(`${apiUrl}/currencies`);
  return resp.data.elements;
}

export const getCurrencyExchanges = async (currencyCode: string, exchangeCurrency: string, startDate: Date, endDate: Date): Promise<CurrencyExchange[]> => {
  const queryString = `?currencyCode=${currencyCode}&exchangeCurrency=${exchangeCurrency}&startDate=${startDate}&endDate=${endDate}`;
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<CurrencyExchange>>(`${apiUrl}/exchangesList${queryString}`);
  return resp.data.elements;
}

export const getCurrencyExchange = async (currencyCode: string, exchangeCurrency: string, searchDate: Date): Promise<CurrencyExchange> => {
  const queryString = `?currencyCode=${currencyCode}&exchangeCurrency=${exchangeCurrency}&searchDate=${searchDate}`;
  const apiUrl = getApiUrl();
  const resp = await axios.get<CurrencyExchange>(`${apiUrl}/exchange${queryString}`);
  return resp.data;
}
