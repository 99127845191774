import {ActivityLogInfo, ActivityLogInfoWithBatchDetail, GetActivityLogsRequest} from "./model";
import {getApiUrl} from "../../config/environment";
import {createError} from "../../util/ErrorUtil";
import axios from "axios";

export const getActivityLogs = async (request?: GetActivityLogsRequest): Promise<ActivityLogInfo[]> => {
  try {
    const apiUrl = getApiUrl();
    const baseUrl = `${apiUrl}/activitylogs/all`;
    const resp = await axios.get(baseUrl, {params: request});
    return resp.data.elements;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getActivityLogsCount = async (): Promise<number> => {
  try {
    const apiUrl = getApiUrl();
    const baseUrl = `${apiUrl}/activitylogs/all/count`;
    const resp = await axios.get(baseUrl);
    return resp.data.count;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getActivityLogsWithBatchDetail = async (request?: GetActivityLogsRequest): Promise<ActivityLogInfoWithBatchDetail[]> => {
  let axiosParams = {};
  for (const [key, value] of Object.entries(Object(request))) {
    if (value) {
      if (Array.isArray(value)) {
        let newValue = '';
        value.forEach(subValue => {
          newValue += ',' + subValue.toString();
        });
        axiosParams[key] = newValue.substring(1);
      } else if (value instanceof Date) {
        axiosParams[key] = value.toString();
      } else {
        axiosParams[key] = value;
      }
    }
  }
  try {
    const apiUrl = getApiUrl();
    const baseUrl = `${apiUrl}/activitylogs/alldetail`;
    const resp = await axios.get(baseUrl, {params: axiosParams});
    return resp.data.elements;
  } catch (err: any) {
    throw createError(err);
  }
}
