import { ExpNoteExpenseFull, PayMode } from '../expense/model';
import { ExpNoteCreCardMov, ExpNoteStateFE, ExpNoteWithStaff, getExpNoteFEState } from '../model';
import { ExpNoteListRole } from '../../../reducers/ExpNoteList';

export enum ExpNoteDetailTab {
  DASHBOARD = 'DASHBOARD',
  EXPENSES = 'EXPENSES',
  PAYMENTS = 'PAYMENTS',
  CREDIT_CARD = 'CREDIT_CARD',
  // ACCOUNTING = 'ACCOUNTING',
  // HISTORY = 'HISTORY'
}

export const getExpNoteTabLabel = (tab: ExpNoteDetailTab, t): string => {
  return t(`detail.tabs.${tab}`);
}

export function arrayGroupByProperty<T, K>(values: T[], property: string): Map<K, T[]> {
  const result = new Map<K, T[]>();
  values.reduce(
    (map: Map<K, T[]>, elm: T) => {
      const key: K = elm[property];
      const list: T[] = map.get(key) || [];
      list.push(elm);
      map.set(key, list);
      return map;
    },
    result
  );
  return result;
}

export enum ExpenseGroupingOption {
  NONE = 'none',
  TRAVEL_POLICY = 'tp',
  LOCALITY = 'locality',
  PROJECT = 'project',
  EXPENSE_TYPE = 'expenseType'
}

export enum ExpenseFilterType {
  MAXIMUM,
  CREDIT_CARD,
  ACCOUNTING
}

export interface ExpenseFilter {
  type: ExpenseFilterType,
  label: string,
  count: number,
  enabled?: boolean
}

export const isMaximumAnomaly = (expense: ExpNoteExpenseFull): boolean => {
  return expense.maxExcExp > 0;
}

export const isCreditCardAnomaly = (expense: ExpNoteExpenseFull, creCardMovs: ExpNoteCreCardMov[] | null): boolean => {
  return expense.payMode === PayMode.CARD && (!creCardMovs || !creCardMovs.find(c => c.docNum === expense.docNum));
}

export const isAccountingAnomaly = (expense: ExpNoteExpenseFull): boolean => {
  return false;
}

export const isReadonlyExpNote = (expNote: ExpNoteWithStaff | null, role: ExpNoteListRole) => {
  const isTraveller = role && role === ExpNoteListRole.TRAVELLER;
  const isAdmin = role && role === ExpNoteListRole.ADMIN;
  const isApprover = role && role === ExpNoteListRole.APPROVER;
  const expNoteState = expNote ? getExpNoteFEState(expNote.state) : null;
  return !!expNote && !!expNoteState &&
    (
      (isAdmin && expNoteState !== ExpNoteStateFE.DA_CONTROLLARE) ||
      isApprover ||
      (isTraveller && expNoteState !== ExpNoteStateFE.DA_COMPLETARE && expNoteState !== ExpNoteStateFE.DA_RIVEDERE)
    );
}
