import { StateType } from '../model';

// Vedi ExpenseCategoryEnum
// Vitto, Alloggio, Mezzi Trasporto, Auto Personale, Varie
export type ExpenseCategory = 'VT' | 'AL' | 'MT' | 'AP' | 'VR';

// Vedi ExpenseTypeEnum
// Pie di lista, Tariffa, Importo fisso
export type ExpenseType = 'PL' | 'TR' | 'IF';

// Vedi ExpenseMeasureUnitEnum
// Giorni, Kilometri, Numero
export type ExpenseMeasureUnit = 'GG' | 'KM' | 'NR';

// vedi FullExpenseResponse.java
export interface Expense {
  id: number,
  code: string,
  description: string,
  expenseIcon: string, //TODO enum!!!
  expenseCategory: ExpenseCategory,
  expenseType: ExpenseType,
  measureUnit?: ExpenseMeasureUnit | null,
  compPeriod: boolean,
  compPeriodStart: boolean,
  compPeriodEnd: boolean,
  startLabel?: string | null,
  endLabel?: string | null,
  state: StateType,
  lastUpdNum: number
}

export const getExpenseCategoryOrder = (category: ExpenseCategory): number => {
  switch (category) {
    case "AL":
      return 2;
    case "AP":
      return 4;
    case "MT":
      return 3;
    case "VR":
      return 5;
    case "VT":
      return 1;
  }
  return 0;
}

