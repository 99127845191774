import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useSelector} from "react-redux";
import {getAlertDialogState} from "../../../reducers/AlertDialog";
import {useAlert} from "../../../hooks/useAlert";
import {useTranslation} from "react-i18next";

export const AlertDialog = () => {
  const {t} = useTranslation('base', {keyPrefix: 'alert-dialog'});

  const {
    open,
    title,
    body,
    label,
    onClick,
    variant,
    dismissable
  } = useSelector(getAlertDialogState);

  const {
    closeAlert
  } = useAlert();

  const clickHandler = () => {
    if (onClick) {
      onClick();
    }
    closeAlert();
  }

  if (!open) {
    return <></>
  }

  let buttonClass = "";
  switch (variant) {
    case "delete":
      buttonClass = "cta-button-danger-small";
      break;
    default:
      buttonClass = "cta-button-primary-small";
  }



  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"xs"}
      onClose={() => dismissable ? closeAlert() : {}}
    >
      <DialogTitle>
        {title ? title : <Typography variant="h3">{t('defaultTitle')}</Typography>}
      </DialogTitle>
      <DialogContent
        dividers={false}
        sx={{
          position: "relative"
        }}
      >

        {body}

      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={buttonClass}
          onClick={clickHandler}
          autoFocus
        >
          <Typography variant={"button"}>{label ? label : t('button1')}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
