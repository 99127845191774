import {Box, CircularProgress, Divider, LinearProgress, Stack, Typography} from "@mui/material";
import {NewExpenseAttachFormValue, NewExpenseFormValues} from "../../../../expense/validation";
import IconButton from "@mui/material/IconButton";
import {MimeType} from "../../../../../base/file-upload/model";
import {Add, Clear, Image, OpenInFull, PictureAsPdf} from "@mui/icons-material";
import {ExpenseAttachmentViewer, ExpenseItemType, ExpNoteExpenseFull, MAX_ATTACH_NUM} from "../../../../expense/model";
import React, {useEffect, useState} from "react";
import {FileUpload} from "../../../../../base/file-upload";
import {ImageUtility} from "../../../../../base/file-upload/image.utility";
import {OCR_INTERVAL_TIMER, OCR_MAX_RETRY, OcrResult, OcrState} from "../../../../model";
import {FileUploadReq} from "../../../../../model";
import {
  deleteTempFile,
  downloadExpNoteAttachFile,
  downloadTempFile,
  getOcrResult,
  uploadFile
} from "../../../../Service";
import {FileViewer} from "../../../../../base/file-viewer";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useErrorMessage} from "../../../../../../util/ErrorUtil";
import {CommonDialog} from "../../../../../base/common-dialog";
import {useExpNoteDetail} from "../../../hooks/useExpNoteDetail";
import {convertBlobToBase64} from "../../../../../../util/FileUtil";

type ComponentProps = {
  expenses: ExpNoteExpenseFull[] | null,
  readonly?: boolean,
  ocrResult: OcrResult | null,
  updateOcrResultHandler: (res: OcrResult | null) => void,
  errorHandler: (errorMsg: string | null) => void
}

export const AttachmentPanel = (props: ComponentProps) => {

  const {
    expenses,
    ocrResult,
    updateOcrResultHandler,
    errorHandler,
    readonly
  } = props;

  const {expNote} = useExpNoteDetail();

  const formMethods = useFormContext<NewExpenseFormValues>();

  const {t} = useTranslation(['exp-note', 'validation', 'api-error']);

  const {convertError} = useErrorMessage();

  const [showFileUpload, setShowFileUpload] = useState(expenses == null);
  const [expenseFiles, setExpenseFiles] = useState<ExpenseAttachmentViewer[]>([]);
  const [downloadProgress, setDownloadProgress] = useState(-1);
  const [uploadProgress, setUploadProgress] = useState(-1);

  const [fullSize, setFullSize] = useState(false);

  useEffect(() => {
    if (expNote && expenses) {
      setDownloadProgress(-1);
      setUploadProgress(-1);
      setShowFileUpload(false);
      setExpenseFiles([]);

      const mainExp = expenses[0];
      if (mainExp.attachments) {
        mainExp.attachments.forEach((a, index) => {
          if (index === 0) {
            // scarico subito il primo allegato
            setDownloadProgress(0);
            downloadExpNoteAttachFile(expNote.id, a.id, downloadProgressHandler)
              .then(res => {
                setDownloadProgress(-1);
                setExpenseFiles([{file: res, id: a.id, active: true}]);
              });
          }
        });
      }

      if (!mainExp || !mainExp.attachments || mainExp.attachments.length === 0) {
        setShowFileUpload(!readonly);
      }
    }
  }, [expenses]);

  const handleShowAttachment = (attach: NewExpenseAttachFormValue) => {
    if (attach.uploadKey) {
      setShowFileUpload(false);
      setExpenseFiles(expenseFiles.map(f => ({...f, active: f.uploadKey === attach.uploadKey})));
    } else if (attach._id) {
      setShowFileUpload(false);

      const file = expenseFiles.find(f => f.id === attach._id);
      if (!file && expNote) {
        setDownloadProgress(0);
        downloadExpNoteAttachFile(expNote.id, attach._id, downloadProgressHandler)
          .then(res => {
            const newFiles = expenseFiles.map(f => ({...f, active: false}));
            newFiles.push({file: res, id: attach._id, active: true});
            setExpenseFiles(newFiles);
            setDownloadProgress(-1);
          });
      } else {
        setExpenseFiles(expenseFiles.map(f => ({...f, active: f.id === attach._id})));
      }
    }
  }

  const isAttachVisible = (attach: NewExpenseAttachFormValue): boolean => {
    let index = -1;
    if (attach.uploadKey) {
      index = expenseFiles.findIndex(f => f.uploadKey === attach.uploadKey && f.active);
    } else if (attach._id) {
      index = expenseFiles.findIndex(f => f.id === attach._id && f.active);
    }
    return index !== -1;
  }

  const uploadProgressHandler = (progressEvent: any) => {
    //console.log(progressEvent)
    if (progressEvent.lengthComputable) {
      setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    }
  }

  const downloadProgressHandler = (progressEvent: any) => {
    if (progressEvent.lengthComputable) {
      setDownloadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    }
  }

  const getCurrentAttachmentData = (): { file: Blob | undefined, isPdf: boolean } => {
    const currentFile = expenseFiles.find(f => f.active);
    let attachment;
    if (currentFile) {
      if (currentFile.uploadKey) {
        attachment = currentFile ? formMethods.getValues('attachments').find(a => a.uploadKey === currentFile.uploadKey) : null;
      } else if (currentFile.id) {
        attachment = currentFile ? formMethods.getValues('attachments').find(a => a._id === currentFile.id) : null;
      }
    }

    const isPdf = attachment && attachment.type === MimeType.PDF;

    return {
      file: currentFile?.file,
      isPdf
    }
  }

  const getFileViewer = () => {
    if (showFileUpload && downloadProgress === -1) {
      return null;
    }

    if (downloadProgress !== -1) {
      return <LinearProgress
        variant={"determinate"}
        value={downloadProgress}
      />
    }

    const attachData = getCurrentAttachmentData();

    return attachData.file ? (
      <Box className={`exp-note-attach-container ${attachData.isPdf ? "exp-note-attach-container-pdf" : ""}`}>
        <Box
          className="full-size-button"
          onClick={() => setFullSize(true)}
        >
          <OpenInFull fontSize="small"/>
        </Box>
        {!readonly &&
          <Box
            className="delete-button"
            onClick={onDeleteAttachClicked}
          >
            <Clear/>
          </Box>
        }
        <FileViewer
          file={attachData.file}
          isPdf={attachData.isPdf}
          alignCenter={true}
          fitContentHeight={!attachData.isPdf}
          zoomDisabled={true}
        />
      </Box>
    ) : null;
  }

  const getFileViewerFullSize = () => {
    if (!fullSize) {
      return <></>;
    }

    const attachData = getCurrentAttachmentData();

    return attachData.file ? (
      <CommonDialog
        show={fullSize}
        title={t('attachment-panel.title')}
        widths={[
          {breakpoint: "lg", width: "800px"}
        ]}
        onClose={() => setFullSize(false)}
        errorMsg={null}
        saving={false}
        showCta={false}
      >
        <Box className='exp-note-attach-container-full-height'>
          <FileViewer
            file={attachData.file}
            isPdf={attachData.isPdf}
            alignCenter={true}
            zoomDisabled={true}
          />
        </Box>
      </CommonDialog>
    ) : <></>;
  }

  const getFileUploadArea = () => {
    return <>
      {showFileUpload && downloadProgress === -1 && <>
        {uploadProgress === -1 ? (
          <FileUpload
            onFileUploaded={(file: File) => fileUploadedHandler(file)}
            renderElement={(errorMsg, loading) => {
              //@ts-ignore
              if (formMethods.formState.errors.attachments?.message) {
                //@ts-ignore
                errorMsg = formMethods.formState.errors.attachments?.message;
              }
              return <Box
                className={"expense-upload-container"}
                width="100%"
              >
                {loading ?
                  <CircularProgress/>
                  :
                  <>
                    <Typography variant={"body2"} textAlign="center">{t('expense.edit.dragDrop')}</Typography>
                    {errorMsg && <Typography variant={"body2"} mt={2} className={"text-danger"}>
                      {errorMsg}
                    </Typography>}
                  </>
                }
              </Box>
            }}
          />
        ) : (
          <LinearProgress
            variant={"determinate"}
            value={uploadProgress}
          />
        )}
      </>}
    </>
  }

  const isActivateOcr = (fileType: string): boolean => {
    const expItem = formMethods.getValues('expenseItem');
    const movement = formMethods.getValues('creCardMov');
    return !expenses &&
      !movement &&
      (!ocrResult || !ocrResult.stateCode || ocrResult.stateCode !== OcrState.SUCCESS) &&
      (!expItem || expItem.type === ExpenseItemType.PIE_DI_LISTA) &&
      fileType !== MimeType.PDF;
  }

  const fileUploadedHandler = (file: File) => {
    errorHandler(null);

    if (!file) {
      return;
    }

    new Promise<Blob | null>((res) => {
      setUploadProgress(0);
      if (file.type === MimeType.PDF) {
        res(file);
      } else {
        res(ImageUtility.resizeImage({file, maxSize: 4096}));
      }
    })
      .then(res => {
        if (res) {
          return convertBlobToBase64(res);
        }
        throw new Error('Resize error');
      })
      .then(raw => {
        if (!raw) {
          throw new Error('Conversion error');
        }

        const activateOcr = isActivateOcr(file.type);
        const req: FileUploadReq = {
          activateOcr,
          diskName: file.name,
          diskSize: file.size,
          diskTime: file.lastModified,
          mimeType: activateOcr ? 'image/jpeg' : file.type,  // resizeImage converte sempre in jpeg??
          rawData: raw
        }
        return uploadFile(req, uploadProgressHandler)
      })
      .then(fileUploadResp => {
        setDownloadProgress(0);
        const uploadKey = fileUploadResp.uploadKey;
        if (!formMethods.getValues('attachments')) {
          formMethods.setValue('attachments', []);
        }
        formMethods.setValue('attachments', [...formMethods.getValues('attachments'), ({
          uploadKey,
          name: fileUploadResp.filename,
          type: fileUploadResp.mimeType
        })], {shouldValidate: true})

        if (isActivateOcr(fileUploadResp.mimeType)) {
          fetchOcr(uploadKey);
        }
        return downloadTempFile(uploadKey, downloadProgressHandler)
      })
      .then(res => {
        setExpenseFiles([...expenseFiles, {
          file: res.file,
          uploadKey: res.uploadKey,
          active: true
        }]);
        setShowFileUpload(false);
        setUploadProgress(-1);
        setDownloadProgress(-1);
      })
      .catch(err => {
        convertError(err).then(msg => errorHandler(msg));

        onUploadError();
        //console.log('Upload error: ', err)
      });
  }

  const onUploadError = () => {
    setUploadProgress(-1);
    setDownloadProgress(-1);
  }

  let ocrFetchCount = 0;

  const evaluateOcrResult = (res: OcrResult, ocrIntervalId) => {
    switch (res.stateCode) {
      case OcrState.IN_PROGRESS:
        ocrFetchCount++;
        if (ocrFetchCount === OCR_MAX_RETRY && ocrIntervalId) {
          //console.log(`OCR max retry (${OCR_MAX_RETRY}) reached. Interrupting...`);
          clearInterval(ocrIntervalId);
        }
        break;
      case OcrState.SUCCESS:
        updateOcrResultHandler(res);
        if (ocrIntervalId) {
          clearInterval(ocrIntervalId);
        }
        break;
      case OcrState.FAILURE:
        if (ocrIntervalId) {
          //console.log(`OCR failed. Interrupting...`);
          clearInterval(ocrIntervalId);
        }
        break;
    }
  }

  const fetchOcr = (uploadKey: string) => {
    ocrFetchCount = 0;
    getOcrResult(uploadKey)
      .then(res => {
        evaluateOcrResult(res, null);
        if (OcrState.IN_PROGRESS === res.stateCode) {
          const ocrIntervalId = setInterval(() => {
            getOcrResult(uploadKey)
              .then(res => evaluateOcrResult(res, ocrIntervalId))
              .catch(err => {
                console.log('Fetch OCR error: ', err);
                clearInterval(ocrIntervalId);
              })
          }, OCR_INTERVAL_TIMER);
        }
      })
      .catch(err => console.log('Fetch OCR error: ', err));
  }

  const handleShowUpload = () => {
    setShowFileUpload(true);
    setExpenseFiles(expenseFiles.map(f => ({...f, active: false})));
  }

  const onDeleteAttachClicked = () => {
    const currentFile = expenseFiles.find(f => f.active);
    if (currentFile) {
      const uploadKey = currentFile.uploadKey;
      if (uploadKey) {
        deleteTempFile(uploadKey)
          .then(() => {
            const newExpenseFiles = expenseFiles.filter(e => e.uploadKey !== uploadKey);

            const newAttachments = formMethods.getValues('attachments').filter(a => !a.uploadKey || a.uploadKey !== uploadKey);
            formMethods.setValue('attachments', newAttachments);

            if (newAttachments.length === 0) {
              setShowFileUpload(!readonly);
              setExpenseFiles(newExpenseFiles);
              updateOcrResultHandler(null);
            } else {
              setExpenseFiles(newExpenseFiles.map((e, idx) => ({...e, active: idx === 0})));
            }
          });
      } else if (currentFile.id) {
        const newAttachments = formMethods.getValues('attachments').filter(a => a._id !== currentFile.id);
        formMethods.setValue('attachments', newAttachments);
        const newExpenseFiles = expenseFiles.filter(f => !f.id || f.id !== currentFile.id);
        setExpenseFiles(newExpenseFiles.map((e, idx) => ({...e, active: idx === 0})));
        if (newAttachments.length === 0) {
          setShowFileUpload(!readonly);
        }
      }
      setFullSize(false);
    }
  }

  const showAttachmentButtons = () => {
    const attachments = formMethods.getValues('attachments');
    if (readonly && (!attachments || attachments.length <= 1)) {
      return false;
    }

    return attachments && attachments.length > 0;
  }

  return (
    <>
      {showAttachmentButtons() &&
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem/>}
          spacing={2}
          mb={2}
        >
          {formMethods.getValues('attachments').map((att: NewExpenseAttachFormValue, index: number) => (
            <IconButton
              key={index}
              size={"small"}
              color={!showFileUpload && isAttachVisible(att) ? "primary" : "default"}
              onClick={() => handleShowAttachment(att)}
            >
              {att.type === MimeType.PDF ? <PictureAsPdf/> : <Image/>}
            </IconButton>
          ))}
          {!readonly && !showFileUpload && formMethods.getValues('attachments').length < MAX_ATTACH_NUM && <IconButton
            size={"small"}
            color={showFileUpload ? "primary" : "default"}
            onClick={handleShowUpload}
          >
            <Add/>
          </IconButton>}
        </Stack>}

      {getFileViewer()}

      {getFileUploadArea()}

      {getFileViewerFullSize()}

    </>
  );
}
