import Box from "@mui/material/Box";

export const AppLoading = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      textAlign={"center"}
      py={3}
      fontSize={"larger"}
      fontWeight={"bold"}
      height={"60vh"}
    >
      <Box
        width={"50%"}
        mx={"auto"}
      >
        Please wait while we load application
      </Box>
    </Box>
  );
}
