import {Project} from "../model";
import {Button, CardContent, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {MoreVert} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {formatDate} from "../../../util/DateUtil";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {useTranslation} from "react-i18next";

type ProjectCardContentProps = {
  project: Project,
  handleMenuClick: Function,
  onOpen: Function,
  onRestore: Function
}

type ComponentProps = {
  props: ProjectCardContentProps
}

export const ProjectCardContent = ({props}: ComponentProps) => {
  const {t} = useTranslation('project', {keyPrefix: 'list.card'});
  const theme = useTheme();
  {/*@ts-ignore*/}
  const sub1Color = theme.palette.subtitle1.main;
  {/*@ts-ignore*/}
  const dateLabelColor = theme.palette.h6.main;

  const {userLocale} = useLoggedUser();

  return (
    <CardContent sx={{position: 'relative'}}>
      <Stack
        direction="row"
        overflow={"hidden"}
        spacing={2}
        width={"100%"}
      >
        <Box pt={1} overflow={"hidden"}>
          <Typography
            variant={"h5"}
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
          >
            {props.project.code} {props.project.description}
          </Typography>
          <Box
            mt={1}
            width={"fit-content"}
            className={'status-pill-success'}
            borderRadius={1}
          >
            {
              props.project.state === 'EF' ?
                (
                  <Typography variant={"subtitle2"} m={0.5} padding={0.5}>{props.project.description}</Typography>
                ) : (
                  <Typography variant={"subtitle2"}></Typography>
                )
            }
          </Box>

        </Box>

      </Stack>
      <Box
        style={{
          top: '5px',
          right: '5px',
          position: 'absolute'
        }}
      >
        {
          props.project.state === 'EF' ?
            (
              <Box
                onClick={(e) => props.handleMenuClick(props.project.id, e)}
                textAlign={"center"}
                mt={1}
                mr={1}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <MoreVert cursor={"pointer"}/>
              </Box>
            ) : (
              <></>
            )
        }

      </Box>

      <Stack mt={3} direction='row' overflow='hidden' spacing={2} width='100%'>

        <Box>
          <Typography variant='h6' textTransform='uppercase' style={{color: dateLabelColor}}>
            {t("startDate")}
          </Typography>
          <Typography
            mt={0.5}
            variant={"body1"}
            overflow={"hidden"}
            maxHeight={"22px"}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
          >
            {formatDate(props.project.startDate, userLocale)}
          </Typography>
        </Box>

        <Box>
          <Typography variant='h6' textTransform='uppercase' style={{color: dateLabelColor}}>
            {t("endDate")}
          </Typography>
          <Typography
            mt={0.5}
            variant={"body1"}
            overflow={"hidden"}
            maxHeight={"22px"}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
          >
            {formatDate(props.project.endDate, userLocale)}
          </Typography>
        </Box>

      </Stack>

      {
        props.project.state === 'EF' ?
          (
            <Box
              style={{
                right: '15px',
                bottom: '15px',
                position: 'absolute'
              }}
            >
              <Button
                variant="contained"
                className="card-open-button"
                onClick={() => props.onOpen(props.project.id)}>
                <Typography variant={"button"}>{t('button.open')}</Typography>
              </Button>
            </Box>
          ) : (
            <Box
              style={{
                right: '15px',
                bottom: '15px',
                position: 'absolute'
              }}
            >
              <Button
                variant="contained"
                className="card-open-button"
                onClick={() => {
                  console.log('clicked')
                  props.onRestore(props.project.id);
                }}>
                <Typography variant={"button"}>{t('button.restore')}</Typography>
              </Button>
            </Box>
          )
      }

    </CardContent>
  );
}
