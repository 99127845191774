// React
import {useDispatch, useSelector} from "react-redux";

// Zucchetti
import { isProfessionalLicense, setPersonalLicense, setProfessionalLicense } from '../reducers/License';

export const useLicense = () => {

  const dispatch = useDispatch();

  //Riga equivalente: const flag = useSelector((state)=>isProfessionalLicense(state));
  const flag = useSelector(isProfessionalLicense);


  const isProfessional = () => {
    return flag;
  }

  const setProfessional = () => {
    dispatch(setProfessionalLicense())
  }

  const clearProfessional = () => {
    dispatch(setPersonalLicense())
  }

  return {
    isProfessional,
    setProfessional,
    clearProfessional
  }

}
