// React
import React from "react";

// MUI
import {Card, CardContent, Stack, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";

// Libs
import {useTranslation} from "react-i18next";


// Custom

type TravelPolicyNewExpenseCardProps = {
  onAction: () => void
}

export const TravelPolicyNewExpenseCard: React.FC<TravelPolicyNewExpenseCardProps> = ({onAction}) => {

  const {t} = useTranslation(['travel-policy'], {keyPrefix: 'common.label'});

  return (
    <Card
      className='tp-exp-card tp-exp-card-new'
      sx={{ cursor: 'pointer' }}
      onClick={() =>{onAction()}}
    >
      <CardContent sx={{position: 'relative'}}>
        <Stack direction='row' style={{justifyContent:'center', alignItems:'center', height:'100%'}}>
          <Add fontSize='small'/>
          &nbsp;
          <Typography variant='body2' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'  className='tp-exp-title'>
            {t('enableExpense')}
          </Typography>
        </Stack>
      </CardContent>

    </Card>
  );

}
