import {PageTitle} from "../layout/page-title";
import {Box, Stack, Typography} from "@mui/material";
import {TabBar} from "../base/tab-bar";
import FavoriteIcon from '@mui/icons-material/Favorite';
import PeopleIcon from '@mui/icons-material/People';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import {MenuNoteSpese} from "../base/svg-icons";
import React, {useEffect, useState} from "react";
import {Article} from "@mui/icons-material";
import {PremiumIcon} from "../base/premium-icon";
import {ReportPreferences} from "./tabs/Preferences";
import {ReportExpenses} from "./tabs/Expenses";
import {ReportExpNotes} from "./tabs/ExpNotes";
import {ReportUsers} from "./tabs/Users";
import {ReportCustom} from "./tabs/Custom";
import {getAllPreferences} from "./Service";
import {useLicense} from "../../hooks/useLicense";
import {PopupContentType} from "../license-popup/model";
import {LicensePopup} from "../license-popup";
import {useTranslation} from "react-i18next";

export function Report() {
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>('');

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const {t: tLicense} = useTranslation('license', {keyPrefix: 'pro.menu'});
  const {t: tReport} = useTranslation('report');
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  const openPopup = () => {
    setPopupOpen(true);
  }

  const closePopup = () => {
    setPopupOpen(false);
  }

  const handleTabChange = (key: string): void => {
    if (free) {
      if (key === 'custom') {
        openPopup();
      } else {
        setCurrentTab(key);
      }
    } else {
      setCurrentTab(key);
    }
  }

  const asyncCheckPreferences = async (): Promise<void> => {
    const reports = await getAllPreferences();
    if (reports && reports.length > 0) {
      setCurrentTab("preferences");
    } else {
      setCurrentTab("expenses");
    }
  }

  useEffect(() => {
    setLoading(true);
    asyncCheckPreferences().then();
    setLoading(false);
  }, []);

  return (
    <>
      {
        !loading &&
        <>
          <LicensePopup open={popupOpen} isPro={!free} closePopup={closePopup} titlePro={tLicense(`${PopupContentType.CUSTOM_REPORTS_TAB.toString()}.title`)} type={PopupContentType.CUSTOM_REPORTS_TAB} />
          <PageTitle title={<Typography variant={"h3"}>{tReport('title')}</Typography>}/>
          <Box mt={1.5}>
            <TabBar
              extraClassName={"report"}
              selected={currentTab}
              tabs={[
                {
                  key: "preferences", render: () => {
                    return (
                      <Stack
                        id={"setting-comp-data"}
                        direction={"row"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <FavoriteIcon fontSize={"small"}/>
                        <Typography variant={"h5"}>{tReport('preferenceTabTitle')}</Typography>
                      </Stack>
                    )
                  }
                },
                {
                  key: "expenses", render: () => {
                    return (
                      <Stack
                        id={"setting-acc"}
                        direction={"row"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        spacing={1}>
                        <Article fontSize={"small"}/>
                        <Typography variant={"h5"}>{tReport('expenseTabTitle')}</Typography>
                      </Stack>
                    )
                  }
                },
                {
                  key: "expNotes", render: () => {
                    return (
                      <Stack
                        direction={"row"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <MenuNoteSpese fontSize={"small"}/>
                        <Typography variant={"h5"}>{tReport('expNoteTabTitle')}</Typography>
                      </Stack>
                    )
                  }
                },
                {
                  key: "users", render: () => {
                    return (
                      <Stack
                        direction={"row"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        spacing={1}>
                        <PeopleIcon fontSize={"small"}/>
                        <Typography variant={"h5"}>{tReport('userTabTitle')}</Typography>
                      </Stack>
                    )
                  }
                },
                {
                  key: "custom", render: () => {
                    return (
                      <Stack
                        direction={"row"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        spacing={1}>
                        <DashboardCustomizeIcon fontSize={"small"}/>
                        <Typography variant={"h5"}>{tReport('customTabTitle')}</Typography>
                        {
                          free &&
                          <PremiumIcon />
                        }
                      </Stack>
                    )
                  }
                }
              ]}
              onSelect={handleTabChange}
            />
          </Box>
          <Typography mb={3} ml={1} mt={3.5} variant={"h5"}>{tReport('message1')}</Typography>
          <Box flexGrow={1} overflow={"auto"}>
            {
              currentTab === 'preferences' &&
              <ReportPreferences/>
            }
            {
              currentTab === 'expenses' &&
              <ReportExpenses/>
            }
            {
              currentTab === 'expNotes' &&
              <ReportExpNotes/>
            }
            {
              currentTab === 'users' &&
              <ReportUsers/>
            }
            {
              currentTab === 'custom' &&
              <ReportCustom/>
            }
          </Box>
        </>
      }
    </>
  );
}
