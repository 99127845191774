import React from 'react';

import {
  AccountBalanceWallet, AssignmentInd, Business,
  ConfirmationNumber, CreditCard,
  Description,
  NotInterested,
  Receipt,
  Explore,
  ExploreOff
} from '@mui/icons-material';
import {Icon} from "@mui/material";



const GreyColor = '#7991AA';

export const getReceiptIcon = (): JSX.Element => {
  return <Receipt fontSize={"small"} sx={{color: GreyColor}}/>
}

export const getInvoiceIcon = (): JSX.Element => {
  return <Description fontSize={"small"} sx={{color: GreyColor}}/>
}

export const getTicketIcon = (): JSX.Element => {
  return <ConfirmationNumber fontSize={"small"} sx={{color: GreyColor}}/>
}

export const getNoDocumentIcon = (): JSX.Element => {
  return <NotInterested fontSize={"small"} sx={{color: GreyColor}}/>
}

export const getPayByTravelerIcon = (): JSX.Element => {
  return <AccountBalanceWallet fontSize={"small"} sx={{color: GreyColor}}/>
}

export const getPayByCardIcon = (): JSX.Element => {
  return <CreditCard fontSize={"small"} sx={{color: GreyColor}}/>
}

export const getPayByCompanyIcon = (): JSX.Element => {
  return <Business fontSize={"small"} sx={{color: GreyColor}}/>
}

export const getColleagueGuestIcon = (): JSX.Element => {
  return <AssignmentInd fontSize={"small"} sx={{color: GreyColor}}/>
}

export const getRouteIcon = (): JSX.Element => {
  //return <Explore fontSize={"small"} sx={{color: GreyColor}}/>

  return <Icon>
    <img className="google-maps-icon" src="/expenses/google-maps.svg"/>
  </Icon>
}

export const getNoRouteIcon = (): JSX.Element => {
  return <ExploreOff fontSize={"small"} sx={{color: GreyColor}}/>
}
