import {Grid, Paper} from "@mui/material";
import React, {useEffect, useState} from "react";
import {isAdminOnboardingActive} from "../../../model";
import {useLoggedUser} from "../../../../../hooks/useLoggedUser";
import {DashboardOnboardingCommon} from "../common";

export const DashboardOnboardingPro = () => {

  const {
    user
  } = useLoggedUser();

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setShow(isAdminOnboardingActive(user.tenant));
    } else {
      setShow(false);
    }
  }, [user]);

  return (
    <>
      {show &&
        <Grid item xs={12}>
          <Paper elevation={0} className="onboarding">
            <DashboardOnboardingCommon
              dismissHandler={() => setShow(false)}
            />
          </Paper>
        </Grid>
      }
    </>
  );
}
