import { default as axios } from 'axios';
import { getApiUrl } from '../../config/environment';
import SimpleJsLog from '../../util/Logger';
import { Logger } from 'react-logger-lib';

const LOG: SimpleJsLog = Logger.of('ZTS.Login.Service');

export const updateLastAccess = async () => {
  try {
    await axios.post(`${getApiUrl()}/user/lastaccessdesktop`, {});
  } catch (err) {
    LOG.trace('error updating last access', err);
  }
}
