import {Button, CardContent, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {Level} from "../model";
import {MoreVert} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  level: Level,
  handleMenuClick: Function,
  onOpen: Function,
  onRestore: Function
}

export const LevelCardContent = ({level, handleMenuClick, onOpen, onRestore}: ComponentProps) => {
  const theme = useTheme();
  const {t} = useTranslation('level', {keyPrefix: 'list.card'});

  return (
    <CardContent sx={{position: 'relative'}}>
      <Stack
        direction="column"
        overflow={"hidden"}
        spacing={2}
      >
        <Stack spacing={0.5} overflow={"hidden"}>
          {/*@ts-ignore*/}
          <Typography variant={"h6"} textTransform={"uppercase"} style={{color: theme.palette.h6.main}}>
            {t('code')}
          </Typography>
          <Typography
            variant={"body1"}
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
          >
            {level.code}
          </Typography>
        </Stack>
        <Stack direction={"row"}>
          <Stack spacing={0.5} overflow={"hidden"} flexGrow={1}>
            {/*@ts-ignore*/}
            <Typography variant={"h6"} textTransform={"uppercase"} style={{color: theme.palette.h6.main}}>
              {t('description')}
            </Typography>
            <Typography
              variant={"body1"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
            >
              {level.description}
            </Typography>
          </Stack>
          {
            level.state === 'EF' ?
              (
                <Button
                  variant="contained"
                  className="card-open-button"
                  onClick={() => onOpen(level.id)}>
                  <Typography variant={"button"}>{t('button.open')}</Typography>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="card-open-button"
                  onClick={() => onRestore(level.id)}>
                  <Typography variant={"button"}>{t('button.restore')}</Typography>
                </Button>
              )
          }
        </Stack>
      </Stack>
      <Box
        style={{
          top: '5px',
          right: '5px',
          position: 'absolute'
        }}
      >
        {
          level.state === 'EF' ?
            (
              <Box
                onClick={(e) => handleMenuClick(level.id, e)}
                textAlign={"center"}
                mt={1}
                mr={1}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <MoreVert cursor={"pointer"}/>
              </Box>
            ) : (
              <></>
            )
        }
      </Box>
    </CardContent>
  );
}
