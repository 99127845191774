import React from 'react';

export enum ReportCode {
  SP001 = 'SP001',
  SP002 = 'SP002',
  NS001 = 'NS001'
}

// Tenere allineato a report.json -> {parameter: period: {yearToDate: "Da inizio anno", ...}}
export enum PeriodParam {
  DA_INIZIO_ANNO = 'yearToDate',
  ULTIMI_6_MESI = 'lastSixMonths',
  ULTIMO_ANNO = 'lastYear',
  PERSONALIZZATO = 'custom'
}

export enum ReportType {
  SP = 'SP',
  NS = 'NS',
  RC = 'RC'
}

export type ExpenseReportCardProps = {
  reportId: number,
  cardLabel: string,
  desc?: string,
  expCat?: string[],
  isPro?: boolean
}

export type ExpNoteReportCardProps = {
  reportId: number,
  cardLabel: string,
  desc?: string,
  expCat?: string[],
  isPro?: boolean
}

export type PreferenceReportCardProps = {
  reportParameters: string,
  reportName: string,
  reportId: number,
  reportType: ReportType
  desc?: string,
  expCat?: string[],
  isPro?: boolean
  removePreference: (reportName: string, reportIdReference: number, setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>, setOpenModal: React.Dispatch<React.SetStateAction<boolean>>) => void
}

// export type ReportCardProps = {
//   reportCode: ReportCode,
//   reportParameters?: string,
//   cardLabel: string,
//   desc?: string,
//   expCat?: string[],
//   hasButton: boolean,
//   buttonLabel?: string,
//   hasMenu: boolean,
//   isPro?:boolean
//   handleButtonClick?: (startDate: Date, endDate: Date) => void,
//   removePreference?: Function,
//   isAPreference?: boolean,
// }

export type ReportCardForm = {
  startDate: Date,
  endDate: Date,
  categories?: string[]
  reportName?: string
}

export const defaultReportCardValues: ReportCardForm = {
  startDate: new Date(Date.now()),
  endDate: new Date(Date.now()),
  categories: [],
  reportName: ''
}

// export const reportValidationSchema = Yup.object({
//   startDate: Yup.date().min(new Date(Date.now())),
//   endDate: Yup.date().min(new Date(Date.now())),
//   categories: Yup.array().of(Yup.string().min(1)),
// })

export interface ReportRequest {
  reportId: number,
  startDate: Date,
  endDate: Date,
  locale: string,
  parameters?: object//string[]
}

export interface ReconciliationReportRequest {
  reportId: number,
  locale: string,
  year: number,
  month: number
}

interface GenericReports {
  id: number,
  code: string,
  name: string,
  description: string,
  repType: ReportType,
  templateName: string,
  pro: boolean
}

export interface ExpensesReports extends GenericReports {
}

export interface ExpNoteReports extends GenericReports {
}

export interface ReconciliationReport extends GenericReports {
}

export interface ReportPreferenceRequest {
  reportName: string,
  reportIdReference: number,
  parameters?: string
}

// export interface ReportPreference {
//   id: number,
//   reportCode: string,
//   reportName: string,
//   parameters: string,
// }
