import {StaffTravelPolicyExpenseItem} from "../../users/model";
import React, {useEffect} from "react";
import {Grid} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import {formatNumber} from "../../../util/NumberUtil";
import {Controller, useFormContext} from "react-hook-form";
import {NewExpenseFormValues} from "./validation";
import {useLoggedUser} from "../../../hooks/useLoggedUser";

type ComponentProps = {
  currentExpItem: StaffTravelPolicyExpenseItem | undefined;
  editableAndReadonly?: boolean,
}

export const ImportoFisso = ({currentExpItem, editableAndReadonly}: ComponentProps) => {

  const formMethods = useFormContext<NewExpenseFormValues>();

  const {user, userLocale: locale, companyCurrencyCode, companyDecimalNum: decimalNum} = useLoggedUser();

  useEffect(() => {
    if (!editableAndReadonly && formMethods.getValues('expenseItem') && !formMethods.getValues('amount')) {
      const tarif = currentExpItem ? currentExpItem.tarif : 0;
      formMethods.setValue('amount', formatNumber(tarif, locale, decimalNum));
    }
  }, [currentExpItem, formMethods]);

  return (
    <>
      <Grid container columnSpacing={3} mb={3}>
        <Grid item xs={3}>
          {/*EUR*/}
          <Controller
            name='amount'
            control={formMethods.control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                label={companyCurrencyCode}
                {...field}
                sx={{width: "150px"}}
                errorMsg={fieldState.error?.message}
                disabled={true}
                fullWidth
                align="right"
              />
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
