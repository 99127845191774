// React
import React, {useEffect} from "react";

// MUI
import {Box} from "@mui/material";
import {Close} from "@mui/icons-material";

// Libs
import moment from "moment";
import { Logger } from 'react-logger-lib';
import SimpleJsLog from '../../util/Logger';

// Custom
import {FilterFunction} from "./model";
import {identityFactory} from "./utils";
import {DatePickerZts} from "../base/date-picker";
import {useTranslation} from "react-i18next";


type DateType = Date | null

export type DateRangeValue = {
  from: DateType
  to: DateType
}

// Il componente notifica che deve essere modificato lo stato
export type DateRangeFilterChangeEvent = {
  name: string
  value: DateRangeValue
}

// Il componente notifica che deve essere modificato il filtro di ricerca a fe
export type DataRangeFilterValueEvent<T1> = {
  name: string
  value: DateRangeValue
  isIdentity: boolean
  filterFn: FilterFunction<T1>
}

type DateRangeFilterProps<T2> = {
  name: string
  label: string
  innerLabel?: string
  range: DateRangeValue
  fromMapperFn: (arg: T2) => DateType
  toMapperFn:  (arg: T2) => DateType
  onChange: (event: DateRangeFilterChangeEvent) => void
  onValue?: (event: DataRangeFilterValueEvent<T2>) => void
  onClose?: (name: string) => void
}

const LOG: SimpleJsLog = Logger.of('ZTS.Filters.DateRangeFilter');

export const DateRangeFilter = <T extends object>(props: DateRangeFilterProps<T>) => {
  const {t} = useTranslation('filter', {keyPrefix: 'label'});

  const {
    name,
    label,
    innerLabel,
    range,
    fromMapperFn,
    toMapperFn,
    onChange,
    onValue,
    onClose
  } = props;


  const filterFactory = (from: DateType, to: DateType): FilterFunction<T> => (values: T[]) => {
    LOG.trace('filterFactory', from, to);
    return values.filter( t => {
      let flag = true;
      if (from!==null) {
        const fromFieldValue = fromMapperFn(t);
        flag = fromFieldValue!==null && moment(fromFieldValue).isAfter(from);
      }
      if (flag && to!==null) {
        const toFieldValue = toMapperFn(t);
        flag = toFieldValue !== null && moment(toFieldValue).isBefore(to);
      }
      return flag;
    })
  }

  const onRangeChange = (from: DateType, to: DateType) => {
    LOG.trace('onRangeChange', from, to);
    const event: DateRangeFilterChangeEvent = {
      name: name,
      value: {
        from: from,
        to: to
      }
    }
    onChange(event);
  }

  const onFromDateChange = (from: Date) => {
    onRangeChange(from, range.to);
  }

  const onToDateChange = (to: Date) => {
    onRangeChange(range.from, to);
  }

  const closeFilter = () => {
    LOG.trace('closeFilter');
    if (onClose) {
      onClose(name);
    }
  }

  useEffect( () =>{
    LOG.trace('useEffect [range]', range);
    if (onValue === undefined || onValue === null) {
      LOG.trace('useEffect [range] -> end. No onValue');
      return;
    }
    const { from, to } = range;
    const isIdentity = from===null && to===null;
    let filterFn: FilterFunction<T>;
    if (isIdentity){
      filterFn = identityFactory();
    } else {
      filterFn = filterFactory(from, to);
    }

    const newValue: DateRangeValue = {
      from: from,
      to: to
    }
    const event: DataRangeFilterValueEvent<T> = {
      name: name,
      value: newValue,
      isIdentity: isIdentity,
      filterFn: filterFn
    }

    onValue(event);

  }, [range]);



  const showLabel = innerLabel ? innerLabel : label;

  return (
    <Box className={"date-filter-wrapper"}>
      <DatePickerZts
        key={`${label}From`}
        label={t('dateFrom', {showLabel: showLabel})}
        field={{value: range.from}}
        disableHelperText={true}
        width={"160px"}
        onChangeHandler={(date) => onFromDateChange(date)}
      />
      <DatePickerZts
        key={`${label}To`}
        label={t('dateTo', {showLabel: showLabel})}
        field={{value: range.to}}
        disableHelperText={true}
        width={"160px"}
        onChangeHandler={(date) => onToDateChange(date)}
      />
      {onClose &&
        <Box className={"filter-close-button"}>
          <Close
            fontSize={"small"}
            onClick={() => closeFilter()}
          />
        </Box>
      }
    </Box>
  );
}
