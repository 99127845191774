// Dev Tools
import { DevTool } from '@hookform/devtools';

// React
import React, {useEffect, useState} from "react";
import {FormProvider, Controller, useForm, KeepStateOptions} from "react-hook-form";
import {useDispatch} from "react-redux";

// YUP
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

// Libs
import {useTranslation} from "react-i18next";
import { Logger } from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// MUI
import {
  Grid, Skeleton,
  Stack,
  Typography
} from "@mui/material";

// Custom
import {SelectZts} from "../../base/select";
import {SelectOption} from "../../base/select/model";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {Expense} from "../model";
import {
  getAllCategoryOptions,
  getAllExpenseTypeOptions, getAllMeasureOptions
} from "../utils";

import {yupMaxNullableString, yupMinMaxRequiredString, yupRequiredString} from "../../../util/YupUtil";

import {HelpCurrentPage, setCurrentPage} from "../../../reducers/Help";
import {CommonDialog} from "../../base/common-dialog";
import {TextFieldZts} from "../../base/text-field";
import {ExpenseSwitch} from "./ExpenseSwitch";
import {activeStateCode} from "../../model";
import {createExpense, loadExpenseById, loadExpenseIconKeys, updateExpense} from "../Service";
import {ExpenseImgSelect} from "../commons/ExpenseImgSelect";


export type ExpenseModalFormValues = Required<Expense>;

const defaultFormValues: ExpenseModalFormValues = {
  id: -1,
  code: '',
  description: '',
  expenseIcon: '',
  expenseCategory: 'VT',
  expenseType: 'PL',
  measureUnit: null,
  compPeriod: false,
  compPeriodStart: false,
  compPeriodEnd: false,
  startLabel: '',
  endLabel: '',
  state: activeStateCode,
  lastUpdNum: 0
}


export type ExpenseModalActionType = 'closed' | 'updated' | 'created';

export type ExpenseModalEvent = {
  action: ExpenseModalActionType
  expense?: Expense
}

export type ExpenseModalActionHandler = (event: ExpenseModalEvent) => void;

type ExpenseModalProps = {
  expense: Partial<Expense> | null,
  onAction: ExpenseModalActionHandler
}

export const ExpenseModal: React.FC<ExpenseModalProps> = ({
  expense,
  onAction
}) => {

  const LOG: Slf4jsLog = Logger.of('ZTS.Expenses.ExpenseModal');

  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [imgList, setImgList] = useState<string[] | null>(null);

  const {t} = useTranslation(['validation']);
  const {t: tExpense} = useTranslation('expense', {keyPrefix: 'modal'});
  const {convertError} = useErrorMessage();

  // code: yupMinMaxRequiredString(1, 20, t),
  const validationSchema = Yup.object({
    expenseIcon: yupRequiredString(t, {required: {key: "iconRequired"}}),
    description: yupMinMaxRequiredString(1, 50, t),
    measureUnit: Yup.string().nullable()
      .when('expenseType', {
        is: (value) => value === 'TR',
        then: Yup.string().required()
      }),
    startLabel: yupMaxNullableString(20, t),
    endLabel: yupMaxNullableString(20, t)
  });

  const formMethods = useForm<ExpenseModalFormValues>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  // Campi controllati
  const [expenseTypeValue, compPeriodFlag, descValue] = formMethods.watch(['expenseType', 'compPeriod', 'description']);

  const dispatch = useDispatch();
  const onCommonModalClose = (flag: boolean) => {
    dispatch(setCurrentPage(HelpCurrentPage.TRAVEL_POLICIES_EDIT));
    LOG.trace('onCommonModalClose', flag);
    if (flag) {
      // submit della form
      formMethods.handleSubmit(onFormValid, onFormError)();
    } else {
      closeModal();
    }
  }

  const closeModal = () => {
    onAction({
      action: 'closed'
    });
  }

  const onFormError = (errors) => {
    LOG.trace('onFormError', errors);
  }

  const onFormValid = (data: ExpenseModalFormValues) => {
    LOG.trace('onFormValid');
    // uso una funzione async
    saveExpense(data)
      .then( () => LOG.trace('async saveExpense end'));
  }

  const saveExpense = async (data: ExpenseModalFormValues) => {
    LOG.trace('saveExpense', data);
    setErrorMsg(null);
    setSaving(true);

    // Copio i valori
    let exp: Expense = {
      ...data
    }

    if (data.expenseType !== 'TR') {
      exp.measureUnit = null;
    }

    if (!data.compPeriod){
      // Se il periodo di competenza è disabilitato forzo il valore dei campi collegati
      exp.compPeriodStart = false;
      exp.compPeriodEnd = false;
      exp.startLabel = null;
      exp.endLabel = null;
    }

    try {
      let expResult: Expense;
      let action: ExpenseModalActionType = 'updated';
      if (exp.id===-1) {
        // Creazione nuova voce di spesa
        const newExpId = await createExpense(exp);
        expResult = await loadExpenseById(newExpId);
        action = 'created';
      } else {
        // Update esistente
        expResult = await updateExpense(exp);
      }
      setSaving(false);

      onAction({
        action: action,
        expense: expResult
      });

    } catch (e) {
      const msg = await convertError(e as Error);
      setErrorMsg(msg);
      setSaving(false);
    }

  }

  const loadIcons = async (): Promise<void> => {
    LOG.trace('loadIcons [async]');
    const icons: string[] = await loadExpenseIconKeys();
    setImgList(icons);
  }

  useEffect(()=>{
    LOG.trace('useEffect [expense]', expense);
    const resetOptions: KeepStateOptions = {
      keepDirtyValues: false,
      keepErrors: false,
      keepDirty: false,
      keepValues: false,
      keepDefaultValues: false,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false
    };
    if (expense) {
      const initVal: ExpenseModalFormValues = {
        ...defaultFormValues,
        ...expense
      };
      formMethods.reset(initVal, resetOptions);
    } else {
      formMethods.reset(defaultFormValues, resetOptions);
    }
  }, [expense]);

  useEffect(()=>{
    LOG.trace('useEffect []');
    loadIcons()
      .then(()=>LOG.trace('loadIcons end.'));

  },[]);

  const showFlag: boolean = expense !== null;
  const isNewFlag: boolean = expense?.id === undefined || expense.id < 0;

  const title = isNewFlag ? tExpense('title.create') : tExpense('title.update');

  const warnMsg = tExpense('warnMsg');

  const typeItems: SelectOption[] = getAllExpenseTypeOptions(t);
  const categoryItems: SelectOption[] = getAllCategoryOptions(t);
  const measureUnitItems: SelectOption[] = getAllMeasureOptions(t);

  return (
    <>
      <CommonDialog
        show={showFlag}
        title={title}
        widths={[
          {breakpoint: "lg", width: "800px"},
        ]}
        saving={saving}
        errorMsg={errorMsg}
        page={HelpCurrentPage.EXPENSE_EDIT}
        onClose={onCommonModalClose}
        announcementMsg={isNewFlag ? undefined : warnMsg}
      >
        <FormProvider {...formMethods}>
          <DevTool control={formMethods.control} placement="top-left" />
          <form noValidate>
            <Stack>
              {/*<Typography variant='h6' textTransform='uppercase'>Icona</Typography>*/}
              {!imgList && <Skeleton variant="rectangular" width='100px' height='80px' style={{marginBottom:'30px'}}/>}
              {imgList &&
                <Controller
                  name="expenseIcon"
                  render={({field, fieldState}) =>
                    <ExpenseImgSelect
                      label={tExpense('label.expenseIcon')}
                      imgList={imgList}
                      required={true}
                      value={field.value}
                      onSelected={v => field.onChange(v)}
                      errorMsg={fieldState.error?.message}
                      expenseDescription={descValue}
                    />
                  }
                />
              }
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" textTransform={"uppercase"}>{tExpense('title.features')}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="code"
                  render={({field, fieldState}) => <TextFieldZts
                    {...field}
                    value={field.value ? field.value : ''}
                    disabled
                    forceHelperOnDisabled={true}
                    label={tExpense('label.code')}
                    errorMsg={fieldState.error?.message}
                  />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="description"
                  render={({field, fieldState}) => <TextFieldZts
                    {...field}
                    value={field.value ? field.value : ''}
                    required
                    label={tExpense('label.description')}
                    errorMsg={fieldState.error?.message}
                  />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="expenseType"
                  render={({field, fieldState}) =>
                    <SelectZts
                      required
                      disabled={!isNewFlag}
                      errorMsg={fieldState.error?.message}
                      label={tExpense('label.expenseType')}
                      width='100%'
                      items={typeItems}
                      selectedValue={field.value ? field.value: ''}
                      setValue={(value) => {field.onChange(value)}}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="expenseCategory"
                  render={({field, fieldState}) =>
                    <SelectZts
                      required
                      errorMsg={fieldState.error?.message}
                      label={tExpense('label.expenseCategory')}
                      width='100%'
                      items={categoryItems}
                      selectedValue={field.value ? field.value: ''}
                      setValue={(value) => {field.onChange(value)}}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="measureUnit"
                  render={({field, fieldState}) =>
                    <SelectZts
                      required={expenseTypeValue==='TR'}
                      disabled={expenseTypeValue!=='TR' || !isNewFlag}
                      errorMsg={fieldState.error?.message}
                      label={tExpense('label.measureUnit')}
                      width='100%'
                      items={measureUnitItems}
                      selectedValue={expenseTypeValue==='TR' && field.value ? field.value: ''}
                      setValue={(value) => {field.onChange(value)}}
                    />
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{marginTop: '20px'}}>
              <Grid item xs={12}>
                <Typography variant="h6" textTransform={"uppercase"}>{tExpense('title.compPeriod')}</Typography>
              </Grid>

            <Grid item xs={12}>
              <ExpenseSwitch name='compPeriod' label={tExpense('label.compPeriod')}/>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="startLabel"
                render={({field, fieldState}) => <TextFieldZts
                  {...field}
                  value={compPeriodFlag && field.value ? field.value : ''}
                  disabled={!compPeriodFlag}
                  forceHelperOnDisabled={true}
                  label={tExpense('label.startLabel')}
                  placeholder={tExpense('placeHolder.startLabel')}
                  errorMsg={fieldState.error?.message}
                />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="endLabel"
                render={({field, fieldState}) => <TextFieldZts
                  {...field}
                  value={compPeriodFlag && field.value ? field.value : ''}
                  disabled={!compPeriodFlag}
                  forceHelperOnDisabled={true}
                  label={tExpense('label.endLabel')}
                  placeholder={tExpense('placeHolder.endLabel')}
                  errorMsg={fieldState.error?.message}
                />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ExpenseSwitch name='compPeriodStart' disabled={!compPeriodFlag} label={tExpense('label.compPeriodStart')}/>
            </Grid>
            <Grid item xs={12}>
              <ExpenseSwitch name='compPeriodEnd' disabled={!compPeriodFlag} label={tExpense('label.compPeriodEnd')}/>
            </Grid>
            </Grid>
          </form>
        </FormProvider>
      </CommonDialog>
    </>
  );
}
