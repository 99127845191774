/**
 * Utility per la TravelPolicy
 */
import { TravelPolicy } from './model';
import { activeStateCode } from '../model';

// TODO cambiare ???
import moment from 'moment';

// I18N
import { TFunction } from 'react-i18next';



//export type TravelPolicyStateType = 'success' | 'danger' | 'expired' | 'future';
export type TravelPolicyStateType = 'success' | 'danger';
export type TravelPolicyDateStateType = 'regular' | 'expired' | 'future';

export const getTravelPolicyStateType = (tp: TravelPolicy): TravelPolicyStateType => {
  if (tp.state !== activeStateCode) {
    return 'danger';
  }
  return 'success';
}

export const getTravelPolicyDateStateType = (tp: TravelPolicy): TravelPolicyDateStateType => {
  // TODO verificare con moment
  const today = new Date();
  const m = moment(today);
  if (m.isAfter(tp.endDate)) {
    return 'expired';
  }
  if (m.isBefore(tp.startDate)) {
    return 'future';
  }
  return 'regular';
}

export const getTravelPolicyStateLabel = (type: TravelPolicyStateType, t: TFunction): string => {
  switch (type){
    case 'success':
    case 'danger':
      const key = 'travel-policy.type.' + type;
      return t(key, {ns: 'travel-policy'});
  }
  throw new Error('Unexpected type:' + type);
}

export const getTravelPolicyDateStateLabel = (type: TravelPolicyDateStateType, t: TFunction): string => {
  switch (type){
    case 'regular':
    case 'expired':
    case 'future':
      const key = 'travel-policy.date-type.' + type;
      return t(key, {ns: 'travel-policy'});
  }
  throw new Error('Unexpected type:' + type);
}

export const getTravelPolicyStateCssClass = (type: TravelPolicyStateType): string => {
  return `status-pill-${type}`;
}

export const getTravelPolicyDateStateCssClass = (type: TravelPolicyDateStateType): string => {
  return `status-pill-${type}`;
}
