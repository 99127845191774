import { GenericPagedList } from '../../../model';
import { ExpNoteSort, ExpNoteState, getExpNotesToCompleteStates } from '../../model';
import { getApiUrl } from '../../../../config/environment';
import { default as axios } from 'axios';
import { HistoricalExpNote, HistoricalExpNoteFilter } from './model';
import { createBlobError, createError } from '../../../../util/ErrorUtil';

export const getAllHistoricalExpNotes = async (pageSize: number, page = 1): Promise<GenericPagedList<HistoricalExpNote>> => {
  return getTravellerExpNotesByState(getExpNotesToCompleteStates(), pageSize, page);
}

export const getTravellerExpNotesByState = async (states: ExpNoteState[], pageSize: number, page: number, filter?: HistoricalExpNoteFilter, sort?: ExpNoteSort): Promise<GenericPagedList<HistoricalExpNote>> => {
  const queryString = null;

  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericPagedList<HistoricalExpNote>>(`${apiUrl}/archivedexpnotes`, {params: queryString});
  //console.log(resp);
  return resp.data;
}

export const getFileZipName = async (id: number): Promise<string> => {
  const queryString = null;
  const apiUrl = getApiUrl();
  const resp = await axios.get<string>(`${apiUrl}/archivedexpnotes/${id}/fileZipName`, {params: queryString});
  //console.log(resp);
  return resp.data;
}

export const downloadZipFile = async (id: number): Promise<Blob> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.get<Blob>(`${apiUrl}/archivedexpnotes/${id}/zip`, {
      responseType: 'blob'
    });
    return resp.data;
  } catch (err: any) {
    throw await createBlobError(err);
  }
}

//Carica da file csv le ns spese storiche, le inserisce su db e poi ne restituisce l'elenco
export const getCsvExpNotes = async (): Promise<boolean> => {
  const queryString = null;
  try {
      const apiUrl = getApiUrl();
      const resp = await axios.get<boolean>(`${apiUrl}/loadcsvexpnotes`, {params: queryString});
      //console.log(resp);
      return resp.data;
  } catch (err: any) {
    throw await createError(err);
  }
}

//Ha il compito di dire se la directory con gli zip esiste per questo tenant oppure no
export const getarchivedexpnotesexists = async (): Promise<boolean> => {
  const queryString = null;
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.get<boolean>(`${apiUrl}/archivedexpnotesexists`, {params: queryString});
    //console.log(resp);
    return resp.data;
  } catch (err: any) {
    throw await createError(err);
  }
}
