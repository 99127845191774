import {Grid, Skeleton, Stack} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import {AutocompleteZts} from "../../base/autocomplete";
import {
  CompanyCurrencyAutocompleteOption,
  createAutocompleteCurrencyOption,
  DataUpdatedByOcr,
  DocumentType,
  ExpNoteExpenseFull,
  getDocumentTypeLabelKey,
  getPayModeLabelKey,
  PayMode,
  useCommonLabel
} from "./model";
import {Currency, CurrencyExchange, DateFormat, ExchangeDecimalNum} from "../../model";
import React, {useCallback, useEffect, useState} from "react";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {formatNumber, parseNumber} from "../../../util/NumberUtil";
import {StaffCreditCard} from "../../users/model";
import {ExchangeRequest, OcrResult} from "../model";
import {getCurrencyExchanges} from "../../../util/BaseService";
import moment from "moment";
import {Guests} from "./Guests";
import {Colleagues} from "./Colleagues";
import {getExchange} from "../Service";
import {useTranslation} from "react-i18next";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {NewExpenseFormValues, NewExpensePayCard} from "./validation";
import {PayModeIcon} from "../util/PayModeIcon";
import {DocumentTypeIcon} from "../util/DocumentTypeIcon";
import Box from "@mui/material/Box";
import {useExpNoteDetail} from "../detail/hooks/useExpNoteDetail";
import {useExpenseForm} from "../../../hooks/useExpenseForm";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {getActiveSuppliers} from "../../suppliers/Service";
import {useExpNoteList} from "../hooks/useExpNoteList";
import {ExpNoteListRole} from "../../../reducers/ExpNoteList";
import {formatDate} from "../../../util/DateUtil";
import {DatePickerZts} from "../../base/date-picker";
import {PremiumIcon} from "../../base/premium-icon";
import {ArrowDropDown} from "@mui/icons-material";
import {useLicense} from "../../../hooks/useLicense";
import {PopupContentType} from "../../license-popup/model";


type ComponentProps = {
  currencyItems: Currency[] | null;
  creditCardItems: StaffCreditCard[];
  ocrResult: OcrResult | null;
  lastExpense: ExpNoteExpenseFull | null;
  computeExchange: boolean; // se true il cambio viene calcolato in tempo reale al variare di: data, divisa, importo, modalità di pagamento
  editExpense: ExpNoteExpenseFull | null;
  editableAndReadonly?: boolean,
}

export const PieDiLista = (
  {
    currencyItems,
    creditCardItems,
    ocrResult,
    lastExpense,
    computeExchange,
    editExpense,
    editableAndReadonly
  }: ComponentProps) => {

  const {userLocale: locale, companyCurrencyCode, companyDecimalNum: decimalNum} = useLoggedUser();

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const {expNote} = useExpNoteDetail();

  const {
    currentRole
  } = useExpNoteList();

  const formMethods = useFormContext<NewExpenseFormValues>();

  const [payModeItems, setPayModeItems] = useState<(AutocompleteGenericOption & NewExpensePayCard)[]>([]);
  const [loadingPayMode, setLoadingPayMode] = useState(false);

  const [docTypeItems, setDocTypeItems] = useState<AutocompleteGenericOption[]>([]);
  const [loadingDocType, setLoadingDocType] = useState(false);

  const [supplierItems, setSupplierItems] = useState<AutocompleteGenericOption[]>([]);
  const [loadingSupplier, setLoadingSupplier] = useState(false);

  const [convertedAmount, setConvertedAmount] = useState(editExpense ? formatNumber(editExpense.compAmount, locale, decimalNum) : '');

  const [currentExchanges, setCurrentExchanges] = useState<CurrencyExchange[] | null>(null);

  const [amountChangeTO, setAmountChangeTO] = useState<any>(null);

  const [autoFields, setAutoFields] = useState<boolean>(!editExpense);

  const noCurrencyItems: AutocompleteGenericOption[] = [
    CompanyCurrencyAutocompleteOption(companyCurrencyCode, decimalNum)
  ];

  const {
    docTypes,
    payModes,
    updatePayMode,
    updateDocType,
    updateFormState
  } = useExpenseForm();

  const {t} = useTranslation('exp-note', {keyPrefix: 'expense.edit'});

  const {getLabel} = useCommonLabel();

  const expItem = formMethods.getValues('expenseItem');

  const compEndDateWatched = useWatch({name: "compEndDate", exact: true});
  const compDateWatched = useWatch({name: "compDate", exact: true});
  const creCardMovWatched = useWatch({name: "creCardMov", exact: true});

  const isAdmin = currentRole && currentRole === ExpNoteListRole.ADMIN;

  useEffect(() => {
    if (creCardMovWatched) {
      setConvertedAmount(formatNumber(creCardMovWatched.compAmount, locale, decimalNum));
    }
  }, [creCardMovWatched]);

  useEffect(() => {
    // in creazione di una nuova spesa la divisa viene proposta:
    //    1 - impostando la divisa dell'ultima spesa (della stessa NS)
    //    2 - impostando la divisa aziendale (se non c'è una spesa precedente)
    if (expItem && currencyItems && !editExpense && creCardMovWatched === undefined) {

      // se la divisa è già valorizzata non faccio nulla
      const currency = formMethods.getValues('currency');
      if (currency && currency.code) {
        return;
      }

      const travelPolicy = formMethods.getValues('travelPolicy');
      if (!travelPolicy || (ocrResult && ocrResult.currCode)) {
        return;
      }

      let currencyToSet: Currency | null = null;
      if (lastExpense) {
        // esiste una spesa precedente
        const lastUsedCurrency = lastExpense && currencyItems ? currencyItems.find(c => c.code === lastExpense.currencyCode) : null;
        if (travelPolicy && travelPolicy.foreignCurr && lastUsedCurrency) {
          currencyToSet = lastUsedCurrency;
        }
      }

      if (currencyToSet) {
        formMethods.setValue('currency', createAutocompleteCurrencyOption(currencyToSet.id ? currencyToSet.id : -1, currencyToSet.code, currencyToSet.decimalNum));
        onChangeCurrency(currencyToSet.code);
      } else {
        formMethods.setValue('currency', CompanyCurrencyAutocompleteOption(companyCurrencyCode, decimalNum));
        onChangeCurrency(companyCurrencyCode);
      }
    }
  }, [expItem, lastExpense, currencyItems, creCardMovWatched]);

  const getCompDate = useCallback((): Date | null => {
    if (expItem && expItem.compPeriod) {
      return compEndDateWatched;
    } else {
      return compDateWatched;
    }
  }, [expItem, compDateWatched, compEndDateWatched]);

  useEffect(() => {
    const date = getCompDate();
    if (date) {
      if (computeExchange) {
        recomputeExchange(date, null, null, null);
      } else {
        const currency = formMethods.getValues('currency');

        if (!currentExchanges && currency && currency.code !== companyCurrencyCode && expNote) {
          const startDate: Date = moment(expNote.startDate).format(DateFormat) as unknown as Date;
          const endDate: Date = moment(expNote.endDate).format(DateFormat) as unknown as Date;
          getCurrencyExchanges(companyCurrencyCode, currency.code, startDate, endDate)
            .then(items => {
              let localExchanges: CurrencyExchange[] | null = null;
              if (items && items.length > 0) {
                localExchanges = items;
                setCurrentExchanges(items);
              } else {
                setCurrentExchanges([]);
              }
              setExchangeAndCompAmount(currency ? currency.code : null, date, localExchanges, null);
            });
        } else {
          setExchangeAndCompAmount(currency ? currency.code : null, date, null, null);
        }
      }
    }
  }, [getCompDate]);

  useEffect(() => {
    //console.log('ocr result -- after', ocrResult, currencyItems, formMethods.getValues('travelPolicy').foreignCurr)
    if (ocrResult) {
      const ocrUpd: DataUpdatedByOcr = {
        dateUpd: false,
        amountUpd: false,
        currencyUpd: false
      };
      let ocrAmount: string | null = null;
      if (ocrResult.expDate) {
        ocrUpd.dateUpd = setOcrDate(ocrResult.expDate);
      }
      const travelPolicy = formMethods.getValues('travelPolicy');
      if (ocrResult.currCode && currencyItems && travelPolicy && travelPolicy.foreignCurr) {
        ocrUpd.currencyUpd = setOcrCurrency(ocrResult.currCode);
      }
      if (ocrResult.expAmount) {
        ocrAmount = setOcrAmount(ocrResult.currCode, ocrResult.expAmount);
        ocrUpd.amountUpd = ocrAmount != null;
      }

      updateAfterOcrResult(ocrUpd, ocrResult.currCode, ocrAmount, ocrResult.expDate);
    }
  }, [ocrResult]);

  useEffect(() => {
    if (!editableAndReadonly) {
      setLoadingPayMode(true);
      const tmpPayModes: (AutocompleteGenericOption & NewExpensePayCard)[] = [];
      payModes.forEach(payMode => {
        switch (payMode) {
          case PayMode.TRAVELLER:
            tmpPayModes.push(createAutocompleteGenericOption(-1, payMode, getLabel(getPayModeLabelKey(payMode)), payMode));
            break;
          case PayMode.CARD:
            if (creditCardItems) {
              creditCardItems.forEach(item => {
                const tmpPayMode = createAutocompleteGenericOption(item.id, payMode, `${getLabel(getPayModeLabelKey(payMode))} ${item.cardNum}`, payMode);
                tmpPayModes.push({...tmpPayMode, cardNum: item.cardNum});
              });
            }

            const currentPayModeSelected: (AutocompleteGenericOption & NewExpensePayCard) | null = formMethods.getValues('payModeItem');
            if (currentPayModeSelected &&
              currentPayModeSelected.code === PayMode.CARD &&
              creditCardItems.find(c => c.id === currentPayModeSelected.id) === undefined) {
              formMethods.setValue('payModeItem', null);
            }

            break;
          case PayMode.COMPANY:
            tmpPayModes.push(createAutocompleteGenericOption(-3, payMode, getLabel(getPayModeLabelKey(payMode)), payMode));
            break;
        }
      });
      setPayModeItems(tmpPayModes);

      //TODO: da rivedere, non deve essere fatto quando apro una spesa in modifica e disegno
      //      per la prima volta la pagina, perchè potrebbe esserci il caso in cui la modalità
      //      di pagamento utilizzata non è più disponibile nella TP e impostando l'unica attualmente
      //      disponibile si sostituisce automaticamente il valore senza far vedere il problema all'utente
      setUniqueItem(tmpPayModes, 'payModeItem');

      setLoadingPayMode(false);
    }
  }, [payModes, creditCardItems]);

  useEffect(() => {
    if (!editableAndReadonly) {
      setLoadingDocType(true);
      const tmpDocTypes: AutocompleteGenericOption[] = [];

      docTypes.forEach(docType => {
        let index = 0;
        switch (docType) {
          case DocumentType.INVOICE:
            index = 2;
            break;
          case DocumentType.RECEIPT:
            index = 1;
            break;
          case DocumentType.TICKET:
            index = 3;
            break;
        }
        tmpDocTypes.push(createAutocompleteGenericOption(index, docType, getLabel(getDocumentTypeLabelKey(docType)), docType));
      });
      //@ts-ignore
      setDocTypeItems(tmpDocTypes.sort((a, b) => a.id - b.id));

      //TODO: da rivedere, non deve essere fatto quando apro una spesa in modifica e disegno
      //      per la prima volta la pagina, perchè potrebbe esserci il caso in cui il giustificativo
      //      utilizzato non è più disponibile nella TP e impostando l'unica attualmente
      //      disponibile si sostituisce automaticamente il valore senza far vedere il problema all'utente
      setUniqueItem(tmpDocTypes, 'docTypeItem');

      setLoadingDocType(false);
    }
  }, [docTypes]);

  useEffect(() => {
    if (editExpense && editExpense.tpExpenseId) {
      setAutoFields(true);
    }
  }, [editExpense]);

  useEffect(() => {
    if (!editableAndReadonly && isAdmin) {
      setLoadingSupplier(true);
      getActiveSuppliers()
        .then(suppliers =>
          setSupplierItems(suppliers.map(s => createAutocompleteGenericOption(s.id, s.vatId, s.description))))
        .finally(() => setLoadingSupplier(false));
    }
  }, [editableAndReadonly, isAdmin]);

  const updateAfterOcrResult = (updatedData: DataUpdatedByOcr, currency: string, amount: string | null, date: Date) => {
    if (updatedData.currencyUpd) {
      const date4Calc = date ? date : getCompDate();
      const amount4Calc = amount ? amount : null;
      onChangeCurrency(currency, (items?) => {
        if (currency !== companyCurrencyCode) {
          if (computeExchange) {
            recomputeExchange(date4Calc, amount4Calc, currency, null);
          } else {
            setExchangeAndCompAmount(currency, date4Calc, items ? items : null, amount4Calc);
          }
        }
      });
    } else if (updatedData.dateUpd || updatedData.amountUpd) {
      const date4Calc = date ? date : getCompDate();
      const amount4Calc = amount ? amount : null;
      if (computeExchange) {
        recomputeExchange(date4Calc, amount4Calc, null, null);
      } else {
        const currency = formMethods.getValues('currency');
        setExchangeAndCompAmount(currency ? currency.code : null, date4Calc, null, amount4Calc);
      }
    }
  }

  const setOcrDate = (date: Date): boolean => {
    if (expNote && expNote.startDate <= date && expNote.endDate >= date) {
      const fieldKey = expItem && expItem.compPeriod ? 'compEndDate' : 'compDate';
      if (formMethods.getValues(fieldKey)) {
        return false;
      }
      formMethods.setValue(fieldKey, date);
      return true;
    }
    return false;
  }

  const setOcrCurrency = (currencyCode: string): boolean => {
    if (formMethods.getValues('amount') !== '') {
      return false;
    }
    const currencyItem: Currency | null = getCurrencyByCode(currencyCode);
    if (currencyItem) {
      const currency = formMethods.getValues('currency');
      const updated = !currency || currency.code !== currencyCode;
      formMethods.setValue('currency', createAutocompleteCurrencyOption(currencyItem.id ? currencyItem.id : 0, currencyItem.code, currencyItem.decimalNum));
      return updated;
    }
    return false;
  }

  const setOcrAmount = (currencyCode: string, amount: number): string | null => {
    if (formMethods.getValues('amount') !== '') {
      return null;
    }
    let localDecimalNum = decimalNum;
    const travelPolicy = formMethods.getValues('travelPolicy');
    if (travelPolicy && travelPolicy.foreignCurr && currencyItems && currencyCode) {
      const currencyItem = getCurrencyByCode(currencyCode);
      if (currencyItem) {
        localDecimalNum = currencyItem.decimalNum;
      }
    }
    const formattedAmount = formatNumber(amount, locale, localDecimalNum);
    formMethods.setValue('amount', formattedAmount);
    return formattedAmount;
  }

  const setCompCurrAmount = (exchange: string, amount: string) => {
    if (exchange && amount) {
      const parsedExchange = parseNumber(exchange, locale);
      const parsedAmount = parseNumber(amount, locale);
      if (parsedExchange && parsedAmount) {
        setConvertedAmount(formatNumber(parsedAmount / parsedExchange, locale, decimalNum));
      } else {
        setConvertedAmount('');
      }
    } else {
      setConvertedAmount('');
    }
  }

  const onChangeCurrency = (value: string | null, customCallback?: (items?: CurrencyExchange[]) => void) => {
    if (value && expNote) {

      if (computeExchange) {
        if (customCallback) {
          customCallback();
        } else {
          recomputeExchange(null, null, value, null);
        }
      } else {
        const startDate: Date = moment(expNote.startDate).format(DateFormat) as unknown as Date;
        const endDate: Date = moment(expNote.endDate).format(DateFormat) as unknown as Date;
        getCurrencyExchanges(companyCurrencyCode, value, startDate, endDate)
          .then(items => {
            if (items && items.length > 0) {
              setCurrentExchanges(items);
            } else {
              setCurrentExchanges([]);
            }

            if (customCallback) {
              customCallback(items);
            } else {
              if (value !== companyCurrencyCode) {
                setExchangeAndCompAmount(value, getCompDate(), !items || items.length === 0 ? [] : items, null);
              }
            }
          })
          .catch(err => console.log('Fetch exchanges error', err));
      }
    }
  }

  // Legge il cambio da tabella per la divisa e la data passate.
  // Da utilizzare se non ci sono anticipi/cambi/resi.
  const setExchangeAndCompAmount = (currency: string | null, compDate: Date | null, exchanges: CurrencyExchange[] | null, amount: string | null) => {
    // console.log('setExchangeAndCompAmount', currency, compDate, exchanges, currentExchanges)
    if (formMethods.getValues("creCardMov") !== undefined) {
      return;
    }

    if (!computeExchange && currency && compDate) {

      if (!exchanges) {
        exchanges = currentExchanges || [];
      }
      const checkDate: string = moment(compDate).format(`${DateFormat} 00:00:00`);
      const excRate: CurrencyExchange | undefined = exchanges.find(exc => moment(exc.startDate).format(`${DateFormat} 00:00:00`) === checkDate);

      if (excRate) {
        formMethods.setValue('exchange', formatNumber(excRate.exchangeRate, locale, ExchangeDecimalNum), {shouldValidate: true});
        //console.log('setExchangeAndCompAmount', excRate)
        setCompCurrAmount(excRate.exchangeRate.toString(), amount ? amount : formMethods.getValues('amount'));
      } else {
        const fallbackExchange = 1;
        formMethods.setValue('exchange', formatNumber(fallbackExchange, locale, ExchangeDecimalNum));
        setCompCurrAmount(fallbackExchange.toString(), amount ? amount : formMethods.getValues('amount'));
      }
    }
  }

  const getCurrencyByCode = (code: string): Currency | null => {
    let item: Currency | undefined;
    if (currencyItems) {
      item = currencyItems.find(c => c.code === code);
    }
    return item ? item : null;
  }

  const onChangePayMode = (payMode: string) => {
    if (computeExchange) {
      recomputeExchange(null, null, null, payMode);
    }
    updatePayMode(payMode ? (payMode as PayMode) : null);
  }

  const onChangeDocType = (docType: string) => {
    updateDocType(docType ? (docType as DocumentType) : null);
  }

  const setUniqueItem = (items: AutocompleteGenericOption[], id: 'docTypeItem' | 'payModeItem') => {
    let uniqueItem: AutocompleteGenericOption | null = null;
    if (items.length === 1) {
      uniqueItem = items[0];
      formMethods.setValue(id, uniqueItem);

      if (id === 'payModeItem') {
        updatePayMode(uniqueItem.code as PayMode);
      } else {
        updateDocType(uniqueItem.code as DocumentType);
      }
    }
  }

  // Calcola il cambio dinamicamente al variare di: importo, divisa, data, modalità di pagamento.
  // Viene chiamato il BE, da utilizzare soltanto se sono presenti anticipi/cambi/resi.
  const recomputeExchange = (date: Date | null, amount: string | null, currency: string | null, payMode: string | null) => {
    if (formMethods.getValues("creCardMov") !== undefined) {
      return;
    }

    const req: ExchangeRequest = {
      expId: editExpense?.id,
      date: date ? date : getCompDate(),
      amount: '0',
      currencyCode: currency ? currency : formMethods.getValues('currency')?.code,
      payMode: payMode ? payMode : formMethods.getValues('payModeItem')?.code
    };
    if (req.currencyCode && req.currencyCode !== companyCurrencyCode) {
      if (amount) {
        req.amount = amount;
      } else if (formMethods.getValues('amount')) {
        req.amount = formMethods.getValues('amount');
      }
      if (expNote && req.date && req.currencyCode) {
        updateFormState(true);
        getExchange(expNote.id, req, locale)
          .then(res => {
            formMethods.setValue('exchange', formatNumber(res.exchange, locale, ExchangeDecimalNum), {shouldValidate: true});
            setCompCurrAmount(res.exchange.toString(), req.amount);
            updateFormState(false);
          });
      }
    }
  }

  const onAmountChangeHandler = (amount: string) => {
    if (amountChangeTO) {
      clearTimeout(amountChangeTO);
    }
    setAmountChangeTO(setTimeout(() => {
      doAfterAmountChanged(amount)
    }, 400));
  }

  const doAfterAmountChanged = (amount: string) => {
    if (computeExchange) {
      recomputeExchange(null, amount ? amount : '0', null, null);
    } else {
      const exchange = formMethods.watch('exchange') // formMethods.getValues('exchange');
      setCompCurrAmount(exchange, amount);
    }
  }

  const renderSupplier = () => {
    if (free) {
      // in modalità gratuita non posso gestire il fornitore
      return <TextFieldZts
        disabled={true}
        label={t('supplier')}
        value={null}
        InputProps={{
          endAdornment: (
            <Stack
              direction="row"
              columnGap={0.5}
              alignItems={"center"}
            >
              <PremiumIcon type={PopupContentType.INVOICE_PILL} clickable={true}/>
              <ArrowDropDown/>
            </Stack>
          )
        }}
      />
    }

    if (editableAndReadonly) {
      return <TextFieldZts
        disabled={true}
        label={t('supplier')}
        value={formMethods.getValues('supplier')?.selectedLabel}
      />
    }

    return <>
      {loadingSupplier && <Skeleton height="50px" sx={{transform: "none"}} />}
      {!loadingSupplier &&
        <Controller
          name='supplier'
          control={formMethods.control}
          render={({field, fieldState}) => {
            return <AutocompleteZts
              id={field.name}
              label={t('supplier')}
              options={supplierItems}
              selectedValue={field.value}
              setValue={(id, value) => {
                field.onChange(value);
              }}
              codeAndDesc={false}
              errorMsg={fieldState.error?.message}
            />
          }}
        />
      }
    </>
  }

  const renderDocumentNumber = () => {
    if (free) {
      return <TextFieldZts
        disabled={true}
        label={t('invoiceNum')}
        value={null}
        InputLabelProps={{
          sx: {
            maxWidth: "100px"
          }
        }}
        InputProps={getProFeatureEndAdornment()}
      />
    }

    if (editableAndReadonly) {
      return <TextFieldZts
        disabled={true}
        label={t('invoiceNum')}
        value={formMethods.getValues('invoiceNum')}
      />
    }

    return <Controller
      name='invoiceNum'
      control={formMethods.control}
      render={({field, fieldState}) => {
        return <TextFieldZts
          label={t('invoiceNum')}
          {...field}
          onChange={(e) => {
            const value = e.target.value;
            field.onChange(value);
          }}
          errorMsg={fieldState.error?.message}
          fullWidth
        />
      }}
    />
  }

  const renderDocumentDate = () => {
    if (free) {
      return <TextFieldZts
        disabled={true}
        label={t('documentDate')}
        value={null}
        InputLabelProps={{
          sx: {
            maxWidth: "100px"
          }
        }}
        InputProps={getProFeatureEndAdornment()}
      />
    }

    if (editableAndReadonly) {
      return <TextFieldZts
        disabled={true}
        sx={{width: "100%"}}
        label={t('documentDate')}
        value={formatDate(editExpense?.docDate, locale)}
      />
    }

    return <Controller
      name={'docDate'}
      control={formMethods.control}
      render={({field, fieldState}) => {
        return <DatePickerZts
          label={t("documentDate")}
          field={field}
          errorMsg={fieldState.error?.message}
          width={"100%"}
          onChangeHandler={(value: Date) => {
            field.onChange(value);
          }}
          defaultCalendarMonth={expNote ? moment(expNote.startDate) : null}
        />
      }}
    />
  }

  const getProFeatureEndAdornment = () => {
    return {
      endAdornment: free ? (
        <Stack
          direction="row"
          columnGap={0.5}
          alignItems={"center"}
        >
          <PremiumIcon type={PopupContentType.INVOICE_PILL} clickable={true}/>
        </Stack>
      ) : null
    }
  }

  return (
    <>
      <Grid container columnSpacing={3} mt={2}>
        <Grid item xs={3}>
          {/*Importo*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              label={t('amount')}
              value={formMethods.getValues('amount')}
            />
          ) : (
            <Controller
              name='amount'
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <TextFieldZts
                  label={t('amount')}
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value);

                    onAmountChangeHandler(value);
                  }
                  }
                  errorMsg={fieldState.error?.message}
                  align={'right'}
                  fullWidth
                />
              }}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          {/*Divisa*/}
          {formMethods.getValues('travelPolicy')?.foreignCurr && currencyItems && <>
            {editableAndReadonly ? (
              <TextFieldZts
                disabled={true}
                label={t('currency')}
                value={formMethods.getValues('currency')?.selectedLabel}
              />
            ) : (
              <Controller
                name='currency'
                control={formMethods.control}
                render={({field, fieldState}) => {
                  return <AutocompleteZts
                    id={field.name}
                    label={t('currency')}
                    options={currencyItems.map(c => createAutocompleteCurrencyOption(c.id ? c.id : 0, c.code, c.decimalNum))}
                    selectedValue={field.value}
                    setValue={(id, value) => {
                      field.onChange(value);
                      onChangeCurrency(value ? value.code : null);
                    }}
                    errorMsg={fieldState.error?.message}
                    matchEqualByCode={true}
                  />
                }}
              />
            )}
          </>}
          {!formMethods.getValues('travelPolicy')?.foreignCurr &&
            <AutocompleteZts
              id="currency"
              label={t('currency')}
              options={noCurrencyItems}
              disabled={true}
              setValue={() => {
              }}
              selectedValue={CompanyCurrencyAutocompleteOption(companyCurrencyCode, decimalNum)}
            />
          }
        </Grid>
        {formMethods.getValues('currency')?.code !== companyCurrencyCode && <>
          <Grid item xs={3}>
            {/*Cambio*/}
            {editableAndReadonly ? (
              <TextFieldZts
                disabled={true}
                label={t('exchange')}
                value={formMethods.getValues('exchange')}
              />
            ) : (
              <Controller
                name='exchange'
                control={formMethods.control}
                render={({field, fieldState}) => {
                  return <TextFieldZts
                    label={t('exchange')}
                    {...field}
                    errorMsg={fieldState.error?.message}
                    disabled={true}
                    align={'right'}
                    fullWidth
                  />
                }}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            {/*Controvalore*/}
            <TextFieldZts
              label={companyCurrencyCode}
              disabled={true}
              value={convertedAmount}
              inputProps={{
                style: {textAlign: 'right'}
              }}
            />
          </Grid>
        </>}
      </Grid>

      <Grid container columnSpacing={3} mt={2}>
        <Grid item xs={6}>
          {/*Tipo giustificativo*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              label={t('documentType')}
              value={formMethods.getValues('docTypeItem')?.selectedLabel}
              InputProps={{
                startAdornment: editExpense ?
                  <Box pt={2.8} mr={1}><DocumentTypeIcon docType={editExpense.docType}/></Box> : <></>
              }}
            />
          ) : (
            <>
              {loadingDocType && <Skeleton height="50px" sx={{transform: "none"}} />}
              {!loadingDocType &&
                <Controller
                  name='docTypeItem'
                  control={formMethods.control}
                  render={({field, fieldState}) => {
                    return <AutocompleteZts
                      id={field.name}
                      label={t('documentType')}
                      options={docTypeItems}
                      selectedValue={field.value}
                      setValue={(name, value) => {
                        onChangeDocType(value ? value.code : null);
                        field.onChange(value);
                      }}
                      codeAndDesc={false}
                      errorMsg={fieldState.error?.message}
                      renderIcon={icon => <DocumentTypeIcon docType={icon}/>}
                    />
                  }}
                />
              }
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          {/*Tipo pagamento*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              label={t('payMode')}
              value={formMethods.getValues('payModeItem')?.selectedLabel}
              InputProps={{
                startAdornment: editExpense ?
                  <Box pt={2.8} mr={1}><PayModeIcon payMode={editExpense.payMode}/></Box> : <></>
              }}
            />
          ) : (
            <>
              {loadingPayMode && <Skeleton height="50px" sx={{transform: "none"}}/>}
              {!loadingPayMode &&
                <Controller
                  name='payModeItem'
                  control={formMethods.control}
                  render={({field, fieldState}) => {
                    return <AutocompleteZts
                      id={field.name}
                      label={t('payMode')}
                      options={payModeItems}
                      selectedValue={field.value}
                      setValue={(id, value) => {
                        onChangePayMode(value ? value.code : null);
                        field.onChange(value);
                      }}
                      codeAndDesc={false}
                      errorMsg={fieldState.error?.message}
                      renderIcon={icon => {
                        return <PayModeIcon payMode={icon}/>
                      }}
                    />
                  }}
                />
              }
            </>
          )}
        </Grid>
      </Grid>

      {isAdmin && (
        <Grid container columnSpacing={3} mt={2}>
          <Grid item xs={6}>
            {renderSupplier()}
          </Grid>
          <Grid item xs={3}>
            {renderDocumentNumber()}
          </Grid>
          <Grid item xs={3}>
            {renderDocumentDate()}
          </Grid>
        </Grid>
      )}


      {expNote && expItem && (expItem.colleagues || expItem.guests) &&
        <Grid
          container
          columnSpacing={3}
          mt={2}
        >
          {/*Colleghi*/}
          {expNote && expItem && expItem.colleagues &&
            <Grid item xs={6}>
              <Colleagues
                staffId={expNote.staffId}
                editableAndReadonly={editableAndReadonly}
              />
            </Grid>
          }

          <Grid item xs={6}>
            {/*Ospiti*/}
            {expNote && expItem && expItem.guests &&
              <Guests expNote={expNote} editableAndReadonly={editableAndReadonly}/>}
          </Grid>
        </Grid>}


    </>
  );
}
