// Dev Tools
import {DevTool} from '@hookform/devtools';

// React
import React, {useEffect, useState} from "react";

// Redux
import {useDispatch} from "react-redux";

// Libs
import {Logger} from 'react-logger-lib';
import SimpleJsLog from "../../util/Logger";
import {useTranslation} from "react-i18next";


// Custom
import * as Yup from "yup";
import {yupMinRequiredString, yupStringSameValue} from "../../util/YupUtil";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Alert, Stack, Typography} from "@mui/material";
import {TextFieldZts} from "../base/text-field";
import {Loader} from "../base/loader";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
  updatePasswordByCredentials,
  UpdatePasswordByCredentialsRequest,
  updatePasswordByToken,
  UpdatePasswordByTokenRequest
} from "./Service";
import {TenantCredentials, UserLoginResponse} from "../login/model";
import {
  getToken,
  getUserByToken, getZtsJwtPayload,
  hasLoginPortalJwt,
  saveLoginPortalJwt,
  saveToken
} from "../../config/token";
import {saveApiUrl} from "../../config/environment";
import {setLoggedUser} from "../../reducers/Logged";
import Box from "@mui/material/Box";
import projectLogo from "../../assets/images/new-logo-ztravel-smart.png";
import PasswordIcon from '@mui/icons-material/Password';
import KeyIcon from '@mui/icons-material/Key';
import {useLicense} from "../../hooks/useLicense";
import {updateLastAccess} from "../login/Service";


const LOG: SimpleJsLog = Logger.of('ZTS.Password.PasswordUpdateForm');

/*
Questa form funziona in due modalità
by tokenId
by utente loggato

** Attenzione devo usare il jwt del portale di login
*/

type ComponentProps = {
  showInModal?: boolean,
  afterUpdateHandler?: () => void
}

type AuthType = 'token' | 'password';

type PasswordUpdateFormType = {
  authType: AuthType
  authValue: string
  newPassword: string
  confirmNewPassword: string
}

export const PasswordUpdateForm: React.FC<ComponentProps> = ({
                                                               showInModal,
                                                               afterUpdateHandler
                                                             }) => {

  const {setProfessional, clearProfessional} = useLicense();

  const readParam = (key: string, defValue: string) => {
    const value = searchParams.get(key);
    if (value === null) {
      return defValue;
    }
    return value;
  }

  let [searchParams, setSearchParams] = useSearchParams();

  const {i18n} = useTranslation(['validation']);
  const {t} = useTranslation('password', {keyPrefix: 'label'});
  const {t: tModal} = useTranslation('password', {keyPrefix: 'label.showInModal'});
  const {t: tPasswordValidation} = useTranslation('password', {keyPrefix: 'error'});
  const {t: tValidation} = useTranslation('validation');
  const {t: tErr} = useTranslation('api-error');

  const tokenId = readParam('tokenId', '');
  const firstAccess: boolean = readParam('firstAccess', '') === 'true';

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const passwordRecoveryLbl = tokenId !== '' ? t('passwordRecovery') : t('passwordExpired');
  const firstAccessLbl = t('firstAccess');
  const oldPasswordLbl = t('oldPassword');
  const newPasswordLbl = t('newPassword');
  const confirmNewPasswordLbl = t('confirmNewPassword');
  const executeLbl = showInModal ? tModal('execute') : t('execute');


  const startFormValues: PasswordUpdateFormType = {
    authType: tokenId === '' ? 'password' : 'token',
    authValue: tokenId,
    newPassword: '',
    confirmNewPassword: ''
  }

  const validationSchema = Yup.object({
    authValue: yupMinRequiredString(4, tValidation),
    newPassword: yupMinRequiredString(4, tValidation),
    confirmNewPassword: yupStringSameValue(
      {name: 'newPassword', label: newPasswordLbl},
      tValidation,
      {sameValue: {key: 'notMatchingPasswords'}})
  });

  const formMethods = useForm<PasswordUpdateFormType>({
    defaultValues: startFormValues,
    resolver: yupResolver(validationSchema),
    mode: "onSubmit"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [hideErrorMsg, setHideErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [successFlag, setSuccessFlag] = useState<boolean>(false);

  const onExecuteClick = () => {
    LOG.trace('onExecuteClick');

    // if (afterUpdateHandler) {
    //   afterUpdateHandler();
    // }

    formMethods.handleSubmit(onFormValid, onFormError)();
  }

  const onFormError = (errors) => {
    // Non ci sono errori nella form da gestire
    // Lascio il log a livello error per avere una eventuale traccia
    LOG.error('onFormError', errors);
  }

  const onFormValid = (data: PasswordUpdateFormType) => {
    LOG.trace('onFormValid');

    updatePassword(data)
      .then(() => LOG.trace('updatePassword [async] end.'));
  }

  const handleLogin = () => {
    const jwtBody = getZtsJwtPayload();
    if (jwtBody !== null && jwtBody.lic === 'pro') {
      setProfessional();
      setSuccessFlag(true);
      setSaving(false);
    } else {
      clearProfessional();
    }
    getUserByToken(getToken()).then(
      user => {
        dispatch(setLoggedUser(user));
        navigate('/');
      }
    );
  }

  const updatePassword = async (data: PasswordUpdateFormType) => {
    LOG.trace('updatePassword [async]', data);
    setSaving(true);
    setHideErrorMsg(false);
    setErrorMsg(null);

    try {
      let obj: UserLoginResponse;
      if (data.authType === 'token') {
        const req: UpdatePasswordByTokenRequest = {
          tokenId: data.authValue,
          newPassword: data.newPassword
        }
        obj = await updatePasswordByToken(req, tPasswordValidation);
      } else {
        //data.authType==='password'
        const req: UpdatePasswordByCredentialsRequest = {
          oldPassword: data.authValue,
          newPassword: data.newPassword
        }
        obj = await updatePasswordByCredentials(req, tPasswordValidation);
      }

      // Mi salvo le nuove credenziali
      saveLoginPortalJwt(obj.loginCredentials.accessToken);
      if (obj.tenantsCredentials) {
        const first: TenantCredentials = obj.tenantsCredentials[0];
        const apiUrl = first.url + '/api';
        saveApiUrl(apiUrl);
        saveToken(first.accessToken);
        updateLastAccess();

        if (firstAccess) {
          handleLogin();
          return;
        }

        if (tokenId !== '') {
          setSuccessFlag(true);
          setSaving(false);
          return;
        }

        if (afterUpdateHandler) {
          afterUpdateHandler();
        }

        if (tokenId === '') {
          const jwtBody = getZtsJwtPayload();
          if (jwtBody !== null && jwtBody.lic === 'pro') {
            setProfessional();
            setSuccessFlag(true);
            setSaving(false);
          } else {
            clearProfessional();
          }
          getUserByToken(first.accessToken).then(
            user => {
              dispatch(setLoggedUser(user));
              navigate('/');
            }
          );
        }
      }

      setSaving(false);
    } catch (e: any) {
      setErrorMsg(e.message);
      LOG.error('Unable to update password', e);
      setSaving(false);
    }

  }

  //TODO la lingua deve essere passata da chi chiama
  const language = 'it';

  useEffect(() => {
    LOG.trace('useEffect []');
    if (i18n.language !== language) {
      i18n.changeLanguage(language)
        .then(() => LOG.trace('i18n changeLanguage done.'));
    }
    if (tokenId === '' && !hasLoginPortalJwt()) {
      LOG.trace('User is not logged in and has no token. Going to login');
      navigate('/login');
    }
  }, []);

  return (
    showInModal ? (
      <Box>
        <Stack>
          <FormProvider {...formMethods}>
            <form noValidate>
              <Box>
                <Typography mb={1}>{tModal('title.oldPassword')}</Typography>
                <Controller
                  render={({field, fieldState}) =>
                    <TextFieldZts
                      {...field}
                      required
                      type='password'
                      label={oldPasswordLbl}
                      errorMsg={fieldState.error?.message}
                    />
                  }
                  name='authValue'
                />
              </Box>
              <Box>
                <Typography mb={1}>{tModal('title.newPassword')}</Typography>
                <Controller
                  render={({field, fieldState}) =>
                    <TextFieldZts
                      {...field}
                      required
                      type='password'
                      label={newPasswordLbl}
                      errorMsg={fieldState.error?.message}
                      fullWidth
                    />
                  }
                  name='newPassword'
                />
              </Box>
              <Box>
                <Typography mb={1}>{tModal('title.confirmNewPassword')}</Typography>
                <Controller
                  render={({field, fieldState}) =>
                    <TextFieldZts
                      {...field}
                      required
                      type='password'
                      label={confirmNewPasswordLbl}
                      errorMsg={fieldState.error?.message}
                    />
                  }
                  name='confirmNewPassword'
                />
              </Box>
              <Box display={'flex'} flexDirection={'row-reverse'}>
                <Box
                  onClick={onExecuteClick}
                  sx={{
                    backgroundColor: '#3315d6',
                    borderRadius: '5px',
                    cursor: 'pointer'
                  }}
                  display={'flex'}
                  justifyContent={'center'}
                  width={'fit-content'}
                  height={'fit-content'}
                  px={'32px'}
                  py={'8px'}
                >
                  <Typography variant={'body1'} color={'white'}>{executeLbl}</Typography>
                </Box>
              </Box>
            </form>
          </FormProvider>
          {!hideErrorMsg && errorMsg &&
            <Alert
              severity={"error"}
              variant={"filled"}
              sx={{
                mt: 2
              }}
              onClose={() => setHideErrorMsg(true)}>
              <div dangerouslySetInnerHTML={{__html: errorMsg}}/>
            </Alert>
          }
        </Stack>
      </Box>
    ) : (
      <Box display={'flex'} width={'100vw'} height={'100vh'} alignSelf={'center'} justifyContent={'center'}>
        <Box display={'flex'} sx={{backgroundColor: 'white', borderRadius: '25px'}}
             height={'fit-content'} p={4} m={1}>
          <Box minWidth={'fit-content'}>
            <Stack spacing={3}>
              <img src={projectLogo} width={175} alt="ZTravel Smart"/>

              <Box>
                <Typography variant={'h2'} mb={1} textAlign={'center'}>{firstAccess ? firstAccessLbl : passwordRecoveryLbl}</Typography>
                {
                  tokenId !== '' && !firstAccess &&
                  <Typography variant={'h3'} textAlign={'center'}>{t('passwordRecoveryStep', {step: 2})}</Typography>
                }
                {
                  tokenId === '' &&
                  <Typography variant={'subtitle1'} textAlign={'center'}>{t('passwordExpiredSubTitle')}</Typography>
                }

              </Box>

              <Stack flexDirection={'row'} columnGap={2} alignItems={'center'}>
                <Box
                  height={'fit-content'}
                  sx={{
                    backgroundColor: '#14c6d5',
                    borderRadius: '100%'
                  }}
                  p={2}
                  display={'flex'}
                  justifyContent={'center'}
                  alignSelf={'start'}
                  mt={1}
                >
                  {
                    firstAccess ? (
                      <KeyIcon fontSize={'large'} sx={{color: 'white'}}/>
                    ) : (
                      <PasswordIcon fontSize={'large'} sx={{color: 'white'}}/>
                    )
                  }

                </Box>
                <Stack width={'100%'}>
                  {
                    !successFlag && tokenId === '' &&
                    (
                      <>
                        <FormProvider {...formMethods}>
                          <form noValidate>
                            <Box>
                              <Typography mb={1}>{tModal('title.oldPassword')}</Typography>
                              <Controller
                                render={({field, fieldState}) =>
                                  <TextFieldZts
                                    {...field}
                                    required
                                    type='password'
                                    label={oldPasswordLbl}
                                    errorMsg={fieldState.error?.message}
                                  />
                                }
                                name='authValue'
                              />
                            </Box>
                            <Box>
                              <Typography mb={1}>{tModal('title.newPassword')}</Typography>
                              <Controller
                                render={({field, fieldState}) =>
                                  <TextFieldZts
                                    {...field}
                                    required
                                    type='password'
                                    label={newPasswordLbl}
                                    errorMsg={fieldState.error?.message}
                                    fullWidth
                                  />
                                }
                                name='newPassword'
                              />
                            </Box>
                            <Box>
                              <Typography mb={1}>{tModal('title.confirmNewPassword')}</Typography>
                              <Controller
                                render={({field, fieldState}) =>
                                  <TextFieldZts
                                    {...field}
                                    required
                                    type='password'
                                    label={confirmNewPasswordLbl}
                                    errorMsg={fieldState.error?.message}
                                  />
                                }
                                name='confirmNewPassword'
                              />
                            </Box>
                            <Box
                              onClick={onExecuteClick}
                              sx={{
                                backgroundColor: '#14c6d5',
                                borderRadius: '5px',
                                cursor: 'pointer'
                              }}
                              display={'flex'}
                              justifyContent={'center'}
                              width={'fit-content'}
                              height={'fit-content'}
                              px={'32px'}
                              py={'8px'}
                            >
                              <Typography variant={'body1'} color={'white'}>{executeLbl}</Typography>
                            </Box>
                          </form>
                        </FormProvider>
                        {!hideErrorMsg && errorMsg &&
                          <Alert
                            severity={"error"}
                            variant={"filled"}
                            sx={{
                              mt: 2
                            }}
                            onClose={() => setHideErrorMsg(true)}>
                            <div dangerouslySetInnerHTML={{__html: errorMsg}}/>
                          </Alert>
                        }
                      </>
                    )
                  }
                  {
                    !successFlag && tokenId !== '' &&
                    (
                      <>
                        <FormProvider {...formMethods}>
                          <form noValidate>
                            <Box>
                              <Typography mb={1}>{t('title.newPassword')}</Typography>
                              <Controller
                                render={({field, fieldState}) =>
                                  <TextFieldZts
                                    className={'pwdRecoveryField'}
                                    {...field}
                                    required
                                    type='password'
                                    label={newPasswordLbl}
                                    errorMsg={fieldState.error?.message}
                                    fullWidth
                                  />
                                }
                                name='newPassword'
                              />
                            </Box>
                            <Box>
                              <Typography mb={1}>{t('title.confirmNewPassword')}</Typography>
                              <Controller
                                render={({field, fieldState}) =>
                                  <TextFieldZts
                                    className={'pwdRecoveryField'}
                                    {...field}
                                    required
                                    type='password'
                                    label={confirmNewPasswordLbl}
                                    errorMsg={fieldState.error?.message}
                                  />
                                }
                                name='confirmNewPassword'
                              />
                            </Box>
                            <Box
                              onClick={onExecuteClick}
                              sx={{
                                backgroundColor: '#14c6d5',
                                borderRadius: '5px',
                                cursor: 'pointer'
                              }}
                              display={'flex'}
                              justifyContent={'center'}
                              width={'fit-content'}
                              height={'fit-content'}
                              px={'32px'}
                              py={'8px'}
                            >
                              <Typography variant={'body1'} color={'white'}>{executeLbl}</Typography>
                            </Box>
                          </form>
                        </FormProvider>
                        {!hideErrorMsg && errorMsg &&
                          <Alert
                            severity={"error"}
                            variant={"filled"}
                            sx={{
                              mt: 2
                            }}
                            onClose={() => setHideErrorMsg(true)}>
                            <div dangerouslySetInnerHTML={{__html: errorMsg}}/>
                          </Alert>
                        }
                      </>
                    )
                  }
                  {
                    successFlag &&
                    (
                      <>
                        <Typography>{t('successMessage')}</Typography>
                        <Box
                          onClick={handleLogin}
                          sx={{
                            backgroundColor: '#14c6d5',
                            borderRadius: '5px',
                            cursor: 'pointer'
                          }}
                          display={'flex'}
                          justifyContent={'center'}
                          width={'100%'}
                          height={'fit-content'}
                          px={'32px'}
                          py={'8px'}
                          mt={2}
                        >
                          <Typography variant={'body1'} color={'white'}>{t('login')}</Typography>
                        </Box>
                      </>
                    )
                  }
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    )
  );

}
