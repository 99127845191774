import {ActivityLogState} from "../model";
import {Box, Stack, Typography} from "@mui/material";
import {formatDateTime} from "../../../util/DateUtil";
import * as React from "react";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import RecommendIcon from '@mui/icons-material/Recommend';
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";

interface CheckExpensesActivityProps {
  title: string,
  timeStamp: Date,
  datesRange?: string[],
  state: ActivityLogState,
  errorMessage?: string
}

export const CheckExpensesActivity = ({
                                        title,
                                        timeStamp,
                                        datesRange,
                                        state,
                                        errorMessage
                                      }: CheckExpensesActivityProps) => {
  const {t} = useTranslation(['activity-register']);
  const {t: tErr} = useTranslation('api-error');
  const {userLocale} = useLoggedUser();

  const [showError, setShowError] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  return (
    <Box position={'relative'}>
      <Typography mb={0.5} ml={4} variant={"subtitle2"}>{formatDateTime(timeStamp, userLocale)}</Typography>

      <Stack flexDirection={"row"} columnGap={1}>
        <Stack flexDirection={"row"} columnGap={1.5}>
          <RecommendIcon fontSize={"small"} sx={{alignSelf: 'center', color: '#14c6d5'}}/>
          <Stack flexDirection={"column"} alignSelf={'center'}>
            <Typography variant={"h5"}>{t(title)}</Typography>
          </Stack>
          {
            ActivityLogState[state] === ActivityLogState.CS &&
            <CheckIcon fontSize={"small"} sx={{alignSelf: 'center', color: '#2DD37A'}}/>
          }
          {
            ActivityLogState[state] === ActivityLogState.CE &&
            <ErrorIcon fontSize={"small"} sx={{alignSelf: 'center', color: 'red'}}/>
          }
          {
            ActivityLogState[state] === ActivityLogState.CW &&
            <WarningIcon fontSize={"small"} sx={{alignSelf: 'center', color: 'orange'}}/>
          }
        </Stack>
        <Box flexGrow={1}/>
        {
          ActivityLogState[state] === ActivityLogState.CE &&
          errorMessage &&
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
              backgroundColor: '#e7e6e6',
              borderRadius: '10px',
              paddingBottom: '3px',
              paddingTop: '3px',
              paddingLeft: '20px',
              paddingRight: '20px',
              position: 'absolute',
              bottom: '1px',
              right: '5px'
            }}
            alignContent={'center'}
            onClick={() => setShowError(!showError)}
          >
            <Typography color={'gray'} variant={"subtitle1"}>{t('button.details')}</Typography>
          </Box>
        }
        {
          ActivityLogState[state] === ActivityLogState.CW &&
          errorMessage &&
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
              backgroundColor: '#e7e6e6',
              borderRadius: '10px',
              paddingBottom: '3px',
              paddingTop: '3px',
              paddingLeft: '20px',
              paddingRight: '20px',
              position: 'absolute',
              bottom: '1px',
              right: '5px'
            }}
            alignContent={'center'}
            onClick={() => setShowWarning(!showWarning)}
          >
            <Typography color={'gray'} variant={"subtitle1"}>{t('button.details')}</Typography>
          </Box>
        }
      </Stack>

      <Typography mt={1} ml={4}
                  variant={"h6"}>{datesRange ? `Dal ${datesRange[0]} al ${datesRange[1]}` : ''}</Typography>
      {
        showError &&
        <Typography maxWidth={'70%'} mt={1} ml={4} variant={'subtitle1'} color={'red'}
                    textOverflow={'ellipsis'}>{errorMessage ? tErr(errorMessage) : ''}</Typography>
      }
      {
        showWarning &&
        <Typography maxWidth={'70%'} mt={1} ml={4} variant={'subtitle1'} color={'orange'}
                    textOverflow={'ellipsis'}>{errorMessage ? tErr(errorMessage) : ''}</Typography>
      }
    </Box>
  );
}
