import {AutocompleteGetTagProps, Typography, useAutocomplete} from "@mui/material";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import React, {useState} from "react";
import CloseIcon from "@mui/icons-material/Close";

interface ComponentProps {
  setSelectedValues: (selctedValues) => void
}

export const MultipleSelectionsAutocompleteZts = ({setSelectedValues}: ComponentProps) => {
  //const [selectedOptions, setSelectedOptions] = useState<{ id: number; label: string; }[]>([]);
  const options: {id: number, code: string, label: string}[] = [
    {id: 1, code: 'DRF', label: 'Da completare'},
    {id: 2, code: 'APR', label: 'Da approvare'},
    {id: 3, code: 'CHK', label: 'Da controllare'},
    {id: 4, code: 'RJC', label: 'Da rivedere'},
    {id: 5, code: 'LQD', label: 'Da liquidare'},
    {id: 6, code: 'ACN', label: 'Da contabilizzare'},
    {id: 7, code: 'TAR', label: 'Da archiviare'},
    {id: 8, code: 'ARC', label: 'Archiviate'},
  ];

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-auto-complete',
    defaultValue: [],
    onChange: (e, selectedOpts) => {
      setSelectedValues(selectedOpts);
    },
    multiple: true,
    options: options,
    getOptionLabel: (option) => option.label,
    isOptionEqualToValue: (option, value) => option.label === value.label
  });

  interface TagProps extends ReturnType<AutocompleteGetTagProps> {
    label: string;
  }

  const Tag = (props: TagProps) => {
    const {label, onDelete, ...other} = props;
    return (
      <Box display={'inline-flex'} flexDirection={'row'} {...other} alignItems={'center'} mr={0.5} columnGap={0.5} p={1} sx={{backgroundColor: '#fafafa', borderRadius: '15px'}}>
        <Typography variant={'h5'} fontWeight={'400'}>{label}</Typography>
        <CloseIcon sx={{width: '12px', height: '12px', borderRadius: '50%', cursor: 'pointer'}} onClick={onDelete}/>
      </Box>
    )
  }

  return (
    <Box>
      <Typography {...getInputLabelProps()} variant={"h6"} textTransform={"uppercase"} mt={3} mb={2}>Stato nota spese</Typography>
      <Box
        {...getRootProps()}
        flexDirection={'row'}
        ref={setAnchorEl}
        p={1}
        height={'fit-content'}
        sx={{
          backgroundColor: 'white',
          borderRadius: '4px',
          border: '1px solid #d9d9d9'
        }}
      >
        {
          value.map((opt, index) => {
            return <Tag label={opt.label} {...getTagProps({index})} />
          })
        }
        <input
          className={'multiSelectionsAutocomplete'}
          {...getInputProps()}
        />
      </Box>
      {
        groupedOptions.length > 0 ? (
          <ul
            {...getListboxProps()}
            style={{
              listStyle: 'none',
              maxWidth: '300px',
              margin: '2px 0 0',
              padding: '0',
              backgroundColor: 'white',
              overflow: 'auto',
              maxHeight: '250px',
              borderRadius: '4px',
              boxShadow: '0 2px 16px 4px rgba(0, 0, 0, 0.15)',
              position: "absolute",
              zIndex: 1,
            }}
          >
            {
              (groupedOptions as typeof options).map((option, index) => (
                  <li
                    {...getOptionProps({option, index})}
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      padding: '5px 12px',
                      justifyContent: 'space-between'
                    }}
                    className={'multiSelectionsAutocompleteOption'}
                  >
                    <Typography variant={'body1'} mr={1}>{option.label}</Typography>
                    <CheckIcon fontSize="small"/>
                  </li>
                )
              )
            }
          </ul>
        ) : null
      }
    </Box>
  );
}
