import * as React from 'react';
import { Article, Home, ListAlt, Policy } from '@mui/icons-material';
import { MenuArchivioStoricoNoteSpese, MenuNoteSpese } from '../../base/svg-icons';
import SettingsIcon from '@mui/icons-material/Settings';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import {
  ACCOUNT_URL,
  ALLEXPNOTE_URL,
  APPROVEEXPNOTE_URL,
  EXPNOTE_URL,
  HOME_ADMIN_URL,
  HOME_TRAVELLER_URL,
  LEVEL_URL,
  PROJECT_URL,
  REACT_TRAVEL_POLICIES_BASE_URL,
  SUPPLIER_URL,
  USER_URL,
  HISTORICAL_EXPNOTE_URL, CRE_CARD_MOV_URL
} from '../../model';
import { getZtsJwtPayload } from '../../../config/token';
import { PopupContentType } from '../../license-popup/model';

export interface MenuItem {
  id: number,
  label: string,
  route: string,
  premium?: boolean
}

export interface InnerMenu {
  id: number,
  label: string,
  icon?: JSX.Element,
  route?: string,
  items?: MenuItem[],
  premiumPopupType?: PopupContentType
}

export interface OuterMenu {
  id: number,
  label: string,
  inner: InnerMenu[]
}

const createMenuIcon = (component: any): JSX.Element => {
  return React.createElement(component, {fontSize: 'small'}, null);
}

export const getMenuItemsData = (isAdmin: boolean, isTraveller: boolean, isApprover: boolean, hasArchivedExpNote: boolean): OuterMenu[] => {
  let menuAdmin: OuterMenu | null = null;

  const modules = getZtsJwtPayload();
  let hasCreditCardModule = false;
  if (modules && modules.mod?.includes("cc")) {
    hasCreditCardModule = true;
  }

  if (isAdmin) {
    menuAdmin = {
      id: 1,
      label: 'admin',
      inner: [
        {
          id: 99,
          label: 'home',
          icon: createMenuIcon(Home),
          route: HOME_ADMIN_URL
        },
        {
          id: 1,
          label: 'personal-data',
          icon: createMenuIcon(Article),
          items: [
            {id: 1, label: 'users', route: USER_URL},
            {id: 2, label: 'levels', route: LEVEL_URL, premium: true},
            {id: 3, label: 'accounts', route: ACCOUNT_URL, premium: true},
            {id: 5, label: 'suppliers', route: SUPPLIER_URL, premium: true},
            {id: 6, label: 'projects', route: PROJECT_URL, premium: true}
          ]
        },
        {
          id: 2,
          label: 'exp-notes',
          icon: createMenuIcon(MenuNoteSpese),
          route: ALLEXPNOTE_URL,
        },
        {
          id: 3,
          label: 'travel-policy',
          icon: createMenuIcon(Policy),
          route: REACT_TRAVEL_POLICIES_BASE_URL
        },
        {
          id: 4,
          label: 'cre-card-mov',
          icon: createMenuIcon(CreditCardIcon),
          route: CRE_CARD_MOV_URL,
          premiumPopupType: !hasCreditCardModule ? PopupContentType.CREDIT_CARD_MODULE : undefined
        },
        {
          id: 6,
          label: 'reports',
          icon: createMenuIcon(TrendingUpIcon),
          route: '/report'
        },
        {
          id: 7,
          label: 'settings',
          icon: createMenuIcon(SettingsIcon),
          route: '/settings'
        }

      ]
    };
  }

  if (isAdmin && hasArchivedExpNote ){
    menuAdmin?.inner.push(
      {
        id: 10,
        label: 'historical-exp-notes',
        icon: createMenuIcon(MenuArchivioStoricoNoteSpese), //TODO modificare icona archivio storico^
        route: HISTORICAL_EXPNOTE_URL,
      }
    );
  }

  let menuTraveller: OuterMenu | null = null;
  if (isTraveller) {
    menuTraveller = {
      id: 2,
      label: 'traveller',
      inner: [
        {
          id: 99,
          label: 'home',
          icon: createMenuIcon(Home),
          route: HOME_TRAVELLER_URL
        },
        {
          id: 1,
          label: 'personal-exp-note',
          route: EXPNOTE_URL
        }
      ]
    };

    if (isApprover) {
      menuTraveller = {
        ...menuTraveller,
        inner: [
          ...menuTraveller.inner,
          {
            id: 2,
            label: 'expNoteToApprove',
            route: APPROVEEXPNOTE_URL,
            icon: createMenuIcon(ListAlt)
          }
        ]
      }
    }
  }

  const items: OuterMenu[] = [];
  if (menuAdmin) {
    items.push(menuAdmin);
  }
  if (menuTraveller) {
    items.push(menuTraveller);
  }
  return items;
}
