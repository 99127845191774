import {Skeleton, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, TooltipProps} from "recharts";
import {ExpenseCategory, getExpenseCategoryOrder} from "../../expenses/model";
import {getCategories} from "../../exp_note/detail/tabs/dashboard/chart/model";
import {useTranslation} from "react-i18next";
import {formatAmount} from "../../../util/NumberUtil";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {SelectZts} from "../../base/select";
import {
  DashboardExpenseTotalByCategoryMonth,
  DashboardExpenseTotalRange,
  getChartRangeLabel,
  getDashboardExpenseTotalByCategoryMonthRange,
  saveDashboardExpenseTotalByCategoryMonthRange
} from "../model";
import {getExpenseTotalsByCategoryMonth} from "../Service";
import SimpleJsLog from "../../../util/Logger";
import {Logger} from 'react-logger-lib';

const LOG: SimpleJsLog = Logger.of('ZTS.Dashboard.DashboardChartCategory');

export const DashboardChartCategory = () => {

  const {
    userLocale,
    companyCurrencyCode,
    companyDecimalNum
  } = useLoggedUser();

  const {t} = useTranslation(['expense', 'exp-note']);
  const {t: tChart} = useTranslation('dashboard', {keyPrefix: 'admin.chart'});

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DashboardExpenseTotalByCategoryMonth[]>([]);
  const [rangeType, setRangeType] = useState<DashboardExpenseTotalRange>(getDashboardExpenseTotalByCategoryMonthRange());

  const rangeOptions: DashboardExpenseTotalRange[] = [
    DashboardExpenseTotalRange.CURRENT_YEAR,
    DashboardExpenseTotalRange.LAST_TWELVE_MONTHS,
    DashboardExpenseTotalRange.LAST_SIX_MONTHS,
    DashboardExpenseTotalRange.LAST_MONTH,
  ];

  useEffect(() => {
    setLoading(true);
    getExpenseTotalsByCategoryMonth(rangeType)
      .then(res => {
        setData(res.sort((d1, d2) => {
          const category1 = getExpenseCategoryOrder(d1.expenseCategory);
          const category2 = getExpenseCategoryOrder(d2.expenseCategory);
          if (category1 > category2) {
            return 1;
          } else if (category1 < category2) {
            return -1;
          }
          return 0;
        }));
      })
      .catch(err => LOG.trace(`Error retrieving expense total by category/month: ${err}`))
      .finally(() => setLoading(false));
  }, [rangeType]);

  // const data = [
  //   {expenseCategory: 'VT', total: 400},
  //   {expenseCategory: 'AL', total: 300},
  //   {expenseCategory: 'VR', total: 300},
  //   {expenseCategory: 'AP', total: 110},
  //   {expenseCategory: 'MT', total: 80}
  // ];

  const handleChangeRange = (rangeType: string) => {
    const range: DashboardExpenseTotalRange = rangeType as DashboardExpenseTotalRange;
    setRangeType(range);
    saveDashboardExpenseTotalByCategoryMonthRange(range);
  }

  const categories = getCategories(t);

  const getColor = (category: ExpenseCategory): string => {
    const found = categories.find(c => c.value === category);
    return found ? found.color : "#000";
  }

  const getLegendLabel = (category: ExpenseCategory): string => {
    const found = categories.find(c => c.value === category);
    return found ? found.label : "";
  }

  const renderTotal = (props) => {
    const total = data.map(d => d.total).reduce((b, sum) => sum + b);
    return (
      <>
        <text
          x={props.viewBox.cx - 30}
          y={props.viewBox.cy - 7}
          fontSize={13}
          fontWeight={600}
          fill="#0e053b80"
        >
          {tChart("category.total")}
        </text>
        <text
          x={props.viewBox.cx}
          y={props.viewBox.cy + 7}
          fontSize={12}
          fontWeight={500}
          textAnchor="middle"
        >
          {formatAmount(total, userLocale, companyDecimalNum, companyCurrencyCode)}
        </text>
      </>
    );
  }

  const renderTooltip = (props: TooltipProps<any, any>) => {
    //console.log(props)
    const {active, payload} = props;

    if (!active || !payload) {
      return null;
    }

    const fontSize = 12;

    const data = payload[0].payload;

    const label = categories.find(c => c.value === data.expenseCategory)?.label || "";

    return (
      <div className="custom-tooltip-dashboard-category">
        <Typography variant="h6" mb={2}>{label}</Typography>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            variant="h5"
            fontSize={`${fontSize}px`}
          >
            {tChart("category.total")}
          </Typography>
          <Typography
            variant="h5"
            fontSize={`${fontSize}px`}
          >
            {formatAmount(data.total, userLocale, companyDecimalNum, companyCurrencyCode)}
          </Typography>
        </Box>
      </div>
    );
  };

  const renderLegend = (props) => {
    //console.log(props);
    const {payload} = props;

    return (
      <Stack
        mt={1}
        rowGap={0.5}
      >
        {payload
          .map(e => e.payload)
          .map(entry => (
            <Stack
              key={entry.expenseCategory}
              direction="row"
              alignItems="center"
              columnGap={1}
            >
              <Box
                width="13px"
                height="13px"
                borderRadius="3px"
                flexShrink="0"
                sx={{
                  backgroundColor: getColor(entry.expenseCategory)
                }}
              />
              <Typography variant="h6" fontSize="12px !important">{getLegendLabel(entry.expenseCategory)}</Typography>
            </Stack>
          ))
        }
      </Stack>
    );
  }

  return (
    <Stack
      height="100%"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          flexBasis="40%"
          flexShrink={0}
          variant="h5"
          mr={1.5}
        >{tChart("category.title")}</Typography>
        <Box
          overflow="hidden"
          height="30px"
          paddingTop="2px"
        >
          <SelectZts
            label=""
            width="100%"
            disableHelperText
            items={rangeOptions.map(opt => ({value: opt, label: getChartRangeLabel(tChart, opt)}))}
            selectedValue={rangeType}
            setValue={handleChangeRange}
            simplifiedLayout
          />
        </Box>
      </Stack>
      <Box flexGrow={1}>

        {loading &&
          <Skeleton
            variant="circular"
            width="120px"
            height="120px"
            sx={{margin: "110px auto"}}
          />
        }

        {!loading && data.length > 0 &&
          <ResponsiveContainer
            width="100%"
            height="100%"
          >
            <PieChart>
              <Pie
                data={data}
                innerRadius={53}
                outerRadius={70}
                fill="#000"
                dataKey="total"
                paddingAngle={-10}
                cornerRadius={40}
                cx={"55%"}
                cy={"56%"}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={getColor(entry.expenseCategory as unknown as ExpenseCategory)}
                    style={{borderRadius: "10px"}}
                  />
                ))}
                <Label
                  value=""
                  position="center"
                  content={renderTotal}
                />
              </Pie>
              <Tooltip
                content={(props) => renderTooltip(props)}
              />
              <Legend
                content={renderLegend}
                verticalAlign="top"
                wrapperStyle={{
                  top: 0
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        }

      </Box>
    </Stack>
  );
}
