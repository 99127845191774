import {Alert, Paper, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import {PageTitle} from "../../layout/page-title";
import {LevelDetailLoader} from "./LevelDetailLoader";
import {disableLevel, getLevelById} from "../Service";
import {GeneralData} from "./GeneralData";
import {getLevelCodeAndDescription, Level} from "../model";
import {DeleteForever, Edit} from "@mui/icons-material";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {TooltipZts} from "../../base/tooltip";
import {useTranslation} from "react-i18next";


export const LevelDetail = () => {

  const [loadingData, setLoadingData] = useState(true);
  const [level, setLevel] = useState<Level | null>(null);
  const [levelUpdated] = useState<Date | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [premiumFeatureMsg, setPremiumFeatureMsg] = useState('');

  const navigate = useNavigate();
  const {convertError} = useErrorMessage();
  const {t} = useTranslation('level', {keyPrefix: 'detail.button'});

  let {id} = useParams();

  useEffect(() => {
    if (id) {
      setLoadingData(true);

      getLevelById(id)
        .then(u => setLevel(u))
        .catch(err => setErrorMsg(`Error retrieving data. ${err}`))
        .finally(() => setLoadingData(false))
    }
  }, [levelUpdated, id]);

  const handleEditClicked = () => {
    if (level) {
      navigate(`/levels/edit/${level.id}`);
    }
  }

  const backToList = () => {
    navigate(`/levels`);
  }

  const handlePremiumFeatureClose = () => {
    setPremiumFeatureMsg('');
  }

  const handleDisableClicked = () => {
    setLoadingData(true);
    if (level) {
      disableLevel(level.id)
        .then(() => {
          setLoadingData(false);
          navigate('/levels');
        })
        .catch(async error => {
          setLoadingData(false);
          const msg = await convertError(createError(error));
          setErrorMsg(msg);
        })
    }
  }

  return (
    <>

      <Box
        overflow={"hidden"}
        flexGrow={1}
        mx={"auto"}
        width={loadingData ? "100%" : "100%"}
      >

        {/* Skeleton */}
        {loadingData && <LevelDetailLoader/>}

        {!loadingData && level && <>
          <Paper
            elevation={0}
            sx={{
              height: "100%"
            }}
          >

            <Box
              height={"100%"}
              overflow={"auto"}
              padding={"20px 30px 20px 20px"}
            >

              {errorMsg && <Alert
                sx={{my: 1}}
                severity={"error"}
                onClose={() => setErrorMsg(null)}
              >{errorMsg}</Alert>}

              {/* titolo + pulsanti */}
              <PageTitle
                backClickHandler={backToList}
                title={
                  <Stack
                    direction={{xs: "column", sm: "row"}}
                    alignItems={{xs: "flex-start", sm: "center"}}
                    justifyContent={{xs: "center", sm: "flex-start"}}
                    columnGap={1.5}
                    flexGrow={"1"}
                  >
                    <Typography
                      variant={"h3"}
                      mr={3}
                      className={"detail-title-wrapper"}
                    >{getLevelCodeAndDescription(level)}</Typography>
                  </Stack>
                }
                actions={
                  <>
                    <TooltipZts
                      title={t('disable')}
                      placement={"bottom"}
                      enterDelay={400}
                    >
                      <IconButton
                        color="primary"
                        className={"delete-button"}
                        onClick={handleDisableClicked}
                      >
                        <DeleteForever/>
                      </IconButton>
                    </TooltipZts>

                    <TooltipZts
                      title={t('modify')}
                      placement={"bottom"}
                      enterDelay={400}
                    >
                      <IconButton
                        color="primary"
                        className={"edit-button"}
                        onClick={handleEditClicked}
                      >
                        <Edit fontSize={"small"}/>

                      </IconButton>
                    </TooltipZts>
                  </>
                }
              />

              {/* dettagli (sx) + elementi collegati (Travel policy, commesse, ecc..) (dx) */}
              <Stack
                mt={2}
                direction={{
                  xs: "column",
                  lg: "row"
                }}
                columnGap={2}
              >

                {/* parte sx */}
                <Stack
                  flexGrow={1}
                  direction={{
                    xs: "column-reverse",
                    sm: "row"
                  }}
                  columnGap={2}
                  width={"100%"}
                >

                  {/* Dati generali */}
                  <GeneralData level={level}/>

                </Stack>
              </Stack>

            </Box>
          </Paper>

        </>
        }

      </Box>
    </>
  );
}
