import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import {PageTitle} from "../layout/page-title";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NonGroupedList} from "./list/NonGroupedList";
import {CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS, CreCardMovStateFilters, ListFilter, ListFilterType} from "./model";
import {ImportConfigurer} from "./import-configurer";
import {Search, UploadFile} from "@mui/icons-material";
import {SelectZts} from "../base/select";
import {SelectOption} from "../base/select/model";
import {DatePickerZts} from "../base/date-picker";
import moment from "moment";
import {PerOperationList} from "./list/PerOperationList";
import {PerUploadList} from "./list/PerUploadList";
import {TooltipZts} from "../base/tooltip";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import {CreCardMovReconciliationPopup} from "./popup/CreCardMovReconciliationPopup";
import {getImportConfig, launchPostImportLinking} from "./import-configurer/Service";
import {ColumnDataType, CompleteConfiguration} from "./import-configurer/model";
import {createError, useErrorMessage} from "../../util/ErrorUtil";
import {Loader} from "../base/loader";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import {CreCardMovImportPopup} from "./popup/CreCardMovImportPopup";
import {useLoggedUser} from "../../hooks/useLoggedUser";
import {FileType} from "../base/file-upload/model";
import {Currency} from "../model";
import {getCurrencies} from "../../util/BaseService";

const ListFilters: ListFilter[] = [
  {
    type: ListFilterType.SEARCH_TEXT,
    label: '',
    value: ''
  },
  {
    type: ListFilterType.STATE,
    label: 'filters.state.label',
    value: 'ALL'
  },
  {
    type: ListFilterType.UPLOAD_DATE_FROM,
    label: 'filters.uploadDate.from',
    value: moment(new Date()).add(-CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS, 'days').toDate()
  },
  {
    type: ListFilterType.UPLOAD_DATE_TO,
    label: 'filters.uploadDate.to',
    value: new Date()
  },
  {
    type: ListFilterType.EXP_DATE_FROM,
    label: 'filters.expenseDate.from',
    value: moment(new Date()).add(-CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS, 'days').toDate()
  },
  {
    type: ListFilterType.EXP_DATE_TO,
    label: 'filters.expenseDate.to',
    value: new Date()
  }
];

export const CreditCardMov = () => {
  const [currentGroup, setCurrentGroup] = useState<string>('noGroup');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [filters, setFilters] = useState<ListFilter[]>(ListFilters);
  const [nextFilters, setNextFilters] = useState<ListFilter[]>(ListFilters);
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);

  const [configurerPopupOpen, setConfigurerPopupOpen] = useState<boolean>(false);
  const [reconciliationPopupOpen, setReconciliationPopupOpen] = useState<boolean>(false);
  const [importPopupOpen, setImportPopupOpen] = useState<boolean>(false);

  const [importCompleteConfiguration, setImportCompleteConfiguration] = useState<CompleteConfiguration | undefined>();
  const [importFileType, setImportFileType] = useState<FileType | undefined>(undefined);

  const [currencies, setCurrencies] = useState<Currency[]>([]);

  const {userLocale} = useLoggedUser();
  const {convertError} = useErrorMessage();
  const {t: tCreCard} = useTranslation('cre-card-mov');

  const creCardMovStateFilterItems: SelectOption[] = Array.from(Object.keys(CreCardMovStateFilters)).map(key => {
    return {
      value: key.valueOf(),
      label: tCreCard(CreCardMovStateFilters[key])
    };
  });

  useEffect(() => {
    asyncGetAndSetImportConfiguration().then();
  }, []);

  const [updateCurrentList, setUpdateCurrentList] = useState<boolean>(false);

  const asyncGetAndSetImportConfiguration = async (): Promise<void> => {
    setLoading(true);
    try {
      const currencies = await getCurrencies();
      setCurrencies(currencies);
      const config = await getImportConfig();
      if (config) {
        const sep = {
          csvSeparator: config.csvSep,
          rowsToSkip: config.rowsToSkip,
          dateFormat: config.dateFormat,
          decimalSeparator: config.decimalSep
        };

        const pos = {
          creCardNumColumn: {index: config.posCreCardNum, isValid: true, dataType: ColumnDataType.NONE},
          payDateColumn: {index: config.posPayDate, isValid: true, dataType: ColumnDataType.DATE},
          compAmountColumn: {index: config.posCompAmount, isValid: true, dataType: ColumnDataType.CURRENCY},
          currencyColumn: {index: config.posCurrency, isValid: true, dataType: ColumnDataType.NONE},
          currAmountColumn: {index: config.posCurrAmount, isValid: true, dataType: ColumnDataType.CURRENCY},
          exchangeColumn: {index: config.posExchange, isValid: true, dataType: ColumnDataType.EXCHANGE},
          creCardMovReferenceColumn: {index: config.posReference, isValid: true, dataType: ColumnDataType.NONE},
          creCardMovDescColumn: {index: config.posDescription, isValid: true, dataType: ColumnDataType.NONE},
          catMercColumn: {index: config.posCatMerc, isValid: true, dataType: ColumnDataType.NONE}
        };

        setImportCompleteConfiguration({
          separators: sep,
          positions: pos
        });

        setImportFileType(config.fileType === 'csv' ? FileType.CSV : FileType.EXCEL);
      }
    } catch (err: any) {
      convertError(createError(err))
        .then(msg => setErrorMsg(msg))
    } finally {
      setLoading(false);
    }
  }

  const onSuccessfulConfiguration = () => {
    closeConfigurerPopup();
    asyncGetAndSetImportConfiguration()
      .then(() => setImportPopupOpen(true));
  }

  const openReconciliationPopup = () => {
    setReconciliationPopupOpen(true);
  }

  const closeReconciliationPopup = () => {
    setReconciliationPopupOpen(false);
  }

  const configureImport = () => {
    setConfigurerPopupOpen(true);
  }

  const closeConfigurerPopup = () => {
    setConfigurerPopupOpen(false);
  }

  const openImportPopup = () => {
    if (importCompleteConfiguration) {
      setImportPopupOpen(true);
    }
  }

  const closeImportPopup = (refresh: boolean) => {
    if (refresh) {
      setUpdateCurrentList(true);
    }
    setImportPopupOpen(false);
  }

  const handleGroupButtonsClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newGroupValue: string = (event.target as HTMLInputElement).value;
    if (newGroupValue) {
      setCurrentGroup(newGroupValue);
    }
  }

  const handleFilterChange = () => {
    let uploadDateFrom;
    let uploadDateTo;
    let expDateFrom;
    let expDateTo;
    let dateDiff;
    switch (currentGroup) {
      case 'noGroups':
        uploadDateFrom = nextFilters.find(f => f.type === ListFilterType.UPLOAD_DATE_FROM)?.value;
        uploadDateTo = nextFilters.find(f => f.type === ListFilterType.UPLOAD_DATE_TO)?.value;
        dateDiff = (new Date(uploadDateTo).getTime() - new Date(uploadDateFrom).getTime()) / (1000 * 3600 * 24);
        if (isNaN(dateDiff) || dateDiff < 0 || dateDiff >= CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS + 1) {
          setErrorMsg(tCreCard('error.dateRangeOutOfBound'));
          return;
        }
        expDateFrom = nextFilters.find(f => f.type === ListFilterType.EXP_DATE_FROM)?.value;
        expDateTo = nextFilters.find(f => f.type === ListFilterType.EXP_DATE_TO)?.value;
        dateDiff = (new Date(expDateTo).getTime() - new Date(expDateFrom).getTime()) / (1000 * 3600 * 24);
        if (isNaN(dateDiff) || dateDiff < 0 || dateDiff >= CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS + 1) {
          setErrorMsg(tCreCard('error.dateRangeOutOfBound'));
          return;
        }
        break;
      case 'perLoading':
        uploadDateFrom = nextFilters.find(f => f.type === ListFilterType.UPLOAD_DATE_FROM)?.value;
        uploadDateTo = nextFilters.find(f => f.type === ListFilterType.UPLOAD_DATE_TO)?.value;
        dateDiff = (new Date(uploadDateTo).getTime() - new Date(uploadDateFrom).getTime()) / (1000 * 3600 * 24);
        if (isNaN(dateDiff) || dateDiff < 0 || dateDiff >= CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS + 1) {
          setErrorMsg(tCreCard('error.dateRangeOutOfBound'));
          return;
        }
        break;
      case 'perOperation':
        expDateFrom = nextFilters.find(f => f.type === ListFilterType.EXP_DATE_FROM)?.value;
        expDateTo = nextFilters.find(f => f.type === ListFilterType.EXP_DATE_TO)?.value;
        dateDiff = (new Date(expDateTo).getTime() - new Date(expDateFrom).getTime()) / (1000 * 3600 * 24);
        if (isNaN(dateDiff) || dateDiff < 0 || dateDiff >= CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS + 1) {
          setErrorMsg(tCreCard('error.dateRangeOutOfBound'));
          return;
        }
        break;
    }
    setFilters(nextFilters);
    setFiltersChanged(false);
  }

  const searchTextChangeHandler = (e: SyntheticEvent) => {
    let target = e.target as HTMLInputElement;

    if (filters) {
      setNextFilters(prevFilters => prevFilters.map(f => f.type === ListFilterType.SEARCH_TEXT ? ({
        ...f,
        value: target.value
      }) : f));
      setFiltersChanged(true);
    }
  }

  const onFilterDateChangeHandler = (value: Date, filterType: ListFilterType) => {
    let dateFrom;
    let dateTo;
    let dateDiff;
    switch (filterType) {
      case ListFilterType.UPLOAD_DATE_FROM:
        dateFrom = new Date(value);
        dateTo = nextFilters.find(f => f.type === ListFilterType.UPLOAD_DATE_TO)?.value;
        dateDiff = (new Date(dateTo).getTime() - dateFrom.getTime()) / (1000 * 3600 * 24);
        if (dateDiff < 0 || dateDiff >= CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS + 1) {
          setNextFilters(prevState => prevState.map(f => {
            if (f.type === ListFilterType.UPLOAD_DATE_TO) {
              return ({
                ...f,
                value: moment(value).add(CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS, 'days').toDate()
              });
            } else if (f.type === ListFilterType.UPLOAD_DATE_FROM) {
              return ({
                ...f,
                value: value
              });
            } else {
              return f;
            }
          }));
        } else {
          setNextFilters(prevState => prevState.map(f => f.type === ListFilterType.UPLOAD_DATE_FROM ? ({
            ...f,
            value: value
          }) : f));
        }
        setFiltersChanged(true);
        break;
      case ListFilterType.UPLOAD_DATE_TO:
        dateTo = new Date(value);
        dateFrom = nextFilters.find(f => f.type === ListFilterType.UPLOAD_DATE_FROM)?.value;
        dateDiff = (dateTo.getTime() - new Date(dateFrom).getTime()) / (1000 * 3600 * 24);
        if (dateDiff < 0 || dateDiff >= CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS + 1) {
          setNextFilters(prevState => prevState.map(f => {
            if (f.type === ListFilterType.UPLOAD_DATE_FROM) {
              return ({
                ...f,
                value: moment(value).add(-CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS, 'days').toDate()
              });
            } else if (f.type === ListFilterType.UPLOAD_DATE_TO) {
              return ({
                ...f,
                value: value
              });
            } else {
              return f;
            }
          }));
        } else {
          setNextFilters(prevState => prevState.map(f => f.type === ListFilterType.UPLOAD_DATE_TO ? ({
            ...f,
            value: value
          }) : f));
        }
        setFiltersChanged(true);
        break;
      case ListFilterType.EXP_DATE_FROM:
        dateFrom = new Date(value);
        dateTo = nextFilters.find(f => f.type === ListFilterType.EXP_DATE_TO)?.value;
        dateDiff = (new Date(dateTo).getTime() - dateFrom.getTime()) / (1000 * 3600 * 24);
        if (dateDiff < 0 || dateDiff >= CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS + 1) {
          setNextFilters(prevState => prevState.map(f => {
            if (f.type === ListFilterType.EXP_DATE_TO) {
              return ({
                ...f,
                value: moment(value).add(CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS, 'days').toDate()
              });
            } else if (f.type === ListFilterType.EXP_DATE_FROM) {
              return ({
                ...f,
                value: value
              });
            } else {
              return f;
            }
          }));
        } else {
          setNextFilters(prevState => prevState.map(f => f.type === ListFilterType.EXP_DATE_FROM ? ({
            ...f,
            value: value
          }) : f));
        }
        setFiltersChanged(true);
        break;
      case ListFilterType.EXP_DATE_TO:
        dateTo = new Date(value);
        dateFrom = nextFilters.find(f => f.type === ListFilterType.EXP_DATE_FROM)?.value;
        dateDiff = (dateTo.getTime() - new Date(dateFrom).getTime()) / (1000 * 3600 * 24);
        if (dateDiff < 0 || dateDiff >= CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS + 1) {
          setNextFilters(prevState => prevState.map(f => {
            if (f.type === ListFilterType.EXP_DATE_FROM) {
              return ({
                ...f,
                value: moment(value).add(-CRE_CARD_MOV_FILTER_MAX_DATES_DIFF_DAYS, 'days').toDate()
              });
            } else if (f.type === ListFilterType.EXP_DATE_TO) {
              return ({
                ...f,
                value: value
              });
            } else {
              return f;
            }
          }));
        } else {
          setNextFilters(prevState => prevState.map(f => f.type === ListFilterType.EXP_DATE_TO ? ({
            ...f,
            value: value
          }) : f));
        }
        setFiltersChanged(true);
        break;
    }
  }

  const onFilterStateChangeHandler = (value) => {
    setNextFilters(prevState => prevState.map(f => f.type === ListFilterType.STATE ? ({
      ...f,
      value: value
    }) : f));
    setFiltersChanged(true);
  }

  return (
    <Box
      width={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      mx={"auto"}
    >
      <Loader show={loading}/>
      <Box flexGrow={1}>
        <PageTitle
          title={<Typography variant={"h3"} mr={3}>{tCreCard('title')}</Typography>}
        />
      </Box>

      <Snackbar open={!!errorMsg} autoHideDuration={13000} onClose={() => setErrorMsg(null)}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
        <Alert severity={"error"} onClose={() => setErrorMsg(null)}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <Paper
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          mt: 2,
          px: 3.5,
          py: 2.5,
          flexGrow: 1,
          height: "100%",
          position: 'relative'
        }}
      >
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
            mt={2}
            ml={0.2}
            columnGap={1}
            alignItems={'center'}
          >
            {
              !importCompleteConfiguration ? (
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  sx={{backgroundColor: '#FDF4F7', color: '#D32D66'}}
                  columnGap={2}
                  p={2}
                  ml={0.5}
                >
                  <AnnouncementIcon/>&nbsp;
                  <Typography>{tCreCard('noConfigMessage')}</Typography>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    width={'fit-content'}
                    height={'fit-content'}
                    sx={{
                      backgroundColor: '#de0461',
                      paddingX: '15px',
                      paddingY: '3px',
                      borderRadius: '7px',
                      cursor: 'pointer'
                    }}
                    alignItems={'center'}
                    columnGap={1}
                    onClick={configureImport}
                  >
                    <Typography color={'white'} minWidth={'max-content'}>{tCreCard('configButton')}</Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  width={'fit-content'}
                  height={'fit-content'}
                  sx={{
                    backgroundColor: '#3315d6',
                    paddingX: '15px',
                    paddingY: '8px',
                    borderRadius: '7px',
                    cursor: 'pointer'
                  }}
                  alignItems={'center'}
                  columnGap={1}
                  onClick={openImportPopup}
                >
                  <UploadFile fontSize={'small'} sx={{color: 'white'}}/>
                  <Typography color={'white'} minWidth={'max-content'}>{tCreCard('subtitle1')}</Typography>
                </Box>
              )
            }
            <Box flexGrow={1}/>
            {
              importCompleteConfiguration &&
              <TooltipZts
                title={tCreCard('subtitle1')}
                placement={'bottom'}
                enterDelay={400}
              >
                <IconButton
                  color="primary"
                  className={"edit-button"}
                  onClick={openImportPopup}
                >
                  <UploadFile fontSize={"medium"} sx={{alignSelf: 'center', color: 'white'}}/>
                </IconButton>
              </TooltipZts>
            }
            <TooltipZts
              title={importCompleteConfiguration ? tCreCard('configButton2') : tCreCard('configButton')}
              placement={'bottom'}
              enterDelay={400}
            >
              <IconButton
                color="primary"
                className={"edit-button"}
                onClick={configureImport}
              >
                <SettingsIcon fontSize={"medium"} sx={{alignSelf: 'center', color: 'white'}}/>
              </IconButton>
            </TooltipZts>
            <TooltipZts
              title={tCreCard('popup.reconciliation.buttonTooltip')}
              placement={'bottom'}
              enterDelay={400}
            >
              <IconButton
                color="primary"
                className={"edit-button"}
                onClick={openReconciliationPopup}
              >
                <PrintIcon fontSize={"medium"} sx={{alignSelf: 'center', color: 'white'}}/>
              </IconButton>
            </TooltipZts>
          </Box>
        </Box>


        <Box display={'flex'} flexDirection={'column'} width={'100%'} mt={2} overflow={'auto'}>
          <Typography variant={"h3"} color={'#7991aa'} fontSize={22} mb={1}>{tCreCard('subtitle2')}</Typography>

          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            columnGap={2}
          >
            <TextField
              variant={"outlined"}
              size={"small"}
              placeholder={tCreCard('searchBarPlaceHolder')}
              value={nextFilters && nextFilters.filter(f => f.type === ListFilterType.SEARCH_TEXT)[0].value}
              onChange={searchTextChangeHandler}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search fontSize={"small"}/>
                  </InputAdornment>
                ),
              }}
              sx={{maxWidth: '330px', minHeight: 'max-content', height: '50px'}}
              className={'searchBarTextField'}
              fullWidth
            />

            <Box
              sx={{
                position: 'relative',
                borderRadius: '8px',
                backgroundColor: 'white',
                width: '170px'
              }}
            >
              <SelectZts
                label={tCreCard('filters.state.label')}
                width={"100%"}
                items={creCardMovStateFilterItems}
                selectedValue={nextFilters.find(f => f.type === ListFilterType.STATE)?.value}
                setValue={onFilterStateChangeHandler}
                disableHelperText={true}
              />
            </Box>

            {
              currentGroup !== 'perOperation' &&
              <Box className={"date-filter-wrapper2"}>
                <DatePickerZts
                  label={tCreCard("filters.uploadDate.from")}
                  field={{value: nextFilters.find(f => f.type === ListFilterType.UPLOAD_DATE_FROM)?.value}}
                  disableHelperText={true}
                  width={"160px"}
                  onChangeHandler={(date) => onFilterDateChangeHandler(date, ListFilterType.UPLOAD_DATE_FROM)}
                />
                <DatePickerZts
                  label={tCreCard("filters.uploadDate.to")}
                  field={{value: nextFilters.find(f => f.type === ListFilterType.UPLOAD_DATE_TO)?.value}}
                  disableHelperText={true}
                  width={"160px"}
                  onChangeHandler={(date) => onFilterDateChangeHandler(date, ListFilterType.UPLOAD_DATE_TO)}
                />
              </Box>
            }

            {
              currentGroup !== 'perLoading' &&
              <Box className={"date-filter-wrapper2"}>
                <DatePickerZts
                  label={tCreCard("filters.expenseDate.from")}
                  field={{value: nextFilters.find(f => f.type === ListFilterType.EXP_DATE_FROM)?.value}}
                  disableHelperText={true}
                  width={"160px"}
                  onChangeHandler={(date) => onFilterDateChangeHandler(date, ListFilterType.EXP_DATE_FROM)}
                />
                <DatePickerZts
                  label={tCreCard("filters.expenseDate.to")}
                  field={{value: nextFilters.find(f => f.type === ListFilterType.EXP_DATE_TO)?.value}}
                  disableHelperText={true}
                  width={"160px"}
                  onChangeHandler={(date) => onFilterDateChangeHandler(date, ListFilterType.EXP_DATE_TO)}
                />
              </Box>
            }

            <Button
              disabled={!filtersChanged}
              variant="contained"
              className={"cta-button-primary-small"}
              onClick={handleFilterChange}
            >
              <Typography variant={'button'}>{tCreCard('applyFilterButton')}</Typography>
            </Button>

          </Box>

          <FormControl sx={{my: 1, ml: 0.2}}>
            <FormLabel>{tCreCard('groupBy.title')}</FormLabel>
            <RadioGroup
              row
              onChange={handleGroupButtonsClick}
              value={currentGroup}
            >
              <FormControlLabel value="perOperation" control={<Radio size={'small'}/>}
                                label={tCreCard('groupBy.perOperation')}/>
              <FormControlLabel value="perLoading" control={<Radio size={'small'}/>}
                                label={tCreCard('groupBy.perLoading')}/>
              <FormControlLabel value="noGroup" control={<Radio size={'small'}/>} label={tCreCard('groupBy.noGroup')}/>
            </RadioGroup>
          </FormControl>

          <Box
            display={'flex'}
            flexDirection={'column'}
            overflow={'auto'}
            className={'creCardTableContainer'}
          >
            {
              currentGroup === 'noGroup' &&
              <NonGroupedList filters={filters} update={updateCurrentList}
                              currencies={currencies}
                              resetUpdate={() => setUpdateCurrentList(false)}/>
            }

            {
              currentGroup === 'perOperation' &&
              <PerOperationList filters={filters} update={updateCurrentList}
                                currencies={currencies}
                                resetUpdate={() => setUpdateCurrentList(false)}/>
            }

            {
              currentGroup === 'perLoading' &&
              <PerUploadList filters={filters} update={updateCurrentList}
                             currencies={currencies}
                             resetUpdate={() => setUpdateCurrentList(false)}/>
            }
          </Box>

        </Box>

      </Paper>
      <CreCardMovReconciliationPopup open={reconciliationPopupOpen} handleClose={closeReconciliationPopup}/>
      <ImportConfigurer open={configurerPopupOpen} closePopup={closeConfigurerPopup}
                        previousConfiguration={importCompleteConfiguration} onSuccess={onSuccessfulConfiguration}/>
      <CreCardMovImportPopup open={importPopupOpen} closePopup={closeImportPopup}
                             allowedFileType={importFileType || FileType.EXCEL}/>
    </Box>
  );
}
