import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button, Link,
  Stack,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import {AccountTree, Close, HighlightOff, KeyboardArrowDown, PlayCircle} from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import FlagIcon from '@mui/icons-material/Flag';
import {
  getCurrentPage,
  HelpCurrentPage,
  isHelpOpen,
  setHelpOpen
} from "../../../reducers/Help";
import {useSelector, useDispatch} from "react-redux";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {Rnd} from "react-rnd";
import {getPageContent, getProcedures, search} from "../Service";
import {Procedure} from "../contents/procedure";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {TextFieldZts} from "../../base/text-field";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {hasApiUrl} from "../../../config/environment";
import {useTranslation} from "react-i18next";
import {SupportForm} from "../contents/support";
import {HelpSearchResult} from "../contents/search/help/HelpSearchResult";
import {ProcedureSearchResult} from "../contents/search/procedure/ProcedureSearchResult";
import {HelpSearchResultContent} from "../contents/search/help/HelpSearchResultContent";
import {Loader} from "../../base/loader";

type PageContent = {
  pageName: string,
  htmlContent: string
}

type Procedure = {
  id: number,
  stepNumber: number,
  htmlContent: string
}

type ProcedureContent = {
  procedureId: number,
  procedureName: string
  stepsNumber: number,
  currentStep: number
}

export type SearchResult = {
  contentType: string,
  pageName: string,
  pageContent: string,
}

export const Help = () => {
  const apiUrlFlag = hasApiUrl();

  const [pagesContent, setPagesContent] = useState<PageContent[]>([]);
  const [currentProcedure, setCurrentProcedure] = useState<ProcedureContent>();
  const [currentProcedureStep, setCurrentProcedureStep] = useState<number>(0);
  const [allProcedures, setAllProcedures] = useState<ProcedureContent[]>([]);
  const [procedureOpen, setProcedureOpen] = useState<boolean>(false);
  const [skippedSteps, setSkippedSteps] = React.useState(new Set<number>());
  const [helpResult, setHelpResult] = useState<SearchResult>();
  const [helpResultOpen, setHelpResultOpen] = useState<boolean>(false);
  const [supportFormOpen, setSupportFormOpen] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<SearchResult[] | null>(null);
  const [queryString, setQueryString] = useState('');
  const [timeOutId, setTimeOutId] = useState<NodeJS.Timeout>();

  const [expanded, setExpanded] = useState<string | false>(false);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>();
  const [searching, setSearching] = useState<boolean>(false)

  const {convertError} = useErrorMessage();

  const currentPage = useSelector(getCurrentPage);
  const {user, userLocale} = useLoggedUser();
  const {t} = useTranslation('help');

  const dispatch = useDispatch();
  const open = useSelector(isHelpOpen);

  const closeHandler = () => {
    dispatch(setHelpOpen(false));
  }

  useEffect(() => {
    clearTimeout(timeOutId);
    if (queryString !== '' && queryString.length > 2) {
      setSearching(true);
      setTimeOutId(setTimeout(() => {
        search(userLocale, queryString)
          .then((response) => {
            if (response.length === 0) {
              setSearchResult([]);
            } else {
              setSearchResult(response);
            }
            setExpanded(false);
          })
          .catch((error) => {
            setSearchResult(null);
            convertError(createError(error))
              .then((msg) => setErrorMsg(msg))
          })
          .finally(() => setSearching(false));
      }, 1000));
    } else {
      if (searching) {
        setSearching(false);
      }
      setSearchResult(null);
    }
  }, [queryString])

  useEffect(() => {
    if (user) {
      asyncGetAndSetProcedures().then();
    }
  }, [user]);

  const asyncGetAndSetProcedures = async (): Promise<void> => {
    if (apiUrlFlag) {
      setLoading(true);
      try {
        const procedures = await getProcedures();
        setAllProcedures(procedures);
      } catch (err: any) {
        convertError(createError(err))
          .then((msg) => setErrorMsg(msg))
          .finally(() => setLoading(false))
      }
      setLoading(false);
    }
  }

  // Versione 1: Carico i contenuti html in maniera dinamica
  useEffect(() => {
    const exist = pagesContent.find(pageContent => pageContent.pageName === currentPage); // Controllo che il contenuto non sia già presente nello stato
    if (!exist && open) { // Se il contenuto non è nello stato, e il tab Help è aperto
      setLoading(true);
      getPageContent(currentPage, userLocale) // get del contenuto
        .then((result) => {
          const content: PageContent = {pageName: currentPage, htmlContent: result};
          setPagesContent((prevState) => [...prevState, content]); // aggiunto il contenuto allo stato
        })
        .catch((error1) => {
          getPageContent(HelpCurrentPage.NOT_FOUND, userLocale) // get della pagina di errore
            .then((result) => {
              const content: PageContent = {pageName: currentPage, htmlContent: result};
              setPagesContent((prevState) => [...prevState, content]); // aggiunto il contenuto allo stato
            })
            .catch((error2) => {
              convertError(createError(error2))
                .then((msg) => {
                  setErrorMsg(msg);
                })
            })
          convertError(createError(error1))
            .then((msg) => {
              setErrorMsg(msg);
            })
        })
        .finally(() => setLoading(false));
    }
  }, [currentPage, open]);

  useEffect(() => {
    if (currentProcedure) {
      setProcedureOpen(true);
    }
  }, [currentProcedure])

  const onOpenClick = (expandedValue: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setQueryString('');
    setExpanded(isExpanded ? expandedValue : false);
  };

  const onOpenProcedure = (procedureId: number, step?: number) => {
    let currentProcedure;
    if (allProcedures) {
      currentProcedure = allProcedures.find((procedure) => {
        if (procedure.procedureId === procedureId) {
          return procedure;
        }
      });
    }
    if (currentProcedure) {
      if (step) {
        setCurrentProcedure({...currentProcedure, currentStep: step})
      } else {
        setCurrentProcedure(currentProcedure);
      }
    }
    setProcedureOpen(true);
  }

  const getCurrentPageContent = (currentPage: HelpCurrentPage) => {
    const fullPage = pagesContent.find(pagesContent => pagesContent.pageName === currentPage);
    const htmlContent = fullPage ? fullPage.htmlContent : '';
    return (
      <div
        style={{borderColor: "black"}}
        dangerouslySetInnerHTML={{
          __html: htmlContent
        }}
      >
      </div>
    );
  }

  // ATTENZIONE: valido per desktop
  const initialWidth = 400;
  const initialHeight = 605;
  const initialXPosition = -70;
  const initialYPosition = 50;
  const maxWidth = "80vw";
  const maxHeight = "100vh";
  const minWidth = "340px";
  const minHeight = "265px";
  const initialGap = 30;
  const resizeBoxWidth = 30;
  const resizeBoxHeight = 30;
  const resizeBoxXOffset = -4;
  const resizeBoxYOffset = -4;
  const resizeBarWidth = 15;
  const resizeBarHeight = 15;


  const [position, setPosition] = useState({x: initialXPosition, y: initialYPosition});
  const [size, setSize] = useState({width: `${initialWidth}px`, height: `${initialHeight}px`});

  const handleProcedureClose = () => {
    setProcedureOpen(false);
    setCurrentProcedureStep(0);
    setSkippedSteps(new Set<number>);
  }

  return (
    <>
      {
        !loading &&
        <Box
          className={"help-container-placement"}
          top={`${initialGap}px`}
          right={`${initialWidth + initialGap}px`}
          display={open ? "block" : "none"}
        >
          <Rnd
            bounds={".help-container-wrapper"}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            minHeight={minHeight}
            minWidth={minWidth}
            cancel=".no-drag-area"
            className={"help-container"}
            position={{ x: position.x, y: position.y }}
            onDragStop={(e, d) => { setPosition({ x: d.x, y: d.y})}}
            size={{width: size.width, height: size.height}}
            onResizeStop={(e, direction, ref, position, delta) => {
              setSize({width: ref.style.width, height: ref.style.height});
              setPosition({x: delta.x, y: delta.y});
            }}
            resizeHandleStyles={{
              bottom: {
                height: `${resizeBarHeight}px`
              },
              top: {
                height: `${resizeBarHeight}px`
              },
              right: {
                width: `${resizeBarWidth}px`
              },
              left: {
                width: `${resizeBarWidth}px`
              },
              bottomRight: {
                width: `${resizeBoxWidth}px`,
                height: `${resizeBoxHeight}px`,
                right: `${resizeBoxXOffset}px`,
                bottom: `${resizeBoxYOffset}px`
              },
              bottomLeft: {
                width: `${resizeBoxWidth}px`,
                height: `${resizeBoxHeight}px`,
                left: `${resizeBoxXOffset}px`,
                bottom: `${resizeBoxYOffset}px`
              },
              topRight: {
                width: `${resizeBoxWidth}px`,
                height: `${resizeBoxHeight}px`,
                right: `${resizeBoxXOffset}px`,
                top: `${resizeBoxYOffset}px`
              },
              topLeft: {
                width: `${resizeBoxWidth}px`,
                height: `${resizeBoxHeight}px`,
                left: `${resizeBoxXOffset}px`,
                top: `${resizeBoxYOffset}px`
              }
            }}
          >
            {
              helpResultOpen && helpResult ? (
                <HelpSearchResultContent page={helpResult.pageName} onClose={() => setHelpResultOpen(false)} />
              ) : (
                procedureOpen && currentProcedure ?
                  (
                    <Procedure procedureId={currentProcedure.procedureId}
                               procedureName={currentProcedure.procedureName}
                               stepsNumber={currentProcedure.stepsNumber}
                               onClose={handleProcedureClose}
                               activeStep={currentProcedureStep}
                               setActiveStep={setCurrentProcedureStep}
                               skippedSteps={skippedSteps}
                               setSkippedSteps={setSkippedSteps}
                    />
                  ) :
                  (
                    supportFormOpen ? (
                      <SupportForm onClose={() => setSupportFormOpen(false)}/>
                    ) : (
                      <>
                        <Stack flexDirection={"row"} pt={0.5} pr={0.5}>
                          <Box sx={{marginLeft: 2, marginRight: 1, marginBottom: 1.5}}>
                            <Typography
                              variant={"h4"}
                            >
                              {t('title')}
                            </Typography>
                          </Box>
                          <Box flexGrow={1}/>
                          <Close
                            cursor={"pointer"}
                            fontSize={"inherit"}
                            sx={{
                              backgroundColor: "#ccd7e3",
                              color: "white",
                              padding: "4.5px",
                              borderRadius: "50%",
                              width: "1.5em",
                              height: "1.5em",
                              marginLeft: "0.2px"
                            }}
                            className={"no-drag-area"}
                            onClick={() => closeHandler()}
                          />
                        </Stack>


                        <Box mx={1.25}>
                          <TextFieldZts
                            className={"no-drag-area"}
                            label={t('label.search')}
                            variant={"outlined"}
                            value={queryString}
                            placeholder={t('label.search')}
                            onChange={(e: SyntheticEvent) => {
                              let target = e.target as HTMLInputElement;
                              setQueryString(target.value);
                            }}
                            InputProps={{
                              endAdornment: queryString.length < 1 ? (
                                <SearchIcon
                                  fontSize={"small"}
                                />
                              ) : (
                                <HighlightOff
                                  cursor={"pointer"}
                                  onClick={() => setQueryString("")}
                                  sx={{width: '0.6em', height: '0.6em'}}
                                />
                              )
                            }}
                          />
                        </Box>

                        <Stack
                          className={"no-drag-area"}
                          sx={{cursor: "default"}}
                          maxWidth={"92%"}
                          ml={2.2}
                          mb={1}
                          maxHeight={"63%"}
                          overflow={"auto"}
                          rowGap={1}
                        >
                          <Loader show={searching} />
                          {
                            searchResult && searchResult.length > 0 &&
                            <>
                              <Typography variant={"h6"}>{t('label.results')}</Typography>
                              {
                                searchResult?.map((result, index) => {
                                  if (result.contentType === 'Help') {
                                    return <HelpSearchResult index={index} result={result} onResultClick={() => {
                                      setHelpResult(result);
                                      setHelpResultOpen(true);
                                    }}/>
                                  } else {
                                    return <ProcedureSearchResult index={index} result={result}
                                                                  onResultClick={onOpenProcedure}/>
                                  }
                                })
                              }
                              <Typography variant={'h5'}>{t('label.resultNotFound')}<Link sx={{cursor: 'pointer'}}
                                                                                          onClick={() => setSupportFormOpen(true)}>{t('label.askAssist')}</Link></Typography>
                            </>
                          }
                          {
                            searchResult && searchResult.length === 0 &&
                            <>
                              <Typography variant={"h6"}>{t('label.results')}</Typography>
                              <Typography variant={'h5'}>{t('label.noResults')}</Typography>
                              <Typography variant={'h5'}>{t('label.resultNotFound')}
                                <Link
                                  sx={{cursor: 'pointer'}}
                                  onClick={() => setSupportFormOpen(true)}
                                >
                                  {t('label.askAssist')}
                                </Link>
                              </Typography>
                            </>
                          }
                        </Stack>

                        {/*{*/}
                        {/*  searchResult && searchResult.length > 0 &&*/}
                        {/*  <Stack className={"no-drag-area"} sx={{cursor: "default"}} maxWidth={"92%"} ml={2.2} mb={1}*/}
                        {/*         maxHeight={"63%"} overflow={"auto"} rowGap={1}>*/}
                        {/*    <Typography variant={"h6"}>{t('label.results')}</Typography>*/}
                        {/*    {*/}
                        {/*      searchResult?.map((result, index) => {*/}
                        {/*        if (result.contentType === 'Help') {*/}
                        {/*          return <HelpSearchResult index={index} result={result} onResultClick={() => {*/}
                        {/*            setHelpResult(result);*/}
                        {/*            setHelpResultOpen(true);*/}
                        {/*          }}/>*/}
                        {/*        } else {*/}
                        {/*          return <ProcedureSearchResult index={index} result={result} onResultClick={onOpenProcedure} />*/}
                        {/*        }*/}
                        {/*      })*/}
                        {/*    }*/}
                        {/*    <Typography variant={'h5'}>{t('label.resultNotFound')}<Link sx={{cursor: 'pointer'}} onClick={() => setSupportFormOpen(true)}>{t('label.askAssist')}</Link></Typography>*/}
                        {/*  </Stack>*/}
                        {/*}*/}
                        {/*{*/}
                        {/*  searchResult && searchResult.length === 0 &&*/}
                        {/*  <Stack className={"no-drag-area"} sx={{cursor: "default"}} maxWidth={"92%"} ml={2.2} mb={1}*/}
                        {/*         maxHeight={"63%"} overflow={"auto"} rowGap={1}>*/}
                        {/*    <Typography variant={"h6"}>{t('label.results')}</Typography>*/}
                        {/*    <Typography variant={'h5'}>{t('label.noResults')}</Typography>*/}
                        {/*    <Typography variant={'h5'}>{t('label.resultNotFound')}<Link sx={{cursor: 'pointer'}} onClick={() => setSupportFormOpen(true)}>{t('label.askAssist')}</Link></Typography>*/}
                        {/*  </Stack>*/}
                        {/*}*/}

                        <Box
                          flexGrow={1}
                          height={"calc(100% - 100px)"}
                          overflow={"hidden"}
                          sx={{cursor: "default", borderTop: 1, borderColor: 'divider'}}
                          className={"no-drag-area"}
                        >
                          <Accordion className={'ztsAccordion'} expanded={expanded === 'help'} onChange={onOpenClick('help')} elevation={0}>
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDown/>}
                            >
                              <AccountTree sx={{color: "#14c6d5", fontSize: "20px"}}/>
                              <Typography sx={{fontSize: "18px", fontWeight: "405"}} ml={1}>{t('label.currentPageTitle')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box height={`calc(${size.height} - 250px)`} overflow={"auto"}>
                                <Typography>{getCurrentPageContent(currentPage)}</Typography>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={'ztsAccordion'} expanded={expanded === 'procedures'} onChange={onOpenClick('procedures')}
                                     elevation={0}>
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDown/>}
                            >
                              <FlagIcon sx={{color: "#14c6d5", fontSize: "20px"}}/>
                              <Typography sx={{fontSize: "18px", fontWeight: "405"}} ml={1}>{t('label.procedureTitle2')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box maxHeight={"335px"} overflow={"auto"}>
                                <Typography>
                                  {t('label.procedureDescription')}
                                </Typography>
                                <br/>
                                {
                                  allProcedures !== undefined &&
                                  allProcedures.map((procedure, index) => {
                                    return (
                                      <Stack key={index} onClick={() => onOpenProcedure(procedure.procedureId)}
                                             flexDirection={"row"} alignItems={"center"} mb={1}>
                                        <Button sx={{borderRadius: "50%", minWidth: "0px", color: "#14c6d5"}}>
                                          <PlayCircle fontSize={"small"}/>
                                        </Button>
                                        <Typography sx={{fontWeight: "500"}}
                                                    noWrap={true}>{procedure.procedureName}</Typography>
                                      </Stack>
                                    );
                                  })
                                }
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </>
                    )
                  )
              )
            }
          </Rnd>
        </Box>
      }
    </>
  );
}
