// React
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";


// TODO spostare
// https://mui.com/blog/lab-date-pickers-to-mui-x/
//https://mui.com/x/react-date-pickers/getting-started/

// MUI
import {Box, Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import {
  Check,
  ContentCopy,
  DeleteForever,
  DoDisturbAlt,
  Edit,
  EventBusy,
  Save,
  WarningAmber
} from "@mui/icons-material";

// Libs
import {Property} from "csstype";
import {Logger} from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// Libs
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";

// Custom
import {TravelPolicy} from "../model";
import {PageTitle} from "../../layout/page-title";
import {DETAIL_URL, getStateCode, isActiveStatus, REACT_TRAVEL_POLICIES_BASE_URL} from "../../model";
import {yupMinMaxRequiredString, yupOptionalDate, yupRequiredDate} from "../../../util/YupUtil";
import {TravelPolicyMainData} from "../commons/TravelPolicyMainData";
import {TravelPolicyOptions} from "../commons/TravelPolicyOptions";
import {disableTravelPolicies, updateTravelPolicy, UpdateTravelPolicyRequest} from "../Service";
import {
  NewAccountModalClosedModeType,
  NewTravelPolicyModal,
  NewTravelPolicyModalClosedEvent,
  NewTravelPolicyType
} from "../commons/NewTravelPolicyModal";
import {
  getTravelPolicyDateStateCssClass,
  getTravelPolicyDateStateLabel,
  getTravelPolicyDateStateType,
  getTravelPolicyStateCssClass,
  getTravelPolicyStateLabel,
  getTravelPolicyStateType,
  TravelPolicyDateStateType,
  TravelPolicyStateType
} from "../utils";
import {TooltipZts} from "../../base/tooltip";


type TravelPolicyEditFormValues = {
  active: boolean,
  code: string,
  description: string,
  startDate: Date,
  endDate: Date | null,
  foreignCurrEnb: boolean,
  projectEnb: boolean
}


export type TravelPolicyUpdateActionHandler = (tp: TravelPolicy) => void;

export type TravelPolicyEditFormProps = {
  travelPolicy: TravelPolicy
  onTravelPolicyUpdate: TravelPolicyUpdateActionHandler
  professional: boolean
  showError: (e: Error) => void
  showLoader: (falg: boolean) => void
}

export const TravelPolicyEditForm: React.FC<TravelPolicyEditFormProps> = ({
                                                                            travelPolicy,
                                                                            onTravelPolicyUpdate,
                                                                            professional,
                                                                            showError,
                                                                            showLoader
                                                                          }) => {

  const LOG: Slf4jsLog = Logger.of('ZTS.TravelPolicies.TravelPolicyEditForm');

  const [editable, setEditable] = useState<boolean>(false);
  // Usata per la modale di clonazione
  const [newTravelPolicy, setNewTravelPolicy] = useState<NewTravelPolicyType | null>(null);

  const {t} = useTranslation(['validation']);
  const {t: tTravelPolicy} = useTranslation('travel-policy', {keyPrefix: 'edit'});

  const validationSchema = Yup.object({
    code: yupMinMaxRequiredString(1, 20, t),
    description: yupMinMaxRequiredString(1, 50, t),
    startDate: yupRequiredDate(t),
    endDate: yupOptionalDate(t)
  });

  let endDate: Date | null;
  if (travelPolicy.endDate) {
    endDate = travelPolicy.endDate;
  } else {
    endDate = null;
  }

  const initVal: TravelPolicyEditFormValues = {
    active: isActiveStatus(travelPolicy.state),
    code: travelPolicy.code,
    description: travelPolicy.description,
    startDate: travelPolicy.startDate,
    endDate: endDate,
    foreignCurrEnb: travelPolicy.foreignCurrEnb,
    projectEnb: travelPolicy.projectEnb
  };

  const formMethods = useForm<TravelPolicyEditFormValues>({
    defaultValues: initVal,
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  const isNew = travelPolicy.id === -1;
  let title = isNew ? tTravelPolicy('title.create') : tTravelPolicy('title.update');
  if (!isNew && !editable) {
    title = travelPolicy.description;
  }

  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(REACT_TRAVEL_POLICIES_BASE_URL);
    /*
    if (isNew) {
      navigate(REACT_TRAVEL_POLICIES_BASE_URL);
    } else {
      navigate(`..${DETAIL_URL}/${travelPolicy.id}`);
    }*/
  }

  const onDelete = async () => {
    LOG.trace('onDelete');
    try {
      showLoader(true);
      const tpId = travelPolicy.id;
      await disableTravelPolicies([tpId]);
      navigate(`${REACT_TRAVEL_POLICIES_BASE_URL}${DETAIL_URL}/${tpId}`);
    } catch (e) {
      showLoader(false);
      showError(e as Error);
    }
  }

  const onEdit = () => {
    LOG.trace('onEdit');
    setEditable(true);
  }

  const onSave = () => {
    LOG.trace('onSave');
    formMethods.handleSubmit(onFormValid, onFormError)();
  }


  const onFormError = (errors) => {
    // Non ci sono errori nella form da gestire
    // Lascio il log a livello error per avere una eventuale traccia
    LOG.error('onFormError', errors);
  }

  const onFormValid = (data: TravelPolicyEditFormValues) => {
    LOG.trace('onFormValid');
    // uso una funzione async
    saveTravelPolicy(data)
      .then(() => LOG.trace('async saveExpense end'));
  }

  const saveTravelPolicy = async (data: TravelPolicyEditFormValues) => {
    LOG.trace('saveTravelPolicy', data);
    showLoader(true);
    const body: UpdateTravelPolicyRequest = {
      description: data.description,
      startDate: data.startDate,
      endDate: data.endDate,
      foreignCurrEnb: data.foreignCurrEnb,
      projectEnb: data.projectEnb,
      state: getStateCode(data.active),
      lastUpdateNum: travelPolicy.lastUpdNum
    }
    try {
      const newTp = await updateTravelPolicy(travelPolicy.id, body);
      setEditable(false);
      onTravelPolicyUpdate(newTp);
    } catch (e) {
      showError(e as Error);
    }
    showLoader(false);
  }

  const onCancel = () => {
    LOG.trace('onCancel');
    formMethods.reset();
    setEditable(false);
  }

  const onCopy = () => {
    LOG.trace('onCopy');
    // code: '',
    const newObj: NewTravelPolicyType = {
      description: tTravelPolicy('type.newTravelPolicy.description', {travelPolicyDesc: travelPolicy.description}),
      startDate: new Date(),
      masterTravelPolicyId: travelPolicy.id
    }
    setNewTravelPolicy(newObj);
  }

  const onNewTravelPolicyModalClosed = (event: NewTravelPolicyModalClosedEvent) => {
    LOG.trace('onNewTravelPolicyModalClosed', event);
    const action: NewAccountModalClosedModeType = event.action;
    if (action === 'created') {
      navigate(`../edit/${event.travelPolicyId}`);
    } else {
      setNewTravelPolicy(null);
    }
  }


  // Per evitare che la pagina si muova nascondo il bottone ma lo disegno
  let editVisibility: Property.Visibility = 'visible';
  if (editable) {
    editVisibility = 'hidden'
  }

  const stateType: TravelPolicyStateType = getTravelPolicyStateType(travelPolicy);
  const dateStateType: TravelPolicyDateStateType = getTravelPolicyDateStateType(travelPolicy);


  return (
    <>
      {/* titolo + pulsanti */}
      <PageTitle
        backClickHandler={handleNavigateBack}
        title={
          <Stack
            direction={{xs: "column", sm: "row"}}
            alignItems={{xs: "flex-start", sm: "center"}}
            justifyContent={{xs: "center", sm: "flex-start"}}
            columnGap={1.5}
            flexGrow={"1"}
          >
            <Typography variant='h3' mr={3} className={"detail-title-wrapper"}>{title}</Typography>
            {!editable && <>
              <Box mt={{xs: 1, sm: 0}} style={{height: '28px'}}
                   className={'status-pill ' + getTravelPolicyStateCssClass(stateType)}
              >
                {stateType === 'success' && <Check fontSize={"small"}/>}
                {stateType !== 'success' && <DoDisturbAlt fontSize={"small"}/>}
                &nbsp;
                <Typography variant='h5' textTransform='uppercase'>
                  {getTravelPolicyStateLabel(stateType, t)}
                </Typography>
                &nbsp;
              </Box>

              {stateType === 'success' && dateStateType !== 'regular' &&
                <Box mt={{xs: 1, sm: 0}} style={{height: '28px'}}
                     className={'status-pill ' + getTravelPolicyDateStateCssClass(dateStateType)}
                >
                  {dateStateType === 'expired' && <EventBusy fontSize={"small"}/>}
                  {dateStateType === 'future' && <WarningAmber fontSize={"small"}/>}
                  &nbsp;
                  <Typography variant='h5' textTransform='uppercase'>
                    {getTravelPolicyDateStateLabel(dateStateType, t)}
                  </Typography>
                  &nbsp;
                </Box>
              }

            </>}


          </Stack>
        }
        actions={
          <>

            {
              !editable &&
              <TooltipZts
                title={tTravelPolicy('button.disable')}
                placement={'bottom'}
                enterDelay={400}
              >
                <IconButton
                  color="primary"
                  className={"delete-button"}
                  onClick={onDelete}
                >
                  <DeleteForever/>
                </IconButton>
              </TooltipZts>

            }

            {
              !editable &&
              <TooltipZts
                title={tTravelPolicy('button.duplicate')}
                placement={'bottom'}
                enterDelay={400}
              >
                <IconButton
                  color="primary"
                  className={"edit-button"}
                  onClick={onCopy}
                >
                  <ContentCopy fontSize={"small"}/>
                </IconButton>
              </TooltipZts>

            }

            <TooltipZts
              title={tTravelPolicy('button.modify')}
              placement={'bottom'}
              enterDelay={400}
            >
              <IconButton
                style={{visibility: editVisibility}}
                color="primary"
                className={"edit-button"}
                onClick={onEdit}
              >
                <Edit fontSize={"small"}/>
              </IconButton>
            </TooltipZts>
          </>
        }
      />

      {/* body */}
      <Box style={{margin: "10px"}}>
        <FormProvider {...formMethods}>
          <form noValidate>

            <TravelPolicyMainData
              editable={editable}
              travelPolicy={travelPolicy}
            />


            <Grid container spacing={2} style={{marginTop: '10px'}}>
              <Grid item xs={6}>
                <TravelPolicyOptions
                  travelPolicy={travelPolicy}
                  hasController={true}
                  updatable={editable}
                  proLicense={professional}
                />
              </Grid>
              <Grid item xs={6}>
                {/* Pulsanti (Annulla, Salva) */}
                {editable && <Stack
                  alignSelf='flex-end'
                  justifyContent='flex-end'
                  direction='row'
                  mt={4}
                  columnGap={2.2}
                >
                  <Button
                    variant="contained"
                    className={"cta-button"}
                    onClick={onCancel}>
                    <Typography variant={"button"}>{tTravelPolicy('button.cancel')}</Typography>
                  </Button>
                  <Button
                    variant="contained"
                    className={"cta-button-primary"}
                    onClick={onSave}
                  >
                    <Save sx={{fontSize: "1.1em"}}/>
                    <Typography ml={1} variant={"button"}>{tTravelPolicy('button.save')}</Typography>
                  </Button>
                </Stack>}
              </Grid>
            </Grid>


          </form>
        </FormProvider>
      </Box>
      {newTravelPolicy &&
        <NewTravelPolicyModal
          newItem={newTravelPolicy}
          masterList={[travelPolicy]}
          onClose={onNewTravelPolicyModalClosed}
        />
      }
    </>
  );
}
