import React from "react";
import {Checkbox, List, ListItem, ListItemIcon, ListItemText, Skeleton, Typography} from "@mui/material";
import Box from "@mui/material/Box";

export interface TransferListItem {
  id: number;
  label: string;
  recordId?: number;
  suffix?: string
}

type ComponentProps = {
  selectedItems: TransferListItem[] | null;
  setSelectedItems: Function;
  allItems: TransferListItem[] | null;
}

/*
 selectedItems e allItems devono essere array in cui il singolo elemento ha 2 property: id e label
 Es.:
 [{id: 1, label: 'Test 1'}, {id: 2, label: 'Test 2'}]
 */
export const TransferList = ({
                               selectedItems,
                               setSelectedItems,
                               allItems,
                             }: ComponentProps) => {

  const handleToggle = (item: TransferListItem) => () => {
    if (!selectedItems) {
      setSelectedItems([]);
    }

    if (selectedItems) {
      const currentIndex = selectedItems.findIndex(itm => itm.id === item.id);
      const newChecked = [...selectedItems];

      if (currentIndex === -1) {
        newChecked.push(item);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setSelectedItems(newChecked);
    }
  };

  const loading = !selectedItems || !allItems;

  return (
    <Box
      className={"transfer-list-card"}
    >
      {loading ? (
        [1, 2].map(index =>
          <Skeleton
            key={index}
            component="div"
            variant="rectangular"
            height={"50px"}
            sx={{my: 1}}
          />
        )
      ) : (
        <List
          sx={{
            overflow: "auto"
          }}
          dense
          component="div"
          role="list"
        >
          {allItems.map((item) => {
            const labelId = `transfer-list-all-item-${item.id}-label`;

            return (
              <ListItem
                key={item.id}
                role="listitem"
                onClick={handleToggle(item)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={selectedItems.findIndex(itm => itm.id === item.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={
                    <>
                      {item.label}
                      {item.suffix ? (
                        <Typography
                          ml={1}
                          display="inline"
                          variant="body2"
                          fontStyle="italic"
                          color="red"
                        >
                          {item.suffix}
                        </Typography>
                      ) : <></>}
                    </>
                  }
                />
              </ListItem>
            );
          })}
          <ListItem/>
        </List>
      )}
    </Box>
  );

}
