import { getApiUrl } from '../../config/environment';
import { GenericList, getStateCode } from '../model';
import { Level, SaveLevel } from './model';
import { createError } from '../../util/ErrorUtil';
import axios from 'axios';

export const getActiveLevels = async (): Promise<Level[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/levels?state=${getStateCode(true)}`);
  return resp.data.elements;
}

export const getLevels = async (): Promise<Level[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<Level>>(`${apiUrl}/levels`);
  return resp.data.elements;
}

export const saveLevel = async (level: SaveLevel) => {
  if (level.id) {
    return await updateLevel(level);
  } else {
    return await insertLevel(level);
  }
}


const insertLevel = async (level: SaveLevel) => {
  const apiUrl = getApiUrl();
  try {
    const resp = await axios.post(`${apiUrl}/levels`, level);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

const updateLevel = async (level: SaveLevel) => {
  const apiUrl = getApiUrl();
  try {
    const resp = await axios.put(`${apiUrl}/levels/${level.id}`, level);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getLevelById = async (id: string): Promise<Level> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/levels/${id}`);
  return resp.data;
}

export const disableLevel = (levelId: number) => {
  const apiUrl = getApiUrl();
  return axios.put(`${apiUrl}/levels/disable`, {levelId: levelId});
}

export const restoreLevel = (levelId: number) => {
  const apiUrl = getApiUrl();
  return axios.put(`${apiUrl}/levels/restore`, {levelId: levelId});
}
