import { Account, CreateAccountRequest, CreateAccountResponse, SaveAccount } from './model';
import { activeStateCode, getStateCode } from '../model';
import { createError } from '../../util/ErrorUtil';
import { getApiUrl } from '../../config/environment';

const axios = require('axios').default;

export const getActiveAccounts = async (): Promise<Account[]> => {
  try {
    const apiUrl = getApiUrl();
    const url = `${apiUrl}/accounts?state=${getStateCode(true)}`;

    const resp = await axios.get(url);
    return resp.data.elements;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getAccounts = () => {
  const apiUrl = getApiUrl();
  return axios.get(`${apiUrl}/accounts`);
}

export const getAccountById = (id: string) => {
  const apiUrl = getApiUrl();
  return axios.get(`${apiUrl}/accounts/${id}`);
}

export const updateAccount = (account: SaveAccount) => {
  const apiUrl = getApiUrl();
  return axios.put(`${apiUrl}/accounts/${account.id}`, account);
}

export const insertAccount = (account: SaveAccount) => {
  const apiUrl = getApiUrl();
  return axios.post(`${apiUrl}/accounts`, account);
}

export const disableAccount = (accountId: number) => {
  const apiUrl = getApiUrl();
  return axios.put(`${apiUrl}/accounts/disable`, {accountId: accountId});
}

export const restoreAccount = (accountId: number) => {
  const apiUrl = getApiUrl();
  return axios.put(`${apiUrl}/accounts/restore`, {accountId: accountId});
}

export type NewAccountObject = Omit<CreateAccountRequest, 'state'>;

export const createAccount = async (request: NewAccountObject): Promise<CreateAccountResponse> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/accounts`;
  const body: CreateAccountRequest = {
    ...request,
    state: activeStateCode
  }
  try {
    const resp = await axios.post(url, body);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const readAccount = async (id: number): Promise<Account> => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/accounts/${id}`;
  try {
    const resp = await axios.get(url);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}
