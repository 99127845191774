import {useEffect, useState} from "react";
import {convertBlobToBase64} from "../../../util/FileUtil";

type ComponentProps = {
  file: Blob;
  isPdf: boolean,
  zoomDisabled?: boolean,
  alignCenter?: boolean,
  fitContentHeight?: boolean
}

export const FileViewer = ({file, isPdf, zoomDisabled, alignCenter, fitContentHeight}: ComponentProps) => {

  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    setFileUrl(null);
    convertBlobToBase64(file)
      .then(raw => {
        //console.log(raw)
        const convertFormat = isPdf ? 'data:application/pdf' : 'data:image/jpg';
        setFileUrl(raw.replace('data:application/octet-stream', convertFormat));
      })

    //setFileUrl(URL.createObjectURL(file));
    // return () => {
    //   if (fileUrl) {
    //     URL.revokeObjectURL(fileUrl);
    //   }
    // }
  }, [file]);

  const writeHTML = (frame) => {
    const cursor = zoomDisabled ? "default" : "zoom-in";
    const clickHandler = zoomDisabled ? "() => {}" : "() => this.classList.toggle('fit-height')";

    if (isPdf || !frame) {
      return
    }

    if (fitContentHeight) {
      frame.onload = function () {
        frame.style.height = frame.contentWindow.document.body.scrollHeight + 'px';
      }
    }

    const doc = frame.contentDocument;
    if (doc) {
      doc.open();
      doc.write(`
        <style>
          body {
            margin: 0;
            height: 100%;
          }
          img {
            cursor: zoom-out;
          }
          .fit-height {
            max-height: 100%;
            max-width: 100%;
            cursor: ${cursor};
          }
          .align-center {
            display: flex;
            justify-content: center;
          }
        </style>
        ${alignCenter ? "<div class='align-center'>" : ""}
          <img id="attachImage"
              src="${fileUrl}"
              class="fit-height"
              alt=""
              onclick="(${clickHandler})()"
          />
        ${alignCenter ? "</div>" : ""}
      `);
      doc.close();

      //console.log(frame.contentDocument.body.scrollWidth)
      //console.log(frame.contentDocument.body.scrollHeight)

    }
  }

  return (
    <>
      {fileUrl && <>
        <iframe ref={writeHTML} src={isPdf ? fileUrl : "about:blank"} className={fitContentHeight ? "file-viewer-frame" : "file-viewer-frame-full-height"}/>
        {/*{isPdf && <Document*/}
        {/*  onLoadSuccess={onDocumentLoadSuccess}*/}
        {/*  file={fileUrl}*/}
        {/*>*/}
        {/*  <Page*/}
        {/*    pageNumber={pageNumber}*/}
        {/*    width={1000}*/}
        {/*  />*/}
        {/*  {numPages && numPages > 1 &&<div>*/}
        {/*    <p>*/}
        {/*      Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}*/}
        {/*    </p>*/}
        {/*    <button*/}
        {/*      type="button"*/}
        {/*      disabled={pageNumber <= 1}*/}
        {/*      onClick={previousPage}*/}
        {/*    >*/}
        {/*      Previous*/}
        {/*    </button>*/}
        {/*    <button*/}
        {/*      type="button"*/}
        {/*      disabled={!numPages || pageNumber >= numPages}*/}
        {/*      onClick={nextPage}*/}
        {/*    >*/}
        {/*      Next*/}
        {/*    </button>*/}
        {/*  </div>}*/}
        {/*</Document>}*/}
        {/*{!isPdf && <img src={fileUrl} className='file-viewer-img' alt=''/>}*/}
      </>
      }
    </>
  );
}
