import { ReactChild } from 'react';

export interface ConfirmDialogProps {
  title?: ReactChild,
  body: ReactChild | null,
  confirmLabel?: string,
  cancelLabel?: string,
  onConfirm: Function,
  onCancel?: Function,
  noCloseAtConfirm?: boolean,
  variant?: "standard" | "delete"
}

export interface ConfirmDialogState extends ConfirmDialogProps {
  open: boolean
}

export interface ConfirmDialogAction extends ConfirmDialogProps {
  type: ConfirmDialogActionType
}

export enum ConfirmDialogActionType {
  OPEN = 'CONFIRM_DIALOG/SHOW_CONFIRM',
  CLOSE = 'CONFIRM_DIALOG/HIDE_CONFIRM',
}

const InitState: ConfirmDialogProps = {
  title: undefined,
  body: null,
  confirmLabel: undefined,
  cancelLabel: undefined,
  onConfirm: () => {
  },
  onCancel: undefined
}

const reducer = (
  state: ConfirmDialogState = {
    ...InitState,
    open: false
  },
  action: ConfirmDialogAction) => {
  switch (action.type) {
    case ConfirmDialogActionType.OPEN:
      const variant = action.variant || "standard";
      return {
        ...state,
        open: true,
        ...action,
        variant
      };
    case ConfirmDialogActionType.CLOSE:
      return {...state, ...InitState, open: false, noCloseAtConfirm: false}
  }
  return state;
}

// action creator
export const setConfirmDialogOpen = (props: ConfirmDialogProps): ConfirmDialogAction => ({
  type: ConfirmDialogActionType.OPEN,
  ...props
});

export const setConfirmDialogClose = (): ConfirmDialogAction => ({
  type: ConfirmDialogActionType.CLOSE,
  body: null,
  onConfirm: () => {
  }
});

// utility
export const getConfirmDialogState = (state: { ConfirmDialog: ConfirmDialogState }): ConfirmDialogState => {
  return state.ConfirmDialog;
}

export default reducer;
