import {Route, Routes} from "react-router-dom";
import {ProtectedRoute} from "../base/protected-routes";
import {useHelp} from "../help/hook";
import {ALLEXPNOTE_URL, DETAIL_URL} from "../model";
import {HelpCurrentPage} from "../../reducers/Help";
import {ExpNoteAdminList} from "./admin/list";
import {ExpNoteDetail} from "./detail";
import {useExpNoteList} from "./hooks/useExpNoteList";
import {ExpNoteListRole} from "../../reducers/ExpNoteList";
import {useLoggedUser} from "../../hooks/useLoggedUser";

export const ExpNoteAdmin = () => {

  useHelp({
    paths: [
      {
        path: `${ALLEXPNOTE_URL}`,
        page: HelpCurrentPage.EXP_NOTE_ADMIN,
        exactMatch: true
      }
    ]
  });

  const {user: loggedUser} = useLoggedUser();

  const {
    currentRole,
    updateCurrentRole
  } = useExpNoteList();

  if (loggedUser && loggedUser.isAdmin && (!currentRole || currentRole !== ExpNoteListRole.ADMIN)) {
    updateCurrentRole(ExpNoteListRole.ADMIN)
  }

  return (
    <ProtectedRoute isAdmin>
      <Routes>
        <Route path="/" element={<ExpNoteAdminList/>}/>
        <Route path={`${DETAIL_URL}/:id/*`} element={<ExpNoteDetail isAdmin/>}/>
        {/*<Route path="/edit/:id" element={<LevelEdit/>}/>*/}
      </Routes>
    </ProtectedRoute>
  );
}
