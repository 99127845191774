import {
  ACCOUNT_URL, ALLEXPNOTE_URL, APPROVEEXPNOTE_URL, CRE_CARD_MOV_URL,
  DETAIL_URL,
  EDIT_URL, EXPNOTE_URL,
  HOME_ADMIN_URL,
  HOME_TRAVELLER_URL,
  LEVEL_URL,
  NEW_URL, PROJECT_URL, REPORT_URL, SETTINGS_URL, SUPPLIER_URL, TRAVEL_POLICIES_URL,
  USER_URL
} from '../components/model';

export const SET_CURRENT_PAGE = 'HELP/SET_CURRENT_PAGE';
export const SET_MENU_ITEM = 'HELP/SET_MENU_ITEM';
export const SET_OPEN = 'HELP/SET_OPEN';

export enum HelpCurrentPage {
  HOME_ADMIN = 'HOME_ADMIN',
  HOME_TRAVELLER = 'HOME_TRAVELLER',
  USER_LIST = 'USER_LIST',
  USER_DETAIL = 'USER_DETAIL',
  USER_CARD = 'USER_CARD',
  USER_TARIF = 'USER_TARIF',
  USER_TRAVEL_POLICY = 'USER_TRAVEL_POLICY',
  USER_PROJECT = 'USER_PROJECT',
  USER_EDIT = 'USER_EDIT',
  USER_NEW = 'USER_NEW',
  LEVEL_LIST = 'LEVEL_LIST',
  LEVEL_DETAIL = 'LEVEL_DETAIL',
  LEVEL_EDIT = 'LEVEL_EDIT',
  LEVEL_NEW = 'LEVEL_NEW',
  ACCOUNT_LIST = 'ACCOUNT_LIST',
  ACCOUNT_DETAIL = 'ACCOUNT_DETAIL',
  ACCOUNT_EDIT = 'ACCOUNT_EDIT',
  ACCOUNT_NEW = 'ACCOUNT_NEW',
  SUPPLIER_LIST = 'SUPPLIER_LIST',
  SUPPLIER_DETAIL = 'SUPPLIER_DETAIL',
  SUPPLIER_EDIT = 'SUPPLIER_EDIT',
  SUPPLIER_NEW = 'SUPPLIER_NEW',
  PROJECT_LIST = 'PROJECT_LIST',
  PROJECT_DETAIL = 'PROJECT_DETAIL',
  PROJECT_EDIT = 'PROJECT_EDIT',
  PROJECT_NEW = 'PROJECT_NEW',
  EXP_NOTE_ADMIN = 'EXP_NOTE_ADMIN',
  EXP_NOTE_TRAVELLER = 'EXP_NOTE_TRAVELLER',
  EXP_NOTE_APPROVER = 'EXP_NOTE_APPROVER',
  EXP_NOTE_DASHBOARD_ADMIN = 'EXP_NOTE_DASHBOARD_ADMIN',
  EXP_NOTE_DASHBOARD_TRAVELLER = 'EXP_NOTE_DASHBOARD_TRAVELLER',
  EXP_NOTE_EXPENSES = 'EXP_NOTE_EXPENSES',
  EXP_NOTE_EXPENSE_DETAIL = 'EXP_NOTE_EXPENSE_DETAIL',
  EXP_NOTE_PAYMENTS = 'EXP_NOTE_PAYMENTS',
  EXP_NOTE_PAYMENT_DETAIL = 'EXP_NOTE_PAYMENT_DETAIL',
  EXP_NOTE_CRE_CARD_MOV = 'EXP_NOTE_CRE_CARD_MOV',
  EXP_NOTE_CRE_CARD_MOV_LINK_DIALOG = 'EXP_NOTE_CRE_CARD_MOV_LINK_DIALOG',
  TRAVEL_POLICIES_LIST = 'TRAVEL_POLICIES_LIST',
  TRAVEL_POLICIES_NEW = 'TRAVEL_POLICIES_NEW',
  TRAVEL_POLICIES_EDIT = 'TRAVEL_POLICIES_EDIT',
  TRAVEL_POLICIES_DETAILS = 'TRAVEL_POLICIES_DETAILS',
  SETTINGS = 'SETTINGS',
  EXPENSE_EDIT = 'EXPENSE_EDIT',
  REPORT = 'REPORT',
  CRE_CARD_MOV = 'CRE_CARD_MOV',
  NOT_FOUND = 'NOT_FOUND'
}

export const HelpPagePath = [
  {
    pageName: 'HOME_ADMIN',
    path: HOME_ADMIN_URL
  },
  {
    pageName: 'USER_LIST',
    path: USER_URL
  },
  {
    pageName: 'USER_DETAIL',
    path: `${USER_URL}/${DETAIL_URL}/`
  },
  {
    pageName: 'USER_EDIT',
    path: `${USER_URL}/${EDIT_URL}`
  },
  {
    pageName: 'USER_NEW',
    path: `${USER_URL}/${NEW_URL}`
  },
  {
    pageName: 'LEVEL_LIST',
    path: LEVEL_URL
  },
  {
    pageName: 'LEVEL_DETAIL',
    path: `${LEVEL_URL}/${DETAIL_URL}/`
  },
  {
    pageName: 'LEVEL_EDIT',
    path: `${LEVEL_URL}/${EDIT_URL}`
  },
  {
    pageName: 'LEVEL_NEW',
    path: `${LEVEL_URL}/${NEW_URL}`
  },
  {
    pageName: 'ACCOUNT_LIST',
    path: ACCOUNT_URL
  },
  {
    pageName: 'ACCOUNT_DETAIL',
    path: `${ACCOUNT_URL}/${DETAIL_URL}`
  },
  {
    pageName: 'ACCOUNT_EDIT',
    path: `${ACCOUNT_URL}/${EDIT_URL}`
  },
  {
    pageName: 'ACCOUNT_NEW',
    path: `${ACCOUNT_URL}/${NEW_URL}`
  },
  {
    pageName: 'SUPPLIER_LIST',
    path: SUPPLIER_URL
  },
  {
    pageName: 'SUPPLIER_DETAIL',
    path: `${SUPPLIER_URL}/${DETAIL_URL}`
  },
  {
    pageName: 'SUPPLIER_EDIT',
    path: `${SUPPLIER_URL}/${EDIT_URL}`
  },
  {
    pageName: 'SUPPLIER_NEW',
    path: `${SUPPLIER_URL}/${NEW_URL}`
  },

  {
    pageName: 'PROJECT_LIST',
    path: PROJECT_URL
  },
  {
    pageName: 'PROJECT_DETAIL',
    path: `${PROJECT_URL}/${DETAIL_URL}`
  },
  {
    pageName: 'PROJECT_EDIT',
    path: `${PROJECT_URL}/${EDIT_URL}`
  },
  {
    pageName: 'PROJECT_NEW',
    path: `${PROJECT_URL}/${NEW_URL}`
  },
  {
    pageName: 'EXP_NOTE_ADMIN',
    path: ALLEXPNOTE_URL
  },
  {
    pageName: 'TRAVEL_POLICIES_LIST',
    path: TRAVEL_POLICIES_URL
  },
  {
    pageName: 'TRAVEL_POLICIES_NEW',
    path: `${TRAVEL_POLICIES_URL}/${NEW_URL}`
  },
  {
    pageName: 'TRAVEL_POLICIES_EDIT',
    path: `${TRAVEL_POLICIES_URL}/${EDIT_URL}`
  },
  {
    pageName: 'TRAVEL_POLICIES_DETAILS',
    path: `${TRAVEL_POLICIES_URL}/${DETAIL_URL}`
  },
  {
    pageName: 'SETTINGS',
    path: SETTINGS_URL
  },
  {
    pageName: 'REPORT',
    path: REPORT_URL
  },
  {
    pageName: 'HOME_TRAVELLER',
    path: HOME_TRAVELLER_URL
  },
  {
    pageName: 'EXP_NOTE_TRAVELLER',
    path: EXPNOTE_URL
  },
  {
    pageName: 'EXP_NOTE_APPROVER',
    path: APPROVEEXPNOTE_URL
  },
  {
    pageName: 'CRE_CARD_MOV',
    path: CRE_CARD_MOV_URL
  }
]

// key = value -> pageName.value è la chiave di bundle
// TENERE ALLINEATO CON 'public/locale/it/help.json'
export enum HelpPageName {
  HOME_ADMIN = 'homeAdmin',
  HOME_TRAVELLER = 'homeTraveller',
  USER_LIST = 'userList',
  USER_DETAIL = 'userDetail',
  USER_CARD = 'userCard',
  USER_TARIF = 'userTarif',
  USER_TRAVEL_POLICY = 'userTravelPolicy',
  USER_PROJECT = 'userProject',
  USER_EDIT = 'userEdit',
  USER_NEW = 'userNew',
  LEVEL_LIST = 'levelList',
  LEVEL_DETAIL = 'levelDetail',
  LEVEL_EDIT = 'levelEdit',
  LEVEL_NEW = 'levelNew',
  ACCOUNT_LIST = 'accountList',
  ACCOUNT_DETAIL = 'accountDetail',
  ACCOUNT_EDIT = 'accountEdit',
  ACCOUNT_NEW = 'accountNew',
  SUPPLIER_LIST = 'supplierList',
  SUPPLIER_DETAIL = 'supplierDetail',
  SUPPLIER_EDIT = 'supplierEdit',
  SUPPLIER_NEW = 'supplierNew',
  PROJECT_LIST = 'projectList',
  PROJECT_DETAIL = 'projectDetail',
  PROJECT_EDIT = 'projectEdit',
  PROJECT_NEW = 'projectNew',
  EXP_NOTE_ADMIN = 'expNoteAdmin',
  EXP_NOTE_TRAVELLER = 'expNoteTraveller',
  EXP_NOTE_APPROVER = 'expNoteApprover',
  EXP_NOTE_DASHBOARD_ADMIN = 'expNoteDashboardAdmin',
  EXP_NOTE_DASHBOARD_TRAVELLER = 'expNoteDashboardTraveller',
  EXP_NOTE_EXPENSES = 'expNoteExpenses',
  EXP_NOTE_EXPENSE_DETAIL = 'expNoteExpenseDetail',
  EXP_NOTE_PAYMENTS = 'expNotePayments',
  EXP_NOTE_PAYMENT_DETAIL = 'expNotePaymentsDetail',
  EXP_NOTE_CRE_CARD_MOV = 'expNotecreditcard',
  EXP_NOTE_CRE_CARD_MOV_LINK_DIALOG = 'expNoteCreCardMovLinkDialog',
  TRAVEL_POLICIES_LIST = 'travelPolicyList',
  TRAVEL_POLICIES_NEW = 'travelPolicyNew',
  TRAVEL_POLICIES_EDIT = 'travelPolicyEdit',
  TRAVEL_POLICIES_DETAILS = 'travelPolicyDetail',
  SETTINGS = 'settings',
  EXPENSE_EDIT = 'expenseEdit',
  REPORT = 'report',
  CRE_CARD_MOV = 'creCardMov',
  NOT_FOUND = '',
}

export enum HelpMenuItem {
  HOME_ADMIN = 'HOME_ADMIN',
  USER = 'USER',
  LEVEL = 'LEVEL',
  ACCOUNT = 'ACCOUNT',
  SUPPLIER = 'SUPPLIER',
  PROJECT = 'PROJECT',
  EXP_NOTE_ADMIN = 'EXP_NOTE_ADMIN',
  SETTINGS = 'SETTINGS',
  REPORT = 'REPORT',
  HOME_TRAVELLER = 'HOME_TRAVELLER',
  EXP_NOTE_TRAVELLER = 'EXP_NOTE_TRAVELLER',
  CRE_CARD_MOV = 'CRE_CARD_MOV'
}

// reducer
const reducer = (
  state = {
    currentPage: HelpCurrentPage.HOME_TRAVELLER,
    menuItem: null,
    open: false
  },
  action
) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {...state, currentPage: action.currentPage}
    case SET_MENU_ITEM:
      return {...state, menuItem: action.menuItem}
    case SET_OPEN:
      return {...state, open: action.open}
  }
  return state;
}

// action creator
export const setCurrentPage = (page: HelpCurrentPage) => ({
  type: SET_CURRENT_PAGE,
  currentPage: page
});

export const setMenuItem = (menuItem: string | null) => ({
  type: SET_MENU_ITEM,
  menuItem: menuItem
});

export const setHelpOpen = (open: boolean) => ({
  type: SET_OPEN,
  open
});

// utility
export const getCurrentPage = (state) => {
  return state.Help ? state.Help.currentPage : null;
}

export const getHelpMenuItem = (state) => {
  return state.Help ? state.Help.menuItem : null;
}

export const isHelpOpen = (state) => {
  return state.Help ? state.Help.open : null;
}

export default reducer;
