import React, {useState} from 'react';

import {Box, Button, ListItemButton, Menu, Typography} from '@mui/material';
import {KeyboardArrowDown} from "@mui/icons-material";
import {getRoleLabel, getUserFullName} from "../../../users/model";
import {Size, UserPicture} from "../../../users/UserPicture";
import {useLoggedUser} from "../../../../hooks/useLoggedUser";
import {useTranslation} from "react-i18next";
import {removeToken} from "../../../../config/token";
import {useNavigate} from "react-router-dom";
import {ChangePassword} from "../../../profile/change-password";

type ComponentProps = {
  menuOpened: boolean
}

export default function UserBox({menuOpened}: ComponentProps) {
  const {
    setUser
  } = useLoggedUser();

  const navigate = useNavigate();
  const [changePassOpen, setChangePassOpen] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeToken();
    setUser(null);
    navigate('/login');
  }

  const {user: loggedUser} = useLoggedUser();

  const {t: tCommon} = useTranslation("common");
  const {t: tLayout} = useTranslation('layout', {keyPrefix: 'header.user-box'});

  return (
    <>
      {loggedUser &&
        <Box
          paddingY={2}
          marginBottom={1}
          mx={"10px"}
          className={"user-box"}
        >
          <Button
            onClick={handleClick}
            color="inherit"
            sx={{px: 1}}
          >
            <Box>
              <UserPicture
                user={loggedUser}
                size={Size.LIST}
              />
            </Box>
            {menuOpened &&
              <Box
                sx={{pl: 0.5, opacity: .5}}
              >
                <KeyboardArrowDown sx={{fontSize: "1.1rem"}}/>
              </Box>}
          </Button>
          {menuOpened && <Box>
            <Typography variant={"h5"} mt={2}>{getUserFullName(loggedUser)}</Typography>
            <Typography variant={"subtitle1"}
                        sx={{fontSize: '14px !important'}}>{loggedUser ? getRoleLabel(loggedUser, tCommon) : ''}</Typography>
          </Box>}
        </Box>
      }

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 25,
          horizontal: 25
        }}
      >
        <Box
          className={"user-box-menu"} // TODO: rimuovere
        >
          {loggedUser &&
            <>
              <ListItemButton onClick={() => {
                setAnchorEl(null);
                navigate('/profile');
              }}>
                {tLayout('myProfile')}
              </ListItemButton>
              <ListItemButton onClick={(e) => {
                setAnchorEl(null);
                setChangePassOpen(true);
              }}>
                {tLayout('changePassword')}
              </ListItemButton>
              <ListItemButton onClick={handleLogout}>
                {tLayout('logout')}
              </ListItemButton>
            </>
          }
        </Box>
      </Menu>

      <ChangePassword
        show={changePassOpen}
        onClose={() => setChangePassOpen(false)}
      />
    </>
  );
}
