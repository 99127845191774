import {Button, Grid, Link, Paper, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLoggedUser} from "../../../../../hooks/useLoggedUser";
import {isAdminOnboardingActive} from "../../../model";
import {useTranslation} from "react-i18next";
import {DashboardOnboardingCommon} from "../common";
import {getStoreUrl} from "../../../../license-popup/Service";

export const DashboardOnboardingPersonal = () => {
  const [storeUrl, setStoreUrl] = useState('');
  const {t} = useTranslation('dashboard', {keyPrefix: 'admin.onboarding'});

  const {
    user
  } = useLoggedUser();

  const [show, setShow] = useState<boolean>(false);

  const asyncGetAndSetStoreUrl = async (): Promise<void> => {
    const url = await getStoreUrl(user);
    setStoreUrl(url);
  }

  useEffect(() => {
    asyncGetAndSetStoreUrl().then();
  }, [])

  useEffect(() => {
    if (user) {
      setShow(isAdminOnboardingActive(user.tenant));
    } else {
      setShow(false);
    }
  }, [user]);

  return (
    <Grid item xs={12}>
      <Paper elevation={0} className="onboarding">

        {show ? (
          <DashboardOnboardingCommon
            dismissHandler={() => setShow(false)}
          />
        ) : (
          <Stack
            justifyContent="space-between"
            height="100%"
          >
            <Typography variant="h4">{t("personal.dismissed1")}</Typography>
            <Typography
              variant="h4"
              mr={2}
              display="inline"
            >
              {t("personal.dismissed2")}
            </Typography>
            <Link
              href={storeUrl}
              underline="none"
              target={"_blank"}
              rel="noreferrer"
              display="inline"
            >
              <Button
                variant="contained"
                className="cta-button-dashboard"
              >
                <Typography>{t("personal.dismissed3")}</Typography>
              </Button>
            </Link>
          </Stack>
        )}
      </Paper>
    </Grid>
  );
}
