// React
import React from "react";

// MUI
import {Box, Card, CardContent, Stack, Typography} from "@mui/material";

// Libs
import {useTranslation} from "react-i18next";

// Custom
import {ExpenseType} from "../model";
import {getExpenseTypeLabel} from "../utils";
import {PremiumIcon} from "../../base/premium-icon";
import {PopupContentType} from "../../license-popup/model";


export type NewExpenseCardActionEvent = {
  expenseType: ExpenseType
}

export type NewExpenseCardActionHandler = (event: NewExpenseCardActionEvent) => void;

type NewExpenseCardProps = {
  expenseType: ExpenseType,
  onAction: NewExpenseCardActionHandler
  premium: boolean
}

export const NewExpenseCard: React.FC<NewExpenseCardProps> = ({
  expenseType,
  onAction,
  premium
}) => {

  const {t} = useTranslation(['expense']);

  return (
    <Card
      className='exp-card-new'
      sx={{ cursor: 'pointer' }}
      onClick={() =>{onAction({expenseType: expenseType})}}
    >
      <CardContent sx={{position: 'relative'}}>
        <Box overflow='hidden' >
          <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography variant='body2' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'  className='tp-exp-title'>
              {`${t('menu.label.add')} ${getExpenseTypeLabel(expenseType, t)}`}
            </Typography>
            <Box flexGrow={1}/>
            {!premium && <PremiumIcon type={PopupContentType.NEW_EXPENSE_BUTTON} clickable={false} />}
          </Stack>

        </Box>

      </CardContent>

    </Card>
  );

}
