import {Grid, Stack} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import IconButton from "@mui/material/IconButton";
import {Controller, useFormContext} from "react-hook-form";
import {DatePickerZts} from "../../base/date-picker";
import React from "react";
import {CardsFormValues} from "./CreditCardList";
import {Delete} from "@mui/icons-material";
import {AutocompleteZts} from "../../base/autocomplete";
import {AutocompleteGenericOption} from "../../base/autocomplete/model";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  accountOpts: AutocompleteGenericOption[],
  index: number,
  remove: Function
}

export const SingleCreditCard = ({accountOpts, index, remove}: ComponentProps) => {

  const {control} = useFormContext<CardsFormValues>();

  const {t} = useTranslation("user", {keyPrefix: "detail.items.creditCards"});

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      my={1}
      columnGap={2}
    >
      <Grid flexGrow={1} container spacing={2} mt={0}>
        <Grid item xs={12} md>
          <Controller
            name={`cards.${index}.cardNum`}
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                label={t("number")}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        </Grid>
        <Grid item xs={12} md>
          <Controller
            name={`cards.${index}.cardDesc`}
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                label={t("desc")}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        </Grid>
        <Grid item xs={12} md>
          <Controller
            name={`cards.${index}.expireDate`}
            control={control}
            render={({field, fieldState}) => {
              return <DatePickerZts
                label={t("expireDate")}
                field={field}
                errorMsg={fieldState.error?.message}
                onChangeHandler={(value: Date) => {
                  //formikProps.setFieldValue(field.name, value);
                  field.onChange(value);
                }}
              />
            }}
          />
        </Grid>
        <Grid item xs={12} md>
          <Controller
            name={`cards.${index}.account`}
            control={control}
            render={({field, fieldState}) => {
              return <AutocompleteZts
                id={field.name}
                options={accountOpts}
                label={t("account")}
                selectedValue={field.value}
                setValue={(_, value) => {
                  field.onChange(value);
                }}
                codeAndDesc={true}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        </Grid>
      </Grid>

      <IconButton
        size={"small"}
        sx={{
          mt: {
            xs: 1,
            md: -1,
          },
          alignSelf: {
            xs: 'flex-start',
            md: 'center'
          }
        }}
        onClick={() => remove(index)}
      >
        <Delete className={"text-danger"}/>
      </IconButton>

    </Stack>
  );
}
