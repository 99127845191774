// libs
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Logger } from 'react-logger-lib';
import SimpleJsLog from '../../util/Logger';

// Custom
import { createError } from '../../util/ErrorUtil';
import { UserLoginResponse } from '../login/model';
import { getLoginPortalUrl } from '../../config/environment';
import { getLoginPortalJwt } from '../../config/token';
import { TFunction } from 'react-i18next';

// vedi login -> UpdatePasswordByTokenRequest
export interface UpdatePasswordByTokenRequest {
  tokenId: string, //32 ch
  newPassword: string //min 4 ch
}

type PasswordError = {
  code: string;
  template: string;
  parameters: Map<string, string>;
}

// vedi HttpPasswordErrors
type HttpPasswordErrors = {
  message: string,
  errors: PasswordError[];
  errorsSize: number;
}

const LOG: SimpleJsLog = Logger.of('ZTS.Password.Service');

export const updatePasswordByToken = async (body: UpdatePasswordByTokenRequest, t: TFunction): Promise<UserLoginResponse> => {
  const loginPortalUrl = getLoginPortalUrl();
  const url = `${loginPortalUrl}password/token`;

  try {
    // Non richiede Auth
    LOG.trace('updatePasswordByToken. Calling API ...');
    const resp = await axios.post<UserLoginResponse>(url, body);
    LOG.trace('updatePasswordByToken API result', resp);
    return resp.data;
  } catch (err: any) {
    const axiosError = err as AxiosError;
    LOG.trace('updatePasswordByToken | error', axiosError.toJSON());

    const errData: ErrorCodeAndParameters|null = getErrorCodeAndParameters(axiosError);
    let message;
    if (errData==null) {
      message = axiosError.message;
    } else {
      // Devo usare il codice per avere la descrizione in lingua dell'errore

      if (errData.code === 'password.error.weak') {
        // Gestione speciale per la lista di errori
        message = getWeakPasswordErrorMessage(axiosError, t);
      } else {
        message = t(errData.code, errData.parameters);
      }
    }
    throw new Error(message);
  }
}

export interface UpdatePasswordByCredentialsRequest {
  oldPassword: string,
  newPassword: string //min 4 ch
}

type ErrorCodeAndParameters = {
  code: string;
  parameters?: any;
}

export const updatePasswordByCredentials = async (body: UpdatePasswordByCredentialsRequest, t: TFunction): Promise<UserLoginResponse> => {
  const loginPortalUrl = getLoginPortalUrl();
  const url = `${loginPortalUrl}password/credentials`;

  try {
    LOG.trace('updatePasswordByCredentials. Calling API ...');
    const loginJwt = getLoginPortalJwt();
    const config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${loginJwt}`
      }
    }
    const resp = await axios.post<UserLoginResponse>(url, body, config);
    LOG.trace('updatePasswordByCredentials API result', resp);
    return resp.data;
  } catch (err: any) {

    const axiosError = err as AxiosError;
    LOG.trace('updatePasswordByCredentials | error', axiosError.toJSON());

    const errData: ErrorCodeAndParameters|null = getErrorCodeAndParameters(axiosError);
    let message;
    if (errData==null) {
      message = axiosError.message;
    } else {
      // Devo usare il codice per avere la descrizione in lingua dell'errore

      if (errData.code === 'password.error.weak') {
        // Gestione speciale per la lista di errori
        message = getWeakPasswordErrorMessage(axiosError, t);
      } else {
        message = t(errData.code, errData.parameters);
      }
    }
    throw new Error(message);

  }
}

const getErrorCodeAndParameters = (axiosError: AxiosError): ErrorCodeAndParameters | null=> {
  if (axiosError.response) {
    // Ho una response
    // Devo prendere il codice dell'errore
    const errorBody: any = axiosError.response.data;
    if (errorBody.code) {
      return { code: errorBody.code, parameters: errorBody.parameters }
    }
    // Se sono in questo caso meglio tenere i dettagli dell'errore originale
    LOG.trace('getErrorCode original message', errorBody.message);

    const status = axiosError.response.status;
    if (status<200) { return { code: 'http.error.1xx' };}
    if (status<400) { return { code: 'http.error.3xx' };}
    if (status<500) { return { code: 'http.error.4xx' };}
    return { code: 'http.error.5xx' };
  }

  if (axiosError.request) {
    // The request was made but no response was received
    return { code: 'http.error.no_resp'};
  }

  // Se sono qui la request non è stata creata correttamente
  // Problema per lo sviluppo non per l'utente
  return null;
}

const getWeakPasswordErrorMessage = (axiosError: AxiosError, t: TFunction): string => {
  // @ts-ignore
  const passwordError: HttpPasswordErrorsType = axiosError.response.data as HttpPasswordErrorsType;

  let pe: PasswordError;
  let message = t('password.error.weak', {count: passwordError.errorsSize});
  message += '<ul>'
  for (let i = 0; i < passwordError.errors.length; ++i) {
    message += '<li>'
    pe = passwordError.errors[i];
    message += t(pe.code, pe.parameters);
    message += '</li>'
  }
  message += '</ul>'
  return message;
}

export interface PasswordRecoveryRequest {
  email: string
}

export const recoveryPassword = async (body: PasswordRecoveryRequest): Promise<void> => {
  const loginPortalUrl = getLoginPortalUrl();
  const url = `${loginPortalUrl}auth/recovery`;
  try {
    LOG.trace('recoveryPassword. Calling API ...');
    const config: AxiosRequestConfig = {
      headers: {
        'Authorization': `NONE`
      }
    }
    const resp = await axios.post<void>(url, body, config);
    LOG.trace('recoveryPassword API result', resp);
  } catch (err: any) {
    throw createError(err);
  }
}
