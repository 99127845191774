import { getApiUrl } from '../../config/environment';
import { ExpensesReports, ReportPreferenceRequest } from './model';
import axios from 'axios';
import { createError } from '../../util/ErrorUtil';

export const getAllReports = async (): Promise<ExpensesReports[]> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.get(`${apiUrl}/reports/all`);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const addPreference = async (request: ReportPreferenceRequest): Promise<number> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/reports/preferences`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const deletePreference = async (request: ReportPreferenceRequest): Promise<void> => {
  try {
    const apiUrl = getApiUrl();
    await axios.delete(`${apiUrl}/reports/preferences?reportName=${request.reportName}&reportCodeReference=${request.reportIdReference}`);
  } catch (err: any) {
    throw createError(err);
  }
}

export const getAllPreferences = async () => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.get(`${apiUrl}/reports/preferences`);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}
