import vars from '../assets/core/_variables.scss';

export default {
  breakpoints: {
    values: {
      xs: vars.breakpointXs,
      sm: vars.breakpointSm,
      md: vars.breakpointMd,
      lg: vars.breakpointLg,
      xl: vars.breakpointXl,
    }
  }
}
