import { activeStateCode, StateType } from '../model';

export interface Account {
  id: number,
  code: string,
  description: string,
  state: StateType,
  lastUpdateNum: number
}

export interface SaveAccount {
  id: number | null,
  code: string,
  description: string,
  state: string,
  lastUpdateNum: number
}

export enum ListFilterType {
  SEARCH_TEXT
}

export interface ListFilter {
  type: ListFilterType,
  enabled: boolean,
  label: string,
  value: any,
  hidden?: boolean
}

export const ListFilters: ListFilter[] = [
  {
    type: ListFilterType.SEARCH_TEXT,
    label: '',
    enabled: true,
    hidden: true,
    value: ''
  },
]

export const isActiveAccount = (account: Account) => {
  return account && activeStateCode === account.state;
}

export const getAccountCodeAndDescription = (account: Account | null): string => {
  return account ? `${account.code} ${account.description}` : '';
}

// vedi CreateGeneralAccRequest.java
export interface CreateAccountRequest {
  code: string,
  description: string,
  state: StateType
}

// vedi CreateGeneralAccResponse.java
export interface CreateAccountResponse {
  id: number;
}
