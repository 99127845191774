import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const SettingsDigitStorePopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile conservare digitalmente le note spese. <strong>Il servizio &egrave; integrato con la
            Conservazione Digitale Zucchetti e ha il costo di 1,5&euro; per utente/mese</strong>. Cattura gli scontrini
          con l&rsquo;app mobile di ZTravel Smart, controlla la nota spese e conservala digitalmente: d&igrave; addio
          alla carta prodotta dalle note spese e alle buste di giustificativi sulla scrivania!</p>
      );
    default:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile conservare digitalmente le note spese. <strong>Il servizio &egrave; integrato con la
            Conservazione Digitale Zucchetti e ha il costo di 1,5&euro; per utente/mese</strong>. Cattura gli scontrini
          con l&rsquo;app mobile di ZTravel Smart, controlla la nota spese e conservala digitalmente: d&igrave; addio
          alla carta prodotta dalle note spese e alle buste di giustificativi sulla scrivania!</p>
      );
  }
}
