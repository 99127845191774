import IconButton from "@mui/material/IconButton";
import {DeleteForever} from "@mui/icons-material";
import {HelpCurrentPage} from "../../../../../../reducers/Help";
import React, {useEffect, useState} from "react";
import {ExpNotePayment, getPaymentCausalDesc, PaymentCausal, PaymentSaveRequest} from "../../../../model";
import {CommonDialog} from "../../../../../base/common-dialog";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {NewPaymentFormValues, NewPaymentInitValues, paymentValidationSchema} from "../validation";
import {useTranslation} from "react-i18next";
import {useExpNoteDetail} from "../../../hooks/useExpNoteDetail";
import {formatNumber, parseNumber} from "../../../../../../util/NumberUtil";
import {Currency} from "../../../../../model";
import {getCurrencies} from "../../../../../../util/BaseService";
import {createAutocompleteCurrencyOption} from "../../../../expense/model";
import {createAutocompleteGenericOption} from "../../../../../base/autocomplete/model";
import {PaymentEdit} from "./PaymentEdit";
import {createPayment, deletePayment, getAvailableAmountsToReturn, updatePayment} from "../../../../Service";
import {useErrorMessage} from "../../../../../../util/ErrorUtil";
import {useLoggedUser} from "../../../../../../hooks/useLoggedUser";

type ComponentProps = {
  show: boolean,
  onClose: Function,
  payment: ExpNotePayment | null,
  create?: boolean,
  isReadonly: boolean
}

export const ExpNotePaymentDetail = ({show, onClose, payment, create, isReadonly}: ComponentProps) => {

  const {expNote} = useExpNoteDetail();
  const {t} = useTranslation(['exp-note', 'validation', 'api-error']);
  const {t: tCausal} = useTranslation("exp-note", {keyPrefix: "payment"});

  const [saving, setSaving] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [currencyItems, setCurrencyItems] = useState<Currency[] | null>(null);

  const {convertError} = useErrorMessage();

  const {userLocale: locale, companyDecimalNum} = useLoggedUser();

  const formMethods = useForm<NewPaymentFormValues>({
    resolver: yupResolver(paymentValidationSchema(t, locale, companyDecimalNum)),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  const mapCurrency = (items: Currency[]): Promise<Currency[]> => {
    return new Promise<Currency[]>(res => res(items.map((c, index) => ({...c, id: index + 1}))));
  }

  useEffect(() => {
    if (payment) {
      setResetting(true);

      getCurrencies()
        .then(items => mapCurrency(items))
        .then(res => {
          setCurrencyItems(res);
          getFormValuesFromPayment(payment, res)
            .then(res => formMethods.reset(res));
        })
        .finally(() => setResetting(false));
    }
    if (create) {
      setResetting(true);
      getCurrencies()
        .then(items => mapCurrency(items))
        .then(res => {
          setCurrencyItems(res);
          formMethods.reset(NewPaymentInitValues);
        })
        .finally(() => setResetting(false));
    }
  }, [payment, create]);

  const getFormValuesFromPayment = async (payment: ExpNotePayment, currencies: Currency[]): Promise<NewPaymentFormValues> => {
    let currency: Currency | null | undefined = null;
    let convertedCurrency: Currency | null | undefined = null;
    if (currencies) {
      currency = currencies.find(c => c.code === payment.currencyCode);
      convertedCurrency = currencies.find(c => c.code === payment.convertedCurrencyCode);
    }

    let amount = payment.currAmount;

    let causalItemId: number = -1;
    switch (payment.causal) {
      case PaymentCausal.ADVANCE:
        causalItemId = 1;
        break;
      case PaymentCausal.CHANGE_CURRENCY:
        causalItemId = 2;
        amount *= -1;
        break;
      case PaymentCausal.RETURN_CURRENCY:
        causalItemId = 3;
        amount *= -1;
        break;
    }
    const causalItem = createAutocompleteGenericOption(causalItemId, payment.causal, getPaymentCausalDesc(tCausal, payment.causal));

    return {
      id: payment.id,
      operDate: payment.operDate,
      causal: causalItem,
      currency: currency ? createAutocompleteCurrencyOption(-1, currency.code, currency.decimalNum) : null,
      convertedCurrency: convertedCurrency ? createAutocompleteCurrencyOption(-1, convertedCurrency.code, convertedCurrency.decimalNum) : null,
      amount: amount ? formatNumber(amount, locale, currency ? currency.decimalNum : companyDecimalNum) : '',
      convertedAmount: payment.convertedCurrAmount ? formatNumber(payment.convertedCurrAmount, locale, convertedCurrency ? convertedCurrency.decimalNum : companyDecimalNum) : '',
      maxAmount: 0
    }
  }

  const handleActionButtonClicked = (save) => {
    if (save) {
      formMethods.handleSubmit(handleSave)();
    } else {
      onClose(false);
    }
  }

  const handleSave = async (values: NewPaymentFormValues) => {
    if (!expNote) {
      return;
    }

    setErrorMsg(null);
    setSaving(true);

    const savePromise = savePayment(values);
    if (savePromise) {
      savePromise
        .then(() => onClose(true))
        .catch(err => convertError(err).then(msg => setErrorMsg(msg)))
        .finally(() => setSaving(false));
    } else {
      setSaving(false);
    }
  }

  const savePayment = (values: NewPaymentFormValues): Promise<number> | undefined => {
    if (!expNote) {
      return;
    }

    let currAmount = parseNumber(values.amount, locale);
    if (!currAmount) {
      console.log('amount is null')
      throw new Error('amount is null');
    }

    const isChangeCurrency = values.causal && values.causal.code === PaymentCausal.CHANGE_CURRENCY;
    const isReturnCurrency = values.causal && values.causal.code === PaymentCausal.RETURN_CURRENCY;

    if (isChangeCurrency || isReturnCurrency) {
      currAmount *= -1;
    }

    const request: PaymentSaveRequest = {
      id: payment ? payment.id : null,
      //@ts-ignore
      causal: values.causal?.code,
      //@ts-ignore
      operDate: values.operDate,
      //@ts-ignore
      currencyCode: values.currency?.code,
      currAmount,
      lastUpdNum: expNote.lastUpdNum
    }

    if (isChangeCurrency) {
      const convertedAmount = parseNumber(values.convertedAmount, locale);
      if (!convertedAmount) {
        console.log('convertedAmount is null')
        throw new Error('convertedAmount is null');
      }
      //@ts-ignore
      request.convertedCurrAmount = convertedAmount;
      request.convertedCurrencyCode = values.convertedCurrency?.code;
    }

    return payment ? updatePayment(expNote.id, payment.id, request) : createPayment(expNote.id, request);
  }

  const handleDeleteClicked = () => {
    if (expNote && payment) {
      setErrorMsg(null);
      setSaving(true);
      deletePayment(expNote.id, payment.id, expNote.lastUpdNum)
        .then(() => onClose(true))
        .catch(err => convertError(err).then(msg => setErrorMsg(msg)))
        .finally(() => setSaving(false));
    }
  }

  return (
    <CommonDialog
      show={show}
      title={t(payment ? "payment.edit.titleUpdate" : "payment.edit.titleNew")}
      titleIcon={
        payment && !isReadonly ? (
          <IconButton
            color="primary"
            className={"delete-button"}
            onClick={handleDeleteClicked}
          >
            <DeleteForever/>
          </IconButton>
        ) : (<></>)
      }
      widths={[
        {breakpoint: "lg", width: "820px"}
      ]}
      saving={saving}
      errorMsg={errorMsg}
      onClose={handleActionButtonClicked}
      page={show ? HelpCurrentPage.EXP_NOTE_PAYMENT_DETAIL : HelpCurrentPage.EXP_NOTE_PAYMENTS}
      showCta={!isReadonly}
    >

      {!resetting ? (
        <FormProvider {...formMethods}>
          <form noValidate>
            <PaymentEdit
              payment={payment}
              currencyItems={currencyItems}
              readonly={isReadonly}
            />
          </form>
        </FormProvider>
      ) : (<></>)}

    </CommonDialog>
  );
}
