import {ZtsTableColumn} from "../../base/table/model";
import {Typography} from "@mui/material";
import {formatDate} from "../../../util/DateUtil";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {useTranslation} from "react-i18next";
import {CompleteCreCardMovDto, CreCardMovState} from "../model";
import {TooltipZts} from "../../base/tooltip";
import Badge from "@mui/material/Badge";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React from "react";
import {Loader} from "../../base/loader";
import {ZtsTable} from "../../base/table";
import notValidCreCardMovImg from "../../../assets/images/cre-card-mov-not-valid.png";
import {formatAmount} from "../../../util/NumberUtil";
import {Currency} from "../../model";

type ComponentProps = {
  loadingRows: boolean,
  pagedRows: CompleteCreCardMovDto[],
  grouped?: boolean,
  handleMenuClick: (e: React.MouseEvent<HTMLButtonElement>, row: CompleteCreCardMovDto) => void,
  currencies: Currency[],
  openRowDetail: (id: number) => void
}

export const CommonList = ({
                             loadingRows,
                             pagedRows,
                             grouped,
                             handleMenuClick,
                             currencies,
                             openRowDetail
                           }: ComponentProps) => {

  const {t} = useTranslation('cre-card-mov');

  const {
    userLocale
  } = useLoggedUser();

  const columns: ZtsTableColumn[] = [
      {
        id: 'creCardId',
        label: t('columns.creCardId'),
        header: <Typography display={'inline-block'} noWrap className={"table-header"}
                            textTransform={"uppercase"} variant={"h6"}>{t('columns.creCardId')}</Typography>,
        typography: 'caption',
        sortIconAlignment: 'flex-start',
        align: 'left',
        formatter: (_: number, value: CompleteCreCardMovDto) => value.creCardId
      },
      {
        id: 'creCardMovUploadTime',
        label: t('columns.creCardMovUploadTime'),
        header: <Typography display={'inline-block'} noWrap className={"table-header"}
                            textTransform={"uppercase"}
                            variant={"h6"}>{t('columns.creCardMovUploadTime')}</Typography>,
        typography: 'caption',
        sortIconAlignment: 'flex-start',
        align: 'left',
        formatter: (_: number, value: CompleteCreCardMovDto) => formatDate(value.creCardMovUploadTime, userLocale)
      },
      {
        id: 'creCardNumCollab',
        label: t('columns.creCardNumCollab'),
        header: <Typography display={'inline-block'} noWrap className={"table-header"}
                            textTransform={"uppercase"} variant={"h6"}>{t('columns.creCardNumCollab')}</Typography>,
        typography: 'caption',
        sortIconAlignment: 'flex-start',
        align: 'left',
        formatter: (_: number, value: CompleteCreCardMovDto) => {
          if (value.creCardNum) {
            if (value.collab && value.collab.trim().length > 0) {
              return (
                <>
                  <Typography variant={'body2'}>{value.collab}</Typography>
                  <Typography variant={'subtitle2'} color={'gray'}>{value.creCardNum}</Typography>
                </>
              )
            } else {
              return <Typography variant={'caption'}>{value.creCardNum}</Typography>
            }
          }
        }
      },
      {
        id: 'creCardDescription',
        label: t('columns.creCardDescription'),
        header: <Typography display={'inline-block'} noWrap className={"table-header"}
                            textTransform={"uppercase"} variant={"h6"}>{t('columns.creCardDescription')}</Typography>,
        typography: 'caption',
        align: 'left',
        sortIconAlignment: 'flex-start',
        style: grouped ? {} : {minWidth: '180px'},
        formatter:
          (_: number, value: CompleteCreCardMovDto) => value.creCardDescription
      },
      {
        id: 'payDate',
        label:
          t('columns.payDate'),
        header:
          <Typography display={'inline-block'} noWrap className={"table-header"}
                      textTransform={"uppercase"} variant={"h6"}>{t('columns.payDate')}</Typography>,
        typography:
          'caption',
        align:
          'left',
        sortIconAlignment:
          'flex-start',
        formatter:
          (_: number, value: CompleteCreCardMovDto) => formatDate(value.payDate, userLocale)
      }
      ,
      {
        id: 'compAmount',
        label:
          t('columns.currAmount'),
        align:
          'right',
        header:
          <Typography display={'inline-block'} noWrap className={"table-header"}
                      textTransform={"uppercase"} variant={"h6"}>{t('columns.currAmount')}</Typography>,
        typography:
          'caption',
        sortIconAlignment:
          'flex-start',
        formatter: (_: number, value: CompleteCreCardMovDto) => {
          const decimalNum = currencies.find(c => c.code === value.currency)?.decimalNum || 0;
          return formatAmount(value.currAmount, userLocale, decimalNum, '');
        }
      }
      ,
      {
        id: 'currency',
        label:
          t('columns.currency'),
        header:
          <Typography display={'inline-block'} noWrap className={"table-header"}
                      textTransform={"uppercase"} variant={"h6"}>{t('columns.currency')}</Typography>,
        typography:
          'caption',
        sortIconAlignment:
          'flex-start',
        align:
          'right',
        formatter:
          (_: number, value: CompleteCreCardMovDto) => value.currency
      }
      ,
      {
        id: 'creCardMovState',
        label:
          t('columns.creCardMovState'),
        header:
          <Typography display={'inline-block'} noWrap className={"table-header"}
                      textTransform={"uppercase"} variant={"h6"}>{t('columns.creCardMovState')}</Typography>,
        typography:
          'caption',
        sortIconAlignment:
          'flex-start',
        align:
          'right',
        formatter:
          (_: number, value: CompleteCreCardMovDto) => {
            const state = value.creCardMovState;
            switch (state) {
              case CreCardMovState.CONNECTED:
                if (value.checkedNotes) {
                  return <TooltipZts
                    title={value.checkedNotes}
                    placement={"bottom"}
                    enterDelay={400}
                  >
                    <Badge color={'info'} variant={'dot'} overlap={'circular'}>
                      <LinkIcon fontSize={'small'} sx={{color: '#2DD37A', ml: '12px'}}/>
                    </Badge>
                  </TooltipZts>;
                } else {
                  return <LinkIcon fontSize={'small'} sx={{color: '#2DD37A', ml: '12px'}}/>;
                }
              case CreCardMovState.DISCONNECTED:
                return <LinkOffIcon fontSize={'small'} sx={{color: '#d32d66', ml: '12px'}}/>;
              case CreCardMovState.EXCLUDED:
                if (value.discardedNotes) {
                  return (
                    <TooltipZts
                      title={
                        <Typography>
                          {value.notValid ? t('tooltip.notValid') : null}
                          <Typography lineHeight={1.1}>{value.discardedNotes}</Typography>
                        </Typography>
                      }
                      placement={"bottom"}
                      enterDelay={400}
                    >
                      {
                        value.notValid ? (
                          <img src={notValidCreCardMovImg} width={20} alt=""/>
                        ) : (
                          <Badge color={'info'} variant={'dot'} overlap={'circular'}>
                            <PlaylistRemoveIcon fontSize={'small'} sx={{color: 'orange', ml: '12px'}}/>
                          </Badge>
                        )
                      }
                    </TooltipZts>
                  );
                } else {
                  return <PlaylistRemoveIcon fontSize={'small'} sx={{color: 'orange', ml: '12px'}}/>;
                }
            }
          }
      }
      ,
      {
        id: 'controlled',
        label:
          t('columns.controlled'),
        header:
          <Typography display={'inline-block'} noWrap className={"table-header"}
                      textTransform={"uppercase"} variant={"h6"}>{t('columns.controlled')}</Typography>,
        typography:
          'caption',
        sortIconAlignment:
          'flex-start',
        align:
          'center',
        unsortable:
          true,
        formatter:
          (_: number, value: CompleteCreCardMovDto) => {
            if (value.checked !== false) {
              if (value.checkedNotes && value.checkedNotes.length > 0) {
                return <TooltipZts
                  title={value.checkedNotes}
                  placement={"bottom"}
                  enterDelay={400}
                >
                  <Badge color={'info'} variant={'dot'} overlap={'circular'}>
                    <CheckIcon fontSize={'small'} sx={{color: '#2DD37A'}}/>
                  </Badge>
                </TooltipZts>;
              }
              return <CheckIcon fontSize={'small'} sx={{color: '#2DD37A'}}/>;
            } else {
              return <ClearIcon fontSize={'small'} sx={{color: '#d32d66'}}/>;
            }
          }
      }
      ,
      {
        id: 'moreVert',
        label:
          '',
        unsortable:
          true,
        align:
          'left',
        typography:
          'caption',
        formatter:
          (_: number, row: CompleteCreCardMovDto) => <IconButton
            onClick={(e) => {
              handleMenuClick(e, row);
            }}
          >
            <MoreHorizIcon/>
          </IconButton>
      }
    ]
  ;

  return (
    <>
      {
        loadingRows ? (
          <Loader show={true}/>
        ) : (
          <ZtsTable
            tableCssClass="user-selectable"
            columns={columns}
            rows={pagedRows}
            initialSort={{orderBy: 'creCardId', orderDir: 'desc'}}
            rowDoubleClickHandler={(row) => openRowDetail(row.creCardId)}
            stickyHeader={true}   // impostato a true rende l'header sempre visibile
                                  // anche quando si scrolla ma c'è un problema perchè
                                  // la barra di scorrimento compare anche quando
                                  // il contenuto non va in overflow
          />
        )
      }
    </>
  );

}
