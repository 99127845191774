import {useFieldArray, useFormContext} from "react-hook-form";
import {FormValues} from "./model";
import React, {useEffect, useState} from "react";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../base/autocomplete/model";
import {Button, Grid} from "@mui/material";
import {Step2Inner} from "./Step2Inner";

export const Step2 = () => {

  const {control} = useFormContext<FormValues>();

  const fieldArrayMethods = useFieldArray({
    name: "languages",
    control
  });

  console.log(fieldArrayMethods)

  const [languageItems, setLanguageItems] = useState<AutocompleteGenericOption[] | null>(null);

  useEffect(() => {
    setLanguageItems([
      createAutocompleteGenericOption(1, 'A', 'Javascript'),
      createAutocompleteGenericOption(2, 'B', 'Python'),
    ]);
  }, []);

  return (
    <Grid item xs={12}>
      {languageItems && fieldArrayMethods.fields.map((item, index) => {
        return (
          <Step2Inner
            key={item.id}
            index={index}
            languageItems={languageItems}
            remove={(idx) => fieldArrayMethods.replace(fieldArrayMethods
              .fields
              .filter((_, index) => index !== idx))}
          />
        )
      })
      }
      <Button
        variant={"contained"}
        sx={{
          m: 2
        }}
        onClick={() => {
          fieldArrayMethods.append({language: null, rating: '', ackDate: null});
        }}
      >
        Add Language
      </Button>
    </Grid>
  );
}
