// React
import React, {useEffect, useState} from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";

// Yup
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

// MUI
import {Stack} from "@mui/material";

// TODO spostare
// https://mui.com/blog/lab-date-pickers-to-mui-x/
//https://mui.com/x/react-date-pickers/getting-started/

// Libs
import {useTranslation} from "react-i18next";
import {Logger} from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// Custom
import {TextFieldZts} from "../../base/text-field";
import {CommonDialog} from "../../base/common-dialog";
import {HelpCurrentPage} from "../../../reducers/Help";
import {yupMinMaxRequiredString, yupRequiredDate} from "../../../util/YupUtil";
import {SelectZts} from "../../base/select";
import {SelectOption} from "../../base/select/model";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {TravelPolicy} from "../model";
import {createTravelPolicy, CreateTravelPolicyRequest} from "../Service";
import {DatePickerZts} from "../../base/date-picker";


export type NewTravelPolicyType = {
  //code: string, - auto generato
  description: string,
  startDate: Date,
  masterTravelPolicyId: number | null // il campo value della SelectOption
}


export type NewAccountModalClosedModeType = 'cancel' | 'created';

export type NewTravelPolicyModalClosedEvent = {
  action: NewAccountModalClosedModeType
  travelPolicyId: number | null
}

export type NewTravelPolicyModalClosedHandler = (event: NewTravelPolicyModalClosedEvent) => void;


type NewTravelPolicyModalProps = {
  newItem: NewTravelPolicyType | null
  masterList: TravelPolicy[]
  onClose: NewTravelPolicyModalClosedHandler
  title?: string
}

export const NewTravelPolicyModal: React.FC<NewTravelPolicyModalProps> = ({
                                                                            newItem,
                                                                            masterList,
                                                                            onClose,
                                                                            title
                                                                          }) => {

  const LOG: Slf4jsLog = Logger.of('ZTS.TravelPolicies.NewTravelPolicyModal');

  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [masterOptions, setMasterOptions] = useState<SelectOption[]>([]);

  const {t} = useTranslation(['validation']);
  const {t: tCommon} = useTranslation('travel-policy', {keyPrefix: 'common.label'});
  const {convertError} = useErrorMessage();

  // code: yupMinMaxRequiredString(1, 20, t),
  const validationSchema = Yup.object({
    description: yupMinMaxRequiredString(1, 50, t),
    startDate: yupRequiredDate(t),
  });

  //    code: '',
  const defValues: NewTravelPolicyType = {
    description: '',
    startDate: new Date(),
    masterTravelPolicyId: null
  }

  const formMethods = useForm<NewTravelPolicyType>({
    defaultValues: defValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  const onAction = (flag: boolean) => {
    LOG.trace('onAction', flag);
    if (flag) {
      // submit della form
      formMethods.handleSubmit(onFormValid, onFormError)();
    } else {
      onClose({
        action: 'cancel',
        travelPolicyId: null
      });
    }
  }

  const onFormError = (errors) => {
    LOG.trace('onFormError', errors);
  }

  const onFormValid = (data: NewTravelPolicyType) => {
    LOG.trace('onFormValid');
    onCreateTravelPolicy(data)
      .then(() => LOG.trace('onCreateTravelPolicy end.'));
  }

  const onCreateTravelPolicy = async (data: NewTravelPolicyType) => {
    LOG.trace('onCreateTravelPolicy [async]', data);
    setErrorMsg(null);
    setSaving(true);

    //code: data.code,
    try {
      const request: CreateTravelPolicyRequest = {
        description: data.description,
        startDate: data.startDate,
        masterTravelPolicyId: data.masterTravelPolicyId
      }
      const newTravelPolicyId = await createTravelPolicy(request);
      setSaving(false);
      onClose({
        action: 'created',
        travelPolicyId: newTravelPolicyId
      });
    } catch (e) {
      const msg = await convertError(e as Error);
      setErrorMsg(msg);
      setSaving(false);
    }
  }


  const showFlag: boolean = newItem !== null;

  const titleLbl = title ? title : tCommon('newTravelPolicy');

  // const codeLbl = 'Codice';
  const descLbl = tCommon('description');
  const startDateLbl = tCommon('startDate');
  const copyFromLbl = tCommon('copyExpenseFrom');

  useEffect(() => {
    LOG.trace('useEffect [newItem, masterList]', newItem, masterList);
    if (newItem) {
      const initVal: NewTravelPolicyType = {
        ...defValues,
        ...newItem
      };
      LOG.trace('Reset form', initVal);
      formMethods.reset(initVal);
      let list: SelectOption[] = masterList.map(tp => {
        return {value: tp.id, label: tp.description};
      });
      setMasterOptions(list);
    }
  }, [newItem, masterList]);

  return (
    <CommonDialog
      show={showFlag}
      title={titleLbl}
      widths={[
        {breakpoint: "lg", width: "400px"},
      ]}
      saving={saving}
      errorMsg={errorMsg}
      page={HelpCurrentPage.TRAVEL_POLICIES_NEW}
      onClose={onAction}
    >
      <FormProvider {...formMethods}>
        <form noValidate>
          <Stack direction='column' spacing={3}>

            {/*
            <Controller
              name="code"
              render={({field, fieldState}) =>
                <TextFieldZts
                  {...field}
                  required
                  label={codeLbl}
                  errorMsg={fieldState.error?.message}
                />
              }
            />
            */}

            <Controller
              name="description"
              render={({field, fieldState}) =>
                <TextFieldZts
                  {...field}
                  required
                  label={descLbl}
                  errorMsg={fieldState.error?.message}
                />
              }
            />

            <Controller
              name="startDate"
              render={({field, fieldState}) => {
                return <DatePickerZts
                  required
                  field={field}
                  label={startDateLbl}
                  errorMsg={fieldState.error?.message}
                  onChangeHandler={(value: Date) => {
                    //formikProps.setFieldValue(field.name, value);
                    field.onChange(value);
                  }}
                />
              }}
            />

            <Controller
              name="masterTravelPolicyId"
              render={({field, fieldState}) =>
                <SelectZts
                  required
                  errorMsg={fieldState.error?.message}
                  label={copyFromLbl}
                  width={'100%'}
                  items={masterOptions}
                  selectedValue={field.value ? field.value : ''}
                  setValue={(value) => {
                    field.onChange(value)
                  }}
                />
              }
            />

          </Stack>
        </form>
      </FormProvider>
    </CommonDialog>
  );
}
