import {ColumnDataType, CompleteConfiguration} from "../import-configurer/model";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import {TransitionLeftZts} from "../../base/transition";
import {Loader} from "../../base/loader";
import {ArrowBack} from "@mui/icons-material";
import {FormProvider, useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {useTheme} from "@mui/material/styles";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {BatchJobInfos, BatchJobState} from "../../jobs/model";
import {ImportFormValues, importValidationSchema, newImportFormValues} from "../../import/validation";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {getJobsStateByIdList} from "../../jobs/Service";
import {
  getImportConfig,
  importCreCardMovList,
  launchPostImportLinking,
  uploadImportFile
} from "../import-configurer/Service";
import {FileType, MimeType} from "../../base/file-upload/model";
import {FileUploadReq} from "../../import/model";
import {FileUpload} from "../../base/file-upload";
import {FileUploadResp} from "../../model";
import {convertBlobToBase64} from "../../../util/FileUtil";

interface ComponentProps {
  open: boolean,
  closePopup: (refresh: boolean) => void,
  allowedFileType: FileType
}

export const CreCardMovImportPopup = ({open, closePopup, allowedFileType}: ComponentProps) => {
  const [configuration, setConfiguration] = useState<CompleteConfiguration>();

  const [loading, setLoading] = useState(false);
  const [warningMsg, setWarningMsg] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [subTitleMsg, setSubTitleMsg] = useState<string | null>(null);
  const [file, setFile] = useState<FileUploadResp | null>(null);

  const [batchJobId, setBatchJobId] = useState<string | null>(null);
  const [importBatchJobInfo, setImportBatchJobInfo] = useState<BatchJobInfos | null>(null);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);

  const [successfullImport, setSuccesfullImport] = useState<boolean>(false);

  const {t} = useTranslation('cre-card-mov');
  const {t: tErr} = useTranslation('api-error');
  const {t: tPopup} = useTranslation('cre-card-mov', {keyPrefix: 'popup.import'});
  const {userLocale} = useLoggedUser();
  const theme = useTheme();
  const {convertError} = useErrorMessage();

  const formMethods = useForm<ImportFormValues>({
    resolver: yupResolver(importValidationSchema()),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  const resetDialog = (jobState?: BatchJobState) => {
    setLoading(false);
    if (jobState == undefined || jobState != BatchJobState.COMPLETED_WITH_WARNINGS) {
      setWarningMsg(null);
    }
    if (jobState == undefined || jobState != BatchJobState.COMPLETED_WITH_ERRORS) {
      setErrorMsg(null);
    }
    if (jobState == undefined || jobState != BatchJobState.COMPLETED_SUCCESFULLY) {
      setSubTitleMsg(null);
    }
    setFile(null);
    setBatchJobId(null);
    setPollingInterval(null);
    setSuccesfullImport(jobState != undefined && (
      jobState === BatchJobState.COMPLETED_WITH_WARNINGS || jobState === BatchJobState.COMPLETED_SUCCESFULLY));
    formMethods.reset(newImportFormValues);
  }

  useEffect(() => {
    if (open) {
      setSuccesfullImport(false);
      asyncGetAndSetImportConfiguration().then();
    }
  }, [open]);

  useEffect(() => {
    setFile(null);
    setSubTitleMsg(null);
    setWarningMsg(null);
    setErrorMsg(null);

    if (open) {
      setLoading(true);
      formMethods.reset(newImportFormValues);

      Promise.all([])
        .then(res => {

        })
        .catch(err => console.log('Error loading page', err))
        .finally(() => setLoading(false));
    }
  }, [open]);

  // polling quando esiste un job
  // il jobId può essere sia quello dell'import sia quello dell'aggancio e controllo
  useEffect(() => {
    if (batchJobId && !pollingInterval) {
      //console.log('setting interval', batchJobId)
      setPollingInterval(setInterval(getImportJobState, 1000, batchJobId));
    } else if (pollingInterval && !batchJobId) {
      //console.log('resetting interval', pollingInterval)
      clearInterval(pollingInterval);
      setLoading(false);
    }
  }, [batchJobId, pollingInterval]);

  // se l'import termina (importBatchJobInfo valorizzato)
  // viene interrotto il polling, lanciato l'aggancio e controllo
  // e modificato il jobId: questo poi farà scattare un nuovo polling
  useEffect(() => {
    if (importBatchJobInfo && pollingInterval) {

      // metto a null batchJobId
      // serve perchè alle righe successive viene
      // resettato pollingInterval e quindi nell'effect precedente
      // si otterrebbe l'effetto di rischedulare l'interval
      // (perchè si avrebbe batchJobId valorizzato ma pollingInterval null
      // e come conseguenza questo effect verrebbe rieseguito lanciando
      // nuovamente l'aggancio e controllo
      setBatchJobId(null);

      clearInterval(pollingInterval);
      setPollingInterval(null);
      //console.log('launch AGGANCIO e CONTROLLO', pollingInterval, importBatchJobInfo)
      launchPostImportLinking({
        completedBatchJobId: importBatchJobInfo.id,
        locale: userLocale
      })
        .then(resp => {
          setBatchJobId(resp.toString());
        })
    }
  }, [importBatchJobInfo]);

  const getImportJobState = (batchJobId: string): void => {
    if (batchJobId) {
      getJobsStateByIdList(batchJobId).then(resp => {
        const jobInfo = resp.at(0);
        if (jobInfo) {
          switch (jobInfo.batchState) {
            case BatchJobState.WAITING_FOR_EXECUTION:
              return;
            case BatchJobState.RUNNING:
              return;
            case BatchJobState.COMPLETED_SUCCESFULLY:
              if (jobInfo.batchName === "ImportCreCardMovJob") {
                setImportBatchJobInfo(jobInfo);
              } else if (jobInfo.batchName === 'PostImportMovJob') {
                setBatchJobId(null)
                if (importBatchJobInfo) {
                  // il job di import è stata completato correttamente (nessun warning)
                  if (importBatchJobInfo.infoMessageBundleKey === 'importCreCardMov.success.001') {
                    setSubTitleMsg(tPopup(importBatchJobInfo.infoMessageBundleKey, {
                      fileName: importBatchJobInfo.infoMessageBundleParams ? importBatchJobInfo.infoMessageBundleParams[0] : '',
                      importedNum: importBatchJobInfo.infoMessageBundleParams ? importBatchJobInfo.infoMessageBundleParams[1] : ''
                    }));
                    resetDialog(BatchJobState.COMPLETED_SUCCESFULLY);
                  } else {
                    // il job di import è stata completato con warning
                    resetDialog(BatchJobState.COMPLETED_WITH_WARNINGS);
                  }
                }
              }
              break;
            case BatchJobState.COMPLETED_WITH_ERRORS:
              setBatchJobId(null);
              if (jobInfo.batchName === 'ImportCreCardMovJob') {
                let errMsg;
                if (jobInfo.infoMessageBundleParams) {
                  let infoMessageBundleParamsOptionObject = {};
                  jobInfo.infoMessageBundleParams.forEach((value, index) => {
                    infoMessageBundleParamsOptionObject[index] = value;
                  })
                  errMsg = tErr(jobInfo.infoMessageBundleKey, infoMessageBundleParamsOptionObject);
                } else {
                  errMsg = tErr(jobInfo.infoMessageBundleKey);
                }
                resetDialog(BatchJobState.COMPLETED_WITH_ERRORS);
                setErrorMsg(errMsg);
              }
              break;
            case BatchJobState.COMPLETED_WITH_WARNINGS:
              if (jobInfo.batchName === 'ImportCreCardMovJob') {
                let warnMsg;
                if (jobInfo.infoMessageBundleParams) {
                  let infoMessageBundleParamsOptionObject = {};
                  jobInfo.infoMessageBundleParams.forEach((value, index) => {
                    infoMessageBundleParamsOptionObject[index] = value;
                  })
                  warnMsg = tErr(jobInfo.infoMessageBundleKey, infoMessageBundleParamsOptionObject);
                } else {
                  warnMsg = tErr(jobInfo.infoMessageBundleKey);
                }
                setWarningMsg(warnMsg);
                setImportBatchJobInfo(jobInfo);
              } else if (jobInfo.batchName === 'PostImportMovJob') {
                setBatchJobId(null)
                if (importBatchJobInfo && importBatchJobInfo.infoMessageBundleKey === 'importCreCardMov.success.001') {
                  setSubTitleMsg(tPopup(importBatchJobInfo.infoMessageBundleKey, {
                    fileName: importBatchJobInfo.infoMessageBundleParams ? importBatchJobInfo.infoMessageBundleParams[0] : '',
                    importedNum: importBatchJobInfo.infoMessageBundleParams ? importBatchJobInfo.infoMessageBundleParams[1] : ''
                  }));
                  resetDialog(BatchJobState.COMPLETED_SUCCESFULLY);
                }
              }
              break;
          }
        }
      })
        .catch(err => {
          convertError(err).then(msg => setErrorMsg(msg))
        })
    }
  }

  const asyncGetAndSetImportConfiguration = async (): Promise<void> => {
    setLoading(true);
    try {
      const config = await getImportConfig();
      if (config) {
        const sep = {
          csvSeparator: config.csvSep,
          rowsToSkip: config.rowsToSkip,
          dateFormat: config.dateFormat,
          decimalSeparator: config.decimalSep
        };

        const pos = {
          creCardNumColumn: {index: config.posCreCardNum, isValid: true, dataType: ColumnDataType.NONE},
          payDateColumn: {index: config.posPayDate, isValid: true, dataType: ColumnDataType.DATE},
          compAmountColumn: {index: config.posCompAmount, isValid: true, dataType: ColumnDataType.CURRENCY},
          currencyColumn: {index: config.posCurrency, isValid: true, dataType: ColumnDataType.NONE},
          currAmountColumn: {index: config.posCurrAmount, isValid: true, dataType: ColumnDataType.CURRENCY},
          exchangeColumn: {index: config.posExchange, isValid: true, dataType: ColumnDataType.EXCHANGE},
          creCardMovReferenceColumn: {index: config.posReference, isValid: true, dataType: ColumnDataType.NONE},
          creCardMovDescColumn: {index: config.posDescription, isValid: true, dataType: ColumnDataType.NONE},
          catMercColumn: {index: config.posCatMerc, isValid: true, dataType: ColumnDataType.NONE}
        };

        setConfiguration({
          separators: sep,
          positions: pos
        });
      }
    } catch (err: any) {
      convertError(createError(err))
        .then(msg => setErrorMsg(msg))
    } finally {
      setLoading(false);
    }
  }

  const handleExecuteImport = async () => {
    if (!file) {
      setSubTitleMsg("Nessun file è stato selezionato per l'importazione"); //TODO: label
      return;
    }
    if (configuration && file) {
      setLoading(true);
      try {
        importCreCardMovList({
          fileName: file.filename,
          uploadKey: file.uploadKey,
          fileType: file.mimeType,
          filter: {
            rowsToSkip: configuration.separators.rowsToSkip ? configuration.separators.rowsToSkip : undefined,
            decimalSeparator: configuration.separators.decimalSeparator ? configuration.separators.decimalSeparator : undefined,
            dateFormat: configuration.separators.dateFormat ? configuration.separators.dateFormat : undefined,
            csvSeparator: configuration.separators.csvSeparator ? configuration.separators.csvSeparator : undefined
          },
          locale: userLocale
        })
          .then(resp => {
            setBatchJobId(resp.toString());
          })
      } catch (err: any) {
        convertError(err).then(msg => {
          setErrorMsg(msg);
          setLoading(false);
        });
      }
    }
  }

  const fileUploadedHandler = (file: File) => {
    resetDialog();
    if (!file) {
      return;
    }
    new Promise<Blob | null>((res) => {
      if (file.type === MimeType.CSV || file.type === MimeType.TXT || file.type === MimeType.EXCEL || file.type === MimeType.EXCEL_XLSX) {
        res(file);
      }
    })
      .then(res => {
        if (res) {
          return convertBlobToBase64(res);
        }
        throw new Error('Resize error');
      })
      .then(raw => {
        if (!raw) {
          throw new Error('Conversion error');
        }
        const req: FileUploadReq = {
          activateOcr: false,
          diskName: file.name,
          diskSize: file.size,
          diskTime: file.lastModified,
          mimeType: file.type === MimeType.CSV ? MimeType.TXT : file.type,
          rawData: raw,
          toCheck: true
        }

        setSubTitleMsg("Nome del file da importare: " + file.name);
        return uploadImportFile(req);
      })
      .then(resp => {
        setFile(resp);
      })
      .catch(err => {
        convertError(err).then(msg => setErrorMsg(msg));
      });
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionLeftZts}
      fullScreen
      scroll={"paper"}
      fullWidth={true}
      sx={{
        width: useMediaQuery(theme.breakpoints.down("md")) ? "100%" : "35%",
        left: useMediaQuery(theme.breakpoints.down("md")) ? 0 : "unset"
      }}
    >
      <Loader show={loading}/>
      <DialogTitle>
        <Stack
          direction={"row"}
          columnGap={2}
          alignItems={"center"}
          sx={{color: "#000"}}
        >
          <ArrowBack onClick={() => closePopup(successfullImport)}/>
          <Typography variant={"h3"}>{tPopup('title')}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <form noValidate>
            <Box
              height={130}
              marginTop={5}
              textAlign={"center"}
            >
              <FileUpload
                excelEnabled={allowedFileType === FileType.EXCEL}
                imageEnabled={false}
                pdfEnabled={false}
                htmlEnabled={false}
                csvEnabled={allowedFileType === FileType.CSV}
                onFileUploaded={(file: File) => fileUploadedHandler(file)}
                renderElement={(errorMsg, loading) => {
                  //@ts-ignore
                  if (formMethods.formState.errors.attachments?.message) {
                    //@ts-ignore
                    errorMsg = formMethods.formState.errors.attachments?.message;
                  }
                  return <Box
                    className={"expense-upload-container"}
                    width="100%"
                  >
                    {loading ?
                      <CircularProgress/>
                      :
                      <>
                        <Typography variant={"body2"}
                                    textAlign="center">{tPopup('button.dragDrop', {fileType: allowedFileType === FileType.CSV ? 'csv' : 'excel'})}</Typography>
                        {errorMsg && <Typography variant={"body2"} mt={2} className={"text-danger"}>
                          {errorMsg}
                        </Typography>}
                      </>
                    }
                  </Box>
                }}
              />
            </Box>
            <Box marginTop={5} textAlign={"center"}>
              <Typography ml={1} variant={"subtitle1"}>{subTitleMsg}</Typography>
            </Box>

            {
              errorMsg && <Alert
                severity={"error"}
                variant={"filled"}
                sx={{mb: 1, mt: 2}}
                onClose={() => setErrorMsg(null)}>{errorMsg}</Alert>
            }

            {
              warningMsg && <Alert
                severity={"warning"}
                variant={"filled"}
                sx={{mb: 1, mt: 2}}
                onClose={() => setWarningMsg(null)}>{warningMsg}</Alert>
            }
            <Box mt={5} textAlign={'center'} height={'fit-content'}>
              <Button
                variant="contained"
                className={"cta-button-primary"}
                disabled={!file || loading}
                onClick={handleExecuteImport}
              >
                <Typography ml={1} variant={"button"}>{tPopup("button.import")}</Typography>
              </Button>
            </Box>

          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={"cta-button"}
          disabled={loading}
          onClick={() => closePopup(successfullImport)}>
          <Typography variant={"button"}>{tPopup("button.close")}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
