import {Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

export const WelcomeContent1 = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} p={2}>
      <Stack flexDirection={'row'}>
        <Typography variant={'h3'} textAlign={'left'} mb={3}>Benvenuto in</Typography>
        <Box flexGrow={1} />
        <Typography variant={'h5'} fontWeight={500}>01/04</Typography>
      </Stack>
      <Box display={'flex'} flexDirection={'column'} rowGap={3} alignItems={'start'} overflow={'hidden'}
           maxHeight={'50vh'}>
        <Typography variant={'h2'}>ZTravel Smart Personal Edition</Typography>
        <Box display={'flex'} flexDirection={'column'} overflow={'auto'} alignItems={'start'}>
          <p style={{textAlign: 'left'}}>
            Ztravel Smart consente a chi viaggia, il <strong style={{color: '#3c3c3c'}}>Viaggiatore</strong>, di registrare le spese di trasferta e inviarle al controllo amministrativo,
            l'<strong style={{color: '#3c3c3c'}}>Amministratore</strong>, per ottenere il rimborso.
          </p>
          <p style={{textAlign: 'left'}}>
            Il tuo è il primo utente attivo sull'ambiente della tua azienda e possiedi <strong style={{color: '#3c3c3c'}}>entrambi</strong> i ruoli di Amministratore e Viaggiatore.
          </p>
        </Box>
      </Box>
    </Box>
  );
}
