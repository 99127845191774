import React, {useEffect, useState} from "react";
import {Alert, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {Supplier, ListFilterType} from "../model";
import IconButton from "@mui/material/IconButton";
import {PageTitle} from "../../layout/page-title";
import {Filters} from "./Filters";
import {getSuppliers, restoreSupplier} from "../Service";
import {SupplierList} from "./SupplierList";
import {Add} from "@mui/icons-material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import {TooltipZts} from "../../base/tooltip";
import {useTranslation} from "react-i18next";
import {ListFilter} from "../model";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";

export const FilterableList = () => {

  const {t} = useTranslation(['supplier'], {keyPrefix: 'list'});

  const [allSuppliers, setAllSuppliers] = useState<Supplier[]>([]);
  const [supplier, setSupplier] = useState<Supplier[]>([]);
  const [disabledSuppliers, setDisabledSuppliers] = useState<Supplier[]>([]);
  const [loading, setLoading] = useState(false);
  const [showActive, setShowActive] = useState<boolean>(true);

  const [fetchError, setFetchError] = useState<string | null>(null);
  const [toggleClicked, setToggleClicked] = useState("lista");


  const navigate = useNavigate();
  const {convertError} = useErrorMessage();

  useEffect(() => {
    fetchSuppliers();
  }, []);

  useEffect(() => {
    setSupplier(allSuppliers);
  }, [allSuppliers]);


  const handleTrashToggle = () => {
    setShowActive(!showActive);
  }

  // Handler: gestione cambiamenti dei filtri -> per ogni cambiamento ai filtri, handleFilterChange(filters: ListFilter[])
  const handleFilterChange = (filters: ListFilter[]) => {
    let newSuppliers = allSuppliers;

    if (newSuppliers && newSuppliers.length > 0) {
      filters
        .filter((filter: ListFilter) => filter.enabled && filter.value)
        .forEach((filter: ListFilter) => {
          switch (filter.type) {
            case ListFilterType.SEARCH_TEXT:
              newSuppliers = newSuppliers.filter((supplier: Supplier) =>
                supplier.vatId.toUpperCase().indexOf(filter.value.toUpperCase()) !== -1 ||
                supplier.description.toUpperCase().indexOf(filter.value.toUpperCase()) !== -1);
              break;
          }
        });
    }
    let filteredDisabledSuppliers = newSuppliers.filter((supplier) => supplier.state === 'AA');
    let filteredActiveSuppliers = newSuppliers.filter((supplier) => supplier.state === 'EF');
    setSupplier(filteredActiveSuppliers);
    setDisabledSuppliers(filteredDisabledSuppliers);
  }

  const fetchSuppliers = async () => {
    let activeSuppliers: Supplier[] = [];
    let disabled: Supplier[] = [];
    setLoading(true);
    try {
      const suppliers = await getSuppliers();
      if (suppliers) {
        suppliers.filter((supplier: Supplier) => {
          if (supplier.state === 'EF') {
            activeSuppliers.push(supplier);
          } else if (supplier.state === 'AA') {
            disabled.push(supplier);
          }
        })
        setAllSuppliers(activeSuppliers);
        setDisabledSuppliers(disabled);
      }
    } catch (error) {
      convertError(createError(error))
        .then((msg) => setFetchError(msg))
      setTimeout(() => setFetchError(null), 3000);
    }
    setLoading(false);
  }

  const onRestoreSupplierHandler = (id: number) => {
    if (id) {
      restoreSupplier(id)
        .then(() => {
          fetchSuppliers();
        })
    }
  }

  const openItemHandler = (id: number) => {
    navigate(`/suppliers/detail/${id}`);
  }

  const handleNewSupplierClicked = () => {
    navigate('/suppliers/edit/-1');
  }
  // }

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView !== null) {
      setToggleClicked(nextView);
      setShowActive(!showActive);
    }
  }

  return (
    <Box
      width={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      mx={"auto"}
    >

      {fetchError && <Alert
        severity={"error"}
        variant={"filled"}
        sx={{mb: 4}}
        onClose={() => setFetchError(null)}>{fetchError}</Alert>}

      {/* Titolo */}
      <PageTitle
        title={
          <Stack direction={"row"} alignItems={"center"}>
            {
              showActive ?
                (
                  <>
                    <Typography variant={"h3"} mr={1}>{t("title")}</Typography>
                    <Box className={"counter-primary"} m={1}>
                      <Typography variant={"button"}>{supplier ? supplier.length : 0}</Typography>
                    </Box>
                  </>
                ) :
                (
                  <>
                    <Typography variant={"h3"} mr={1}>{t("title")}</Typography>
                    <Typography variant={"h4"} children={`(${t("titleParam")})`} sx={{color: "#14c6d5"}}/>
                    <Box className={"counter-primary"} m={1}>
                      <Typography variant={"button"}>{disabledSuppliers ? disabledSuppliers.length : 0}</Typography>
                    </Box>
                  </>
                )
            }
            <Box flexGrow={1}/>
            <ToggleButtonGroup
              exclusive={true}
              value={toggleClicked}
              size={"small"}
              onChange={handleChange}
              color={"primary"}
            >
              <ToggleButton
                value={"lista"}
                className={"ztsToggle"}
              >
                <TooltipZts
                  title={t('button.showList')}
                  placement={"bottom"}
                  enterDelay={400}
                >
                  <ViewModuleIcon fontSize={"small"}/>
                </TooltipZts>

              </ToggleButton>
              <ToggleButton
                value={"cestino"}
                className={"ztsToggle"}
              >
                <TooltipZts
                  title={t('button.showTrash')}
                  placement={"bottom"}
                  enterDelay={400}
                >
                  <DeleteOutlineIcon fontSize={"small"}/>
                </TooltipZts>

              </ToggleButton>

            </ToggleButtonGroup>
          </Stack>
        }
      />

      <Box
        mt={1}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        columnGap={1}
      >

        {/*Filtri TODO*/}
        <Filters props={{onFilterChange: handleFilterChange, handleTrashToggle: handleTrashToggle}}/>

        <Box flexGrow={1}/>

        {/* Pulsanti: Nuovo Fornitori */}
        <TooltipZts
          title={t('button.newSupplier')}
          placement={"bottom"}
          enterDelay={400}
        >
          <IconButton
            id={"new-supp-button"}
            color="primary"
            className={"edit-button"}
            disabled={!showActive}
            onClick={handleNewSupplierClicked}
          >
            <Add/>
          </IconButton>
        </TooltipZts>

      </Box>

      {/* Lista Fornitori */}
      <Box
        id={"infinite-scroll-target"}
        overflow={"auto"}
        flexGrow={1}
      >
        {
          showActive ?
            (
              <SupplierList
                suppliers={supplier}
                loading={loading}
                onOpen={openItemHandler}
                onRestore={onRestoreSupplierHandler}
                active={true}
              />
            ) : (
              <SupplierList
                suppliers={disabledSuppliers}
                loading={loading}
                onOpen={openItemHandler}
                onRestore={onRestoreSupplierHandler}
                active={false}
              />
            )
        }
      </Box>
    </Box>
  );

}
