import {ReactChild} from "react";
import {useLoggedUser} from "../../../hooks/useLoggedUser";

type ComponentProps = {
  children: ReactChild,
  isAdmin?: boolean,
  isTraveller?: boolean,
  customCondition?: () => boolean
}

export const ProtectedRoute = ({children, isAdmin, isTraveller, customCondition}: ComponentProps) => {

  const {user: loggedUser} = useLoggedUser();

  let enabled = false;

  if (isAdmin) {
    enabled = !!loggedUser && loggedUser.isAdmin;
  } else if (isTraveller) {
    enabled = !!loggedUser && loggedUser.isTraveller;
  } else if (customCondition) {
    enabled = customCondition();
  }

  return (
    <>
      {enabled ? children : <></>}
    </>
  )
}
