import {Grid, Skeleton, Stack, Typography} from "@mui/material";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {formatDate} from "../../../../util/DateUtil";
import {useLoggedUser} from "../../../../hooks/useLoggedUser";
import moment from "moment";
import {useEffect, useState} from "react";
import {DashboardLicense as DashboardLicenseModel} from "../../model";
import {getLicenseData} from "../../Service";
import {Logger} from 'react-logger-lib';
import SimpleJsLog from "../../../../util/Logger";
import {useTranslation} from "react-i18next";
import {getZtsJwtPayload} from "../../../../config/token";
import masterCardLogo from "../../../../assets/images/masterCardLogo.png";
import ztsLogoShort from "../../../../assets/images/new-logo-ztravel-smart-short.png";
import Box from "@mui/material/Box";

const LOG: SimpleJsLog = Logger.of('ZTS.Dashboard.DashboardLicense');

export const DashboardLicense = () => {

  const {t} = useTranslation('dashboard', {keyPrefix: 'admin.license'});

  const {
    userLocale,
    user
  } = useLoggedUser();

  const [data, setData] = useState<DashboardLicenseModel | null>(null);
  const [logo, setLogo] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        setLoading(true);
        const token = getZtsJwtPayload();
        const data = await getLicenseData(token ? token.tenantId : '0');
        setData(data);
        const storeType = data.storeType;
        if (storeType === 'ZS') {
          setLogo(ztsLogoShort);
        } else if (storeType === 'MASTERCARD') {
          setLogo(masterCardLogo);
        }
      } catch (err) {
        setData(null);
        LOG.trace(`Error retrieving license data: ${err}`)
      } finally {
        setLoading(false);
      }
    }
    fetchLicenseData();
  }, []);

  //const expirationDate: Moment | null = data ? moment.utc(data.expirationDate) : null;
  const expirationDate = data?.expirationDate;
  const isExpired = expirationDate && moment(expirationDate).isBefore(new Date(), 'days');

  const getLicenseStatus = (): JSX.Element => {
    let label = t("active");
    let className = "text-success";

    if (isExpired) {
      label = t("expired");
      className = "text-danger";
    }
    return <span className={className}>{label}</span>;
  }

  const getLicenseExpireDateText = (): string => {
    if (!expirationDate) {
      return "";
    }

    let label = "";
    if (isExpired) {
      label = t("expiredOn", {expireDate: formatDate(expirationDate, userLocale)});
    } else {
      switch (data?.type) {
        case "PERSONAL":
          label = t("freeUntil", {expireDate: formatDate(expirationDate, userLocale)});
          break;
        case "PROFESSIONAL":
          label = t('expires', {expireDate: formatDate(expirationDate, userLocale)});
          //label = t("expiresOn", {expireDate: formatDate(expirationDate, userLocale)});
          break;
      }
    }
    return label;
  }

  const handleMyLicenseButton = () => {
    if (data) {
      window.open(data.storeUrl, '_blank');
    }
  }

  return (
    <Stack
      height="100%"
      rowGap={1}
      justifyContent="space-between"
    >
      <Stack direction={'row'} columnGap={2} alignItems={'center'}>
        <WorkspacePremiumIcon fontSize={'large'}/>
        <Typography variant="h3">{t("title")}</Typography>
      </Stack>

      {loading && (
        <>
          <Skeleton variant="rectangular" width="80%" height="20px" sx={{transform: "none", mb: 1}}/>
          <Skeleton variant="rectangular" width="50%" height="20px" sx={{transform: "none", mb: 1}}/>
          <Skeleton variant="rectangular" width="30%" height="20px" sx={{transform: "none", mb: 1}}/>
        </>
      )}

      {!loading && (
        <>
          {data ? (
            <Box display={'flex'}>
              <Grid container gap={1} justifyContent={'space-evenly'} alignItems="center">
                <Grid item xs={2} md={7} lg={2} textAlign="center">
                  {
                    logo &&
                    <img
                      src={logo}
                      className="license-logo"
                      alt={'License Logo'}
                    />
                  }
                </Grid>
                <Grid item xs={4} md={7} lg={4}>
                  <Stack m={1.5}>
                    <Typography textTransform={"capitalize"}>{`${t("type", {type: (data.type).toLowerCase()})}`}</Typography>
                    <Typography>{t("users", {active: data.activeUsers, total: data.maxUsers})}</Typography>
                    <Typography>{`${getLicenseExpireDateText()}`}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4} md={7} lg={4} display="flex" justifyContent="center">
                  <Box sx={{color: 'white', backgroundColor: '#14c6d5', width: 'fit-content', padding: '8px', cursor: 'pointer', borderRadius: '10px'}} onClick={handleMyLicenseButton}>
                    <Typography textAlign={'center'}>{t('myLicenseButton')}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              <Typography variant="body1">{t("error")}</Typography>
              <Typography variant="body1"></Typography>
            </>
          )}
        </>
      )}

    </Stack>
  );
}
