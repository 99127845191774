// React
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

// MUI
import {Alert, Box, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";

// Libs
import {Logger} from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// Custom
import {TravelPolicy} from "../model";
import {PageTitle} from "../../layout/page-title";
import {TravelPolicyFilters} from "./TravelPolicyFilters";
import {TravelPolicyList} from "./TravelPolicyList";
import {MultipleSelection} from "../../base/multiple-selection";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {FilterFunction, FilterWrapper} from "../../filters/model";


// Service
import {disableTravelPolicies, getAllTravelPolicies} from "../Service";
import {identityFactory} from "../../filters/utils";
import {
  NewAccountModalClosedModeType,
  NewTravelPolicyModal,
  NewTravelPolicyModalClosedEvent,
  NewTravelPolicyType
} from "../commons/NewTravelPolicyModal";
import {useHelp} from "../../help/hook";
import {activeStateCode, canceledStateCode, isActiveStatus, StateType, TRAVEL_POLICIES_URL} from "../../model";
import {HelpCurrentPage} from "../../../reducers/Help";
import {TooltipZts} from "../../base/tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {useParams} from "react-router";
import {useCurrentList} from "../../../hooks/useCurrentList";
import {useTranslation} from "react-i18next";

const LOG: Slf4jsLog = Logger.of('ZTS.TravelPolicies.TravelPolicyFilterableList');

export const TravelPolicyFilterableList: React.FC = () => {

  const [newTravelPolicy, setNewTravelPolicy] = useState<NewTravelPolicyType | null>(null);

  // Tutti i valori caricati dal db
  const [allItems, setAllItems] = useState<TravelPolicy[]>([]);

  // Solo i valori mostrati a video
  const [items, setItems] = useState<TravelPolicy[]>([]);

  // Filtri attivi
  const [filterObj, setFilterObj] = useState<FilterWrapper<TravelPolicy>>({fn: identityFactory<TravelPolicy>()});

  const {convertError} = useErrorMessage();

  useHelp({
    paths: [
      {
        path: TRAVEL_POLICIES_URL,
        page: HelpCurrentPage.TRAVEL_POLICIES_LIST
      }
    ]
  });

  const {
    binMode,
    setBinMode
  } = useCurrentList();

  const [loading, setLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);


  // Solo i valori selezionati
  const [selectedItems, setSelectedItems] = useState<TravelPolicy[]>([]);


  const sortTravelPolicyArray = (list: TravelPolicy[]) => {
    return list.sort(
      (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())
    );
  }

  const navigate = useNavigate();

  const onOpen = (item: TravelPolicy) => {
    LOG.trace('onOpen', item);
    let url = `edit/${item.id}`;
    if (!isActiveStatus(item.state)) {
      url = `detail/${item.id}`
    }
    navigate(url);
  }

  const onEdit = (item: TravelPolicy) => {
    LOG.trace('onEdit', item);
    navigate(`edit/${item.id}`);
  }

  const onToggleSelect = (item: TravelPolicy) => {
    LOG.trace('onToggleSelect', item);
    // Devo creare comunque un nuovo array di selezione per react
    const newSelection: TravelPolicy[] = [];
    let exists = false;
    selectedItems.forEach(tp => {
      if (tp.code === item.code) {
        exists = true;
      } else {
        newSelection.push(tp);
      }
    });
    if (!exists ){
      newSelection.push(item);
    }
    setSelectedItems(newSelection);
  }

  const sort = (list: TravelPolicy[]): TravelPolicy[] => {
    LOG.trace('sort', list);
    if (list.length<2) {
      return list;
    }
    return list.sort(
      (a, b) => {
        let i = a.description.toLowerCase().localeCompare(b.description.toLowerCase());
        if (i===0) {
          i = a.code.localeCompare(b.code);
        }
        return i;
      }
    );
  }

  const onDisableSelection = async () => {
    LOG.trace('asyc onDisableSelection ');
    if (selectedItems) {
      try {
        const ids: number[] = selectedItems.map(tp => tp.id);
        await disableTravelPolicies(ids);

        const list = await getAllTravelPolicies();
        const sortedList = sort(list);
        setAllItems(sortedList);
        setSelectedItems([]);
      } catch (error: any) {
        convertError(error).then(msg => setErrorMsg(msg));
      }
    }
  }

  const onFilterChange = (fn: FilterFunction<TravelPolicy>) => {
    LOG.trace('onFilterChange', fn);
    setFilterObj({fn: fn});
  }

  const onNewTravelPolicy = () => {
    LOG.trace('onNewTravelPolicy');
    // navigate('edit/-1')
    // Uso una modale per iserire i campi obbligatori
    // code: '',
    const newObj: NewTravelPolicyType = {
      description: '',
      startDate: new Date(),
      masterTravelPolicyId: null
    }
    setNewTravelPolicy(newObj);
  }

  const onNewTravelPolicyModalClosed = (event: NewTravelPolicyModalClosedEvent) => {
    LOG.trace('onNewTravelPolicyModalClosed', event);
    const action: NewAccountModalClosedModeType = event.action;
    if (action==='created') {
      navigate(`edit/${event.travelPolicyId}`);
    } else {
      setNewTravelPolicy(null);
    }
  }

  // Load
  useEffect(() => {
    LOG.trace('useEffect []');
    setLoading(true);

    const asyncLoadValues = async () => {
      LOG.trace('asyncLoadValues [async]')
      try {
        const list: TravelPolicy[] = await getAllTravelPolicies();
        const sortedList = sortTravelPolicyArray(list);
        setAllItems(sortedList);
      } catch (error) {
        setErrorMsg(t('fetchError'));
      }
      setLoading(false);
    }

    asyncLoadValues()
      .then(()=>LOG.trace('asyncLoadValues end.'));
  }, []);

  // Quando cambia la lista delle travel policy o i filtri o la modalità di visualizzazione
  useEffect( () => {
    LOG.trace('useEffect [allItems, filterObj, stateFilter]', allItems, filterObj, binMode);
    const elementsByState: TravelPolicy[] = allItems.filter(tp => tp.state=== (binMode ? canceledStateCode : activeStateCode));
    const tmp = filterObj.fn(elementsByState);
    setItems(tmp);
  }, [allItems, filterObj, binMode]);

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    setBinMode(!!nextView && nextView === 'cestino');
  }

  const {t} = useTranslation('travel-policy', {keyPrefix: 'list'});

  return (
    <>
      <Box
        width='100%'
        overflow='hidden'
        display='flex'
        flexDirection='column'
        flexGrow={1}
        mx='auto'
      >

        { errorMsg &&
          <Alert
            severity={"error"}
            variant={"filled"}
            sx={{mb: 4}}
            onClose={() => setErrorMsg(null)}
          >{errorMsg}</Alert>
        }

        {/* Titolo */}
        <PageTitle
          title={
            <Stack direction={"row"} alignItems={"center"}>
              <Typography variant={"h3"} mr={3}>{t('title')}</Typography>
              {binMode && <Typography variant={"h4"} sx={{color: "#14c6d5"}}>{t('titleParam')}</Typography> }
              <Box flexGrow={1}/>
              <ToggleButtonGroup
                exclusive={true}
                value={binMode ? t('label.trash') : t('label.list')}
                size={"small"}
                onChange={handleChange}
                color={"primary"}
              >
                <ToggleButton
                  value={"lista"}
                  className={"ztsToggle"}
                >
                  <TooltipZts
                    title={t('label.showList')}
                    placement={"bottom"}
                    enterDelay={400}
                  >
                    <ViewModuleIcon fontSize={"small"}/>
                  </TooltipZts>

                </ToggleButton>
                <ToggleButton
                  value={"cestino"}
                  className={"ztsToggle"}
                >
                  <TooltipZts
                    title={t('label.showTrash')}
                    placement={"bottom"}
                    enterDelay={400}
                  >
                    <DeleteOutlineIcon fontSize={"small"}/>
                  </TooltipZts>

                </ToggleButton>

              </ToggleButtonGroup>
            </Stack>
          }
        />

        <Box
          mt={1}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
        >
          {/* Filtri */}
          <TravelPolicyFilters onFilterChange={onFilterChange} />

          <Box flexGrow={1}/>

          {/* Pulsanti: Nuova TP */}
          <TooltipZts
            title={t('label.newTravelPolicy')}
            placement={"bottom"}
            enterDelay={400}
          >
            <IconButton
              id={"new-user-btn"}
              color="primary"
              className={"edit-button"}
              sx={{
                opacity: !binMode ? 1 : 0.4,
                cursor: !binMode ? 'pointer' : 'default',
                pointerEvents: !binMode ? 'auto' : 'none'
              }}
              onClick={() => onNewTravelPolicy() }
            >
              <Add />
            </IconButton>
          </TooltipZts>

        </Box>

        {/* Lista travel policy */}
        <Box
          id={"infinite-scroll-target"}
          overflow={"auto"}
          flexGrow={1}
        >
          <TravelPolicyList
            items={items}
            selectedItems={selectedItems}
            loading={loading}
            onOpen={onOpen}
            onEdit={onEdit}
            onToggleSelect={onToggleSelect}
          />
        </Box>

      </Box>

      <MultipleSelection
        size={selectedItems.length}
        clearItems={() => setSelectedItems([])}
        actionHandler={onDisableSelection}
        ctaLabel={t('label.disable')}
      />

      {newTravelPolicy &&
        <NewTravelPolicyModal
          newItem={newTravelPolicy}
          masterList={allItems}
          onClose={onNewTravelPolicyModalClosed}
        />
      }

    </>
  );

};
