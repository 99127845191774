// React
import React from "react";
import {Controller} from "react-hook-form";

// MUI
import {FormControlLabel, Grid, Typography, Stack} from "@mui/material";

// Custom
import {IOSSwitch} from "../../base/switch";
import {PremiumIcon} from "../../base/premium-icon";
import {TravelPolicy} from "../model";
import {PopupContentType} from "../../license-popup/model";
import {useTranslation} from "react-i18next";


type TravelPolicyOptionsProps = {
  travelPolicy: TravelPolicy,
  hasController: boolean,
  updatable: boolean
  proLicense: boolean
}
export const TravelPolicyOptions: React.FC<TravelPolicyOptionsProps> = ({
  travelPolicy, hasController, updatable, proLicense
}) => {
  const {t: tCommon} = useTranslation('travel-policy', {keyPrefix: 'common.label'});
  const sectionLab = tCommon('options');
  const enableForeignLbl = tCommon('enableForeign');
  const enableProjectLbl = tCommon('enableProject');

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Typography variant='h6' textTransform='uppercase'>{sectionLab}</Typography>
      </Grid>

      <Grid item xs={12}>
        { hasController
          ? <Controller
              render={ ({field}) =>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      disabled={!updatable}
                      checked = {field.value}
                      onChange={ (e) => field.onChange(e) }
                    />
                  }
                  label={enableForeignLbl}
                  labelPlacement='end'
                />
              }
              name='foreignCurrEnb'
            />
          : <FormControlLabel
              control={
                <IOSSwitch
                  disabled
                  checked = {travelPolicy.foreignCurrEnb}
                />
              }
              label={enableForeignLbl}
              labelPlacement='end'
            />
        }
      </Grid>

      <Grid item xs={12}>
        <Stack direction='row' columnGap={1} alignItems='center'>

          {hasController &&
            <Controller
              render={ ({field}) =>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      disabled={!updatable || (!proLicense && !field.value)}
                      checked = {field.value}
                      onChange={ (e) => field.onChange(e) }
                    />
                  }
                  label={enableProjectLbl}
                  labelPlacement='end'
                  />
              }
              name='projectEnb'
            />
          }

          {!hasController &&
            <FormControlLabel
              control={
                <IOSSwitch
                  disabled
                  checked = {travelPolicy.projectEnb}
                />
              }
              label={enableProjectLbl}
              labelPlacement='end'
            />
          }

          {!proLicense && <PremiumIcon type={PopupContentType.TP_ENABLE_PROJECT_SWITCH} clickable={true}/>}

        </Stack>

      </Grid>

    </Grid>
  );

}
