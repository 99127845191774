import {Collapse, ListItemButton, ListItemText, Menu, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {MenuItem} from "../model";
import Box from "@mui/material/Box";
import {ItemsList} from "./ItemsList";
import {useLocation, useNavigate} from "react-router-dom";
import {Article, ExpandLess, ExpandMore} from "@mui/icons-material";
import {DASHBOARD_PATH} from "../../../model";
import {useLicense} from "../../../../hooks/useLicense";
import {PremiumIcon} from "../../../base/premium-icon";
import {PopupContentType} from "../../../license-popup/model";
import {LicensePopup} from "../../../license-popup";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  id: number,
  label: string,
  icon?: JSX.Element,
  route?: string,
  forceClose?: boolean,
  menuItems?: MenuItem[],
  menuDesktopOpen: boolean,
  premiumPopupType?: PopupContentType
}

export const Inner = ({id, label, icon, forceClose, route, menuItems, menuDesktopOpen, premiumPopupType}: ComponentProps) => {
  const {t: tLicense} = useTranslation('license', {keyPrefix: 'pro.menu'});
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement | HTMLAnchorElement>(null);

  const location = useLocation();

  const navigate = useNavigate();

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [popupTitle, setPopupTitle] = useState<string | null>(null);

  const openPopup = (type: PopupContentType) => {
    setPopupTitle(tLicense(`${type.toString()}.title`));
    setPopupOpen(true);
  }
  const closePopup = () => {
    setPopupTitle(null);
    setPopupOpen(false);
  }

  const navigateRoute = (route: string) => {
    navigate(route);
  }

  useEffect(() => {
    if (forceClose) {
      setMenuOpen(false);
    }
  }, [forceClose]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
    if (route) {
      if (premiumPopupType) {
        openPopup(premiumPopupType);
      } else {
        navigateRoute(route);
      }
    } else {
      if (menuDesktopOpen) {
        setMenuOpen(!menuOpen);
      } else {
        handleContextMenuOpen(e);
      }
    }
  }

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const handleContextMenuOpen = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
    if (menuDesktopOpen) {
      return;
    }

    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };

  let isOuterMenuActive = false;
  if (location.pathname.startsWith(`/${DASHBOARD_PATH}`)) {
    isOuterMenuActive = !!route && route === location.pathname;
  } else {
    let pathToMatch = '';
    //console.log('path', location.pathname);
    const startIndex = location.pathname.indexOf('/');
    let endIndex = location.pathname.indexOf('/', startIndex + 1);
    if (endIndex === -1) {
      pathToMatch = location.pathname.substring(startIndex);
    } else {
      pathToMatch = location.pathname.substring(startIndex, endIndex);
    }
    //console.log('pathToMatch', pathToMatch);

    if ((route && pathToMatch === route) ||
      (menuItems && menuItems.length > 0 && menuItems.some(mi => pathToMatch === mi.route))) {
      isOuterMenuActive = true;
    }
  }

  return (
    <>
      <ListItemButton
        className='outer-menu'
        onClick={(e) => handleClick(e)}
        sx={{pt: 1, display: 'flex', justifyContent: menuDesktopOpen ? 'unset' : 'center'}}
      >

        <Box
          className={isOuterMenuActive && menuDesktopOpen ? 'outer-menu-btn-active' : 'outer-menu-btn'}
        >
          {icon ? icon : <Article fontSize={"small"}/>}
        </Box>

        {
          menuDesktopOpen && <>
            <ListItemText
              sx={{ml: 2.5, flexGrow: 1}}
              primary={<Typography variant="h5">{label}</Typography>}
              className={route && isOuterMenuActive ? "menu-item-active" : ""}
            />

            {menuItems && <>
              {menuOpen ? <ExpandLess fontSize={"small"}/> : <ExpandMore fontSize={"small"}/>}
            </>}
          </>
        }
        {
          premiumPopupType &&
          <PremiumIcon type={premiumPopupType} sx={{cursor: 'pointer'}} />
        }
      </ListItemButton>
      {menuItems && <Collapse in={menuOpen} timeout="auto" unmountOnExit>
        <ItemsList
          free={free}
          menuItems={menuItems}
        />
      </Collapse>}

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          ml: 2
        }}
        open={Boolean(anchorEl)}
        onClose={handleContextMenuClose}
      >

        <ListItemButton
          className='outer-menu'
          onClick={(e) => handleClick(e)}
          sx={{pt: 1, display: 'flex'}}
        >
          <Box
            className={isOuterMenuActive ? "outer-menu-btn-active" : "outer-menu-btn"}
          >
            {icon ? icon : <Article fontSize={"small"}/>}
          </Box>
          <Typography variant="h5" sx={{ml: 2.5}}>{label}</Typography>
        </ListItemButton>

        {menuItems && <ItemsList
          free={free}
          menuItems={menuItems}
          onSelectItem={handleContextMenuClose}
        />}
      </Menu>

      <LicensePopup open={popupOpen} isPro={false} closePopup={closePopup} titlePro={popupTitle ? popupTitle : ''} type={premiumPopupType ? premiumPopupType : PopupContentType.DEFAULT} freeButtonText={true} />
    </>
  );
}
