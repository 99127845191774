import {Grid, Paper, Stack, Typography, useMediaQuery} from "@mui/material";
import "../base.scss";
import Box from "@mui/material/Box";
import {TodoItem} from "../todo-item";
import {DashboardChartTotal} from "../chart-total";
import {DashboardChartCategory} from "../chart-category";
import {DashboardExpNoteList} from "../exp-note-list";
import {DashboardCarousel} from "../carousel";
import {DashboardLicense} from "./license";
import {DashboardOnboarding} from "./onboarding";
import {ALLEXPNOTE_URL} from "../../model";
import {
  ExpNoteState,
  ExpNoteStateFE,
  getExpNotesToArchiveStates,
  getExpNotesToCheckStates,
  getExpNotesToContabStates,
  getExpNotesToPayStates
} from "../../exp_note/model";
import React, {useEffect, useState} from "react";
import {countAdminExpNotes} from "../Service";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {WelcomePopup} from "../../welcome-popup";
import {PromoPopup} from "../../promo-popup";

export const DashboardAdmin = () => {

  const {t} = useTranslation('dashboard', {keyPrefix: 'admin'});

  const [data, setData] = useState<number[]>([]);
  const [loadingTodo, setLoadingTodo] = useState(false);

  const theme = useTheme();
  const isSmartPhone = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setLoadingTodo(true);

    const toCheckStates = getExpNotesToCheckStates();
    const toPayStates = getExpNotesToPayStates();
    const toAccountStates = getExpNotesToContabStates();
    const toArchiveStates = getExpNotesToArchiveStates();

    let states: ExpNoteState[] = [];
    states = states.concat(toCheckStates);
    states = states.concat(toPayStates);
    states = states.concat(toAccountStates);
    states = states.concat(toArchiveStates);

    countAdminExpNotes(states)
      .then(res => {
        let toCheckNotes = 0;
        let toPayNotes = 0;
        let toAccountNotes = 0;
        let toArchiveNotes = 0;
        res.forEach(r => {
          if (toCheckStates.find(s => s === r.state)) {
            toCheckNotes += r.count;
          } else if (toPayStates.find(s => s === r.state)) {
            toPayNotes += r.count;
          } else if (toAccountStates.find(s => s === r.state)) {
            toAccountNotes += r.count;
          } else if (toArchiveStates.find(s => s === r.state)) {
            toArchiveNotes += r.count;
          }
        });
        setData([toCheckNotes, toPayNotes, toAccountNotes, toArchiveNotes]);
      })
      .catch(err => console.log(err))
      .finally(() => setLoadingTodo(false));
  }, []);

  return (
    <Box overflow="auto" height="100vh">
      <Typography variant="h2">{t("title")}</Typography>

      {isSmartPhone &&
        <Paper
          elevation={0}
          className="viewport-alert"
        >
          <Typography variant="h5">{t("viewportWarning")}</Typography>
        </Paper>
      }

      <Grid
        container
        columnSpacing={3.5}
        rowSpacing={2}
        mt={1.5}
        className="dashboard"
      >

        {/*colonna sx*/}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>

            {/*Onboarding*/}
            <DashboardOnboarding/>
            <WelcomePopup/>
            <PromoPopup/>

            {/*Contatori*/}
            <Grid item xs={6} md={6} lg={3}>
              <TodoItem
                label={t("todo.toCheck")}
                count={data[0]}
                className="to-check"
                navigation={{
                  url: ALLEXPNOTE_URL,
                  data: {
                    state: ExpNoteStateFE.DA_CONTROLLARE
                  }
                }}
                loading={loadingTodo}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <TodoItem
                label={t("todo.toPay")}
                count={data[1]}
                className="to-pay"
                navigation={{
                  url: ALLEXPNOTE_URL,
                  data: {
                    state: ExpNoteStateFE.DA_LIQUIDARE
                  }
                }}
                loading={loadingTodo}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <TodoItem
                label={t("todo.toAccount")}
                count={data[2]}
                className="to-account"
                pro={true}
                navigation={{
                  url: ALLEXPNOTE_URL,
                  data: {
                    state: ExpNoteStateFE.DA_CONTABILIZZARE
                  }
                }}
                loading={loadingTodo}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <TodoItem
                label={t("todo.toArchive")}
                count={data[3]}
                className="to-archive"
                loading={loadingTodo}
                navigation={{
                  url: ALLEXPNOTE_URL,
                  data: {
                    state: ExpNoteStateFE.DA_ARCHIVIARE
                  }
                }}
              />
            </Grid>

            {/*Grafici*/}
            <Grid item xs={12}>
              <Stack
                direction="row"
                flexWrap="wrap"
                rowGap={2}
                columnGap={2}
              >
                <Paper
                  elevation={0}
                  className="chart chart-total">
                  <DashboardChartTotal/>
                </Paper>
                <Paper elevation={0} className="chart chart-category">
                  <DashboardChartCategory/>
                </Paper>
              </Stack>
            </Grid>

            {/*Licenza*/}
            <Grid item xs={12}>
              <Paper elevation={0} className="license">
                <DashboardLicense/>
              </Paper>
            </Grid>

          </Grid>
        </Grid>

        {/*colonna dx*/}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>

            {/*Carosello*/}
            <Grid item xs={12}>
              <Paper elevation={0} className="carousel">
                <DashboardCarousel/>
              </Paper>
            </Grid>

            {/*Lista note spese*/}
            <Grid item xs={12}>
              <Paper elevation={0} className="exp-note-list">
                <DashboardExpNoteList/>
              </Paper>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

    </Box>
  );
}
