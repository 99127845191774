import {Route, Routes} from "react-router-dom";
import {SupplierDetail} from "./detail/SupplierDetail";
import {SupplierEdit} from "./edit/SupplierEdit";
import {FilterableList} from "./list/FilterableList";
import {ProtectedRoute} from "../base/protected-routes";
import {useHelp} from "../help/hook";
import {DETAIL_URL, EDIT_URL, NEW_URL, SUPPLIER_URL} from "../model";
import {HelpCurrentPage} from "../../reducers/Help";
import {useCurrentList} from "../../hooks/useCurrentList";
import {useEffect} from "react";
import {PremiumWrapper} from "../base/premium-wrapper";
import {PopupContentType} from "../license-popup/model";

export const Supplier = () => {

  const {clearListFilters} = useCurrentList();

  useEffect(() => {
    return () => clearListFilters()
  }, []);

  useHelp({
    paths: [
      {
        path: `${SUPPLIER_URL}${NEW_URL}`,
        page: HelpCurrentPage.SUPPLIER_NEW,
        exactMatch: true
      },
      {
        path: `${SUPPLIER_URL}${EDIT_URL}`,
        page: HelpCurrentPage.SUPPLIER_EDIT
      },

      {
        path: `${SUPPLIER_URL}${DETAIL_URL}`,
        page: HelpCurrentPage.SUPPLIER_DETAIL
      },
      {
        path: SUPPLIER_URL,
        page: HelpCurrentPage.SUPPLIER_LIST
      }
    ]
  });

  return (
    <PremiumWrapper type={PopupContentType.SUPPLIERS}>
      <ProtectedRoute isAdmin>
        <Routes>
          <Route path="/" element={<FilterableList/>}/>
          <Route path="/detail/:id" element={<SupplierDetail/>}/>
          <Route path="/edit/:id" element={<SupplierEdit/>}/>
        </Routes>
      </ProtectedRoute>
    </PremiumWrapper>
  );
}
