import Box from "@mui/material/Box";
import {Drawer, Stack} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import {ReactChild} from "react";
import UserBox from "../header/user-box";
import {Logo} from "./logo";
import {DRAWER_GUTTER} from "../model";
import {Close, Menu as MenuIcon} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {getHelpMenuItem, HelpMenuItem, setMenuItem} from "../../../reducers/Help";
import {ACCOUNT_URL, HOME_ADMIN_URL, HOME_TRAVELLER_URL, LEVEL_URL, USER_URL} from "../../model";
import {useDispatch, useSelector} from "react-redux";

type ComponentProps = {
  menuItems: ReactChild,
  toggleMenuDesktop: Function,
  drawerWidth: number,
  menuDesktopOpen: boolean,
  menuMobileOpen: boolean,
  toggleMenuMobile: Function,
  isMobile: boolean
}

export const Menu = (props: ComponentProps) => {

  const {
    menuItems,
    toggleMenuDesktop,
    drawerWidth,
    menuDesktopOpen,
    menuMobileOpen,
    toggleMenuMobile,
    isMobile
  } = props;

  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();
  const menuItem = useSelector(getHelpMenuItem);
  const dispatch = useDispatch();

  if (menuItem) {
    switch (menuItem) {
      case HelpMenuItem.HOME_ADMIN: {
        dispatch(setMenuItem(null));
        navigate(HOME_ADMIN_URL);
        break;
      }

      case HelpMenuItem.HOME_TRAVELLER: {
        dispatch(setMenuItem(null));
        navigate(HOME_TRAVELLER_URL);
        break;
      }

      case HelpMenuItem.USER: {
        dispatch(setMenuItem(null));
        navigate(USER_URL);
        break;
      }
      case HelpMenuItem.LEVEL: {
        dispatch(setMenuItem(null));
        navigate(LEVEL_URL);
        break;
      }
      case HelpMenuItem.ACCOUNT: {
        dispatch(setMenuItem(null));
        navigate(ACCOUNT_URL);
        break;
      }

    }
  }

  return (
    <Box
      component="nav"
      className={menuDesktopOpen || isMobile ? "" : "nav-closed"}
      sx={{
        width: {
          md: (drawerWidth + DRAWER_GUTTER)
        },
        flexShrink: {md: 0}
      }}
    >

      {/*Menu Mobile*/}
      <Drawer
        variant="temporary"
        open={menuMobileOpen}
        onClose={() => toggleMenuMobile()}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: {
            xs: 'block',
            md: 'none'
          },
          '& .MuiDrawer-paper': {
            alignItems: 'flex-start',
            boxSizing: 'border-box',
            width: drawerWidth,
            overflow: 'hidden',
            border: 'none'
          },
          '& .MuiListItemText-root': {fontSize: '15px'},
          '& .MuiTypography-root': {fontSize: '15px'}
        }}
      >

        <Toolbar
          className={"logo-container"}
        >
          {/*Logo*/}
          <Logo width={170}/>

          {/*Pulsante per chiudere il menu*/}
          <IconButton
            size="large"
            edge="start"
            onClick={() => toggleMenuMobile()}
            sx={{display: {md: 'none'}}}
          >
            <Close/>
          </IconButton>
        </Toolbar>

        {/*Box utente*/}
        <UserBox
          menuOpened={true}
        />

        {/*Voci di menu*/}
        {menuItems}
      </Drawer>

      {/*Menu Desktop*/}
      <Box
        overflow={"hidden"}
        sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}
      >
        <Drawer
          variant={"permanent"}
          className={menuDesktopOpen ? "app-drawer" : "app-drawer app-drawer-closed"}
          sx={{
            '& .MuiDrawer-paper': {
              alignItems: menuDesktopOpen ? 'flex-start' : 'center',
              boxSizing: 'border-box',
              width: drawerWidth,
              overflow: 'hidden',
              border: 'none'
            },
            '& .MuiListItemText-root': {fontSize: '15px'},
            '& .MuiTypography-root': {fontSize: '15px'}
          }}
          open={true}
        >

          <Toolbar>
            {menuDesktopOpen ? (
              // Pulsante per chiudere il menu e logo
              <Stack
                direction={"row"}
                alignItems={"center"}
                height={"60px"}
                ml={"6px"}
                columnGap={1}
              >
                <IconButton
                  size="large"
                  edge="start"
                  onClick={() => toggleMenuDesktop()}
                  disableRipple={true}
                >
                  <MenuIcon/>
                </IconButton>
                <Box
                  sx={{
                    mt: "15px",
                    ml: "10px"
                  }}
                >
                  <Logo width={170}/>
                </Box>
              </Stack>
            ) : (
              // Pulsante per aprire il menu
              <Box
                height={"60px"}
                ml={"6px"}
                display={"flex"}
                alignItems={"center"}
              >
                <IconButton
                  size="large"
                  edge="start"
                  onClick={() => toggleMenuDesktop()}
                  disableRipple={true}
                >
                  <MenuIcon/>
                </IconButton>
              </Box>
            )}
          </Toolbar>

          {/*Box utente*/}
          <UserBox
            menuOpened={menuDesktopOpen}
          />

          {/*Voci di menu*/}
          {menuItems}
        </Drawer>
      </Box>
    </Box>
  );
}
