import {useTranslation} from "react-i18next";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useState} from "react";
import {CreCardMovActionType} from "../model";
import {Loader} from "../../base/loader";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {TextFieldZts} from "../../base/text-field";

interface ComponentProps {
  id?: number,
  info: { open: boolean, popupType: CreCardMovActionType | null, checked?: boolean, discarded?: boolean, checkedNotes?: string, discardedNotes?: string },
  handleActions: (actionType: CreCardMovActionType | null, notes?: string) => Promise<void>,
  handleClose: () => void
}

export const CreCardMovActionPopup = ({id, info, handleActions, handleClose}: ComponentProps) => {
  const {t} = useTranslation('cre-card-mov', {keyPrefix: 'popup.action'});
  const {convertError} = useErrorMessage();

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [notes, setNotes] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!info.open) {
      setNotes(undefined);
      setErrorMsg(null);
    }
  }, [info]);


  const onActionClicked = async () => {
    if (info) {
      setLoading(true);
      try {
        await handleActions(info.popupType, notes);
        handleClose();
      } catch (err: any) {
        convertError(err)
          .then(msg => {
            setErrorMsg(msg);
          })
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <Dialog
      open={id !== undefined && info.open}
      scroll={"paper"}
      fullWidth={true}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          columnGap={2}
          alignItems={"center"}
          sx={{color: "#000"}}
        >
          <ArrowBack onClick={() => {
            setNotes(undefined);
            handleClose();
          }}/>
          <Typography
            variant={"h3"}
          >
            {
              (info.popupType === CreCardMovActionType.CHECK_MOV || info.popupType === CreCardMovActionType.UNCHECK_MOV) && t('confirmTitle')
            }
            {
              info.popupType === CreCardMovActionType.DISCARD_MOV && t('discardTitle')
            }
            {
              info.popupType === CreCardMovActionType.UNDISCARD_MOV && t('undiscardTitle')
            }
          </Typography>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Loader show={loading}/>
        {
          info.popupType === CreCardMovActionType.CHECK_MOV &&
          <Typography gutterBottom={true}>{t('confirmNoteLabel')}</Typography>
        }
        {
          info.popupType === CreCardMovActionType.DISCARD_MOV &&
          <>
            <Typography mb={1}>{t('discardNoteLabel1')}</Typography>
            <Typography gutterBottom={true}>{t('discardNoteLabel')}</Typography>
          </>
        }
        {
          info.popupType === CreCardMovActionType.UNCHECK_MOV &&
          <>
            <Typography mb={1} fontWeight={500}>{t('unconfirmNoteLabel1')}</Typography>
            <Typography mb={1} display={'inline-flex'}>
              <Typography fontWeight={500}>{t('attention')}</Typography>
              &nbsp;
              {t('unconfirmNoteLabel2')}
            </Typography>
            <Typography gutterBottom={true}>{t('unconfirmNoteLabel3')}</Typography>
          </>
        }
        {
          info.popupType === CreCardMovActionType.UNDISCARD_MOV &&
          <>
            <Typography mb={1} fontWeight={500}>{t('undiscardNoteLabel1')}</Typography>
            <Typography mb={1} display={'inline-flex'}>
              <Typography fontWeight={500}>{t('attention')}</Typography>
              &nbsp;
              {t('undiscardNoteLabel2')}
            </Typography>
            <Typography gutterBottom={true}>{t('undiscardNoteLabel3')}</Typography>
          </>
        }
        {
          (info.popupType === CreCardMovActionType.CHECK_MOV || info.popupType === CreCardMovActionType.DISCARD_MOV) &&
          <TextFieldZts
            value={notes}
            label={t('notePlaceHolder')}
            multiline={true}
            minRows={5}
            required={false}
            onChange={(e) => setNotes(e.currentTarget.value)}
          />
        }
        {
          info.popupType === CreCardMovActionType.UNCHECK_MOV &&
          <TextFieldZts
            value={info.checkedNotes}
            label={t('notePlaceHolder')}
            multiline={true}
            minRows={5}
            required={false}
            disabled={true}
          />
        }
        {
          info.popupType === CreCardMovActionType.UNDISCARD_MOV &&
          <TextFieldZts
            value={info.discardedNotes ? info.discardedNotes : ''}
            label={t('notePlaceHolder')}
            multiline={true}
            minRows={5}
            required={false}
            disabled={true}
          />
        }
        {
          errorMsg &&
          <Alert
            severity={"error"}
            variant={"filled"}
            sx={{my: 2}}
            onClose={() => setErrorMsg(null)}
          >
            {errorMsg}
          </Alert>
        }
      </DialogContent>

      <DialogActions>
        {
          info.popupType === CreCardMovActionType.CHECK_MOV &&
          <>
            <Box display={'flex'} flexGrow={1}/>
            <Typography mr={2}>{t('setAs')}</Typography>
            <Button
              variant="contained"
              className={"info-popup-confirm-button"}
              onClick={onActionClicked}
            >
              <Typography ml={1}
                          variant={"button"}>{info.popupType === CreCardMovActionType.CHECK_MOV ? t('button.controlled') : t('button.discard')}</Typography>
            </Button>
          </>
        }
        {
          info.popupType === CreCardMovActionType.DISCARD_MOV &&
          <>
            <Box display={'flex'} flexGrow={1}/>
            <Button
              variant="contained"
              className={"info-popup-discard-button"}
              sx={{backGroundColor: 'yellow'}}
              onClick={onActionClicked}
            >
              <Typography
                ml={1}
                variant={"button"}
              >
                {t('button.discard')}
              </Typography>
            </Button>
          </>
        }
        {
          info.popupType === CreCardMovActionType.UNDISCARD_MOV &&
          <>
            <Box display={'flex'} flexGrow={1}/>
            <Button
              variant="contained"
              className={"info-popup-undiscard-button"}
              sx={{backGroundColor: 'yellow'}}
              onClick={onActionClicked}
            >
              <Typography
                ml={1}
                variant={"button"}
              >
                {t('button.undiscard')}
              </Typography>
            </Button>
          </>
        }
        {
          info.popupType === CreCardMovActionType.UNCHECK_MOV &&
          <>
            <Box display={'flex'} flexGrow={1}/>
            <Button
              variant="contained"
              className={"info-popup-cancel-button"}
              onClick={onActionClicked}
            >
              <Typography
                ml={1}
                variant={"button"}
              >
                {t('button.uncontrolled')}
              </Typography>
            </Button>
          </>
        }
      </DialogActions>
    </Dialog>
  );
}
