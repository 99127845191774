import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import imgSrc from "../../../../assets/images/upgrade.png";

type ComponentProps = {
  storeUrl: string
}

export const CustomReportsCarouselContent = ({storeUrl}: ComponentProps) => {

  const handleClick = () => {
    window.open(storeUrl, '_blank');
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Box overflow={'hidden'} maxHeight={'250px'}>
          <Typography textAlign={'left'} variant={'h3'} mb={1}>REPORT PERSONALIZZATI</Typography>
          <Box maxHeight={'185px'} overflow={'auto'}>
            <p style={{textAlign: 'left', fontSize: 'small'}}>
              Analisi dettagliata delle tue spese di trasferta, controllo dei massimali, spese per progetto o commessa e
              molto altro. <strong>Scegli la versione Professional di ZTravel Smart per personalizzare i report della
              soluzione</strong> e ottenere in pochi passaggi tutti i dati delle tue note spese!
            </p>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box display={'flex'} height={'100%'} flexDirection={'column'}>
          <Box maxHeight={'250px'}>
            <img src={imgSrc} alt={'upgrade image'} style={{backgroundSize: 'cover'}}/>
          </Box>
          <Box flexGrow={1}/>
          <Box mt={2} sx={{
            color: 'white',
            backgroundColor: '#14c6d5',
            width: 'fit-content',
            padding: '10px 10px',
            cursor: 'pointer'
          }} borderRadius={'10px'} onClick={handleClick}><Typography variant={'body2'}>Scopri di più</Typography></Box>
        </Box>
      </Grid>
    </Grid>
  );
}
