import {Typography} from "@mui/material";
import React from "react";

export const EmptyStateExpNote = ({message}: {message: string}) => {
  return (
    <Typography
      variant="body1"
      fontStyle="italic"
      textAlign="center"
      color="#b5b5b5"
      mt={1}
    >
      {message}
    </Typography>
  )
}
