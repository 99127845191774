import { AutocompleteCurrencyOption, AutocompleteGenericOption } from '../../base/autocomplete/model';
import { useTranslation } from 'react-i18next';

export const MAX_ATTACH_NUM = 3;

export const createAutocompleteCurrencyOption = (id: number, code: string, decimalNum: number): AutocompleteCurrencyOption => {
  return {
    id,
    code: code,
    desc: code,
    icon: null,
    listLabel: code,
    selectedLabel: code,
    decimalNum
  };
}

export const createAutocompleteCreditCardOption = (id: number, cardNum: string): AutocompleteGenericOption => {
  return {
    id,
    code: cardNum,
    desc: cardNum,
    icon: null,
    listLabel: cardNum,
    selectedLabel: cardNum
  };
}

export enum PayMode {
  TRAVELLER = 'T',
  CARD = 'C',
  COMPANY = 'Y'
}

export const getPayModeLabelKey = (mode: PayMode): string => {
  switch (mode) {
    case PayMode.TRAVELLER:
      return 'payMode.traveller';
    case PayMode.CARD:
      return 'payMode.card';
    case PayMode.COMPANY:
      return 'payMode.company';
  }
  return mode;
}

export enum DocumentType {
  INVOICE = 'I',
  RECEIPT = 'R',
  TICKET = 'T',
  NONE = 'N'
}

export const getDocumentTypeLabelKey = (type: DocumentType): string => {
  switch (type) {
    case DocumentType.INVOICE:
      return 'documentType.invoice';
    case DocumentType.RECEIPT:
      return 'documentType.receipt';
    case DocumentType.TICKET:
      return 'documentType.ticket';
    case DocumentType.NONE:
      return 'documentType.none';
  }
  return type;
}

export enum ApprovalState {
  APPROVED = 'A',
  REJECTED = 'R'
}

export const getApprovalStateLabelKey = (type: ApprovalState): string => {
  switch (type) {
    case ApprovalState.APPROVED:
      return 'approvalState.approved';
    case ApprovalState.REJECTED:
      return 'approvalState.rejeceted';
  }
  return type;
}

export interface ExpNoteExpense {
  id: number;
  tpExpenseId: number;
  compDate: Date;
  compStartDate: Date;
  compEndDate: Date;
  quantity: number;
  tarif: number;
  currencyCode: string;
  exchange: number;
  currAmount: number;
  compAmount: number;
  docType: DocumentType;
  docNum: number;
  payMode: PayMode;
  cardId: number;
  supplierId: number;
  invoiceNum: string;
  docDate: Date;
  notes: string;
  locality: string;
  projectId: number;
  origMaximum: number;
  maximum: number;
  maxExcExp: number;
  maxExcAut: number;
  approvalState: ApprovalState;
  debCreStaff: number;
  vatAmount: number;
  vatCost: number;
  cogeCost: number;
  contableAccountId: number;
  insertUser: number;
  insertTime: Date;
  lastUpdUser: number;
  lastUpdTime: Date;
}

export interface ExpNoteExpenseFull extends ExpNoteExpense {
  expenseIcon: string,
  expenseDesc: string,
  expenseId: number,
  compPeriod: boolean,
  compPeriodStart: boolean,
  compPeriodEnd: boolean,
  travelPolicyId: number,
  travelPolicyDesc: string,
  cardNum: string,
  projectCode: string,
  projectDesc: string,
  attachments?: ExpNoteAttach[],
  colleagues?: ExpNoteExpenseColleague[],
  guests?: ExpNoteExpenseGuest[],
  routes?: ExpNoteExpenseRoute[]
}

export interface ExpNoteAttach {
  id: number,
  attachRealName: string
}

export interface ExpNoteExpenseColleague {
  id: number,
  staffId: number,
  firstName: string,
  lastName: string
}

export interface ExpNoteExpenseGuest {
  id: number,
  guestName: string
}

export interface ExpNoteExpenseRoute {
  id: number,
  stepNum: number,
  startAddress: string,
  startAddressPlaceId: string,
  endAddress: string,
  endAddressPlaceId: string,
  distance: number,
  expectedDistance: number,
  notes: string
}

export interface StaffTravelPolicyExpItem4Validation {
  id: number | null;
  code: string;
  desc: string;
  icon: string | null;
  listLabel: string;
  selectedLabel: string;
  compPeriod: boolean;
  attachRequired: boolean;
  localityRequired: boolean;
  notesRequired: boolean;
  projectRequired: boolean;
  routes: boolean;
  type: ExpenseItemType;
}

export enum ExpenseItemType {
  PIE_DI_LISTA = 'PL',
  TARIFFA = 'TR',
  IMPORTO_FISSO = 'IF'
}

export interface DataUpdatedByOcr {
  dateUpd: boolean;
  amountUpd: boolean;
  currencyUpd: boolean;
}

export const CompanyCurrencyAutocompleteOption = (currencyCode, decimalNum) => createAutocompleteCurrencyOption(1, currencyCode, decimalNum);

export interface ExpenseAttachmentViewer {
  file: Blob;
  uploadKey?: string;
  id?: number;
  active?: boolean;
}

export const useCommonLabel = (): { getLabel: (key: string) => string } => {
  const {t} = useTranslation('common');

  const getLabel = (key: string): string => {
    return t(key);
  }

  return {getLabel};
}
