import {Box, ListItemButton, Menu, Typography} from "@mui/material";
import {CreCardMovState} from "../../../../../credit-card-mov/model";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ExpNoteCreCardMov} from "../../../../model";
import {CreCardMovInfoPopup} from "../../../../../credit-card-mov/popup/CreCardMovInfoPopup";
import {isReadonlyExpNote} from "../../../model";
import {useExpNoteDetail} from "../../../hooks/useExpNoteDetail";
import {useExpNoteList} from "../../../../hooks/useExpNoteList";
import {Currency} from "../../../../../model";

type ComponentProps = {
  movement?: ExpNoteCreCardMov,
  menuAnchor: HTMLElement | null,
  handleClose: () => void,
  handleInclude: () => void,
  handleExclude: () => void,
  handleDisconnect: () => void,
  handleSelectToLink: (id: number) => void,
  currencies: Currency[]
}

export const ExpNoteCreCardListMenu = ({
                                         movement,
                                         menuAnchor,
                                         handleClose,
                                         handleInclude,
                                         handleExclude,
                                         handleDisconnect,
                                         handleSelectToLink,
                                         currencies
                                       }: ComponentProps) => {

  const {t} = useTranslation("exp-note", {keyPrefix: "creCardMovs.list"});

  const {
    expNote
  } = useExpNoteDetail();

  const {
    currentRole
  } = useExpNoteList();

  const isReadonly = isReadonlyExpNote(expNote, currentRole);

  const [infoMovId, setInfoMovId] = useState<number | null>(null);

  return (
    <>
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={menuAnchor != null}
        onClose={handleClose}
      >
        <Box
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
        >
          {!isReadonly && movement && movement.creCardMovState === CreCardMovState.DISCONNECTED &&
            <ListItemButton onClick={() => {
            }}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                columnGap={1}
                alignItems={'center'}
                onClick={() => handleSelectToLink(movement.movId)}
              >
                <LinkIcon fontSize={'small'} className="text-success"/>
                <Typography>{t('menu.connect')}</Typography>
              </Box>
            </ListItemButton>
          }
          {!isReadonly && movement && movement.creCardMovState === CreCardMovState.CONNECTED &&
            <ListItemButton onClick={() => {
            }}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                columnGap={1}
                alignItems={'center'}
                onClick={handleDisconnect}
              >
                <LinkOffIcon fontSize={'small'} className="text-danger"/>
                <Typography>{t('menu.disconnect')}</Typography>
              </Box>
            </ListItemButton>
          }
          {!isReadonly && movement &&
            (movement.creCardMovState === CreCardMovState.DISCONNECTED ||
              movement.creCardMovState === CreCardMovState.EXCLUDED) &&
            <ListItemButton onClick={() => {
            }}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                columnGap={1}
                alignItems={'center'}
                onClick={movement.creCardMovState === CreCardMovState.EXCLUDED ? handleInclude : handleExclude}
              >
                <PlaylistRemoveIcon fontSize={'small'} className="text-warning"/>
                <Typography>{t('menu.exclude')}</Typography>
              </Box>
            </ListItemButton>
          }
          {
            <ListItemButton onClick={() => {
            }}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                columnGap={1}
                alignItems={'center'}
                onClick={() => setInfoMovId(movement?.movId || null)}
              >
                <InfoOutlinedIcon fontSize={'small'} sx={{color: 'gray'}}/>
                <Typography>{t('menu.info')}</Typography>
              </Box>
            </ListItemButton>
          }
        </Box>
      </Menu>

      <CreCardMovInfoPopup
        id={infoMovId || 0}
        open={infoMovId != null}
        handleClose={() => setInfoMovId(null)}
        currencies={currencies}
      />

    </>
  );
}
