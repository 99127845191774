import {ZtsTable} from "../../../../../base/table";
import {EmptyStateExpNote} from "../../../../empty-state";
import {Button, IconButton, Stack, Typography} from "@mui/material";
import {CompleteCreCardMovDto, CreCardMovActionType, CreCardMovState} from "../../../../../credit-card-mov/model";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import React, {useState} from "react";
import {ZtsTableColumn} from "../../../../../base/table/model";
import {ExpNoteCreCardMov} from "../../../../model";
import {useTranslation} from "react-i18next";
import {GreyColor} from "../../../../admin/list/model";
import {formatDate} from "../../../../../../util/DateUtil";
import {formatNumber} from "../../../../../../util/NumberUtil";
import {ExpenseImg} from "../../../../../expenses/commons/ExpenseImg";
import {AddLink, Undo} from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {discardCreCardMov, disconnectCreCardMov} from "../../../../../credit-card-mov/Service";
import {Currency} from "../../../../../model";
import {useLoggedUser} from "../../../../../../hooks/useLoggedUser";
import {useExpNoteDetail} from "../../../hooks/useExpNoteDetail";
import {ExpNoteCreCardListMenu} from "./ExpNoteCreCardListMenu";
import {LinkDialog} from "../LinkDialog";
import {CreCardMovActionPopup} from "../../../../../credit-card-mov/popup/CreCardMovActionPopup";
import {createError, useErrorMessage} from "../../../../../../util/ErrorUtil";
import {useConfirm} from "../../../../../../hooks/useConfirm";
import {useSnackbar} from "notistack";
import {isReadonlyExpNote} from "../../../model";
import {useExpNoteList} from "../../../../hooks/useExpNoteList";

enum Columns {
  MOV_ID = 'movId',
  STATE = 'creCardMovState',
  CARD_NUM = 'creCardNum',
  DESCRIPTION = 'description',
  PAY_DATE = 'payDate',
  CURR_AMOUNT = 'currAmount',
  CURRENCY = 'currency',
  LINK = 'link'
}

type ComponentProps = {
  rows?: ExpNoteCreCardMov[],
  currencies: Currency[],
  setLoading: (boolean) => void
}

export const ExpNoteCreditCardList = ({
                                        rows,
                                        currencies,
                                        setLoading
                                      }: ComponentProps) => {

  const {t} = useTranslation("exp-note", {keyPrefix: "creCardMovs.list"});

  const {
    userLocale: locale
  } = useLoggedUser();

  const {
    confirm
  } = useConfirm();

  const {convertError} = useErrorMessage();
  const {enqueueSnackbar} = useSnackbar();

  const {
    expNote,
    expenses,
    creditCardMovs,
    updateCreditCardMovs
  } = useExpNoteDetail();

  const {
    currentRole
  } = useExpNoteList();

  const isReadonly = isReadonlyExpNote(expNote, currentRole);

  const columns: ZtsTableColumn[] = [];

  columns.push(
    {
      id: Columns.MOV_ID,
      style: {display: 'none'},
      label: '',
      formatter: () => '',
    },
    {
      id: Columns.STATE,
      label: t("state"),
      style: {width: '65px', maxWidth: '65px'},
      align: 'center',
      formatter: (state: CreCardMovState) => {
        if (state === CreCardMovState.CONNECTED) {
          return <LinkIcon className="text-success"/>;
        } else if (state === CreCardMovState.EXCLUDED) {
          return <PlaylistRemoveIcon className="text-warning"/>;
        } else if (state === CreCardMovState.DISCONNECTED) {
          return <LinkOffIcon className="text-danger"/>;
        }
        return null;
      }
    },
    {
      id: Columns.CARD_NUM,
      label: t("cardNumber"),
      style: {maxWidth: '45px'},
      cellColor: GreyColor,
      formatter: (num) => <Typography color={GreyColor} textOverflow="ellipsis" overflow="hidden" variant="body1">{num}</Typography>
    },
    {
      id: Columns.DESCRIPTION,
      label: t("description"),
      style: {maxWidth: '90px'},
    },
    {
      id: Columns.PAY_DATE,
      label: t("date"),
      style: {width: '100px', maxWidth: '100px'},
      cellColor: GreyColor,
      typography: 'caption',
      formatter: (value) => formatDate(value, locale)
    },
    {
      id: Columns.CURR_AMOUNT,
      label: t("amount"),
      style: {width: '100px', maxWidth: '100px'},
      align: 'right',
      formatter: (amount, r: ExpNoteCreCardMov) => {
        const decimalNum = currencies.find(c => c.code === r.currency)?.decimalNum || 0;
        return formatNumber(r.currAmount, locale, decimalNum);
      }
    },
    {
      id: Columns.CURRENCY,
      label: t("currency"),
      style: {width: '70px', maxWidth: '100px'},
      cellColor: GreyColor,
      typography: 'caption',
    },
    {
      id: Columns.LINK,
      label: t("linkedExpense"),
      style: {width: '170px', maxWidth: '170px'},
      unsortable: true,
      formatter: (v, row: ExpNoteCreCardMov) => {
        if (row.creCardMovState === CreCardMovState.CONNECTED) {
          const docExpenses = expenses?.filter(e => e.docNum === row.docNum);
          if (docExpenses && docExpenses.length > 0) {
            const expense = docExpenses[0];
            return (
              <Stack
                direction="row"
                columnGap={1}
              >
                <ExpenseImg img={expense.expenseIcon} label=""/>
                <Typography variant="body1">{expense.expenseDesc}</Typography>
              </Stack>
            );
          }
        } else if (!isReadonly && row.creCardMovState === CreCardMovState.EXCLUDED) {
          return (
            <Button
              variant="contained"
              className={"cta-button-cre-card-mov"}
              onClick={() => handleIncludeMovFromMenu(row)}
            >
              <Undo/>
              <Typography ml={1} variant={"button"}>{t("restore")}</Typography>
            </Button>
          );
        } else if (!isReadonly && row.creCardMovState === CreCardMovState.DISCONNECTED) {
          return (
            <Button
              variant="contained"
              className={"cta-button-cre-card-mov"}
              onClick={() => handleSelectMovToLink(row.movId)}
            >
              <AddLink/>
              <Typography ml={1} variant={"button"}>{t("linkExpense")}</Typography>
            </Button>
          );
        }
        return null;
      }
    },
    {
      id: 'moreVert',
      label: '',
      unsortable: true,
      formatter: (id, value: ExpNoteCreCardMov) =>
        <IconButton
          id={`menu_${value.movId}`}
          onClick={(e) => handleMenuClick(e)}>
          <MoreHorizIcon/>
        </IconButton>
    }
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [selectedMov, setSelectedMov] = useState<ExpNoteCreCardMov | null>(null);

  const [excludeMovId, setExcludeMovId] = useState<number | null>(null);

  const [excludePopupInfo, setExcludePopupInfo] = useState<{
    open: boolean,
    popupType: CreCardMovActionType | null,
    discarded?: boolean
  }>({
    open: false,
    popupType: null
  });

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMovToLink = (id: number) => {
    const creCardMov = creditCardMovs?.find(c => c.movId === id);
    if (creCardMov) {
      setSelectedMov(creCardMov);
    }
  }

  const handleExcludeMov = () => {
    if (!currentMovForMenu || !creditCardMovs) {
      return;
    }

    setExcludeMovId(currentMovForMenu.movId);
    setExcludePopupInfo({
      open: true,
      popupType: CreCardMovActionType.DISCARD_MOV,
      discarded: false
    });
  }

  const handleIncludeMov = () => {
    if (!currentMovForMenu) {
      return;
    }

    setExcludeMovId(currentMovForMenu.movId);
    setExcludePopupInfo({
      open: true,
      popupType: CreCardMovActionType.UNDISCARD_MOV,
      discarded: true
    });
  }

  const handleIncludeMovFromMenu = (mov: ExpNoteCreCardMov) => {
    setExcludeMovId(mov.movId);
    setExcludePopupInfo({
      open: true,
      popupType: CreCardMovActionType.UNDISCARD_MOV,
      discarded: true
    });
  }

  const closeExcludePopup = () => {
    setExcludePopupInfo({open: false, popupType: null});
    setExcludeMovId(null);
  }

  const handleExcludePopupActions = async (actionType: CreCardMovActionType | null, notes?: string): Promise<void> => {
    if (excludeMovId && actionType != null) {
      let resp;
      switch (actionType) {
        case CreCardMovActionType.DISCARD_MOV:
          resp = await discardCreCardMov(excludeMovId, true, notes);
          if (resp) {
            updateList(resp);
          }
          return;
        case CreCardMovActionType.UNDISCARD_MOV:
          resp = await discardCreCardMov(excludeMovId, false, notes);
          if (resp) {
            updateList(resp);
          }
          return;
        default:
          throw new Error(`actionType ${actionType} not managed`);
      }
    }
  }

  const onCreCardMovLinkedToDoc = (movToUpdate: ExpNoteCreCardMov) => {
    updateListCommon(movToUpdate);
    setSelectedMov(null);
  }

  const updateList = (creCardMov: CompleteCreCardMovDto) => {
    const updatedMov: ExpNoteCreCardMov = {
      movId: creCardMov.creCardId,
      description: creCardMov.creCardDescription,
      payDate: creCardMov.payDate,
      currAmount: creCardMov.currAmount,
      currency: creCardMov.currency,
      compAmount: creCardMov.compAmount,
      creCardMovState: creCardMov.creCardMovState as CreCardMovState,
      docNum: creCardMov.docNum,
      discarded: creCardMov.discarded || false,
      creCardNum: creCardMov.creCardNum,
    };
    updateListCommon(updatedMov);
  }

  const updateListCommon = (creCardMov: ExpNoteCreCardMov) => {
    if (creditCardMovs) {
      const newCreditCardMovs = creditCardMovs.map(mov => mov.movId === creCardMov.movId ? creCardMov : mov);
      updateCreditCardMovs(newCreditCardMovs);
    }
  }

  const handleDisconnectMov = () => {
    if (!currentMovForMenu || !creditCardMovs) {
      return;
    }

    confirm({
      body: (
        <Typography variant="body1" display="inline" mr={0.5}>
          {t('menu.confirmDisconnect')}
        </Typography>
      ),
      onConfirm: async () => {
        setLoading(true);
        try {
          const resp = await disconnectCreCardMov(currentMovForMenu.movId);
          updateList(resp);
        } catch (err: any) {
          convertError(createError(err))
            .then(msg => {
              enqueueSnackbar(msg,
                {
                  variant: "error",
                  autoHideDuration: 2000,
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "top"
                  }
                });
            })
        } finally {
          setLoading(false);
        }
      }
    });
  }

  const currentMovForMenu: ExpNoteCreCardMov | undefined = anchorEl ? creditCardMovs?.find(mov => mov.movId === +anchorEl.id.substring('menu_'.length)) : undefined;

  return (
    <>
      <ZtsTable
        columns={columns}
        rows={rows || []}
      />
      {
        (!rows || rows.length === 0) &&
        <EmptyStateExpNote
          message={t("emptyList")}
        />
      }

      <LinkDialog
        selectedMov={selectedMov}
        closeHandler={(mov) => onCreCardMovLinkedToDoc(mov)}
        currencies={currencies}
      />

      <CreCardMovActionPopup
        id={excludeMovId || 0}
        info={excludePopupInfo}
        handleActions={handleExcludePopupActions}
        handleClose={closeExcludePopup}
      />

      <ExpNoteCreCardListMenu
        menuAnchor={anchorEl}
        handleClose={handleMenuClose}
        movement={currentMovForMenu}
        handleInclude={handleIncludeMov}
        handleExclude={handleExcludeMov}
        handleDisconnect={handleDisconnectMov}
        handleSelectToLink={(id) => handleSelectMovToLink(id)}
        currencies={currencies}
      />
    </>
  );
}
