import {Button, Container, Grid} from "@mui/material";
import {Step1} from "./Step1";
import {Step2} from "./Step2";
import Box from "@mui/material/Box";
import {useFormContext} from "react-hook-form";
import React from "react";
import {FormValues} from "./model";

export const DemoForm = () => {

  const showLog = true;

  // const formMethods = useForm<FormValues>({
  //   defaultValues: {
  //     firstName: '',
  //     lastName: '',
  //     email: '',
  //     address: '',
  //     city: '',
  //     job: null,
  //     languages: null,
  //     assumptionDate: null
  //   },
  //   resolver: yupResolver(schema)
  // });

  const formMethods = useFormContext<FormValues>();

  return (
    // <FormProvider {...formMethods}>
    <form noValidate>

      <Container maxWidth={"md"}>

        <Grid container spacing={2}>

          <Step1/>
          <Step2/>

        </Grid>

        <Box my={4}/>

        <Button
          onClick={formMethods.handleSubmit((values) => console.log(values))}
          variant={"contained"}
        >Invia</Button>

        <Box my={4}/>

        <hr/>

        {showLog && <>
        firstName: {formMethods.watch("firstName")}<br/>
        lastName: {formMethods.watch("lastName")}<br/>
          gender: {formMethods.watch("gender")}<br/>
        email: {formMethods.watch("email")}<br/>
        address: {formMethods.watch("address")}<br/>
        city: {formMethods.watch("city")}<br/>
        job: {formMethods.watch("job")?.desc}<br/>
        assumptionDate: {formMethods.watch("assumptionDate")?.toString()}<br/>
        languages: {formMethods.watch("languages")?.map(lng => `${lng?.language?.desc} - ${lng?.rating}`).join("   ")}<br/>
        </>}

      </Container>
    </form>
    // </FormProvider>
  );
}
