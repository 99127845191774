export enum BatchJobState {
  COMPLETED_SUCCESFULLY = 'CS',
  COMPLETED_WITH_ERRORS = 'CE',
  COMPLETED_WITH_WARNINGS = 'CW',
  WAITING_FOR_EXECUTION = 'WE',
  RUNNING = 'EF'
}

export interface ExpNotePrintRequest {
  expNoteId: number,
  locale: string,
  zipWithAttach?: boolean
}

export interface ExpNoteExportRequest {
  expNoteId: number,
  locale: string,
}

export interface LinkCreCardMovRequest {
  locale: string,
  year: number,
  month: number
}

export interface BatchJobInfos {
  id: number,
  tenantId: number,
  userId: number,
  batchName: string,
  batchTitleBundleKey: string,
  batchTitleBundleParams?: string[],
  batchSubtitleBundleKey: string,
  batchSubtitleBundleParams?: string[],
  batchProgression: number,
  filePath: string,
  fileUniqueName: string,
  fileDownloadName: string,
  batchState: BatchJobState,
  infoMessageBundleKey: string,
  infoMessageBundleParams?: string[],
  elementCode?: string,
  elementsNum: number,
  insertTime: Date,
  lastUpdTime: Date
}
