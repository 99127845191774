import {useEffect, useState} from "react";
import {convertBlobToBase64} from "../../../util/FileUtil";

type AvatarViewerProps = {
  avatar: Blob | string | null,
  onError?: (error: any) => void
}

export const AvatarViewer = ({avatar, onError}: AvatarViewerProps) => {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  const asyncConvertAndSetAvatar= async (blob: Blob): Promise<void> => {
    try {
      const res = await convertBlobToBase64(blob);
      setAvatarUrl(res.replace('data:application/octet-stream', 'data:image/jpg'));
    } catch (err) {
      setAvatarUrl(null);
      if (onError) {
        onError(err);
      }
    }
  }

  useEffect(() => {
    if (avatar && typeof avatar !== 'string') {
      setAvatarUrl(null);
      asyncConvertAndSetAvatar(avatar).then();
    } else if (typeof avatar === 'string') {
      setAvatarUrl(avatar);
    }
  }, [avatar]);

  return (
    <>
      {
        avatarUrl && <div style={{
          width: '100%',
          height: '100%',
          backgroundPosition: "center center",
          backgroundImage: `url(${avatarUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}/>
      }
    </>
  );
}
