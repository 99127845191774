import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import imgSrc from "../../../../assets/images/upgrade.png";

type ComponentProps = {
  storeUrl: string
}

export const ExpNoteAccountingCarouselContent = ({storeUrl}: ComponentProps) => {
  const handleClick = () => {
    window.open(storeUrl, '_blank');
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Box overflow={'hidden'} maxHeight={'250px'}>
          <Typography textAlign={'left'} variant={'h3'} mb={1}>CONTABILIZZAZIONE NOTE SPESE</Typography>
          <Box maxHeight={'185px'} overflow={'auto'}>
            <p style={{textAlign: 'left', fontSize: 'small'}}>
              <strong>ZTravel Smart ti permette di velocizzare la registrazione in contabilità delle note
                spese. </strong>
              <br/>
              Scegli la versione Professional e aggiungi i tuoi conti contabili, i centri di costo e le commesse alla
              soluzione, <strong>esporta un file Excel completo di tutti i dati di contabilità generale e
              analitica</strong> già pronto per un import nel tuo software di contabilità!
            </p>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box display={'flex'} height={'100%'} flexDirection={'column'}>
          <Box maxHeight={'250px'}>
            <img src={imgSrc} alt={'upgrade image'} style={{backgroundSize: 'cover'}}/>
          </Box>
          <Box flexGrow={1}/>
          <Box mt={2} sx={{
            color: 'white',
            backgroundColor: '#14c6d5',
            width: 'fit-content',
            padding: '10px 10px',
            cursor: 'pointer'
          }} borderRadius={'10px'} onClick={handleClick}><Typography variant={'body2'}>Scopri di più</Typography></Box>
        </Box>
      </Grid>
    </Grid>
  );
}
