import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {ExpNoteReports, ReportType} from "../model";
import {getAllReports} from "../Service";
import {ExpenseReportCard} from "../component/ExpenseReportCard";
import {ExpNoteReportCard} from "../component/ExpNoteReportCard";

export function ReportExpNotes() {
  const [expNoteReports, setExpNoteReports] = useState<ExpNoteReports[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const {t} = useTranslation('report');

  const asyncGetAndSetExpNoteReport = async (): Promise<void> => {
    try {
      const allReports = await getAllReports();
      const expNoteReports = allReports.filter((report) => {
        if (report.repType === ReportType.NS) {
          return report;
        }
      });
      // @ts-ignore
      setExpNoteReports(expNoteReports);
    } catch (err: any) {
      setErrorMsg(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    asyncGetAndSetExpNoteReport().then();
    setLoading(false);
  }, []);

  return (
    <Grid container spacing={2}>
      {
        !loading && expNoteReports &&
        expNoteReports.map((report, index) => {
          return (
            <ExpNoteReportCard key={index} cardLabel={report.name} desc={report.description} reportId={report.id} isPro={report.pro}/>
          );
        })

      }
    </Grid>
  );
}
