import {Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import imgSrc1 from "../../../../assets/images/welcomeContent3Img1.png";
import imgSrc2 from "../../../../assets/images/welcomeContent3Img2.png";

export const WelcomeContent3 = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} p={2}>
      <Stack flexDirection={'row'}>
        <Typography variant={'h3'} textAlign={'left'} mb={3}>La tua nota spese</Typography>
        <Box flexGrow={1} />
        <Typography variant={'h5'} fontWeight={500}>03/04</Typography>
      </Stack>
      <Box display={'flex'} width={'100%'} maxHeight={'50vh'} overflow={'hidden'} columnGap={2}>
        <Box display={'flex'} flexGrow={1} maxWidth={'30%'} height={'100%'} flexDirection={'column'} rowGap={1} mt={1}>
          <img src={imgSrc1} alt={'welcomeContent3 Img1'} style={{backgroundSize: 'cover'}}/>
          <img src={imgSrc2} alt={'welcomeContent3 Img2'} style={{backgroundSize: 'cover'}}/>
        </Box>
        <Box display={'flex'} flexDirection={'column'} overflow={'auto'} alignItems={'start'} maxWidth={'70%'}>
          <p style={{textAlign: 'left', fontWeight: 500, fontSize: 20}}>
            <strong>Per inserire nuove note spese utilizza l'App Mobile</strong>
          </p>
          <p style={{textAlign: 'left', marginTop: 0, marginBottom: 0}}>
            Il modo più facile e veloce per compilare la tua nota spese è <strong style={{color: '#3c3c3c'}}>utilizzare l'App Mobile ZTravel Smart disponibile sugli store</strong>
            del tuo dispositivo.
            Scaricala sul tuo smartphone o tablet e inizia a registrare le tue spese fotografando gli scontrini.
          </p>
          <p style={{textAlign: 'left', fontWeight: 500, fontSize: 20}}>
            <strong>Nel menù viaggiatore</strong>
          </p>
          <p style={{textAlign: 'left', marginTop: 0, marginBottom: 0}}>
            Anche dal portale desktop puoi compilare e gestire le tue note spese tramite il menù viaggiatore.
          </p>
        </Box>
      </Box>
    </Box>
  );
}
