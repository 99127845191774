import {Dialog, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import proImageGift from "../../assets/images/go_to_pro_gift.png";
import emojiStar from "../../assets/images/emoji_star.png";
import {useLoggedUser} from "../../hooks/useLoggedUser";
import {isProfessionalLicense} from "../../reducers/License";
import {useSelector} from "react-redux";
import {isWelcomeDismissed} from "../dashboard/model";


// COSTANTI DA MODIFICARE AL VARIARE DELLA PROMOZIONE
const PROMO_VIEWS_COUNTER_STORAGE_KEY = 'dashboard.admin.christmasViewsCounter'; // Chiave della promozione attuale
const DEFAULT_PROMO_VIEWS_COUNTER_VALUE = '1'; // Valore di default
export const CURRENT_PROMO_LINK = 'https://www.zucchetti.it/store/cms/software-nota-spese-ztravel-smart-acquista.html?utm_source=zts_app&utm_medium=banner&utm_campaign=store_promo_off20';
export const CURRENT_PROMO_EXPIRATION_DATE = new Date(2023, 11, 31, 23, 59, 59);

const getPromoViewsCounterNumber = (): number | null => {
  const currentStoredValue = localStorage.getItem(PROMO_VIEWS_COUNTER_STORAGE_KEY);
  if (currentStoredValue) { // Se esiste un valore (currentStoredValue !== null)
    const intCurrentStoredValue = parseInt(currentStoredValue); // Parso la stringa ottenuta (mi aspetto che sia un intero)
    if (!Number.isNaN(intCurrentStoredValue)) { // Se la stringa conteneva un number ( is not NaN )
      return intCurrentStoredValue;
    }
  }
  return null;
}

const setDefaultPromoViewsCounter = () => { // Metodo utile a settare
  localStorage.setItem(PROMO_VIEWS_COUNTER_STORAGE_KEY, DEFAULT_PROMO_VIEWS_COUNTER_VALUE);
}

const incrementPromoViewsCounter = (): number | null => {
  const currentStoredValueNumber = getPromoViewsCounterNumber(); // Prendo il valore presente nello storage (string | null)
  if (currentStoredValueNumber) { // Se esiste un valore (currentStoredValue !== null)
    const updatedValue = currentStoredValueNumber + 1;
    localStorage.setItem(PROMO_VIEWS_COUNTER_STORAGE_KEY, updatedValue.toString());
    return updatedValue;
  }
  return null;
}

export const PromoPopup = () => {
  const {user} = useLoggedUser();
  const isPro = useSelector(isProfessionalLicense);

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(isPopupToShow());
  }, [user]);

  const isPopupToShow = (): boolean => {
    if (user && !isPro && isWelcomeDismissed(user.tenant)) { // Condizione 1: utente non pro al quale non viene mostrato il WelcomePopup
      const promoNotExpired = new Date(Date.now()).getTime() <= CURRENT_PROMO_EXPIRATION_DATE.getTime(); // Se la data corrente precede la data di scadenza della promozione
      const updatedCounter = incrementPromoViewsCounter(); // Incrementa il counter e restituisce il valore aggiornato
      if (updatedCounter === null) { // Se non esiste alcun counter: setto il valore di default (ovvero 1)
        setDefaultPromoViewsCounter();
        return true;
      } else if (updatedCounter <= 5 && updatedCounter % 2 !== 0 && promoNotExpired) { // Se counter era già valorizzato -> Condizione 2: counter <= 5 con counter dispari e la promozione non è scaduta
        return true;
      }
    }
    return false;
  }

  const closePopup = () => {
    setShow(false);
  }

  const handleClick = () => {
    window.open(CURRENT_PROMO_LINK, '_blank');
    closePopup();
  }

  return (
    <Dialog className={'license-popup'} open={show} fullWidth maxWidth={'md'} onClose={closePopup}>
      <Grid container overflow={'hidden'}>
        <Grid item xs={9} p={2}>
          <Box display={'flex'} flexDirection={'column'} m={2} alignItems={'center'} rowGap={'48px'}>
            <Typography
              variant={'h3'}
              sx={{
                fontWeight: 600,
                fontSize: '30px'
              }}
            >
              Ottieni il massimo dal tuo ZTravel Smart!
            </Typography>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Typography
                variant={'h3'}
                sx={{
                  display: 'flex',
                  fontWeight: 600,
                  fontSize: '25px',
                  alignItems: 'center',
                }}
              >
                Fino al 31/12/2023 Sconto 20%
                <img
                  src={emojiStar}
                  alt='Start Emoji'
                  style={{
                    width: '40px',
                    height: '40px'
                  }}
                />
              </Typography>
              <Typography
                variant={'h3'}
                sx={{
                  fontWeight: 600,
                  fontSize: '25px'
                }}
              >
                se passi alla versione Professional!
              </Typography>
              <Typography
                variant={'h4'}
                sx={{
                  lineHeight: '32px',
                  wordSpacing: '2.5px',
                  textAlign: 'center',
                  fontWeight: 400,
                  marginTop: '40px',
                  marginRight: '64px',
                  marginLeft: '64px'
                }}
              >
                Inserisci il codice promozionale <strong>OFF20</strong> al momento dell'acquisto per ottenere una
                gestione avanzata e personalizzata della tua nota spesa ad un prezzo speciale!
              </Typography>
            </Box>
            <Box flexGrow={1}/>
            <Box
              sx={{
                width: 'fit-content',
                border: '1px',
                borderRadius: '25px',
                backgroundColor: '#8414d5',
                cursor: 'pointer',
                padding: '16px'
              }}
              onClick={handleClick}
            >
              <Typography
                variant={'h4'}
                sx={{
                  color: 'white',
                  textAlign: 'center',
                  marginLeft: '12px',
                  marginRight: '12px',
                }}
              >
                Passa a PRO adesso!
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3} sx={{backgroundColor: '#8414d5'}}>
          <img src={proImageGift} alt={'Pro image'} style={{textAlign: 'center', width: '100%', padding: '25px'}}/>
        </Grid>
      </Grid>
    </Dialog>
  );
}
