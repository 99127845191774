import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const NewExpensePopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile <strong>aggiungere voci di spesa personalizzate</strong> e gestire al meglio le
          proprie note spese. Passa alla versione Professional e riporta le tue regole di trasferta in ogni dettaglio
          per digitalizzare completamente la compilazione delle note spese!</p>
      );
    default:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile <strong>aggiungere voci di spesa personalizzate</strong> e gestire al meglio le
          proprie note spese. Passa alla versione Professional e riporta le tue regole di trasferta in ogni dettaglio
          per digitalizzare completamente la compilazione delle note spese!</p>
      );
  }
}
