import {SearchResult} from "../../../component";
import {Stack, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

interface ComponentProps {
  index?: number
  result: SearchResult,
  onResultClick: (procedureId: number, step?: number) => void
}

export const ProcedureSearchResult = ({index, result, onResultClick}: ComponentProps) => {
  const {t} = useTranslation('help');
  return (
    <Stack key={index ? index : undefined} rowGap={0.5}>
      <li>
        <Typography
          variant={"h5"} sx={{cursor: "pointer"}}
          onClick={() => {
            onResultClick(parseInt(result.pageName.substring(result.pageName.indexOf('_') + 1, result.pageName.indexOf('-'))), parseInt(result.pageName.substring(result.pageName.indexOf('-') + 1)))
          }}
          display={'inline'}
        >
          {t('label.procedureTitle', {procedureName: result.pageName.substring(0, result.pageName.indexOf('_'))})}
        </Typography>
      </li>
      <Typography>
        <div
          dangerouslySetInnerHTML={{
            __html: result.pageContent
          }}
        >
        </div>
      </Typography>
    </Stack>
  );
}
