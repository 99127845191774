import React, {useEffect, useState} from "react";
import {Alert, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {UserList} from "./UserList";
import {useNavigate} from "react-router-dom";
import {
  AllValidCreditCard,
  CreCardFilter,
  isActiveUser,
  ListFilterType,
  RoleFilter,
  User
} from "../model";
import {disableUsers, getUsers, getValidCreCards, hasAvailableLicenses} from "../Service";
import IconButton from "@mui/material/IconButton";
import {PageTitle} from "../../layout/page-title";
import {Filters} from "./Filters";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {Add} from "@mui/icons-material";
import {MultipleSelection} from "../../base/multiple-selection";
import {useCurrentList} from "../../../hooks/useCurrentList";
import {activeStateCode, canceledStateCode} from "../../model";
import {TooltipZts} from "../../base/tooltip";
import {useTranslation} from "react-i18next";
import {useLicense} from "../../../hooks/useLicense";
import {LicensePopup} from "../../license-popup";
import {useCheckDisableUser} from "../hooks/useCheckDisableUser";
import {PopupContentType} from "../../license-popup/model";
import {ImportPage, TemplateFileType} from "../../import/ImportPage";

type ComponentProps = {
  onEnableUser: (user: User) => Promise<User>,
  onDisableUser: (userId: number) => Promise<any>
}

export const FilterableList = ({onEnableUser, onDisableUser}: ComponentProps) => {

  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [openImportPage, setOpenImportPage] = useState(false);
  const [successfulImport, setSuccessfulImport] = useState<boolean>(false);

  const [validCreCards, setValidCreCards] = useState<AllValidCreditCard[] | [] >([]);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const {t} = useTranslation("user", {keyPrefix: "list"});
  const {t: tCommon} = useTranslation("common");
  const {t: tLicense} = useTranslation("license");
  const {t: tImport} = useTranslation('import');

  const navigate = useNavigate();

  const {isProfessional} = useLicense();
  const isPro = isProfessional();

  const {convertError} = useErrorMessage();

  const {filters} = useCurrentList();

  const {
    canDisableUser
  } = useCheckDisableUser();

  const tmpGetUsers = async (): Promise<void> => {
    setLoading(true);
    try {
      const users = await getUsers();
      if (users) {
        setAllUsers(orderUsers(users));
      }
    } catch (error) {
      setErrorMsg(tCommon('fetchError'));
    }
    setLoading(false);
  }

  useEffect(() => {
    tmpGetUsers().then();
  }, []);

  useEffect(() => {
    if (filters) {
      handleFilterChange(filters);
    } else {
      setUsers(allUsers);
    }
  }, [allUsers, filters]);

  const orderUsers = (users: User[]) => {
    return users.sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
  }

  useEffect(() => {
    //viene fatta la chiamata solo se l'utente seleziona il tipo di filtro carta valida/non valida
    //Faccio la chiamata per avere l'elenco delle carte attive nella data di oggi. Mostrerò solo gli utenti che hanno almeno una carta valida
    if (filters) {
      filters
        .filter(f => f.enabled && f.value)
        .forEach(filter => {
          switch (filter.type) {
            case ListFilterType.VALID_CRE_CARD:
              try {
                getValidCreCards().then(tmpValidCreCards => setValidCreCards(tmpValidCreCards));
              } catch (error) {
                setErrorMsg(tCommon('fetchError'));
              }
              break;
          }
        })
    }
  }, [allUsers, filters]);


  const handleFilterChange = (filters) => {
    let newUsers = allUsers;
    if (newUsers && newUsers.length > 0) {
      filters
        .filter(f => f.enabled && f.value)
        .forEach(filter => {
          switch (filter.type) {
            case ListFilterType.SEARCH_TEXT:
              newUsers = newUsers.filter((u: User) =>
                u.firstName.toUpperCase().indexOf(filter.value.toUpperCase()) !== -1 ||
                u.lastName.toUpperCase().indexOf(filter.value.toUpperCase()) !== -1);
              break;
            case ListFilterType.ENABLED:
              newUsers = newUsers.filter((u: User) => filter.value !== '1' || isActiveUser(u));
              break;
            case ListFilterType.TRAVEL_POLICY:
              if (filter.value) {
                newUsers = newUsers.filter((u: User) => !filter.value || isMatchingTravelPolicy(u, filter.value.code));
              }
              break;
            case ListFilterType.ROLE:
              if (filter.value) {
                newUsers = newUsers.filter((u: User) => !filter.value || isMatchingRole(u, filter.value));
              }
              break;
            case ListFilterType.LEVEL:
              if (filter.value) {
                newUsers = newUsers.filter((u: User) => !filter.value || isMatchingLevel(u, filter.value.code));
              }
              break;
            case ListFilterType.APPROVER:
              if (filter.value) {
                newUsers = newUsers.filter((u: User) => !filter.value || isMatchingApprover(u, filter.value.id));
              }
              break;
            case ListFilterType.VALID_CRE_CARD:
              if (filter.value) {
                newUsers = newUsers.filter((u: User) => !filter.value || isMatchingValidCreCard(u, filter.value));
              }
              break;
          }
        });
    }
    setUsers(newUsers);
  }

  const isMatchingTravelPolicy = (user: User, code: string): boolean => {
    if (!user.travelPolicies) {
      return false;
    }

    return user.travelPolicies.some(tp => tp.code === code);
  }

  const isMatchingRole = (user: User, roleFilter: RoleFilter): boolean => {
    return (roleFilter === RoleFilter.ADMIN && user.isAdmin) ||
      (roleFilter === RoleFilter.TRAVELLER && user.isTraveller);
  }

  const isMatchingValidCreCard = (user: User, validCreCardFilter: CreCardFilter): boolean => {
    //Nell'elenco di tutte le carte valide guardo se l'utente ne ha una (len > 0)
    const len = validCreCards ? validCreCards.filter(vcc => user.id === vcc.staffId).length : 0;
    return (validCreCardFilter === CreCardFilter.VALID_CRE_CARD && len > 0) ||
      (validCreCardFilter === CreCardFilter.NO_CRE_CARD && len === 0);

  }

  const isMatchingLevel = (user: User, code: string): boolean => {
    if (!user.costCenter) {
      return false;
    }
    return user.costCenter.code === code;
  }

  const isMatchingApprover = (user: User, code: number): boolean => {
    if (!user.approver) {
      return false;
    }
    return user.approver.id === code;
  }

  const openItemHandler = (id: number) => {
    navigate(`detail/${id}`);
  }

  const handleNewUserClicked = async () => {
    // TODO aprire una modale per evitare all'uetnte di interagire
    const flag = await hasAvailableLicenses();
    if (flag) {
      navigate('edit/-1');
    } else {
      setPopupOpen(true);
    }
  }

  const selectItemHandler = async (id: number) => {
    const canDisable = await canDisableUser(id);
    if (!canDisable) {
      return;
    }

    if (selectedItems.some(tmpId => tmpId === id)) {
      setSelectedItems((items: number[]) => items.filter(tmpId => tmpId !== id));
    } else {
      setSelectedItems((items: number[]) => [...items, id]);
    }
  }

  const onDisableSelectedUsers = async () => {
    if (selectedItems) {
      try {
        await disableUsers(selectedItems);

        const users = await getUsers();
        setAllUsers(orderUsers(users));
        setUsers(users);

        setSelectedItems([]);
      } catch (error: any) {
        convertError(error).then(msg => setErrorMsg(msg));
      }
    }
  }

  const enableHandler = async (userId: number) => {
    try {
      const user = users.find(u => u.id === userId);
      if (user) {
        const flag = true; //await hasAvailableLicenses();
        if (flag) {
          await onEnableUser(user);
          setAllUsers(users => users.map(u => ({
            ...u,
            lastUpdateNum: u.lastUpdateNum + 1,
            state: u.id === userId ? activeStateCode : u.state
          })));
        } else {
          setPopupOpen(true);
        }
      }
    } catch (error: any) {
      const msg = await convertError(error);
      if (msg==='error.loginportal.no_licenses'){
        setPopupOpen(true);
      } else {
        setErrorMsg(msg)
      }
    }
  }

  const disableHandler = async (userId: number) => {
    try {
      const canDisable = await canDisableUser(userId);
      if (!canDisable) {
        return;
      }
      await onDisableUser(userId);
      setAllUsers(users => users.map(u => ({
        ...u,
        lastUpdateNum: u.lastUpdateNum + 1,
        state: u.id === userId ? canceledStateCode : u.state
      })));
    } catch (error: any) {
      convertError(error).then(msg => setErrorMsg(msg));
    }
  }

  const closeDialog = () => {
    setPopupOpen(!popupOpen);
  }

  const handleImportPageClose = () => {
    setOpenImportPage(false);
    if (successfulImport) {
      tmpGetUsers();
    }
  }

  const handleImportButtonClicked = () => {
    setOpenImportPage(true);
  }

  return (
    <>
      <Box
        width={"100%"}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        mx={"auto"}
      >

        {errorMsg && <Alert
          severity={"error"}
          variant={"filled"}
          sx={{mb: 4}}
          onClose={() => setErrorMsg(null)}>{errorMsg}</Alert>}

        {/* Titolo */}
        <PageTitle
          title={
            <Stack direction={"row"} alignItems={"center"}>
              <Typography variant={"h3"} mr={3}>{t("title")}</Typography>
              <Box className={"counter-primary"} mr={2}>
                <Typography variant={"button"}>{allUsers ? allUsers.length : 0}</Typography>
              </Box>
              <div className={"status-pill-list-header"}>
                <div className={"counter-active"}>
                  <Typography
                    variant={"button"}>{allUsers ? allUsers.filter(u => isActiveUser(u)).length : 0}</Typography>
                </div>
                <Typography variant={"button"} textTransform={"lowercase"} mx={1}>{t("active")}</Typography>
              </div>
            </Stack>
          }
        />

        <Box
          mt={1}
          mb={0.6}
          display={"flex"}
          flexWrap={"wrap"}
          alignItems={"center"}
          rowGap={1}
        >

          {/* Filtri */}
          <Filters/>

          {/* Pulsanti: Nuovo utente */}
          <Stack
            order={2}
            direction={"row"}
            columnGap={1}
          >
            {/*<TooltipZts*/}
            {/*  title={tImport('tooltip.users')}*/}
            {/*  placement={"bottom"}*/}
            {/*  enterDelay={400}*/}
            {/*>*/}
            {/*  <IconButton*/}
            {/*    color="primary"*/}
            {/*    className={"edit-button"}*/}
            {/*    id={"import-button"}*/}
            {/*    sx={{*/}
            {/*      opacity: 1,*/}
            {/*      cursor: "pointer"*/}
            {/*    }}*/}
            {/*    onClick={handleImportButtonClicked}*/}
            {/*  >*/}
            {/*    <UploadFile/>*/}
            {/*  </IconButton>*/}
            {/*</TooltipZts>*/}

            <TooltipZts
              title={t("createNewUser")}
              placement={"bottom"}
              enterDelay={400}
            >
              <IconButton
                id={"new-user-btn"}
                color="primary"
                className={"edit-button"}
                onClick={handleNewUserClicked}
              >
                <Add/>
              </IconButton>
            </TooltipZts>
          </Stack>

        </Box>

        {/* Lista utenti */}
        <Box
          id={"infinite-scroll-target"}
          overflow={"auto"}
          flexGrow={1}
          mt={0.6}
        >
          <UserList
            users={users}
            loading={loading}
            onOpen={openItemHandler}
            onToggleSelect={selectItemHandler}
            onEnable={enableHandler}
            onDisable={disableHandler}
            selectedItems={selectedItems}
          />
        </Box>

        <ImportPage
          title={tImport('list.titleImport.users')}
          onClose={handleImportPageClose}
          show={openImportPage}
          save={false}
          onImport={null}
          onDownload={null}
          templateFileType={TemplateFileType.USERS}
          notifySuccessfulImport={() => setSuccessfulImport(true)}
        />

      </Box>

      <MultipleSelection
        size={selectedItems.length}
        clearItems={() => setSelectedItems([])}
        actionHandler={onDisableSelectedUsers}
        ctaLabel={tCommon("disable")}
      />

      <LicensePopup open={popupOpen} isPro={isPro} closePopup={closeDialog} titlePro={tLicense('pro.menu.user.add.title')} type={PopupContentType.USER_ADD}/>

    </>
  );

}
