import { getLoginPortalUrl } from '../../config/environment';
import { default as axios } from 'axios';
import SimpleJsLog from '../../util/Logger';
import { Logger } from 'react-logger-lib';
import { UserLoginResponse } from '../login/model';

const LOG: SimpleJsLog = Logger.of('ZTS.impersonate.ImpersonateService');

export interface ImpersonateLoginResponse extends UserLoginResponse {
  adminUser: string;
}

export const impersonateUser = async (token: string): Promise<ImpersonateLoginResponse|null>=> {
  const prefix = 'impersonateUser';

  const baseUrl = getLoginPortalUrl();
  const url = `${baseUrl}auth/impersonate`;
  const headers = {
    Authorization: 'None'
  };
  const body = {
    token: token
  }


  //LOG.trace(`${prefix} | `);
  try{
    LOG.trace(`${prefix} | Calling url`, url);
    const resp= await axios.post<ImpersonateLoginResponse>(url, body, {headers});
    LOG.trace(`${prefix} | resp status`, resp.status);
    LOG.trace(`${prefix} | resp data`, resp.data);
    if (resp.status >= 200 && resp.status<300) {
      return resp.data;
    }
  }catch(e){
    LOG.trace(`${prefix} | Calling error`, e);
  }
  // Errore di rete o errore dal server
  return null;
}

