import React, {useEffect, useState} from "react";
import {getProcedureStepsById} from "../../Service";
import {createError, useErrorMessage} from "../../../../util/ErrorUtil";
import {Box, Button, Stack, Step, StepLabel, Stepper, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {ArrowBack} from "@mui/icons-material";
import {useLoggedUser} from "../../../../hooks/useLoggedUser";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  procedureId: number,
  procedureName: string,
  stepsNumber: number,
  onClose: Function,
  activeStep: number,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
  skippedSteps: Set<number>,
  setSkippedSteps:  React.Dispatch<React.SetStateAction<Set<number>>>
}

export type ProcedureStep = {
  stepNumber: number,
  htmlContent: string
}

export const Procedure = ({procedureId, procedureName, stepsNumber, onClose, activeStep, setActiveStep, skippedSteps, setSkippedSteps}: ComponentProps) => {
  const {convertError} = useErrorMessage();
  const {userLocale} = useLoggedUser();
  const {t} = useTranslation('procedure', {keyPrefix: 'button'});

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [currentContent, setCurrentContent] = useState<string>();
  const [allContents, setAllContents] = useState<ProcedureStep[]>([]);

  const isStepOptional = (step: number) => {
    return step !== stepsNumber;
  };

  const isStepSkipped = (stepNumber: number) => {
    return skippedSteps.has(stepNumber);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      convertError(createError(new Error("You can't skip a step that isn't optional.")));
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkippedSteps((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleNext = () => {
    let newSkipped = skippedSteps;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkippedSteps(newSkipped);
  };


  useEffect(() => {
    setLoading(true);
    for (let i = 0; i < stepsNumber; i++) {
      getProcedureStepsById(procedureId, i + 1, userLocale)
        .then((result) => {
          const step: ProcedureStep = {stepNumber: i, htmlContent: result};
          setAllContents((prevState) => [...prevState, step]);
          if (i === activeStep) {
            setCurrentContent(result);
          }
        })
        .catch(async (error) => {
          const msg = await convertError(createError(error));
          setErrorMsg(msg);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    const step = allContents ? allContents.find(active => active.stepNumber === activeStep) : null;
    if (step) {
      setCurrentContent(step.htmlContent);
    }
  }, [activeStep, allContents])

  return (
    <>
      <Box>
        <Stack
          direction={"row"}
          alignItems={"center"}
          alignContent={"center"}
          my={0.6}
        >
          {onClose && <IconButton
            disableRipple={true}
            sx={{
              flexBasis: '40px',
              justifyContent: 'flex-start'
            }}
            size={"small"}
            onClick={() => onClose()}
          >
            <ArrowBack fontSize={"small"}/>
          </IconButton>}
          <Typography variant={"h4"} mb={1} mt={0.5} noWrap={true}>{procedureName}</Typography>
        </Stack>

        {/* Orizzontale */}
        <Box sx={{maxWidth: '100%'}} mt={1.5} mb={1}>
          <Stepper activeStep={activeStep}>
            {allContents.map((step, index) => {
              const stepProps: { completed?: boolean } = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={index} {...stepProps}>
                  <StepLabel className={"stepLabel"}/>
                </Step>
              );
            })
            }
          </Stepper>
        </Box>
      </Box>
      {
        !loading &&
        <>
          {/* Orizzontale */}
          <Box
            flexGrow={1}
            height={"calc(100% - 130px)"}
            overflow={"auto"}
            sx={{cursor: "default"}}
            m={1.5}
            className={"no-drag-area"}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: currentContent ? currentContent : ""
              }}
            >
            </div>
          </Box>

          {
            activeStep === stepsNumber ? (
              <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <Box sx={{flex: '1 1 auto'}}/>
                <Button className={"stepButton"} onClick={handleReset}>{t('restart')}</Button>
                <Button color="inherit" onClick={() => onClose()}>{t('exit')}</Button>
              </Box>
            ) : (
              <>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Button
                    disabled={activeStep === 0}
                    color="inherit"
                    onClick={handleBack}
                    sx={{mr: 1}}
                  >
                    {t('back')}
                  </Button>
                  <Box sx={{flex: '1 1 auto'}}/>
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{mr: 1}}>
                      {t('skip')}
                    </Button>
                  )}
                  <Button className={"stepButton"} onClick={handleNext}>
                    {activeStep === stepsNumber - 1 ? t('end') : t('next')}
                  </Button>
                </Box>
              </>
            )
          }
        </>
      }
    </>
  );
}
