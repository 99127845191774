// i18n
import { TFunction } from 'react-i18next';

import { ExpenseCategory, ExpenseMeasureUnit, ExpenseType } from './model';
import { SelectOption } from '../base/select/model';

export const getExpenseTypeLabel = (type: ExpenseType | undefined, t: TFunction): string => {
  if (type===undefined) { return ''; }
  switch (type){
    case 'PL':
    case 'TR':
    case 'IF':
      const key = 'expense.type.' + type;
      return t(key, {ns: 'expense'});
  }
  throw new Error('Unexpected type:' + type);
}

export const getExpenseCategoryLabel = (category: ExpenseCategory | undefined, t: TFunction) => {
  if (category === undefined){ return ''; }

  switch (category) {
    case 'VT':
    case 'AL':
    case 'MT':
    case 'AP':
    case 'VR':
      const key = 'expense.category.' + category;
      return t(key, {ns: 'expense'});
  }
  throw new Error('Unexpected category:' + category);
}

export const getExpenseMeasureUnitLabel = (unit: ExpenseMeasureUnit | undefined, t: TFunction): string => {
  if (unit===undefined || unit===null){ return ''; }
  switch (unit) {
    case 'GG':
    case 'KM':
    case 'NR':
      const key = 'expense.measure.' + unit;
      return t(key, {ns: 'expense'});
  }
  throw new Error('Unexpected MeasureUnit:' + unit);
}

export const getAllExpenseTypeOptions = (t: TFunction): SelectOption[] => {
  const values: ExpenseType[] = ['PL', 'TR', 'IF'];
  return getExpenseTypeOptions(values, t);
}

export const getExpenseTypeOptions = (values: ExpenseType[], t: TFunction): SelectOption[] => {
  return values.map(x => {return {value: x, label: getExpenseTypeLabel(x, t)}});
}

export const getAllCategoryOptions = (t: TFunction): SelectOption[] => {
  const values: ExpenseCategory[] = ['VT', 'AL', 'MT', 'AP', 'VR'];
  return getCategoryOptions(values, t);
}

export const getCategoryOptions = (values: ExpenseCategory[], t: TFunction): SelectOption[] => {
  return values.map(x => {return {value: x, label: getExpenseCategoryLabel(x, t)}});
}

export const getAllMeasureOptions = (t: TFunction): SelectOption[] => {
  const values: ExpenseMeasureUnit[] = ['GG', 'KM', 'NR'];
  return getMeasureOptions(values, t);
}

export const getMeasureOptions = (values: ExpenseMeasureUnit[], t: TFunction): SelectOption[] => {
  return values.map(x => {return {value: x, label: getExpenseMeasureUnitLabel(x, t)}});
}
