import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import imgSrc from "../../../../assets/images/promo_carosello.jpg";
import React from "react";
import {CURRENT_PROMO_LINK} from "../../../promo-popup";
import emojiStar from "../../../../assets/images/emoji_star.png";

export const GenericPromoPopupContent = () => {
  const handleClick = () => {
    window.open(CURRENT_PROMO_LINK, '_blank');
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Box overflow={'hidden'} maxHeight={'250px'}>
          <Typography textAlign={'left'} variant={'h3'} mb={1}>
            PASSA A PROFESSIONAL CON SCONTO 20%&nbsp;<img src={emojiStar} alt='Start Emoji'
                                                          style={{width: '30px', height: '30px'}}/>
          </Typography>
          <Box maxHeight={'185px'} overflow={'auto'}>
            <p style={{textAlign: 'left', fontSize: '16px'}}>
              Fino al 31/12/2023 puoi passare alla versione Professional con lo sconto del 20%.
              <br/>
              Inserisci il codice promozionale <strong>OFF20</strong> al momento dell'acquisto per ottenere una gestione
              avanzata e personalizzata della tua nota spesa ad un prezzo speciale!
            </p>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box display={'flex'} height={'100%'} flexDirection={'column'}>
          <Box maxHeight={'250px'}>
            <img src={imgSrc} alt={'star emoji image'} style={{backgroundSize: 'cover'}}/>
          </Box>
          <Box flexGrow={1}/>
          <Box mt={2} sx={{
            color: 'white',
            backgroundColor: '#14c6d5',
            width: 'fit-content',
            padding: '10px 10px',
            cursor: 'pointer'
          }} borderRadius={'10px'} onClick={handleClick}><Typography variant={'body2'}>Scopri di più</Typography></Box>
        </Box>
      </Grid>
    </Grid>
  );
}
