import {useDispatch, useSelector} from "react-redux";
import {
  CompletedJobNotify,
  dequeueRunningJob,
  dequeueToRunJob,
  enqueueRunningJob,
  enqueueToRunJob, getCompletedJobNotifies,
  getRunningJobs,
  getToRunJobs, notifyCompletedJob, removeCompletedJobNotify,
  RunningJob,
  ToRunJob
} from "../reducers/CurrentJobs";

export const useCurrentJobs = () => {
  const toRunJobs: ToRunJob[] = useSelector(getToRunJobs);
  const runningJobs: RunningJob[] = useSelector(getRunningJobs);
  const completedJobNotifies: CompletedJobNotify[] = useSelector(getCompletedJobNotifies);

  const dispatch = useDispatch();

  const getRunningJobsState = () => {
    return runningJobs;
  }

  const getToRunJobsState = () => {
    return toRunJobs;
  }

  const insertToRunJob = (job: ToRunJob) => {
    dispatch(enqueueToRunJob(job));
  }

  const deleteToRunJob = (job: ToRunJob) => {
    dispatch(dequeueToRunJob(job));
  }

  const insertRunningJob = (job: RunningJob) => {
    dispatch(enqueueRunningJob(job));
  }

  const deleteRunningJob = (job: RunningJob) => {
    dispatch(dequeueRunningJob(job));
  }

  const sendNotifyCompletedJob = (notify: CompletedJobNotify) => {
    dispatch(notifyCompletedJob(notify));
  }

  const removeNotifyCompletedJob = (notify: CompletedJobNotify) => {
    dispatch(removeCompletedJobNotify(notify));
  }

  return {
    toRunJobs,
    runningJobs,
    completedJobNotifies,
    insertToRunJob,
    deleteToRunJob,
    insertRunningJob,
    deleteRunningJob,
    getRunningJobsState,
    getToRunJobsState,
    sendNotifyCompletedJob,
    removeNotifyCompletedJob
  }
}
