// React
import React from "react";
import {FocusEvent} from "react";

// MUI
import {OutlinedInputProps, TextField, TextFieldProps} from "@mui/material";


type TextFieldZtsProps = {
  errorMsg?: string,
  align?: 'left' | 'right';
  enablePerformance?: boolean;
  autoTrim?: boolean;
  forceHelperOnDisabled?: boolean
}

export const TextFieldZts = React.forwardRef<HTMLInputElement, TextFieldZtsProps & TextFieldProps>((
  {
    errorMsg,
    align,
    enablePerformance,
    autoTrim = true,
    disabled,
    forceHelperOnDisabled,
    ...otherProps
  }, ref) => {

  const configTextField: TextFieldProps = {
    fullWidth: true,
    variant: 'filled',
    size: 'small',
    inputRef: ref,
    disabled: disabled,
    ...otherProps,
  };

  if (errorMsg && !configTextField.disabled) {
    configTextField.error = true;
    configTextField.helperText = errorMsg;
  } else if (!configTextField.helperText && !configTextField.disabled) {
    configTextField.helperText = ' '; // fix height when no error
  }

  if (forceHelperOnDisabled && disabled) {
    configTextField.helperText = ' '; // fix height when no error
  }

  if (align) {
    configTextField.inputProps = {
      style: {
        textAlign: align
      }
    }
  }

  const onBlurAutoTrim = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (autoTrim && configTextField.onChange) {
      const newValue = e.target.value ? e.target.value.trim() : e.target.value;
      const newTarget = {...e.target, value: newValue};
      const newE = {...e, target: newTarget};
      configTextField.onChange(newE);
    }
    if (configTextField.onBlur) {
      configTextField.onBlur(e);
    }
  }

  //console.log(configTextField)

  return (
    <TextField
      InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}
      {...configTextField}

      onBlur={onBlurAutoTrim}
    />
  );
});
