import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {ColumnDataType, ColumnPosition, CompleteConfiguration, ImportConfigurationSeparators} from "./model";
import {ZtsTableColumn} from "../../base/table/model";
import {FileUploadResp} from "../../model";
import {ImportConfigurerFirstTab} from "./tabs/1";
import {ImportConfigurerSecondTab} from "./tabs/2";
import {ImportConfigurerFourthTab} from "./tabs/4";
import {ImportConfigurerThirdTab} from "./tabs/3";
import CloseIcon from "@mui/icons-material/Close";
import {MimeType} from "../../base/file-upload/model";
import {saveImportConfig} from "./Service";
import {Loader} from "../../base/loader";
import {ImportConfigurerFifthTab} from "./tabs/5";

interface ComponentProps {
  open: boolean,
  closePopup: () => void,
  onSuccess: () => void,
  previousConfiguration?: CompleteConfiguration
}

enum Tab {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  FOURTH = 'FOURTH',
  FIFTH = 'FIFTH'
}

export type ResetColumnKeys = 'EXCHANGE' | 'REFERENCE' | 'CAT_MERC';

export const ImportConfigurer = ({open, closePopup, onSuccess, previousConfiguration}: ComponentProps) => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [currentTab, setCurrentTab] = useState<Tab>(Tab.FIRST);

  const [uploadedFile, setUploadedFile] = useState<FileUploadResp | null>(null);
  const [csvSeparator, setCsvSeparator] = useState<string | null>(null);
  const [configurationSeparators, setConfiguration] = useState<ImportConfigurationSeparators | null>(null);
  const [columnsPosition, setColumnsPosition] = useState<ColumnPosition | null>(null);

  const [columns, setColumns] = useState<ZtsTableColumn[]>([]);
  const [rows, setRows] = useState<object[]>([]);

  const [previewHeader, setPreviewHeader] = useState<JSX.Element | undefined>(undefined);

  const {convertError} = useErrorMessage();
  const {t} = useTranslation('cre-card-mov', {keyPrefix: 'configurer'});

  useEffect(() => {
    if (!open) {
      resetDialog();
    } else {
      if (previousConfiguration) {
        setConfiguration(csvSeparator ? {
          ...previousConfiguration.separators,
          csvSeparator: csvSeparator
        } : previousConfiguration.separators);
        setColumnsPosition(previousConfiguration.positions);
      }
    }
  }, [open]);

  const resetDialog = () => {
    setErrorMsg(null);
    setLoading(false);
    setColumns([]);
    setRows([]);
    setUploadedFile(null);
    setCurrentTab(Tab.FIRST);
  }

  const handleNextClicked = () => {
    switch (currentTab) {
      case Tab.FIRST:
        setCurrentTab(Tab.SECOND);
        break;
      case Tab.SECOND:
        setCurrentTab(Tab.THIRD);
        break;
      case Tab.THIRD:
        setCurrentTab(Tab.FOURTH);
        break;
      default:
        break;
    }
  }

  const handlePrevClicked = () => {
    switch (currentTab) {
      case Tab.SECOND:
        setCurrentTab(Tab.FIRST);
        break;
      case Tab.THIRD:
        setCurrentTab(Tab.SECOND);
        break;
      case Tab.FOURTH:
        setCurrentTab(Tab.THIRD);
        break;
      default:
        break;
    }
  }

  const handleConfirmClicked = () => {
    if (uploadedFile && configurationSeparators && columnsPosition) {
      setLoading(true);
      saveImportConfig({
        fileType: (uploadedFile.mimeType === MimeType.CSV || uploadedFile.mimeType === MimeType.TXT) ? 'csv' : 'xls',
        rowsToSkip: configurationSeparators.rowsToSkip || 0,
        dateFormat: configurationSeparators.dateFormat || '',
        decimalSep: configurationSeparators.decimalSeparator || '.',
        csvSep: configurationSeparators.csvSeparator ? configurationSeparators.csvSeparator : undefined,
        posCreCardNum: columnsPosition.creCardNumColumn.index !== undefined ? columnsPosition.creCardNumColumn.index : -1,
        posPayDate: columnsPosition.payDateColumn.index !== undefined ? columnsPosition.payDateColumn.index : -1,
        posCompAmount: columnsPosition.compAmountColumn.index !== undefined ? columnsPosition.compAmountColumn.index : -1,
        posCurrency: columnsPosition.currencyColumn.index !== undefined ? columnsPosition.currencyColumn.index : -1,
        posCurrAmount: columnsPosition.currAmountColumn.index !== undefined ? columnsPosition.currAmountColumn.index : -1,
        posExchange: columnsPosition.exchangeColumn.index !== undefined ? columnsPosition.exchangeColumn.index : -1,
        posReference: columnsPosition.creCardMovReferenceColumn.index !== undefined ? columnsPosition.creCardMovReferenceColumn.index : -1,
        posDescription: columnsPosition.creCardMovDescColumn.index !== undefined ? columnsPosition.creCardMovDescColumn.index : -1,
        posCatMerc: columnsPosition.catMercColumn.index !== undefined ? columnsPosition.catMercColumn.index : -1
      })
        .then(resp => {
          setCurrentTab(Tab.FIFTH);
        })
        .catch(err => {
          convertError(err)
            .then(msg => setErrorMsg(msg));
        })
        .finally(() => setLoading(false));
    }
  }

  const checkNextDisabled = (): boolean => {
    switch (currentTab) {
      case Tab.FIRST:
        if (uploadedFile) {
          return false;
        }
        break;
      case Tab.SECOND:
        if (uploadedFile && configurationSeparators) {
          if ((uploadedFile.mimeType === MimeType.CSV || uploadedFile.mimeType === MimeType.TXT)) {
            const keys = Object.keys(configurationSeparators);
            const validConfigParameters = keys.filter(key => {
              if (configurationSeparators[key] || (key === 'rowsToSkip' && configurationSeparators[key] === 0)) {
                return key;
              }
            });
            if (keys.length === validConfigParameters.length) {
              return false;
            }
          } else {
            const keys = Object.keys(configurationSeparators);
            const validConfigParameters = keys.filter(key => {
              if (configurationSeparators[key]) {
                return key;
              }
            });
            if (keys.length <= validConfigParameters.length + 1) {
              return false;
            }
          }
        }
        break;
      case Tab.THIRD:
        if (columns.length > 0 && rows.length > 0 && columnsPosition) {
          return false;
        }
        break;
      case Tab.FOURTH:
        if (columnsPosition && rows && columns) {
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  }

  const resetColumnPosition = (columnKey: ResetColumnKeys) => {
    setColumnsPosition(prevState => {
      if (!prevState) {
        return null;
      }

      let newStateSlice;
      switch (columnKey) {
        case "EXCHANGE":
          newStateSlice = {
            exchangeColumn: {...prevState.exchangeColumn, index: -1, isValid: true}
          }
        break;
        case "CAT_MERC":
          newStateSlice = {
            catMercColumn: {...prevState.catMercColumn, index: -1, isValid: true}
          }
          break;
        case "REFERENCE":
          newStateSlice = {
            creCardMovReferenceColumn: {...prevState.creCardMovReferenceColumn, index: -1, isValid: true}
          }
          break;
        default:
          throw new Error(`Invalid column key: ${columnKey}`);
      }

      return {
        ...prevState,
        ...newStateSlice
      }
    });
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'md'}
      disableEscapeKeyDown={true}
      className={'license-popup'}
    >
      <DialogTitle>
        <Box
          display={'flex'}
          flexDirection={"row"}
          columnGap={2}
          justifyContent={'space-between'}
          alignItems={"center"}
        >
          <Typography fontSize={22} fontWeight={550} color={'black'} padding={1}
                      variant={"h3"}>{t('title')}</Typography>
          <IconButton
            sx={{color: 'black'}}
          >
            <CloseIcon onClick={() => {
              resetDialog();
              closePopup();
            }}/>
          </IconButton>

        </Box>
      </DialogTitle>
      <DialogContent
        sx={{overflow: 'hidden', py: '0px !important'}}
      >
        <Loader show={loading}/>
        {
          currentTab === Tab.FIRST &&
          <ImportConfigurerFirstTab file={uploadedFile ? uploadedFile : undefined} setFile={setUploadedFile}
                                    setCsvSeparator={setCsvSeparator}/>
        }
        {
          currentTab === Tab.SECOND && uploadedFile &&
          <ImportConfigurerSecondTab open={currentTab === Tab.SECOND} uploadedFile={uploadedFile}
                                     foundCsvSeparator={csvSeparator ? csvSeparator : undefined}
                                     previousConfig={configurationSeparators ? configurationSeparators : undefined}
                                     setConfig={setConfiguration}
                                     previewHeader={previewHeader}
          setPreviewHeader={setPreviewHeader}/>
        }
        {
          currentTab === Tab.THIRD && configurationSeparators && uploadedFile &&
          <ImportConfigurerThirdTab open={currentTab === Tab.THIRD} configurationSeparators={csvSeparator ? {
            ...configurationSeparators,
            csvSeparator: csvSeparator
          } : configurationSeparators} uploadedFile={uploadedFile}
                                    previousPositions={columnsPosition ? columnsPosition : undefined}
                                    setPositions={setColumnsPosition} setFinalColumns={setColumns}
                                    previewHeader={previewHeader}
                                    setFinalRows={setRows}
                                    resetColumnPosition={resetColumnPosition}
          />
        }
        {
          currentTab === Tab.FOURTH && columnsPosition &&
          <ImportConfigurerFourthTab open={currentTab === Tab.FOURTH}
                                     initialColumns={columns}
                                     initialRows={rows}
                                     previewHeader={previewHeader}
                                     columnsPositions={columnsPosition}/>
        }
        {
          currentTab === Tab.FIFTH &&
          <ImportConfigurerFifthTab triggerImport={onSuccess}/>
        }
      </DialogContent>
      <DialogActions>
        <Stack
          alignSelf={"flex-end"}
          direction={"row"}
          columnGap={2.2}
        >
          {
            currentTab !== Tab.FIRST && currentTab != Tab.FIFTH &&
            <Button
              variant="contained"
              className={"cta-button-primary"}
              onClick={handlePrevClicked}
            >
              <Typography ml={1} variant={"button"}>{t('button.back')}</Typography>
            </Button>
          }
          {
            currentTab !== Tab.FOURTH && currentTab != Tab.FIFTH &&
              <Button
                variant="contained"
                className={"cta-button-primary"}
                onClick={handleNextClicked}
                disabled={checkNextDisabled()}
              >
                <Typography ml={1} variant={"button"}>{t('button.next')}</Typography>
              </Button>
          }
          {
            currentTab === Tab.FOURTH &&
            <Button
              variant="contained"
              className={"cta-button-primary"}
              onClick={handleConfirmClicked}
            >
              <Typography ml={1} variant={"button"}>{t('button.confirm')}</Typography>
            </Button>
          }
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
