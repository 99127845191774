import { getApiUrl } from '../../config/environment';
import {
  CompleteCreCardMovByIdRequest,
  CompleteCreCardMovDto,
  CompleteCreCardMovRequest,
  CompleteCreCardMovResponse,
  CreCardMovInfoResponse,
  CreCardMovPerOperationCounterRequest,
  CreCardMovPerOperationCounterResponse,
  CreCardMovPerUploadCounterRequest,
  CreCardMovPerUploadCounterResponse
} from './model';
import axios from 'axios';
import { createError } from '../../util/ErrorUtil';

export const getNonGroupedCreCardMov = async (request: CompleteCreCardMovRequest): Promise<CompleteCreCardMovResponse> => {
  try {
    const apiUrl = getApiUrl();
    const queryString = queryStringBuilder(request);
    const url = `${apiUrl}/creCardMov/all${queryString}`;

    const resp = await axios.get(url);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getCreCardMovById = async (id: number): Promise<CompleteCreCardMovDto> => {
  try {
    const apiUrl = getApiUrl();
    const url = `${apiUrl}/creCardMov?id=${id}`;

    const resp = await axios.get(url);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getCreCardMovByUploadId = async (request: CompleteCreCardMovByIdRequest): Promise<CompleteCreCardMovResponse> => {
  try {
    const apiUrl = getApiUrl();
    const queryString = queryStringBuilder(request);
    const url = `${apiUrl}/creCardMov/upload${queryString}`;

    const resp = await axios.get(url);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getCreCardMovInfoById = async (id: number): Promise<CreCardMovInfoResponse> => {
  try {
    const apiUrl = getApiUrl();
    const url = `${apiUrl}/creCardMov/info?id=${id}`;

    const resp = await axios.get(url);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const confirmCreCardMov = async (id: number, checked: boolean, checkedNotes?: string): Promise<CompleteCreCardMovDto> => {
  try {
    const apiUrl = getApiUrl();
    const url = `${apiUrl}/creCardMov/confirm`;

    let req;

    if (checkedNotes) {
      req = {id: id, checked: checked, checkedNotes: checkedNotes};
    } else {
      req = {id: id, checked: checked};
    }

    const resp = await axios.post(url, req);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const discardCreCardMov = async (id: number, discarded: boolean, discardedNotes?: string): Promise<CompleteCreCardMovDto> => {
  try {
    const apiUrl = getApiUrl();
    const url = `${apiUrl}/creCardMov/discard`;

    let req;

    if (discardedNotes) {
      req = {id: id, discarded: discarded, discardedNotes: discardedNotes};
    } else {
      req = {id: id, discarded: discarded};
    }

    const resp = await axios.post(url, req);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const disconnectCreCardMov = async (id: number): Promise<CompleteCreCardMovDto> => {
  try {
    const apiUrl = getApiUrl();
    const url = `${apiUrl}/creCardMov/disconnect`;

    const resp = await axios.put(url, {id: id});
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const cancelUploadById = async (uploadId: number) => {
  try {
    const apiUrl = getApiUrl();
    const url = `${apiUrl}/creCardMov/cancel?uploadId=${uploadId}`;

    const resp = await axios.delete(url);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getPerOperationCounters = async (request: CreCardMovPerOperationCounterRequest): Promise<CreCardMovPerOperationCounterResponse> => {
  try {
    const apiUrl = getApiUrl();
    const queryString = queryStringBuilder(request);
    const url = `${apiUrl}/creCardMov/counters/operation${queryString}`;

    const resp = await axios.get(url);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getPerUploadCounters = async (request: CreCardMovPerUploadCounterRequest): Promise<CreCardMovPerUploadCounterResponse> => {
  try {
    const apiUrl = getApiUrl();
    const queryString = queryStringBuilder(request);
    const url = `${apiUrl}/creCardMov/counters/upload${queryString}`;

    const resp = await axios.get(url);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

const queryStringBuilder = (request: Object): string => {
  let str: string[] = [];

  for (let key in request) {
    const value = request[key];
    if (value) {
      str.push(`${key}=${value}`);
    }
  }

  if (str.length > 0) {
    return '?' + str.join('&');
  }

  return '';
}
