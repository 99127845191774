import { activeStateCode, StateType } from '../model';

export interface Level {
  id: number,
  code: string,
  description: string,
  lastUpdateNum: number,
  state: StateType
}

export interface SaveLevel {
  id: number | null,
  code: string,
  description: string,
  lastUpdateNum: number,
  state: string
}
export enum ListFilterType {
  SEARCH_TEXT,
  // ENABLED
}

export interface ListFilter {
  type: ListFilterType,
  enabled: boolean,
  label: string,
  value: any,
  hidden?: boolean
}
export const ListFilters: ListFilter[] = [
  {
    type: ListFilterType.SEARCH_TEXT,
    label: '',
    enabled: true,
    hidden: true,
    value: ''
  },
]



export const getLevelCodeAndDescription = (level: Level): string => {
  return level ? `${level.code} ${level.description}` : '';
}

export const isActiveLevel = (level: Level) => {
  return level && activeStateCode === level.state;
}
