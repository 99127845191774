import React, {useEffect, useState} from "react";

// https://mui.com/blog/lab-date-pickers-to-mui-x/
//https://mui.com/x/react-date-pickers/getting-started/

import {DemoForm} from "./DemoForm";
import {CommonDialog} from "../base/common-dialog";
import {Button} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {FormValues, schema} from "./model";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import Box from "@mui/material/Box";
import {createAutocompleteGenericOption} from "../base/autocomplete/model";

export const Demo = () => {

  const [showDialog, setShowDialog] = useState(false);

  const initVal = {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    city: '',
    job: null,
    languages: [],
    assumptionDate: null
  }

  const formMethods = useForm<FormValues>({
    defaultValues: initVal,
    resolver: yupResolver(schema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
                          // (per vedere subito gli errori, prima della submit)
                          // IMPATTA sulle PERFORMANCE
  });

  const handleCloseDialog = (save: boolean) => {
    //console.log('handleCloseDialog', save)

    if (save) {
      formMethods.handleSubmit(onSaveData)();
    } else {
      setShowDialog(false);
      formMethods.reset(initVal);
    }
  }

  const onSaveData = (values) => {
    setShowDialog(false);
    console.log('values', values);
    formMethods.reset(initVal);
  }

  useEffect(() => {
    formMethods.reset({...initVal, languages: [
        {
          language: createAutocompleteGenericOption(1, 'A', 'Javascript'),
          ackDate: null,
          rating: '3'
        },
        {
          language: createAutocompleteGenericOption(2, 'B', 'Python'),
          ackDate: null,
          rating: '5'
        }
      ]})
  }, []);

  return (
    <>

      <Button
        variant={"contained"}
        onClick={(e) => setShowDialog(true)}
      >
        open
      </Button>

      {showDialog && <FormProvider {...formMethods}>
        <CommonDialog
          show={showDialog}
          title={"Test"}
          widths={[
            {breakpoint: "lg", width: "800px"},
          ]}
          saving={false}
          onClose={handleCloseDialog}
          errorMsg={null}>
          <DemoForm/>
          {/*<DemoFormUnchecked />*/}
        </CommonDialog>
      </FormProvider>}


      <Box my={6}/>

      <FormProvider {...formMethods}>
        <DemoForm/>
      </FormProvider>


    </>
  );
}
