import {useDispatch, useSelector} from "react-redux";
import {
  clear,
  ExpNoteListAdminFilter,
  ExpNoteListAdminState,
  ExpNoteListApproverFilter,
  ExpNoteListApproverState,
  ExpNoteListPagination,
  ExpNoteListRole,
  ExpNoteListSorting,
  ExpNoteListState,
  ExpNoteListTravellerFilter,
  ExpNoteListTravellerState,
  getAdminList,
  getApproverList,
  getCurrentRole,
  getTravellerList,
  setAdminFilter,
  setAdminListType,
  setAdminPagination,
  setAdminSorting, setApproverFilter,
  setApproverListType, setApproverPagination, setApproverSorting,
  setCurrentRole,
  setTravellerFilter,
  setTravellerListType,
  setTravellerPagination,
  setTravellerSorting
} from "../../../reducers/ExpNoteList";
import {ExpNoteStateFE} from "../model";

export const useExpNoteList = () => {

  const currentRole: ExpNoteListRole = useSelector<ExpNoteListState>(getCurrentRole);

  const adminList: ExpNoteListAdminState = useSelector<ExpNoteListState>(getAdminList);
  const travellerList: ExpNoteListTravellerState = useSelector<ExpNoteListState>(getTravellerList);
  const approverList: ExpNoteListApproverState = useSelector<ExpNoteListState>(getApproverList);

  const dispatch = useDispatch();

  const updateAdminListType = (stateSelected: ExpNoteStateFE) => {
    dispatch(setAdminListType(stateSelected));
  }

  const updateAdminFilter = (filter: ExpNoteListAdminFilter) => {
    dispatch(setAdminFilter(filter));
  }

  const updateAdminSorting = (sorting: ExpNoteListSorting) => {
    dispatch(setAdminSorting(sorting));
  }

  const updateAdminPagination = (pagination: ExpNoteListPagination) => {
    dispatch(setAdminPagination(pagination));
  }

  const updateTravellerListType = (stateSelected: ExpNoteStateFE) => {
    dispatch(setTravellerListType(stateSelected));
  }

  const updateTravellerFilter = (filter: ExpNoteListTravellerFilter) => {
    dispatch(setTravellerFilter(filter));
  }

  const updateTravellerSorting = (sorting: ExpNoteListSorting) => {
    dispatch(setTravellerSorting(sorting));
  }

  const updateTravellerPagination = (pagination: ExpNoteListPagination) => {
    dispatch(setTravellerPagination(pagination));
  }

  const updateApproverListType = (stateSelected: ExpNoteStateFE) => {
    dispatch(setApproverListType(stateSelected));
  }

  const updateApproverFilter = (filter: ExpNoteListApproverFilter) => {
    dispatch(setApproverFilter(filter));
  }

  const updateApproverSorting = (sorting: ExpNoteListSorting) => {
    dispatch(setApproverSorting(sorting));
  }

  const updateApproverPagination = (pagination: ExpNoteListPagination) => {
    dispatch(setApproverPagination(pagination));
  }

  const updateCurrentRole = (role: ExpNoteListRole) => {
    dispatch(setCurrentRole(role));
  }

  const clearAll = () => {
    dispatch(clear());
  }

  return {
    adminList,
    travellerList,
    approverList,
    currentRole,
    updateCurrentRole,
    updateAdminListType,
    updateAdminFilter,
    updateAdminSorting,
    updateAdminPagination,
    updateTravellerListType,
    updateTravellerFilter,
    updateTravellerSorting,
    updateTravellerPagination,
    updateApproverListType,
    updateApproverFilter,
    updateApproverSorting,
    updateApproverPagination,
    clearAll
  }

}
