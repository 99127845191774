import { setErrorState } from '../reducers/ErrorHandler';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { I18nNamespaces, ResourceType } from '../components/model';
import { formatDate } from './DateUtil';
import { useLoggedUser } from '../hooks/useLoggedUser';
import moment from 'moment';
import { Moment } from 'moment/moment';

export interface ErrorParam {
  key: string;
  value: string;
  toBeTranslated?: boolean;
  isDate?: boolean
}

export interface ErrorData {
  errorCode: string;
  errorMessage: string;
  errorMessageParams?: ErrorParam[];
  fieldErrors?: string[]; // presente solo in caso di errori di validazione gestiti da Spring (vedi GlobalExceptionHandler.handleMethodArgumentNotValid)
  message?: string; // presente solo in caso di errori di validazione gestiti da Spring (vedi GlobalExceptionHandler.handleMethodArgumentNotValid)
}

export const useErrorMessage = (): { convertError: (error: Error) => Promise<string> } => {

  const {
    userLocale
  } = useLoggedUser();

  const {t, i18n} = useTranslation('api-error');

  const convertError = async (error: Error): Promise<string> => {
    try {
      const errorData: ErrorData = JSON.parse(error.message);

      if (errorData.fieldErrors) {
        return errorData.fieldErrors.join(',');
      } else if (errorData.message) {
        return errorData.message;
      } else {
        let params = {};
        //debugger;
        if (errorData.errorMessageParams) {
          for (const errorData1 of errorData.errorMessageParams) {
            if (errorData1.toBeTranslated) {
              params[errorData1.key] = await getNestedTranslation(errorData1.value);
            } else if (errorData1.isDate) {
              const parsedDate = parseDateParam(errorData1.value) || errorData1.value;
              params[errorData1.key] = parsedDate;
            } else {
              params[errorData1.key] = errorData1.value;
            }
          }
          //params = errorData.errorMessageParams;
        }
        const msgKey = `${errorData.errorCode}`;
        //console.log(msgKey, params)
        return t(`${msgKey}`, params);
      }
    } catch (err1: any) {
      return err1.message;
    }
  }

  const parseDateParam = (dateStr: string | null | undefined): string | null => {
    if (dateStr===null || dateStr=== undefined) {
      return null;
    }
    const m: Moment = moment.utc(dateStr);
    return formatDate(m.toDate(), userLocale);
  }

  const getNestedTranslation = async (key: string): Promise<string> => {
    let val = t(key);
    if (val !== key) {
      return val;
    }

    // itero tutti i namespace perchè la chiave potrebbe non essere nel ns api-error
    let found = false;
    for (let ns in I18nNamespaces) {
      if (!found) {
        await i18n.loadNamespaces(ns);
        val = t(`${ns}:${key}`);
        //console.log('ns iteration', ns, val)
        if (val !== key) {
          found = true;
        }
      }
    }
    return val;
  }

  return {convertError};
}

export const showError = (t: Function, dispatch: Function, error: Error) => {
  try {
    const errorData: ErrorData = JSON.parse(error.message);
    const params = {};
    if (errorData.errorMessageParams) {
      errorData.errorMessageParams.forEach((p, index) => {
        params[`param${index + 1}`] = p;
      });
    }
    const msgKey = errorData.errorCode;
    if (msgKey === 'error.notFound') {
      params['param1'] = t(convertResourceType(params['param1']));
    }
    const message = t(msgKey, params);
    dispatch(setErrorState(true, message));
  } catch (err1) {
    const msg = error.message ? error.message : error;
    if (msg && msg !== '') {
      dispatch(setErrorState(true, msg));
    } else {
      throw err1;
    }
  }
}

export const createError = (err) => {
  if (err.response) {
    return new Error(JSON.stringify(err.response.data));
  }
  return err;
}

export const createBlobError = async (err) => {
  if (err.response) {
    const errorMsg = await err.response.data.text();
    throw new Error(errorMsg);
  }
  return err;
}

export const useErrorHandler = (): any => {
  const {t} = useTranslation('api-error');
  const dispatch = useDispatch();

  const handleError = (err) => {
    showError(t, dispatch, err);
  };

  const clearError = () => {
    dispatch(setErrorState(false));
  }

  return {handleError, clearError};
}

const convertResourceType = (type: ResourceType): string => {
  return `resource.${type}`;
}
