export type ActivityLogLevel = 'INFO' | 'DETAIL';

export enum ActivityLogCategory {
  EXP = 'EXPENSE_REPORT',
  STF = 'STAFF',
  LVL = 'LEVEL_STR',
  ACC = 'GENERAL_ACC',
  SPL = 'SUPPLIER',
  PRJ = 'PROJECT',
  TPL = 'TRAVEL_POLICY',
  SET = 'SETTING',
  CUR = 'CURRENCY',
  HLP = 'HELP',
  HPR = 'HELP_PROCEDURE'
}

export enum ActivityLogState {
  CS = 'COMPLETED_SUCCESFULLY',
  CW = 'COMPLETED_WITH_WARNINGS',
  CE = 'COMPLETED_WITH_ERRORS'
}

export interface ActivityLogInfo {
  id: number, // Su tabella [MT_ACTIVITY_LOG]
  tenantId: number,
  userId: number,
  activityLevel: ActivityLogLevel,
  activityCategory: ActivityLogCategory,
  activityState: ActivityLogState,
  activityTitleBundleKey: string,
  activityTitleBundleParams?: string[],
  activitySubtitleBundleKey: string,
  activitySubtitleBundleParams?: string[],
  infoMessageBundleKey?: string,
  infoMessageBundleParams?: string[],
  elementCode?: string,
  elementsNum: number,
  batchJobId: number, // Su tabella [MT_BATCH_JOB]
  insertTime: Date
}

export interface ActivityLogInfoWithBatchDetail extends ActivityLogInfo {
  batchName: string,
  filePath: string,
  fileUniqueName: string,
  fileDownloadName: string
}

// tenere allineato al back-end ('GetActivityLogsRequest.java')
export interface GetActivityLogsRequest {
  ids?: number[],
  levels?: ActivityLogLevel[],
  categories?: ActivityLogCategory[],
  states?: ActivityLogState[],
  insertTime?: Date,
  batchId?: number,
  elementCode?: string,
  orderby?: string,
  page?: number,
  pageSize?: number
}
