import {CommonDialog} from "../../base/common-dialog";
import React, {useEffect, useState} from "react";
import {Button, Divider, Skeleton, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {StaffExpenseItem, StaffExpensesSaveReq, User} from "../model";

import * as Yup from "yup";
import {getAvailableTarifExpenses, getStaffExpenses, saveStaffExpenses} from "../Service";
import {formatNumber, parseNumber} from "../../../util/NumberUtil";
import {SingleExpense} from "./SingleExpense";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {TarifDecimalNum} from "../../model";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Add} from "@mui/icons-material";
import {HelpCurrentPage} from "../../../reducers/Help";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {yupMaxNullableString, yupOptionalAmount} from "../../../util/YupUtil";
import {useTranslation} from "react-i18next";
import {useHelp} from "../../help/hook";

type ComponentProps = {
  onClose: Function,
  show: boolean,
  user: User | null
}

export type StaffExpenseFormValue = {
  _id?: number;
  expId: AutocompleteGenericOption | null;
  tpId: AutocompleteGenericOption | null;
  tarif: string;
  notes: string;
}

export type StaffExpensesFormValues = {
  expenses: Array<StaffExpenseFormValue> | null;
};

export const ExpenseList = ({show, onClose, user}: ComponentProps) => {

  const {t} = useTranslation();

  useHelp({
    paths: [
      {
        path: '/modal',
        page: show ? HelpCurrentPage.USER_TARIF : HelpCurrentPage.USER_DETAIL
      }
    ]
  });

  const {user: loggedUser, userLocale: locale} = useLoggedUser();

  const [availableTravelPolicyByExp, setAvailableTravelPolicyByExp] = useState<Map<number, AutocompleteGenericOption[]> | null>(null);
  const [availableExpenses, setAvailableExpenses] = useState<StaffExpenseItem[]>([]);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const validationSchema = Yup.object({
    expenses: Yup.array(
      Yup.object({
        expId: Yup.object()
          .nullable()
          .required(t("validation:required")),
        tpId: Yup.object().nullable(),
        tarif: yupOptionalAmount(t, 5, locale),
        notes: yupMaxNullableString(50, t)
      })
    ).test((expenses) => {
      // controllo che non esistano voci duplicate
      let duplicated = null;
      // expenses.forEach((card1, index1) => {
      //   expenses.forEach((card2, index2) => {
      //     if (index1 !== index2 && card1.cardNum === card2.cardNum) {
      //       duplicated = `Credit card number duplicated: ${card1.cardNum}`;
      //     }
      //   })
      // });
      //
      // if (duplicated) {
      //   return new ValidationError(duplicated, undefined, 'expenses');
      // }

      return true;
    }),
  });

  const handleSaveData = async (values) => {
    if (user) {
      setErrorMsg(null);
      setSaving(true);

      //console.log(values.expenses)

      const data: StaffExpensesSaveReq = {
        lastUpdNum: user.lastUpdateNum,
        expenses: values.expenses.map(elm => ({
          id: elm._id,
          expenseId: elm.expId.id,
          travelPolicyId: elm.tpId ? elm.tpId.id : null,
          tarif: parseNumber(elm.tarif, locale), //TODO: da verificare come recuperare il locale
          notes: elm.notes
        }))
      }

      try {
        await saveStaffExpenses(user.id, data);
        onClose(true);
      } catch (error) {
        setErrorMsg(`Saving error. ${error}`);
      } finally {
        setSaving(false);
      }
    }
  }

  const formMethods = useForm<StaffExpensesFormValues>({
    defaultValues: {expenses: []},
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  const {fields, append, replace, remove} = useFieldArray({
    name: "expenses",
    control: formMethods.control
  });

  useEffect(() => {
    if (show) {
      setErrorMsg(null);
      setLoading(true);
      Promise.all([
        getAvailableTarifExpenses(user ? user.id : 0),
        getStaffExpenses(user ? user.id : 0)
      ]).then(result => {
        let expenses = result[0];
        const staffExpenses = result[1];

        if (expenses && expenses.length > 0) {
          const result = expenses.reduce((r, a) => {
            r[a.id] = r[a.id] || [];
            r[a.id].push(createAutocompleteGenericOption(a.travelPolicyId, a.travelPolicyCode, a.travelPolicyDescription));
            return r;
          }, Object.create(null));
          setAvailableTravelPolicyByExp(result);

          //TODO: da verificare
          expenses = expenses.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);

          setAvailableExpenses(expenses.map(exp => ({
            id: exp.id,
            code: exp.code,
            description: exp.description,
            measureUnit: exp.measureUnit
          })));
        } else {
          setAvailableExpenses([]);
        }

        let mappedExpenses: StaffExpenseFormValue[] = [];
        if (staffExpenses && staffExpenses.length > 0) {
          mappedExpenses = staffExpenses.map(elm => ({
            _id: elm.id,
            expId: createAutocompleteGenericOption(elm.expenseId, elm.expenseCode, elm.expenseDesc),
            tpId: elm.travelPolicyId && elm.travelPolicyId > 0 ?
              createAutocompleteGenericOption(elm.travelPolicyId, elm.travelPolicyCode, elm.travelPolicyDesc) : null,
            /*expId: {id: elm.expenseId, code: elm.expenseCode, description: elm.expenseDesc},
            tpId: elm.travelPolicyId && elm.travelPolicyId > 0 ? {
              id: elm.travelPolicyId,
              code: elm.travelPolicyCode,
              description: elm.travelPolicyDesc
            } : null,

             */
            tarif: formatNumber(elm.tarif, locale, TarifDecimalNum), //TODO: da verificare come recuperare il locale
            notes: elm.notes
          }));
        } else {
          mappedExpenses.push({expId: null, tpId: null, tarif: '', notes: ''});
        }

        formMethods.reset({expenses: mappedExpenses});
      })
        .catch(err => console.log('Fetch staff expenses error', err))
        .finally(() => setLoading(false));
    } else {
      setAvailableTravelPolicyByExp(null);
      setAvailableExpenses([]);
    }
  }, [show, user]);

  const handleCloseDialog = (save: boolean) => {
    if (save) {
      formMethods.handleSubmit(handleSaveData)();
    } else {
      onClose(false);
    }
  }

  const isEmptyRow = (row): boolean => {
    return !row.expId || row.expId === 0;
  }

  const handleAddNewExpense = () => {
    const expenses = formMethods.getValues('expenses');
    const foundEmptyRow = expenses && expenses.find(c => isEmptyRow(c));
    if (!foundEmptyRow) {
      append({expId: null, tpId: null, tarif: '', notes: ''});
    }
  }

  return (
    <CommonDialog
      show={show}
      title={t("detail.items.tarif.title", {ns: "user"})}
      widths={[
        {breakpoint: "md", width: "900px"}
      ]}
      saving={saving}
      onClose={handleCloseDialog}
      errorMsg={errorMsg}
      page={show ? HelpCurrentPage.USER_TARIF : HelpCurrentPage.USER_DETAIL}
    >
      <FormProvider {...formMethods}>
        <form noValidate>
          <>
            {fields && fields.length > 0 ? (
              fields.map((item, index) => (
                <React.Fragment key={item.id}>
                  {loading ? (<Skeleton
                      sx={{
                        my: 2
                      }}
                      className={"expense-skeleton"}
                      variant="rectangular"
                    />
                  ) : (
                    <>
                      <SingleExpense
                        index={index}
                        //remove={(idx) => remove(idx)}
                        remove={(idx) => {
                          const values = formMethods.getValues('expenses');
                          if (values) {
                            replace(values.filter((_, index) => index !== idx));
                          }
                        }}
                        element={item}
                        expenseItems={availableExpenses}
                        availableTravelPolicyByExp={availableTravelPolicyByExp}
                      />
                      {index < fields.length - 1 && <Divider light />}
                    </>
                  )}
                </React.Fragment>
              ))
            ) : (
              <></>
            )}

            {!loading && <Box mt={4}
                              sx={{
                                textAlign: {
                                  xs: 'center',
                                  md: 'left'
                                }
                              }}>
              {/*
                            usare un pulsante con icona e testo non funziona perchè l'allineamento verticale è errato,
                            quindi icona e testo sono state inserite come contenuto del Button e l'allineamento verticale
                            è stato impostato a flex-start
                            https://github.com/mui-org/material-ui/issues/19584
                          */}

              <Button
                variant="contained"
                className={"cta-button-primary-small"}
                disabled={saving}
                onClick={handleAddNewExpense}
              >
                <Add/>
                <Typography ml={1} variant={"button"}>{t("detail.items.tarif.newItem", {ns: "user"})}</Typography>
              </Button>

            </Box>
            }

          </>
        </form>
      </FormProvider>
    </CommonDialog>
  );
}
