import {Alert, Box, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {PageTitle} from "../../layout/page-title";
import {ListFilter, ListFilterType, Project} from "../model";
import IconButton from "@mui/material/IconButton";
import {Add, UploadFile} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {getProjectsList, restoreProjects} from "../Service";
import {ProjectList} from "./ProjectList";
import {Filters} from "./Filters";
import {TooltipZts} from "../../base/tooltip";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {useTranslation} from "react-i18next";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {ImportPage, TemplateFileType} from "../../import/ImportPage";

export const FilterableList = () => {
  const {t} = useTranslation(['project'], {keyPrefix: 'list'});
  const {t: tImport} = useTranslation('import');
  //------
  // State
  //------

  const [allProjects, setAllProjects] = useState<Project[]>([]); // State: lista di tutti i projects: getAllProject
  const [projects, setProjects] = useState<Project[]>([]); // State: lista dei projects da renderizzare
  const [disabledProjects, setDisabledProjects] = useState<Project[]>([]); // State: lista dei projects 'eliminati'
  const [loading, setLoading] = useState<boolean>(false); // State: flag utile alla gestione del rendering in fase di caricamento
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [showActive, setShowActive] = useState<boolean>(true); // State: flag utile a visualizzare solo certi projects (attivi/eliminati)
  const [toggleClicked, setToggleClicked] = useState("lista");
  const [openImportPage, setOpenImportPage] = useState(false);
  const [successfulImport, setSuccessfulImport] = useState<boolean>(false);

  const navigate = useNavigate();
  const {convertError} = useErrorMessage();

  //-----------
  // useEffects
  //-----------

  // Rendering: al primo render, fetch di tutti gli Projects
  useEffect(() => {
    fetchProjects();
  }, []);

  //----------
  // Functions
  //----------

  const fetchProjects = () => {
    let activeProjects: Project[] = [];
    let disabled: Project[] = [];
    setLoading(true);
    getProjectsList()
      .then(response => {
        if (response.data.elements) {
          response.data.elements.filter((project: Project) => {
            if (project.state === 'EF') {
              activeProjects.push(project);
            } else if (project.state === 'AA') {
              disabled.push(project);
            }
          });
          setAllProjects(response.data.elements);
          setProjects(activeProjects);
          setDisabledProjects(disabled);
        }
      })
      .catch((error) => {
        convertError(createError(error))
          .then((msg) => setFetchError(msg))
      })
      .finally(() => setLoading(false))
  }

  const handleNewProjectClicked = () => {
    navigate('edit/-1'); // Stessa route dell'editing, però con project code = -1
  }

  // Handler: apertura pagina dettaglio singolo Project
  const openItemHandler = (id: number) => {
    navigate(`/projects/detail/${id}`);
  }

  const onRestoreItemHandler = (id: number) => {
    if (id) {
      restoreProjects(id)
        .then(() => {
          fetchProjects();
        })
    }
  }

  // Handler: gestione cambiamenti dei filtri -> per ogni cambiamento ai filtri, handleFilterChange(filters: ListFilter[])
  const handleFilterChange = (filters: ListFilter[]) => {
    let newProjects = allProjects;

    if (newProjects && newProjects.length > 0) {
      filters
        .filter((filter: ListFilter) => filter.enabled && filter.value)
        .forEach((filter: ListFilter) => {
          switch (filter.type) {
            case ListFilterType.SEARCH_TEXT:
              newProjects = newProjects.filter((project: Project) =>
                project.code.toUpperCase().indexOf(filter.value.toUpperCase()) !== -1 ||
                project.description.toUpperCase().indexOf(filter.value.toUpperCase()) !== -1);
              break;
          }
        });
    }
    let filteredDisableProjects = newProjects.filter((project) => project.state === 'AA');
    let filteredActiveProjects = newProjects.filter((project) => project.state === 'EF');
    setProjects(filteredActiveProjects);
    setDisabledProjects(filteredDisableProjects);
  }

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView !== null) {
      setToggleClicked(nextView);
      setShowActive(!showActive);
    }
  }

  const handleImportPageClose = () => {
    setOpenImportPage(false);
    if (successfulImport) {
      fetchProjects();
    }
  }

  const handleImportButtonClicked = () => {
    setOpenImportPage(true);
  }

  //----------------
  // React Component
  //----------------

  return (
    <Box
      width={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      mx={"auto"}
    >
      {/*Alert nel caso in cui si incontrino problemi durante la fase di fetching dei dati*/}
      {
        fetchError &&
        <Alert
          severity={"error"}
          variant={"filled"}
          sx={{mb: 4}}
          onClose={() => setFetchError(null)}
        >
          Error retrieving data! Please retry later.
        </Alert>
      }

      {/*Titolo pagina*/}
      <PageTitle
        title={
          <Stack direction={"row"} alignItems={"center"}>
            {
              showActive ?
                (
                  <>
                    <Typography variant={"h3"} mr={1}>{t('title')}</Typography>
                    <Box className={"counter-primary"}>
                      <Typography variant={"button"}>{projects ? projects.length : 0}</Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography variant={"h3"} mr={1}>{t('title')}</Typography>
                    <Typography variant={"h4"} children={`(${t("titleParam")})`} sx={{color: "#14c6d5"}}/>
                    <Box className={"counter-primary"} ml={1}>
                      <Typography variant={"button"}>{disabledProjects ? disabledProjects.length : 0}</Typography>
                    </Box>
                  </>
                )
            }

            <Box flexGrow={1}/>
            <ToggleButtonGroup
              exclusive={true}
              value={toggleClicked}
              size={"small"}
              onChange={handleChange}
              color={"primary"}
            >
              <ToggleButton
                value={"lista"}
                className={"ztsToggle"}
              >
                <TooltipZts
                  title={t('button.showList')}
                  placement={"bottom"}
                  enterDelay={400}
                >
                  <ViewModuleIcon fontSize={"small"}/>
                </TooltipZts>

              </ToggleButton>
              <ToggleButton
                value={"cestino"}
                className={"ztsToggle"}
              >
                <TooltipZts
                  title={t('button.showTrash')}
                  placement={"bottom"}
                  enterDelay={400}
                >
                  <DeleteOutlineIcon fontSize={"small"}/>
                </TooltipZts>

              </ToggleButton>

            </ToggleButtonGroup>
          </Stack>
        }
      />

      <Box
        mt={1}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        columnGap={1}
      >
        {/*Filtri*/}
        <Filters onFilterChange={handleFilterChange}/>

        <Box flexGrow={1}/>

        <TooltipZts
          title={tImport('tooltip.projects')}
          placement={"bottom"}
          enterDelay={400}
        >
          <IconButton
            color="primary"
            className={"edit-button"}
            id={"import-button"}
            sx={{
              opacity: showActive ? 1 : 0.4,
              cursor: showActive ? "pointer" : "default"
            }}
            disabled={!showActive}
            onClick={handleImportButtonClicked}
          >
            <UploadFile/>
          </IconButton>
        </TooltipZts>

        {/* Pulsanti: Nuovo conto contabile */}
        <TooltipZts
          title={t('button.newProject')}
          placement={"bottom"}
          enterDelay={400}
        >
          <IconButton
            id={"new-proj-button"}
            color="primary"
            className={"edit-button"}
            sx={{
              opacity: showActive ? 1 : 0.4,
              cursor: showActive ? "pointer" : "default"
            }}
            disabled={!showActive}
            onClick={handleNewProjectClicked}
          >
            <Add/>
          </IconButton>
        </TooltipZts>

      </Box>

      {/*Lista dei commesse*/}
      <Box
        id={"infinite-scroll-target"}
        overflow={"auto"}
        flexGrow={1}
      >
        {
          showActive ?
            (
              <ProjectList props={{
                projects: projects,
                loading: loading,
                onOpen: openItemHandler,
                onRestore: onRestoreItemHandler,
                active: true
              }}/>
            ) :
            (
              <ProjectList props={{
                projects: disabledProjects,
                loading: loading,
                onOpen: openItemHandler,
                onRestore: onRestoreItemHandler,
                active: false
              }}/>
            )
        }
      </Box>

      <ImportPage
        title={tImport('list.titleImport.projects')}
        onClose={handleImportPageClose}
        show={openImportPage}
        save={false}
        onImport={null}
        onDownload={null}
        templateFileType={TemplateFileType.PROJECT}
        notifySuccessfulImport={() => setSuccessfulImport(true)}
      />

    </Box>
  );
}
