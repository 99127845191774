// React
import React from "react";

// Libs
import { Logger } from 'react-logger-lib';
import Slf4jsLog from "../../../util/Logger";

// Custom
import {TravelPolicyExpense, TravelPolicyWithExpenses} from "../model";
import {Typography} from "@mui/material";
import {
  TravelPolicyExpenseCard,
  TravelPolicyExpenseCardActionHandler
} from "./TravelPolicyExpenseCard";
import {TravelPolicyNewExpenseCard} from "./TravelPolicyNewExpenseCard";
import {useTranslation} from "react-i18next";


type TravelPolicyExpensesProps = {
  travelPolicy: TravelPolicyWithExpenses
  editable: boolean
  onCardAction: TravelPolicyExpenseCardActionHandler
}
export const TravelPolicyExpenses: React.FC<TravelPolicyExpensesProps> = ({
  travelPolicy,
  editable,
  onCardAction
}) => {

  const LOG: Slf4jsLog = Logger.of('ZTS.TravelPolicies.TravelPolicyExpenses');

  const onNewExpense = () => {
    LOG.trace('onNewExpense');
    // @ts-ignore
    let tpExp: TravelPolicyExpense = {};
    onCardAction({action:'create', expense: tpExp});
  }

  const {t: tCommon} = useTranslation('travel-policy', {keyPrefix: 'common.label'});
  const sectionLab = tCommon('expEnabled');

  return (
    <>
      <div style={{display:'flex', flexWrap:'wrap', flexBasis: '270px'}}>
        <div style={{width:'100%', marginBottom:'20px', marginTop:'20px'}}>
          <Typography variant='h6' textTransform='uppercase'>{sectionLab}</Typography>
        </div>
        {travelPolicy.expenses.sort((a, b) => {
          return a.expenseDescription.toUpperCase().localeCompare(b.expenseDescription.toUpperCase())
        }).map(exp =>
          <div key={exp.id} style={{marginRight:'15px', marginBottom: '20px'}}>
            <TravelPolicyExpenseCard
              expense={exp}
              editable={editable}
              onAction={onCardAction} />
          </div>
        )}
        {editable &&
          <div style={{marginRight:'15px', marginBottom: '20px'}}>
            <TravelPolicyNewExpenseCard onAction={onNewExpense} />
          </div>
        }
      </div>

    </>
  );

}
