import React, {useState} from "react";
import {BoxProps, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {PopupContentType} from "../../license-popup/model";
import {useTranslation} from "react-i18next";
import {LicensePopup} from "../../license-popup";

interface ComponentProps extends BoxProps {
  type?: PopupContentType,
  clickable?: boolean
}

export const PremiumIcon = ({...props}: ComponentProps) => {
  const {t} = useTranslation('license', {keyPrefix: 'pro.menu'});
  const {t: tBase} = useTranslation('base', {keyPrefix: 'premium-icon'})
  const theme = useTheme();

  const {type, clickable} = {...props};

  let popupTitle = type ? type : tBase('altTitle');

  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  const openPopup = () => {
    if (type && clickable) {
      setPopupOpen(true);
    }
  }

  const closePopup = () => {
    setPopupOpen(false);
  }

  const fontSize = theme.typography.subtitle2.fontSize;

  return (
    <>
      <Box
        {...props}
        className={"pro-feature-pill"}
        sx={{cursor: type && clickable ? 'pointer' : 'default'}}
        onClick={openPopup}
      >
        <Typography
          variant={"subtitle2"}
          textTransform={"uppercase"}
          fontSize={`${fontSize} !important`}
        >pro</Typography>
      </Box>
      {
        type &&
        <LicensePopup open={popupOpen} isPro={false} closePopup={closePopup} titlePro={t(`${popupTitle}.title`)} type={type} />
      }
    </>
  );
}
