import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Stack, Typography} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {ReactChild, useState} from "react";
import {useTranslation} from "react-i18next";
import {DigitalStorageCarouselContent} from "./contents/DigitalStorageCarouselContent";
import {CreCardImportCarouselContent} from "./contents/CreCardImportCarouselContent";
import {ExpNoteAccountingCarouselContent} from "./contents/ExpNoteAccountingCarouselContent";
import {ProjectsManagementCarouselContent} from "./contents/ProjectsManagementCarouselContent";
import {CustomReportsCarouselContent} from "./contents/CustomReportsCarouselContent";
import {TrainingCarouselContent} from "./contents/TrainingCarouselContent";
import {useLicense} from "../../../hooks/useLicense";
import {GenericPromoPopupContent} from "./contents/GenericPromoPopupContent";
import {CURRENT_PROMO_EXPIRATION_DATE} from "../../promo-popup";

export const DashboardCarousel = () => {
  const {t} = useTranslation('dashboard', {keyPrefix: 'admin.carousel'});
  const {isProfessional} = useLicense();
  const professional = isProfessional();
  const [currentSlide, setCurrentSlide] = useState(0);

  const storeUrl = 'https://www.zucchetti.it/store/cms/ztravel-smart/245-software-nota-spese-moduli.html';
  const storeBuyUrl = 'https://www.zucchetti.it/store/cms/software-nota-spese-ztravel-smart-acquista.html';

  const contents: ReactChild[] = [
    <DigitalStorageCarouselContent storeUrl={storeUrl}/>,
    <CreCardImportCarouselContent storeUrl={storeUrl}/>
  ];
  if (!professional) {
    contents.push(
      <ExpNoteAccountingCarouselContent storeUrl={storeBuyUrl}/>,
      <ProjectsManagementCarouselContent storeUrl={storeBuyUrl}/>,
      <CustomReportsCarouselContent storeUrl={storeBuyUrl}/>
    );
  }

  const promoNotExpired = new Date(Date.now()).getTime() <= CURRENT_PROMO_EXPIRATION_DATE.getTime(); // Se la data corrente precede la data di scadenza della promozione
  if (promoNotExpired) {
    contents.push(<GenericPromoPopupContent/>);
  }

  contents.push(<TrainingCarouselContent storeUrl={storeUrl}/>);

  const handleShowPrevSlide = () => {
    setCurrentSlide(prev => prev - 1);
  }

  const handleShowNextSlide = () => {
    setCurrentSlide(prev => prev + 1);
  }

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="h5">{t("title")}</Typography>
        <Box>
          <KeyboardArrowLeft
            cursor="pointer"
            style={{color: "#8c8c8c"}}
            onClick={handleShowPrevSlide}
          />
          <KeyboardArrowRight
            cursor="pointer"
            style={{color: "#8c8c8c"}}
            onClick={handleShowNextSlide}
          />
        </Box>
      </Stack>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={12000}
        onChange={setCurrentSlide}
        selectedItem={currentSlide}
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        stopOnHover={true}
        transitionTime={500}
      >
        {contents}
      </Carousel>
    </Stack>
  );
}
