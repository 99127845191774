import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {HelpCurrentPage, setCurrentPage} from "../../../reducers/Help";
import {useEffect} from "react";

export interface PathHelp {
  path: string,
  page: HelpCurrentPage,
  exactMatch?: boolean
}

type HookProps = {
  paths: PathHelp[] | null
}

export const useHelp = ({paths}: HookProps) => {

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {

    if (!paths) {
      return;
    }

    const path = location.pathname;

    //console.log('path', path);

    if (path) {
      const foundPath = paths.find(p => p.exactMatch ? p.path === path : path.indexOf(p.path) !== -1);
      let page;
      if (foundPath) {
        page = foundPath.page;
      } else {
        const modalPath = paths.find(p => p.path === '/modal');
        if (modalPath) {
          page = modalPath.page;
        }
      }
      if (page) {
        dispatch(setCurrentPage(page));
      }
    }
  }, [dispatch, paths, location.pathname])

  return null;

}
