import { Project, SaveProject, StaffByProjectIdResponse, StaffProjectResponse } from './model';
import { getStateCode } from '../model';
import { getApiUrl } from '../../config/environment';
import { createError } from '../../util/ErrorUtil';

const axios = require('axios').default;

export const getProjects = async (): Promise<Project[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/projects`);
  //console.log(resp);
  return resp.data;
}

export const getActiveProjects = async (): Promise<Project[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/projects?state=${getStateCode(true)}`);
  //console.log(resp);
  return resp.data.elements;
}

export const getUserProjects = async (staffId: number): Promise<StaffProjectResponse[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/staff/${staffId}/projects`);
  //console.log(resp);
  return resp.data.elements;
}

export const saveAllProjects = async (staffId: number, projects) => {
  try {
    const apiUrl = getApiUrl();
    await axios.post(`${apiUrl}/staff/${staffId}/projects`, projects);
  } catch (err: any) {
    throw createError(err);
  }
}

export const getProjectsList = () => {
  const apiUrl = getApiUrl();
  return axios.get(`${apiUrl}/projects`);
}

export const getProjectById = (id: string) => {
  const apiUrl = getApiUrl();
  return axios.get(`${apiUrl}/project/${id}`);
}

export const updateProject = (project: SaveProject) => {
  const apiUrl = getApiUrl();
  return axios.put(`${apiUrl}/project/${project.id}`, project);
}

export const insertProject = async (project: SaveProject): Promise<number> => {
  const apiUrl = getApiUrl();
  try {
    const resp = await axios.post(`${apiUrl}/projects`, project);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const disableProjects = (projectId: number) => {
  const apiUrl = getApiUrl();
  return axios.put(`${apiUrl}/projects/disable`, {projectId: projectId});
}

export const restoreProjects = (projectId: number) => {
  const apiUrl = getApiUrl();
  return axios.put(`${apiUrl}/projects/restore`, {projectId: projectId});
}

export const getStaffsByProjectId = async (projectId: string): Promise<StaffByProjectIdResponse[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/project/${projectId}/staffs`);
  return resp.data.elements;
}

