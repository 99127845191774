import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const NewExpensePillPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>
          Attraverso questa funzionalità, disponibile nella versione Professional di ZTravel Smart, è possibile associare ad un allegato spese di diversa natura. Se prendiamo ad esempio la fattura di un hotel è possibile dividerne l’importo
          in diverse voci di spesa: pernottamento, cena, parcheggio ecc. In questo modo l’azienda può avere il massimo dettaglio di compilazione e gestire tutte le casistiche contabili e fiscali delle singole voci di spesa presenti in un unico documento/scontrino.
        </p>
      );
    default:
      return (
        <p>Vuoto</p>
      );
  }
}
