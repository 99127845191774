import {Skeleton} from "@mui/material";
import {User} from "../model";
import {UserCard} from "./UserCard";
import {UserCardWrapper} from "./UserCardWrapper";
import Box from "@mui/material/Box";
import {InfiniteScrollZts} from "../../base/infinite-scroll";

type ComponentProps = {
  users: User[],
  loading: boolean,
  onOpen: Function,
  onToggleSelect: Function,
  onEnable: Function,
  onDisable: Function,
  selectedItems: number[]
}

export const UserList = ({users, loading, onOpen, onToggleSelect, onEnable, onDisable, selectedItems}: ComponentProps) => {

  return (
    <>

      {/*Skeleton*/}
      {loading && <Box
        display={"flex"}
        flexWrap={"wrap"}
        mt={3}
        maxWidth={"1200px"}
      >
        {[1, 2, 3].map(idx => {
          return <UserCardWrapper
            key={idx}
            child={
              <Skeleton
                animation="pulse"
                variant="rectangular"
                width="100%"
                height="216px"/>
            }
          />
        })}
      </Box>}

      {!loading && <InfiniteScrollZts
        scrollableTargetId={"infinite-scroll-target"}
        elements={users}
        renderElement={(user: User) => {
          return <UserCardWrapper
            key={user.id}
            child={
              <UserCard
                user={user}
                onOpen={onOpen}
                onToggleSelect={onToggleSelect}
                onEnable={onEnable}
                onDisable={onDisable}
                selected={selectedItems.some(i => i === user.id)}
                selectMode={selectedItems.length > 0}
              />
            }
          />
        }}
        style={{
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "1900px"
        }}
      />}

    </>
  );
}
