import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export function ReportCustom() {
  const {t} = useTranslation('report');
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Typography variant={'h3'} color={'#c2c0c2'} justifySelf={'center'} mb={1}>Coming soon!</Typography>
    </Box>
  );
}
