import { FileUploadReq, FileUploadResp } from '../../model';
import { getApiUrl } from '../../../config/environment';
import { default as axios } from 'axios';
import { createError } from '../../../util/ErrorUtil';
import {
  ImportConfigDto, ImportCreCardMovRequest, LinkPostImportCreCardMovRequest,
  ParseAndFilterUploadedFileRequest,
  SaveImportConfigRequest,
  SaveImportConfigResponse,
  ValidateColumnRequest, ValidateColumnResp
} from './model';

export const uploadImportFile = async (req: FileUploadReq): Promise<FileUploadResp> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/creCardMov/import/upload`, req);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const uploadFileCsv = async (request: FileUploadReq): Promise<FileUploadResp> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/uploads/uploadFileCsv`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getUploadedFileRows = async (request: ParseAndFilterUploadedFileRequest): Promise<string[][]> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/creCardMov/import/configuration`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const parseCsvAndFindSep = async (uploadKey: string): Promise<{ separator: string, rows: string[][] }> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/creCardMov/import/configuration/csv/first`, {uploadKey: uploadKey});
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const validateColumn = async (request: ValidateColumnRequest): Promise<ValidateColumnResp> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/creCardMov/import/configuration/validate`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const getImportConfig = async (): Promise<ImportConfigDto> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.get(`${apiUrl}/creCardMov/import/configuration`);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const saveImportConfig = async (request: SaveImportConfigRequest): Promise<SaveImportConfigResponse> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.put(`${apiUrl}/creCardMov/import/configuration/save`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const importCreCardMovList = async (request: ImportCreCardMovRequest): Promise<number> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/creCardMov/import`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

export const launchPostImportLinking = async (request: LinkPostImportCreCardMovRequest): Promise<number> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/creCardMov/afterimport`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}


export const getColumnLabelByArrayIndex = (index: number): string => {
  if (index == null || index < 0) {
    return '';
  }
  const aIndex = 65;
  const alphabetLen = 26;
  let res = '';

  const quotient = Math.floor(index / alphabetLen);
  const remainder = index % alphabetLen;

  if (quotient > 0) {
    res = getColumnLabelByArrayIndex(quotient - 1);
  }
  res += String.fromCharCode(aIndex + remainder);
  return res;
}

export const getArrayIndexByColumnLabel = (columnLabel: string): number => {
  let numericIndex = 0;

  for (let i = 0; i < columnLabel.length; i++) {
    const currentChar = columnLabel.toUpperCase().charCodeAt(i) - 64; // 'A' = 1 anziché 0, serve per effettuare il calcolo in maniera corretta
    const positionMultiplier = 26 ** i;
    numericIndex += currentChar * positionMultiplier;
  }

  return numericIndex - 1;
}

export const uploadCreCardMovConfig = async (request: FileUploadReq): Promise<FileUploadResp> => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post(`${apiUrl}/uploads/crecardmovconfig`, request);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}
