import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import TableSortLabel from "@mui/material/TableSortLabel";
import React, {useEffect, useState} from "react";
import {ZtsTableColumn} from "./model";

type ComponentProps = {
  columns: ZtsTableColumn[],
  rows: any[],
  extraClassName?: Map<number, string>,
  forcedSort?: { orderBy: string, orderDir: 'asc' | 'desc' },   // ordinamento controllato da fuori
  notifySortChanged?: (orderBy: string, orderDir: 'asc' | 'desc') => void,  // notifica al componente padre che è cambiato l'ordinamento
  sortExternal?: boolean, // se true NON ordina mai internamente (da usare se passo una lista già ordinata)
  rowClickHandler?: Function,
  showHeader?: boolean,
  additionalHeader?: JSX.Element
  initialSort?: { orderBy: string, orderDir: 'asc' | 'desc' } // valori di default ordinamento,
  tableCssClass?: string,
  rowDoubleClickHandler?: (id) => void,
  stickyHeader?: boolean
}

export const ZtsTable = (props: ComponentProps) => {

  const {
    columns,
    rows,
    extraClassName,
    forcedSort,
    notifySortChanged,
    sortExternal,
    rowClickHandler,
    showHeader = true,
    additionalHeader,
    initialSort,
    tableCssClass,
    rowDoubleClickHandler,
    stickyHeader,
  } = props;

  const [localRows, setLocalRows] = useState(rows);

  const [orderBy, setOrderBy] = useState(initialSort ? initialSort.orderBy : 'id');
  const [orderDir, setOrderDir] = useState<'asc' | 'desc'>(initialSort ? initialSort.orderDir : 'asc');

  const tmpOrderBy = forcedSort && forcedSort.orderBy ? forcedSort.orderBy : orderBy;
  const tmpOrderDir = forcedSort && forcedSort.orderDir ? forcedSort.orderDir : orderDir;

  useEffect(() => {
    if (!rows || rows.length <= 1) {
      setLocalRows(rows);
    } else {
      const copyValues = rows.slice();
      sortInPlace(copyValues);
      setLocalRows(copyValues);
    }
  }, [rows]);

  useEffect(() => {
      if (sortExternal) {
        return;
      }

      if (localRows.length <= 1) {
        return;
      }
      const copyValues = localRows.slice();
      sortInPlace(copyValues);
      setLocalRows(copyValues);
    },
    [tmpOrderBy, tmpOrderDir]
  );

  const sortInPlace = (values) => {
    values.sort(rowComparator);
  }

  const rowComparator = (row1, row2) => {
    const v1 = row1[tmpOrderBy];
    const v2 = row2[tmpOrderBy];

    if (v1 === undefined && v2) {
      return tmpOrderDir === 'asc' ? -1 : 1;
    }
    if (v1 && v2 === undefined) {
      return tmpOrderDir === 'asc' ? 1 : -1;
    }
    if (v1 === undefined && v2 === undefined) {
      return 0;
    }

    if (v1 === v2) return 0;
    let result;
    if (v1 < v2) {
      result = -1;
    } else {
      result = +2;
    }
    if (tmpOrderDir === 'desc') {
      result *= -1;
    }
    return result;
  }

  const onRequestSort = (event, column) => {
    let newDir: 'asc' | 'desc' = 'asc';
    if (column === tmpOrderBy && tmpOrderDir === 'asc') {
      // Se la colonna coincide con quella corrente
      // ed al momento ho un ordinamento asc
      // faccio flip
      newDir = 'desc';
    }

    if (notifySortChanged) {
      notifySortChanged(column, newDir);
    } else {
      setOrderBy(column);
      setOrderDir(newDir);
    }
  };

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const getTableHeader = (column: ZtsTableColumn): JSX.Element => {
    return column.header ? (
      column.header
    ) : (
      <Typography
        className={"table-header"}
        textTransform={"uppercase"}
        variant={"h6"}
      >
        {column.label}
      </Typography>
    );
  }

  const onRowClicked = (id) => {
    if (rowClickHandler) {
      rowClickHandler(id);
    }
  }

  const onRowDoubleClicked = (e: React.MouseEvent<HTMLTableRowElement>, row) => {
    if (rowDoubleClickHandler) {
      rowDoubleClickHandler(row);
    }
  }

  const preventTextSelectionOnDoubleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    // se è stato definito un evento per il doppio clic sulla riga è necessario bypassare
    // il doppio clic sulle singole celle altrimenti si otterrebbe l'effetto di selezionare
    // il testo nella cella nonostante l'esecuzione dell'handler del doppio clic sulla riga
    if (rowDoubleClickHandler && e.detail === 2) {
      e.preventDefault();
    }
  }

  let className = showHeader ? "" : "table-without-header ";
  if (tableCssClass) {
    className += tableCssClass;
  }

  return (
    <TableContainer sx={stickyHeader ? {overflow: 'auto'} : null}>
      <Table
        className={className}
        size={'medium'}
        sx={stickyHeader ? {overflowX: 'auto'} : null}
        stickyHeader={stickyHeader}
      >
        <TableHead>
          <TableRow>
            {columns.map((c, index) => (
              <TableCell
                key={c.id}
                sortDirection={tmpOrderBy === c.id ? tmpOrderDir : false}
                align={c.align}
                valign={'middle'}
                sx={c.style ? c.style : (c.headerStyle ? c.headerStyle : {})}
              >
                {c.unsortable && getTableHeader(c)}
                {!c.unsortable && <TableSortLabel
                  active={tmpOrderBy === c.id}
                  direction={tmpOrderBy === c.id ? tmpOrderDir : 'asc'}
                  onClick={createSortHandler(c.id)}
                  sx={c.sortIconAlignment ? {alignItems: c.sortIconAlignment} : {}}
                >
                  <span>{getTableHeader(c)}</span>
                </TableSortLabel>
                }
              </TableCell>
            ))}
          </TableRow>

          {additionalHeader}

        </TableHead>
        <TableBody>
          {
            localRows.length > 0 &&
            localRows.map(r => {
              const hasChildren = r.childrenRows;
              let className = '';
              if (extraClassName && extraClassName.has(r.check)) {
                // @ts-ignore
                className = extraClassName.get(r.check);
              }
              if (hasChildren) {
                className += 'aggregate-row';
              }
              return hasChildren ? (
                  <TableRow hover
                            onClick={() => onRowClicked(r.check)}
                            onDoubleClick={(e: React.MouseEvent<HTMLTableRowElement>) => onRowDoubleClicked(e, r)}
                            key={r.id}
                            sx={{
                              cursor: rowClickHandler ? "pointer" : "default"
                            }}
                            className={className}
                  >
                    <TableCell colSpan={columns.length}>
                      {r.childrenRows}
                    </TableCell>
                  </TableRow>
                )
                : (
                  <TableRow hover
                            onClick={() => onRowClicked(r.check)}
                            onDoubleClick={(e: React.MouseEvent<HTMLTableRowElement>) => onRowDoubleClicked(e, r)}
                            key={r.id}
                            sx={{
                              cursor: rowClickHandler ? "pointer" : "default"
                            }}
                            className={extraClassName && extraClassName.has(r.check) ? extraClassName.get(r.check) : ""}
                  >
                    {columns.map((c, index) => (
                      <TableCell
                        sx={c.style ? c.style : (c.rowsStyle ? c.rowsStyle : {})}
                        key={`${r.id}_${c.id}`}
                        align={c.align ? c.align : 'left'}
                      >
                        {r.hideAggregate && c.hideOnAggregate ? (
                          <></>
                        ) : (
                          <span className={c.displayOnHover && c.displayOnHover(r[c.id]) ? "show-cell-on-hover" : ""}>
                          <Typography
                            onMouseDown={(e: React.MouseEvent<HTMLSpanElement>) => preventTextSelectionOnDoubleClick(e)}
                            variant={c.typography ? c.typography : "body2"}
                            color={c.cellColor ? c.cellColor : 'default'}
                            className={c.cssOnAggregate && r.hideAggregate ? c.cssOnAggregate : ""}
                          >
                            {c.formatter ? c.formatter(r[c.id], r) : r[c.id]}
                          </Typography>
                        </span>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
