import {Box, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import {ArrowBack} from "@mui/icons-material";
import {HelpPageName} from "../../../../../reducers/Help";
import {getPageContent} from "../../../Service";
import {useLoggedUser} from "../../../../../hooks/useLoggedUser";
import {useTranslation} from "react-i18next";

interface ComponentProps {
  page: string,
  onClose: Function
}

export const HelpSearchResultContent = ({page, onClose}: ComponentProps) => {
  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');

  const {t} = useTranslation('help');
  const {userLocale} = useLoggedUser();

  const asyncGetAndSetPageContent = async (): Promise<void> => {
    const head = t('label.helpTitle', {pageName: t(`pageName.${HelpPageName[page]}`)});
    const content = await getPageContent(page, userLocale);
    if (content) {
      setTitle(head);
      setBody(content);
    }
  }

  useEffect(() => {
    asyncGetAndSetPageContent().then();
  }, []);

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        alignContent={"center"}
        my={0.6}
      >
        <IconButton
          disableRipple={true}
          sx={{
            flexBasis: '40px',
            justifyContent: 'flex-start'
          }}
          size={"small"}
          onClick={() => onClose()}
        >
          <ArrowBack fontSize={"small"}/>
        </IconButton>
        <Typography variant={"h4"} mb={1} mt={0.5} noWrap={true} textOverflow={'ellipsis'}>{title}</Typography>
      </Stack>
      <Box
        flexGrow={1}
        height={"calc(100% - 80px)"}
        overflow={"auto"}
        sx={{cursor: "default"}}
        m={1.5}
        className={"no-drag-area"}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: body
          }}
        >
        </div>
      </Box>
    </>
  );
}
