import {CommonDialog} from "../../../../../base/common-dialog";
import React, {useEffect, useState} from "react";
import {
  createAutocompleteCurrencyOption,
  DocumentType,
  ExpenseItemType,
  ExpNoteExpenseFull,
  getDocumentTypeLabelKey,
  getPayModeLabelKey,
  PayMode,
  useCommonLabel
} from "../../../../expense/model";
import {ExpenseEdit} from "../../../../expense/ExpenseEdit";
import {
  createAutocompletePlaceOption,
  ExpenseDocumentSaveRequest,
  ExpenseDocumentSaveResponse,
  ExpenseDocumentSingleExp,
  ExpenseRoute,
  ExpNoteCreCardMov,
  ExpNoteStateFE,
  getExpNoteFEState,
  OcrResult,
  SingleExpenseSaveRequest,
  SkeletonRowHeight
} from "../../../../model";
import {FormProvider, useForm} from "react-hook-form";
import {
  expenseValidationSchema,
  NewExpenseAdditionalExpFormValue,
  NewExpenseAttachFormValue,
  NewExpenseColleagueFormValue,
  NewExpenseExpItem,
  NewExpenseFormValues,
  NewExpenseGuestFormValue,
  newExpenseInitValues,
  NewExpenseRouteFormValue
} from "../../../../expense/validation";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";
import {formatNumber, parseNumber} from "../../../../../../util/NumberUtil";
import {CarRouteKmDecimalNum, Currency, ExchangeDecimalNum, TarifDecimalNum} from "../../../../../model";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../../../../base/autocomplete/model";
import {getCurrencies} from "../../../../../../util/BaseService";
import {getTravelPolicyById, loadTravelPolicyExpenseById} from "../../../../../travel_policies/Service";
import {Box, Grid, Skeleton, Typography} from "@mui/material";
import {MimeType} from "../../../../../base/file-upload/model";
import {useErrorMessage} from "../../../../../../util/ErrorUtil";
import {AttachmentPanel} from "./AttachmentPanel";
import {
  deleteExpenseDocument,
  deleteSingleExpense,
  saveExpenseDocument,
  saveSingleExpense,
  updateExpenseDocument,
  updateSingleExpense
} from "../../../../Service";
import IconButton from "@mui/material/IconButton";
import {DeleteForever} from "@mui/icons-material";
import {useExpNoteDetail} from "../../../hooks/useExpNoteDetail";
import {getStaffCreditCards} from "../../../../../users/Service";
import {StaffCreditCard} from "../../../../../users/model";
import {useLoggedUser} from "../../../../../../hooks/useLoggedUser";
import {useConfirm} from "../../../../../../hooks/useConfirm";
import {getSupplierById} from "../../../../../suppliers/Service";
import {Supplier} from "../../../../../suppliers/model";
import {useExpNoteList} from "../../../../hooks/useExpNoteList";
import {ExpNoteListRole} from "../../../../../../reducers/ExpNoteList";
import {CompleteCreCardMovDto} from "../../../../../credit-card-mov/model";
import {getCreCardMovById} from "../../../../../credit-card-mov/Service";
import {useExpenseForm} from "../../../../../../hooks/useExpenseForm";

import {Logger} from "react-logger-lib";
import SimpleJsLog from "../../../../../../util/Logger";

type ComponentProps = {
  show: boolean,
  onClose: (updated: boolean, disconnectMovement?: boolean) => void,
  expenses: ExpNoteExpenseFull[] | null,
  create?: boolean,
  isReadonly: boolean,
  movement?: CompleteCreCardMovDto
}


const LOG: SimpleJsLog = Logger.of('ZTS.expnote.ExpNoteExpenseDetail');


export const ExpNoteExpenseDetail = ({show, onClose, expenses, create, isReadonly, movement}: ComponentProps) => {

  const {expNote, expenses: expNoteExpenses, creditCardMovs} = useExpNoteDetail();

  const {user, userLocale: locale, companyCurrencyCode, companyDecimalNum} = useLoggedUser();

  const {
    updatePayMode,
  } = useExpenseForm();

  const [saving, setSaving] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [announcementMsg, setAnnouncementMsg] = useState<string | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [ocrResult, setOcrResult] = useState<OcrResult | null>(null);

  const {t} = useTranslation(['exp-note', 'validation', 'api-error', 'common']);

  const {getLabel} = useCommonLabel();
  const {convertError} = useErrorMessage();

  const {confirm} = useConfirm();

  const {
    currentRole
  } = useExpNoteList();

  const isAdmin = currentRole && currentRole === ExpNoteListRole.ADMIN;

  const formMethods = useForm<NewExpenseFormValues>({
    resolver: yupResolver(expenseValidationSchema(t, locale, companyDecimalNum, companyCurrencyCode)),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  useEffect(() => {
    if (expenses) {
      setResetting(true);
      setOcrResult(null);
      getFormValuesFromExpenses(expenses)
        .then(res => formMethods.reset(res))
        .finally(() => setResetting(false));
    }
    if (create) {
      setOcrResult(null);
      setResetting(true);
      initializeNewExpenseForm()
        .then(form => formMethods.reset(form))
        .catch(err => { throw new Error('Error initializing data', err) })
        .finally(() => setResetting(false));
    }
  }, [expenses, create]);

  const initializeNewExpenseForm = async (): Promise<NewExpenseFormValues> => {
    let form: NewExpenseFormValues = {...newExpenseInitValues};
    if (movement) {
      form.creCardMov = movement;
      form.compDate = movement.payDate;
      form.compEndDate = movement.payDate;

      const currencies = await getCurrencies();
      const movCurrency = currencies.find(c => c.code === movement.currency);
      if (movCurrency) {
        form.currency = createAutocompleteCurrencyOption(movCurrency.id || -1, movCurrency.code, movCurrency.decimalNum);
      }

      form.amount = formatNumber(movement.currAmount, locale, movCurrency ? movCurrency.decimalNum : companyDecimalNum);
      form.exchange = formatNumber(movement.exchange, locale, ExchangeDecimalNum);
      form.amount = formatNumber(movement.currAmount, locale, movCurrency ? movCurrency.decimalNum : companyDecimalNum);

      const staffCards = expNote ? await getStaffCreditCards(expNote.staffId) : [];
      const card = staffCards.find(c => c.cardNum === movement.creCardNum);
      if (card) {
        const label = `${getLabel(getPayModeLabelKey(PayMode.CARD))} ${card.cardNum}`;
        const payModeOption = createAutocompleteGenericOption(card.id, PayMode.CARD, label, PayMode.CARD);
        form.payModeItem = {
          ...payModeOption,
          cardNum: card.cardNum
        };

        updatePayMode(PayMode.CARD);
      }

      form.notes = movement.creCardDescription;
    }
    return form;
  }

  const loadExpenseItemAutocompleteOption = async (expenseId, travelPolicyId) => {
    const expItem = await loadTravelPolicyExpenseById(expenseId, travelPolicyId);
    const autocompleteOption = createAutocompleteGenericOption(
      expItem.id,
      expItem.expenseCode,
      expItem.expenseDescription,
      expItem.expenseIcon);

    const expenseItem: AutocompleteGenericOption & NewExpenseExpItem = {
      ...autocompleteOption,
      type: expItem.expenseType,
      colleagues: expItem.colleagueEnb,
      guests: expItem.guestEnb,
      routes: expItem.carRouteEnb,
      measUnit: expItem.measureUnit ? expItem.measureUnit : '',
      compPeriod: expItem.compPeriod,
      compPeriodStartLabel: expItem.compPeriodStartLabel,
      compPeriodEndLabel: expItem.compPeriodEndLabel,
      attachRequired: expItem.attachRequired,
      localityRequired: expItem.localityRequired,
      notesRequired: expItem.notesRequired,
      projectRequired: expItem.projectRequired,
    }
    return expenseItem;
  }

  const getFormValuesFromExpenses = async (expenses: ExpNoteExpenseFull[]): Promise<NewExpenseFormValues> => {
    const mainExp = expenses[0];

    let payModeItemId: number = -1;
    let card: StaffCreditCard | null | undefined = null;
    switch (mainExp.payMode) {
      case PayMode.TRAVELLER:
        payModeItemId = -1;
        break;
      case PayMode.CARD:
        const staffCards = expNote ? await getStaffCreditCards(expNote.staffId) : [];
        card = staffCards.find(c => c.cardNum === mainExp.cardNum);
        payModeItemId = card ? card.id : -2;
        break;
      case PayMode.COMPANY:
        payModeItemId = -3;
        break;
    }
    const label = `${getLabel(getPayModeLabelKey(mainExp.payMode))} ${card ? card.cardNum : ""}`;
    const payModeOption = createAutocompleteGenericOption(payModeItemId, mainExp.payMode, label, mainExp.payMode);
    const payModeItem = mainExp.payMode ? {
      ...payModeOption,
      cardNum: mainExp.payMode === PayMode.CARD ? mainExp.cardNum : undefined
    } : null;

    let docTypeItemId: number = -1;
    switch (mainExp.docType) {
      case DocumentType.INVOICE:
        docTypeItemId = 2;
        break;
      case DocumentType.RECEIPT:
        docTypeItemId = 1;
        break;
      case DocumentType.TICKET:
        docTypeItemId = 3;
        break;
    }
    const docTypeItem = mainExp.docType ? createAutocompleteGenericOption(docTypeItemId, mainExp.docType, getLabel(getDocumentTypeLabelKey(mainExp.docType)), mainExp.docType) : null;

    const currencies = await getCurrencies();
    let currency: Currency | null | undefined = null;
    if (currencies) {
      currency = currencies.find(c => c.code === mainExp.currencyCode);
    }

    const travelPolicy = await getTravelPolicyById(mainExp.travelPolicyId);
    const tpItem = createAutocompleteGenericOption(travelPolicy.id, travelPolicy.code, travelPolicy.description);

    const uniqueExpByExpItem = expenses.filter((v, i, a) => a.findIndex(v2 => (v2.tpExpenseId === v.tpExpenseId)) === i);
    const expPromises = uniqueExpByExpItem.map(exp => loadExpenseItemAutocompleteOption(exp.tpExpenseId, exp.travelPolicyId));
    const expItemsAutocompleteOption = await Promise.all([...expPromises]);

    let supplier: Supplier | null = null;
    if (mainExp.supplierId) {
      supplier = await getSupplierById(mainExp.supplierId.toString());
    }

    const colleagues: NewExpenseColleagueFormValue[] = [];
    if (mainExp.colleagues) {
      mainExp.colleagues.forEach(colleague =>
        colleagues.push({
          colleague: createAutocompleteGenericOption(
            colleague.staffId,
            colleague.staffId.toString(),
            `${colleague.firstName} ${colleague.lastName}`)
        }));
    }

    const guests: NewExpenseGuestFormValue[] = [];
    if (mainExp.guests) {
      mainExp.guests.forEach((guest, index) => {
          guests.push({
            guest: createAutocompleteGenericOption(
              index + 1,
              guest.guestName,
              guest.guestName)
          });
        }
      );
    }

    const attachments: NewExpenseAttachFormValue[] = [];
    if (mainExp.attachments) {
      mainExp.attachments.forEach(attach => {
        attachments.push({_id: attach.id, type: attach.attachRealName.endsWith('.pdf') ? MimeType.PDF : MimeType.JPEG});
      });
    }

    const routes: NewExpenseRouteFormValue[] = [];
    if (mainExp.routes) {
      mainExp.routes.forEach((route, index) => {
        if (index === 0) {
          routes.push({location: createAutocompletePlaceOption({placeId: route.startAddressPlaceId, formattedAddress: route.startAddress}), distance: '0', expectedDistance: 0});
        }
        routes.push({
          _id: route.id,
          location: createAutocompletePlaceOption({placeId: route.endAddressPlaceId, formattedAddress: route.endAddress}),
          distance: formatNumber(route.distance, locale, CarRouteKmDecimalNum),
          expectedDistance: route.expectedDistance
        });
      })
    }

    const additionalExpenses: NewExpenseAdditionalExpFormValue[] = [];
    if (expenses.length > 1) {
      expenses.forEach((exp, index) => {
        if (index > 0) {

          const expColleagues: NewExpenseColleagueFormValue[] = [];
          if (exp.colleagues) {
            exp.colleagues.forEach(colleague =>
              expColleagues.push({
                colleague: createAutocompleteGenericOption(
                  colleague.staffId,
                  colleague.staffId.toString(),
                  `${colleague.firstName} ${colleague.lastName}`)
              }));
          }

          const expGuests: NewExpenseGuestFormValue[] = [];
          if (exp.guests) {
            exp.guests.forEach((guest, index) => {
                expGuests.push({
                  guest: createAutocompleteGenericOption(
                    index + 1,
                    guest.guestName,
                    guest.guestName)
                });
              }
            );
          }

          const expenseItem = expItemsAutocompleteOption.find(item => item.id === exp.tpExpenseId);

          additionalExpenses.push({
            _id: exp.id,
            expenseItem: expenseItem ? expenseItem : null,
            compDate: exp.compDate,
            compStartDate: exp.compStartDate ? exp.compStartDate : null,
            compEndDate: exp.compEndDate ? exp.compEndDate : null,
            amount: exp.currAmount ? formatNumber(exp.currAmount, locale, currency ? currency.decimalNum : companyDecimalNum) : '',
            colleagues: expColleagues,
            guests: expGuests
          });
        }
      })
    }

    const expItem = expItemsAutocompleteOption.find(item => item.id === mainExp.tpExpenseId);

    const linkedCreCardMov: ExpNoteCreCardMov | undefined = creditCardMovs?.find(mov => mov.docNum === mainExp.docNum);
    let creCardMov: CompleteCreCardMovDto | undefined;
    if (linkedCreCardMov) {
      try {
        creCardMov = await getCreCardMovById(linkedCreCardMov.movId);
      } catch (error) {}
    }

    return {
      id: mainExp.id,
      travelPolicy: {...tpItem, foreignCurr: travelPolicy.foreignCurrEnb, projectsEnabled: travelPolicy.projectEnb},
      expenseItem: expItem ? expItem : null,
      locality: mainExp.locality ? createAutocompleteGenericOption(-1, mainExp.locality, mainExp.locality) : null,
      project: mainExp.projectId ? createAutocompleteGenericOption(mainExp.projectId, mainExp.projectCode, mainExp.projectDesc) : null,
      compDate: mainExp.compDate,
      compStartDate: mainExp.compStartDate ? mainExp.compStartDate : null,
      compEndDate: mainExp.compEndDate ? mainExp.compEndDate : null,
      currency: createAutocompleteCurrencyOption(-1, mainExp.currencyCode, currency ? currency.decimalNum : companyDecimalNum),
      amount: mainExp.currAmount ? formatNumber(mainExp.currAmount, locale, currency ? currency.decimalNum : companyDecimalNum) : '',
      exchange: mainExp.exchange ? formatNumber(mainExp.exchange, locale, ExchangeDecimalNum) : '',
      payModeItem,
      docTypeItem,
      notes: mainExp.notes ? mainExp.notes : '',
      quantity: mainExp.quantity ? formatNumber(mainExp.quantity, locale, 2) : '',
      tarif: mainExp.tarif ? formatNumber(mainExp.tarif, locale, TarifDecimalNum) : '',
      supplier: supplier ? createAutocompleteGenericOption(supplier.id, supplier.vatId, supplier.description) : null,
      invoiceNum: mainExp.invoiceNum ? mainExp.invoiceNum : '',
      docDate: mainExp.docDate ? mainExp.docDate : null,
      attachments,
      colleagues,
      guests,
      routes,
      additionalExpenses,
      creCardMov
    };
  }

  const handleActionButtonClicked = (save) => {
    LOG.trace('handleActionButtonClicked (save):', save);
    if (save) {
      formMethods.handleSubmit(handleSave, onFormError)();
    } else {
      onClose(false);
    }
  }

  const onFormError = (e) => {
    LOG.trace('onFormError', e);
  }

  const handleSave = async (values: NewExpenseFormValues) => {
    if (values.expenseItem) {
      let savePromise: Promise<number> | Promise<ExpenseDocumentSaveResponse> | undefined;
      switch (values.expenseItem.type) {
        case ExpenseItemType.PIE_DI_LISTA:
          savePromise = savePieDiListaExpense(values);
          break;
        case ExpenseItemType.TARIFFA:
          savePromise = saveTarifExpense(values);
          break;
        case ExpenseItemType.IMPORTO_FISSO:
          savePromise = saveFixedAmountExpense(values);
          break;
      }

      if (savePromise) {
        setErrorMsg(null);
        setSaving(true);
        savePromise
          .then((res) => {
            let disconnectMovement = false;
            if (res && res.movementDisconnected) {
              disconnectMovement = true;
            }
            onClose(true, disconnectMovement);
          })
          .catch(err => convertError(err).then(msg => setErrorMsg(msg)))
          .finally(() => setSaving(false));
      }
    }
  }

  const handleDeleteClicked = () => {
    if (expNote && expenses) {
      confirm({
        body: (
          <>
            <Typography variant="body1">{t("exp-note:expense.detail.deleteWarning")}</Typography>
            <Typography variant="body1">{t("common:confirmDialog.continueQuestion")}</Typography>
          </>
        ),
        variant: "delete",
        onConfirm: () => {
          let deletePromise;
          const mainExp = expenses[0];
          const expItem = formMethods.getValues('expenseItem');
          if (expItem) {
            switch (expItem.type) {
              case ExpenseItemType.PIE_DI_LISTA:
                deletePromise = deleteExpenseDocument(expNote.id, mainExp.docNum, expNote.lastUpdNum);
                break;
              case ExpenseItemType.TARIFFA:
              case ExpenseItemType.IMPORTO_FISSO:
                deletePromise = deleteSingleExpense(expNote.id, mainExp.id, expNote.lastUpdNum);
                break;
            }

            setErrorMsg(null);
            setSaving(true);
            deletePromise
              .then(() => onClose(true, true))
              .catch(err => convertError(err).then(msg => setErrorMsg(msg)))
              .finally(() => setSaving(false));
          }
        }
      });
    }
  }

  const savePieDiListaExpense = (values: NewExpenseFormValues): Promise<number> | Promise<ExpenseDocumentSaveResponse> => {
    if (!expNote) {
      throw new Error('ExpNote is null');
    }

    //TODO: da rivedere
    const exchange = values.currency && values.currency.code === companyCurrencyCode ? 1 : parseNumber(values.exchange, locale);
    if (!exchange) {
      console.log('exchange is null')
      throw new Error('exchange is null');
    }

    const currAmount = parseNumber(values.amount, locale);
    if (!currAmount) {
      console.log('amount is null')
      throw new Error('amount is null');
    }

    // Inizialmente i campi sono null nella form ma la validazione li rende obbligatori.
    // Typescript vuole che sia gestito il null e lo bypasso con il //@ts-ignore ma per sicurezza
    // verifico preventivamente che i campi non siano effettivamente null e nel caso interrompo il salvataggio.
    if (values.expenseItem == null ||
      values.payModeItem == null ||
      (!values.expenseItem.compPeriod && values.compDate == null)) {
      throw new Error('Missing required fields');
    }

    const expensesToSave: ExpenseDocumentSingleExp[] = [];

    // spesa principale
    expensesToSave.push(createSingleExpSaveData({
      _id: values.id,
      expenseItem: values.expenseItem,
      compDate: values.compDate,
      compStartDate: values.compStartDate,
      compEndDate: values.compEndDate,
      amount: values.amount,
      colleagues: values.colleagues,
      guests: values.guests
    }, values.notes));

    // spese aggiuntive
    if (values.additionalExpenses) {
      values.additionalExpenses.forEach(addExp => expensesToSave.push(createSingleExpSaveData(addExp, values.notes)));
    }

    const data: ExpenseDocumentSaveRequest = {
      docType: values.docTypeItem ? values.docTypeItem.code : '',
      //@ts-ignore
      payMode: values.payModeItem.code,
      cardId: values.payModeItem && values.payModeItem.code === PayMode.CARD && values.payModeItem.id ? values.payModeItem.id : undefined,
      currencyCode: values.currency ? values.currency.code : '',
      exchange: exchange,
      locality: values.locality?.code,
      projectId: values.project?.id ? values.project.id : undefined,
      supplierId: values.supplier?.id ? values.supplier.id : undefined,
      invoiceNum: values.invoiceNum,
      docDate: values.docDate ? values.docDate : undefined,
      lastUpdNum: expNote ? expNote.lastUpdNum : 0,
      attachments: values.attachments.map(a => ({uuid: a.uploadKey, attachId: a._id})),
      expenses: expensesToSave,
      skipExchRecalc: false
    }

    if (!expenses && formMethods.getValues('creCardMov')) {
      data.skipExchRecalc = true;
    }

    return expenses ? updateExpenseDocument(expNote.id, expenses[0].docNum, data) : saveExpenseDocument(expNote.id, data);
  }

  const createSingleExpSaveData = (additionalExpense: NewExpenseAdditionalExpFormValue, notes: string): ExpenseDocumentSingleExp => {
    const currAmount = parseNumber(additionalExpense.amount, locale);
    if (!currAmount) {
      console.log('amount is null')
      throw new Error('amount is null');
    }

    return {
      id: additionalExpense._id,
      //@ts-ignore
      tpExpenseId: additionalExpense.expenseItem.id,
      //@ts-ignore
      compDate: additionalExpense.compDate,
      compStartDate: additionalExpense.compStartDate,
      compEndDate: additionalExpense.compEndDate,
      currAmount: currAmount,
      notes,
      //@ts-ignore
      colleagues: additionalExpense.expenseItem.colleagues
        ? additionalExpense.colleagues
          .filter(c => c != null && c.colleague != null)
          //@ts-ignore
          .map(c => ({staffId: c.colleague.id}))
        : [],
      //@ts-ignore
      guests: additionalExpense.expenseItem.guests
        ? additionalExpense.guests
          .filter(g => g != null && g.guest && g.guest.desc.trim() !== '')
          .map(guest => ({guestName: guest.guest?.desc}))
        : []
    }
  }

  const saveTarifExpense = (values: NewExpenseFormValues): Promise<number> => {
    if (!expNote) {
      throw new Error('ExpNote is null');
    }

    //TODO: da rivedere
    const quantity = parseNumber(values.quantity, locale);
    if (!quantity) {
      console.log('quantity is null')
      throw new Error('quantity is null');
    }

    const tarif = parseNumber(values.tarif, locale);
    if (!tarif) {
      console.log('tarif is null')
      throw new Error('tarif is null');
    }

    const currAmount = parseNumber(values.amount, locale);
    if (!currAmount) {
      console.log('amount is null')
      throw new Error('amount is null');
    }

    const data: SingleExpenseSaveRequest = {
      locality: values.locality ? values.locality.code : undefined,
      projectId: values.project && values.project.id ? values.project.id : undefined,
      lastUpdNum: expNote ? expNote.lastUpdNum : 0,
      attachments: values.attachments.map(a => ({uuid: a.uploadKey, attachId: a._id})),
      //@ts-ignore
      tpExpenseId: values.expenseItem.id,
      //@ts-ignore
      compDate: values.compDate,
      compStartDate: values.compStartDate,
      compEndDate: values.compEndDate,
      quantity: quantity,
      tarif: tarif,
      currAmount: currAmount,
      notes: values.notes
    }

    if (values.expenseItem && values.expenseItem.routes) {
      const saveRoutes: ExpenseRoute[] = [];

      let stepsIdx = 1;

      values.routes.forEach((route, idx) => {
        if (idx === 0 && route.location) {
          saveRoutes.push({
            stepNum: stepsIdx++,
            startAddress: route.location.desc,
            startAddressPlaceId: route.location.code,
            endAddress: '',
            endAddressPlaceId: '',
            distance: 0,
            expectedDistance: 0
          });
        } else if (route.location && route.distance) {
          const savedRoute = saveRoutes[idx - 1];
          const distance = parseNumber(route.distance, locale);
          savedRoute.endAddress = route.location.desc;
          savedRoute.endAddressPlaceId = route.location.code;
          savedRoute.distance = distance ? distance : 0;
          savedRoute.expectedDistance = route.expectedDistance;
          savedRoute.id = route._id;
          saveRoutes.push({
            stepNum: stepsIdx++,
            startAddress: route.location.desc,
            startAddressPlaceId: route.location.code,
            endAddress: '',
            endAddressPlaceId: '',
            distance: 0,
            expectedDistance: 0
          });
        }
      });

      data.routes = saveRoutes.filter(r => r.startAddress && r.endAddress);
    }

    return expenses ? updateSingleExpense(expNote.id, expenses[0].id, data) : saveSingleExpense(expNote.id, data);
  }

  const saveFixedAmountExpense = (values): Promise<number> => {
    if (!expNote) {
      throw new Error('ExpNote is null');
    }

    //TODO: da rivedere
    const currAmount = parseNumber(values.amount, locale);
    if (!currAmount) {
      console.log('amount is null')
      throw new Error('amount is null');
    }

    const data: SingleExpenseSaveRequest = {
      locality: values.locality ? values.locality.code : null,
      projectId: values.project?.id,
      lastUpdNum: expNote ? expNote.lastUpdNum : 0,
      attachments: values.attachments.map(a => ({uuid: a.uploadKey, attachId: a._id})),
      tpExpenseId: values.expenseItem.id,
      compDate: values.compDate,
      compStartDate: values.compStartDate,
      compEndDate: values.compEndDate,
      quantity: 0,
      tarif: 0,
      currAmount: currAmount,
      notes: values.notes
    }

    return expenses ? updateSingleExpense(expNote.id, expenses[0].id, data) : saveSingleExpense(expNote.id, data);
  }

  const isExpNoteToCheck = expNote && getExpNoteFEState(expNote.state) === ExpNoteStateFE.DA_CONTROLLARE;
  const isExpenseApprovedOrRejected = isExpNoteToCheck && expenses && expNoteExpenses ? expNoteExpenses.some(e => !!e.approvalState) : false;

  useEffect(() => {
    if (isExpenseApprovedOrRejected && !isReadonly) {
      setAnnouncementMsg(t("expense.edit.maximumRecalcWarning"));
    }
  }, [isExpenseApprovedOrRejected, isReadonly]);

  //const maximumRecalcWarning: string | undefined = isExpenseApprovedOrRejected && !isReadonly ? t("expense.edit.maximumRecalcWarning") : undefined;
  // if (isExpenseApprovedOrRejected && !isReadonly) {
  //   setAnnouncementMsg(t("expense.edit.maximumRecalcWarning"));
  // }

  return (
    <CommonDialog
      show={show}
      title={t(expenses ? "expense.edit.titleUpdate" : "expense.edit.titleNew")}
      titleIcon={
        expenses && !isReadonly ? (
          <IconButton
            color="primary"
            className={"delete-button"}
            onClick={handleDeleteClicked}
          >
            <DeleteForever/>
          </IconButton>
        ) : (<></>)
      }
      widths={[
        {breakpoint: "lg", width: "1200px"}
      ]}
      saving={saving}
      errorMsg={errorMsg}
      onClose={handleActionButtonClicked}
      showCta={!isReadonly}
      announcementMsg={announcementMsg}
    >
      <>

        {resetting ? (
          <Box display="flex">
            <Box
              flexGrow={1}
              mr={3}
            >
              <Grid container columnSpacing={3}>
                {/*riga 1*/}
                <Grid item xs={6}>
                  <Skeleton height={SkeletonRowHeight}/>
                </Grid>
                <Grid item xs={6}/>
                {/*riga 2*/}
                <Grid item xs={6}>
                  <Skeleton height={SkeletonRowHeight}/>
                </Grid>
                <Grid item xs={3}>
                  <Skeleton height={SkeletonRowHeight}/>
                </Grid>
                {/*riga 3*/}
                <Grid item xs={3}>
                  <Skeleton height={SkeletonRowHeight}/>
                </Grid>
                <Grid item xs={3}>
                  <Skeleton height={SkeletonRowHeight}/>
                </Grid>
                <Grid item xs={3}>
                  <Skeleton height={SkeletonRowHeight}/>
                </Grid>
                <Grid item xs={3}>
                  <Skeleton height={SkeletonRowHeight}/>
                </Grid>
                {/*riga 4*/}
                <Grid item xs={6}>
                  <Skeleton height={SkeletonRowHeight}/>
                </Grid>
                <Grid item xs={6}></Grid>
                {/*riga 5*/}
                <Grid item xs={6}>
                  <Skeleton height={SkeletonRowHeight}/>
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
            </Box>
            <Box
              flexBasis={{
                xs: "350px",
                lg: "400px"
              }}
              flexShrink={0}
              display={"flex"}
              flexDirection={"column"}
            >
              <Skeleton height={120}/>
            </Box>
          </Box>
        ) : (<></>)}


        {!resetting ? (
          <FormProvider {...formMethods}>
            <form noValidate style={{height: "100%"}}>

              <Box className="edit-expense-responsive-warning">
                <Typography variant="h5">{t("exp-note:expense.edit.pageNotAvailable")}</Typography>
              </Box>

              <Box className="edit-expense-container">
                <Box
                  flexGrow={1}
                  mr={3}
                >
                  <ExpenseEdit
                    ocrResult={ocrResult}
                    expenses={expenses}
                    readonly={isReadonly}
                    setAnnouncementMsg={setAnnouncementMsg}
                  />
                </Box>
                <Box
                  flexBasis={{
                    xs: "350px",
                    lg: "400px"
                  }}
                  flexShrink={0}
                  display={"flex"}
                  flexDirection={"column"}
                  height="100%"
                >
                  <AttachmentPanel
                    expenses={expenses}
                    readonly={isReadonly}
                    ocrResult={ocrResult}
                    updateOcrResultHandler={res => setOcrResult(res)}
                    errorHandler={errorMsg => setErrorMsg(errorMsg)}
                  />
                </Box>
              </Box>

            </form>
          </FormProvider>) : (<></>)
        }
      </>
    </CommonDialog>
  );
}
