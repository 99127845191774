const DEV_MODE_LOGIN = 'axsfjdsorjdsflfdsksddsfgkjgris';


export const getEnvLabel = (): string | null => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_LOGIN_PORTAL_URL === 'https://l-api.ztravelsmart.com/api/'){
      if (localStorage.getItem('FORCE_ENV_LABEL')==='true'){
        return 'PROD';
      }
      return null;
    }
    return 'TEST';
  }
  // In sviluppo restituisco una label personalizzabile
  const value = localStorage.getItem('ENV_LABEL');
  if (value===null && localStorage.getItem('FORCE_ENV_LABEL')==='true') {
    return 'DEV';
  }
  return value;
}

export const getLoginPortalUrl = (): string => {
  if (process.env.REACT_APP_LOGIN_PORTAL_URL===undefined) {
    throw new Error('Invalid Environment');
  }
  return process.env.REACT_APP_LOGIN_PORTAL_URL;
}

// https://create-react-app.dev/docs/adding-custom-environment-variables/
let defaultLogLevel = 'TRACE';
let defaultLoginMode = DEV_MODE_LOGIN;
if (process.env.NODE_ENV === 'production') {
  //LOGIN_PORTAL_URL = 'https://zts-new-be-login.azurewebsites.net/api/';
  defaultLogLevel = 'ERROR';
  defaultLoginMode = 'REMOTE'
}

export const initLoggerLevel = () => {
  const key = 'ZTS';
  const loggerLevel = localStorage.getItem(key);
  if ( loggerLevel === null) {
    localStorage.setItem(key, defaultLogLevel);
  }
}

const LOGIN_MODE_KEY = 'login.server';

export const initLoginMode = () => {
  const currentLoginMode = localStorage.getItem(LOGIN_MODE_KEY);
  if (currentLoginMode === null) {
    localStorage.setItem(LOGIN_MODE_KEY, defaultLoginMode);
  }
}

export const isRemoteLogin = (): boolean => {
  const mode = localStorage.getItem(LOGIN_MODE_KEY);
  return mode!==null && mode!==DEV_MODE_LOGIN;
}

const DEV_URL_KEY = 'dev.baseUrl';

export const initDevUrl = () => {
  const currentLoginMode = sessionStorage.getItem(DEV_URL_KEY);
  if (currentLoginMode === null) {
    sessionStorage.setItem(DEV_URL_KEY, 'http://localhost:9090');
  }
}

export const getDevUrl = (): string => {
  const res = sessionStorage.getItem(DEV_URL_KEY);
  if (res===null) {
    throw Error();
  }
  return res;
}


const API_URL_KEY = 'api.url';

export const saveApiUrl = (value: string): void => {
  sessionStorage.setItem(API_URL_KEY, value);
}

export const getApiUrl = (): string => {
  const res = sessionStorage.getItem(API_URL_KEY);
  if (res===null) {
    throw Error();
  }
  return res;
}

export const hasApiUrl = (): boolean => {
  const res = sessionStorage.getItem(API_URL_KEY);
  return res!==null;
}
