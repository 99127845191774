import {ExpNoteExpenseFull} from "../../../expense/model";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {ZtsTableColumn} from "../../../../base/table/model";
import {GreyColor} from "../../../admin/list/model";
import {formatAmountZero, formatNumber} from "../../../../../util/NumberUtil";
import {Currency} from "../../../../model";
import {
  arrayGroupByProperty,
  ExpenseFilter,
  ExpenseFilterType,
  ExpenseGroupingOption,
  isAccountingAnomaly,
  isCreditCardAnomaly,
  isMaximumAnomaly,
  isReadonlyExpNote
} from "../../model";
import {Add, AssignmentInd, AttachFile, Check, Remove, Warning} from "@mui/icons-material";
import {ExpNoteExpensesHeader} from "./header";
import {ExpNoteExpenseList} from "./list";
import {Accordion, AccordionDetails, AccordionSummary, Button, Stack, Typography} from "@mui/material";
import {ExpNoteExpenseDetail} from "./detail";
import {PayModeIcon} from "../../../util/PayModeIcon";
import {DocumentTypeIcon} from "../../../util/DocumentTypeIcon";
import {getExpNote, getExpNoteExpenses} from "../../../Service";
import {useExpNoteDetail} from "../../hooks/useExpNoteDetail";
import {useExpenseForm} from "../../../../../hooks/useExpenseForm";
import {useExpNoteList} from "../../../hooks/useExpNoteList";
import {useLoggedUser} from "../../../../../hooks/useLoggedUser";
import {formatDate} from "../../../../../util/DateUtil";
import {useTranslation} from "react-i18next";
import {TooltipZts} from "../../../../base/tooltip";
import {ExpenseImg} from "../../../../expenses/commons/ExpenseImg";
import {isCreditCardModuleEnabled} from "../../../../../config/token";
import {ExpNoteListRole} from "../../../../../reducers/ExpNoteList";

export enum Columns {
  DOC_NUM = 'docNum',
  ANOMALY = 'anomaly',
  ATTACH = 'attach',
  COMP_DATE = 'compDate',
  EXPENSE_DESC = 'expenseDesc',
  TRAVEL_POLICY_DESC = 'travelPolicyDesc',
  LOCALITY = 'locality',
  PROJECT_DESC = 'projectDesc',
  CURR_AMOUNT = 'currAmount',
  CURRENCY = 'currencyCode',
  PAY_MODE = 'payMode'
}

interface GroupedList {
  id: any,
  label: string,
  expanded: boolean,
  expenses: ExpNoteExpenseFull[],
  renderHeader?: () => JSX.Element
}

type ComponentProps = {
  currencies: Currency[],
  onDisconnectMovement: (docNum?: number) => void
}

export const ExpNoteExpenses = ({currencies, onDisconnectMovement}: ComponentProps) => {

  const {clearFormState} = useExpenseForm();
  const expNoteDetail = useExpNoteDetail();

  const {userLocale: locale, companyDecimalNum, companyCurrencyCode} = useLoggedUser();

  const {t} = useTranslation("exp-note", {keyPrefix: "expense.list"});
  const {t: tCommon} = useTranslation("common");

  const expNoteExpenses = expNoteDetail.expenses;

  const {
    expNote,
    creditCardMovs,
    updateExpenses
  } = expNoteDetail;

  const {
    currentRole
  } = useExpNoteList();

  const isAdmin = currentRole && currentRole === ExpNoteListRole.ADMIN;

  const creditCardModuleEnabled = isCreditCardModuleEnabled();

  const tmpFilters: ExpenseFilter[] = [];
  tmpFilters.push({type: ExpenseFilterType.MAXIMUM, label: t('filterLabels.maximum'), count: 0, enabled: false});
  if (isAdmin && creditCardModuleEnabled) {
    tmpFilters.push({
      type: ExpenseFilterType.CREDIT_CARD,
      label: t('filterLabels.credit-card'),
      count: 0,
      enabled: false
    });
  }
  tmpFilters.push({type: ExpenseFilterType.ACCOUNTING, label: t('filterLabels.accounting'), count: 0, enabled: false});

  const [filters, setFilters] = useState<ExpenseFilter[]>(tmpFilters);
  const [groupBy, setGroupBy] = useState<ExpenseGroupingOption>(ExpenseGroupingOption.NONE);

  const [groupedLists, setGroupedLists] = useState<GroupedList[]>([]);

  const [detailExpenses, setDetailExpenses] = useState<ExpNoteExpenseFull[] | null>(null);

  const [createNewExpense, setCreateNewExpense] = useState(false);

  const groupByProperty = (expenses: ExpNoteExpenseFull[], property: string, propertyDesc: string): GroupedList[] => {
    const tmpGroupedList: GroupedList[] = [];
    const expByProperty: Map<number, ExpNoteExpenseFull[]> = arrayGroupByProperty(expenses, property);
    if (expByProperty && expByProperty.size > 0) {
      expByProperty.forEach((rows, key) => {

        let label = '';
        if (key) {
          const exp = expenses.find(e => e[property] === key);
          if (exp) {
            label = exp[propertyDesc];
          }
        }
        if (!label) {
          label = '';
        }

        let renderAccordionHeader;
        if (property === 'expenseId') {
          const groupExpenseDesc = rows[0].expenseDesc; // In questo punto si suppone che la rows.length sia sempre > 0
          const groupExpenseIcon = <ExpenseImg img={rows[0].expenseIcon} label=""/>; // In questo punto si suppone che la rows.length sia sempre > 0

          renderAccordionHeader = () => {
            const totalCompAmount = rows.reduce((accumulator, currentRow) => accumulator + currentRow.compAmount, 0);
            const containsForeignCurrencies = !!rows.find(row => row.currencyCode !== companyCurrencyCode);
            return (
              <Box display='flex' flexDirection='row' flexGrow={1} justifyContent='space-between'>
                <Box display='flex' flexDirection='row' columnGap={1}>
                  {groupExpenseIcon}
                  <Typography variant={'h5'}>{groupExpenseDesc}</Typography>
                </Box>
                <Box display='flex' flexDirection='row' width='200px' justifyContent={'space-between'}>
                  {
                    containsForeignCurrencies &&
                    <Typography fontStyle={'italic'}>{t('foreignCurrencies')}</Typography>
                  }
                  <Box flexGrow={1}/>
                  <Typography>{formatAmountZero(totalCompAmount, locale, companyDecimalNum, companyCurrencyCode)}</Typography>
                </Box>
              </Box>
            );
          }
        }

        tmpGroupedList.push({
          id: key || '$$##emptyval##$$',
          label,
          expenses: rows,
          expanded: false,
          renderHeader: renderAccordionHeader
        });
      });
    }
    return tmpGroupedList;
  }

  const filterCond = (expense: ExpNoteExpenseFull, filters: ExpenseFilter[]): boolean => {
    const isMaxExc = filters.find(f => f.type === ExpenseFilterType.MAXIMUM) !== undefined;
    const isCreCardWithoutMov = filters.find(f => f.type === ExpenseFilterType.CREDIT_CARD) !== undefined;
    const isAccountingError = filters.find(f => f.type === ExpenseFilterType.ACCOUNTING) !== undefined;

    return (isMaxExc && isMaximumAnomaly(expense)) ||
      (isCreCardWithoutMov && isCreditCardAnomaly(expense, creditCardMovs)) ||
      (isAccountingError && isAccountingAnomaly(expense)) ||
      (!isMaxExc && !isCreCardWithoutMov && !isAccountingError);
  }

  useEffect(() => {
    if (expNoteExpenses) {
      setFilters(filters.map(f => {
        let count = 0;
        switch (f.type) {
          case ExpenseFilterType.ACCOUNTING:
            count = expNoteExpenses.filter(e => isAccountingAnomaly(e)).length;
            break;
          case ExpenseFilterType.MAXIMUM:
            count = expNoteExpenses.filter(e => isMaximumAnomaly(e)).length;
            break;
          case ExpenseFilterType.CREDIT_CARD:
            count = expNoteExpenses.filter(e => isCreditCardAnomaly(e, creditCardMovs)).length;
            break;
        }
        return {...f, count};
      }));
    }
  }, [expNoteExpenses]);

  useEffect(() => {
    if (expNoteExpenses) {
      let filteredExpenses = expNoteExpenses;
      if (filters) {
        filteredExpenses = filteredExpenses.filter(e => filterCond(e, filters.filter(f => f.enabled)));
      }
      if (groupBy) {
        let tmpGroupedLists: GroupedList[] = [];
        const expandedGroupIds = groupedLists?.filter(group => group.expanded)?.map(group => group.id);
        switch (groupBy) {
          case ExpenseGroupingOption.LOCALITY:
            tmpGroupedLists = groupByProperty(filteredExpenses, Columns.LOCALITY, Columns.LOCALITY);
            break;
          case ExpenseGroupingOption.PROJECT:
            tmpGroupedLists = groupByProperty(filteredExpenses, 'projectId', Columns.PROJECT_DESC);
            break;
          case ExpenseGroupingOption.TRAVEL_POLICY:
            tmpGroupedLists = groupByProperty(filteredExpenses, 'travelPolicyId', Columns.TRAVEL_POLICY_DESC);
            break;
          case ExpenseGroupingOption.EXPENSE_TYPE:
            tmpGroupedLists = groupByProperty(filteredExpenses, 'expenseId', Columns.EXPENSE_DESC);
            break;
          case ExpenseGroupingOption.NONE:
            tmpGroupedLists = [{id: '', label: '', expenses: filteredExpenses, expanded: true}];
            break;
        }
        setGroupedLists(expandedGroupIds ?
          tmpGroupedLists.map(group => expandedGroupIds.find(id => id === group.id) ? {
            ...group,
            expanded: true
          } : group) :
          tmpGroupedLists
        );
      }
    } else {
      setGroupedLists([]);
    }
  }, [expNoteExpenses, groupBy, filters]);

  const onFilterChange = (filters: ExpenseFilter[]) => {
    setFilters(filters);
  }

  const onGroupChange = (groupBy: ExpenseGroupingOption) => {
    setGroupBy(groupBy);
  }

  const emptyValueFormatter = (value): JSX.Element => {
    return value ? value : <span style={{color: GreyColor}}>{t('empty')}</span>;
  }

  const documentFormatter = (_, expense: ExpNoteExpenseFull): JSX.Element => {
    return (
      <Box height={"100%"} display={"flex"} alignItems={"center"}>
        {getAttachIcon(expense)}
        {<DocumentTypeIcon docType={expense.docType}/>}
      </Box>
    );
  }

  const paymentFormatter = (_, expense: ExpNoteExpenseFull): JSX.Element => {
    return (
      <Box height={"100%"} display={"flex"} alignItems={"center"}>
        {getColleagueGuestIcon(expense)}
        {<PayModeIcon payMode={expense.payMode}/>}
        {getCreCardMovIcon(expense)}
      </Box>
    );
  }

  const anomalyFormatter = (_, expense: ExpNoteExpenseFull): JSX.Element => {
    return (
      <TooltipZts
        enterDelay={200}
        placement="top-start"
        title={t("maximumTooltip", {amount: formatAmountZero(expense.maxExcExp, locale, companyDecimalNum, companyCurrencyCode)})}
      >
        <Box height={"100%"} display={"flex"} alignItems={"center"}>
          {getMaximumIcon(expense)}
        </Box>
      </TooltipZts>
    );
  }

  const getAttachIcon = (expense: ExpNoteExpenseFull): JSX.Element => {
    const hasAttach = expense.attachments && expense.attachments.length > 0;
    // se non ci sono allegati imposto opacity: 0 in modo da avere cmq la larghezza dell'icona,
    // in questo modo le celle sono allineate in tutte le righe
    return <AttachFile fontSize={"small"} sx={{color: GreyColor, opacity: hasAttach ? 1 : 0}}/>;
  }

  const getCreCardMovIcon = (expense: ExpNoteExpenseFull): JSX.Element => {
    return <Check fontSize={"small"}
                  sx={{opacity: creditCardMovs?.find(c => c.docNum === expense.docNum) ? 1 : 0, color: '#18C0E8'}}/>
  }

  const getColleagueGuestIcon = (expense: ExpNoteExpenseFull): JSX.Element => {
    const hasOthers = ((expense.colleagues && expense.colleagues.length > 0) ||
      (expense.guests && expense.guests.length > 0));
    return <AssignmentInd fontSize={"small"} sx={{color: GreyColor, opacity: hasOthers ? 1 : 0}}/>
  }

  const getMaximumIcon = (expense: ExpNoteExpenseFull): JSX.Element => {
    const exceedsMaximum = expense.maxExcExp > 0;
    // se non ci sono allegati imposto opacity: 0 in modo da avere cmq la larghezza dell'icona,
    // in questo modo le celle sono allineate in tutte le righe
    return <Warning fontSize={"small"} className={"text-danger"} sx={{opacity: exceedsMaximum ? 1 : 0}}/>;
  }

  const columns: ZtsTableColumn[] = [];

  columns.push(
    {
      id: Columns.DOC_NUM,
      label: '#',
      style: {width: '20px', maxWidth: '20px'},
      typography: 'caption',
      cellColor: GreyColor,
      hideOnAggregate: true
    },
    {
      id: Columns.ANOMALY,
      label: '',
      style: {width: '25px', maxWidth: '25px'},
      unsortable: true,
      hideOnAggregate: false,
      formatter: anomalyFormatter
    },
    {
      id: Columns.ATTACH,
      label: '',
      style: {width: '50px', maxWidth: '50px'},
      unsortable: true,
      hideOnAggregate: true,
      formatter: documentFormatter
    },
    {
      id: Columns.COMP_DATE,
      label: tCommon("date"),
      style: {width: '85px', maxWidth: '85px'},
      typography: 'caption',
      cellColor: GreyColor,
      hideOnAggregate: true,
      formatter: (value) => formatDate(value, locale)
    },
    {
      id: Columns.EXPENSE_DESC,
      label: t("expense"),
      unsortable: true,
      style: {width: '175px', minWidth: '100px'},
      formatter: (desc, row) => {
        return (
          <Stack direction="row" columnGap={1}>
            {desc && <ExpenseImg img={row.expenseIcon} label=""/>}
            <Typography>{desc}</Typography>
          </Stack>
        );
      }
    },
    {
      id: Columns.CURR_AMOUNT,
      label: tCommon("amount"),
      // TODO: tenere allineamento a destra? align: 'right',
      unsortable: true,
      style: {width: '100px', maxWidth: '100px'},
      formatter: (amount, row) => {
        const currency = currencies.find(curr => curr.code === row.currencyCode);
        return formatNumber(amount, locale, currency ? currency.decimalNum : companyDecimalNum);
      },
      cssOnAggregate: 'expense-list-cell-small-size'
    },
    {
      id: Columns.CURRENCY,
      label: tCommon("currency"),
      unsortable: true,
      style: {width: '70px', maxWidth: '70px'},
      typography: 'caption',
      cellColor: GreyColor,
      cssOnAggregate: 'expense-list-cell-small-size'
    },
    {
      id: Columns.TRAVEL_POLICY_DESC,
      label: tCommon("travelPolicy"),
      // style: {minWidth: '150px'},
      style: {width: '150px'},
      hideOnAggregate: true
    },
    {
      id: Columns.LOCALITY,
      label: tCommon("locality"),
      // style: {minWidth: '150px'},
      style: {width: '150px'},
      hideOnAggregate: true,
      formatter: emptyValueFormatter
    },
    {
      id: Columns.PROJECT_DESC,
      label: tCommon("project"),
      // style: {minWidth: '150px'},
      style: {width: '150px'},
      hideOnAggregate: true,
      formatter: emptyValueFormatter
    },
    {
      id: Columns.PAY_MODE,
      label: '',
      style: {width: '65px', maxWidth: '65px'},
      unsortable: true,
      hideOnAggregate: true,
      formatter: paymentFormatter
    },
    {
      id: 'cta',
      label: '',
      unsortable: true,
      displayOnHover: () => true,
      hideOnAggregate: true,
      align: 'right',
      style: {width: '100px', maxWidth: '100px'},
      formatter: (r) => {
        return <Button
          variant="contained"
          className={"cta-list-button"}>
          <Typography variant={"button"}>{t("details")}</Typography>
        </Button>
      }
    }
  );

  const handleListExpand = (id: any) => {
    setGroupedLists(groupedLists
      .map(gl => gl.id === id ? ({...gl, expanded: !gl.expanded}) : gl));
  }

  const openExpDetailHandler = (docNum: number) => {
    if (expNoteExpenses) {
      setDetailExpenses(expNoteExpenses.filter(e => e.docNum === docNum));
    }
  }

  const handleCloseExpenseDetail = async (updated: boolean, disconnectMovement?: boolean) => {
    setDetailExpenses(null);
    setCreateNewExpense(false);
    if (updated && expNote) {
      if (disconnectMovement) {
        const docNum = detailExpenses?.map(exp => exp.docNum)[0];
        onDisconnectMovement(docNum);
      }
      const newExpNote = await getExpNote(expNote.id);
      const expenses = await getExpNoteExpenses(expNote.id);
      updateExpenses(newExpNote, expenses);
    }
    clearFormState();
  }

  const handleNewExpenseClicked = () => {
    setCreateNewExpense(true);
  }

  const isReadonly = isReadonlyExpNote(expNote, currentRole);

  return (
    <>
      <Box mx={2}>

        <Stack
          direction="row"
        >
          <Box flexGrow={1}>
            <ExpNoteExpensesHeader
              groupBy={groupBy}
              groupChangeHandler={onGroupChange}
              filters={filters}
              filterChangeHandler={onFilterChange}
            />
          </Box>
          {!isReadonly && (
            <Stack
              rowGap={2}
              justifyContent="flex-end"
            >
              <Box
                display="flex"
                alignItems="center"
                height="48px"
              >
                <Button
                  color="primary"
                  className="exp-note-detail-add-item-button"
                  onClick={handleNewExpenseClicked}
                  startIcon={
                    <Add fontSize={"small"}/>
                  }
                >
                  {t("addItem")}
                </Button>
              </Box>
            </Stack>
          )}
        </Stack>

        {groupBy === ExpenseGroupingOption.NONE ? (
          <Box
            mt={3}
          >
            {groupedLists && groupedLists[0] ? (
              <ExpNoteExpenseList
                columns={columns}
                expenses={groupedLists[0].expenses}
                openExpDetailHandler={openExpDetailHandler}
              />) : (
              <ExpNoteExpenseList
                columns={columns}
                expenses={[]}
                openExpDetailHandler={openExpDetailHandler}
              />
            )}
          </Box>
        ) : (
          groupedLists
            .sort((gl1, gl2) => {
              if (!gl1.label && gl2.label) {
                return -1;
              }
              if (gl1.label && !gl2.label) {
                return 1;
              }
              if (!gl1.label && !gl2.label) {
                return 0;
              }
              if (gl1.label && gl2.label) {
                return gl1.label.localeCompare(gl2.label);
              }
              return 0;
            })
            .map((gl, index) => (
                <Accordion
                  key={index}
                  disableGutters
                  expanded={gl.expanded}
                  onChange={() => handleListExpand(gl.id)}
                  className={"exp-note-expense-list-group"}
                >
                  <AccordionSummary>
                    <Stack
                      direction={"row"}
                      columnGap={2}
                      alignItems={"center"}
                      width={'100%'}
                    >
                      <Box
                        className={"expand-button"}
                      >
                        {gl.expanded ? <Remove/> : <Add/>}
                      </Box>
                      {
                        gl.renderHeader ? gl.renderHeader() :
                          (
                            <Typography variant={"h5"}>{gl.label ? gl.label : t("empty")}</Typography>
                          )
                      }
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ExpNoteExpenseList
                      columns={columns}
                      expenses={gl.expenses}
                      openExpDetailHandler={openExpDetailHandler}
                    />
                  </AccordionDetails>
                </Accordion>
              )
            ))}
      </Box>

      <ExpNoteExpenseDetail
        show={!!detailExpenses || createNewExpense}
        onClose={handleCloseExpenseDetail}
        expenses={detailExpenses}
        create={createNewExpense}
        isReadonly={isReadonly}
      />

    </>
  );
}
