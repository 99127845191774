import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const CustomReportsPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile realizzare dei report personalizzati da affiancare a quelli standard presenti sulla
          soluzione. Sar&agrave; quindi possibile indagare maggiormente le spese di trasferta e generare esportazioni
          Excel in grado di analizzare tutte le dimensioni di analisi messe a disposizione dalla soluzione. Scopri
          quanto hai speso per una commessa, per quale destinazione hai speso di pi&ugrave;, come i collaboratori
          spendono in trasferta e molto altro!</p>
      );
    default:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile realizzare dei report personalizzati da affiancare a quelli standard presenti sulla
          soluzione. Sar&agrave; quindi possibile indagare maggiormente le spese di trasferta e generare esportazioni
          Excel in grado di analizzare tutte le dimensioni di analisi messe a disposizione dalla soluzione. Scopri
          quanto hai speso per una commessa, per quale destinazione hai speso di pi&ugrave;, come i collaboratori
          spendono in trasferta e molto altro!</p>
      );
  }
}
