
// Zucchetti
import {DashboardOnboardingPro} from "./professional";
import {DashboardOnboardingPersonal} from "./personal";
import {useLicense} from "../../../../hooks/useLicense";

export const DashboardOnboarding = () => {

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  return (
    <>
      {free ? <DashboardOnboardingPersonal/> : <DashboardOnboardingPro/>}
    </>
  );
}
