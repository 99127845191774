import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Controller, FormProvider, useForm} from "react-hook-form";

// Mui
import {Alert, Box, Button, Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";

// Librerie esterne
import {AxiosError} from "axios";

import {useTranslation} from "react-i18next";

import {Logger} from 'react-logger-lib';
import SimpleJsLog from "../../util/Logger";

// Zucchetti
import {
  getApiUrl,
  getDevUrl,
  getEnvLabel,
  getLoginPortalUrl,
  isRemoteLogin,
  saveApiUrl
} from "../../config/environment";
import {getUserByToken, getZtsJwtPayload, saveLoginPortalJwt, saveToken} from "../../config/token";
import {TextFieldZts} from "../base/text-field";
import {useLoggedUser} from "../../hooks/useLoggedUser";
import {useLicense} from "../../hooks/useLicense";

import './base.scss';
import {useMobileBreakpoint} from "../../hooks/useMobileBreakpoint";
import {MOBILE_PAGE_URL} from "../model";
import {TenantCredentials, UserLoginResponse} from "./model";
import {updateLastAccess} from "./Service";

const axios = require('axios').default;

const LOG: SimpleJsLog = Logger.of('ZTS.Login.LoginForm');


export const LoginForm = () => {

  const [premium, setPremium] = useState(true);

  const [fetchError, setFetchError] = useState<string | null>(null);

  const navigate = useNavigate();
  const formMethods = useForm();
  const {t} = useTranslation('login', {keyPrefix: 'form'});
  const {t: tRegistration} = useTranslation('login', {keyPrefix: 'registration'});
  const {t: tErr} = useTranslation('api-error');

  const {setProfessional, clearProfessional} = useLicense();

  const {
    setUser: setLoggedUser
  } = useLoggedUser();

  const {
    isMobileBreakpoint
  } = useMobileBreakpoint();

  useEffect(() => {
    if (isMobileBreakpoint) {
      navigate(MOBILE_PAGE_URL, {replace: true});
    }
  }, [isMobileBreakpoint]);

  const loginDev = async () => {
    const baseUrl = getDevUrl();
    const apiUrl = baseUrl + '/api';
    saveApiUrl(apiUrl);
    let licenseType = 'PERSONAL';
    if (premium) {
      licenseType = 'PROFESSIONAL';
    }
    const values = formMethods.getValues();
    const reqData = {
      email: values.email ? values.email : 'user',
      tenantId: values.tenantId ? values.tenantId : '',
      signature: values.password ? values.password : '',
      licenseType: licenseType
    };
    LOG.trace('baseUrl=', baseUrl);
    LOG.trace('reqData=', reqData);
    const resp = await axios.post(`${apiUrl}/auth/login`, reqData);
    LOG.trace('login resp:', resp);
    if (resp.status === 200) {
      saveToken(resp.data.auth);
      saveLoginPortalJwt('DEV');
      updateLastAccess();
      navigate('/');
      if (premium) {
        setProfessional();
      } else {
        clearProfessional();
      }

      getUserByToken(resp.data.auth).then(
        user => setLoggedUser(user)
      );
    }
  }

  const loginProd = async () => {
    let baseUrl = getLoginPortalUrl();
    const values = formMethods.getValues();
    let reqData = {
      email: values.email ? values.email : 'user',
      password: values.password ? values.password : ''
    };
    LOG.trace('baseUrl=', baseUrl);

    let resp;
    try {
      resp = await axios.post(`${baseUrl}auth/user`, reqData);
      LOG.trace('login resp:', resp);
    } catch (e) {
      LOG.trace('login error:', e);
      const axiosError = e as AxiosError;
      if (axiosError.response) {
        const respStatus = axiosError.response.status;
        const respLocation = axiosError.response.data.redirectUrl;
        if (respStatus === 401 && respLocation != null) {
          // @ts-ignore
          window.location.href = respLocation;
          return;
        }
      }
      throw e;
    }

    if (resp.status === 200) {
      const obj: UserLoginResponse = resp.data;
      saveLoginPortalJwt(obj.loginCredentials.accessToken);
      if (obj.expiredPassword) {
        navigate('/password/update');
      } else {
        //TODO gestire tanti tenant, password in scadenza fra n giorni

        let tenantsCredentials: TenantCredentials[] = [];
        if (obj.tenantsCredentials) {
          tenantsCredentials = obj.tenantsCredentials;
        }

        if (tenantsCredentials.length !== 1) {
          let mode = 'none';
          if (tenantsCredentials.length > 1) {
            mode = 'multi';
          }
          navigate(`/login/info?tenantId=${mode}`);
          return;
        }

        const first: TenantCredentials = tenantsCredentials[0];

        if (first.state !== 'ENABLED') {
          navigate(`/login/info?tenantId=${first.tenantId}&name=${first.name}&state=${first.state}`);
          return;
        }


        const apiUrl = first.url + '/api';
        saveApiUrl(apiUrl);
        saveToken(first.accessToken);
        updateLastAccess();

        const jwtBody = getZtsJwtPayload();
        if (jwtBody !== null && jwtBody.lic === 'pro') {
          setProfessional();
        } else {
          clearProfessional();
        }

        navigate('/');

        getUserByToken(first.accessToken).then(
          user => setLoggedUser(user)
        );
      }

    }
  }

  const login = async () => {
    setFetchError(null);
    try {
      if (remoteLoginFlag) {
        await loginProd();
      } else {
        await loginDev();
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.data.code) {
        setFetchError(tErr(axiosError.response.data.code));
      } else {
        LOG.error('Unable to execute login on REST server', err);
        setFetchError('Error retrieving data! Please retry later.');
      }
    }
  }

  const remoteLoginFlag: boolean = isRemoteLogin();

  const passwordLabel = remoteLoginFlag ? t('label.password') : t('label.signature');

  const goToRegistration = () => {
    navigate('/registration/personal?language=it');
  }

  const goToPasswordRecovery = () => {
    navigate('/password/recovery');
  }

  const envLabel: string | null = getEnvLabel();

  const trialSubtitle = tRegistration('trial.subtitle',
    {
      appName: `<span class='registration-app-name'>${tRegistration('trial.appName')}</span>`,
      interpolation: {
        escapeValue: false
      }
    }
  );

  const handleLoginOnKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // console.log(e.nativeEvent.key)
    if (e.nativeEvent.key === 'Enter') {
      login();
    }
  }

  return (
    <Box className="login-container">
      {envLabel && <div className='env-wrapper-login'><div className='env-wrapper'>{envLabel}</div></div>}
      <Stack
        direction="row"
        columnGap={9}
        className="login-form-wrapper"
      >
        <Stack>
          <Typography className="login-title" mb={3}>{t('title')}</Typography>
          <FormProvider {...formMethods}>
            <form noValidate>
              <Typography className="field-label">
                {t('label.emailTitle')}
              </Typography>
              <Controller
                render={({field, fieldState}) =>
                  <TextFieldZts
                    {...field}
                    required
                    label=""
                    placeholder={t('label.email')}
                    errorMsg={fieldState.error?.message}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleLoginOnKeyPressed(e)}
                  />
                }
                name="email"
              />
              <Typography className="field-label">
                {t('label.password')}
              </Typography>
              <Controller
                render={({field, fieldState}) =>
                  <TextFieldZts
                    {...field}
                    required
                    label=""
                    placeholder={passwordLabel}
                    errorMsg={fieldState.error?.message}
                    type={t('label.password')}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleLoginOnKeyPressed(e)}
                  />
                }
                name="password"
              />
              {
                !remoteLoginFlag &&
                <Controller
                  render={({field, fieldState}) =>
                    <TextFieldZts
                      {...field}
                      label=""
                      placeholder={t('label.tenant')}
                      errorMsg={fieldState.error?.message}
                    />
                  }
                  name={"tenantId"}
                />
              }
              {
                !remoteLoginFlag &&
                <FormControlLabel
                  control={<Checkbox checked={premium} size={'small'} onChange={() => setPremium(!premium)}/>}
                  label={t('label.premium')}
                />
              }
            </form>
          </FormProvider>

          <Typography
            mt={2}
            onClick={goToPasswordRecovery}
            className="password-recovery"
          >
            {t('button.forgotPassword')}
          </Typography>

          {fetchError &&
            <Alert
              severity={"error"}
              variant={"filled"}
              sx={{my: 1}}
              onClose={() => setFetchError(null)}>
              {fetchError}
            </Alert>
          }

          <Box className="login-button-wrapper">
            <Button
              variant="contained"
              onClick={login}
              className="login-button"
            >
              <Typography>{t("button.login")}</Typography>
            </Button>
          </Box>

        </Stack>
        <Stack
          className="registration-container"
        >
          <Typography className="registration-question" mb={3}>{tRegistration('noAccountQuestion')}</Typography>
          <Typography className="registration-title" mb={4}>{tRegistration('trial.title')}</Typography>
          <Typography className="registration-subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: trialSubtitle
              }}
            >
            </span>
          </Typography>
          <Box className="login-button-wrapper">
            <Button
              variant="contained"
              onClick={goToRegistration}
              className="registration-button"
            >
              <Typography>{tRegistration("button")}</Typography>
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
