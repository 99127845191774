import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const ProjectPillPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>Vuoto</p>
      );
    default:
      return (
        <p>Vuoto</p>
      );
  }
}
