import {Route, Routes} from "react-router-dom";
import {ProtectedRoute} from "../base/protected-routes";
import {ProjectDetail} from "./detail/ProjectDetail";
import {FilterableList} from "./list/FilterableList";
import {ProjectEdit} from "./edit/ProjectEdit";
import {PremiumWrapper} from "../base/premium-wrapper";
import {PopupContentType} from "../license-popup/model";
import {useHelp} from "../help/hook";
import {DETAIL_URL, EDIT_URL, NEW_URL, PROJECT_URL} from "../model";
import {HelpCurrentPage} from "../../reducers/Help";

export const Projects = () => {
  useHelp({
    paths: [
      {
        path: `${PROJECT_URL}${NEW_URL}`,
        page: HelpCurrentPage.PROJECT_NEW,
        exactMatch: true
      },
      {
        path: `${PROJECT_URL}${EDIT_URL}`,
        page: HelpCurrentPage.PROJECT_EDIT
      },

      {
        path: `${PROJECT_URL}${DETAIL_URL}`,
        page: HelpCurrentPage.PROJECT_DETAIL
      },
      {
        path: PROJECT_URL,
        page: HelpCurrentPage.PROJECT_LIST
      }
    ]
  });

  return (
    <PremiumWrapper type={PopupContentType.PROJECTS}>
      <ProtectedRoute isAdmin>
        <Routes>
          {<Route path="/" element={<FilterableList/>}/>}
          {<Route path="/detail/:id" element={<ProjectDetail/>}/>}
          {<Route path="/edit/:id" element={<ProjectEdit/>}/>}
        </Routes>
      </ProtectedRoute>
    </PremiumWrapper>
  );
}
