import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {ChangeEvent} from "react";

export interface RadioOption {
  value: string,
  label: string
}

type ComponentProps = {
  selectedValue: string,
  options: RadioOption[],
  displayOnRow?: boolean,
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
}


export const RadioGroupZts = ({selectedValue, options, handleChange, displayOnRow}: ComponentProps) => {
  return (
    <RadioGroup
      row={displayOnRow}
      value={selectedValue}
      onChange={handleChange}
    >
      {options.map(opt => (
        <FormControlLabel
          key={opt.value}
          value={opt.value}
          control={<Radio/>}
          label={opt.label}
          sx={{
            height: "48px"
          }}
        />
      ))}
    </RadioGroup>
  );
}
