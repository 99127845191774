import {Alert, Paper, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import {PageTitle} from "../../layout/page-title";
import {SupplierDetailLoader} from "./SupplierDetailLoader";
import {disableSupplier, getSupplierById} from "../Service";
import {GeneralData} from "./GeneralData";
import {getSupplierCodeAndDescription, Supplier} from "../model";
import {DeleteForever, Edit} from "@mui/icons-material";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {TooltipZts} from "../../base/tooltip";
import {useTranslation} from "react-i18next";


export const SupplierDetail = () => {
  const {t} = useTranslation('supplier', {keyPrefix: 'detail.button'});

  const [loadingData, setLoadingData] = useState(true);
  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const [supplierUpdated] = useState<Date | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [premiumFeatureMsg, setPremiumFeatureMsg] = useState('');

  const navigate = useNavigate();

  const {convertError} = useErrorMessage();

  let {id} = useParams();



  useEffect(() => {
    if (id) {
      setLoadingData(true);

      getSupplierById(id)
        .then(u => setSupplier(u))
        .catch(err => setErrorMsg(`Error retrieving data. ${err}`))
        .finally(() => setLoadingData(false))
    }
  }, [supplierUpdated, id]);

  const handleEditClicked = () => {
    if (supplier) {
      navigate(`/suppliers/edit/${supplier.id}`);
    }
  }

  const backToList = () => {
    navigate(`/suppliers`);
  }

  const handlePremiumFeatureClose = () => {
    setPremiumFeatureMsg('');
  }

  const handleDisableClicked = () => {
    setLoadingData(true);
    if (supplier) {
      disableSupplier(supplier.id)
        .then(() => {
          setLoadingData(false);
          navigate('/suppliers');
        })
        .catch(async error => {
          setLoadingData(false);
          const msg = await convertError(createError(error));
          setErrorMsg(msg);
        })
    }
  }

  return (
    <>

      <Box
        overflow={"hidden"}
        flexGrow={1}
        mx={"auto"}
        width={loadingData ? "100%" : "100%"}
      >

        {/* Skeleton */}
        {loadingData && <SupplierDetailLoader/>}

        {!loadingData && supplier && <>
          <Paper
            elevation={0}
            sx={{
              height: "100%"
            }}
          >

            <Box
              height={"100%"}
              overflow={"auto"}
              padding={"20px 30px 20px 20px"}
            >

              {errorMsg && <Alert
                sx={{my: 1}}
                severity={"error"}
                onClose={() => setErrorMsg(null)}
              >{errorMsg}</Alert>}

              {/* titolo + pulsanti */}
              <PageTitle
                backClickHandler={backToList}
                title={
                  <Stack
                    direction={{xs: "column", sm: "row"}}
                    alignItems={{xs: "flex-start", sm: "center"}}
                    justifyContent={{xs: "center", sm: "flex-start"}}
                    columnGap={1.5}
                    flexGrow={"1"}
                  >
                    <Typography
                      variant={"h3"}
                      mr={3}
                      className={"detail-title-wrapper"}
                    >{getSupplierCodeAndDescription(supplier)}</Typography>
                    {/*<Box*/}
                    {/*  mt={{xs: 1, sm: 0}}*/}
                    {/*  className={'status-pill-detail-header ' + getStatusClass(supplier.state)}*/}
                    {/*>*/}
                    {/*  <Typography variant={"h5"}>{getStatusLabel(supplier.state)}</Typography>*/}
                    {/*</Box>*/}
                  </Stack>
                }
                actions={
                  <>
                    <TooltipZts
                      title={t('disable')}
                      placement={"bottom"}
                      enterDelay={400}
                    >
                      <IconButton
                        color="primary"
                        className={"delete-button"}
                        onClick={handleDisableClicked}
                      >
                        <DeleteForever/>
                      </IconButton>
                    </TooltipZts>

                    <TooltipZts
                      title={t('modify')}
                      placement={"bottom"}
                      enterDelay={400}
                    >
                      <IconButton
                        color="primary"
                        className={"edit-button"}
                        onClick={handleEditClicked}
                      >
                        <Edit fontSize={"small"}/>

                      </IconButton>
                    </TooltipZts>
                  </>
                }
              />

              {/* dettagli (sx) + elementi collegati (Travel policy, commesse, ecc..) (dx) */}
              <Stack
                mt={2}
                direction={{
                  xs: "column",
                  lg: "row"
                }}
                columnGap={2}
              >

                {/* parte sx */}
                <Stack
                  flexGrow={1}
                  direction={{
                    xs: "column-reverse",
                    sm: "row"
                  }}
                  columnGap={2}
                  width={"100%"}
                >

                  {/* Dati generali */}
                  <GeneralData supplier={supplier}/>

                </Stack>
              </Stack>

            </Box>
          </Paper>

        </>
        }

      </Box>

    </>
  );
}
