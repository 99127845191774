//Resize dell' immagine tenendo conto del' exif data dell' immagine di input.
interface ImageResizeSettings {
  file: Blob; // Il file che contiene l'immagine
  scale?: string; // La dimensione massima : larghezza o altezza con la quale scalare l'immagine , non specificare se si specifica il parametro scale
  maxSize?: number; // Indica di quanto si vuole scalare l'immagine per intero. 0.5 = metà , 0.25 = un quarto etc. Non specificare se si specifica il parametro maxSize.
}

export class ImageUtility {
  public static resizeImage(settings: ImageResizeSettings): Promise<Blob> | null {
    let file = settings.file;
    let maxSize = settings.maxSize;
    let scale = settings.scale;
    let reader = new FileReader();
    let image = new Image();
    let canvas = document.createElement('canvas');
    let cropCanvas = document.createElement('canvas');

    if (!scale && !maxSize) {
      console.error('Pleas specify maxSize or scale parameters.');
      return null;
    }

    if (scale && maxSize) {
      alert('Please specify only maxSize or only scale parameters');
      return null;
    }

    return new Promise (function (ok, no) {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }

      reader.onload = function (readerEvent: any) {

        image.onload = function () {
          return ok(ImageUtility.resize(image, maxSize, scale, canvas, cropCanvas, reader));
        };

        image.onerror = function () {
          no(new Error('Not an image'));
        }

        image.src = readerEvent.target.result;
      };

      ImageUtility.readOrientation(file, reader);
    });
  }

  private static readOrientation(file, mainReader) {
    let reader = new FileReader();
    reader.onload = function (e: any) {
      let view = new DataView(e.target.result);
      if (view.getUint16(0, false) != 0xffd8) return ImageUtility.callback_exif(file, mainReader, -2);
      let length = view.byteLength,
        offset = 2;
      while (offset < length) {
        let marker = view.getUint16(offset, false);
        offset += 2;
        if (marker == 0xffe1) {
          if (view.getUint32((offset += 2), false) != 0x45786966) return ImageUtility.callback_exif(file, mainReader, -1);
          let little = view.getUint16((offset += 6), false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          let tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++)
            if (view.getUint16(offset + i * 12, little) == 0x0112)
              return ImageUtility.callback_exif(file, mainReader, view.getUint16(offset + i * 12 + 8, little));
        } else if ((marker & 0xff00) != 0xff00) break;
        else offset += view.getUint16(offset, false);
      }
      return ImageUtility.callback_exif(file, mainReader, -1);
    };
    reader.readAsArrayBuffer(file);
  }

  private static dataURItoBlob(dataURI) {
    let bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    let mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    let max = bytes.length;
    let ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  }

  public static callback_exif(file, reader: any, ornt) {
    reader.orientation = 0;
    reader.readAsDataURL(file);
  }

  private static resize(image, maxSize, scale, canvas, cropCanvas, reader) {
    let width = image.width;
    let height = image.height;
    let invertDimension = false;
    if (maxSize) {
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }
    } else if (scale) {
      width *= scale;
      height *= scale;
    }
    //Prendo il massimo tra il width e l'height , che sarranno poi le dimensioni del mio canvas per effettuare la rotazione.
    let squareCanvasEdge = Math.max(width, height);
    canvas.width = width;
    canvas.height = height; //Canvas quadrato dimensione pari al lato dell' immagine più grande.

    let ctx = canvas.getContext('2d');
    let delta_h = canvas.width / 2 - width / 2;
    let delta_w = canvas.height / 2 - height / 2;
    let swapWidthHeight = false;
    ctx.translate(canvas.width / 2, canvas.height / 2);
    // switch (reader.orientation) {
    //   case 8:
    //     ctx.rotate((-90 * Math.PI) / 180);
    //     swapWidthHeight = true;
    //     break;
    //   case 3:
    //     ctx.rotate((180 * Math.PI) / 180);
    //     break;
    //   case 6:
    //     ctx.rotate((90 * Math.PI) / 180);
    //     swapWidthHeight = true;
    //     break;
    // }
    ctx.translate(-canvas.width / 2, -canvas.height / 2);
    ctx.drawImage(image, delta_h, delta_w, delta_w + width, delta_h + height);
    let dataUrl = canvas.toDataURL('image/jpeg');
    if (swapWidthHeight) {
      cropCanvas.height = squareCanvasEdge;
      cropCanvas.width = squareCanvasEdge;
      ctx = cropCanvas.getContext('2d');
      let img = new Image();

      img.src = dataUrl;
      // dataUrl1 = cropCanvas.toDataURL('image/jpeg');
    }

    dataUrl = ExifRestorer.restore(image.src, dataUrl);

    let blob = ImageUtility.dataURItoBlob(dataUrl);
    let res = blob;
    return res;
  }
}

class ExifRestorer {
  private static KEY_STR = 'ABCDEFGHIJKLMNOP' + 'QRSTUVWXYZabcdef' + 'ghijklmnopqrstuv' + 'wxyz0123456789+/' + '=';

  private static encode64(input) {
    let output = '',
      chr1,
      chr2,
      chr3: any = '',
      enc1,
      enc2,
      enc3,
      enc4: any = '',
      i = 0;

    do {
      chr1 = input[i++];
      chr2 = input[i++];
      chr3 = input[i++];

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        ExifRestorer.KEY_STR.charAt(enc1) +
        ExifRestorer.KEY_STR.charAt(enc2) +
        ExifRestorer.KEY_STR.charAt(enc3) +
        ExifRestorer.KEY_STR.charAt(enc4);
      chr1 = chr2 = chr3 = '';
      enc1 = enc2 = enc3 = enc4 = '';
    } while (i < input.length);

    return output;
  }

  public static restore(origFileBase64, resizedFileBase64) {
    if (!origFileBase64.match('data:image/jpeg;base64,')) {
      return resizedFileBase64;
    }

    let rawImage = ExifRestorer.decode64(origFileBase64.replace('data:image/jpeg;base64,', ''));
    let segments = ExifRestorer.slice2Segments(rawImage);

    let image = ExifRestorer.exifManipulation(resizedFileBase64, segments);

    return 'data:image/jpeg;base64,' + ExifRestorer.encode64(image);
  }

  private static exifManipulation(resizedFileBase64, segments) {
    let exifArray = ExifRestorer.getExifArray(segments),
      newImageArray = ExifRestorer.insertExif(resizedFileBase64, exifArray),
      aBuffer = new Uint8Array(newImageArray);

    return aBuffer;
  }

  private static getExifArray(segments) {
    let seg: any;
    for (let x = 0; x < segments.length; x++) {
      seg = segments[x];
      if (seg[0] == 255 && seg[1] == 225) {
        //(ff e1)
        return seg;
      }
    }
    return [];
  }

  private static insertExif(resizedFileBase64, exifArray) {
    let imageData = resizedFileBase64.replace('data:image/jpeg;base64,', ''),
      buf: any = ExifRestorer.decode64(imageData),
      separatePoint = buf.indexOf(255, 3),
      mae = buf.slice(0, separatePoint),
      ato = buf.slice(separatePoint),
      array = mae;

    array = array.concat(exifArray);
    array = array.concat(ato);
    return array;
  }

  private static slice2Segments(rawImageArray: any) {
    let head = 0,
      segments: any[] = [];

    while (1) {
      if (rawImageArray[head] == 255 && rawImageArray[head + 1] == 218) {
        break;
      }
      if (rawImageArray[head] == 255 && rawImageArray[head + 1] == 216) {
        head += 2;
      } else {
        let length = rawImageArray[head + 2] * 256 + rawImageArray[head + 3],
          endPoint = head + length + 2,
          seg = rawImageArray.slice(head, endPoint);
        segments.push(seg);
        head = endPoint;
      }
      if (head > rawImageArray.length) {
        break;
      }
    }

    return segments;
  }

  private static decode64(input) {
    let output = '',
      chr1,
      chr2,
      chr3: any = '',
      enc1,
      enc2,
      enc3,
      enc4: any = '',
      i = 0,
      buf: any[] = [];

    // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
    let base64test = /[^A-Za-z0-9\+\/\=]/g;
    if (base64test.exec(input)) {
      alert(
        'There were invalid base64 characters in the input text.\n' +
          "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
          'Expect errors in decoding.'
      );
    }
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

    do {
      enc1 = ExifRestorer.KEY_STR.indexOf(input.charAt(i++));
      enc2 = ExifRestorer.KEY_STR.indexOf(input.charAt(i++));
      enc3 = ExifRestorer.KEY_STR.indexOf(input.charAt(i++));
      enc4 = ExifRestorer.KEY_STR.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      buf.push(chr1);

      if (enc3 != 64) {
        buf.push(chr2);
      }
      if (enc4 != 64) {
        buf.push(chr3);
      }

      chr1 = chr2 = chr3 = '';
      enc1 = enc2 = enc3 = enc4 = '';
    } while (i < input.length);

    return buf;
  }
}
//Fine resize dell' immagine
//EXIF restorer
//Based on MinifyJpeg
//http://elicon.blog57.fc2.com/blog-entry-206.html
