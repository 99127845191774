import { ExpNoteState } from '../exp_note/model';
import { ExpenseCategory } from '../expenses/model';
import { Tenant } from '../users/model';
import moment from 'moment';

const ACTIVATION_PERIOD_LENGTH = 15;  // in giorni

const ONBOARDING_DISMISS_STORAGE_KEY = 'dashboard.admin.onBoardingDismiss';
const ONBOARDING_DISMISS_STORAGE_VALUE = '1';

const WELCOME_DISMISS_STORAGE_KEY = 'dashboard.admin.welcomeDismiss';
const WELCOME_DISMISS_STORAGE_VALUE = '1';

const EXP_TOTAL_BY_MONTH_RANGE_STORAGE_KEY = 'dashboard.admin.expByMonthRange';
const EXP_TOTAL_BY_CATEGORY_MONTH_RANGE_STORAGE_KEY = 'dashboard.admin.expByCategoryMonthRange';

export interface DashboardExpNoteCount {
  state: ExpNoteState,
  staffId: number,
  count: number
}

export enum DashboardExpenseTotalRange {
  CURRENT_YEAR = 'CY',
  LAST_TWELVE_MONTHS = 'LY',
  LAST_SIX_MONTHS = 'LS',
  LAST_MONTH = 'LM'
}

export interface DashboardExpenseTotalByMonth {
  month: string,
  total: number
}

export interface DashboardExpenseTotalByCategoryMonth {
  expenseCategory: ExpenseCategory,
  total: number
}

export interface DashboardExpenseTotalByStaffPolicy {
  staffId: number,
  traveller: string,
  travelPolicyId: number,
  travelPolicyDesc: string,
  total: number
}

export interface DashboardExpenseTotalByStaff {
  staffId: number,
  traveller: string,
  travelPolicies: {id: number, description: string}[],
  total: number,
  expNoteCount: number
}

type ZtsLicenseType = "PROFESSIONAL" | "PERSONAL";

type ZtsStoreType = "ZS" | "MASTERCARD";

export interface DashboardLicense {
  type: ZtsLicenseType,
  expirationDate: Date,
  activeUsers: number,
  maxUsers: number,
  creditCard?: boolean,
  creditCardModuleActivationDate?: Date,
  digitalPreservation?: boolean,
  digitalPreservationActivationDate?: Date,
  companyName: string,
  companyVat: string,
  storeType: ZtsStoreType,
  storeUrl: string
}

export interface DashBoardTravellerLastExpense {
  insertTime: Date,
  expense: string,
  expNote: string,
  travelPolicy: string,
  currencyAmount: number,
  id: number,
  compDate: Date,
  compStartDate?: Date,
  compEndDate?: Date,
  expenseIcon: string,
  expNoteId: number,
  currencyCode: string,
  decimalNum: number,
  docNum: number,
  attachDocNum?: number
}

export const getChartRangeLabel = (t, range: DashboardExpenseTotalRange): string => {
  switch (range) {
    case DashboardExpenseTotalRange.CURRENT_YEAR:
      return t("common.range.currentYear");
    case DashboardExpenseTotalRange.LAST_TWELVE_MONTHS:
      return t("common.range.lastYear");
    case DashboardExpenseTotalRange.LAST_SIX_MONTHS:
      return t("common.range.lastSixMonths");
    case DashboardExpenseTotalRange.LAST_MONTH:
      return t("common.range.currentMonth");
  }
  return "";
}

export const isOnboardingDismissed = (): boolean => {
  const value = localStorage.getItem(ONBOARDING_DISMISS_STORAGE_KEY);
  return !!value && value === ONBOARDING_DISMISS_STORAGE_VALUE;
}

export const saveOnboardingDismiss = () => {
  localStorage.setItem(ONBOARDING_DISMISS_STORAGE_KEY, ONBOARDING_DISMISS_STORAGE_VALUE);
}

export const saveWelcomeDismiss = () => {
  localStorage.setItem(WELCOME_DISMISS_STORAGE_KEY, WELCOME_DISMISS_STORAGE_VALUE);
}

export const isWelcomeDismissed = (tenant: Tenant): boolean => {
  if (isActivationPeriodExpired(tenant)) {
    return true;
  }
  const value = localStorage.getItem(WELCOME_DISMISS_STORAGE_KEY);
  return !!value && value === WELCOME_DISMISS_STORAGE_VALUE;
}

export const isActivationPeriodExpired = (tenant: Tenant): boolean => {
  return !!tenant && moment(new Date()).diff(tenant.insertTime, 'days') > ACTIVATION_PERIOD_LENGTH;
}

export const isAdminOnboardingActive = (tenant: Tenant): boolean => {
  return !(isOnboardingDismissed() || isActivationPeriodExpired(tenant));

}

export const getDashboardExpenseTotalByMonthRange = (): DashboardExpenseTotalRange => {
  const storedValue = localStorage.getItem(EXP_TOTAL_BY_MONTH_RANGE_STORAGE_KEY);
  if (storedValue) {
    try {
      switch (storedValue) {
        case DashboardExpenseTotalRange.CURRENT_YEAR:
        case DashboardExpenseTotalRange.LAST_TWELVE_MONTHS:
        case DashboardExpenseTotalRange.LAST_SIX_MONTHS:
          return storedValue;
        default:
          return DashboardExpenseTotalRange.CURRENT_YEAR;
      }
    } catch (error) {
    }
  }
  return DashboardExpenseTotalRange.CURRENT_YEAR;
}

export const saveDashboardExpenseTotalByMonthRange = (range: DashboardExpenseTotalRange) => {
  localStorage.setItem(EXP_TOTAL_BY_MONTH_RANGE_STORAGE_KEY, range);
}

export const getDashboardExpenseTotalByCategoryMonthRange = (): DashboardExpenseTotalRange => {
  const storedValue = localStorage.getItem(EXP_TOTAL_BY_CATEGORY_MONTH_RANGE_STORAGE_KEY);
  if (storedValue) {
    try {
      switch (storedValue) {
        case DashboardExpenseTotalRange.CURRENT_YEAR:
        case DashboardExpenseTotalRange.LAST_TWELVE_MONTHS:
        case DashboardExpenseTotalRange.LAST_SIX_MONTHS:
        case DashboardExpenseTotalRange.LAST_MONTH:
          return storedValue;
        default:
          return DashboardExpenseTotalRange.CURRENT_YEAR;
      }
    } catch (error) {
    }
  }
  return DashboardExpenseTotalRange.CURRENT_YEAR;
}

export const saveDashboardExpenseTotalByCategoryMonthRange = (range: DashboardExpenseTotalRange) => {
  localStorage.setItem(EXP_TOTAL_BY_CATEGORY_MONTH_RANGE_STORAGE_KEY, range);
}
