import {
  formatStringNumberByLocale,
  getAmountDecimalSeparator, getAmountGroupingSeparator, getCurrencySymbol,
  Locale,
  parseStringNumberByLocale
} from './LocalizationUtil';

export const formatNumber = (number: number, locale: Locale, decimalNum: number): string => {
  if (!number) {
    return '';
  }
  let numberString = number.toFixed(decimalNum);
  let decimalSep = getAmountDecimalSeparator(locale);
  numberString = formatStringNumberByLocale(numberString, locale);

  if (numberString.indexOf(decimalSep) !== -1) {
    // il numero contiene il separatore dei decimali. es.: 0,34
    const padLenght = (decimalNum ? decimalNum : 2) + 1 + numberString.indexOf(decimalSep);
    return numberString.padEnd(padLenght, '0');
  } else {
    // il numero NON contiene il separatore dei decimali. es.: 6
    if (decimalNum === 0) {
      return numberString;
    }

    const padLenght = (decimalNum ? decimalNum : 2) + 1 + numberString.length;
    numberString = numberString + decimalSep;
    return numberString.padEnd(padLenght, '0');
  }
}

export const formatNumberZero = (number: number, locale: Locale, decimalNum: number): string => {
  if (!number) {
    return '0';
  }
  return formatNumber(number, locale, decimalNum);
}

export const parseNumber = (numberString: string, locale: Locale): number | null => {
  if (!numberString) {
    return null;
  }
  numberString = parseStringNumberByLocale(numberString, locale);
  return parseFloat(numberString);
}

const localFormatAmount = (locale: Locale, formattedValue: string, currencyCode: string): string => {
  const decimalSep = getAmountDecimalSeparator(locale);
  let prefix = formattedValue;
  let suffix = '';
  const idx = formattedValue.indexOf(decimalSep);
  if (idx>0) {
    prefix = formattedValue.substring(0, idx);
    suffix = formattedValue.substring(idx);
  }

  // Aggiungo il separatore delle migliaia
  if (prefix.length>3) {
    const groupingSep = getAmountGroupingSeparator(locale);

    let startIdx = 0;
    let nextIdx = prefix.length % 3;
    if (nextIdx===0) {
      nextIdx = 3;
    }
    let formatted = prefix.substring(startIdx, nextIdx);
    while( nextIdx<prefix.length) {
      startIdx = nextIdx;
      nextIdx += 3;
      formatted = formatted + groupingSep +  prefix.substring(startIdx, nextIdx);
    }
    prefix = formatted;
  }

  // https://regex101.com/
  // Prende dei gruppi di 3 cifre ed aggiunge il separatore delle migliaia
  // Solo nella parte intera
  // Non funziona su Safari
  //prefix = prefix.replace(/\B(?<!\d{1-3})(?=(\d{3})+(?!\d))/g, groupingSep);

  const symbol = getCurrencySymbol(currencyCode);
  return `${symbol} ${prefix}${suffix}`;
}

export const formatAmount = (amount: number, locale: Locale, decimalNum: number, currencyCode: string) => {
  const formatted = formatNumber(amount, locale, decimalNum);
  return localFormatAmount(locale, formatted, currencyCode);
}

export const formatAmountZero = (amount: number, locale: Locale, decimalNum: number, currencyCode: string) => {
  let formatted = formatNumber(amount, locale, decimalNum);
  if (formatted === '') {
    formatted = `0${getAmountDecimalSeparator(locale)}`;
    for (let index = 0; index < decimalNum; index++) {
      formatted += '0';
    }
  }
  return localFormatAmount(locale, formatted, currencyCode);
}
