import {Project, StaffByProjectIdResponse} from "../model";
import {Controller, useFormContext} from "react-hook-form";
import {Grid, Typography} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import React from "react";
import {DatePickerZts} from "../../base/date-picker";
import {useTranslation} from "react-i18next";
import {StaffByProjectData} from "./StaffByProjectData";

// TODO spostare
// https://mui.com/blog/lab-date-pickers-to-mui-x/
//https://mui.com/x/react-date-pickers/getting-started/

type GeneralDataProps = {
  project: Project,
  edit?: boolean
  staffsList: StaffByProjectIdResponse[] ;
}

type ComponentProps = {
  props: GeneralDataProps
}

export const GeneralData = ({props}: ComponentProps) => {
  const {t} = useTranslation('project', {keyPrefix: 'generalData'});
  let control;
  const formMethods = useFormContext();

  control = props.edit ? formMethods.control : null;
  //Abilito l'elenco delle persone a cui è assegnata la commessa, ma non in editing, solo consultazione
  let staffDetailsEnabled = !props.edit && props.staffsList;

  return (
    <>

      <Grid container maxWidth={{xs: 12, lg: "50%"}} spacing={2} justifyContent={"space-between"}>
        <Grid item xs={12}>
          <Typography variant="h6" textTransform={"uppercase"}>{t('title')}</Typography>
        </Grid>

        <Grid item xs={12} >
          {
            props.project.id === -1 ?
            (
                <Controller
                  name="code"
                  control={control}
                  render={({field, fieldState}) => {
                    return <TextFieldZts
                      {...field}
                      required
                      label={t('label.code')}
                      errorMsg={fieldState.error?.message}
                    />
                  }}
                />
              ) :
              (
                <TextFieldZts
                  disabled={true}
                  label={t('label.code')}
                  value={props.project.code}
                />
              )
          }
        </Grid>

        <Grid item xs={12}>
          {
            props.edit ?
              (
                <Controller
                  name="description"
                  control={control}
                  render={({field, fieldState}) => {
                    return <TextFieldZts
                      {...field}
                      required
                      label={t('label.description')}
                      errorMsg={fieldState.error?.message}
                    />
                  }}
                />
              ) :
              (
                <TextFieldZts
                  disabled={true}
                  label={t('label.description')}
                  value={props.project.description}
                />
              )
          }
        </Grid>


        <Grid item xs={6} >
          { props.edit
            ? ( <Controller
              name="startDate"
              control={control}
              render={({field, fieldState}) => {
                return <DatePickerZts
                  required
                  field={field}
                  label={t('label.startDate')}
                  errorMsg={fieldState.error?.message}
                  onChangeHandler={(value: Date) => {
                    //formikProps.setFieldValue(field.name, value);
                    field.onChange(value);
                  }}
                />
              }}
            /> )
            :(
              <DatePickerZts
                disabled
                label={t('label.startDate')}
                disabledValue={props.project.startDate}
              />
            )
          }
        </Grid>

        <Grid item xs={6}  >
            { props.edit
              ? ( <Controller
                name="endDate"
                control={control}
                render={({field, fieldState}) => {
                  return <DatePickerZts
                    required
                    field={field}
                    label={t('label.endDate')}
                    errorMsg={fieldState.error?.message}
                    onChangeHandler={(value: Date) => {
                      //formikProps.setFieldValue(field.name, value);
                      field.onChange(value);
                    }}
                  />
                }}
              /> )
              :(
                <DatePickerZts
                  disabled
                  label={t('label.endDate')}
                  disabledValue={props.project.endDate}
                />
              )
            }
        </Grid>

        {
          staffDetailsEnabled &&
          <Grid item xs={12} >
              <Typography variant="h6" textTransform={"uppercase"}>{t('titleUsers')}</Typography>
          </Grid>
        }

        {
          staffDetailsEnabled &&
            props.staffsList.map((item, index) => <StaffByProjectData props={{staff: item, edit: false}}/>)
        }


    </Grid>
    </>
  );
}
