import { ITALIAN, Locale } from '../util/LocalizationUtil';

export const DASHBOARD_PATH = 'dashboard';

export const HOME_ADMIN_URL = `/${DASHBOARD_PATH}/admin`;
export const HOME_TRAVELLER_URL = `/${DASHBOARD_PATH}/traveller`;
export const USER_URL = '/users';
export const LEVEL_URL = '/levels';
export const ACCOUNT_URL = '/accounts';
export const ALLEXPNOTE_URL = '/allexpnotes';
export const APPROVEEXPNOTE_URL = '/approveexpnotes';
export const EXPNOTE_URL = '/expnotes';
export const SETTINGS_URL = '/settings';
export const TRAVEL_POLICIES_URL = "/travelpolicies";
export const TRAVEL_POLICY_URL = '/travel_policies';
export const PROJECT_URL = '/projects';
export const SUPPLIER_URL = '/suppliers';
export const REPORT_URL = '/report';
export const HISTORICAL_EXPNOTE_URL = '/historicalexpnotes';
export const MOBILE_PAGE_URL = '/mobile';
export const PROFILE_URL = '/profile';
export const ACTIVITIES_LIST_URL = '/activities_list';
export const CRE_CARD_MOV_URL = '/credit_card_mov';

// Url per andare sulle travel policy
export const REACT_TRAVEL_POLICIES_BASE_URL = '/travelpolicies';

export const DETAIL_URL = '/detail';
export const EDIT_URL = '/edit';
export const NEW_URL = '/edit/-1';

export type StateType = 'EF' | 'AA';

export const activeStateCode:StateType = 'EF';
export const canceledStateCode:StateType = 'AA';

// da utilizzare SOLO per invio/ricezione da backend
export const DateFormat = 'YYYY/MM/DD';

//TODO: da rivedere
export const CompanyCurrency = 'EUR';
export const CompanyDecimalNum = 2;
export const ExchangeDecimalNum = 6;
export const TarifDecimalNum = 5;
export const CarRouteKmDecimalNum = 2;

//TODO: da sostituire con locale predefinito letto da BE??
// utilizzare il locale utente
export const CompanyLocale: Locale = ITALIAN;

export interface GenericElement {
  id: number;
  code: string;
  description: string;
}

export interface AutocompleteOption {
  id: number;
  code: string;
  description: string;
}

export interface GenericList<T> {
  elements: T[];
  size: number;
}

export interface GenericPagedList<T> {
  elements: T[];
  page: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
}

export const getStateCode = (active: boolean): StateType => {
  return active ? activeStateCode : canceledStateCode;
}

export const getStatusClass = (status: StateType) => {
  if (status === activeStateCode) {
    return 'status-pill-success';
  }
  return 'status-pill-danger';
}

export const getStatusLabel = (status: StateType, t) => {
  if (status === activeStateCode) {
    return t("list.card.active");
  }
  return t("list.card.inactive");
}


export const isActiveStatus = (status: StateType) => {
  return status === activeStateCode;
}

export interface Currency {
  id?: number;
  code: string;
  description: string;
  decimalNum: number;
}

export interface CurrencyExchange {
  currencyCode: string;
  exchangeCurrency: string;
  startDate: Date;
  exchangeRate: number;
}

export interface FileUploadReq {
  diskName: string;
  mimeType: string;
  rawData: string | unknown;
  diskSize: number;
  diskTime: number;
  activateOcr: boolean;
}

export interface FileUploadResp {
  uploadKey: string;
  filename: string;
  mimeType: string;
}

export interface ResourceType {
  UPLOAD,
  STAFF,
  STAFF_CARD,
  PROJECT,
  EXPENSE,
  EXP_NOTE,
  ATTACHMENT,
  RECTIFIED,
  ROUTE,
  PAY_RECOV,
  TRAVEL_POLICY,
  TRAVEL_POLICY_STAFF,
  TRAVEL_POLICY_EXPENSE,
  STAFF_EXPENSE
}

export enum I18nNamespaces {
  COMMON = 'common',
  EXP_NOTE = 'exp-note',
  USER = 'user',
  VALIDATION = 'validation',
  API_ERROR = 'api-error',
}

export interface LinkCreCardMovToExpNoteDocRequest {
  docNum: number;
  creCardMovId: number;
}
