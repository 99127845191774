import {useLoggedUser} from "../../hooks/useLoggedUser";
import {Box, Divider, Stack, Typography} from "@mui/material";
import projectLogo from "../../assets/images/new-logo-ztravel-smart.png";
import playStoreLogo from "../../assets/images/googlePlayLinkLogo.png";
import appStoreLogo from "../../assets/images/appStoreLinkLogo.svg";
import homeMobile from "../../assets/images/home-mobile.png";
import homeDesktop from "../../assets/images/home-desktop.png";
import * as React from "react";

import './base.scss';
import {useTranslation} from "react-i18next";
import {MOBILE_APP_STORE_URL, MOBILE_PLAY_STORE_URL} from "../mobileStoreLinks";

export const MobilePage = () => {

  const {t} = useTranslation('dashboard', {keyPrefix: 'mobilePage'});

  const {
    user
  } = useLoggedUser();

  const isAdmin = user && user.isAdmin;

  const navigateToAppStore = () => {
    window.open(MOBILE_APP_STORE_URL, '_blank');
  }

  const navigateToPlayStore = () => {
    window.open(MOBILE_PLAY_STORE_URL, '_blank');
  }

  return (
    <Box
      className={`mobile-page-wrapper ${isAdmin ? "mobile-page-wrapper-admin" : ""}`}
    >

      <img
        alt="ZTravel Smart"
        src={projectLogo}
        className="mobile-page-logo"
      />

      <Divider className="mobile-page-logo-divider"/>

      {isAdmin ?
        <>
          {/*amministratore*/}
          <Stack
            columnGap={2}
            direction={{
              xs: 'column',
              sm: 'row'
          }}
          >
            <Box
              className='mobile-page-banner admin-banner'
            >
              <Typography variant="h4">
                {t('admin.question')}
              </Typography>

              <Typography variant="h3">
                {t('admin.desktopAccess')}
              </Typography>

              <img
                src={homeDesktop}
                className="mobile-page-desktop-screen"
              />

            </Box>

            {/*viaggiatore*/}
            <Box
              className='mobile-page-banner'
              position="relative"
            >
              <Typography variant="h4">
                {t('traveller.question')}
              </Typography>

              <Typography
                variant="h3"
                maxWidth="65%"
              >
                {t('traveller.mobileAccess')}
              </Typography>

              <Stack
                direction="column"
                alignItems="flex-start"
                rowGap={1.5}
                className="mobile-page-store-wrapper"
              >
                <Typography variant="body1">
                  {t('traveller.download')}
                </Typography>
                <img
                  src={appStoreLogo}
                  className="mobile-page-store-img"
                />
                <img
                  src={playStoreLogo}
                  className="mobile-page-store-img"
                />
              </Stack>

              <img
                height={39}
                src={homeMobile}
                onClick={navigateToPlayStore}
                className="mobile-page-mobile-screen"
              />

            </Box>
          </Stack>
        </>

        :

        // solo viaggiatore
        <Box
          className='mobile-page-banner mobile-page-banner-only-traveller'
          display='flex'
        >

          <Stack>
            <Typography variant="h4">
              {t('traveller.question')}
            </Typography>

            <Typography variant="h3">
              {t('traveller.mobileAccess')}
            </Typography>

            <Typography variant="body1">
              {t('traveller.download')}
            </Typography>
            <Stack
              mt={1.5}
              direction="row"
              justifyContent="space-between"
              overflow="hidden"
            >
              <img
                src={appStoreLogo}
                className="mobile-page-store-img"
                onClick={navigateToAppStore}
              />
              <img
                src={playStoreLogo}
                className="mobile-page-store-img"
                onClick={navigateToPlayStore}
              />
            </Stack>
          </Stack>

          <Box
            style={{
              backgroundImage: `url(${homeMobile})`
            }}
            className="mobile-page-mobile-screen"
          />

        </Box>

      }

    </Box>
  );
}
