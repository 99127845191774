import {ListItem, ListItemText, Typography} from "@mui/material";
import * as React from "react";
import {ReactChild} from "react";
import Box from "@mui/material/Box";

type ComponentProps = {
  label: string,
  hideLabel: boolean,
  children: ReactChild[],
  menuDesktopOpen: boolean
}

export const Outer = ({label, hideLabel, children, menuDesktopOpen}: ComponentProps) => {

  return (
    <>
      {!hideLabel && (
        <ListItem
          className={`role-menu`}
        >
          <ListItemText primary={<Typography variant="h6" textTransform={"uppercase"}>{label}</Typography>}/>
        </ListItem>
      )}

      <Box
        ml={1.5}
        mr={2}
        mb={3}
        display={"flex"}
        flexDirection={"column"}
      >
        {children}
      </Box>
    </>
  );
}
