import {Button, Divider, Grid, Radio, Stack, Typography} from "@mui/material";
import {formatNumber} from "../../../../../../util/NumberUtil";
import {CommonDialog} from "../../../../../base/common-dialog";
import React, {useEffect, useState} from "react";
import {ExpNoteCreCardMov} from "../../../../model";
import {useTranslation} from "react-i18next";
import {useLoggedUser} from "../../../../../../hooks/useLoggedUser";
import {DocumentType, ExpNoteExpenseFull, PayMode} from "../../../../expense/model";
import {Moment} from "moment";
import moment from "moment/moment";
import {useExpNoteDetail} from "../../../hooks/useExpNoteDetail";
import {ZtsTable} from "../../../../../base/table";
import {EmptyStateExpNote} from "../../../../empty-state";
import {ZtsTableColumn} from "../../../../../base/table/model";
import {GreyColor} from "../../../../admin/list/model";
import {formatDate} from "../../../../../../util/DateUtil";
import {ExpenseImg} from "../../../../../expenses/commons/ExpenseImg";
import {Currency, LinkCreCardMovToExpNoteDocRequest} from "../../../../../model";
import {Check} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {getExpNote, getExpNoteExpenses, linkCreCardMovToDocument} from "../../../../Service";
import {useErrorMessage} from "../../../../../../util/ErrorUtil";
import {arrayGroupByProperty} from "../../../model";
import {ExpNoteExpenseDetail} from "../../expenses/detail";
import {CompleteCreCardMovDto} from "../../../../../credit-card-mov/model";
import {getCreCardMovById} from "../../../../../credit-card-mov/Service";
import {useExpenseForm} from "../../../../../../hooks/useExpenseForm";
import {HelpCurrentPage} from "../../../../../../reducers/Help";

enum Columns {
  RADIO = 'radio',
  COMP_DATE = 'compDate',
  EXPENSE = 'expenseDesc',
  CURR_AMOUNT = 'currAmount',
}

type ComponentProps = {
  selectedMov: ExpNoteCreCardMov | null,
  closeHandler: (mov: ExpNoteCreCardMov) => void,
  currencies: Currency[]
}

export const LinkDialog = ({
                             selectedMov,
                             closeHandler,
                             currencies
                           }: ComponentProps) => {

  const {t} = useTranslation("exp-note", {keyPrefix: "creCardMovs.linkDialog"});
  const {t: tInfo} = useTranslation('cre-card-mov', {keyPrefix: 'popup.info'});

  const {
    userLocale: locale
  } = useLoggedUser();

  const {
    expNote,
    expenses,
    creditCardMovs,
    updateExpenses
  } = useExpNoteDetail();

  const {
    convertError
  } = useErrorMessage();

  const {clearFormState} = useExpenseForm();

  const [selectedDocNum, setSelectedDocNum] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [movement, setMovement] = useState<CompleteCreCardMovDto | undefined>(undefined);

  useEffect(() => {
    if (!selectedMov) {
      setSelectedDocNum(null);
      setSaving(false);
      setErrorMsg(null);
      setMovement(undefined);
    }
  }, [selectedMov]);

  const columns: ZtsTableColumn[] = [];

  columns.push(
    {
      id: Columns.RADIO,
      label: '',
      unsortable: true,
      align: 'left',
      style: {width: '48px', maxWidth: '48px'},
      formatter: (id, row: ExpNoteExpenseFull) => {
        return (
          <Radio
            disableRipple
            checked={selectedDocNum === row.docNum}
            onClick={() => setSelectedDocNum(row.docNum)}
          />
        )
      },
      hideOnAggregate: true
    },
    {
      id: Columns.COMP_DATE,
      label: t("list.date"),
      unsortable: true,
      style: {width: '100px', maxWidth: '100px'},
      cellColor: GreyColor,
      typography: 'caption',
      formatter: (value) => formatDate(value, locale),
      hideOnAggregate: true
    },
    {
      id: Columns.EXPENSE,
      label: t("list.expense"),
      unsortable: true,
      style: {width: '170px', maxWidth: '170px'},
      formatter: (expenseDesc, row: ExpNoteExpenseFull) => (
        <Stack
          direction="row"
          columnGap={1}
        >
          {expenseDesc && <ExpenseImg img={row.expenseIcon} label=""/>}
          <Typography variant="body1">{expenseDesc}</Typography>
        </Stack>
      )
    },
    {
      id: Columns.CURR_AMOUNT,
      label: t("list.amount"),
      unsortable: true,
      style: {width: '100px', maxWidth: '100px'},
      align: 'right',
      formatter: (amount, r: ExpNoteExpenseFull) => {
        const decimalNum = currencies.find(c => c.code === r.currencyCode)?.decimalNum || 0;
        return formatNumber(r.currAmount, locale, decimalNum);
      },
      cssOnAggregate: 'expense-list-cell-small-size'
    }
  );

  const filterLinkableDocuments = (docExpenses: Map<number, ExpNoteExpenseFull[]>): Map<number, ExpNoteExpenseFull[]> => {
    if (!selectedMov) {
      return new Map<number, ExpNoteExpenseFull[]>();
    }

    const filteredDocs: Map<number, ExpNoteExpenseFull[]> = new Map<number, ExpNoteExpenseFull[]>();

    docExpenses.forEach((expenses, docNum) => {
      const expense = expenses[0];
      const compDate = expense.compPeriodEnd ? expense.compEndDate : expense.compDate;
      const docAmount = expenses.reduce((sum, exp) => sum + exp.currAmount, 0);

      if (isLinkableExpense(expense, compDate, docAmount)) {
        filteredDocs.set(docNum, expenses);
      }
    });

    return filteredDocs;
  }

  const isLinkableExpense = (expense: ExpNoteExpenseFull, compDate: Date, amount: number): boolean => {
    if (!selectedMov) {
      return false;
    }

    // escludo le spese associate a voci a tariffa o ad importo fisso
    if (expense.docType === DocumentType.NONE) {
      return false;
    }

    // se la spesa è già collegata ad un altro movimento allora non è collegabile
    if (creditCardMovs && creditCardMovs.find(c => c.docNum === expense.docNum)) {
      return false;
    }

    // se data e importo sono uguali allora la spesa è collegabile (non importa il metodo di pagamento)
    if (compDate === selectedMov.payDate &&
      amount === selectedMov.currAmount &&
      expense.currencyCode === selectedMov.currency) {
      return true;
    }

    // se importo e metodo di pagamento sono uguali la spesa è collegabile
    // se la data rientra nel range "3gg prima - 3gg dopo" (rispetto alla data del movimento)
    if (amount === selectedMov.currAmount &&
      expense.currencyCode === selectedMov.currency &&
      expense.payMode === PayMode.CARD &&
      expense.cardNum === selectedMov.creCardNum
    ) {
      const rangeStart: Moment = moment(selectedMov.payDate).subtract(3, 'days');
      const rangeEnd: Moment = moment(selectedMov.payDate).add(3, 'days');
      if (moment(compDate).isBetween(rangeStart, rangeEnd, 'days')) {
        return true;
      }
    }

    // negli altri casi la spesa NON è collegabile
    return false;
  }

  const expByDocument: Map<number, ExpNoteExpenseFull[]> = expenses && expenses.length > 0 ? filterLinkableDocuments(arrayGroupByProperty(expenses, 'docNum')) : new Map<number, ExpNoteExpenseFull[]>();

  let linkableExpenses: any[] = [];

  expByDocument.forEach((exps, docNum) => {
    if (exps.length === 1) {
      linkableExpenses.push({...exps[0], check: docNum});
    } else {
      const docAmount = exps.reduce((sum, exp) => sum + exp.currAmount, 0);
      const aggregateRow: any = {...exps[0]};
      const heading: any = {...exps[0], id: -10 * docNum, check: docNum, currAmount: docAmount, hideAggregate: false};
      heading[Columns.EXPENSE] = '';
      let groupedRows: any = exps.map(r => ({...r, hideAggregate: true}));
      groupedRows = [heading, ...groupedRows];
      aggregateRow.childrenRows = <ZtsTable showHeader={false} columns={columns} rows={groupedRows}></ZtsTable>;
      linkableExpenses.push(aggregateRow);
    }
  });

  // aggiunge una riga vuota per il problema dell'allineamento quando esistono righe raggruppate per numero documento
  // quindi una riga che contiene più righe con una innerTable, per questa riga verrà poi impostata la classe 'document-hidden-row'
  // che mette l'altezza a 0px in modo da non mostrare la riga
  let emptyRow: any = linkableExpenses.find((_, index) => index === 0);
  if (emptyRow) {
    emptyRow = {...emptyRow, id: -1, check: -1, docNum: -1, childrenRows: null};
    linkableExpenses = [emptyRow, ...linkableExpenses];
  }

  const linkExpense = async () => {
    if (!selectedMov || !selectedDocNum || !expNote) {
      return;
    }

    setSaving(true);
    try {
      const req: LinkCreCardMovToExpNoteDocRequest = {
        docNum: selectedDocNum,
        creCardMovId: selectedMov.movId
      }
      const mov = await linkCreCardMovToDocument(expNote.id, req);
      closeHandler(mov);
    } catch (error) {
      convertError(error as Error).then(res => setErrorMsg(res));
    } finally {
      setSaving(false);
    }
  }

  const handleCreateNewExpense = async () => {
    if (!selectedMov) {
      return;
    }

    try {
      const movement = await getCreCardMovById(selectedMov.movId);
      setMovement(movement);
    } catch (error) {
      convertError(error as Error).then(res => setErrorMsg(res));
    }
  }

  const handleCloseCreateNewExpense = async (created: boolean) => {
    setMovement(undefined);

    if (created && expNote) {
      const newExpNote = await getExpNote(expNote.id);
      const expenses = await getExpNoteExpenses(expNote.id);
      updateExpenses(newExpNote, expenses);
    }
    clearFormState();
  }

  return (
    <>
      <CommonDialog
        show={!!selectedMov}
        title={t("title")}
        widths={[
          {breakpoint: "lg", width: "450px"}
        ]}
        saving={saving}
        onClose={closeHandler}
        errorMsg={errorMsg}
        showCta={false}
        page={!!selectedMov ? HelpCurrentPage.EXP_NOTE_CRE_CARD_MOV_LINK_DIALOG : HelpCurrentPage.EXP_NOTE_CRE_CARD_MOV}
        className="movement-link-doc"
      >
        {selectedMov ? (
          <>
            <Typography variant={"h5"} mb={2}>{t("movement")}</Typography>

            <Grid container rowGap={2} p={2} sx={{backgroundColor: '#f2f5f8', borderRadius: '15px'}}>
              <Grid item xs={6}>
                <Stack rowGap={1}>
                  <Typography variant={'h6'}>{tInfo('movement.movDate')}</Typography>
                  <Typography variant={'body2'}>{formatDate(selectedMov.payDate, locale)}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack rowGap={1}>
                  <Typography variant={'h6'}>{tInfo('movement.description')}</Typography>
                  <Typography variant={'body2'}>{selectedMov.description}</Typography>
                </Stack>
              </Grid>
              <Grid item container spacing={4}>
                <Grid item>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{tInfo('movement.currAmount')}</Typography>
                    <Typography
                      variant={'body2'}
                      textAlign="right"
                    >
                      {formatNumber(selectedMov.currAmount, locale, currencies.find(c => c.code === selectedMov.currency)?.decimalNum || 0)}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{tInfo('movement.currency')}</Typography>
                    <Typography variant={'body2'}>{selectedMov.currency}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Typography variant={"h5"} mt={5} mb={2}>{t("linkExpense")}</Typography>

            <ZtsTable
              extraClassName={new Map().set(-1, 'document-hidden-row')}
              columns={columns}
              rows={linkableExpenses || []}
            />
            {
              (!linkableExpenses || linkableExpenses.length === 0) &&
              <EmptyStateExpNote
                message={t("emptyList")}
              />
            }

            {linkableExpenses && linkableExpenses.length > 0 &&
              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  sx={{marginTop: "12px", display: "flex", justifyContent: "end"}}
                  variant="contained"
                  disabled={selectedDocNum == null}
                  className={selectedDocNum ? "cta-button-primary-small" : "cta-button-small"}
                  onClick={linkExpense}
                >
                  <Check fontSize={"small"} sx={{fontSize: '15px'}}/>
                  <Typography ml={1} variant={"button"}>{t('linkExpense')}</Typography>
                </Button>
              </Box>
            }

            {linkableExpenses && linkableExpenses.length > 0 &&
              <Divider sx={{marginTop: '20px', marginBottom: '20px'}}/>
            }

            <Typography
              variant={"h5"}
              mt={linkableExpenses && linkableExpenses.length > 0 ? 2 : 4}
            >
              {t("newExpenseFromMovement")}
            </Typography>

            <Button
              sx={{marginTop: "15px", display: "flex", justifyContent: "end"}}
              variant="contained"
              className="cta-button-primary-small"
              onClick={handleCreateNewExpense}
            >
              <Check fontSize={"small"} sx={{fontSize: '15px'}}/>
              <Typography ml={1} variant={"button"}>{t('createNewExpense')}</Typography>
            </Button>

          </>
        ) : <></>}
      </CommonDialog>

      <ExpNoteExpenseDetail
        show={!!movement}
        onClose={handleCloseCreateNewExpense}
        expenses={null}
        create={!!movement}
        isReadonly={false}
        movement={movement}
      />
    </>
  );
}
