import {Pagination, Skeleton, Stack, Typography} from "@mui/material";
import {ZtsTable} from "../../base/table";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {ZtsTableColumn} from "../../base/table/model";
import {formatAmountZero} from "../../../util/NumberUtil";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {DashboardExpenseTotalByStaff, DashboardExpenseTotalByStaffPolicy, DashboardExpNoteCount} from "../model";
import {countAdminExpNotes, getExpenseToCompleteTotalsByStaffPolicy} from "../Service";
import SimpleJsLog from "../../../util/Logger";
import {Logger} from 'react-logger-lib';
import {ExpNoteState} from "../../exp_note/model";
import {useTranslation} from "react-i18next";
import {arrayGroupByProperty} from "../../exp_note/detail/model";

const LOG: SimpleJsLog = Logger.of('ZTS.Dashboard.DashboardExpNoteList');

interface DashBoardExpNote {
  traveller: string,
  count: number,
  travelPolicyDesc: string,
  total: number
}

export const DashboardExpNoteList = () => {

  const {t} = useTranslation('dashboard', {keyPrefix: 'admin.expNoteList'});

  const {
    userLocale,
    companyCurrencyCode,
    companyDecimalNum
  } = useLoggedUser();

  const PAGE_SIZE = 5;

  const [counter, setCounter] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DashboardExpenseTotalByStaff[]>([]);
  const [pagedData, setPagedData] = useState<DashboardExpenseTotalByStaff[]>([]);

  const columns: ZtsTableColumn[] = [];

  columns.push(
    {
      id: 'traveller',
      label: '',
      formatter: (value, row) => `${value} ${row.expNoteCount ? `(${row.expNoteCount})` : ''}`
    },
    {
      id: 'travelPolicyDesc',
      label: '',
      typography: 'caption',
      formatter: (value, row) =>
        <Stack rowGap={1}>
          {row.travelPolicies.map(tp =>
            <Box className="dashboard-pill">
              <Typography variant={"subtitle2"}>{tp.description}</Typography>
            </Box>
          )}
        </Stack>
    },
    {
      id: 'total',
      label: '',
      align: 'right',
      style: {width: '120px', maxWidth: '120px'},
      formatter: (value) => formatAmountZero(value, userLocale, companyDecimalNum, companyCurrencyCode)
    }
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getExpenseToCompleteTotalsByStaffPolicy(),
      countAdminExpNotes([
          ExpNoteState.DA_COMPLETARE,
          ExpNoteState.DA_APPROVARE,
          ExpNoteState.DA_RIVEDERE
        ],
        true)
    ])
      .then(resArray => {
        const data = resArray[0];
        const elabData: DashboardExpenseTotalByStaff[] = [];

        const dataByStaffId: Map<number, DashboardExpenseTotalByStaffPolicy[]> = arrayGroupByProperty(data, "staffId");

        dataByStaffId.forEach(d => {
          const totalByStaff = d.reduce((sum, c) => sum + c.total, 0);
          elabData.push({
            staffId: d[0].staffId,
            traveller: d[0].traveller,
            travelPolicies: d.map(elm => ({id: elm.travelPolicyId, description: elm.travelPolicyDesc})),
            total: totalByStaff,
            expNoteCount: 0
          });
        });

        const countData = resArray[1];

        const countByStaffId: Map<number, DashboardExpNoteCount[]> = arrayGroupByProperty(countData, "staffId");
        countByStaffId.forEach((staffData, staffId) => {
          const found = elabData.find(e => e.staffId === staffId);
          if (found) {
            found.expNoteCount = staffData.reduce((sum, c) => sum + c.count, 0);
          }
        });
        setData(elabData);

        setCounter(countData.reduce((sum, c) => sum + c.count, 0));
      })
      .catch(err => LOG.trace(`Error retrieving expense total by staff/policy: ${err}`))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setTotalPages(Math.floor(data.length / PAGE_SIZE) + (data.length % PAGE_SIZE ? 1 : 0));
  }, [data]);

  useEffect(() => {
    const start = (page - 1) * PAGE_SIZE;
    let end = page * PAGE_SIZE;
    if (end > data.length) {
      end = data.length;
    }
    setPagedData(data.slice(start, end));
  }, [data, page]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  }

  const total = data.map(d => d.total).reduce((sum, total) => sum + total, 0);

  const getTitle = () => {
    return <Typography variant="h4">{t("title", {counter})}</Typography>;
  }

  const getTotalAmount = () => {
    return <Typography
      variant="h4">
      {t("total", {amount: formatAmountZero(total, userLocale, companyDecimalNum, companyCurrencyCode)})}
    </Typography>;
  }

  return (
    <Stack height="100%">
      <Stack
        mt={0.5}
        mx={1.2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {loading ? (
          <>
            <Skeleton variant="rectangular" height="50px" width="300px" sx={{mb: 1, transform: "none"}}/>
            <Skeleton variant="rectangular" height="50px" width="150px" sx={{mb: 1, transform: "none"}}/>
          </>
        ) : (
          <>
            {getTitle()}
            {getTotalAmount()}
          </>
        )}

      </Stack>
      <Stack
        justifyContent="space-between"
        flexGrow={1}
        mt={1}
        flexBasis="250px"
      >

        {loading && (
          <Box mx={1.2}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height="40px"
              sx={{mt: 1, mb: 1}}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height="40px"
            />
          </Box>
        )}

        {!loading && pagedData && (
          <>
            <ZtsTable
              showHeader={false}
              columns={columns}
              rows={pagedData}
            />
            {pagedData.length > 0 && (
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  shape="rounded"/>
              </Box>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
}
