import {Route, Routes} from "react-router-dom";
import {useHelp} from "../help/hook";
import {DETAIL_URL, EXPNOTE_URL} from "../model";
import {HelpCurrentPage} from "../../reducers/Help";
import {ExpNoteTravellerList} from "./traveller/list";
import {ExpNoteDetail} from "./detail";
import {ProtectedRoute} from "../base/protected-routes";
import {useExpNoteList} from "./hooks/useExpNoteList";
import {ExpNoteListRole} from "../../reducers/ExpNoteList";
import {useLoggedUser} from "../../hooks/useLoggedUser";

export const ExpNoteTraveller = () => {

  useHelp({
    paths: [
      {
        path: `${EXPNOTE_URL}`,
        page: HelpCurrentPage.EXP_NOTE_TRAVELLER,
        exactMatch: true
      }
    ]
  });

  const {user: loggedUser} = useLoggedUser();

  const {
    currentRole,
    updateCurrentRole
  } = useExpNoteList();

  if (loggedUser && loggedUser.isTraveller && (!currentRole || currentRole !== ExpNoteListRole.TRAVELLER)) {
    updateCurrentRole(ExpNoteListRole.TRAVELLER)
  }

  return (
    <ProtectedRoute isTraveller>
      <Routes>
        <Route path="/" element={<ExpNoteTravellerList/>}/>
        <Route path={`${DETAIL_URL}/:id/*`} element={<ExpNoteDetail isAdmin={false}/>}/>
        {/*<Route path="/edit/:id" element={<LevelEdit/>}/>*/}
      </Routes>
    </ProtectedRoute>
  );
}
