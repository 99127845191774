// React
import React from "react";

import {Controller, useFormContext} from "react-hook-form";

import {TravelPolicy} from "../model";
import {Grid, Stack, Typography} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import {DatePickerZts} from "../../base/date-picker";
import {useTranslation} from "react-i18next";

type TravelPolicyMainDataProps = {
  travelPolicy: TravelPolicy,
  editable?: boolean
}
export const TravelPolicyMainData: React.FC<TravelPolicyMainDataProps> = ({travelPolicy, editable}) => {
  const {t: tCommon} = useTranslation('travel-policy', {keyPrefix: 'common.label'});
  let control;
  if (editable) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formMethods = useFormContext();
    control = formMethods.control;
  }

  const sectionLab = tCommon('generalData');
  const codeLbl = tCommon('code');
  const descLbl = tCommon('description');
  const startDateLbl = tCommon('startDate');
  const endDateLbl = tCommon('endDate');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' textTransform='uppercase'>{sectionLab}</Typography>
      </Grid>
      <Grid item xs={2} xl={1}>
        { editable && false
          ? ( <Controller
            name="code"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                required
                label={codeLbl}
                errorMsg={fieldState.error?.message}
              />
            }}
          /> )
          :(
            <TextFieldZts
              disabled={true}
              label={codeLbl}
              value={travelPolicy.code}
            />
          )
        }
      </Grid>

      <Grid item xs={10} lg={4} xl={5}>
        { editable
          ? ( <Controller
            name="description"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                required
                label={descLbl}
                errorMsg={fieldState.error?.message}
              />
            }}
          /> )
          :(
            <TextFieldZts
              disabled
              label={descLbl}
              value={travelPolicy.description}
            />
          )
        }
      </Grid>

      <Grid item xs={9} lg={6}>
        <Stack direction='row' spacing={2}>
        { editable
          ? <>
            <Controller
              name="startDate"
              control={control}
              render={({field, fieldState}) => {
                return <DatePickerZts
                  required
                  field={field}
                  label={startDateLbl}
                  errorMsg={fieldState.error?.message}
                  onChangeHandler={(value: Date) => {
                    //formikProps.setFieldValue(field.name, value);
                    field.onChange(value);
                  }}
                />
              }}
            />
            <Controller
              name="endDate"
              control={control}
              render={({field, fieldState}) => {
                return <DatePickerZts

                  field={field}
                  label={endDateLbl}
                  errorMsg={fieldState.error?.message}
                  onChangeHandler={(value: Date) => {
                    //formikProps.setFieldValue(field.name, value);
                    field.onChange(value);
                  }}
                />
              }}
            />
          </>
          : <>
            <DatePickerZts
              disabled
              label={startDateLbl}
              disabledValue={travelPolicy.startDate}
            />
            <DatePickerZts
              disabled
              label={endDateLbl}
              disabledValue={travelPolicy.endDate}
            />
          </>
        }
        </Stack>
      </Grid>

    </Grid>
  );

}
