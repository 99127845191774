import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CommonDialog} from "../../base/common-dialog";
import {getCarRouteMap} from "../Service";
import {Skeleton} from "@mui/material";
import {useErrorMessage} from "../../../util/ErrorUtil";

type ComponentProps = {
  show: boolean,
  addresses: string[],
  onClose: Function
}

export const CarRoutesMap = ({show, addresses, onClose}: ComponentProps) => {

  const {t} = useTranslation("exp-note", {keyPrefix: "expense.edit"});

  const [mapUrl, setMapUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const {
    convertError
  } = useErrorMessage();

  useEffect(() => {
      if (show && addresses && !mapUrl) {
        setLoading(true);
        setErrorMsg(null);
        getCarRouteMap(addresses)
          .then(res => setMapUrl(URL.createObjectURL(res)))
          .catch(err => convertError(err).then(msg => setErrorMsg(msg)))
          .finally(() => setLoading(false));
        return;
      }

      if (!show && mapUrl) {
        URL.revokeObjectURL(mapUrl);
        setMapUrl(null);
      }
    }, [addresses, show, mapUrl]
  );

  const getLoader = (): JSX.Element | null => {
    if (loading) {
      return <Skeleton
        width="500px"
        height="500px"
        variant="rectangular"
      />
    }
    return null;
  }

  const getMap = (): JSX.Element | null => {
    if (mapUrl) {
      let externalLink = `https://www.google.it/maps/dir`;
      addresses.forEach(addr => externalLink += `/${addr}`);

      return (
        <a
          href={externalLink}
          title={t("openExternalMap")}
          target="_blank"
          rel="noreferrer"
        >
          <img src={mapUrl} alt=""/>
        </a>
      );
    }
    return null;
  }

  return (
    <CommonDialog
      show={show}
      title={t("map")}
      widths={[
        {breakpoint: "md", width: "580px"},
      ]}
      errorMsg={errorMsg}
      saving={false}
      onClose={onClose}
      hideSave={true}
    >
      <>
        {getLoader()}
        {getMap()}
      </>
    </CommonDialog>
  )
}
