import React, {useEffect, useState} from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";
import BusinessIcon from "@mui/icons-material/Business";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StorageIcon from '@mui/icons-material/Storage';
import {getCompanyLogo, getSettings, testCoDi, updateSettings, uploadLogo} from "./Service";
import {useNavigate} from "react-router-dom";
import {Alert, Box, Button, FormControlLabel, Grid, Paper, Snackbar, Stack, Typography} from "@mui/material";
import {PageTitle} from "../layout/page-title";
import {Save} from "@mui/icons-material";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../base/autocomplete/model";
import {getAccounts} from "../accounts/Service";
import {CompanyData, DigitStoreData, SaveSettings, SettingsFormValues, TestCoDiRequest} from "./model";
import {Account} from "../accounts/model";
import {useHelp} from "../help/hook";
import {FileUploadReq, SETTINGS_URL} from "../model";
import {HelpCurrentPage} from "../../reducers/Help";
import {createError, useErrorMessage} from "../../util/ErrorUtil";
import {TabBar} from "../base/tab-bar";
import {TextFieldZts} from "../base/text-field";
import {AutocompleteZts} from "../base/autocomplete";
import {IOSSwitch} from "../base/switch";
import {ImageViewer} from "../base/image/viewer";
import {useSnackbar} from "notistack";
import {PremiumIcon} from "../base/premium-icon";
import {useLicense} from "../../hooks/useLicense";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import { yupMaxNullableString, yupMinMaxRequiredString} from "../../util/YupUtil";
import {LicensePopup} from "../license-popup";
import {PopupContentType} from "../license-popup/model";
import {isDigitalStorageModuleEnabled} from "../../config/token";

type AccountData = {
  accountCompCashId: AutocompleteGenericOption,
  accountVatId: AutocompleteGenericOption,
  accountSupplierId: AutocompleteGenericOption,
  accountStaffId: AutocompleteGenericOption
}

export function Settings() {
  //------
  // State
  //------
  const [digitStore, setDigitStore] = useState<DigitStoreData>({});
  const [compData, setCompData] = useState<CompanyData>({description: "", vatNumber: "", currencyCode: "", pictureUniqueName: ""});
  const [monthlyExpEnabled, setMonthlyExp] = useState<boolean>();
  const [newExpEnabled, setNewExpEnabled] = useState<boolean>();
  const [digitStoreEnabled, setDigitStoreEnabled] = useState<boolean>();

  const [options, setOptions] = useState<AutocompleteGenericOption[]>([]);
  const [accountData, setAccountData] = useState<AccountData | null>(null);

  const [logoId, setLogoId] = useState<string | null | undefined>();
  const [tempLogo, setTempLogo] = useState<boolean>(!!logoId);

  const [errorMsg, setErrorMsg] = useState<string | null>(null) // TODO: refactor componente per mostrare errori
  const [feedbackMsg, setFeedbackMsg] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState<string>("dati");
  const [loading, setLoading] = useState<boolean>();
  const [ready, setReady] = useState<boolean>(false);

  const [dataError, setDataError] = useState<boolean>(false);
  const [digitError, setDigitError] = useState<boolean>(false);

  const companyData = ['description', 'vatNumber', 'telephone', 'address', 'locality', 'zipCode', 'notes'];
  const digitData = ['digitStoreUsername', 'digitStorePassword', 'digitStoreUrl'];

  const {t: tLicense} = useTranslation('license', {keyPrefix: 'pro.menu'});
  const {t: tComm} = useTranslation('common');
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [popupTitle, setPopupTitle] = useState<string | null>(null);
  const [popupContentType, setPopupContentType] = useState<PopupContentType | null>(null);
  const openPopup = (type: PopupContentType) => {
    setPopupTitle(tLicense(`${type.toString()}.title`));
    setPopupContentType(type);
    setPopupOpen(true);
  }
  const closePopup = () => {
    setPopupTitle(null);
    setPopupContentType(null);
    setPopupOpen(false);
  }

  //------
  // Hooks
  //------

  const navigate = useNavigate();
  const {convertError} = useErrorMessage();
  const {enqueueSnackbar} = useSnackbar();

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const {t: tSetting} = useTranslation('setting');

  useHelp({
    paths: [
      {
        path: SETTINGS_URL,
        page: HelpCurrentPage.SETTINGS
      }
    ]
  });

  //-----------
// Validation
//-----------

  const {t} = useTranslation(['validation']);

  const yupDigitStoreUrl = () => {
    const maxLength = 100;
    const prefix = 'https://';
    const startsWithRegExp = RegExp(`^(${prefix}|$)`);

    const maxErr = t('invalidMaxLength', {length: maxLength});
    const prefixErr = t('notMatchingPrefix', {prefix: "https://"});

    return Yup.string()
      .nullable()
      .max(maxLength, maxErr)
      .matches(startsWithRegExp, prefixErr)
  }

  const yupDigitStorePassword = () => {
    const maxLenPreEditing = 200;
    const maxErrPreEditing = t('invalidMaxLength', {length: maxLenPreEditing});

    const maxLenPostEditing = 50;
    const maxErrPostEditing = t('invalidMaxLength', {length: maxLenPostEditing});

    return Yup.string()
      .nullable()
      .when('digitStorePasswordEdited', {
          is: true,
          then: Yup.string().nullable().max(maxLenPostEditing, maxErrPostEditing),
          otherwise: Yup.string().nullable().max(maxLenPreEditing, maxErrPreEditing)
      })
  }

  const validationSchema = Yup.object({
    digitStoreUsername: yupMaxNullableString(50, t),
    digitStorePassword: yupDigitStorePassword(),
    digitStoreUrl: yupDigitStoreUrl(),
    description: yupMinMaxRequiredString(3, 100, t),
    locality: yupMaxNullableString(100, t),
    address: yupMaxNullableString(100, t),
    zipCode: yupMaxNullableString(50, t),
    telephone: yupMaxNullableString(15, t),
    vatNumber: yupMinMaxRequiredString(3, 100, t),
    pictureUniqueName: yupMaxNullableString(200, t),
    notes: yupMaxNullableString(200, t),
    digitStorePasswordEdited: Yup.boolean()
  });

  // Form Methods init
  const formMethods = useForm<SettingsFormValues>({
    defaultValues: {
      digitStoreUsername: "",
      digitStorePassword: "",
      digitStoreUrl: "",
      digitStoreEnabled: false,
      accountCompCashId: null,
      accountVatId: null,
      accountSupplierId: null,
      accountStaffId: null,
      description: "",
      locality: "",
      address: "",
      zipCode: "",
      telephone: "",
      vatNumber: "",
      currencyCode: "",
      pictureUniqueName: "",
      notes: "",
      expNoteMonthly: false,
      newExpNoteAllowed: false,
      digitStorePasswordEdited: false
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const digitStoreUsernameWatch = formMethods.watch('digitStoreUsername');
  const digitStorePasswordWatch = formMethods.watch('digitStorePassword');
  const digitStoreUrlWatch = formMethods.watch('digitStoreUrl');
  const [testButtonEnabled, setTestButtonEnabled] = useState<boolean>(false);

  useEffect(() => {
    asyncGetAndSetAllSettings().then();
  }, []);

  useEffect(() => {
    if (!loading && accountData && ready) {
      formMethods.reset({
        digitStoreUsername: digitStore.digitStoreUsername,
        digitStorePassword: digitStore.digitStorePassword,
        digitStoreUrl: digitStore.digitStoreUrl,
        digitStoreEnabled: digitStoreEnabled,
        accountCompCashId: accountData.accountCompCashId,
        accountVatId: accountData.accountVatId,
        accountSupplierId: accountData.accountSupplierId,
        accountStaffId: accountData.accountStaffId,
        description: compData.description,
        locality: compData.locality,
        address: compData.address,
        zipCode: compData.zipCode,
        telephone: compData.telephone,
        vatNumber: compData.vatNumber,
        pictureUniqueName: compData.pictureUniqueName,
        notes: compData.notes,
        expNoteMonthly: monthlyExpEnabled,
        newExpNoteAllowed: newExpEnabled
      });
    }
  }, [loading]);

  useEffect(() => {
    if (!ready) {
      setReady(true);
    }
  }, [setAccountData]);

  useEffect(() => {
    if (!digitStoreUsernameWatch || (digitStoreUsernameWatch && digitStoreUsernameWatch.length === 0)) {
      setTestButtonEnabled(false);
      return;
    }
    if (!digitStorePasswordWatch || (digitStorePasswordWatch && digitStorePasswordWatch.length === 0)) {
      setTestButtonEnabled(false);
      return;
    }
    if (!digitStoreUrlWatch || (digitStoreUrlWatch && digitStoreUrlWatch.length === 0)) {
      setTestButtonEnabled(false);
      return;
    }
    setTestButtonEnabled(true);
  }, [digitStoreUsernameWatch, digitStorePasswordWatch, digitStoreUrlWatch]);

  //----------
  // Functions
  //----------

  const asyncGetAndSetAllSettings = async (): Promise<void> => {
    setLoading(true);
    let accounts;

    getAccounts()
      .then((response) => {
        if (response.data.size > 0) {
          accounts = response.data.elements;
          // setAccounts(accounts);
          const optAccounts = accounts.filter((account: Account) => {
            return account.state === 'EF';
          });
          let options = optAccounts.map((account) => {
            return createAutocompleteGenericOption(account.id, account.code, account.description);
          });
          if (options) {
            setOptions(options);
          }
        }
        return getSettings();
      })
      .then((response) => {
        setLogoId(response.data.pictureUniqueName);
        setTempLogo(false);
        let compCash, vat, supplier, transitional;
        if (accounts && response.data) {
          accounts.filter((account) => {
            if (account.id == response.data.accountCompCashId) {
              compCash = createAutocompleteGenericOption(account.id, account.code, account.description);
            }
            if (account.id == response.data.accountVatId) {
              vat = createAutocompleteGenericOption(account.id, account.code, account.description);
            }
            if (account.id == response.data.accountSupplierId) {
              supplier = createAutocompleteGenericOption(account.id, account.code, account.description);
            }
            if (account.id == response.data.accountStaffId) {
              transitional = createAutocompleteGenericOption(account.id, account.code, account.description);
            }
          });
        }
        setAccountData({
          accountCompCashId: compCash ? compCash : null,
          accountVatId: vat ? vat : null,
          accountSupplierId: supplier ? supplier : null,
          accountStaffId: transitional ? transitional : null
        });

        if (response) {
          setCompData({
            description: response.data.description,
            locality: response.data.locality,
            address: response.data.address,
            zipCode: response.data.zipCode,
            telephone: response.data.telephone,
            vatNumber: response.data.vatNumber,
            currencyCode: response.data.currencyCode ? response.data.currencyCode : '',
            pictureUniqueName: response.data.pictureUniqueName ? response.data.pictureUniqueName : '',
            notes: response.data.notes,
          });

          setDigitStore({
            digitStoreUsername: response.data.digitStoreUsername,
            digitStorePassword: response.data.digitStorePassword,
            digitStoreUrl: response.data.digitStoreUrl,
          });

          setDigitStoreEnabled(response.data.digitStoreEnabled);

          setMonthlyExp(response.data.expNoteMonthly);

          setNewExpEnabled(response.data.newExpNoteAllowed);
        }
      })
      .catch((error) => {
        convertError(createError(error))
          .then((msg) => setErrorMsg(msg))
      })
      .finally(() => setLoading(false));
  }

  const onCancelClick = () => {
    asyncGetAndSetAllSettings()
      .then(() => {
        enqueueSnackbar(tSetting('snackBar.editCanceled'), {
          variant: "info",
          autoHideDuration: 2500,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top"
          }
        });
      })
    // if (Object.keys(formMethods.control._formState.touchedFields).length > 0) {
    //   formMethods.reset({
    //     digitStoreUsername: digitStore.digitStoreUsername,
    //     digitStorePassword: digitStore.digitStorePassword,
    //     digitStoreUrl: digitStore.digitStoreUrl,
    //     digitStoreEnabled: digitStoreEnabled ? digitStoreEnabled : null,
    //     accountCompCashId: accountData ? accountData.accountCompCashId : null,
    //     accountVatId: accountData ? accountData.accountVatId : null,
    //     accountSupplierId: accountData ? accountData.accountSupplierId : null,
    //     accountStaffId: accountData ? accountData.accountStaffId : null,
    //     description: compData.description,
    //     locality: compData.locality,
    //     address: compData.address,
    //     zipCode: compData.zipCode,
    //     telephone: compData.telephone,
    //     vatNumber: compData.vatNumber,
    //     pictureUniqueName: compData.pictureUniqueName,
    //     notes: compData.notes,
    //     expNoteMonthly: monthlyExpEnabled,
    //     newExpNoteAllowed: newExpEnabled
    //   });
    //   enqueueSnackbar(tSetting('snackBar.editCanceled'), {
    //     variant: "info",
    //     autoHideDuration: 2500,
    //     anchorOrigin: {
    //       horizontal: "right",
    //       vertical: "top"
    //     }
    //   });
    // }
  }

  const handleSave = (values) => {
    if (values.digitStoreEnabled === true) {
      if (!values.digitStoreUsername || !values.digitStorePassword || !values.digitStoreUrl) {
        setErrorMsg(tSetting('error.missingDigitStoreConfig'));
        return;
      }
    } else {
      formMethods.setValue('digitStoreUsername', null);
      formMethods.setValue('digitStorePassword', null);
      formMethods.setValue('digitStoreUrl', null);
    }

    const toSave: SaveSettings = {
      digitStoreUsername: values.digitStoreUsername,
      digitStorePassword: values.digitStorePassword,
      digitStoreUrl: values.digitStoreUrl,
      digitStoreEnabled: values.digitStoreEnabled,
      accountCompCashId: values.accountCompCashId ? values.accountCompCashId.id : null,
      accountVatId: values.accountVatId ? values.accountVatId.id : null,
      accountSupplierId: values.accountSupplierId ? values.accountSupplierId.id : null,
      accountStaffId: values.accountStaffId ? values.accountStaffId.id : null,
      description: values.description,
      locality: values.locality,
      address: values.address,
      zipCode: values.zipCode,
      telephone: values.telephone,
      vatNumber: values.vatNumber,
      pictureUniqueName: values.pictureUniqueName,
      notes: values.notes,
      expNoteMonthly: values.expNoteMonthly,
      newExpNoteAllowed: values.newExpNoteAllowed,
      digitStorePasswordEdited: values.digitStorePasswordEdited ? values.digitStorePasswordEdited : false
    }
    updateSettings(toSave)
      .then(() => {
        enqueueSnackbar(tSetting('snackBar.successfulSave'), {
          variant: "success",
          autoHideDuration: 3500,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top"
          }
        });
        formMethods.control._formState.touchedFields = {};
        formMethods.setValue("digitStorePasswordEdited", false);
      })
      .catch((error) => {
        convertError(createError(error))
          .then((msg) => {
            enqueueSnackbar(msg, {
              variant: "error",
              autoHideDuration: 3500,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top"
              }
            })
            setErrorMsg(msg);
          })
      })
  }

  // Handler pulsante 'Salva'
  const handleSaveClicked = () => {
    if (dataError) {
      setDataError(false);
      formMethods.handleSubmit(handleSave)();
    }
    if (digitError) {
      setDigitError(false);
      formMethods.handleSubmit(handleSave)();
    }
    companyData.some((str) => {
      if (str in formMethods.control._formState.errors) {
        setDataError(true);
        return;
      }
    });
    digitData.some((str) => {
      if (str in formMethods.control._formState.errors) {
        setDigitError(true);
        return;
      }
    })
    if (!dataError && !digitError) {
      formMethods.handleSubmit(handleSave)();
    }

  }

  // Handler switch 'Note spese mensili'
  const firstCheckHandler = () => {
    formMethods.setValue('expNoteMonthly', monthlyExpEnabled ? !monthlyExpEnabled : true);
    setMonthlyExp((prevState) => !prevState);
  }

  // Handler switch 'Nuova nota spese'
  const secondCheckHandler = () => {
    formMethods.setValue('newExpNoteAllowed', newExpEnabled ? !newExpEnabled : true);
    setNewExpEnabled((prevState) => !prevState);
  }

  const resetDigitStoreValues = () => {
    formMethods.setValue('digitStoreUrl', '');
    formMethods.setValue('digitStoreUsername', '');
    formMethods.setValue('digitStorePassword', '');
    formMethods.setValue('digitStorePasswordEdited', false);
  }

  const handleDigitStoreToggle = () => {
    if (!digitStoreEnabled) {
      formMethods.setValue('digitStoreEnabled', true);
    } else {
      formMethods.setValue('digitStoreEnabled', false);
      resetDigitStoreValues();
    }
    setDigitStoreEnabled(prevState => !prevState);
  }

  const onLogoUpload = async (request: FileUploadReq, onUploadProgressHandler: (progressEvent: any) => void) => {
    try {
      //setLoading(true);
      const resp = await uploadLogo(request, onUploadProgressHandler);
      formMethods.setValue('pictureUniqueName', resp.uploadKey);
      setTempLogo(true);
      setLogoId(resp.uploadKey);
    } catch (err: any) {
      const error = await convertError(err);
      setErrorMsg(error);
    } finally {
      //setLoading(false);
    }
  }

  const removeLogoHandler = () => {
    setLogoId(null);
    formMethods.setValue('pictureUniqueName', "");
  };

  const getPicture = async (pictureUniqueName: string) => {
    return await getCompanyLogo(pictureUniqueName);
  }

  const handleTabChange = (key: string) => {
    if (free) {
      if (key === 'conti') {
        openPopup(PopupContentType.SETTINGS_ACCOUNT_TAB);
        setCurrentTab(key);
      } else if (key === 'conservazione') {
        openPopup(PopupContentType.SETTINGS_DIGITSTORE_TAB);
        setCurrentTab(key);
      }
    }
    if (currentTab === 'conservazione' && digitStoreEnabled) {
      if (digitStore.digitStoreUsername && digitStore.digitStorePassword && digitStore.digitStoreUrl) {
        setCurrentTab(key);
      } else {
        const formValues = formMethods.getValues();
        const username = formValues.digitStoreUsername;
        const passwd = formValues.digitStorePassword;
        const url = formValues.digitStoreUrl;
        if (username && passwd && url) {
          setCurrentTab(key);
        } else {
          setErrorMsg(tSetting('error.missingDigitStoreConfig'));
        }
      }
    } else {
      setCurrentTab(key);
    }
  }

  const handleTestClick = () => {
    const formValues = formMethods.getValues();
    const companyVat = formValues.vatNumber;

    if (!companyVat || companyVat?.length === 0) {
      setErrorMsg('');
      return;
    }

    const request: TestCoDiRequest = {
      userName: digitStoreUsernameWatch ? digitStoreUsernameWatch : '',
      password: digitStorePasswordWatch ? digitStorePasswordWatch : '',
      vatNumber: companyVat,
      url: digitStoreUrlWatch ? digitStoreUrlWatch : '',
      clear: formValues.digitStorePasswordEdited ? formValues.digitStorePasswordEdited : false
    }

    testCoDi(request)
      .then(_ => {
        // ok
        setFeedbackMsg(tSetting('tab.digitStore.success'));
      })
      .catch(err => {
        convertError(err)
          .then(msg => setErrorMsg(msg))
      })
  }

  const isDigitStoreModuleEnabled = isDigitalStorageModuleEnabled();

  //----------------
  // React Component
  //----------------

  return (
    <Box
      width={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      mx={"auto"}
    >
      <Box flexGrow={1}>
        <PageTitle
          title={<Typography variant={"h3"} mr={3}>{tSetting('title')}</Typography>}
        />
      </Box>
      <Snackbar open={!!errorMsg} autoHideDuration={13000} onClose={() => setErrorMsg(null)}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
        <Alert severity={"error"} onClose={() => setErrorMsg(null)}>
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!feedbackMsg}
        autoHideDuration={5000}
        onClose={() => setFeedbackMsg(null)}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      >
        <Alert
          severity={"success"}
          onClose={() => setFeedbackMsg(null)}
        >
          {feedbackMsg}
        </Alert>
      </Snackbar>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          mt: 2,
          px: 3.5,
          py: 2.5,
          flexGrow: 1,
          height: "100%"
        }}
      >
        <Box>
          <TabBar
            selected={currentTab}
            tabs={[
              {
                key: "dati", isError: dataError, render: (flag) => {
                  return (
                    <Stack
                      id={"setting-comp-data"}
                      direction={"row"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      spacing={2}
                    >
                      <BusinessIcon/>
                      <Typography variant={"h5"}>{tSetting('tab.companyData.title')}</Typography>
                    </Stack>
                  )
                }
              },
              {
                key: "conti", render: (flag) => {
                  return (
                    <Stack
                      id={"setting-acc"}
                      direction={"row"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      spacing={2}
                    >
                      <AccountBalanceIcon/>
                      <Typography variant={"h5"}>{tSetting('tab.account.title')}</Typography>
                      {
                        free &&
                        <PremiumIcon ml={1}/>
                      }
                    </Stack>
                  )
                }
              },
              {
                key: "conservazione", isError: digitError, render: (flag) => {
                  return <Stack
                    id={"setting-comp-data"}
                    direction={"row"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    spacing={2}
                  >
                    <StorageIcon/>
                    <Typography variant={"h5"}>{tSetting('tab.digitStore.title')}</Typography>
                    {
                      !isDigitStoreModuleEnabled &&
                      <PremiumIcon ml={1}/>
                    }
                  </Stack>
                }
              },
              // {
              //   key: "note", render: (flag) => {
              //     return <Stack direction={"row"} flexDirection={"row"} alignItems={"center"}
              //                   spacing={2}><MenuNoteSpese/> <Typography variant={"h5"}>{tSetting('tab.expNote.title')}</Typography></Stack>
              //   }
              // }
            ]}
            onSelect={(key) => handleTabChange(key)}
          />
        </Box>
        <Box flexGrow={1} mt={3} overflow={"auto"}>
          <FormProvider {...formMethods}>
            {
              currentTab === 'dati' &&
              <>
                <Typography variant="h6" textTransform={"uppercase"}>{tSetting('tab.companyData.label.generalData')}</Typography>
                <br/>
                <Grid container flexDirection={"row-reverse"}>

                  <Grid item xs={12} lg={3}>
                    <Box ml={3}>
                      <Stack alignItems={"center"}>
                        <Box alignSelf={"center"} mb={2}>
                          <ImageViewer imageId={logoId} tempImage={tempLogo} uploadImage={onLogoUpload} deleteImage={removeLogoHandler}
                                       getImage={getPicture} width={100} height={100} label={'Logo'}
                                       title={tSetting('tab.companyData.label.editLogo')}/>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>

                  <Grid item xs={12} lg={9}>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Controller
                          name="description"
                          control={formMethods.control}
                          render={({field, fieldState}) => {
                            return (
                              <TextFieldZts
                                {...field}
                                required
                                label={tSetting('tab.companyData.label.description')}
                                errorMsg={fieldState.error?.message}
                              />
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <Controller
                          name="vatNumber"
                          control={formMethods.control}
                          render={({field, fieldState}) => {
                            return (
                              <TextFieldZts
                                {...field}
                                variant="filled"
                                label={tSetting('tab.companyData.label.vatNumber')}
                                errorMsg={fieldState.error?.message}
                              />
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <Controller
                          name="telephone"
                          control={formMethods.control}
                          render={({field, fieldState}) => {
                            return (
                              <TextFieldZts
                                {...field}
                                variant="filled"
                                label={tSetting('tab.companyData.label.telephone')}
                                errorMsg={fieldState.error?.message}
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Typography variant="h6" textTransform={"uppercase"}>{tSetting('tab.companyData.label.address')}</Typography>
                <br/>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} lg={9}>
                    <Controller
                      name="address"
                      control={formMethods.control}
                      render={({field, fieldState}) => {
                        return (
                          <TextFieldZts
                            {...field}
                            variant="filled"
                            label={tSetting('tab.companyData.label.address')}
                            errorMsg={fieldState.error?.message}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={4.5}>
                    <Controller
                      name="locality"
                      control={formMethods.control}
                      render={({field, fieldState}) => {
                        return (
                          <TextFieldZts
                            {...field}
                            variant="filled"
                            label={tSetting('tab.companyData.label.locality')}
                            errorMsg={fieldState.error?.message}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={4.5}>
                    <Controller
                      name="zipCode"
                      control={formMethods.control}
                      render={({field, fieldState}) => {
                        return (
                          <TextFieldZts
                            {...field}
                            variant="filled"
                            label={tSetting('tab.companyData.label.zipCode')}
                            errorMsg={fieldState.error?.message}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={9}>
                    <Controller
                      name="notes"
                      control={formMethods.control}
                      render={({field, fieldState}) => {
                        return (
                          <TextFieldZts
                            {...field}
                            variant="filled"
                            multiline={true}
                            label={tSetting('tab.companyData.label.notes')}
                            errorMsg={fieldState.error?.message}
                          />
                        );
                      }}
                    />
                  </Grid>

                </Grid>
              </>
            }
            {
              currentTab === 'conti' &&
              (
                <>
                  <Grid
                    container
                    maxWidth={{xs: "90%", lg: "80%"}}
                    columnSpacing={2}
                  >
                    <Grid item xs={12} lg={6}>
                      <Controller
                        name="accountCompCashId"
                        control={formMethods.control}
                        render={({field, fieldState}) => {
                          return (
                            <AutocompleteZts
                              id={field.name}
                              options={options}
                              label={tSetting('tab.account.label.accountCompCashId')}
                              selectedValue={field.value ? field.value : null}
                              setValue={
                                (_, value) => field.onChange(value)
                              }
                              codeAndDesc={true}
                              errorMsg={fieldState.error?.message}
                              disabled={free}
                              noOptionsText={tComm('noOptions')}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Controller
                        name="accountVatId"
                        control={formMethods.control}
                        render={({field, fieldState}) => {
                          return (
                            <AutocompleteZts
                              id={field.name}
                              options={options}
                              label={tSetting('tab.account.label.accountVatId')}
                              selectedValue={field.value ? field.value : null}
                              setValue={
                                (_, value) => field.onChange(value)
                              }
                              codeAndDesc={true}
                              errorMsg={fieldState.error?.message}
                              disabled={free}
                              noOptionsText={tComm('noOptions')}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Controller
                        name="accountSupplierId"
                        control={formMethods.control}
                        render={({field, fieldState}) => {
                          return (
                            <AutocompleteZts
                              id={field.name}
                              options={options}
                              label={tSetting('tab.account.label.accountSupplierId')}
                              selectedValue={field.value ? field.value : null}
                              setValue={
                                (_, value) => field.onChange(value)
                              }
                              codeAndDesc={true}
                              errorMsg={fieldState.error?.message}
                              disabled={free}
                              noOptionsText={tComm('noOptions')}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Controller
                        name="accountStaffId"
                        control={formMethods.control}
                        render={({field, fieldState}) => {
                          return (
                            <AutocompleteZts
                              id={field.name}
                              options={options}
                              label={tSetting('tab.account.label.accountStaffId')}
                              selectedValue={field.value ? field.value : null}
                              setValue={
                                (_, value) => field.onChange(value)
                              }
                              codeAndDesc={true}
                              errorMsg={fieldState.error?.message}
                              disabled={free}
                              noOptionsText={tComm('noOptions')}
                            />
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )
            }
            {
              currentTab === 'conservazione' &&
              <Grid
                container
                maxWidth={{xs: "90%", lg: "50%"}}
                rowGap={1}
              >
                {
                  !isDigitStoreModuleEnabled ? (
                    <>
                      <Grid item xs={12} ml={1} mb={1}>
                        <FormControlLabel
                          label={<Typography>{tSetting('tab.digitStore.label.enableDigitStore')}</Typography>}
                          control={
                            <IOSSwitch
                              checked={false}
                              disabled={true}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldZts disabled={true} required variant={"filled"} label={tSetting('tab.digitStore.label.digitStoreUrl')}/>
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldZts disabled={true} required variant={"filled"} label={tSetting('tab.digitStore.label.digitStoreUsername')}/>
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldZts disabled={true} required variant={"filled"} type={"password"} label={tSetting('tab.digitStore.label.digitStorePassword')}/>
                      </Grid>
                      <Grid item>
                        <Button disabled={true} variant={"outlined"}><Typography variant={"button"}>{tSetting('tab.digitStore.button.testButton')}</Typography></Button>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} ml={1} mb={1}>
                        <Stack flexDirection={"row"} alignItems={'center'}>
                          <FormControlLabel
                            label={<Typography>{tSetting('tab.digitStore.label.enableDigitStore')}</Typography>}
                            control={
                              <IOSSwitch
                                name={"digitStoreEnabled"}
                                checked={digitStoreEnabled ? digitStoreEnabled : false}
                                onChange={(e) => {
                                  let value = !e.target.checked;
                                  formMethods.setValue("digitStoreEnabled", !value);
                                  handleDigitStoreToggle();
                                }}
                              />
                            }
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="digitStoreUrl"
                          control={formMethods.control}
                          render={({field, fieldState}) => {
                            return (
                              <TextFieldZts {...field} required variant={"filled"} label={tSetting('tab.digitStore.label.digitStoreUrl')}
                                            errorMsg={fieldState.error?.message} disabled={!digitStoreEnabled}/>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="digitStoreUsername"
                          control={formMethods.control}
                          render={({field, fieldState}) => {
                            return (
                              <TextFieldZts {...field} required variant={"filled"} label={tSetting('tab.digitStore.label.digitStoreUsername')}
                                            errorMsg={fieldState.error?.message} disabled={!digitStoreEnabled}/>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="digitStorePassword"
                          control={formMethods.control}
                          render={({field, fieldState}) => {
                            return (
                              <TextFieldZts
                                {...field}
                                required
                                variant={"filled"}
                                type={"password"}
                                label={tSetting('tab.digitStore.label.digitStorePassword')}
                                errorMsg={fieldState.error?.message}
                                disabled={!digitStoreEnabled}
                                onChange={(e) => {
                                  const previousPwdValue = formMethods.getValues("digitStorePassword");
                                  const currentPwdValue = e.target.value;
                                  if (previousPwdValue !== currentPwdValue) {
                                    formMethods.setValue("digitStorePasswordEdited", true);
                                  }
                                  formMethods.setValue("digitStorePassword", e.target.value);
                                }}
                              />
                            )
                          }}
                        />
                      </Grid>
                      <Grid item >
                        <Button disabled={!testButtonEnabled
                        } onClick={handleTestClick} variant={"outlined"}><Typography variant={"button"}>{tSetting('tab.digitStore.button.testButton')}</Typography></Button>
                      </Grid>
                    </>
                  )
                }
              </Grid>
            }
            {/*{*/}
            {/*  currentTab === 'note' &&*/}
            {/*  <Stack ml={1}>*/}
            {/*    <FormControlLabel*/}
            {/*      label={<Typography>{tSetting('tab.expNote.label.expNoteMonthly')}</Typography>}*/}
            {/*      control={*/}
            {/*        <IOSSwitch*/}
            {/*          name={"expNoteMonthly"}*/}
            {/*          checked={monthlyExpEnabled ? monthlyExpEnabled : false}*/}
            {/*          onChange={(e) => {*/}
            {/*            let value = !e.target.checked;*/}
            {/*            formMethods.setValue("expNoteMonthly", !value);*/}
            {/*            firstCheckHandler()*/}
            {/*          }}*/}
            {/*        />*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <FormControlLabel*/}
            {/*      label={<Typography>{tSetting('tab.expNote.label.newExpNoteAllowed')}</Typography>}*/}
            {/*      control={*/}
            {/*        <IOSSwitch*/}
            {/*          name={"newExpNoteAllowed"}*/}
            {/*          checked={newExpEnabled ? newExpEnabled : false}*/}
            {/*          onChange={(e) => {*/}
            {/*            let value = !e.target.checked;*/}
            {/*            formMethods.setValue("newExpNoteAllowed", !value);*/}
            {/*            secondCheckHandler();*/}
            {/*          }}*/}
            {/*        />*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </Stack>*/}
            {/*}*/}
          </FormProvider>

        </Box>

        <Box flexGrow={1}/>

        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          columnGap={2.2}
          mt={4}
          mr={2}
        >
          <Button key={2}
                  variant="contained"
                  className={"cta-button"}
                  onClick={() => onCancelClick()}>
            <Typography variant={"button"}>{tSetting('button.cancel')}</Typography>
          </Button>
          <Button key={1}
                  variant="contained"
                  className={"cta-button-primary"}
                  onClick={() => {
                    handleSaveClicked();
                  }}
          >
            <Save sx={{fontSize: "1.1em"}}/>
            <Typography ml={1} variant={"button"}>{tSetting('button.save')}</Typography>
          </Button>
        </Stack>

      </Paper>
      <LicensePopup open={popupOpen} isPro={!free} closePopup={closePopup} titlePro={popupTitle ? popupTitle : ''} type={popupContentType ? popupContentType : PopupContentType.DEFAULT} />
    </Box>
  );
}

