import { ExpNoteFilter } from '../../model';
import { ExpNoteListApproverFilter } from '../../../../reducers/ExpNoteList';

export const convertApproverPersistedFilterToApiFilter = (filter?: ExpNoteListApproverFilter): ExpNoteFilter => {
  const apiFilter: ExpNoteFilter = {
    searchText: null,
    startDate: null,
    contabDate: null
  };
  if (filter) {
    if (filter.searchText) {
      apiFilter.searchText = filter.searchText;
    }
    if (filter.startDate) {
      apiFilter.startDate = filter.startDate;
    }
  }
  return apiFilter;
}

export const convertApproverApiFilterToPersistedFilter = (apiFilter: ExpNoteFilter): ExpNoteListApproverFilter => {
  const persistedFilter: ExpNoteListApproverFilter = {};
  if (apiFilter.searchText) {
    persistedFilter.searchText = apiFilter.searchText;
  }
  if (apiFilter.startDate) {
    persistedFilter.startDate = apiFilter.startDate;
  }
  return persistedFilter;
}
