import Box from "@mui/material/Box";
import {ReactChild} from "react";

type AccountCardWrapperProps = {
  child: ReactChild
}

type ComponentProps = {
  prop: AccountCardWrapperProps
}

export const AccountCardWrapper = ({prop}: ComponentProps) => {
  return (
    <Box
      className={"user-card-wrapper"} /*TODO definire css class per conti contabili */
      mr={{
        sm: 2
      }}
      mt={{
        xs: "0",
        sm: "unset"
      }}
      mb={2}
    >
      {prop.child}
    </Box>
  );
}
