import React, {useEffect, useState} from "react";
import {Skeleton, Stack } from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import {StaffProjectsSaveReq, User} from "../model";
import {CommonDialog} from "../../base/common-dialog";
import Box from "@mui/material/Box";
import {getActiveProjects, getUserProjects, saveAllProjects} from "../../projects/Service";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {AutocompleteZts} from "../../base/autocomplete";
import {Delete} from '@mui/icons-material';
import {useTranslation} from "react-i18next";
import {useHelp} from "../../help/hook";
import {HelpCurrentPage} from "../../../reducers/Help";

type ComponentProps = {
  show: boolean,
  onClose: Function,
  user: User | null
}

export const ProjectsList = ({show, onClose, user}: ComponentProps) => {
  //lista di tutti i progetti (da API)
  const [allProjects, setAllProjects] = useState<AutocompleteGenericOption[]>([]);
  //lista di tutti i progetti già assegnati all'utente (da API) al momento sempre vuoto
  const [userProjects, setUserProjects] = useState<number[]>([]);
  //List progetti selezionati da utente
  const [selectedOpts, setSelectedOpts] = useState<AutocompleteGenericOption[]>([]);
  //List progetti non selezionati da utente quindi da mostrare in autocompleter (calcolato in base a allProjects e userProjects)
  const [autocompleterOpts, setAutocompleterOpts] = useState<AutocompleteGenericOption[]>([]);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [mySelectedValue, setMySelectedValue] = useState(null);

  const {t} = useTranslation(['common', 'user']);

  useHelp({
    paths: [
      {
        path: '/modal',
        page: show ? HelpCurrentPage.USER_PROJECT : HelpCurrentPage.USER_DETAIL
      }
    ]
  });

  useEffect(() => {
    if (show) {
      setLoading(true);

      if (user) {
        Promise.all([getUserProjects(user.id), getActiveProjects()]).then((response) => {
          //carico le commesse di questo utente
          const _userProjects = response[0] ? response[0].map(p => p.projectId) : []
          //carico tutte le commesse disponibili
          const _allProjects = response[1] ? response[1].map(p => createAutocompleteGenericOption(p.id, p.id.toString(), `${p.code} ${p.description}`)) : [];

          setUserProjects(_userProjects);
          setAllProjects(_allProjects);

          setSelectedOpts(_allProjects.filter(p => _userProjects.find(id => id === p.id)));
        }).catch(err => console.log('Fetch user-projects error', err))
          .finally(() => setLoading(false))
      }
    }
  }, [show]);


  useEffect(() => {
      //Quando ha fatto la selezione sbianco la scelta all'interno del autocompleter
      setMySelectedValue(null);
      //aggiorno l'elenco delle voci all'interno dell'autocompleter, eliminando quelli già selezionati
      setAutocompleterOpts(allProjects.filter(p => !selectedOpts.find(sp => sp.id === p.id)));
  }, [selectedOpts]);


  const removeProject = (id : number|null) => {
    setSelectedOpts(selectedOpts.filter(p => p.id !== id)); //filter da un nuovo array
  }

  const handleActionButtonClicked = (save: boolean) => {
    if (save) {
      handleSaveData();
    } else {
      onClose(false);
    }
  };

  const handleSaveData = async () => {
    if (user) {
      setErrorMsg(null);
      setSaving(true);

      //console.log(values.expenses)

      const data: StaffProjectsSaveReq = {
        lastUpdNum: user.lastUpdateNum,
        projects: selectedOpts.map(elm => ({
          id: elm.id,
          projectId: elm.code
        }))
      }

      try {
        await saveAllProjects(user.id, data);
        onClose(true);
      } catch (error) {
        setErrorMsg(`Saving error. ${error}`);
      } finally {
        setSaving(false);
      }
    }
  }

  return(
    <CommonDialog
      show={show}
      title={t("user:detail.items.projects.assigned")}
      widths={[
        {breakpoint: "lg", width: "350px"},
      ]}
      saving={saving}
      onClose={handleActionButtonClicked}
      errorMsg={errorMsg}
    >
      {loading ? (<Skeleton
          height={50}
          variant="rectangular"
        />
      ) : (

        <Stack
          spacing={4}
          direction={"column"}
          alignItems={"right"}
        >
          <Box flexGrow={1}>
              <AutocompleteZts
                  id={'commesse'}
                  label={t("common:project")}
                  options={autocompleterOpts}
                  setValue={(id, value) => {
                      //field.onChange(value);
                      if (value) {
                        //Aggiunto la commessa selezionata all'elenco
                        setMySelectedValue(value);
                        setSelectedOpts(oldArray => [...oldArray, value]);
                      } else {
                        //removeRow(index);
                      }
                    }}
                  selectedValue={mySelectedValue}
                  codeAndDesc={false}
              />
          </Box>

          {/*Lista dei selezionati*/}
          <Stack spacing={1}>
            {selectedOpts.map(s => (
              <Stack key={s.id} direction={"row"} alignItems={"center"}>
                <TextFieldZts
                  size={'medium'}
                  key={s.id}
                  disabled={true}
                  label={s.desc}
                />
                <Delete className={"text-danger"} cursor={"pointer"} onClick={() => removeProject(s.id)}/>
              </Stack>
            ))}
          </Stack>
        </Stack>

      )}
    </CommonDialog>
  );
}


export default ProjectsList;
