import React, {useEffect, useState} from "react";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {
  getFilteredProjects,
  getStaffCreditCards,
  getStaffTravelPoliciesByExpNote,
  getStaffTravelPolicyExpItems
} from "../../users/Service";
import {Alert, Grid, Skeleton, Stack, Typography} from "@mui/material";
import {AutocompleteZts} from "../../base/autocomplete";
import Box from "@mui/material/Box";
import {TextFieldZts} from "../../base/text-field";

// https://mui.com/blog/lab-date-pickers-to-mui-x/
//https://mui.com/x/react-date-pickers/getting-started/
import {getCurrencies} from "../../../util/BaseService";
import {StaffCreditCard, StaffTravelPolicy, StaffTravelPolicyExpenseItem} from "../../users/model";
import moment from "moment";
import {countPayments, getExpNoteLocalities, getLastExpNoteExpense} from "../Service";
import {ExpNoteCreCardMov, OcrResult, SkeletonRowHeight} from "../model";
import {Currency, DateFormat} from "../../model";
import {DatePickerZts} from "../../base/date-picker";
import {PieDiLista} from "./PieDiLista";
import {CompanyCurrencyAutocompleteOption, DocumentType, ExpenseItemType, ExpNoteExpenseFull, PayMode} from "./model";
import {Tariffa} from "./Tariffa";
import {ImportoFisso} from "./ImportoFisso";
import {useTranslation} from "react-i18next";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {NewExpenseAdditionalExpFormValue, NewExpenseFormValues, newExpenseInitValues} from "./validation";
import {ExpenseImg} from "../../expenses/commons/ExpenseImg";
import {useExpNoteDetail} from "../detail/hooks/useExpNoteDetail";
import {PremiumIcon} from "../../base/premium-icon";
import {ArrowDropDown} from "@mui/icons-material";
import {AdditionalExpenses} from "./AdditionalExpenses";
import {useExpenseForm} from "../../../hooks/useExpenseForm";
import {formatNumber, parseNumber} from "../../../util/NumberUtil";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {formatDate} from "../../../util/DateUtil";
import {getActiveTravelPoliciesByDate} from "../../travel_policies/Service";
import {getExpenseCategoryOrder} from "../../expenses/model";
import {useLicense} from "../../../hooks/useLicense";
import {useExpNoteList} from "../hooks/useExpNoteList";
import {ExpNoteListRole} from "../../../reducers/ExpNoteList";
import {CompleteCreCardMovDto} from "../../credit-card-mov/model";

type ComponentProps = {
  expenses: ExpNoteExpenseFull[] | null,
  ocrResult: OcrResult | null,
  readonly?: boolean,
  setAnnouncementMsg: (value: string) => void
}

export const ExpenseEdit = ({ocrResult, expenses, readonly = false, setAnnouncementMsg}: ComponentProps) => {

  const editExpense = expenses ? expenses[0] : null;

  const {userLocale: locale, companyCurrencyCode, companyDecimalNum} = useLoggedUser();

  const {
    currentRole
  } = useExpNoteList();

  const {
    creditCardMovs
  } = useExpNoteDetail();

  const linkedCreCardMov: ExpNoteCreCardMov | undefined = editExpense ? creditCardMovs?.find(mov => mov.docNum === editExpense.docNum) : undefined;

  const {expNote} = useExpNoteDetail();
  const formMethods = useFormContext<NewExpenseFormValues>();

  const {t} = useTranslation('exp-note', {keyPrefix: 'expense.edit'});
  const {t: tCommon} = useTranslation('common');
  const {t: tMovInfo} = useTranslation('cre-card-mov', {keyPrefix: 'popup.info'});

  const [travelPolicies, setTravelPolicies] = useState<StaffTravelPolicy[] | null>(null);
  const [loadingTP, setLoadingTP] = useState(false);

  const [allStaffCreditCards, setAllStaffCreditCards] = useState<StaffCreditCard[]>([]);
  const [creditCardItems, setCreditCardItems] = useState<StaffCreditCard[]>([]);

  const [currentExpItems, setCurrentExpItems] = useState<StaffTravelPolicyExpenseItem[]>([]);
  const [loadingExpItem, setLoadingExpItem] = useState(false);

  const [currencyItems, setCurrencyItems] = useState<Currency[] | null>(null);

  const [localityItems, setLocalityItems] = useState<AutocompleteGenericOption[] | null>(null);

  const [projectItems, setProjectItems] = useState<AutocompleteGenericOption[] | null>(null);

  const [showCompPeriod, setShowCompPeriod] = useState<boolean | null>(null);

  const [computeExchange, setComputeExchange] = useState(false);

  const [lastExpense, setLastExpense] = useState<ExpNoteExpenseFull | null>(null);

  const [travelPolicyExists, setTravelyPolicyExists] = useState(false);
  const [uniqueTravelPolicyNotValid, setUniqueTravelPolicyNotValid] = useState(false);

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const travelPolicy: AutocompleteGenericOption | null = useWatch({name: "travelPolicy", exact: true});
  const currency: AutocompleteGenericOption | null = useWatch({name: "currency", exact: true});
  const amount = useWatch({name: "amount", exact: true});
  const additionalExpenses: NewExpenseAdditionalExpFormValue[] | null = useWatch({name: 'additionalExpenses'});
  const expenseItemWatcher = useWatch({name: "expenseItem", exact: true});

  let documentAmount: number = 0;
  let formattedDocAmount: string | null = null;
  const isAdditionExpenses = additionalExpenses && !!additionalExpenses.find(exp => !!exp.expenseItem);
  if (isAdditionExpenses) {
    const currAmountDigit = parseNumber(amount, locale);
    if (currAmountDigit) {
      documentAmount += currAmountDigit;
    }
    additionalExpenses.forEach(exp => {
      const currAmountDigit = parseNumber(exp.amount, locale);
      if (currAmountDigit) {
        documentAmount += currAmountDigit;
      }
    });
    if (documentAmount > 0) {
      const currencyItem = currencyItems && currency ? currencyItems.find(c => c.code === currency.code) : null;
      if (currencyItem) {
        formattedDocAmount = `${formatNumber(documentAmount, locale, currencyItem.decimalNum)} ${currencyItem.code}`;
      }
    }
  }

  const {
    updateSingleExpenseItem,
    updateExpenseItems,
    filterExpenseItems,
    updatePayMode,
    updateDocType,
  } = useExpenseForm();

  useEffect(() => {
    let uniqueTravelPolicyNotValid = false;
      if (!editExpense && travelPolicies && travelPolicies.length === 1) {
        const uniqueTravelPolicy = travelPolicies[0];

        const tpItem = createAutocompleteGenericOption(uniqueTravelPolicy.travelPolicyId, uniqueTravelPolicy.code, uniqueTravelPolicy.description);
        formMethods.setValue('travelPolicy', {...tpItem, foreignCurr: uniqueTravelPolicy.foreignCurrEnb, projectsEnabled: uniqueTravelPolicy.projectEnb});
        onChangeTravelPolicy(uniqueTravelPolicy.travelPolicyId);

        // se l'unica Travel Policy assegnata al viaggiatore non è valida nel periodo della nota spese
        // gli è permesso utilizzarla (vedi sotto quando chiama getStaffTravelPoliciesByExpNote(...))
        // ma viene mostrato un warning
        if (expNote && moment(uniqueTravelPolicy.endDate).isBefore(moment(expNote.startDate), 'days')) {
          uniqueTravelPolicyNotValid = true;
        }
      }
    setUniqueTravelPolicyNotValid(uniqueTravelPolicyNotValid);
    }, [travelPolicies]
  )

  useEffect(() => {
      let messageKey: string | null = null;
      if (currentRole && currentRole === ExpNoteListRole.TRAVELLER && !readonly && !loadingTP) {
        if (uniqueTravelPolicyNotValid) {
          messageKey = "travelPolicyAssignedNotValid";
        } else if (travelPolicies?.length === 0) {
          messageKey = travelPolicyExists ? "noTravelPolicyAssigned" : "noTravelPolicyAvailable";
        }
      }
      if (messageKey == null) {
        setAnnouncementMsg("");
      } else {
        setAnnouncementMsg(t(messageKey));
      }
    }, [travelPolicies, travelPolicyExists, loadingTP, uniqueTravelPolicyNotValid]
  )

  useEffect(() => {
    if (expNote) {
      setLoadingTP(true);
      Promise.all([
        getStaffCreditCards(expNote.staffId),
        getStaffTravelPoliciesByExpNote(expNote),
        countPayments(expNote.id),
        getLastExpNoteExpense(expNote.id)
      ]).then(result => {
        const staffCards = result[0];
        let staffTPs: StaffTravelPolicy[] = result[1];
        const hasPayments = result[2] > 0;
        const lastExpense = result[3];
        if (staffCards && staffCards.length > 0) {
          setAllStaffCreditCards(staffCards);
          setCreditCardItems([...staffCards]);
        } else {
          setAllStaffCreditCards([]);
          setCreditCardItems([]);
        }
        if (staffTPs && staffTPs.length > 0) {
          // se ci sono più TP le filtro anche per data fine (dal BE arrivano filtrate solo per data inizio)
          // se ne ho solo 1 disponibile la considero valida al fine di non bloccare l'utente nell'inserimento della spesa
          if (staffTPs.length > 1) {
            staffTPs = staffTPs.filter(tp => !moment(tp.endDate).isBefore(moment(expNote.startDate), 'days'));
          }

          const movement: CompleteCreCardMovDto | undefined = formMethods.getValues("creCardMov");
          if (movement && movement.currency !== companyCurrencyCode) {
            setTravelPolicies(staffTPs.filter(tp => tp.foreignCurrEnb));
          } else {
            setTravelPolicies(staffTPs);
          }
        } else {
          setTravelPolicies([]);
          getActiveTravelPoliciesByDate(expNote.startDate)
            .then(res => setTravelyPolicyExists(res.length > 0));
        }

        setComputeExchange(hasPayments);

        setLastExpense(lastExpense);
      })
        .catch(err => console.log('Fetch staff data error', err))
        .finally(() => {
          setLoadingTP(false);
        });
    }
  }, [expNote]);

  useEffect(() => {
    if (editExpense) {
      loadExpenseItems(editExpense.travelPolicyId, true);
      if (!readonly) {
        updatePayMode(editExpense.payMode);
        updateDocType(editExpense.docType);
      }
      if (formMethods.getValues('expenseItem')?.compPeriod) {
        setShowCompPeriod(true);
      } else {
        setShowCompPeriod(false);
      }
      if (!currencyItems) {
        loadCurrencies();
      }
    }
  }, [editExpense]);

  useEffect(() => {
    if (!editExpense && lastExpense && !travelPolicy) {
      const tpItem = travelPolicies ? travelPolicies.find(tp => tp.travelPolicyId === lastExpense.travelPolicyId) : null;
      if (tpItem) {
        formMethods.setValue("travelPolicy", ({
          ...createAutocompleteGenericOption(tpItem.travelPolicyId, tpItem.code, tpItem.description),
          foreignCurr: tpItem.foreignCurrEnb,
          projectsEnabled: tpItem.projectEnb
        }));
        onChangeTravelPolicy(tpItem.travelPolicyId);
      }
    }
  }, [editExpense, travelPolicies, lastExpense, formMethods, travelPolicy]);

  useEffect(() => {
    const date = editExpense?.compEndDate ? editExpense.compEndDate : editExpense?.compDate;
    if (date) {
      onChangeCompDate(date);
    }
  }, [editExpense, allStaffCreditCards]);

  const loadLocalities = async () => {
    if (expNote) {
      const localities = await getExpNoteLocalities(expNote.id);
      if (localities) {
        setLocalityItems(localities.map((l, idx) => createAutocompleteGenericOption(idx + 1, l.locality, l.locality)));
      } else {
        setLocalityItems([]);
      }
    }
  }

  const loadProjects = async () => {
    if (expNote && !free) {
      const projects = await getFilteredProjects(expNote);
      if (projects) {
        setProjectItems(projects.map(p => createAutocompleteGenericOption(p.projectId, p.code, p.description)));
      } else {
        setProjectItems([]);
      }
    }
  }

  const loadExpenseItems = (travelPolicyId: number, updateFormState?: boolean) => {
    if (travelPolicyId && expNote) {
      setLoadingExpItem(true);
      getStaffTravelPolicyExpItems(expNote.staffId, travelPolicyId)
        .then(items => {

          const sortedItems = items ?
            items.sort((e1, e2) => {
              const exp1CategoryOrder = getExpenseCategoryOrder(e1.expenseCategory);
              const exp2CategoryOrder = getExpenseCategoryOrder(e2.expenseCategory);
              if (exp1CategoryOrder > exp2CategoryOrder) {
                return 1;
              } else if (exp1CategoryOrder < exp2CategoryOrder) {
                return -1;
              } else {
                return e1.expenseDescription.localeCompare(e2.expenseDescription);
              }
            }) : items;

          if (sortedItems && sortedItems.length > 0) {
            setCurrentExpItems(items);
          } else {
            setCurrentExpItems([]);
          }
          if (updateFormState && !readonly) {
            updateExpenseItemsInFormState(sortedItems);
          }
        })
        .catch(err => console.log('Fetch expense items error', err))
        .finally(() => setLoadingExpItem(false));
    } else {
      setCurrentExpItems([]);
    }
  }

  const loadCurrencies = () => {
    if (!currencyItems) {
      getCurrencies()
        .then(items => {
          if (items && items.length > 0) {
            let cnt = 1;
            const tmpCurrencies = items.map(c => ({...c, id: cnt++}));
            setCurrencyItems(tmpCurrencies);
          } else {
            setCurrencyItems([]);
          }
        })
        .catch(err => console.log('Fetch currency items error', err));
    }
  }

  const onChangeTravelPolicy = (travelPolicyId: number) => {
    loadExpenseItems(travelPolicyId);

    const currentTP = travelPolicies && travelPolicies.find(tp => tp.travelPolicyId === travelPolicyId);
    if (currentTP) {
      if (currentTP.foreignCurrEnb) {
        loadCurrencies();
      } else {
        formMethods.setValue('currency', CompanyCurrencyAutocompleteOption(companyCurrencyCode, companyDecimalNum));
      }
    }
  }

  const onChangeExpItem = (expItemId: number) => {
    //TODO: è corretto in inserimento, da valutare in modifica
    if (formMethods.getValues('expenseItem') != null) {
      resetValues(expItemId);
    }

    checkCompPeriod(expItemId);

    updateMainExpenseItemInFormState(expItemId);
  }

  const updateMainExpenseItemInFormState = (expItemId: number) => {
    const item = currentExpItems.find(item => item.id === expItemId);
    if (item) {
      updateSingleExpenseItem(item, 0);
    }
  }

  const updateExpenseItemsInFormState = (expItems: StaffTravelPolicyExpenseItem[]) => {
    const items: StaffTravelPolicyExpenseItem[] = [];

    let mainExpItemId: number | null = null;
    const expItem = formMethods.getValues("expenseItem");
    if (expItem) {
      mainExpItemId = expItem.id;
    }

    const localExpItems = expItems ? expItems : currentExpItems;
    const item = localExpItems.find(item => item.id === mainExpItemId);
    if (item) {
      items.push(item);
    }

    const additionalExpenses: NewExpenseAdditionalExpFormValue[] = formMethods.getValues('additionalExpenses');
    if (additionalExpenses) {
      additionalExpenses.forEach(addExp => {
        if (addExp.expenseItem) {
          const item = localExpItems.find(item => item.id === addExp.expenseItem?.id);
          if (item) {
            items.push(item);
          }
        }
      })
    }

    updateExpenseItems(items);
  }

  const checkCompPeriod = (expItemId: number) => {
    const currentExpItem = currentExpItems?.find(item => item.id === expItemId);
    if (!currentExpItem) {
      setShowCompPeriod(null);
    } else if (currentExpItem && currentExpItem.compPeriod) {
      setShowCompPeriod(true);
    } else {
      setShowCompPeriod(false);
    }

  }

  const onChangeCompDate = (date: Date | null) => {
    if (!date) {
      setCreditCardItems(allStaffCreditCards);
    } else {
      const compDate = moment(date, DateFormat).toDate();
      setCreditCardItems(allStaffCreditCards.filter(c => c.expireDate != null && moment(c.expireDate, DateFormat).toDate() > compDate));
    }
  }

  const getFormDataByType = () => {
    if (!loadingTP && expenseItemWatcher && (!editExpense || currentExpItems.length > 0)) {
      switch (expenseItemWatcher.type) {
        case ExpenseItemType.PIE_DI_LISTA:
          return <PieDiLista
            currencyItems={currencyItems}
            creditCardItems={creditCardItems}
            ocrResult={ocrResult}
            lastExpense={lastExpense}
            computeExchange={computeExchange}
            editExpense={editExpense}
            editableAndReadonly={editableAndReadonly}
          />
        case ExpenseItemType.TARIFFA:
          return <Tariffa
            currentExpItem={currentExpItems.find(e => e.id === expenseItemWatcher.id)}
            editableAndReadonly={editableAndReadonly}
          />
        case ExpenseItemType.IMPORTO_FISSO:
          return <ImportoFisso
            currentExpItem={currentExpItems.find(e => e.id === expenseItemWatcher.id)}
            editableAndReadonly={editableAndReadonly}
          />
      }
    }
  }

  const resetValues = (expItemId: number) => {
    const currentExpItem = currentExpItems?.find(item => item.id === expItemId);
    if (currentExpItem) {

      const newValues = {};

      newValues['tarif'] = '';
      newValues['quantity'] = '';
      newValues['amount'] = '';

      if (currentExpItem.expenseType === ExpenseItemType.PIE_DI_LISTA) {
        const docTypeItem = formMethods.getValues('docTypeItem');
        const docTypeCode = docTypeItem?.code;
        switch (docTypeCode) {
          case DocumentType.INVOICE:
            if (!currentExpItem.docInvoice) {
              newValues['docTypeItem'] = null;
            }
            break;
          case DocumentType.RECEIPT:
            if (!currentExpItem.docReceipt) {
              newValues['docTypeItem'] = null;
            }
            break;
          case DocumentType.TICKET:
            if (!currentExpItem.docTicket) {
              newValues['docTypeItem'] = null;
            }
            break;
        }

        const payModeItem = formMethods.getValues('payModeItem')
        const payModeCode = payModeItem?.code;
        switch (payModeCode) {
          case PayMode.TRAVELLER:
            if (!currentExpItem.payCollab) {
              newValues['payModeItem'] = null;
            }
            break;
          case PayMode.CARD:
            if (!currentExpItem.payCard) {
              newValues['payModeItem'] = null;
              newValues['creditCardItem'] = null;
            }
            break;
          case PayMode.COMPANY:
            if (!currentExpItem.payCompany) {
              newValues['payModeItem'] = null;
            }
            break;
        }

      } else {
        newValues['payModeItem'] = null;
        newValues['creditCardItem'] = null;
        newValues['docTypeItem'] = null;
        newValues['currency'] = CompanyCurrencyAutocompleteOption(companyCurrencyCode, companyDecimalNum);
      }
      if (currentExpItem.compPeriod) {
        newValues['compDate'] = null;
      } else {
        newValues['compStartDate'] = null;
        newValues['compEndDate'] = null;
      }
      if (!currentExpItem.carRouteEnb) {
        newValues['routes'] = [];
      }
      if (!currentExpItem.colleagueEnb) {
        newValues['colleagues'] = [];
      }
      if (!currentExpItem.guestEnb) {
        newValues['guests'] = [];
      }

      formMethods.reset({...formMethods.getValues(), ...newValues});
    } else {
      formMethods.reset({...newExpenseInitValues, travelPolicy: formMethods.getValues('travelPolicy')});
    }
  }

  const editableAndReadonly = !!editExpense && readonly;

  const compPeriodStartLabel = expenseItemWatcher?.compPeriodStartLabel;
  const compPeriodEndLabel = expenseItemWatcher?.compPeriodEndLabel;

  const filterCurrentExpenseItems = (): StaffTravelPolicyExpenseItem[] => {
    const movement: CompleteCreCardMovDto | undefined = formMethods.getValues("creCardMov");
    let localExpItems = currentExpItems;
    if (movement) {
      localExpItems = localExpItems.filter(item => item.payCard);
    }

    const additionalExpenses = formMethods.getValues("additionalExpenses");
    if (!additionalExpenses || additionalExpenses.length === 0) {
      return localExpItems;
    }
    return filterExpenseItems(localExpItems);
  }

  const getCompDates = (): JSX.Element => {
    if (showCompPeriod === null) {
      return <></>
    }

    return <>
      {!showCompPeriod ? (
        <Grid item xs={3}>
          {/*Data competenza*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              sx={{width: "100%"}}
              label={t('compDate')}
              value={formatDate(editExpense?.compDate, locale)}
            />
          ) : (
            <Controller
              name={'compDate'}
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <DatePickerZts
                  label={t("compDate")}
                  field={field}
                  errorMsg={fieldState.error?.message}
                  minDate={expNote ? moment(expNote.startDate, DateFormat) : null}
                  maxDate={expNote ? moment(expNote.endDate, DateFormat) : null}
                  width={"100%"}
                  onChangeHandler={(value: Date) => {
                    field.onChange(value);
                    onChangeCompDate(value);
                  }}
                  defaultCalendarMonth={expNote ? moment(expNote.startDate) : null}
                />
              }}
            />
          )}
        </Grid>
      ) : (
        <>
          <Grid item xs={3}>
            {/*Data inizio competenza*/}
            {editableAndReadonly ? (
              <TextFieldZts
                disabled={true}
                sx={{width: "100%"}}
                label={compPeriodStartLabel ? compPeriodStartLabel : t('compStartDate')}
                value={formatDate(editExpense?.compStartDate, locale)}
              />
            ) : (
              <Controller
                name={'compStartDate'}
                control={formMethods.control}
                render={({field, fieldState}) => {
                  return <DatePickerZts
                    label={compPeriodStartLabel ? compPeriodStartLabel : t("compStartDate")}
                    field={field}
                    errorMsg={fieldState.error?.message}
                    minDate={expNote ? moment(expNote.startDate, DateFormat) : null}
                    maxDate={expNote ? moment(expNote.endDate, DateFormat) : null}
                    width={"100%"}
                    onChangeHandler={(value: Date) => {
                      field.onChange(value);
                    }}
                    defaultCalendarMonth={expNote ? moment(expNote.startDate) : null}
                  />
                }}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            {/*Data fine competenza*/}
            {editableAndReadonly ? (
              <TextFieldZts
                disabled={true}
                sx={{width: "100%"}}
                label={compPeriodEndLabel ? compPeriodEndLabel : t('compEndDate')}
                value={formatDate(editExpense?.compEndDate, locale)}
              />
            ) : (
              <Controller
                name={'compEndDate'}
                control={formMethods.control}
                render={({field, fieldState}) => {
                  return <DatePickerZts
                    label={compPeriodEndLabel ? compPeriodEndLabel : t("compEndDate")}
                    field={field}
                    errorMsg={fieldState.error?.message}
                    minDate={expNote ? moment(expNote.startDate, DateFormat) : null}
                    maxDate={expNote ? moment(expNote.endDate, DateFormat) : null}
                    width={"100%"}
                    onChangeHandler={(value: Date) => {
                      field.onChange(value);
                      onChangeCompDate(value);
                    }}
                    defaultCalendarMonth={expNote ? moment(expNote.startDate) : null}
                  />
                }}
              />
            )}
          </Grid>
        </>
      )}
    </>
  }

  const getProject = (): JSX.Element => {
    if (free) {
      // in modalità gratuita non posso gestire le commesse
      return <TextFieldZts
        disabled={true}
        label={t('project')}
        value={null}
        InputProps={{
          endAdornment: (
            <Stack
              direction="row"
              columnGap={0.5}
              alignItems={"center"}
            >
              <PremiumIcon/>
              <ArrowDropDown/>
            </Stack>
          )
        }}
      />
    }

    const travelPolicy = formMethods.getValues("travelPolicy");
    if (travelPolicy) {
      const currentTP = travelPolicies && travelPolicies.find(tp => tp.travelPolicyId === travelPolicy.id);
      if (!currentTP || !currentTP.projectEnb) {
        // la TP non gestisce le commesse
        return <></>;
      }
    }

    if (editableAndReadonly) {
      // sono in consultazione quindi disegno un campo di testo
      return <TextFieldZts
        disabled={true}
        label={t('project')}
        value={editExpense?.projectDesc}
      />;
    }

    return <Controller
      name='project'
      control={formMethods.control}
      render={({field, fieldState}) => {
        return <AutocompleteZts
          id={field.name}
          lazyLoadOptions={loadProjects}
          label={t("project")}
          options={projectItems}
          selectedValue={field.value}
          codeAndDesc={true}
          setValue={(id, value) => {
            //console.log(id,value)
            field.onChange(value);
          }}
          errorMsg={fieldState.error?.message}
        />
      }}
    />
  }

  return (
    <>

      {(isAdditionExpenses || linkedCreCardMov) &&
        <Alert
          sx={{mb: 3}}
          severity="info"
        >
          {isAdditionExpenses &&
            <Stack direction="column" mb={linkedCreCardMov ? 4 : 0}>
              <Typography>
                {t("multiExpenseInfo")}
              </Typography>
              {formattedDocAmount &&
                <Typography>
                  {t("multiExpenseTotal", {amount: formattedDocAmount})}
                </Typography>
              }
            </Stack>
          }

          {linkedCreCardMov &&
            <Stack direction="column">
              <Typography mb={1}>
                {t("linkedCreCardMov")}
              </Typography>

              <Grid container rowSpacing={2} columnSpacing={4}>
                <Grid item>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{tMovInfo('movement.description')}</Typography>
                    <Typography variant={'body2'}>{linkedCreCardMov.description}</Typography>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{tMovInfo('movement.movDate')}</Typography>
                    <Typography variant={'body2'}>{formatDate(linkedCreCardMov.payDate, locale)}</Typography>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{tMovInfo('movement.currAmount')}</Typography>
                    <Typography
                      variant={'body2'}
                      textAlign="right"
                    >
                      {`${formatNumber(linkedCreCardMov.currAmount, locale, currencyItems?.find(c => c.code === linkedCreCardMov.currency)?.decimalNum || 0)} ${linkedCreCardMov.currency}`}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>

            </Stack>
          }
        </Alert>
      }

      <Grid container columnSpacing={3}>
        <Grid item xs={6}>
          {/*Travel Policy*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              label={tCommon('travelPolicy')}
              value={editExpense?.travelPolicyDesc}
            />
          ) : (
            <>
              {loadingTP && <Skeleton height={SkeletonRowHeight}/>}
              {!loadingTP && travelPolicies && travelPolicies.length > 1 &&
                <Controller
                  name='travelPolicy'
                  control={formMethods.control}
                  render={({field, fieldState}) => {
                    return <AutocompleteZts
                      id={field.name}
                      label={tCommon('travelPolicy')}
                      options={travelPolicies.map(l => ({
                        ...createAutocompleteGenericOption(l.travelPolicyId, l.code, l.description),
                        foreignCurr: l.foreignCurrEnb
                      }))}
                      selectedValue={field.value}
                      setValue={(id, value) => {
                        formMethods.setValue('expenseItem', null);
                        onChangeTravelPolicy(value ? value.id : null);
                        field.onChange(value);
                      }}
                      codeAndDesc={false}
                      errorMsg={fieldState.error?.message}
                    />
                  }}
                />
              }
            </>
          )}
        </Grid>
        <Grid item xs={6}/>
      </Grid>

      <Grid container mt={editableAndReadonly || (travelPolicies && travelPolicies.length > 1) ? 2 : 0}
            columnSpacing={3}>
        <Grid item xs={6}>
          {/*Voci spesa*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              label={t('expenseItem')}
              value={editExpense?.expenseDesc}
              InputProps={{
                startAdornment: editExpense ? <Box pt={2.8} mr={1}><ExpenseImg label={editExpense?.expenseDesc}
                                                                               img={editExpense?.expenseIcon}/></Box> : <></>
              }}
            />
          ) : (
            <>
              {loadingExpItem && <Skeleton height={50} sx={{transform: "none"}}/>}
              {!loadingExpItem &&
                <Controller
                  name='expenseItem'
                  control={formMethods.control}
                  render={({field, fieldState}) => {
                    return <AutocompleteZts
                      id={field.name}
                      label={t('expenseItem')}
                      options={filterCurrentExpenseItems()
                        .map(i => createAutocompleteGenericOption(
                          i.id,
                          i.expenseCode,
                          i.expenseDescription,
                          i.expenseIcon))}
                      selectedValue={field.value}
                      setValue={(id, value: AutocompleteGenericOption) => {
                        //console.log(value);
                        if (value && value.id) {
                          onChangeExpItem(value.id);

                          const expItem = currentExpItems.find(e => e.id === value.id);
                          if (expItem) {
                            formMethods.setValue(id,
                              {
                                ...value,
                                type: expItem.expenseType,
                                colleagues: expItem.colleagueEnb,
                                guests: expItem.guestEnb,
                                routes: expItem.carRouteEnb,
                                measUnit: expItem.measureUnit,
                                compPeriod: expItem.compPeriod,
                                compPeriodStartLabel: expItem.compPeriodStartLabel,
                                compPeriodEndLabel: expItem.compPeriodEndLabel,
                                attachRequired: expItem.attachRequired,
                                localityRequired: expItem.localityRequired,
                                notesRequired: expItem.notesRequired,
                                projectRequired: expItem.projectRequired,
                              }
                            );
                          } else {
                            formMethods.setValue(id, value);
                          }
                        } else {
                          onChangeExpItem(-1);
                          formMethods.setValue(id, null);
                        }
                      }}
                      codeAndDesc={false}
                      errorMsg={fieldState.error?.message}
                      renderIcon={(icon) => <ExpenseImg label="" img={icon}/>}
                    />
                  }}
                />
              }
            </>
          )}
        </Grid>
        {getCompDates()}
      </Grid>

      <Box mt={2}/>

      {getFormDataByType()}

      {(editableAndReadonly || expenseItemWatcher) && <>
        <Grid container columnSpacing={3} mt={2}>
          <Grid item xs={6}>
            {/*Località*/}
            {editableAndReadonly ? (
              <TextFieldZts
                disabled={true}
                label={t('locality')}
                value={editExpense?.locality}
              />
            ) : (

              <Controller
                name='locality'
                control={formMethods.control}
                render={({field, fieldState}) => {
                  return <AutocompleteZts
                    id={field.name}
                    label={t("locality")}
                    options={localityItems}
                    lazyLoadOptions={loadLocalities}
                    selectedValue={field.value}
                    codeAndDesc={false}
                    userValueEnabled={true}
                    onBlurHandler={value => {
                      if (value && value.trim() !== '') {
                        //formMethods.setValue('locality', );
                        field.onChange(createAutocompleteGenericOption(localityItems ? localityItems.length + 1 : 1, value, value));
                      } else {
                        //formMethods.setValue('locality', null);
                        field.onChange(null);
                      }
                    }}
                    setValue={(id, value) => {
                      //console.log(id,value)
                      field.onChange(value);
                    }}
                    errorMsg={fieldState.error?.message}
                    matchEqualByCode={true}
                  />
                }}
              />

            )}
          </Grid>
          <Grid item xs={6}>
            {getProject()}
          </Grid>
        </Grid>


      </>}

      {(editableAndReadonly || expenseItemWatcher) && <Grid container mt={2}>
        <Grid item xs={12}>
          {/*Note*/}
          {editableAndReadonly ? (
            <TextFieldZts
              multiline
              minRows={2}
              maxRows={3}
              disabled={true}
              label={t('notes')}
              value={editExpense?.notes}
            />
          ) : (
            <Controller
              name='notes'
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <TextFieldZts
                  multiline
                  minRows={3}
                  maxRows={3}
                  label={t("notes")}
                  {...field}
                  errorMsg={fieldState.error?.message}
                  fullWidth
                />
              }}
            />
          )}
        </Grid>
      </Grid>}

      {/*Spese aggiuntive*/}
      <AdditionalExpenses
        expNote={expNote}
        expenses={expenses}
        loadingExpItem={loadingExpItem}
        currentExpItems={currentExpItems}
        readonly={readonly}
      />

    </>
  );

}
