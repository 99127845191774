import React from "react";
import {CommonDialog} from "../../base/common-dialog";
import {useTranslation} from "react-i18next";
import {PasswordUpdateForm} from "../../password/PasswordUpdateForm";
import {useAlert} from "../../../hooks/useAlert";
import {Typography} from "@mui/material";

type ComponentProps = {
  show: boolean,
  onClose: (boolean) => void
}

export const ChangePassword: React.FC<ComponentProps> = ({
                                                           show,
                                                           onClose
                                                         }) => {

  const {t: tProfile} = useTranslation('profile');

  const {
    alert,
    closeAlert
  } = useAlert();

  const handleAfterUpdate = () => {
    alert({
      title: 'Info',
      body: <Typography variant="body1">{tProfile("passwordUpdatedSuccessfully")}</Typography>,
      onClick: () => {
        closeAlert();
        onClose(true);
      }
    });
  }

  return (
    <CommonDialog
      show={show}
      title={tProfile('changePassword')}
      widths={[
        {breakpoint: "md", width: "40%"},
      ]}
      saving={false}
      onClose={onClose}
      errorMsg={null}
      showCta={false}
    >
      <PasswordUpdateForm
        showInModal
        afterUpdateHandler={handleAfterUpdate}
      />
    </CommonDialog>
  );
}
