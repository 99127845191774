import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TransitionLeftZts} from "../../base/transition";
import {useTheme} from "@mui/material/styles";
import {getCreCardMovInfoById} from "../Service";
import {CompleteCreCardMovDto, CreCardMovInfoResponse} from "../model";
import {Loader} from "../../base/loader";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {formatDate} from "../../../util/DateUtil";
import {ExpenseImg} from "../../expenses/commons/ExpenseImg";
import {ZtsTableColumn} from "../../base/table/model";
import {ZtsTable} from "../../base/table";
import {formatAmount, formatNumber} from "../../../util/NumberUtil";
import {CompanyDecimalNum, Currency, ExchangeDecimalNum} from "../../model";

interface ComponentProps {
  id: number,
  open: boolean,
  handleClose: () => void,
  currencies: Currency[]
}

export const CreCardMovInfoPopup = ({id, open, handleClose, currencies}: ComponentProps) => {
  const {t} = useTranslation('cre-card-mov', {keyPrefix: 'popup.info'});
  const {userLocale} = useLoggedUser();
  const theme = useTheme();

  const [data, setData] = useState<CreCardMovInfoResponse | null>(null);

  const [decimalNum, setDecimalNum] = useState(CompanyDecimalNum);

  useEffect(() => {
    if (open && id) {
      asyncGetAndSetCreCardMovInfo().then();
    }
  }, [id]);

  const asyncGetAndSetCreCardMovInfo = async (): Promise<void> => {
    const infos = await getCreCardMovInfoById(id);
    setDecimalNum(currencies.find(c => c.code === infos.creCardCurrency)?.decimalNum || 0);
    setData(infos);
  }

  const columns: ZtsTableColumn[] = [
    {
      id: 'expenseDate',
      label: t('columns.expenseDate'),
      header: <Typography display={'inline-block'} noWrap textTransform={"uppercase"} fontSize={'12px'}
                          fontWeight={500}>{t('columns.expenseDate')}</Typography>,
      sortIconAlignment: 'flex-start',
      formatter: (id, value) => formatDate(value.expenseDate, userLocale)
    },
    {
      id: 'expenseType',
      label: t('columns.expenseType'),
      header: <Typography display={'inline-block'} noWrap textTransform={"uppercase"} fontSize={'12px'}
                          fontWeight={500}>{t('columns.expenseType')}</Typography>,
      sortIconAlignment: 'flex-start',
      formatter: (id, value) => (
        <Box display={'flex'} columnGap={1}>
          <ExpenseImg img={value.expenseIcon} label=""/>
          <Typography variant={'body2'}>{value.expenseDesc}</Typography>
        </Box>
      )
    },
    {
      id: 'currAmount',
      label: t('columns.currAmount'),
      header: <Typography display={'inline-block'} noWrap textTransform={"uppercase"} fontSize={'12px'}
                          fontWeight={500}>{t('columns.currAmount')}</Typography>,
      sortIconAlignment: 'flex-start',
      formatter: (_: number, value: CompleteCreCardMovDto) => {
        return formatAmount(value.currAmount, userLocale, decimalNum, '');
      }
    }
  ];

  const getLinkedExpenses = (): ReactElement | null => {
    if (!data || data.creCardNotValid) {
      return null;
    }

    return (
      <Grid container rowGap={2} p={2}>
        <Grid item xs={12} mt={2}>
          <Typography
            variant={'h4'}>{data.linkedExpenses.length > 1 ? t('expense.title2') : t('expense.title1')}</Typography>
        </Grid>

        {data.linkedExpenses.length > 0 ? (
          data.linkedExpenses.length > 1 ? (
            <>
              <Grid item xs={12}>
                <Stack rowGap={1}>
                  <Typography variant={'h6'}>{t('expense.expNote')}</Typography>
                  <Typography variant={'body2'}>{data.linkedExpenses[0].expNoteDesc}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack rowGap={1}>
                  <Typography variant={'h6'}>{t('expense.currAmount')}</Typography>
                  <Typography
                    variant={'body2'}>{formatAmount(data.linkedExpenses[0].expNoteTotalAmount, userLocale, decimalNum, '')}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack rowGap={1}>
                  <Typography variant={'h6'}>{t('expense.currency')}</Typography>
                  <Typography variant={'body2'}>{data.linkedExpenses[0].currency}</Typography>
                </Stack>
              </Grid>
              <ZtsTable
                columns={columns}
                rows={data.linkedExpenses}
                initialSort={{orderBy: 'expenseDate', orderDir: 'desc'}}
              />
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Stack rowGap={1}>
                  <Typography variant={'h6'}>{t('expense.expNote')}</Typography>
                  <Typography variant={'body2'}>{data.linkedExpenses[0].expNoteDesc}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack rowGap={1}>
                  <Typography variant={'h6'}>{t('expense.currAmount')}</Typography>
                  <Typography
                    variant={'body2'}>{formatAmount(data.linkedExpenses[0].currAmount, userLocale, decimalNum, '')}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack rowGap={1}>
                  <Typography variant={'h6'}>{t('expense.currency')}</Typography>
                  <Typography variant={'body2'}>{data.linkedExpenses[0].currency}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack rowGap={1}>
                  <Typography variant={'h6'}>{t('expense.expenseDate')}</Typography>
                  <Typography
                    variant={'body2'}>{formatDate(data.linkedExpenses[0].expenseDate, userLocale)}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack rowGap={1}>
                  <Typography variant={'h6'}>{t('expense.expenseType')}</Typography>
                  <Box display={'flex'} columnGap={1}>
                    <ExpenseImg img={data.linkedExpenses[0].expenseIcon} label=""/>
                    <Typography variant={'body2'}>{data.linkedExpenses[0].expenseDesc}</Typography>
                  </Box>
                </Stack>
              </Grid>
            </>
          )
        ) : (
          <Grid item xs={12}>
            <Typography variant={'body2'}>({t('expense.emptyState')})</Typography>
          </Grid>
        )
        }
      </Grid>);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionLeftZts}
      fullScreen
      scroll={"paper"}
      fullWidth={true}
      sx={{
        width: useMediaQuery(theme.breakpoints.down("md")) ? "100%" : "35%",
        left: useMediaQuery(theme.breakpoints.down("md")) ? 0 : "unset"
      }}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          columnGap={2}
          alignItems={"center"}
          sx={{color: "#000"}}
        >
          <ArrowBack onClick={handleClose}/>
          <Typography variant={"h3"}>{t('title')}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {
          data ? (
            <>
              <Grid container rowGap={2} p={2} sx={{backgroundColor: '#f2f5f8', borderRadius: '15px'}}>
                <Grid item xs={12}>
                  <Typography variant={'h4'}>{t('movement.title')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{t('movement.uploadId')}</Typography>
                    <Typography variant={'body2'}>{data.uploadId}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{t('movement.uploadDate')}</Typography>
                    <Typography variant={'body2'}>{formatDate(data.uploadInsertDate, userLocale)}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{t('movement.movId')}</Typography>
                    <Typography variant={'body2'}>{data.creCardMovId}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{t('movement.movDate')}</Typography>
                    <Typography variant={'body2'}>{formatDate(data.creCardMovPayDate, userLocale)}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{t('movement.description')}</Typography>
                    <Typography variant={'body2'}>{data.creCardMovDesc}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{t('movement.currAmount')}</Typography>
                    <Typography variant={'body2'}>{formatAmount(data.creCardCurrAmount, userLocale, decimalNum, '')}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack rowGap={1}>
                    <Typography variant={'h6'}>{t('movement.currency')}</Typography>
                    <Typography variant={'body2'}>{data.creCardCurrency}</Typography>
                  </Stack>
                </Grid>
                {
                  data.creCardExchange && data.creCardCurrency &&
                  <>
                    <Grid item xs={6}>
                      <Stack rowGap={1}>
                        <Typography
                          variant={'h6'}>{t('movement.compAmount', {currency: 'EURO'})}</Typography>
                        <Typography variant={'body2'}>{formatAmount(data.creCardCompAmount, userLocale, CompanyDecimalNum, '')}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack rowGap={1}>
                        <Typography
                          variant={'h6'}>{t('movement.exchange')}</Typography>
                        <Typography variant={'body2'}>{formatNumber(data.creCardExchange, userLocale, ExchangeDecimalNum)}</Typography>
                      </Stack>
                    </Grid>
                  </>
                }
              </Grid>

              {getLinkedExpenses()}

              {data.creCardNotValid && (
                <Alert severity="error" sx={{mt: 5}}>
                  <Typography>
                    {t('movement.notValid')}
                    <Typography lineHeight={1.1}>
                      {data.creCardDiscardedNotes}
                    </Typography>
                  </Typography>
                </Alert>
              )}
            </>
          ) : (
            <Loader show={true}/>
          )
        }
        {

        }
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={"cta-button-primary"}
          onClick={handleClose}
        >
          <Typography ml={1} variant={"button"}>{t("button.close")}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
