import {ListFilter, ListFilters, ListFilterType} from "../model";
import Box from "@mui/material/Box";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {SearchBar} from "../../base/search-bar";

type LevelFilterProps = {
  onFilterChange: Function
}

export const Filters = ({onFilterChange}: LevelFilterProps) => {

  const [filters, setFilters] = useState<ListFilter[]>(ListFilters);

  useEffect(() => {
    onFilterChange(filters);
  }, [filters]);

  const searchTextChangeHandler = (e: SyntheticEvent) => {
    let target = e.target as HTMLInputElement;

    if (filters) {
      setFilters((prevFilters: ListFilter[]) =>
        prevFilters.map(f => f.type === ListFilterType.SEARCH_TEXT ? ({...f, value: target.value}) : f));
    }
  }

  return (
    <Box
      flexGrow={{
        xs: 1,
        lg: 0
      }}
      flexBasis={{
        xs: "calc(100% - 60px)",
        lg: "auto"
      }}
    >
      <SearchBar
        filterText={filters && filters.filter(f => f.type === ListFilterType.SEARCH_TEXT)[0].value}
        onFilterChange={searchTextChangeHandler}
      />
    </Box>
  );
}
