import {Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import {TextFieldZts} from "../../base/text-field";
import {useTranslation} from "react-i18next";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {NewExpenseFormValues} from "./validation";
import {Delete} from "@mui/icons-material";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {AutocompleteZts} from "../../base/autocomplete";
import {getExpNoteGuests} from "../Service";
import {ExpNote} from "../model";

type ComponentProps = {
  expNote: ExpNote,
  editableAndReadonly?: boolean,
  formArrayName?: "guests" | `additionalExpenses.${number}.guests`
}

export const Guests = ({expNote, editableAndReadonly, formArrayName = "guests"}: ComponentProps) => {

  const formMethods = useFormContext<NewExpenseFormValues>();

  const {fields, append, replace} = useFieldArray({
    name: formArrayName,
    control: formMethods.control
  });

  const {t} = useTranslation('exp-note', {keyPrefix: 'expense.edit'});

  const guests = useWatch({name: formArrayName, exact: true});

  const [guestItems, setGuestItems] = useState<AutocompleteGenericOption[] | null>(null);

  // aggiunge la prima riga vuota
  useEffect(() => {
    if (!editableAndReadonly) {
      append({guest: null}, {shouldFocus: false});
    }
  }, []);

  const loadGuests = async () => {
    if (guestItems) {
      return;
    }
    const guests = await getExpNoteGuests(expNote.id);
    if (guests && guests.length > 0) {
      setGuestItems(guests
        .map((guest, index) => createAutocompleteGenericOption(index + 1, guest, guest)));
    } else {
      setGuestItems([]);
    }
  }

  const forceValidation = () => {
    formMethods.trigger(formArrayName);
  }

  useEffect(() => {
    forceValidation();
  }, [fields]);

  const handleDeleteGuest = (index: number) => {
    if (guests.length === 1) {
      formMethods.setValue(`${formArrayName}.0.guest`, null);
    } else {
      removeRow(index);
    }
  }

  const getEmptyRowIndex = (): number => {
    return guests.findIndex(g => !g || !g.guest || g.guest.code === '');
  }

  const removeRow = (index) => {
    const values = guests;
    if (values) {
      replace(values.filter((_, idx) => index !== idx));
    }
  }

  return (
    <>
      {editableAndReadonly && fields.length === 0 ? (
        <></>
      ) : (
        <>
          <Typography mb={1} variant={"body2"}>{t('guest', {count: 2})}</Typography>
          <Grid
            container
            rowSpacing={1}
            alignItems={"center"}
          >
            {fields.map((item, index) => (
              <React.Fragment key={item.id}>
                {editableAndReadonly ? (
                  <Grid item xs={12}>
                    <TextFieldZts
                      disabled={true}
                      hiddenLabel={true}
                      value={formMethods.getValues(`${formArrayName}.${index}.guest`)?.selectedLabel}
                    />
                  </Grid>
                ) : (
                    <Grid item xs={12}>
                      <Controller
                        name={`${formArrayName}.${index}.guest`}
                        control={formMethods.control}
                        render={({field, fieldState}) => {
                          return <AutocompleteZts
                            id={field.name}
                            hiddenLabel={true}
                            label=''
                            lazyLoadOptions={loadGuests}
                            options={guestItems}
                            selectedValue={field.value}
                            userValueEnabled={true}
                            onBlurHandler={value => {
                              if (field.value && value && field.value.code === value) {
                                // il valore è già stato impostato
                                return;
                              }

                              const isValue = value && value.trim() !== '';

                              if (isValue) {
                                field.onChange(createAutocompleteGenericOption(guestItems ? guestItems.length + 1 : 1, value, value));
                              } else {
                                field.onChange(null);
                              }

                              const emptyRowIdx = getEmptyRowIndex();
                              if (isValue && emptyRowIdx === -1) {
                                append({guest: null});
                              } else if (!isValue && emptyRowIdx !== guests.length - 1) {
                                removeRow(emptyRowIdx);
                              } else {
                                forceValidation();
                              }
                            }}
                            setValue={(id, value) => {
                              field.onChange(value);
                              if (value) {
                                append({guest: null});
                              } else {
                                removeRow(index);
                              }
                            }}
                            codeAndDesc={false}
                            errorMsg={fieldState.error?.message}
                            matchEqualByCode={true}
                            clearIcon={<Delete
                              className={"text-danger"}
                              fontSize={"small"}
                            />}
                          />
                        }}
                      />
                    </Grid>
                    // <Grid item xs={1}>
                  //   {index > 0 && guests[index] && guests[index].guestName &&
                  //     <IconButton
                  //       size={"small"}
                  //       onClick={() => handleDeleteGuest(index)}
                  //     >
                  //       <Delete
                  //         className={"text-danger"}
                  //         fontSize={"small"}
                  //       />
                  //     </IconButton>}
                  // </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
    </>
  );
}
