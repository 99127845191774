import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {Size, UserPicture} from "../UserPicture";

import {useTranslation} from 'react-i18next';

import '../base.scss';
import {createAutocompleteApproverOption, SaveUser, User} from "../model";
import {activeStateCode, DETAIL_URL, FileUploadReq, USER_URL} from "../../model";
import {Alert, Button, Paper, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {getApprovers, getUserById, saveUser} from "../Service";
import {getActiveLevels} from "../../levels/Service";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {getActiveAccounts} from "../../accounts/Service";
import {useErrorMessage} from "../../../util/ErrorUtil";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {PageTitle} from "../../layout/page-title";
import {GeneralData} from "../detail/GeneralData";
import {Role} from "../detail/Role";
import {AdministrativeData} from "../detail/AdministrativeData";
import {PersonalData} from "../detail/PersonalData";
import {Loader} from "../../base/loader";
import {useSelector} from "react-redux";
import {UserEditLoader} from "./UserEditLoader";
import {uploadFile} from "../../exp_note/Service";
import {Image, Save} from "@mui/icons-material";
import {
  yupMaxNullableString,
  yupMinMaxRequiredString,
  yupRequiredEmail,
} from "../../../util/YupUtil";
import {ITALIAN, Locale} from "../../../util/LocalizationUtil";
import {ImageEditor} from "../../base/image/editor";
import {getDefaultTravelPolicy} from "../../travel_policies/Service";
import {TravelPolicy} from "../../travel_policies/model";
import {useAlert} from "../../../hooks/useAlert";
import {useLicense} from "../../../hooks/useLicense";
import {useCheckDisableUser} from "../hooks/useCheckDisableUser";

type UserEditFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  fiscalCode: string;
  matricNumber: string;
  address: string;
  location: string;
  cap: string;
  costCenter: AutocompleteGenericOption | null;
  approver: AutocompleteGenericOption | null;
  account: AutocompleteGenericOption | null;
  locale: Locale;
}

export const UserEdit = () => {

  const {t} = useTranslation(['user', 'validation', 'common']);

  const [loadingData, setLoadingData] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTraveller, setIsTraveller] = useState(true);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const {convertError} = useErrorMessage();

  const [costCenterOpts, setCostCenterOpts] = useState<AutocompleteGenericOption[]>([]);
  const [loadingCostCenter, setLoadingCostCenter] = useState(false);

  const [accountOpts, setAccountOpts] = useState<AutocompleteGenericOption[]>([]);
  const [loadingAccount, setLoadingAccount] = useState(false);

  const [approverOpts, setApproverOpts] = useState<AutocompleteGenericOption[]>([]);
  const [loadingApprover, setLoadingApprover] = useState(false);

  const [picture, setPicture] = useState<string | Blob | null>(null);
  const [pictureId, setPictureId] = useState<string | null | undefined>(null);
  const [tempPicture, setTempPicture] = useState(false);

  let {id} = useParams();

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const navigate = useNavigate();

  const {
    alert
  } = useAlert();

  const {
    canDisableUser
  } = useCheckDisableUser();

  useEffect(() => {
    if (id) {
      setLoadingData(true);
      if (id === '-1') {
        setUser({
          id: -1,
          firstName: '',
          lastName: '',
          email: '',
          fiscalCode: '',
          matricNumber: '',
          address: '',
          cap: '',
          location: '',
          lastUpdateNum: 0,
          state: activeStateCode,
          isAdmin: false,
          isTraveller: true,
          locale: ITALIAN //TODO: impostare il locale di default letto dal BE???
        });
        setLoadingData(false);
      } else {
        getUserById(id)
          .then(u => {
            setUser(u);
            setIsTraveller(true); // il ruolo Viaggiatore è obbligatorio
            setIsAdmin(u.isAdmin);
            setPictureId(u.pictureId);
          })
          .catch(err => setErrorMsg(`Error retrieving data. ${err}`))
          .finally(() => setLoadingData(false));
      }
    }
  }, [id]);

  const validationSchema = Yup.object({
    firstName: yupMinMaxRequiredString(3, 30, t),
    lastName: yupMinMaxRequiredString(3, 30, t),
    email: yupRequiredEmail(t),
    fiscalCode: yupMaxNullableString(16, t), //, {length: {key: 'ciccio.prova'}}),
    matricNumber: yupMaxNullableString(20, t),
    address: yupMaxNullableString(50, t),
    location: yupMaxNullableString(30, t),
    cap: yupMaxNullableString(10, t),
    costCenter: Yup.object().nullable(),
    approver: Yup.object().nullable(),
    account: Yup.object().nullable(),
    locale: Yup.string()
  });

  const initVal = {
    firstName: user ? user.firstName : '',
    lastName: user ? user.lastName : '',
    email: user ? user.email : '',
    fiscalCode: user ? user.fiscalCode : '',
    matricNumber: user ? user.matricNumber : '',
    address: user ? user.address : '',
    location: user ? user.location : '',
    cap: user ? user.cap : '',
    costCenter: user && user.costCenter ?
      createAutocompleteGenericOption(user.costCenter.id, user.costCenter.code, user.costCenter.description) :
      null,
    approver: user && user.approver ?
      createAutocompleteApproverOption(user.approver.id, user.approver.firstName, user.approver.lastName) :
      null,
    account: user && user.account ?
      createAutocompleteGenericOption(user.account.id, user.account.code, user.account.description) :
      null,
    locale: user && user.locale ? user.locale : ITALIAN
  };

  const formMethods = useForm<UserEditFormValues>({
    defaultValues: {},
    resolver: yupResolver(validationSchema),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  useEffect(() => {
    if (user) {
      formMethods.reset(initVal);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (!free) {
        const tmpGetLevels = async () => {
          try {
            setLoadingCostCenter(true);
            const levels = await getActiveLevels();
            if (levels && levels.length > 0) {
              setCostCenterOpts(levels.map(l => createAutocompleteGenericOption(l.id, l.code, l.description)));
              //console.log('costCenterOpts', costCenterOpts)
            }
          } catch (error) {
            console.log('Fetch levels error', error);
          }
          setLoadingCostCenter(false);
        }
        tmpGetLevels();
      } else {
        const tmpCostCenterOpts: AutocompleteGenericOption[] = [];
        setCostCenterOpts(tmpCostCenterOpts);
      }
    }
  }, [user, free]);

  useEffect(() => {
    if (user) {
      if (!free) {
        setLoadingAccount(true);
        getActiveAccounts()
          .then(accounts => {
            if (accounts && accounts.length > 0) {
              setAccountOpts(accounts.map(l => createAutocompleteGenericOption(l.id, l.code, l.description)));
            }
          })
          .catch(err => console.log('Fetch accounts error', err))
          .finally(() => setLoadingAccount(false));
      } else {
        const tmpOpts: AutocompleteGenericOption[] = [];
        setAccountOpts(tmpOpts);
      }
    }
  }, [user, free]);

  useEffect(() => {
    if (user) {
      if (!free) {
        const tmpGetApprovers = async () => {
          try {
            setLoadingApprover(true);
            const approvers = await getApprovers();
            //console.log('approvers', approvers)
            if (approvers && approvers.length > 0) {
              setApproverOpts(approvers
                .filter(a => a.id !== user.id)
                .map(a => createAutocompleteApproverOption(a.id, a.firstName, a.lastName)));
            }
          } catch (error) {
            console.log('Fetch approver error', error);
          }
          setLoadingApprover(false);
        }
        tmpGetApprovers();
      } else {
        const tmpApproverOpts: AutocompleteGenericOption[] = [];
        setApproverOpts(tmpApproverOpts);
      }
    }
  }, [user, free]);

  const handleSave = async (values) => {
    setErrorMsg(null);
    setSaving(true);

    const insert = id === '-1';

    let defaultTP: TravelPolicy | null = null;
    if (insert) {
      try {
        defaultTP = await getDefaultTravelPolicy();
      } catch (err) {
        convertError(err as Error).then(msg => setErrorMsg(msg));
      }
    }

    if (!insert || (defaultTP && defaultTP.state === activeStateCode)) {
      await doSave(values);
    } else {
      alert({
        body: (
          <>
            <Typography variant="body1">{t("user:edit.assignTravelPolicyAlert")}</Typography>
          </>
        ),
        onClick: async () => {
          await doSave(values, true);
        }
      });
    }
  }

  const doSave = async (values, openAfterSave = false) => {
    const updUser: SaveUser = {
      id: user && user.id !== -1 ? user.id : null,
      state: user ? user.state : activeStateCode,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      fiscalCode: values.fiscalCode,
      matricNumber: values.matricNumber,
      address: values.address,
      location: values.location,
      cap: values.cap,
      costCenterId: values.costCenter ? values.costCenter.id : null,
      approverId: values.approver ? values.approver.id : null,
      accountId: values.account ? values.account.id : null,
      lastUpdateNum: user ? user.lastUpdateNum : 0,
      isAdmin,
      isTraveller,
      pictureId: pictureId ? pictureId : null,
      locale: values.locale
    };

    try {
      const resp = await saveUser(updUser);
      if (openAfterSave) {
        navigate(`..${DETAIL_URL}/${resp.id}`, {state: {openTp: true}});
      } else {
        handleNavigateBack();
      }
    } catch (error: any) {
      convertError(error).then(msg => setErrorMsg(msg));
    } finally {
      setSaving(false);
    }
  }

  const handleSaveClicked = () => {
    formMethods.handleSubmit(handleSave)();
  }

  const handleNavigateBack = () => {
    if (id === '-1') {
      navigate(USER_URL);
    } else {
      navigate(`..${DETAIL_URL}/${id}`);
    }
  }

  const onPictureUpload = (request: FileUploadReq, onUploadProgressHandler: (progressEvent: any) => void) => {
    uploadFile(request, onUploadProgressHandler)
      .then(response => {
        setPictureId(response.uploadKey);
        setTempPicture(true);
      })
  }

  const onPictureRemove = () => {
    if (pictureId) {
      setPicture(null);
      setPictureId(null);
    }
  };

  const toggleAdminRole = async (checked: boolean) => {
    if (!checked && user) {
      const canDisable = await canDisableUser(user.id, "edit.removeAdminError");
      if (!canDisable) {
        return;
      }
    }
    setIsAdmin(checked);
  }

  return (
    <>
      <Box
        overflow={"hidden"}
        flexGrow={1}
      >

        <Paper
          elevation={0}
          sx={{
            height: "100%"
          }}
        >

          <Stack
            height={"100%"}
            overflow={"auto"}
            padding={"20px 30px 20px 20px"}
          >

            <Loader show={saving}/>

            {/* Skeleton */}
            {loadingData && <UserEditLoader/>}

            {/* banner con errori */}
            {errorMsg && <Alert
              severity={"error"}
              onClose={() => setErrorMsg(null)}
            >{errorMsg}</Alert>}

            {/* titolo + pulsanti */}
            {user && <PageTitle
              backClickHandler={handleNavigateBack}
              title={
                <Stack
                  direction={{xs: "column", sm: "row"}}
                  alignItems={{xs: "flex-start", sm: "center"}}
                  justifyContent={{xs: "center", sm: "flex-start"}}
                  flexGrow={"1"}
                >
                  <Typography variant={"h3"}
                              mr={3}>{t(user && user.id !== -1 ? "edit.titleUpdate" : "edit.titleNew", {ns: "user"})}</Typography>
                </Stack>
              }
            />}

            {/* body */}
            {user && <Box flexGrow={1}>
              <FormProvider {...formMethods}>
                <form noValidate>

                  {/* parte sx */}
                  <Stack
                    mt={3}
                    flexGrow={1}
                    direction={{
                      xs: "column-reverse",
                      lg: "row"
                    }}
                    columnGap={2}
                    width={"100%"}
                  >
                    <Box
                      width={'100%'}
                      ml={{
                        xs: 0,
                        lg: 4.8
                      }}
                    >

                      {/* Dati generali */}
                      <GeneralData
                        user={user}
                        enabled={true}
                      />

                      {/* Ruoli */}
                      <Role
                        user={user}
                        enabled={true}
                        isAdmin={isAdmin}
                        handleToggleAdmin={(checked) => toggleAdminRole(checked)}
                        isTraveller={isTraveller}
                        handleToggleTraveller={(checked) => setIsTraveller(checked)}
                      />

                      {/* Dati anagrafici */}
                      <PersonalData
                        user={user}
                        enabled={true}
                      />

                      {/* Dati amministrativi */}
                      <AdministrativeData
                        user={user}
                        enabled={true}
                        costCenterOpts={costCenterOpts}
                        loadingCostCenter={loadingCostCenter}
                        approverOpts={approverOpts}
                        loadingApprover={loadingApprover}
                        accountOpts={accountOpts}
                        loadingAccount={loadingAccount}
                      />

                    </Box>

                    {/* parte dx */}
                    <Stack
                      direction={{
                        xs: 'row',
                        md: 'column'
                      }}
                      alignItems={"center"}
                      mb={{
                        xs: 3,
                        lg: 0
                      }}
                      pl={{
                        xs: 0,
                        md: 3
                      }}
                      sx={{
                        flexBasis: {
                          xs: '0',
                          lg: '200px'
                        }
                      }}
                    >
                      <UserPicture
                        user={user}
                        size={Size.DETAIL}
                        edit={true}
                        pictureId={pictureId}
                        tempPicture={tempPicture}
                      />
                      <Box
                        mt={{
                          xs: 0,
                          md: 2
                        }}
                        ml={{
                          xs: 1.5,
                          md: 0
                        }}
                      >

                        {pictureId ? (
                          <Box
                            className={"edit-picture"}
                            onClick={onPictureRemove}
                          >
                            <Image fontSize={"small"}/>
                            <Typography ml={1} variant={"h6"}>{t("edit.removePicture")}</Typography>
                          </Box>
                        ) : (
                          <>
                            <Box
                              className={"edit-picture"}
                              onClick={() => setOpenDialog(true)}
                            >
                              <Image fontSize={"small"}/>
                              <Typography ml={1} variant={"h6"}>{t("edit.addPicture")}</Typography>
                            </Box>
                            {errorMsg && <Typography variant={"body2"} mt={2} className={"text-danger"}>
                              {errorMsg}
                            </Typography>}
                          </>
                        )}
                        <ImageEditor setImage={setPicture} open={openDialog} openDialog={setOpenDialog}
                                     uploadImage={onPictureUpload}/>

                      </Box>

                    </Stack>

                  </Stack>

                </form>
              </FormProvider>
            </Box>}

            {/* Pulsanti (Annulla, Salva) */}
            {user && <Stack
              alignSelf={"flex-end"}
              direction={"row"}
              mt={4}
              columnGap={2.2}
            >
              <Button
                variant="contained"
                className={"cta-button"}
                disabled={saving}
                onClick={() => {
                  setErrorMsg(null);
                  handleNavigateBack();
                }}>
                <Typography variant={"button"}>{t('common:modal.cancel')}</Typography>
              </Button>
              <Button
                variant="contained"
                className={"cta-button-primary"}
                disabled={saving}
                onClick={() => {
                  setErrorMsg(null);
                  handleSaveClicked()
                }}
              >
                <Save sx={{fontSize: "1.1em"}}/>
                <Typography ml={1} variant={"button"}>{t('common:modal.save')}</Typography>
              </Button>
            </Stack>}

          </Stack>
        </Paper>

      </Box>
    </>
  );
}
