import {Alert, Box, Button, Paper, Stack, Typography} from "@mui/material";
import {Loader} from "../../base/loader";
import React, {useEffect, useState} from "react";
import {AccountEditLoader} from "./AccountEditLoader";
import {Account, SaveAccount} from "../model";
import {PageTitle} from "../../layout/page-title";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import {GeneralData} from "../detail/GeneralData";
import {activeStateCode, getStateCode} from "../../model";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {getAccountById, insertAccount, updateAccount} from "../Service";
import {Save} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

type AccountEditFormValues = {
  code: string,
  description: string,
  state: string
}

export const AccountEdit = () => {

  const {t} = useTranslation(['account'], {keyPrefix: 'edit'});
  //------
  // State
  //------
  const [saving, setSaving] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [account, setAccount] = useState<Account>();
  const [active, setActive] = useState<boolean>(true);
  const {convertError} = useErrorMessage();

  const navigate = useNavigate();

  let {id} = useParams();

  // Objects
  //--------

  const initialValues = {
    code: account ? account.code : '',
    description: account ? account.description : '',
    state: account ? account.state : getStateCode(false)
  };

  const validationSchema = Yup.object({
    code: Yup.string()
      .max(20, 'Must be 20 characters or less')
      .min(3, 'Must be at least 3 characters')
      .required('Required'),
    description: Yup.string()
      .max(50, 'Must be 50 characters or less')
      .min(3, 'Must be at least 3 characters')
      .required('Required')
  });

  const formMethods = useForm<AccountEditFormValues>({
    defaultValues: {
      code: "",
      description: "",
      state: ""
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange"
  });

  // Use effects
  //------------
  useEffect(() => {
    if (id && id !== '-1') {
      setLoadingData(true);

      getAccountById(id)
        .then(response => {
          const data = response.data;
          setAccount(data);
          setActive(data.state === 'EF');

        })
        .catch(error => {
          convertError(createError(error))
            .then(resp => setErrorMsg(resp))
        })
        .finally(() => setLoadingData(false));
    }
    else if (id && id === '-1') {
      setLoadingData(true);
      setActive(true);
      const newAccount = {
        id: -1,
        code: '',
        description: '',
        state: activeStateCode,
        lastUpdateNum: 0
      };
      setAccount(newAccount);
      setLoadingData(false);
    }
  }, [id]);



  useEffect(() => {
    if (account) {
      formMethods.reset(initialValues);
    }
  }, [account]);

  // Functions
  //----------

  const handleNavigateBack = () => {
    if (id === '-1') {
      navigate('/accounts');
    }
    else {
      navigate(`/accounts/detail/${id}`);
    }
  }

  const handleSave = (values) => {
    setErrorMsg(null);
    setSaving(true);

    const toSave: SaveAccount = {
      id: account ? account.id : null,
      state: getStateCode(active),
      code: values.code,
      description: values.description,
      lastUpdateNum: account ? account.lastUpdateNum : 0
    };

    if (id !== '-1') {
      updateAccount(toSave)
        .then(() => {
          handleNavigateBack();
        })
        .catch(error => {
          convertError(error)
            .then(msg => setErrorMsg(msg))
        })
        .finally(() => setSaving(false))
    }
    else {
      insertAccount(toSave)
        .then(() => {
          handleNavigateBack();
        })
        .catch(error => {
          convertError(error)
            .then(msg => setErrorMsg(msg))
        })
        .finally(() => setSaving(false))
    }

  }

  const handleSaveClicked = () => {
    formMethods.handleSubmit(handleSave)();
  }

  // Componente React
  //-----------------
  return (
    <Box
      overflow={"hidden"}
      flexGrow={1}
    >
      <Paper
        elevation={0}
        sx={{
          height: "100%"
        }}
      >
        <Stack
          height={"100%"}
          overflow={"auto"}
          padding={"20px 30px 20px 20px"}
        >
          <Loader show={saving}/>

          {/*Skeleton*/}
          {
            loadingData &&
            <AccountEditLoader/>
          }

          {/*Banner errori*/}
          {
            errorMsg &&
            <Alert
              severity={"error"}
              onClose={() => setErrorMsg(null)}
            >
              {errorMsg}
            </Alert>
          }

          {/*Titolo pagina e pulsanti*/}
          {
            account &&
            <PageTitle
              backClickHandler={handleNavigateBack}
              title={
                  <Stack
                    direction={{xs: "column", sm: "row"}}
                    alignItems={{xs: "flex-start", sm: "center"}}
                    justifyContent={{xs: "center", sm: "flex-start"}}
                    flexGrow={"1"}
                  >
                    <Typography variant={"h3"} mr={3}>{t("titleNew")}</Typography>
                  </Stack>
              }
            />
          }


          {/*Body*/}
          {
            account &&
            <Box flexGrow={1}>
              <FormProvider {...formMethods}>
                <form noValidate>
                  <Stack
                    mt={3}
                    flexGrow={1}
                    direction={{
                      xs: "column-reverse",
                      lg: "row"
                    }}
                    columnGap={2}
                    width={"100%"}
                  >

                    {/* Dati generali */}
                    <GeneralData props={{account: account, edit: true, secondEnabled: true, thirdEnabled: true}} />

                  </Stack>

                </form>
              </FormProvider>
            </Box>
          }

          {/*Pulsanti (Annulla e Salva)*/}
          {
            account &&
            <Stack
              alignSelf={"flex-end"}
              direction={"row"}
              mt={4}
              columnGap={2.2}
            >
              <Button
                variant="contained"
                className={"cta-button"}
                disabled={saving}
                onClick={() => {
                  setErrorMsg(null);
                  handleNavigateBack();
                }}>
                <Typography variant={"button"}>{t("button.cancel")}</Typography>
              </Button>

              <Button
                variant="contained"
                className={"cta-button-primary"}
                disabled={saving}
                onClick={() => {
                  setErrorMsg(null);
                  handleSaveClicked()
                }}
              >
                <Save sx={{fontSize: "1.1em"}}  />
                <Typography ml={1} variant={"button"}>{t("button.save")}</Typography>
              </Button>

            </Stack>
          }

        </Stack>

      </Paper>

    </Box>
  );
}
