import {FileType, MimeType} from "../../../../base/file-upload/model";
import {Box, Stack, Step, StepLabel, Stepper, TableCell, TableRow, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {FileUploadResp} from "../../../../model";
import {getColumnLabelByArrayIndex, getUploadedFileRows} from "../../Service";
import {
  ColumnDataType, computeRowIndex,
  CsvSeparators,
  DateFormats,
  DecimalSeparators,
  ImportConfigurationSeparators, isSkipRowsSet, isSkipRowsSetByFilter,
  ParseFileFilter
} from "../../model";
import {ZtsTableColumn} from "../../../../base/table/model";
import {formatDate} from "../../../../../util/DateUtil";
import {useLoggedUser} from "../../../../../hooks/useLoggedUser";
import {ZtsTable} from "../../../../base/table";
import {useTranslation} from "react-i18next";
import {TextFieldZts} from "../../../../base/text-field";
import {AutocompleteZts} from "../../../../base/autocomplete";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../../../base/autocomplete/model";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import {Loader} from "../../../../base/loader";

interface ComponentsProps {
  open: boolean,
  uploadedFile: FileUploadResp,
  previousConfig?: ImportConfigurationSeparators,
  setConfig: (config: ImportConfigurationSeparators) => void,
  foundCsvSeparator?: string,
  previewHeader: JSX.Element | undefined
  setPreviewHeader: (previewHeader: JSX.Element | undefined) => void
}

interface FormValues {
  csvSeparator: AutocompleteGenericOption | null,
  rowsToSkip: number,
  dateFormat: string | null,
  decimalSeparator: AutocompleteGenericOption | null
}


export const ImportConfigurerSecondTab = ({
                                            open,
                                            uploadedFile,
                                            previousConfig,
                                            setConfig,
                                            foundCsvSeparator,
  previewHeader,
  setPreviewHeader
                                          }: ComponentsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<ZtsTableColumn[]>([]);
  const [rows, setRows] = useState<object[]>([]);

  const [currentConfiguration, setCurrentConfiguration] = useState<ImportConfigurationSeparators>(previousConfig ? (previousConfig && foundCsvSeparator ? {
    ...previousConfig,
    csvSeparator: foundCsvSeparator
  } : previousConfig) : {
    csvSeparator: foundCsvSeparator ? foundCsvSeparator : null,
    rowsToSkip: 0,
    dateFormat: '',
    decimalSeparator: null
  });

  const {userLocale} = useLoggedUser();
  const {t} = useTranslation('cre-card-mov', {keyPrefix: 'configurer'});


  const fileType = (uploadedFile.mimeType === MimeType.CSV || uploadedFile.mimeType === MimeType.TXT) ? FileType.CSV : (uploadedFile.mimeType === MimeType.EXCEL ? FileType.EXCEL : FileType.EXCEL_XLSX);

  let formDefaultValues: FormValues = {
    csvSeparator: null,
    rowsToSkip: previousConfig?.rowsToSkip ? previousConfig.rowsToSkip : 0,
    dateFormat: null,
    decimalSeparator: null
  }

  const separatorOptions: AutocompleteGenericOption[] = Object.keys(CsvSeparators).map((e, i) => {
    const opt: AutocompleteGenericOption = createAutocompleteGenericOption(i + 1, CsvSeparators[e] === '\t' ? '\\t' : CsvSeparators[e], `${t(`separators.${e.toLowerCase()}`)} (${CsvSeparators[e] === '\t' ? '\\t' : CsvSeparators[e]})`);
    if (previousConfig && previousConfig.csvSeparator && CsvSeparators[e] === previousConfig.csvSeparator) {
      formDefaultValues.csvSeparator = opt;
    } else if (CsvSeparators[e] === foundCsvSeparator) {
      formDefaultValues.csvSeparator = opt;
    }
    return opt;
  });

  const dateFormatOptions: AutocompleteGenericOption[] = Object.keys(DateFormats).map((e, i) => {
    return createAutocompleteGenericOption(i + 1, e, DateFormats[e]);
  });

  if (previousConfig && previousConfig.dateFormat) {
    formDefaultValues.dateFormat = previousConfig.dateFormat;
  }

  const decimalSeparatorOptions: AutocompleteGenericOption[] = Object.keys(DecimalSeparators).map((e, i) => {
    const opt: AutocompleteGenericOption = createAutocompleteGenericOption(i + 1, DecimalSeparators[e], DecimalSeparators[e]);
    if (previousConfig && DecimalSeparators[e] === previousConfig.decimalSeparator) {
      formDefaultValues.decimalSeparator = opt;
    }
    return opt;
  });

  const formMethods = useForm<FormValues>({
    defaultValues: formDefaultValues,
    mode: "onBlur",
    resolver: yupResolver(Yup.object({
      rowsToSkip: Yup.number()
    }))
  });

  useEffect(() => {
    asyncGetAndSetRows({
      decimalSeparator: currentConfiguration.decimalSeparator ? currentConfiguration.decimalSeparator : undefined,
      rowsToSkip: currentConfiguration.rowsToSkip ? currentConfiguration.rowsToSkip : undefined,
      dateFormat: previousConfig?.dateFormat ? previousConfig.dateFormat : (currentConfiguration.dateFormat ? currentConfiguration.dateFormat : undefined),
      csvSeparator: foundCsvSeparator ? foundCsvSeparator : (currentConfiguration.csvSeparator ? currentConfiguration.csvSeparator : undefined)
    }).then();
  }, []);

  const asyncGetAndSetRows = async (filter?: ParseFileFilter): Promise<void> => {
    setLoading(true);
    try {
      const remoteRows = await getUploadedFileRows({
        uploadKey: uploadedFile.uploadKey,
        fileType: uploadedFile.mimeType,
        filter: filter ? filter : {}
      });
      if (remoteRows && remoteRows.length > 0) {

        const isSkipRows = isSkipRowsSetByFilter(filter);

        const len = Object.keys(remoteRows[0]).length;
        const remoteColumns = Array.from(Array(len)).map((e, i) => {
          return buildZtsTableColumn(i);
        });

        const indexColumn = {
          id: 'index',
          label: '',
          unsortable: true,
          rowsStyle: {borderStyle: '1px solid', borderColor: 'gray'},
          formatter: (id, value) => computeRowIndex(value.index, isSkipRows)
        };

        setColumns([indexColumn, ...remoteColumns]);

        if (isSkipRows) {
          const previewHeader = (
            <TableRow sx={{background: 'lightgrey'}}>
              <TableCell
                key="addHead_index0"
                valign="middle"
              >
              </TableCell>
              {remoteColumns.map((col, index) => (
                <TableCell
                  key={`addHead_${col.id}`}
                  valign="middle"
                >
                  <Typography textAlign="left" fontFamily={'monospace'} whiteSpace={'nowrap'} fontSize={12}>
                    {remoteRows[0][index]}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          );
          setPreviewHeader(previewHeader);
        } else {
          setPreviewHeader(undefined);
        }

        const r = remoteRows.map((row, i) => {
          const result = {
            index: i + 1
          };

          row.forEach((column, idx) => {
            result[getColumnLabelByArrayIndex(idx)] = column;
          });

          return result;
        });

        const startIndex = isSkipRows ? 1 : 0;
        setRows(r.slice(startIndex, startIndex + 4));
      }
    } catch (err: any) {
      //TODO
    } finally {
      setLoading(false);
    }
  }

  const buildZtsTableColumn = (index: number, dataType?: ColumnDataType, highlightColor?: string): ZtsTableColumn => {
    const label = getColumnLabelByArrayIndex(index);
    return {
      id: index.toString(),
      label: label,
      align: 'left',
      unsortable: true,
      headerStyle: {borderStyle: '1px solid', borderColor: 'gray'},
      rowsStyle: highlightColor ? {
        borderStyle: '1px solid',
        borderColor: 'gray',
        backgroundColor: highlightColor
      } : {borderStyle: '1px solid', borderColor: 'gray'},
      formatter: (id, value, columnName = label, dataT = dataType) => {
        const columnValue = value[columnName];
        let newColValue = value[columnName];
        if (dataT) {
          let numericValue: number;
          switch (dataT) {
            case ColumnDataType.DATE:
              newColValue = formatDate(new Date(Date.parse(columnValue)), userLocale);
              break;
            case ColumnDataType.CURRENCY:
              numericValue = columnValue;
              newColValue = numericValue.toLocaleString(userLocale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
              break;
            case ColumnDataType.EXCHANGE:
              numericValue = columnValue;
              newColValue = numericValue.toLocaleString(userLocale, {
                minimumFractionDigits: 6,
                maximumFractionDigits: 6
              });
              break;
          }
        }
        return <Typography fontFamily={'monospace'} whiteSpace={'nowrap'} fontSize={12}>{newColValue}</Typography>;
      }
    };
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      overflow={'hidden'}
      maxHeight={'65vh'}
    >
      <Loader show={loading}/>

      <Stepper activeStep={0}>
        <Step key={1}>
          <StepLabel className={"stepLabel"}>Generale</StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel className={"stepLabel"}>Dati</StepLabel>
        </Step>
        <Step key={3}>
          <StepLabel className={"stepLabel"}>Conferma</StepLabel>
        </Step>
      </Stepper>

      <Typography fontWeight={500} color={'#3315d6'} mt={2}>{t('tabs.2.labels.2')}</Typography>

      <Box
        maxHeight={'40%'}
        overflow={'auto'}
        flexShrink={0}
        mb={2}
        className={'importPreviewTableContainer'}
      >
        <ZtsTable
          columns={columns}
          rows={rows}
          additionalHeader={previewHeader}
        />
      </Box>

      <Box display='flex' overflow={'hidden'} flexGrow={1} flexDirection={'row'}>
        <Box display='flex' flexDirection={'column'} width={'50%'} overflow={'auto'} mt={3.2} p={2}>

          <FormProvider {...formMethods}>
            <Stack overflow={'auto'} pr={2}>
              {
                fileType === FileType.CSV &&
                <Controller
                  name={'csvSeparator'}
                  render={({field, fieldState}) => {
                    return <AutocompleteZts
                      id={field.name}
                      label={t('tabs.2.labels.csvSeparator')}
                      options={separatorOptions}
                      selectedValue={field.value}
                      setValue={(name, value) => {
                        field.onChange(value);
                        if (value) {
                          setCurrentConfiguration(prevState => {
                            asyncGetAndSetRows({
                              decimalSeparator: prevState.decimalSeparator ? prevState.decimalSeparator : undefined,
                              rowsToSkip: prevState.rowsToSkip ? prevState.rowsToSkip : undefined,
                              dateFormat: prevState.dateFormat ? prevState.dateFormat : undefined,
                              csvSeparator: value.code
                            }).then();
                            setConfig({...prevState, csvSeparator: value.code});
                            return {...prevState, csvSeparator: value.code};
                          });
                        } else {
                          setCurrentConfiguration(prevState => {
                            asyncGetAndSetRows({
                              decimalSeparator: prevState.decimalSeparator ? prevState.decimalSeparator : undefined,
                              rowsToSkip: prevState.rowsToSkip ? prevState.rowsToSkip : undefined,
                              dateFormat: prevState.dateFormat ? prevState.dateFormat : undefined,
                              csvSeparator: undefined
                            }).then();
                            setConfig({...prevState, csvSeparator: null});
                            return {...prevState, csvSeparator: null};
                          });
                        }
                      }}
                      codeAndDesc={false}
                      errorMsg={fieldState.error?.message}
                    />
                  }}
                />
              }
              <Controller
                render={({field, fieldState}) =>
                  <TextFieldZts
                    size={'small'}
                    {...field}
                    errorMsg={fieldState.error?.message}
                    label={t('tabs.2.labels.rowsToSkip')}
                    onBlur={() => {
                      const intValue = parseInt(field.value);
                      if (isNaN(intValue)) {
                        setCurrentConfiguration(prevState => {
                          asyncGetAndSetRows({
                            decimalSeparator: prevState.decimalSeparator ? prevState.decimalSeparator : undefined,
                            rowsToSkip: formDefaultValues.rowsToSkip,
                            dateFormat: prevState.dateFormat ? prevState.dateFormat : undefined,
                            csvSeparator: prevState.csvSeparator ? prevState.csvSeparator : undefined
                          }).then();
                          setConfig({...prevState, rowsToSkip: formDefaultValues.rowsToSkip});
                          return {...prevState, rowsToSkip: formDefaultValues.rowsToSkip};
                        });
                      } else {
                        setCurrentConfiguration(prevState => {
                          asyncGetAndSetRows({
                            decimalSeparator: prevState.decimalSeparator ? prevState.decimalSeparator : undefined,
                            rowsToSkip: intValue,
                            dateFormat: prevState.dateFormat ? prevState.dateFormat : undefined,
                            csvSeparator: prevState.csvSeparator ? prevState.csvSeparator : undefined
                          }).then();
                          setConfig({...prevState, rowsToSkip: intValue});
                          return {...prevState, rowsToSkip: intValue};
                        });
                      }
                    }}
                  />
                }
                name="rowsToSkip"
              />
              <Controller
                name={'dateFormat'}
                render={({field, fieldState}) => {
                  return <AutocompleteZts
                    id={field.name}
                    label={t('tabs.2.labels.dateFormat')}
                    options={dateFormatOptions}
                    selectedValue={field.value}
                    matchEqualByCode={false}
                    userValueEnabled={true}
                    onBlurHandler={(format) => {
                      const isOpt = dateFormatOptions.find(f => f.code === format);
                      if (format.length > 0 && isOpt === undefined) {
                        formMethods.setValue(field.name, format);
                        setCurrentConfiguration(prevState => {
                          asyncGetAndSetRows({
                            decimalSeparator: prevState.decimalSeparator ? prevState.decimalSeparator : undefined,
                            rowsToSkip: prevState.rowsToSkip ? prevState.rowsToSkip : undefined,
                            dateFormat: format,
                            csvSeparator: prevState.csvSeparator ? prevState.csvSeparator : undefined
                          }).then();
                          setConfig({...prevState, dateFormat: format});
                          return {...prevState, dateFormat: format};
                        });
                      }
                    }}
                    setValue={(name, value) => {
                      if (value === null) {
                        formMethods.setValue(field.name, null);
                        setCurrentConfiguration(prevState => {
                          asyncGetAndSetRows({
                            decimalSeparator: prevState.decimalSeparator ? prevState.decimalSeparator : undefined,
                            rowsToSkip: prevState.rowsToSkip ? prevState.rowsToSkip : undefined,
                            dateFormat: undefined,
                            csvSeparator: prevState.csvSeparator ? prevState.csvSeparator : undefined
                          }).then();
                          setConfig({...prevState, dateFormat: null});
                          return {...prevState, dateFormat: null};
                        });
                      }
                    }}
                    codeAndDesc={false}
                    errorMsg={fieldState.error?.message}
                  />
                }}
              />
              <Controller
                name={'decimalSeparator'}
                render={({field, fieldState}) => {
                  return <AutocompleteZts
                    id={field.name}
                    label={t('tabs.2.labels.decimalSeparator')}
                    options={decimalSeparatorOptions}
                    selectedValue={field.value}
                    setValue={(name, value) => {
                      field.onChange(value);
                      if (value) {
                        setCurrentConfiguration(prevState => {
                          asyncGetAndSetRows({
                            decimalSeparator: value.code,
                            rowsToSkip: prevState.rowsToSkip ? prevState.rowsToSkip : undefined,
                            dateFormat: prevState.dateFormat ? prevState.dateFormat : undefined,
                            csvSeparator: prevState.csvSeparator ? prevState.csvSeparator : undefined
                          }).then();
                          setConfig({...prevState, decimalSeparator: value.code});
                          return {...prevState, decimalSeparator: value.code};
                        });
                      } else {
                        setCurrentConfiguration(prevState => {
                          asyncGetAndSetRows({
                            decimalSeparator: undefined,
                            rowsToSkip: prevState.rowsToSkip ? prevState.rowsToSkip : undefined,
                            dateFormat: prevState.dateFormat ? prevState.dateFormat : undefined,
                            csvSeparator: prevState.csvSeparator ? prevState.csvSeparator : undefined
                          }).then();
                          setConfig({...prevState, decimalSeparator: null});
                          return {...prevState, decimalSeparator: null};
                        });
                      }
                    }}
                    codeAndDesc={false}
                    errorMsg={fieldState.error?.message}
                  />
                }}
              />
            </Stack>
          </FormProvider>

        </Box>
        <Box display='flex' flexDirection={'column'} width={'50%'} overflow={'auto'} p={2}>

          <Typography fontWeight={500} color={'#3315d6'}>Passo 1</Typography>
          <Typography>{t('tabs.2.labels.3')}</Typography>
          <Typography>{t('tabs.2.labels.4')}</Typography>
          <Typography>{t('tabs.2.labels.5')}</Typography>

        </Box>
      </Box>
    </Box>
  );
}
