// React
import {useNavigate} from "react-router-dom";

// MUI
import {CircularProgress, Paper, Typography} from "@mui/material";

// Zucchetti
import {PremiumIcon} from "../../base/premium-icon";
import {TodoItemNavigation} from "./model";
import {useExpNoteList} from "../../exp_note/hooks/useExpNoteList";
import {ALLEXPNOTE_URL, EXPNOTE_URL} from "../../model";
import {useLicense} from "../../../hooks/useLicense";


type ComponentProps = {
  label: string,
  count: number,
  className?: string,
  pro?: boolean,
  navigation?: TodoItemNavigation,
  loading?: boolean
}

export const TodoItem = ({label, count, className, pro, navigation, loading}: ComponentProps) => {

  const {isProfessional} = useLicense();
  const free = !isProfessional();

  const disabled = pro && free;
  const clickable = !disabled && count > 0 && navigation;

  const navigate = useNavigate();

  const {
    updateAdminListType,
    updateTravellerListType
  } = useExpNoteList();

  const handleClick = () => {
    if (!clickable) {
      return;
    }

    if (navigation.url === ALLEXPNOTE_URL && navigation?.data?.state) {
      updateAdminListType(navigation?.data.state);
    } else if (navigation.url === EXPNOTE_URL && navigation?.data?.state) {
      updateTravellerListType(navigation?.data.state);
    }

    navigate(navigation?.url);
  }

  const showLoader = loading && !disabled;

  return (
    <Paper
      elevation={0}
      className={`notification ${className ? className : ''}`}
      onClick={handleClick}
      sx={{cursor: clickable ? "pointer" : "default"}}
    >
      <Typography variant="body2" lineHeight={1.2}>{label}</Typography>

      {showLoader ? (
        <CircularProgress size={25}/>
      ) : (
        <>
          <Typography variant="h3">{disabled ? 0 : count}</Typography>
          {count > 0 && !disabled && <div className="circle"></div>}
          {disabled && <PremiumIcon/>}
        </>
      )}
    </Paper>
  );
}
