import {isUserMasterAdmin} from "../Service";
import {Typography} from "@mui/material";
import React from "react";
import {useAlert} from "../../../hooks/useAlert";
import {useTranslation} from "react-i18next";

export const useCheckDisableUser = () => {

  const {t} = useTranslation("user");

  const {
    alert,
    closeAlert
  } = useAlert();

  const canDisableUser = async (userId: number, key = "list.card.disableError"): Promise<boolean> => {
    const isMasterAdmin = await isUserMasterAdmin(userId);
    if (isMasterAdmin) {
      alert({
        onClick: () => {
          closeAlert()
        },
        body: (
          <>
            <Typography variant="body1" display="inline" mr={0.5}>
              {t(key)}
            </Typography>
          </>
        )
      });
      return false;
    }
    return true;
  }

  return {
    canDisableUser
  }

}
