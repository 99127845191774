import React, {useEffect, useState} from "react";
import {useSnackbar} from 'notistack';
import {useCurrentJobs} from "../../hooks/useCurrentJobs";
import {CompletedJobNotify, JobType, RunningJob} from "../../reducers/CurrentJobs";
import {
  downloadJobResult,
  getJobsStateByIdList,
  launchExpenseReport, launchExpNoteExport,
  launchExpNotePrint, launchExpNoteReport, launchReconciliationReport
} from "./Service";
import {useLoggedUser} from "../../hooks/useLoggedUser";
import {useErrorMessage} from "../../util/ErrorUtil";
import {BatchJobState, BatchJobInfos} from "./model";
import {Button} from "@mui/material";
import {ReconciliationReportRequest, ReportRequest} from "../report/model";
import {useTranslation} from "react-i18next";

export function JobsNotifier() {
  const {userLocale} = useLoggedUser();
  const {convertError} = useErrorMessage();
  const {enqueueSnackbar} = useSnackbar();
  const {t: tExpNote} = useTranslation('job', {keyPrefix: 'exp-note'});
  const {t: tExpenseReport} = useTranslation('job', {keyPrefix: 'expense-report'});
  const {t: tExpNotePay} = useTranslation('job', {keyPrefix: 'exp-note.pay'});
  const {t: tExpNoteAccounting} = useTranslation('job', {keyPrefix: 'exp-note.accounting'});
  const {t: tExpNoteDigitalStorage} = useTranslation('job', {keyPrefix: 'exp-note.digitalStorage'});
  const {t: tCreCardMovReport} = useTranslation('job', {keyPrefix: 'creCardMovReport'});
  const {t: tCreCardMovLink} = useTranslation('job', {keyPrefix: 'creCardMovLink'});
  const {t: tExpNoteExport} = useTranslation('job', {keyPrefix: 'expNoteExport'});
  const {t: tComm} = useTranslation('common');
  const {
    toRunJobs,
    runningJobs,
    deleteToRunJob,
    insertRunningJob,
    deleteRunningJob,
    sendNotifyCompletedJob
  } = useCurrentJobs();

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [currentBlob, setCurrentBlob] = useState<Blob>();

  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);

  const printExpNote = async (expNoteId: number, locale: string, zipWithAttach?: boolean): Promise<void> => {
    try {
      const responseId = await launchExpNotePrint({expNoteId: expNoteId, locale: locale, zipWithAttach});
      insertRunningJob({id: responseId, timeStamp: new Date(Date.now())});
      enqueueSnackbar(tExpNote('execute'), {
        autoHideDuration: 2000,
        variant: "info",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      })
    } catch (error: any) {
      convertError(error).then(msg => setErrorMsg(msg))
    }
  }

  const generateExpNoteExport = async (expNoteId: number, locale: string): Promise<void> => {
    try {
      const responseId = await launchExpNoteExport({expNoteId: expNoteId, locale: locale});
      insertRunningJob({id: responseId, timeStamp: new Date(Date.now())});
      enqueueSnackbar(tExpNoteExport('execute'), {
        autoHideDuration: 2000,
        variant: "info",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      })
    } catch (error: any) {
      convertError(error).then(msg => setErrorMsg(msg))
    }
  }

  const generateExpenseReport = async (request: ReportRequest): Promise<void> => {
    try {
      const responseId = await launchExpenseReport(request);
      insertRunningJob({id: responseId, timeStamp: new Date(Date.now())});
      enqueueSnackbar(tExpenseReport('execute'), {
        autoHideDuration: 2000,
        variant: "info",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
    } catch (error: any) {
      convertError(error).then(msg => setErrorMsg(msg))
    }
  }

  const generateExpNoteReport = async (request: ReportRequest): Promise<void> => {
    try {
      const responseId = await launchExpNoteReport(request);
      insertRunningJob({id: responseId, timeStamp: new Date(Date.now())});
      enqueueSnackbar(tExpenseReport('execute'), {
        autoHideDuration: 2000,
        variant: "info",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
    } catch (error: any) {
      convertError(error).then(msg => setErrorMsg(msg))
    }
  }

  const generateReconciliationReport = async (request: ReconciliationReportRequest): Promise<void> => {
    try {
      const responseId = await launchReconciliationReport(request);
      insertRunningJob({id: responseId, timeStamp: new Date(Date.now())});
      enqueueSnackbar(tCreCardMovReport('execute'), {
        autoHideDuration: 2000,
        variant: "info",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        }
      });
    } catch (error: any) {
      convertError(error).then(msg => setErrorMsg(msg))
    }
  }

  useEffect(() => {
    if (toRunJobs.length > 0) {
      const job = toRunJobs[0];
      deleteToRunJob(job);
      let params;
      switch (job.type) {
        case JobType.PRINT:
          printExpNote(job.id, userLocale).then();
          return;
        case JobType.PRINT_WITH_ATTACH:
          printExpNote(job.id, userLocale, true).then();
          return;
        case JobType.EXPENSE_REPORT:
          generateExpenseReport({
            reportId: job.id,
            startDate: job.startDate ? job.startDate : new Date(Date.now()),
            endDate: job.endDate ? job.endDate : new Date(Date.now()),
            parameters: job.parameters ? job.parameters : {},
            locale: userLocale
          }).then();
          return;
        case JobType.EXP_NOTE_REPORT:
          generateExpNoteReport({
            reportId: job.id,
            startDate: job.startDate ? job.startDate : new Date(Date.now()),
            endDate: job.endDate ? job.endDate : new Date(Date.now()),
            locale: userLocale
          }).then();
          return;
        case JobType.RECONCILIATION_REPORT:
          params = job.parameters;
          if (params) {
            generateReconciliationReport({
              reportId: job.id,
              locale: userLocale,
              year: params[0],
              month: params[1]
            }).then();
          }
          return;
        case JobType.EXP_NOTE_EXPORT:
          generateExpNoteExport(job.id, userLocale).then();
          return;
      }
    }
  }, [toRunJobs]);

  useEffect(() => {
    if (runningJobs.length === 0) {
      if (pollingInterval !== null) {
        clearInterval(pollingInterval);
        setPollingInterval(null);
      }
    } else {
      if (pollingInterval !== null) {
        clearInterval(pollingInterval);
      }
      setPollingInterval(setInterval(getJobsUpdate, 1000));
    }
  }, [runningJobs]);

  const getJobsUpdate = () => {
    const ids = runningJobs.map(job => {
      return job.id
    });
    getJobsStateByIdList(ids)
      .then(response => {
        runningJobs.forEach(running => {
          const isCompleted = response.find(res => res.id === parseInt(running.id));
          if (isCompleted) {
            switch (isCompleted.batchState) {
              case BatchJobState.COMPLETED_SUCCESFULLY:
                notifyJobCompletion(running, isCompleted);
                deleteRunningJob(running);
                downloadJobResult(running.id)
                  .then(resp => {
                    setCurrentBlob(resp);
                  })
                  .catch(error => {
                    convertError(error)
                      .then(msg => setErrorMsg(msg))
                  })
                let successMessage;
                switch (isCompleted.batchName) {
                  case 'ExpNotePrint':
                    successMessage = tExpNote('completed.success');
                    break;
                  case 'ExpenseReport':
                    successMessage = tExpenseReport('completed.success');
                    break;
                  case 'ExpNotePay':
                    successMessage = tExpNotePay('completed.success');
                    break;
                  case 'ExpNoteAccounting':
                    successMessage = tExpNoteAccounting('completed.success');
                    break;
                  case 'ExpNoteDigStor':
                    successMessage = tExpNoteDigitalStorage('completed.success');
                    break;
                  case 'ReconcilReport':
                    successMessage = tCreCardMovReport('completed.success');
                    break;
                  case 'LinkCreCardMovJob':
                    successMessage = tCreCardMovLink('completed.success');
                    break;
                  case 'ExpNoteExport':
                    successMessage = tExpNoteExport('completed.success');
                    break;
                  default:
                    successMessage = tExpNote('completed.success');
                    break;
                }
                enqueueSnackbar(successMessage, {
                  variant: "success",
                  autoHideDuration: 3500,
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "top"
                  },
                  action:
                    isCompleted.batchName === ('ExpNoteDigStor' || 'LinkCreCardMovJob')
                      ? null
                      : <Button
                        sx={{color: 'white'}}
                        onClick={() => {
                          downloadJobResult(running.id)
                            .then(response => {
                              const url = URL.createObjectURL(response);
                              const link = document.createElement('a');
                              link.href = url;
                              link.setAttribute('download', isCompleted.fileDownloadName);
                              document.body.appendChild(link);
                              link.click();
                              URL.revokeObjectURL(url);
                            })
                        }}
                      >
                        {tComm('download')}
                      </Button>
                });
                return;
              case BatchJobState.COMPLETED_WITH_WARNINGS:
                notifyJobCompletion(running, isCompleted);
                deleteRunningJob(running);
                let warningMessage;
                switch (isCompleted.batchName) {
                  case 'ExpNotePrint':
                    warningMessage = tExpNote('completed.warning');
                    break;
                  case 'ExpenseReport':
                    warningMessage = tExpenseReport('completed.warning');
                    break;
                  case 'ExpNotePay':
                    warningMessage = tExpNotePay('completed.warning');
                    break;
                  case 'ExpNoteAccounting':
                    warningMessage = tExpNoteAccounting('completed.warning');
                    break;
                  case 'ExpNoteDigStor':
                    warningMessage = tExpNoteDigitalStorage('completed.warning');
                    break;
                  case 'LinkCreCardMovJob':
                    warningMessage = tCreCardMovLink('completed.warning');
                    break;
                  case 'ExpNoteExport':
                    successMessage = tExpNoteExport('completed.warning');
                    break;
                  default:
                    warningMessage = tExpNote('completed.warning');
                    break;
                }
                enqueueSnackbar(warningMessage, {
                  variant: "warning",
                  autoHideDuration: 2000,
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "top"
                  }
                });
                return;
              case BatchJobState.COMPLETED_WITH_ERRORS:
                notifyJobCompletion(running, isCompleted);
                deleteRunningJob(running);
                let errorMessage;
                switch (isCompleted.batchName) {
                  case 'ExpNotePrint':
                    errorMessage = tExpNote('completed.error');
                    break;
                  case 'ExpenseReport':
                    errorMessage = tExpenseReport('completed.error');
                    break;
                  case 'ExpNotePay':
                    errorMessage = tExpNotePay('completed.error');
                    break;
                  case 'ExpNoteAccounting':
                    errorMessage = tExpNoteAccounting('completed.error');
                    break;
                  case 'ExpNoteDigStor':
                    errorMessage = tExpNoteDigitalStorage('completed.error');
                    break;
                  case 'LinkCreCardMovJob':
                    errorMessage = tCreCardMovLink('completed.error');
                    break;
                  case 'ExpNoteExport':
                    successMessage = tExpNoteExport('completed.error');
                    break;
                  default:
                    errorMessage = tExpNote('completed.error');
                    break;
                }
                enqueueSnackbar(errorMessage, {
                  variant: "error",
                  autoHideDuration: 2000,
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "top"
                  }
                });
                return;
            }
          }
        });

      })
      .catch(error => {
        convertError(error)
          .then(msg => setErrorMsg(msg))
      })
  }

  const notifyJobCompletion = (running: RunningJob, batchInfo: BatchJobInfos) => {
    if (running.notify) {
      const notifyMessage: CompletedJobNotify = {
        job: running,
        state: batchInfo.batchState
      }
      sendNotifyCompletedJob(notifyMessage);
    }
  }

  return (
    <>
      {
        errorMsg && enqueueSnackbar(`${errorMsg}`, {
          variant: "error",
          autoHideDuration: 2000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top"
          }
        })
      }
    </>
  );

}
