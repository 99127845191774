import {Autocomplete, Paper, TextField} from "@mui/material";
import React, {createRef, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {getGooglePlaceSuggestions} from "../../../exp_note/Service";
import {createAutocompletePlaceOption, GOOGLE_PLACE_SUGGESTION_MIN_CHAR} from "../../../exp_note/model";
import {AutocompleteGenericOption} from "../model";
import googleMapsLogo from "../../../../assets/images/powered_by_google_on_white.png";

type ComponentProps = {
  id: string,
  disabled?: boolean,
  placeholder?: string,
  errorMsg?: string,
  value: AutocompleteGenericOption | null,
  setValue: Function,
  setFocus?: boolean
}

export const GooglePlaceZTS = (props: ComponentProps) => {

  const inputRef = createRef<HTMLInputElement>();

  const {
    id,
    disabled,
    placeholder,
    errorMsg,
    value,
    setValue,
    setFocus
  } = props;

  const [inputValue, setInputValue] = useState(value ? value.desc : '');
  const [options, setOptions] = useState<AutocompleteGenericOption[]>([]);
  const [placeTO, setPlaceTO] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (setFocus && inputRef) {
      inputRef.current?.focus();
    }
  }, [setFocus]);

  const loadSuggestions = async () => {
    const suggestions = await getGooglePlaceSuggestions(inputValue);
    try {
      if (suggestions) {
        setOptions(suggestions.map(s => createAutocompletePlaceOption(s)));
      }
    } catch (error) {

    }
    setLoading(false);
  }

  useEffect(() => {
    if (placeTO) {
      clearTimeout(placeTO);
    }
    if (!disabled && inputValue && inputValue.length >= GOOGLE_PLACE_SUGGESTION_MIN_CHAR) {
      setLoading(true);
      setOptions([]);
      setPlaceTO(setTimeout(() => {
        loadSuggestions();
      }, 300));
    } else {
      setOptions([]);
    }
  }, [inputValue]);

  return (
    <Autocomplete
      id={id}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.selectedLabel
      }
      filterOptions={(x) => x}
      forcePopupIcon={false}
      options={options}
      disabled={disabled}
      autoComplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option && value && option.code === value.code}
      value={value}
      loading={loading}
      onChange={(event: any, newValue: AutocompleteGenericOption | null) => {
        setValue(newValue);
        setInputValue('');
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        return <TextField
          {...params}
          name={params.id}
          placeholder={placeholder}
          variant="filled"
          size={"small"}
          hiddenLabel
          error={Boolean(errorMsg)}
          helperText={errorMsg || ' '}
          inputRef={inputRef}
        />
      }}
      renderOption={(props, option) => {
        return (
          <Box component="li" {...props}>
            {option.selectedLabel}
          </Box>
        );
      }}
      PaperComponent={(props) => {
        const {children, ...otherProps} = props;
        return (
          <Paper {...otherProps}>
            {children}
            {options && options.length > 0 &&
              <Box
                display="flex"
                justifyContent="right"
                p={0.5}
              >
                <img src={googleMapsLogo} alt="google logo"/>
              </Box>
            }
          </Paper>
        );
      }}
    />
  );

}
