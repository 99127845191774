import {AccountBalanceWallet, Business, CreditCard} from "@mui/icons-material";
import {GreyColor} from "../admin/list/model";
import React, {ReactNode} from "react";
import {PayMode} from "../expense/model";

export const PayModeIcon = ({payMode}: { payMode: string }) => {

  const getIcon = (): ReactNode => {
    switch (payMode) {
      case PayMode.TRAVELLER:
        return <AccountBalanceWallet fontSize={"small"} sx={{color: GreyColor}}/>
      case PayMode.CARD:
        return <CreditCard fontSize={"small"} sx={{color: GreyColor}}/>
      case PayMode.COMPANY:
        return <Business fontSize={"small"} sx={{color: GreyColor}}/>
      default:
        return <AccountBalanceWallet fontSize={"small"} sx={{opacity: 0}}/>
    }
  }

  return (
    <>
      {getIcon()}
    </>
  )
}
