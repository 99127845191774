import axios, { AxiosRequestConfig } from 'axios';
import { HelpCurrentPage } from '../../reducers/Help';
import { getApiUrl, getLoginPortalUrl } from '../../config/environment';
import { getLoginPortalJwt } from '../../config/token';

import {Logger} from 'react-logger-lib';
import Slf4jsLog from '../../util/Logger';

const LOG: Slf4jsLog = Logger.of('ZTS.help.Service');

// get del singolo pageContent
export const getPageContent = async (page: HelpCurrentPage | string, locale: string) => {
  const apiUrl = getApiUrl();
  const response = await axios.get(`${apiUrl}/help/content?pageName=${page}&locale=${locale}`);
  return response.data;
}

export const getProcedures = async () => {
  const apiUrl = getApiUrl();
  const response = await axios.get(`${apiUrl}/help/procedures`);
  return response.data;
}

export const getProcedureStepsById = async (procedureId: number, stepNumber: number, locale: string) => {
  const apiUrl = getApiUrl();
  const response = await axios.get(`${apiUrl}/help/procedureStep?procedureId=${procedureId}&step=${stepNumber}&locale=${locale}`);
  return response.data;
}

export const search = async (locale: string, queryString: string) => {
  const apiUrl = getApiUrl();
  const response = await axios.get(`${apiUrl}/help/search?locale=${locale}&queryString=${queryString}`);
  return response.data;
}

export type QuestionRequest = {
  application: string;
  companyName?: string;
  user?: string;
  tenantId?: number;
  role?: string;
  requestType?: string;
  operationArea?: string;
  message?: string;
}

export const askForQuestion = async (body: QuestionRequest): Promise<boolean> => {
  LOG.trace('askForQuestion')
  const loginPortalUrl = getLoginPortalUrl();

  const url = `${loginPortalUrl}support/questions`;
  const axiosConfig: AxiosRequestConfig = {
    headers: {
      'Authorization': 'NONE'
    }
  }
  try {
    LOG.trace('askForQuestion | calling login portal api ...');
    await axios.post(url, body, axiosConfig);
    return true;
  }catch(e ){
    LOG.trace('askForQuestion | login portal api error', e);
    return false;
  }
}
