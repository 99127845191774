import {useDispatch} from "react-redux";
import {AlertDialogProps, setAlertDialogClose, setAlertDialogOpen} from "../reducers/AlertDialog";

export const useAlert = () => {

  const dispatch = useDispatch();

  const alert = (props: AlertDialogProps) => {
    dispatch(setAlertDialogOpen(props));
  }

  const closeAlert = () => {
    dispatch(setAlertDialogClose());
  }

  return {
    alert,
    closeAlert
  }
}
