import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {Warning} from "@mui/icons-material";
import {Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ExpenseCategory} from "../../../../../expenses/model";
import {getExpenseCategoryLabel} from "../../../../../expenses/utils";
import {useTranslation} from "react-i18next";
import {ChartData, getCategories, getCategoryOrder} from "./model";
import {useExpNoteDetail} from "../../../hooks/useExpNoteDetail";
import {ExpNoteExpenseFull} from "../../../../expense/model";
import {arrayGroupByProperty} from "../../../model";
import moment, {unitOfTime} from "moment";
import React, {useState} from "react";
import {useLoggedUser} from "../../../../../../hooks/useLoggedUser";
import {formatAmount} from "../../../../../../util/NumberUtil";
import {DateFormat} from "../../../../../model";

export const ExpNoteChart = () => {

  const {
    expNote,
    expenses
  } = useExpNoteDetail();

  const {userLocale: locale, companyDecimalNum: decimalNum, companyCurrencyCode, companyCurrencyDesc} = useLoggedUser();

  const {t} = useTranslation(['expense', 'exp-note']);

  const dayLabel = t("exp-note:detail.chart.day");

  const [barTooltipActive, setBarTooltipActive] = useState<ExpenseCategory | null>(null);
  const [totalTooltipActive, setTotalTooltipActive] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number, y: number, width: number }>({x: 0, y: 0, width: 0});

  const categories = getCategories(t);

  let data: ChartData[] = [];
  // const data = [
  //   {
  //     name: '01',
  //     total: 0
  //   },
  //   {
  //     name: '02',
  //     total: 30,
  //     daily: {
  //       VT: 27,
  //       VR: 3
  //     }
  //   },
  //   {
  //     name: '03',
  //     total: 50,
  //     daily: {
  //       VT: 10,
  //       MT: 10
  //     },
  //     exceed: 10
  //   },
  //   {
  //     name: '05',
  //     total: 62,
  //     daily: {
  //       VT: 12
  //     },
  //     exceed: 10
  //   },
  //   {
  //     name: '06',
  //     total: 314,
  //     daily: {
  //       MT: 60,
  //       VT: 22,
  //       AL: 170
  //     },
  //     exceed: 10
  //   },
  //   {
  //     name: '10',
  //     total: 381,
  //     daily: {
  //       VT: 22,
  //       AL: 45
  //     }
  //   }
  // ];

  const firstLastCategoryByDay = {};
  // firstLastCategoryByDay['02'] = ['VT', 'VR'];
  // firstLastCategoryByDay['03'] = ['VT', 'MT'];
  // firstLastCategoryByDay['05'] = ['VT', 'VT'];
  // firstLastCategoryByDay['06'] = ['VT', 'MT'];
  // firstLastCategoryByDay['10'] = ['VT', 'AL'];

  const spaceForLittleTriangle = 12;

  const unit: unitOfTime.Diff = 'days';
  if (expNote) {
    let start = moment(expNote.startDate);
    let end = moment(expNote.endDate);

    const minDaysNum = 10;
    const diff = end.diff(start, unit) +1;
    if (diff < minDaysNum) {
      const toAdd = minDaysNum - diff;
      const leftDays = Math.floor(toAdd / 2);
      start = start.add(-leftDays, unit);
      end = end.add(leftDays + (toAdd % 2 ? 1 : 0), unit);
    }

    do {
      data.push({name: start.format("DD/MM"), total: 0, exceed: 0});
      start = start.add(1, unit);
    } while (start.isSameOrBefore(end));
  }

  if (expenses) {

    const compPeriodExpenses: {expId: number, data: ExpNoteExpenseFull[]}[] = [];
    expenses.forEach(exp => {
      if (exp.compPeriod) {
        let residualAmount = exp.compAmount;
        const compStart = moment(exp.compStartDate, DateFormat);
        const compEnd = moment(exp.compEndDate, DateFormat);
        let diff = compEnd.diff(compStart, unit) +1;
        if (!exp.compPeriodStart) {
          diff -= 1;
        }
        if (!exp.compPeriodEnd) {
          diff -= 1;
        }
        const dayAmount = +(exp.compAmount / diff).toFixed(decimalNum);

        const data: ExpNoteExpenseFull[] = [];
        for (let i = 0; i < diff; i++) {
          const newDate = compStart.clone().add(i, unit).toDate();

          data.push({
            ...exp,
            compDate: newDate,
            compAmount: i === diff-1 ? residualAmount : dayAmount
          });
          residualAmount -= dayAmount;
        }

        compPeriodExpenses.push({expId: exp.id, data});
      }
    });

    let mappedExpenses = [...expenses];
    compPeriodExpenses.forEach(elm => {
      mappedExpenses.splice(mappedExpenses.findIndex(e => e.id === elm.expId), 1);
      mappedExpenses.push(...elm.data);
    });

    mappedExpenses = mappedExpenses.map(exp => ({...exp, compDateFormatted: moment(exp.compDate, DateFormat).toISOString(false)}));

    //console.log(mappedExpenses)

    const sortedExpenses = mappedExpenses.sort((a, b) => {
      const mA = moment(a.compDate);
      const mB = moment(b.compDate);
      if (mA.isBefore(mB)) {
        return -1;
      }
      if (mA.isAfter(mB)) {
        return 1;
      }
      return a.id - b.id;
    });

    const expByDate: Map<Date, ExpNoteExpenseFull[]> = arrayGroupByProperty(sortedExpenses, 'compDateFormatted');
    if (expByDate && expByDate.size > 0) {
      let progressiveTotal = 0;
      let dayCategories: ExpenseCategory[];
      expByDate.forEach((exps, date) => {
        const day = moment(date).format("DD/MM");
        const dayData = data.find(d => d.name === day);
        if (dayData) {
          dayCategories = [];
          dayData.daily = {};
          progressiveTotal += exps.map(e => e.compAmount).reduce((sum, value) => sum + value, 0);
          dayData.total = progressiveTotal;
          dayData.exceed = exps.map(e => e.maxExcExp).reduce((sum, value) => sum + value, 0);
          const expByCategory: Map<ExpenseCategory, ExpNoteExpenseFull[]> = arrayGroupByProperty(exps, 'expenseCategory');
          expByCategory.forEach((categoryExps, category) => {
            dayCategories.push(category);
            if (dayData.daily) {
              dayData.daily[category] = categoryExps.map(e => e.compAmount).reduce((sum, value) => sum + value, 0);
            }
          });

          dayCategories.sort((a, b) => getCategoryOrder(a) - getCategoryOrder(b));
          firstLastCategoryByDay[day] = [dayCategories[0], dayCategories[dayCategories.length - 1]];
        }
      });
    }
  }

  const getPrevTotal = (index: number): number => {
    if (index > 0) {
      let total = data[index - 1].total;
      return total ? total : getPrevTotal(index - 1);
    }
    return 0;
  }

  data = data.map((d, index) => {
    if (d.total === 0) {
      d.total = getPrevTotal(index);
    }
    return d;
  });

  const isExceed = !!data.find(d => d.exceed);

  const isExceeding = (day: string): boolean => {
    const dayData = data.find(d => d.name === day);
    return !!dayData && !!dayData.exceed && dayData.exceed > 0;
  }

  const renderXAxisTick = ({x, y, payload}) => {
    if (isExceeding(payload.value)) {
      return (
        <>
          <text x={x} y={y} dy={30} textAnchor="middle" fill="#ff0000" width={6} fontWeight={500}>
            {payload.value.substring(0, 2)}
          </text>
          <svg x={x - 10} y={y - 6} width={20} height={20}>
            <Warning sx={{color: "#ff0000"}}/>
          </svg>
        </>
      );
    } else {
      return (
        <>
          <text x={x} y={y} dy={30} textAnchor="middle" fill="#666">
            {payload.value.substring(0, 2)}
          </text>
        </>
      );
    }
  }

  const renderShape = (props, category) => {
    //console.log(props)
    //console.log(props.daily)

    let isFirst = false;
    let isLast = false;

    const categories = firstLastCategoryByDay[props.name];
    if (categories) {
      const radius = [0, 0, 0, 0];
      if (categories[0] === category) {
        isFirst = true;
        radius[2] = radius[3] = 10;
      }
      if (categories[1] === category) {
        isLast = true;
        radius[0] = radius[1] = 10;
      }
      props.radius = radius;
    }

    const outProps = {...props};

    const maxBorder = 2;

    if (props.exceed) {
      if (isLast) {
        props.y += maxBorder;
        props.height -= maxBorder;
      }

      if (isFirst) {
        props.height -= maxBorder;
      }

      props.x += maxBorder;
      props.width -= maxBorder * 2;
    }

    return <>
      {props.exceed && <Rectangle {...outProps} fill="#ff0000"/>}
      <Rectangle {...props} />
    </>
  }

  const renderTooltip = (props) => {
    //console.log(props)
    const {payload} = props;

    //console.log(payload)

    const fontSize = 12;

    if (totalTooltipActive) {

      const total = payload.find(p => p.name === 'total');

      return total && total.value ? (
        <div className="custom-tooltip-total">
          <Typography variant="h6" mb={2}>{`${dayLabel} ${+total.payload.name.substring(0, 2)}`}</Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            overflow="hidden"
          >
            <Typography
              variant="h5"
              fontSize={`${fontSize}px`}
            >
              {t("exp-note:detail.chart.totalAggregate")}
            </Typography>
            <Typography
              variant="h5"
              fontSize={`${fontSize}px`}
              ml={1.5}
              flexShrink={0}
            >
              {formatAmount(total.value, locale, decimalNum, companyCurrencyCode)}
            </Typography>
          </Box>
        </div>
      ) : null;
    }

    if (barTooltipActive) {
      const total = payload
        .filter(p => p.name !== 'total')
        .map(p => p.value)
        .reduce((acc, v) => acc + v, 0);

      if (!total) {
        return null;
      }

      return (
        <div className="custom-tooltip-data">
          <Typography variant="h6" mb={2}>{`${dayLabel} ${+payload[0].payload.name.substring(0, 2)}`}</Typography>
          {payload
            .filter(p => p.name === `daily.${barTooltipActive}`)
            .map(p => (
              <Box
                key={p.name}
                display="flex"
                justifyContent="space-between"
                overflow="hidden"
              >
                <Typography
                  variant="h5"
                  fontSize={`${fontSize}px`}
                  maxHeight="20px"
                >
                  {getExpenseCategoryLabel(barTooltipActive, t)}
                </Typography>
                <Typography
                  variant="h5"
                  fontSize={`${fontSize}px`}
                  ml={1.5}
                  flexShrink={0}
                >
                  {formatAmount(p.value, locale, decimalNum, companyCurrencyCode)}
                </Typography>
              </Box>
            ))}


          {total && (
            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Typography
                variant="h5"
                fontSize={`${fontSize}px`}
              >
                {t("exp-note:detail.chart.total")}
              </Typography>
              <Typography
                variant="h5"
                fontSize={`${fontSize}px`}
              >
                {formatAmount(total, locale, decimalNum, companyCurrencyCode)}
              </Typography>
            </Box>
          )}
        </div>
      );
    }

    return null;
  };

  const getLegendEntryLabel = (entry: string): string => {
    entry = entry.replace('daily.', '');
    if (entry) {
      return getExpenseCategoryLabel(entry as ExpenseCategory, t);
    }
    return '';
  }

  const renderLegend = (props) => {
    //console.log(props);

    const {payload} = props;

    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" mr={2}>{t("exp-note:detail.chart.title")}</Typography>

        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          rowGap={0.5}
          columnGap={2.5}
          justifyContent="flex-end"
          flexWrap={{
            xs: "wrap",
            lg: "nowrap"
          }}
          maxWidth={{
            xs: "250px",
            lg: "100%"
          }}
        >
          {
            payload
              .filter(entry => entry.value !== 'total')
              .filter(entry => data.find(d => !!d.daily && !!d.daily[entry.value.replace('daily.', '')]))
              .map(entry => (
                <Stack
                  key={entry.value}
                  direction="row"
                  alignItems="center"
                  columnGap={1}
                >
                  <Box
                    width="15px"
                    height="15px"
                    borderRadius="3px"
                    flexShrink="0"
                    sx={{
                      backgroundColor: entry.color
                    }}
                  />
                  <Typography variant="h6">{getLegendEntryLabel(entry.value)}</Typography>
                </Stack>
              ))
          }

          <Stack
            direction="row"
            alignItems="center"
            columnGap={1}
          >
            <Stack
              direction="row"
              alignItems="center"
            >
              <Box
                width="7px"
                height="3px"
                sx={{
                  borderBottom: "3px solid rgba(20, 198, 213, 1)"
                }}
              />
              <Box
                width="10px"
                height="10px"
                padding="3px"
                borderRadius="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: "rgba(20, 198, 213, 1)"
                }}
              >
                <Box
                  width="4px"
                  height="4px"
                  borderRadius="50%"
                  sx={{
                    backgroundColor: "#ffffff"
                  }}
                />
              </Box>
              <Box
                width="7px"
                height="3px"
                sx={{
                  borderBottom: "3px solid rgba(20, 198, 213, 1)"
                }}
              />
            </Stack>
            <Typography variant="h6">{t("exp-note:detail.chart.totalAggregate")}</Typography>
          </Stack>

          {isExceed && (
            <Stack
              key="maximum"
              direction="row"
              alignItems="center"
              columnGap={1}
            >
              <Warning color="error" sx={{fontSize: "15px"}}/>
              <Typography variant="h6">Alert</Typography>
            </Stack>
          )}

        </Stack>
      </Stack>
    );
  }

  return (
    <ResponsiveContainer>
      <ComposedChart
        data={data}
        margin={{
          top: 85,
          right: 10,
          left: 0,
          bottom: 60,
        }}
      >
        <defs>
          <linearGradient
            id="colorTotal"
            gradientTransform="rotate(90)"
          >
            <stop
              offset="5%"
              stopColor="#32959A2C"
              stopOpacity={1}
            />
            <stop
              offset="95%"
              stopColor="#32959A2C"
              stopOpacity={0.15}
            />
          </linearGradient>
        </defs>
        <CartesianGrid
          strokeDasharray="0"
          vertical={false}
        />
        <XAxis
          axisLine={false}
          dataKey="name"
          label={{
            offset: 45,
            position: "bottom",
            value: dayLabel,
            stroke: "#C7C1CF",
            fontSize: "13px",
            fontWeight: 100,
            letterSpacing: 1.2
          }}
          tickMargin={5}
          tickSize={0}
          tick={renderXAxisTick}
        />
        <YAxis
          axisLine={false}
          label={{
            offset: 40,
            position: "top",
            value: companyCurrencyDesc,
            stroke: "#C7C1CF",
            fontSize: "13px",
            fontWeight: 100,
            letterSpacing: 1.2
          }}
          tickSize={0}
          tickMargin={18}
          yAxisId="left"
        />
        <YAxis
          axisLine={false}
          dataKey="total"
          orientation="right"
          tickSize={0}
          tickMargin={18}
          yAxisId="right"
        />
        <Tooltip
          content={renderTooltip}
          position={{x: position.x, y: position.y}}
          wrapperStyle={{
            transform: `translate(${position.x}px, ${position.y}px)`,
            top: `-${(totalTooltipActive ? 70 : 90) + spaceForLittleTriangle + (totalTooltipActive ? 3 : 0)}px`,
            left: `${(position.width - 220) / 2}px`
          }}
        />
        <Legend
          content={renderLegend}
          verticalAlign="top"
          wrapperStyle={{
            top: 0
          }}
        />

        {/*Gradiente del totale cumulato*/}
        <Area
          activeDot={false}
          dataKey="total"
          fillOpacity={1}
          fill="url(#colorTotal)"
          strokeWidth={0}
          type="monotone"
          yAxisId="right"
        />

        {/*Barre delle spese per categoria nei singoli giorni*/}
        {categories.map(category => (
          <Bar
            key={category.value}
            dataKey={`daily.${category.value}`}
            fill={category.color}
            maxBarSize={150}
            onMouseOver={(point) => {
              setPosition({x: point.x, y: point.y, width: point.width});
              setBarTooltipActive(category.value);
            }}
            onMouseLeave={() => setBarTooltipActive(null)}
            shape={(props) => renderShape(props, category.value)}
            stackId="expense"
            yAxisId="left"
          />
        ))}

        {/*Linea del totale cumulato*/}
        <Line
          activeDot={{
            onMouseEnter: (_, event) => {
              setTotalTooltipActive(true);
              //@ts-ignore
              setPosition({x: event.cx, y: event.cy, width: 0});
            },
            onMouseLeave: () => setTotalTooltipActive(false)
          }}
          dataKey="total"
          stroke="#14C6D5"
          strokeWidth={3}
          type="monotone"
          yAxisId="right"
        />

      </ComposedChart>
    </ResponsiveContainer>
  );
}
