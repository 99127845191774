import {List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {PremiumIcon} from "../../../base/premium-icon";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {MenuItem} from "../model";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  free: boolean,
  menuItems: MenuItem[],
  onSelectItem?: Function
}

export const ItemsList = ({free, menuItems, onSelectItem}: ComponentProps) => {
  const {t} = useTranslation('layout', {keyPrefix: 'menu.label'});
  const location = useLocation();
  const navigate = useNavigate();

  const navigateRoute = (route: string) => {
    navigate(route);
  }

  return (
    <List sx={{pl: 6.2, pb: 1, pr: 3, mt: -0.4}}>
      {menuItems.map((item) => (
        <ListItem
          key={item.label}
          className={`menu-item ${location.pathname.indexOf(item.route) !== -1 ? 'menu-item-active' : ''}`}
          button
          data-help={item.route.replace('/', '')}
          onClick={() => {
            navigateRoute(item.route);
            if (onSelectItem) {
              onSelectItem();
            }
          }}
          sx={{py: 0.6}}
        >
          {item.premium && free ? (
            <>
              <ListItemText primary={t(item.label)} sx={{flexGrow: 0}}/>
              <ListItemIcon sx={{ml: 1}}>
                <PremiumIcon/>
              </ListItemIcon>
            </>
          ) : (
            <ListItemText primary={t(item.label)}/>
          )}
        </ListItem>
      ))}
    </List>
  );
}
