import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis} from "recharts";
import React, {useEffect, useState} from "react";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {Skeleton, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {formatAmount} from "../../../util/NumberUtil";
import {useTranslation} from "react-i18next";
import {SelectZts} from "../../base/select";
import {
  DashboardExpenseTotalByMonth,
  DashboardExpenseTotalRange,
  getChartRangeLabel,
  getDashboardExpenseTotalByMonthRange,
  saveDashboardExpenseTotalByMonthRange
} from "../model";
import {getExpenseTotalsByMonth} from "../Service";
import SimpleJsLog from "../../../util/Logger";
import {Logger} from 'react-logger-lib';

const LOG: SimpleJsLog = Logger.of('ZTS.Dashboard.DashboardChartTotal');

export const DashboardChartTotal = () => {

  const {t: tCommon} = useTranslation("common");
  const {t} = useTranslation('dashboard', {keyPrefix: 'admin.chart'});

  const [position, setPosition] = useState<{ x: number, y: number, width: number, height: number }>({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DashboardExpenseTotalByMonth[]>([]);
  const [rangeType, setRangeType] = useState<DashboardExpenseTotalRange>(getDashboardExpenseTotalByMonthRange());

  const rangeOptions: DashboardExpenseTotalRange[] = [
    DashboardExpenseTotalRange.CURRENT_YEAR,
    DashboardExpenseTotalRange.LAST_TWELVE_MONTHS,
    DashboardExpenseTotalRange.LAST_SIX_MONTHS
  ];

  const {
    user,
    userLocale: locale,
    companyDecimalNum: decimalNum,
    companyCurrencyCode,
    companyCurrencyDesc
  } = useLoggedUser();

  const companyName = user && user.tenant ? user.tenant.description : "";

  useEffect(() => {
    setLoading(true);
    getExpenseTotalsByMonth(rangeType)
      .then(res => {
        setData(res);
      })
      .catch(err => LOG.trace(`Error retrieving expense total by month: ${err}`))
      .finally(() => setLoading(false));
  }, [rangeType]);

  // const data = [
  //   {
  //     month: "202204",
  //     total: 978
  //   },
  //   {
  //     month: "202205",
  //     total: 1023
  //   },
  //   {
  //     month: "202206",
  //     total: 1120
  //   },
  //   {
  //     month: "202207",
  //     total: 600
  //   },
  //   {
  //     month: "202208",
  //     total: 2400
  //   },
  //   {
  //     month: "202209",
  //     total: 1398
  //   }
  // ];

  const monthLabel = t("total.months");

  const handleChangeRange = (rangeType: string) => {
    const range: DashboardExpenseTotalRange = rangeType as DashboardExpenseTotalRange;
    setRangeType(range);
    saveDashboardExpenseTotalByMonthRange(range);
  }

  const renderXAxisTick = ({x, y, payload}) => {
    return (
      <>
        <text x={x} y={y} dy={5} textAnchor="middle" fill="#666" fontSize="12px">
          {payload.value.substring(4, 6)}
        </text>
      </>
    );
  }

  const renderYAxisTick = ({x, y, payload}) => {
    return (
      <>
        <text x={x} y={y} dy={5} dx={-10} textAnchor="middle" fill="#666" fontSize="12px">
          {payload.value}
        </text>
      </>
    );
  }

  const renderTooltip = (props: TooltipProps<any, any>) => {
    const {active, payload} = props;

    if (!active || !payload) {
      return null;
    }

    const fontSize = 12;

    const data = payload[0].payload;

    if (!data.total) {
      return null;
    }

    const label = `${tCommon(`month.${data.month.substring(4, 6)}`)} ${data.month.substring(0, 4)}`;

    const offset = `${80 - (position.width / 2) - (13 / 2)}px`;

    return (
      <Box
        className="custom-tooltip-dashboard-total"
        sx={{
          '&:after': {
            left: `${offset} !important`
          }
        }}
      >
        <Typography variant="h6" mb={2}>{label}</Typography>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            variant="h5"
            fontSize={`${fontSize}px`}
          >
            {t("total.tooltip.total")}
          </Typography>
          <Typography
            variant="h5"
            fontSize={`${fontSize}px`}
          >
            {formatAmount(data.total, locale, decimalNum, companyCurrencyCode)}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Stack
      height="100%"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          flexBasis="175px"
          flexShrink={0}
          variant="h5"
          mr={1.5}
        >{t("total.title", {companyName})}</Typography>
        <Box
          overflow="hidden"
          height="30px"
          paddingTop="2px"
        >
          <SelectZts
            label=""
            width="100%"
            disableHelperText
            items={rangeOptions.map(opt => ({value: opt, label: getChartRangeLabel(t, opt)}))}
            selectedValue={rangeType}
            setValue={handleChangeRange}
            simplifiedLayout
          />
        </Box>
      </Stack>
      <Box flexGrow={1}>

        {loading && <Skeleton height="80%" sx={{transform: "none", translate: "0 20px"}}/>}

        {!loading && data.length > 0 &&
          <ResponsiveContainer
            width="100%"
            height="100%"
          >
            <BarChart
              data={data}
              margin={{
                top: 38,
                right: 5,
                left: -15,
                bottom: 25,
              }}
            >
              <CartesianGrid
                strokeDasharray="0"
                vertical={false}
              />
              <XAxis
                axisLine={false}
                dataKey="month"
                label={{
                  offset: 6,
                  position: "bottom",
                  value: monthLabel,
                  stroke: "#C7C1CF",
                  fontSize: "11px",
                  fontWeight: 100,
                  letterSpacing: 1.2
                }}
                tick={renderXAxisTick}
                tickMargin={18}
                tickSize={0}
              />
              <YAxis
                axisLine={false}
                label={{
                  offset: 18,
                  position: "top",
                  value: companyCurrencyDesc,
                  stroke: "#C7C1CF",
                  fontSize: "11px",
                  fontWeight: 100,
                  letterSpacing: 1.2
                }}
                tick={renderYAxisTick}
                tickMargin={10}
                tickSize={0}
              />
              <Tooltip
                content={(props) => renderTooltip(props)}
                cursor={false}
                position={{x: position.x, y: position.y}}
                wrapperStyle={{
                  transform: `translate(${position.x}px, ${position.y}px)`,
                  top: `${(-70 - 12)}px`,
                  left: `${(position.width - 80)}px`,
                  zIndex: 100
                }}
              />
              <Bar
                dataKey="total"
                fill="#D32D66"
                onMouseOver={(point) => {
                  setPosition({x: point.x, y: point.y, width: point.width, height: point.height});
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        }
      </Box>
    </Stack>
  );
}
