import React, {useEffect, useState} from "react";

import {Alert, Button, CircularProgress, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {FileDownload, UploadFile} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {ImportFormValues, importValidationSchema, newImportFormValues} from "./validation";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useErrorMessage} from "../../util/ErrorUtil";
import {FileUpload} from "../base/file-upload";
import {CommonDialog} from "../base/common-dialog";
import {TooltipZts} from "../base/tooltip";
import {downloadTemplateFile, importFileData, uploadFileExcel} from "./Service";
import {MimeType} from "../base/file-upload/model";
import {FileImportRequest, FileUploadReq} from "./model";
import {useLoggedUser} from "../../hooks/useLoggedUser";
import {getJobsStateByIdList} from "../jobs/Service";
import {BatchJobState} from "../jobs/model";
import {convertBlobToBase64} from "../../util/FileUtil";

type ComponentProps = {
  //Titolo della modale (no chiave di bundle)
  title: string,
  //Funziona da chiamare in chiusura della modale, default vuota
  onClose: Function,
  show: boolean,
  //Se true viene visualizzato il tasto salva, default nascosto
  save: boolean,
  //Funziona chiamata quando si importa un file
  onImport: Function | null,
  //Funzione chiamata quando l'utente chiede di eseguire il download del template
  onDownload: Function | null,
  templateFileType: TemplateFileType,
  notifySuccessfulImport: () => void
}

export enum TemplateFileType{
  GENERAL_ACC = 'GENERAL_ACC',
  LEVEL = 'LEVEL',
  PROJECT = 'PROJECT',
  USERS = 'USERS'
}

export const ImportPage = ({title, show, onClose, save, onImport, onDownload, templateFileType, notifySuccessfulImport}: ComponentProps) => {
  const {userLocale} = useLoggedUser();
  const {t} = useTranslation('import');
  const {
    convertError
  } = useErrorMessage();

  const [loading, setLoading] = useState(false);
  const [warningMsg, setWarningMsg] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [subTitleMsg, setSubTitleMsg] = useState<string | null>(null);
  const [uploadedFileUUID, setUploadedFileUUID] = useState<string | null>(null);

  const [batchJobId, setBatchJobId] = useState<string | null>(null);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);

  const formMethods = useForm<ImportFormValues>({
    resolver: yupResolver(importValidationSchema()),
    mode: "onChange"    // per mostrare gli eventuali errori quando il campo viene modificato
    // (per vedere subito gli errori, prima della submit)
    // IMPATTA sulle PERFORMANCE
  });

  const resetDialog = (jobState?: BatchJobState) => {
    setLoading(false);
    if (jobState == undefined || jobState != BatchJobState.COMPLETED_WITH_WARNINGS) {
      setWarningMsg(null);
    }
    if (jobState == undefined || jobState != BatchJobState.COMPLETED_WITH_ERRORS) {
      setErrorMsg(null);
    }
    if (jobState == undefined || jobState != BatchJobState.COMPLETED_SUCCESFULLY) {
      setSubTitleMsg(null);
    }
    setUploadedFileUUID(null);
    setBatchJobId(null);
    setPollingInterval(null);
    formMethods.reset(newImportFormValues);
  }

  useEffect(() => {
    setUploadedFileUUID(null);
    setSubTitleMsg(null);
    setErrorMsg(null);

    if (show) {
      setLoading(true);
      formMethods.reset(newImportFormValues);

      Promise.all([])
        .then(res => {

        })
        .catch(err => console.log('Error loading page', err))
        .finally(() => setLoading(false));
    }
  }, [show]);

  useEffect(() => {
    if (batchJobId && !pollingInterval) {
      setPollingInterval(setInterval(getImportJobState, 1000, batchJobId));
    } else if (pollingInterval && !batchJobId) {
      clearInterval(pollingInterval);
      setLoading(false);
    }
  }, [batchJobId, pollingInterval]);

  const getImportJobState = (batchJobId: string): void => {
    if (batchJobId) {
      getJobsStateByIdList(batchJobId).then(resp => {
        const jobInfo = resp.at(0);
        if (jobInfo) {
          switch (jobInfo.batchState) {
            case BatchJobState.WAITING_FOR_EXECUTION:
              return;
            case BatchJobState.RUNNING:
              return;
            case BatchJobState.COMPLETED_SUCCESFULLY:
              notifySuccessfulImport();
              resetDialog(BatchJobState.COMPLETED_SUCCESFULLY);
              setSubTitleMsg("Dati importati correttamente");
              break;
            case BatchJobState.COMPLETED_WITH_ERRORS:
              setBatchJobId(null);
              let errMsg;
              if (jobInfo.infoMessageBundleParams) {
                let infoMessageBundleParamsOptionObject = {};
                jobInfo.infoMessageBundleParams.forEach((value, index) => {
                  infoMessageBundleParamsOptionObject[index] = value;
                })
                errMsg = t(jobInfo.infoMessageBundleKey, infoMessageBundleParamsOptionObject);
              } else {
                errMsg = t(jobInfo.infoMessageBundleKey);
              }
              resetDialog(BatchJobState.COMPLETED_WITH_ERRORS);
              setErrorMsg(errMsg);
              break;
            case BatchJobState.COMPLETED_WITH_WARNINGS:
              let warnMsg;
              if (jobInfo.infoMessageBundleParams) {
                let infoMessageBundleParamsOptionObject = {};
                jobInfo.infoMessageBundleParams.forEach((value, index) => {
                  infoMessageBundleParamsOptionObject[index] = value;
                })
                warnMsg = t(jobInfo.infoMessageBundleKey, infoMessageBundleParamsOptionObject);
              } else {
                warnMsg = t(jobInfo.infoMessageBundleKey);
              }
              notifySuccessfulImport();
              resetDialog(BatchJobState.COMPLETED_WITH_WARNINGS);
              setWarningMsg(warnMsg);
              break;
          }
        }
      })
        .catch(err => {
          convertError(err).then(msg => setErrorMsg(msg))
        })
    }
  }

  const handleExecuteImport = async () => {
    if (!uploadedFileUUID) {
      setSubTitleMsg("Nessun file è stato selezionato per l'importazione");
      return;
    }
    setLoading(true);
    try {
      const req: FileImportRequest = {
        uploadedFileUUID: uploadedFileUUID,
        templateFileType: templateFileType.toString(),
        locale: userLocale
      }
      importFileData(req)
        .then(resp => {
          setBatchJobId(resp.batchJobId.toString());
        })
    } catch (err: any) {
      setLoading(false);
      convertError(err).then(msg => setErrorMsg(msg));
    }

  }

  const handleDownloadTemplate = () => {
      if(onDownload){
        //Faccio quello che mi dice la funziona chiamante
        onDownload();
      }else{
        //Comportamento di default

        //Passo la tipologia di file zip da scaricare
        //Mi restituisce il file template
        downloadTemplateFile(templateFileType.toString())
          .then(resp => {
            const templateFileName = "template_" + templateFileType.toString().toLowerCase() + ".xlsx";
            const url = URL.createObjectURL(resp);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', templateFileName);
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
          })
          .catch((error) => {
            convertError(error as Error).then(res => setErrorMsg(res));
          })
      }
  }


  const fileUploadedHandler = (file: File) => {
    resetDialog();

    if (!file) {
      return;
    }

    new Promise<Blob | null>((res) => {
      if (file.type === MimeType.EXCEL_XLSX || file.type === MimeType.EXCEL) {
        res(file);
      } else {
        //res(ImageUtility.resizeImage({file, maxSize: 4096}));
      }
    })
      .then(res => {
        if (res) {
          return convertBlobToBase64(res);
        }
        throw new Error('Resize error');
      })
      .then(raw => {
        if (!raw) {
          throw new Error('Conversion error');
        }

        const req: FileUploadReq = {
          activateOcr: false,
          diskName: file.name,
          diskSize: file.size,
          diskTime: file.lastModified,
          mimeType: file.type,
          rawData: raw
        }

        setSubTitleMsg("Nome del file da importare: " + file.name);

        return uploadFileExcel(req);
      })
      .then(resp => {
        setUploadedFileUUID(resp.uploadKey);
      })
      .catch(err => {
        convertError(err).then(msg => setErrorMsg(msg));
      });
  }



  const getFileUploadArea = () => {
    return <>
      {<FileUpload
        excelEnabled={true}
        imageEnabled={false}
        pdfEnabled={false}
        htmlEnabled={false}
        onFileUploaded={(file: File) => fileUploadedHandler(file)}
        renderElement={(errorMsg, loading) => {
          //@ts-ignore
          if (formMethods.formState.errors.attachments?.message) {
            //@ts-ignore
            errorMsg = formMethods.formState.errors.attachments?.message;
          }
          return <Box
            className={"expense-upload-container"}
            width="100%"
          >
            {loading ?
              <CircularProgress/>
              :
              <>
                <Typography variant={"body2"} textAlign="center">{t('list.button.dragDrop')}</Typography>
                {errorMsg && <Typography variant={"body2"} mt={2} className={"text-danger"}>
                  {errorMsg}
                </Typography>}
              </>
            }
          </Box>
        }}
      />}
    </>
  }

  const closeDialog = () => {
    if (!loading) {
      resetDialog();
      onClose();
    }
  }

  return (
    <CommonDialog
      hideSave={!save}
      show={show}
      title={title}
      widths={[
        {breakpoint: "md", width: "450px"}
      ]}
      saving={loading}
      onClose={closeDialog}
      errorMsg={null}
      // page={show ? HelpCurrentPage.ACCOUNT_LIST : HelpCurrentPage.ACCOUNT_DETAIL}
    >
      <>

        {<FormProvider {...formMethods}>
            <form noValidate  style={{height: "100%"}}>

              <Box marginTop={5} textAlign={"center"}>
                {/*bottoncino per eseguire download del template*/}
                <TooltipZts
                  title={t('list.button.downloadTooltip')}
                  placement={"bottom"}
                  enterDelay={400}
                >
                  <Button
                    fullWidth={true}
                    variant="contained"
                    className={"cta-list-button"}
                    onClick={handleDownloadTemplate}>
                    <FileDownload/>
                    <Typography variant={"button"}>{t("list.button.download")}</Typography>
                  </Button>

                </TooltipZts>
              </Box>

              {/*scegli file*/}
              <Box height={130} marginTop={5} textAlign={"center"}>
                {getFileUploadArea()}
              </Box>

              <Box marginTop={5} textAlign={"center"}>
                <Typography ml={1} variant={"subtitle1"}>{subTitleMsg}</Typography>
              </Box>

              {
                errorMsg && <Alert
                severity={"error"}
                variant={"filled"}
                sx={{mb: 1, mt: 2}}
                onClose={() => setErrorMsg(null)}>{errorMsg}</Alert>
              }

              {
                warningMsg && <Alert
                  severity={"warning"}
                  variant={"filled"}
                  sx={{mb: 1, mt: 2}}
                  onClose={() => setWarningMsg(null)}>{warningMsg}</Alert>
              }

              <Box marginTop={5} textAlign={"center"} height={'fit-content'}>
                {/*esegui import file*/}
                <Button
                  fullWidth={true}
                  variant="contained"
                  className={"cta-button-primary-small"}
                  disabled={!uploadedFileUUID || loading}
                  onClick={handleExecuteImport}
                >
                  <UploadFile/>
                  <Typography ml={1} variant={"button"}>{t("list.button.executeImport")}</Typography>
                </Button>
              </Box>

            </form>
          </FormProvider>}
      </>
    </CommonDialog>
  );
}
