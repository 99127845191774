import projectLogo from "../../../../assets/images/new-logo-ztravel-smart.png";
import {Link} from "react-router-dom";
import * as React from "react";

type ComponentProps = {
  width: number
}

export const Logo = ({width}: ComponentProps) => {
  return (
    <Link to="/" className="app-logo-link">
      <img
        className="app-header-logo-img"
        alt="ZTravel Smart"
        width={width}
        src={projectLogo}
      />
    </Link>
  );
}
