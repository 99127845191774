import {Account} from "../model";
import Box from "@mui/material/Box";
import {Skeleton, Stack, Typography} from "@mui/material";
import {AccountCardWrapper} from "./AccountCardWrapper";
import {AccountCard} from "./AccountCard";
import {InfiniteScrollZts} from "../../base/infinite-scroll";
import {Add} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

type AccountProps = {
  accounts: Account[],
  loading: boolean,
  onOpen: Function,
  onRestore: Function,
  onDelete?: Function,
  active: boolean,
}

type ComponentProps = {
  props: AccountProps
}

export const AccountList = ({props}: ComponentProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation('account', {keyPrefix: 'list.emptyState'});

  return (
    <>
      {/*Skeleton*/}
      {props.loading &&
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          mt={3}
          maxWidth={"1200px"}
        >
          {
            [1, 2, 3].map(idx => {
              return (
                <AccountCardWrapper
                  key={idx}
                  prop={
                    {
                      child:
                        (<Skeleton
                          animation="pulse"
                          variant="rectangular"
                          width="100%"
                          height="216px"
                        />)
                    }
                  }
                />
              );
            })
          }
        </Box>
      }

      {
        !props.loading && props.accounts.length > 0 &&
        <InfiniteScrollZts
          scrollableTargetId={"infinite-scroll-target"}
          elements={props.accounts}
          renderElement={
            (account: Account) => {
              /* jsx */
              return (
                <AccountCardWrapper key={account.id} prop={{
                  child:
                    (<AccountCard props={
                      {
                        account: account,
                        onOpen: props.onOpen,
                        onRestore: props.onRestore,
                      }}
                    />)
                }}
                />
              );
            }
          }
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "12px",
            maxWidth: "1900px"
          }}
        />

      }
      {
        props.accounts.length === 0 &&
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "12px",
            maxWidth: "1900px"
          }}
        >
          <Box flexGrow={1}/>
          <Stack flexDirection={'column'}>
            {
              props.active ? (
                <>
                  <Typography variant={'h3'} color={'#c2c0c2'} mb={1}>{t('pro.title')}</Typography>
                  <Box alignSelf={'center'} width={'fit-content'} borderRadius={'20px'}
                       sx={{backgroundColor: '#14c6d5', cursor: 'pointer'}}>
                    <Stack flexDirection={'row'} pl={1.5} pr={1.5} pt={0.5} pb={0.5}
                           onClick={() => navigate('/accounts/edit/-1')}>
                      <Add fontSize={'small'} sx={{color: 'white'}}/>
                      <Typography pl={0.5} pr={0.5} color={'white'}>{t('pro.button1')}</Typography>
                    </Stack>
                  </Box>
                </>
              ) : (<Typography variant={'h3'} color={'#c2c0c2'} mb={1}>{t('free.title')}</Typography>)
            }
          </Stack>
          <Box flexGrow={1}/>
        </Box>
      }
    </>
  );
}
