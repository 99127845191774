import Box from "@mui/material/Box";
import {SearchBar} from "../../../../base/search-bar";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {ExpNoteStateFE} from "../../../model";
import {Button, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {Add, Close} from "@mui/icons-material";
import {DatePickerZts} from "../../../../base/date-picker";

// https://mui.com/blog/lab-date-pickers-to-mui-x/
//https://mui.com/x/react-date-pickers/getting-started/
import {useExpNoteList} from "../../../hooks/useExpNoteList";
import {ExpNoteListFilter, ExpNoteListFilterType, ExpNoteToApproveListFilters} from "../../../admin/list/model";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  onFilterChange: Function
}

export const ExpNoteFilters = ({onFilterChange}: ComponentProps) => {

  const {t} = useTranslation(["exp-note", "common"]);

  const [filters, setFilters] = useState<ExpNoteListFilter[] | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const {
    approverList: {filter, stateSelected},
  } = useExpNoteList();

  const applySessionFilter = (filters: ExpNoteListFilter[]): ExpNoteListFilter[] => {
    if (filter) {
      return filters.map(f => {
        let valueToSet: any[] | undefined = filter[f.type];
        if (!valueToSet) {
          valueToSet = f.values;
        }
        return ({...f, values: valueToSet, enabled: f.enabled || !!filter[f.type]})
      });
    } else {
      return filters;
    }
  }

  useEffect(() => {
    switch (stateSelected) {
      case ExpNoteStateFE.DA_APPROVARE:
        setFilters(applySessionFilter(ExpNoteToApproveListFilters));
        break;
    }
  }, [stateSelected]);

  useEffect(() => {
    onFilterChange(filters);
  }, [filters]);

  const onSearchTextChangeHandler = (e: SyntheticEvent) => {
    let target = e.target as HTMLInputElement;

    setFilters(prevFilters => {
      if (prevFilters) {
        return prevFilters
          .map(f => f.type === ExpNoteListFilterType.SEARCH_TEXT ? ({...f, values: [target.value]}) : f);
      }
      return null;
    });
  }

  const onDateChangeHandler = (type: ExpNoteListFilterType, index: number, value: Date) => {
    // @ts-ignore
    setFilters(prevFilters => prevFilters
      .map(f => {
        if (f.type === type) {
          const newValues = [...f.values];
          newValues[index] = value;
          return {...f, values: newValues};
        } else {
          return f;
        }
      }));
  }

  const handleFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const showFilter = (type: ExpNoteListFilterType) => {
    if (filters) {
      const enabledFilters = filters
        .filter(f => !f.hidden && f.enabled);

      const maxOrder = enabledFilters.length > 0 ?
        enabledFilters
          .map(f => f.order)
          .reduce((a, b) => a && b && a > b ? a : b) : 0;

      // @ts-ignore
      setFilters(prevFilters => prevFilters
        .map(f => f.type === type ?
          ({...f, enabled: true, order: (maxOrder ? maxOrder : 0) + 1}) :
          f));

      handleFilterClose();
    }
  }

  const disableFilter = (type: ExpNoteListFilterType) => {
    // @ts-ignore
    setFilters(prevFilters => prevFilters
      .map(f => f.type === type ? ({
        ...f,
        enabled: false,
        values: type === ExpNoteListFilterType.SEARCH_TEXT ? [''] : [null, null]
      }) : f));
  }

  return (
    <>

      {filters && <>
        <Box
          display={"flex"}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          <Box mr={2} mb={3}>
            <SearchBar
              placeHolder={t("list.searchDescription")}
              extraClassName={"search-field-exp-note-list"}
              filterText={
                filters
                  .filter(f => f.type === ExpNoteListFilterType.SEARCH_TEXT)[0].values[0]
              }
              onFilterChange={onSearchTextChangeHandler}
            />
          </Box>
          <Stack
            direction={"row"}
            alignItems={"center"}
            columnGap={2}
            mb={3}
          >

            {filters
              .filter(f => !f.hidden && f.enabled)
              .sort((a, b) => a.order && b.order ? a.order - b.order : 0)
              .map(f => {
                switch (f.type) {
                  case ExpNoteListFilterType.START_DATE:
                    return <Box key={f.type} className={"date-filter-wrapper"}>
                      <DatePickerZts
                        key={f.label + "From"}
                        label={t("list.filter.dateFrom")}
                        field={{value: f.values[0]}}
                        disableHelperText={true}
                        width={"160px"}
                        onChangeHandler={(date) => onDateChangeHandler(f.type, 0, date)}
                      />
                      <DatePickerZts
                        key={f.label + "To"}
                        label={t("list.filter.dateTo")}
                        field={{value: f.values[1]}}
                        disableHelperText={true}
                        width={"160px"}
                        onChangeHandler={(date) => onDateChangeHandler(f.type, 1, date)}
                      />
                      <Box className={"filter-close-button"}>
                        <Close
                          fontSize={"small"}
                          onClick={() => disableFilter(f.type)}
                        />
                      </Box>
                    </Box>

                  default:
                    return <></>
                }
              })}

            {filters.filter(f => !f.hidden && !f.enabled).length > 0 &&
              <Button
                disableRipple={true}
                variant="contained"
                className={"filter-button"}
                onClick={(e) => {
                  handleFilterClick(e)
                }}>
                <Typography variant={"button"} mr={2}>{t("common:list.filter")}</Typography>
                <Add fontSize={"small"}/>
              </Button>
            }

          </Stack>
        </Box>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleFilterClose}
        >
          {filters
            .filter(f => !f.hidden && !f.enabled)
            .map((filter) => {
              return <MenuItem
                key={filter.type}
                onClick={() => showFilter(filter.type)}
              >
                {t(filter.label)}
              </MenuItem>
            })}
        </Menu>

      </>}

    </>
  );
}
