import React, {ReactChild} from "react";
import {useLicense} from "../../../hooks/useLicense";
import {LicensePopup} from "../../license-popup";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getPopupcontent, PopupContentType} from "../../license-popup/model";
import {useLoggedUser} from "../../../hooks/useLoggedUser";

type ComponentProps = {
  children: ReactChild,
  type: PopupContentType
}

export const PremiumWrapper = ({children, type}: ComponentProps) => {
  const navigate = useNavigate();
  const {isProfessional} = useLicense();
  const free = !isProfessional();
  const {userLocale} = useLoggedUser();
  const {t} = useTranslation('license', {keyPrefix: 'pro.menu'});

  let popupTitle: string = type;
  let popupBody: ReactChild = getPopupcontent(type, userLocale);

  return (
    <>
      {
        free &&
        (
          <LicensePopup
            open={true}
            isPro={!free}
            closePopup={() => navigate(-1)}
            titlePro={t(`${popupTitle}.title`)}
            type={type}
          >
            {popupBody}
          </LicensePopup>
        )
      }
      {!free && children}
    </>
  );
}
