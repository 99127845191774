// React
import React from "react";

// MUI
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl, FormHelperText
} from "@mui/material";

// Custom
import {ExpenseImg} from "./ExpenseImg";



type ExpenseImgSelectProps = {
  label: string,
  imgList: string[]
  value: string
  onSelected: (img: string) => void
  required?: boolean
  disabled?: boolean
  errorMsg?: string
  expenseDescription: string
}

export const ExpenseImgSelect: React.FC<ExpenseImgSelectProps> = ({
  label,
  imgList,
  value,
  onSelected,
  required,
  disabled,
  errorMsg,
  expenseDescription
}) => {

  const isEmpty: boolean = value === undefined || value === null || value === '';

  return (
    <FormControl
      disabled={disabled}
      variant="filled"
      className={"select-form-control"}
      sx={{width: '112px', height: '110px'}}
      error={!!errorMsg}
    >
      <InputLabel
        className={isEmpty ? 'exp-img-selection-label' : ''}
        required={required}>{label}</InputLabel>
      <Select
        sx={{width: '100px', height: '80px'}}
        value={value}
        onChange={e => onSelected(e.target.value)}

        MenuProps={{
          className: 'exp-img-selection-menu',
        }}

        SelectDisplayProps = {{
          style: {
            marginTop: '6px'
          }
        }}
      >

        {imgList.map(img =>
          <MenuItem key={img} value={img}>
            <ExpenseImg
              img={img}
              label=''
              size='large'
            />
          </MenuItem>
        )}
      </Select>
      <FormHelperText>{errorMsg ? errorMsg : ' '}</FormHelperText>
    </FormControl>
  );

}
