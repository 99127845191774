import {Grid} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {AutocompleteZts} from "../base/autocomplete";
import {DatePickerZts} from "../base/date-picker";
import {TextFieldZts} from "../base/text-field";
import IconButton from "@mui/material/IconButton";
import React from "react";
import {FormValues} from "./model";
import {Close} from "@mui/icons-material";

export const Step2Inner = ({index, languageItems, remove}) => {

  const {control} = useFormContext<FormValues>();

  return (
    <Grid container my={2} spacing={2}>
      <Grid item xs={6}>
        <Controller
          name={`languages.${index}.language`}
          control={control}
          render={({field, fieldState}) => {
            return <AutocompleteZts
              id={field.name}
              label="Language"
              options={languageItems}
              selectedValue={field.value}
              setValue={(name, value) => {
                //console.log('setValue', name, value);
                field.onChange(value);
                //setValue(field.name, value, {shouldTouch: true})
              }}
              codeAndDesc={false}
              errorMsg={fieldState.error?.message}
            />
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name={`languages.${index}.ackDate`}
          control={control}
          render={({field, fieldState}) => {

            const meta = {touched: true, error: fieldState.error?.message};

            return <DatePickerZts
              label="Data acquisizione"
              field={field}
              errorMsg={fieldState.error?.message}
              onChangeHandler={(value: Date) => {
                //formikProps.setFieldValue(field.name, value);
                field.onChange(value);
              }}
            />
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name={`languages.${index}.rating`}
          control={control}
          render={({field, fieldState}) => {
            return <TextFieldZts
              {...field}
              label={"Rating"}
              errorMsg={fieldState.error?.message}
            />
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton
          size={"small"}
          onClick={() => remove(index)}
        >
          <Close className={"text-danger"} />
        </IconButton>
      </Grid>
    </Grid>
  );
}
