import {Grid, Typography} from "@mui/material";
import {TextFieldZts} from "../../base/text-field";
import React, {useEffect, useState} from "react";
import {User} from "../model";
import {Controller, useFormContext} from "react-hook-form";
import {SelectZts} from "../../base/select";
import {getLocaleLabel, getSelectableLocales} from "../../../util/LocalizationUtil";
import {useTranslation} from "react-i18next";
import {isUserMasterAdmin} from "../Service";
import SimpleJsLog from "../../../util/Logger";
import {Logger} from "react-logger-lib";

type ComponentProps = {
  user: User,
  enabled?: boolean
}

const LOG: SimpleJsLog = Logger.of('ZTS.User.GeneralData');

export const GeneralData = ({user, enabled}: ComponentProps) => {

  const {t} = useTranslation(['user', 'validation', 'common']);

  const [emailEnabled, setEmailEnabled] = useState(false);

  let control;
  if (enabled) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formMethods = useFormContext();
    control = formMethods.control;
  }

  useEffect(() => {
    if (enabled && user) {
      if (user.id === -1) {
        // nuovo utente --> la mail è sempre abilitata
        setEmailEnabled(true);
      } else {
        // utente esistente --> verifico se è il creatore dell'azienda
        // e solo in caso negativo abilito la mail
        isUserMasterAdmin(user.id)
          .then(isAdmin => {
            setEmailEnabled(!isAdmin);
          })
          .catch(err => {
            LOG.error(`Error searching master admin. userId: ${user.id}`, err);
            setEmailEnabled(false);
          })
      }
    }
  }, [user, enabled]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" textTransform={"uppercase"}>{t("user:detail.generalData.title")}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {enabled ? (
          <Controller
            name="firstName"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                required
                label={t("user:detail.generalData.firstName")}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        ) : (
          <TextFieldZts
            disabled={true}
            label={t("user:detail.generalData.firstName")}
            value={user.firstName}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {enabled ? (
          <Controller
            name="lastName"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                required
                label={t("user:detail.generalData.lastName")}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        ) : (
          <TextFieldZts
            disabled={true}
            label={t("user:detail.generalData.lastName")}
            value={user.lastName}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {enabled && emailEnabled ? (
          <Controller
            name="email"
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                required
                label={t("user:detail.generalData.email")}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        ) : (
          <TextFieldZts
            disabled={true}
            label={t("user:detail.generalData.email")}
            value={user.email}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {enabled ? (
        <Controller
          name="locale"
          control={control}
          render={({field, fieldState}) => {
            return <SelectZts
              label={t("user:detail.generalData.locale")}
              width="100%"
              items={getSelectableLocales(t)}
              selectedValue={field.value ? field.value : ''}
              errorMsg={fieldState.error?.message}
              setValue={(value: string) => {
                field.onChange(value);
              }}
            />
          }}
        />
        ) : (
          <TextFieldZts
            disabled={true}
            label={t("user:detail.generalData.locale")}
            value={getLocaleLabel(t, user.locale)}
          />
          )}
      </Grid>
    </Grid>
  );
}
