import {SvgIcon} from "@mui/material";
import {ReactComponent as MenuNoteSpeseIcon} from '../../../assets/images/MenuNoteSpese.svg';
import {ReactComponent as SuitcaseIcon} from '../../../assets/images/suitcase.svg';
import {ReactComponent as GoogleMapsIcon} from '../../../assets/images/GoogleMapsLogo.svg';

type SvgIconProps = {
  fontSize?: 'inherit' | 'large' | 'medium' | 'small',
  style?: any
}

export const MenuNoteSpese = (props: SvgIconProps) => {
  return <SvgIcon
    component={MenuNoteSpeseIcon}
    inheritViewBox
    {...props}
  />
}

export const MenuArchivioStoricoNoteSpese = (props: SvgIconProps) => {
  return <SvgIcon
    component={SuitcaseIcon}
    inheritViewBox
    {...props}
  />
}

export const Suitcase = (props: SvgIconProps) => {
  return <SvgIcon
    component={SuitcaseIcon}
    inheritViewBox
    {...props}
  />
}

export const GoogleMaps = (props: SvgIconProps) => {
  return <SvgIcon
    component={GoogleMapsIcon}
    inheritViewBox
    {...props}
  />
}
