import * as Yup from 'yup';
import { yupRequiredAmount, yupRequiredDate } from '../../../../../util/YupUtil';
import { AutocompleteCurrencyOption, AutocompleteGenericOption } from '../../../../base/autocomplete/model';
import { Locale } from '../../../../../util/LocalizationUtil';
import { PaymentCausal } from '../../../model';
import { formatAmount, formatNumber, parseNumber } from '../../../../../util/NumberUtil';
import { ValidationError } from 'yup';
import { CompanyDecimalNum } from '../../../../model';

export interface NewPaymentFormValues {
  id?: number;
  causal: AutocompleteGenericOption | null,
  operDate: Date | null,
  currency: AutocompleteCurrencyOption | null,
  amount: string,
  convertedCurrency: AutocompleteCurrencyOption | null,
  convertedAmount: string,
  maxAmount: number
}

export const NewPaymentInitValues = {
  causal: null,
  operDate: new Date(),
  currency: null,
  amount: '',
  convertedCurrency: null,
  convertedAmount: '',
  maxAmount: 0
}

export const paymentValidationSchema = (t, locale: Locale, companyDecimalNum: number) => {
  const required = t('required', {ns: 'validation'});

  return Yup.object({
    causal: Yup.object()
      .nullable()
      .required(required),
    operDate: yupRequiredDate(t),
    currency: Yup.object()
      .nullable()
      .required(required),
    amount: Yup.string()
      .when('currency', (currency: AutocompleteCurrencyOption) => {
        const decimalNum = currency ? currency.decimalNum : companyDecimalNum;
        return yupRequiredAmount(t, decimalNum, locale);
      }),
    convertedCurrency: Yup.object().nullable()
      .when('causal', {
        is: (causal: AutocompleteGenericOption) => causal && causal.code === PaymentCausal.CHANGE_CURRENCY,
        then: Yup.object()
          .nullable()
          .required(required)
      }),
    convertedAmount: Yup.string()
      .when('causal', {
        is: (causal: AutocompleteGenericOption) => causal && causal.code === PaymentCausal.CHANGE_CURRENCY,
        then: Yup.string()
          .when('convertedCurrency', (currency: AutocompleteCurrencyOption) => {
            const decimalNum = currency ? currency.decimalNum : companyDecimalNum;
            return yupRequiredAmount(t, decimalNum, locale);
          })
      }),
    maxAmount: Yup.number()
  })
    .test((context) => {
      const causal = context.causal;
      const amount = context.amount;
      const maxAmount = context.maxAmount;
      if (causal &&
        amount &&
        (causal.code === PaymentCausal.CHANGE_CURRENCY || causal.code === PaymentCausal.RETURN_CURRENCY) &&
        maxAmount) {
        const amountNum = parseNumber(amount, locale);
        if (amountNum && amountNum > maxAmount) {
          const decimalNum = context.currency ? context.currency.decimalNum : 0;
          const formattedAmount = formatNumber(maxAmount, locale, decimalNum);
          return new ValidationError(t('payment.error.amountNotAvailable', {amount: formattedAmount}), undefined, 'amount')
        }
      }
      return true;
    });
}
