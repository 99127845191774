import React, {useEffect, useState} from "react";
import {StaffExpenseItem} from "../model";
import {Grid, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {AutocompleteZts} from "../../base/autocomplete";
import {TextFieldZts} from "../../base/text-field";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {Controller, useFormContext} from "react-hook-form";
import {StaffExpenseFormValue, StaffExpensesFormValues} from "./ExpenseList";
import {Delete} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  index: number,
  remove: Function,
  element: StaffExpenseFormValue,
  expenseItems: StaffExpenseItem[],
  availableTravelPolicyByExp: Map<number, AutocompleteGenericOption[]> | null
}

export const SingleExpense = ({index, remove, element, expenseItems, availableTravelPolicyByExp}: ComponentProps) => {

  const [travelPolicies, setTravelPolicies] = useState<AutocompleteGenericOption[]>([]);
  const [showNotes, setShowNotes] = useState(false);

  const {t} = useTranslation(["user", "common"]);

  const {control, setValue} = useFormContext<StaffExpensesFormValues>();

  useEffect(() => {
    //console.log(element)
    if (element && element.expId && element.expId.id) {
      filterTravelPolicy(element.expId.id);
      if (isMeasureUnitKm(element.expId.id)) {
        setShowNotes(true);
      }
    }
  }, [element]);

  const isMeasureUnitKm = (elementId): boolean => {
    //console.log('expenseItems  ', expenseItems, elementId)
    if (elementId) {
      const exp = expenseItems.find(exp => exp.id === elementId);
      if (exp) {
        return exp.measureUnit === 'KM';
      }
    }
    return false;
  }

  const filterTravelPolicy = (expenseId: number) => {
    // console.log('exp', expense)
    // console.log('availableTravelPolicyByExp', availableTravelPolicyByExp[expense.id])
    if (expenseId) {
      setTravelPolicies(availableTravelPolicyByExp ? availableTravelPolicyByExp[expenseId] : []);
    } else {
      setTravelPolicies([]);
    }
  }
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      my={1}
      columnGap={2}
    >
      <Grid flexGrow={1} container spacing={2} mt={0}>
        <Grid item xs={12} md={4}>
          <Controller
            name={`expenses.${index}.expId`}
            control={control}
            render={({field, fieldState}) => {
              return <AutocompleteZts
                id={field.name}
                options={expenseItems.map(e => createAutocompleteGenericOption(e.id, e.code, e.description))}
                label={t("common:expenseItem")}
                selectedValue={field.value}
                //selectedValue={field.value && field.value.description ? getExpenseItemAutocompleteValue(field.value) : field.value}
                setValue={(name, value) => {
                  //console.log(value);
                  filterTravelPolicy(value ? value.id : null);
                  setValue(`expenses.${index}.expId`, value);
                  setShowNotes(value ? isMeasureUnitKm(value.id) : false);
                  setValue(`expenses.${index}.tpId`, null);
                }}
                codeAndDesc={false}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name={`expenses.${index}.tpId`}
            control={control}
            render={({field, fieldState}) => {
              return <AutocompleteZts
                id={field.name}
                options={travelPolicies}
                label={t("common:travelPolicy")}
                placeholder={t("user:detail.items.tarif.allTravelPolicies")}
                selectedValue={field.value}
                setValue={(_, value) => {
                  field.onChange(value);
                }}
                codeAndDesc={false}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            name={`expenses.${index}.tarif`}
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                align={"right"}
                label={t("user:detail.items.tarif.tarifAmount")}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            name={`expenses.${index}.notes`}
            control={control}
            render={({field, fieldState}) => {
              return <TextFieldZts
                {...field}
                label={t("user:detail.items.tarif.licensePlate")}
                sx={{
                  display: showNotes ? 'block' : 'none'
                }}
                errorMsg={fieldState.error?.message}
              />
            }}
          />
        </Grid>
      </Grid>

      <IconButton
        size={"small"}
        sx={{
          mt: {
            xs: 1,
            md: -1,
          },
          alignSelf: {
            xs: 'flex-start',
            md: 'center'
          }
        }}
        onClick={() => remove(index)}
      >
        <Delete className={"text-danger"}/>
      </IconButton>

    </Stack>
  );
}
