import {Header} from "../header";
import React, {useEffect, useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import {Menu} from "../menu";
import Box from "@mui/material/Box";
import {Outer} from "../menu/outer";
import {Inner} from "../menu/inner";
import {getMenuItemsData, OuterMenu} from "../menu/model";
import {BrowserRouter, Outlet} from "react-router-dom";
import {AppRoutes} from "../../routes";
import {useMenu} from "../menu/hook";
import {Article} from "@mui/icons-material";
import {Help} from "../../help/component";
import {ConfirmDialog} from "../../base/confirm-dialog";
import {useTranslation} from "react-i18next";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
// https://mui.com/blog/lab-date-pickers-to-mui-x/
import {LocalizationProvider} from '@mui/x-date-pickers'
//https://mui.com/x/react-date-pickers/getting-started/
import "moment/locale/it";
import "moment/locale/en-gb";
import "moment/locale/es";
import {isApprover} from "../../users/Service";
import {ZtsDateAdapter} from "../../../util/ZtsDateAdapter";
import {JobsNotifier} from "../../jobs";
import {AlertDialog} from "../../base/alert-dialog";
import {useMobileBreakpoint} from "../../../hooks/useMobileBreakpoint";
import {KpiData} from "./model";
import {getZtsJwtPayload} from "../../../config/token";
import {getLicenseData} from "../../dashboard/Service";
import SimpleJsLog from "../../../util/Logger";
import {Logger} from "react-logger-lib";

const LOG: SimpleJsLog = Logger.of('ZTS.Main');

export const Main = () => {
  const {t} = useTranslation('layout', {keyPrefix: 'menu.label'});
  const [outerMenu, setOuterMenu] = useState<OuterMenu[]>([]);

  const [kpiData, setKpiData] = useState<KpiData | null>(null);

  const {
    drawerWidth,
    toggleMenuDesktop,
    toggleMenuMobile,
    menuDesktopOpen,
    menuMobileOpen,
    isMobile
  } = useMenu();

  const {user: loggedUser, userLocale} = useLoggedUser();

  const {i18n} = useTranslation();

  const {
    isMobileBreakpoint
  } = useMobileBreakpoint();

  useEffect(() => {
    if (loggedUser) {
      isApprover()
        .then(isApprover => setOuterMenu(getMenuItemsData(loggedUser.isAdmin, loggedUser.isTraveller, isApprover, loggedUser.tenant.hasArchivedExpNote)));

      fetchKpiData();
    } else {
      setKpiData(null);
    }
  }, [loggedUser]);

  useEffect(() => {
    if (loggedUser && loggedUser.locale) {
      i18n.changeLanguage(loggedUser.locale);
    }
  }, [loggedUser, i18n]);

  const fetchKpiData = async () => {
    let kpiData: KpiData | null = null;
    if (loggedUser) {
      const token = getZtsJwtPayload();
      if (token) {
        try {
          const licenseData = await getLicenseData(token.tenantId);
          kpiData = {
            tenantId: loggedUser.tenant.id,
            tenantName: loggedUser.tenant.description,
            isAdmin: loggedUser.isAdmin,
            licenseType: licenseData.type,
            licenseExpiration: licenseData.expirationDate as unknown as string
          };
        } catch (error) {
          LOG.error("Error fetching license data", error);
        }
      }
    }
    setKpiData(kpiData);
  }

  return (
    <>
      <LocalizationProvider
        dateAdapter={ZtsDateAdapter}
        adapterLocale={userLocale}
        localeText={{
          nextMonth: '',
          previousMonth: ''
        }}
      >
        <BrowserRouter>
          <Box display={"flex"} className={"help-container-wrapper"}>
            {!isMobileBreakpoint && loggedUser ? (
              <>
                <Header
                  drawerWidth={drawerWidth}
                  toggleMenuMobile={toggleMenuMobile}
                  menuDesktopOpen={menuDesktopOpen}
                  isMobile={isMobile}
                />

                {outerMenu.length > 0 &&
                  <Menu
                    drawerWidth={drawerWidth}
                    toggleMenuDesktop={toggleMenuDesktop}
                    menuItems={
                      <Box
                        width={"100%"}
                        sx={{
                          overflowX: "hidden",
                          overflowY: "auto"
                        }}
                      >
                        {outerMenu.map(outer =>
                          <Outer
                            key={outer.id}
                            label={t(outer.label)}
                            hideLabel={loggedUser.isTraveller && !loggedUser.isAdmin}
                            menuDesktopOpen={menuDesktopOpen || isMobile}
                          >
                            {outer.inner.map(inner => <Inner
                                key={inner.id}
                                id={inner.id}
                                label={t(inner.label)}
                                icon={inner.icon ? inner.icon : <Article fontSize={"small"}/>}
                                route={inner.route}
                                menuItems={inner.items}
                                menuDesktopOpen={menuDesktopOpen || isMobile}
                                premiumPopupType={inner.premiumPopupType ? inner.premiumPopupType : undefined}
                              />
                            )}
                          </Outer>
                        )}
                      </Box>
                    }
                    menuDesktopOpen={menuDesktopOpen}
                    menuMobileOpen={menuMobileOpen}
                    toggleMenuMobile={toggleMenuMobile}
                    isMobile={isMobile}
                  />
                }
              </>
            ) : (
              <></>
            )
            }
            <Box
              component="main"
              className={"app-main"}
              padding={3}
              paddingTop={4}
            >
              <Toolbar/>
              <JobsNotifier/>
              <AppRoutes/>
              <Outlet/>

              <AlertDialog/>
              <ConfirmDialog/>

            </Box>
            <Help/>
          </Box>
        </BrowserRouter>
      </LocalizationProvider>

      {kpiData && (
        <>
          <input type="hidden" id="kpi_tenantId" name="kpi_tenantId" value={kpiData.tenantId}/>
          <input type="hidden" id="kpi_tenantName" name="kpi_tenantName" value={kpiData.tenantName}/>
          <input type="hidden" id="kpi_isAdmin" name="kpi_isAdmin" value={kpiData.isAdmin ? "true" : "false"}/>
          <input type="hidden" id="kpi_licenseType" name="kpi_licenseType" value={kpiData.licenseType}/>
          <input type="hidden" id="kpi_licenseExpiration" name="kpi_licenseExpiration" value={kpiData.licenseExpiration}/>
        </>
      )}

    </>
  )
    ;

}
