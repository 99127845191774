import {Button, Snackbar, Stack, Typography} from "@mui/material";
import {TransitionUpZts} from "../transition";
import Box from "@mui/material/Box";
import {DeleteForever} from "@mui/icons-material";
import React from "react";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  size: number,
  clearItems: Function,
  actionHandler?: Function,
  ctaLabel?: string,
  ctaButton?: JSX.Element | null
}

export const MultipleSelection = ({size, clearItems, actionHandler, ctaLabel, ctaButton}: ComponentProps) => {
  const {t} = useTranslation('base', {keyPrefix: 'multiple-selection'});
  return (
    <Snackbar
      open={size > 0}
      className={"selected-items"}
      TransitionComponent={TransitionUpZts}
    >
      <Stack
        direction={"row"}
        flexWrap={{
          xs: "wrap",
          sm: "nowrap"
        }}
        rowGap={1}
        justifyContent={"flex-start"}
        alignItems={{
          xs: "flex-start",
          sm: "center"
        }}
        m={3}
        width={"100%"}
      >

        <Stack
          direction={{
            xs: "column",
            sm: "row"
          }}
          alignItems={{
            xs: "flex-start",
            sm: "center"
          }}
          flexGrow={1}
          columnGap={2}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
          >
            <Box className={"selected-counter"}>
              <Typography variant={"body1"}>{size}</Typography>
            </Box>
            <Typography
              variant={"body1"}
              ml={2}
            >
              {t('selected')}
            </Typography>
          </Box>
          <Box
            className={"unselect-button"}
            onClick={() => clearItems()}
          >
            <Typography variant={"body1"}>
              {t('unselectAll')}
            </Typography>
          </Box>
        </Stack>

        {ctaButton ? (
          ctaButton
        ) : (
          <Button
            variant="contained"
            className={"delete-button"}
            sx={{
              margin: {
                xs: "0 auto",
                sm: "none"
              }
            }}
            onClick={() => actionHandler ? actionHandler() : {}}
          >
            <DeleteForever fontSize={"small"}/>
            <Typography ml={1} variant={"button"}>{ctaLabel ? ctaLabel : t('delete')}</Typography>
          </Button>
        )}
      </Stack>
    </Snackbar>
  );
}
