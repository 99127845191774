import {Autocomplete, AutocompleteProps, InputAdornment, InputProps, TextField} from "@mui/material";
import React, {ReactNode, SyntheticEvent, useState} from "react";
import Box from "@mui/material/Box";
import {autocompleteFilter, AutocompleteGenericOption} from "./model";

type ComponentProps = {
  id?: string,
  label: string,
  disabled?: boolean,
  codeAndDesc?: boolean,
  placeholder?: string,
  userValueEnabled?: boolean,
  options: AutocompleteGenericOption[] | null,
  selectedValue: AutocompleteGenericOption | null,
  setValue: Function,
  onBlurHandler?: Function
  errorMsg?: string,
  disableHelperText?: boolean
  appendNewValue?: (newValue: string) => AutocompleteGenericOption,
  matchEqualByCode?: boolean,
  hiddenLabel?: boolean,
  renderIcon?: (icon: string) => ReactNode,
  minSearchChar?: number,
  lazyLoadOptions?: Function,
  clearIcon?: ReactNode
  disablePortal?: boolean
  noOptionsText?: string
}

export const AutocompleteZts = ({
                                  id,
                                  label,
                                  disabled,
                                  options,
                                  selectedValue,
                                  setValue,
                                  codeAndDesc,
                                  placeholder,
                                  userValueEnabled,
                                  onBlurHandler,
                                  errorMsg,
                                  disableHelperText,
                                  appendNewValue,
                                  matchEqualByCode,
                                  hiddenLabel,
                                  renderIcon,
                                  minSearchChar,
                                  lazyLoadOptions,
                                  clearIcon,
                                  disablePortal,
                                  noOptionsText
                                }: ComponentProps) => {

  const [open, setOpen] = useState(false);

  let loading = false;

  const getGenericOptionLabel = (opt: AutocompleteGenericOption | string): string => {
    // Cambiata dipendenza
    if (opt === undefined || opt === null) {
      return '';
    }
    if (typeof opt === 'string') {
      return opt as string;
    }

    if (opt.id) {
      return opt.selectedLabel;
    }

    return '';
  }

  const renderGenericOption = (props: any, opt: AutocompleteGenericOption) => {
    return <Box component="li" sx={{'& > img, & > svg': {mr: 2, flexShrink: 0}}} {...props}>
      {getIcon(opt)}
      {codeAndDesc ? opt.listLabel : opt.selectedLabel}
    </Box>
  }

  const filterGenericOptions = (options: Array<AutocompleteGenericOption>, state: any): AutocompleteGenericOption[] => {
    const searchText = state.inputValue.toLowerCase();
    if (searchText === '') {
      return options;
    }
    const filtered = options.filter(o => autocompleteFilter(searchText, o, codeAndDesc));

    if (appendNewValue && state.inputValue !== '') {
      // aggiungo la nuova voce
      const newValueOption = appendNewValue(state.inputValue);
      filtered.push(newValueOption);
    }
    return filtered;
  }

  const getIcon = (option: AutocompleteGenericOption | null): ReactNode => {
    return option && option.icon ? (
        renderIcon ? renderIcon(option.icon) : (
          <img
            loading="lazy"
            width="20"
            src={``}
            alt=""
          />
        )
      ) :
      (<></>);
  }

  const getInputProps = (inputProps: InputProps): InputProps => {
    if (selectedValue && selectedValue.icon) {
      return {
        ...inputProps,
        startAdornment: <InputAdornment position="start">{getIcon(selectedValue)}</InputAdornment>
      };
    }
    return inputProps;
  }

  const otherProps: Partial<AutocompleteProps<any, any, any, any>> = {}

  if (lazyLoadOptions) {
    otherProps.open = open;
    otherProps.onFocus = () => {
      if (userValueEnabled) {
        lazyLoadOptions();
      }
    }
    otherProps.onInputChange = (e) => {
      if (minSearchChar) {
        const value = (e.target as HTMLInputElement).value;
        if (!value || value.length < minSearchChar) {
          setOpen(false);
        }
      }
    }
    otherProps.onOpen = (e) => {
      const value = (e.target as HTMLInputElement).value;
      if (minSearchChar) {
        if (!value || value.length < minSearchChar) {
          return;
        }
      }
      setOpen(true);
      if (!options) {
        lazyLoadOptions(value);
      }
    }
    otherProps.onClose = () => {
      setOpen(false);
    }
    loading = open && !options;
    otherProps.loading = loading;
  }

  if (clearIcon) {
    otherProps.clearIcon = clearIcon;
  }

  return (
    <Autocomplete
      disablePortal={disablePortal}
      id={id}
      options={options ? options : []}
      getOptionLabel={getGenericOptionLabel}
      filterOptions={filterGenericOptions}
      renderOption={renderGenericOption}
      isOptionEqualToValue={(option, value) => option && value && (matchEqualByCode ? option.code === value.code : option.id === value.id)}
      autoHighlight
      disabled={disabled}
      value={selectedValue}
      freeSolo={userValueEnabled}
      noOptionsText={noOptionsText}
      onBlur={(e) => {
        if (onBlurHandler) {
          onBlurHandler((e.target as HTMLInputElement).value);
        }
      }}
      onChange={(e, value) => {
        setValue(
          id,
          value !== null ? value : null
        );
      }}

      {...otherProps}

      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          label={label}
          variant="filled"
          size={"small"}
          hiddenLabel={hiddenLabel}
          error={Boolean(errorMsg)}
          helperText={!disableHelperText && (errorMsg || ' ')}
          InputProps={getInputProps(params.InputProps)}
        />
      )}
    />
  );
}
