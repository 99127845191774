import React, {useEffect, useState} from "react";
import {Alert, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {Level, ListFilterType} from "../model";
import IconButton from "@mui/material/IconButton";
import {PageTitle} from "../../layout/page-title";
import {Filters} from "./Filters";
import {getLevels, restoreLevel} from "../Service";
import {LevelList} from "./LevelList";
import {Add, UploadFile} from "@mui/icons-material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import {TooltipZts} from "../../base/tooltip";
import {useTranslation} from "react-i18next";
import {createError, useErrorMessage} from "../../../util/ErrorUtil";
import {ImportPage, TemplateFileType} from "../../import/ImportPage";

export const FilterableList = () => {

  const [allLevels, setAllLevels] = useState<Level[]>([]);
  const [level, setLevel] = useState<Level[]>([]);
  const [disabledLevels, setDisabledLevels] = useState<Level[]>([]);
  const [loading, setLoading] = useState(false);
  const [showActive, setShowActive] = useState<boolean>(true);
  const [openImportPage, setOpenImportPage] = useState(false);
  const [successfulImport, setSuccessfulImport] = useState<boolean>(false);

  const [fetchError, setFetchError] = useState<string | null>(null);
  const [toggleClicked, setToggleClicked] = useState("lista");
  const {t} = useTranslation(['level'], {keyPrefix: 'list'});
  const {t: tImport} = useTranslation('import');

  const navigate = useNavigate();
  const {convertError} = useErrorMessage();

  useEffect(() => {
    fetchLevels();
  }, []);

  const handleFilterChange = (filters) => {
    let newLevels = allLevels;

    if (newLevels && newLevels.length > 0) {
      filters
        .filter(f => f.enabled && f.value)
        .forEach(filter => {
          switch (filter.type) {
            case ListFilterType.SEARCH_TEXT:
              newLevels = newLevels.filter((l: Level) =>
                l.code.toUpperCase().indexOf(filter.value.toUpperCase()) !== -1 ||
                l.description.toUpperCase().indexOf(filter.value.toUpperCase()) !== -1);
              break;
          }
        });
    }
    let filteredDisabledLevels = newLevels.filter((level) => level.state === 'AA');
    let filteredActiveLevels = newLevels.filter((level) => level.state == 'EF');

    setLevel(filteredActiveLevels);
    setDisabledLevels(filteredDisabledLevels);
  }

  const fetchLevels = async () => {
    let activeLevels: Level[] = [];
    let disabled: Level[] = [];
    setLoading(true);
    try {
      const levels = await getLevels();
      if (levels) {
        levels.filter((level: Level) => {
          if (level.state === 'EF') {
            activeLevels.push(level);
          } else if (level.state === 'AA') {
            disabled.push(level);
          }
        })
        setAllLevels(levels);
        setLevel(activeLevels);
        setDisabledLevels(disabled);
      }
    } catch (error) {
      convertError(createError(error))
        .then((msg) => setFetchError(msg))
      setTimeout(() => setFetchError(null), 3000);
    }
    setLoading(false);
  }

  const onRestoreLevelHandler = (id: number) => {
    if (id) {
      restoreLevel(id)
        .then(() => {
          fetchLevels();
        })
    }
  }

  const openItemHandler = (id: number) => {
    navigate(`/levels/detail/${id}`);
  }

  const handleNewLevelClicked = () => {
    navigate('/levels/edit/-1');
  }
  // }

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView !== null) {
      setToggleClicked(nextView);
      setShowActive(!showActive);
    }
  }

  const handleImportPageClose = () => {
    setOpenImportPage(false);
    if (successfulImport) {
      fetchLevels();
    }
  }

  const handleImportButtonClicked = () => {
    setOpenImportPage(true);
  }

  return (
    <Box
      width={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      mx={"auto"}
    >

      {fetchError && <Alert
        severity={"error"}
        variant={"filled"}
        sx={{mb: 4}}
        onClose={() => setFetchError(null)}>{fetchError}</Alert>}

      {/* Titolo */}
      <PageTitle
        title={
          <Stack direction={"row"} alignItems={"center"}>
            {
              showActive ?
                (
                  <>
                    <Typography variant={"h3"} mr={1}>{t('title')}</Typography>
                    <Box className={"counter-primary"}>
                      <Typography variant={"button"}>{level ? level.length : 0}</Typography>
                    </Box>
                  </>
                ) :
                (
                  <>
                    <Typography variant={"h3"} mr={1}>Centri di costo</Typography>
                    <Typography variant={"h4"} children={`(${t("titleParam")})`} sx={{color: "#14c6d5"}}/>
                    <Box className={"counter-primary"} ml={1}>
                      <Typography variant={"button"}>{disabledLevels ? disabledLevels.length : 0}</Typography>
                    </Box>
                  </>
                )
            }
            <Box flexGrow={1}/>
            <ToggleButtonGroup
              exclusive={true}
              value={toggleClicked}
              size={"small"}
              onChange={handleChange}
              color={"primary"}
            >
              <ToggleButton
                value={"lista"}
                className={"ztsToggle"}
              >
                <TooltipZts
                  title={t('button.showList')}
                  placement={"bottom"}
                  enterDelay={400}
                >
                  <ViewModuleIcon fontSize={"small"}/>
                </TooltipZts>

              </ToggleButton>
              <ToggleButton
                value={"cestino"}
                className={"ztsToggle"}
              >
                <TooltipZts
                  title={t('button.showTrash')}
                  placement={"bottom"}
                  enterDelay={400}
                >
                  <DeleteOutlineIcon fontSize={"small"}/>
                </TooltipZts>

              </ToggleButton>

            </ToggleButtonGroup>
          </Stack>
        }
      />

      <Box
        mt={1}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        columnGap={1}
      >

        {/* Filtri */}
        <Filters onFilterChange={handleFilterChange}/>

        <Box flexGrow={1}/>


        <TooltipZts
          title={tImport('tooltip.levels')}
          placement={"bottom"}
          enterDelay={400}
        >
          <IconButton
            color="primary"
            className={"edit-button"}
            id={"import-button"}
            sx={{
              opacity: showActive ? 1 : 0.4,
              cursor: showActive ? "pointer" : "default"
            }}
            disabled={!showActive}
            onClick={handleImportButtonClicked}
          >
            <UploadFile/>
          </IconButton>
        </TooltipZts>

        {/* Pulsanti: Nuovo centro di costo */}
        <TooltipZts
          title={t('button.newLevel')}
          placement={"bottom"}
          enterDelay={400}
        >
          <IconButton
            id={"new-lev-button"}
            color="primary"
            className={"edit-button"}
            onClick={handleNewLevelClicked}
            sx={{
              opacity: showActive ? 1 : 0.4,
              cursor: showActive ? "pointer" : "default"
            }}
            disabled={!showActive}
          >
            <Add/>
          </IconButton>
        </TooltipZts>


      </Box>

      {/* Lista centri di costo */}
      <Box
        id={"infinite-scroll-target"}
        overflow={"auto"}
        flexGrow={1}
      >
        {
          showActive ?
            (
              <LevelList
                levels={level}
                loading={loading}
                onOpen={openItemHandler}
                onRestore={onRestoreLevelHandler}
                active={true}
              />
            ) : (
              <LevelList
                levels={disabledLevels}
                loading={loading}
                onOpen={openItemHandler}
                onRestore={onRestoreLevelHandler}
                active={false}
              />
            )
        }
      </Box>

      <ImportPage
        title={tImport('list.titleImport.levels')}
        onClose={handleImportPageClose}
        show={openImportPage}
        save={false}
        onImport={null}
        onDownload={null}
        templateFileType={TemplateFileType.LEVEL}
        notifySuccessfulImport={() => setSuccessfulImport(true)}
      />

    </Box>
  );
}
