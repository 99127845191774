import {
  AdminLabel,
  CreCardFilter,
  ListFilters,
  ListFilterType,
  RoleFilter,
  TravellerLabel
} from "../model";
import Box from "@mui/material/Box";
import {Button, FormControlLabel, Menu, MenuItem, Skeleton, Stack, Typography} from "@mui/material";
import {IOSSwitch} from "../../base/switch";
import {AutocompleteZts} from "../../base/autocomplete";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {getAllLevels, getAllTravelPolicies} from "../../travel_policies/Service";
import {SearchBar} from "../../base/search-bar";
import {SelectZts} from "../../base/select";
import {SelectOption} from "../../base/select/model";
import {Add, Close} from "@mui/icons-material";
import {useCurrentList} from "../../../hooks/useCurrentList";
import {useTranslation} from "react-i18next";
import {getApprovers} from "../Service";

export const Filters = () => {

  const {
    filters,
    setListFilters
  } = useCurrentList();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [travelPolicies, setTravelPolicies] = useState<AutocompleteGenericOption[] | null>(null);
  const [levels, setLevels] = useState<AutocompleteGenericOption[] | null>(null);
  const [approvers, setApprovers] = useState<AutocompleteGenericOption[] | null>(null);
  const [loadingTP, setLoadingTP] = useState(false);
  const [loadingLevels, setLoadingLevels] = useState(false);
  const [loadingApprovers, setLoadingApprovers] = useState(false);

  const {t} = useTranslation("user");
  const {t: tCommon} = useTranslation("common");

  useEffect(() => {
    if (!filters) {
      setListFilters(ListFilters);
    } else {
      const tpFilter = filters.find(f => f.type === ListFilterType.TRAVEL_POLICY);
      if (tpFilter && tpFilter.enabled && !travelPolicies) {
        loadTravelPolicies();
      }

      //Carico anche l'elenco dei centri di costo
      const levelFilter = filters.find(f => f.type === ListFilterType.LEVEL);
      if (levelFilter && levelFilter.enabled && !levels) {
        loadLevels();
      }

      //Carico anche l'elenco degli approvatori
      const approverFilter = filters.find(f => f.type === ListFilterType.APPROVER);
      if (approverFilter && approverFilter.enabled && !approvers) {
        loadApprovers();
      }
    }
  }, [filters]);

  const loadTravelPolicies = () => {
    setLoadingTP(true);
    getAllTravelPolicies()
      .then(travelPolicies => {
        setTravelPolicies(travelPolicies.map((tp) => createAutocompleteGenericOption(tp.id, tp.code, tp.description)));
      })
      .finally(() => setLoadingTP(false));
  }

  const loadLevels = () => {
    setLoadingLevels(true);
    getAllLevels()
      .then(levels => {
        setLevels(levels.map((cc) => createAutocompleteGenericOption(cc.id, cc.code, cc.description)));
      })
      .finally(() => setLoadingLevels(false));
  }

  const loadApprovers = () => {
    setLoadingApprovers(true);
    getApprovers()
      .then(approvers => {
        setApprovers(approvers.map((app) => createAutocompleteGenericOption(app.id, app.firstName, app.lastName)));
      })
      .finally(() => setLoadingApprovers(false));
  }

  const activeChangeHandler = (e: SyntheticEvent) => {
    let target = e.target as HTMLInputElement;

    if (filters) {
      setListFilters(filters
        .map(f => f.type === ListFilterType.ENABLED ? ({...f, value: target.checked ? '1' : '0'}) : f));
    }
  }

  const handleFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const showFilter = (type: ListFilterType) => {
    if (filters) {
      const enabledFilters = filters
        .filter(f => !f.hidden && f.enabled);

      const maxOrder = enabledFilters.length > 0 ?
        enabledFilters
          .map(f => f.order)
          .reduce((a, b) => a && b && a > b ? a : b) : 0;

      setListFilters(filters
        .map(f => f.type === type ?
          ({
            ...f,
            enabled: true,
            order: (maxOrder ? maxOrder : 0) + 1,
            value: (f.type === ListFilterType.ENABLED ? '1' : null)
          }) :
          f));
      handleFilterClose();
    }
  }

  const disableFilter = (type: ListFilterType) => {
    if (filters) {
      setListFilters(filters
        .map(f => f.type === type ? ({...f, enabled: false, value: null}) : f));
    }
  }

  const searchTextChangeHandler = (e: SyntheticEvent) => {
    if (filters) {
      let target = e.target as HTMLInputElement;

      setListFilters(filters
        .map(f => f.type === ListFilterType.SEARCH_TEXT ? ({...f, value: target.value}) : f));
    }
  }

  const roleFilters: Map<RoleFilter, string> = new Map();
  roleFilters.set(RoleFilter.TRAVELLER, tCommon(TravellerLabel));
  roleFilters.set(RoleFilter.ADMIN, tCommon(AdminLabel));

  const roleItems: SelectOption[] = Array.from(roleFilters.keys())
    .map(key => ({value: key.valueOf(), label: roleFilters.get(key)}));

  const creCardFilters: Map<CreCardFilter, string> = new Map();
  creCardFilters.set(CreCardFilter.VALID_CRE_CARD, t('list.filter.validCreCard'));
  creCardFilters.set(CreCardFilter.NO_CRE_CARD, t('list.filter.novalidCreCard'));

  const creCardItems: SelectOption[] = Array.from(creCardFilters.keys())
    .map(key => ({value: key.valueOf(), label: creCardFilters.get(key)}));

  return (
    <>
      <Box
        flexGrow={{
          xs: 1,
          lg: 0
        }}
        flexBasis={{
          xs: "calc(100% - 60px)",
          lg: "auto"
        }}
        order={1}
        mr={2}
      >
        {filters && <SearchBar
          filterText={
            filters
              .filter(f => f.type === ListFilterType.SEARCH_TEXT)[0].value
          }
          onFilterChange={searchTextChangeHandler}
        />}
      </Box>

      <Box
        flexGrow={1}
        flexBasis={"auto"}
        overflow={"hidden"}
        mt={{
          xs: 1,
          lg: 0
        }}
        order={{
          xs: 2,
          lg: 1
        }}
      >
        <Stack
          flexGrow={1}
          direction={"row"}
          alignItems={"center"}
          columnGap={2}
        >

          {filters ? (
            <>
              {filters
                .filter(f => !f.hidden && f.enabled)
                .sort((a, b) => a.order && b.order ? a.order - b.order : 0)
                .map(f => {
                  switch (f.type) {
                    case ListFilterType.ENABLED:
                      return <Box key={f.type} className={"enabled-filter-wrapper"}>
                        <FormControlLabel
                          label={t("list.filter.onlyActive")}
                          labelPlacement={"start"}
                          control={
                            <IOSSwitch
                              checked={f.value === '1'}
                              onChange={activeChangeHandler}/>
                          }/>
                        <Box className={"filter-close-button"}>
                          <Close
                            fontSize={"small"}
                            onClick={() => disableFilter(f.type)}
                          />
                        </Box>
                      </Box>

                    case ListFilterType.TRAVEL_POLICY:
                      return <Box key={f.type} className={"travel-policy-filter-wrapper"}>
                        {loadingTP || !travelPolicies ? (
                          <Skeleton variant="rectangular" height={52}/>
                        ) : (
                          <>
                            <AutocompleteZts
                              label={t(f.label)}
                              options={travelPolicies}
                              selectedValue={f.value}
                              setValue={(id, value) => {
                                setListFilters(filters
                                  .map(f => f.type === ListFilterType.TRAVEL_POLICY ? ({...f, value}) : f));
                              }}
                              codeAndDesc={false}
                              disableHelperText={true}
                            />
                            <Box className={"filter-close-button"}>
                              <Close
                                fontSize={"small"}
                                onClick={() => disableFilter(f.type)}
                              />
                            </Box>
                          </>
                        )}
                      </Box>

                    case ListFilterType.LEVEL:
                      return <Box key={f.type} className={"travel-policy-filter-wrapper"}>
                        {loadingLevels || !levels ? (
                          <Skeleton variant="rectangular" height={52}/>
                        ) : (
                          <>
                            <AutocompleteZts
                              label={t(f.label)}
                              options={levels}
                              selectedValue={f.value}
                              setValue={(id, value) => {
                                setListFilters(filters
                                  .map(f => f.type === ListFilterType.LEVEL ? ({...f, value}) : f));
                              }}
                              codeAndDesc={false}
                              disableHelperText={true}
                            />
                            <Box className={"filter-close-button"}>
                              <Close
                                fontSize={"small"}
                                onClick={() => disableFilter(f.type)}
                              />
                            </Box>
                          </>
                        )}
                      </Box>

                    case ListFilterType.APPROVER:
                      return <Box key={f.type} className={"travel-policy-filter-wrapper"}>
                        {loadingApprovers || !approvers ? (
                          <Skeleton variant="rectangular" height={52}/>
                        ) : (
                          <>
                            <AutocompleteZts
                              label={t(f.label)}
                              options={approvers}
                              selectedValue={f.value}
                              setValue={(id, value) => {
                                setListFilters(filters
                                  .map(f => f.type === ListFilterType.APPROVER ? ({...f, value}) : f));
                              }}
                              codeAndDesc={false}
                              disableHelperText={true}
                            />
                            <Box className={"filter-close-button"}>
                              <Close
                                fontSize={"small"}
                                onClick={() => disableFilter(f.type)}
                              />
                            </Box>
                          </>
                        )}
                      </Box>

                    case ListFilterType.ROLE:
                      return <Box key={f.type} className={"role-filter-wrapper"}>
                        <>
                          <SelectZts
                            label={t(f.label)}
                            width={"100%"}
                            items={roleItems}
                            selectedValue={f.value ? f.value : ''}
                            setValue={(value) => {
                              setListFilters(filters
                                .map(f => f.type === ListFilterType.ROLE ? ({...f, value: value}) : f));
                            }}
                            disableHelperText={true}
                          />
                          <Box className={"filter-close-button"}>
                            <Close
                              fontSize={"small"}
                              onClick={() => disableFilter(f.type)}
                            />
                          </Box>
                        </>
                      </Box>

                    case ListFilterType.VALID_CRE_CARD:
                      return <Box key={f.type} className={"role-filter-wrapper"}>
                        <>
                          <SelectZts
                            label={t(f.label)}
                            width={"100%"}
                            items={creCardItems}
                            selectedValue={f.value ? f.value : ''}
                            setValue={(value) => {
                              setListFilters(filters
                                .map(f => f.type === ListFilterType.VALID_CRE_CARD ? ({...f, value: value}) : f));
                            }}
                            disableHelperText={true}
                          />
                          <Box className={"filter-close-button"}>
                            <Close
                              fontSize={"small"}
                              onClick={() => disableFilter(f.type)}
                            />
                          </Box>
                        </>
                      </Box>

                    default:
                      return <></>
                  }
                })}
            </>
          ) : (<></>)}

          {filters && filters.filter(f => !f.hidden && !f.enabled).length > 0 &&
            <Button
              disableRipple={true}
              variant="contained"
              className={"filter-button"}
              onClick={(e) => {
                handleFilterClick(e)
              }}>
              <Typography variant={"button"} mr={2}>{tCommon("list.filter")}</Typography>
              <Add fontSize={"small"}/>
            </Button>
          }

        </Stack>
      </Box>

      {filters && <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
      >
        {filters
          .filter(f => !f.hidden && !f.enabled)
          .map((filter) => {
            return <MenuItem
              key={filter.type}
              onClick={() => showFilter(filter.type)}
            >
              {t(filter.label)}
            </MenuItem>
          })}
      </Menu>}
    </>
  );
}
