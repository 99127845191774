import {getApiUrl, hasApiUrl} from "./environment";

const axios = require('axios').default;
const {getToken} = require("./token");


export const setAuthInterceptor = () => {

  axios.interceptors.request.use(
    req => {
      const currentAuth = req.headers['Authorization'];
      const hasAuth = currentAuth !== undefined && currentAuth !== null;

      if (hasAuth && currentAuth==='None') {
        // Rimuovo
        req.headers['Authorization']=undefined;
      }

      if (!hasAuth && hasApiUrl()) {
        const jwt = getToken();
        if (jwt!==null) {
          const apiUrl = getApiUrl();
          const reqUrl = req.url;
          if (reqUrl.startsWith(apiUrl) && !reqUrl.includes('/auth/login')) {
            req.headers['Authorization'] = `Bearer ${jwt}`;
          }
        }
      }

      return req;
    },
    err => {
      return Promise.reject(err);
    }
  );
}
