import {Grid, Skeleton} from "@mui/material";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {NewExpenseExpItem, NewExpenseFormValues} from "./validation";
import {TextFieldZts} from "../../base/text-field";
import Box from "@mui/material/Box";
import {ExpenseImg} from "../../expenses/commons/ExpenseImg";
import {ExpNoteWithStaff, SkeletonRowHeight} from "../model";
import {AutocompleteZts} from "../../base/autocomplete";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {StaffTravelPolicyExpenseItem} from "../../users/model";
import {DatePickerZts} from "../../base/date-picker";
import moment from "moment/moment";
import {DateFormat} from "../../model";
import {formatNumber, parseNumber} from "../../../util/NumberUtil";
import {ExpNoteExpenseFull} from "./model";
import {Colleagues} from "./Colleagues";
import {Guests} from "./Guests";
import {Delete} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useExpenseForm} from "../../../hooks/useExpenseForm";
import {useLoggedUser} from "../../../hooks/useLoggedUser";
import {formatDate} from "../../../util/DateUtil";

type ComponentProps = {
  expNote: ExpNoteWithStaff | null,
  editExpense?: ExpNoteExpenseFull,
  index: number,
  readonly?: boolean,
  currentExpItems: StaffTravelPolicyExpenseItem[],
  loadingExpItem: boolean,
  handleDelete: (index: number) => void
}

export const SingleAdditionalExpense = (
  {
    expNote,
    editExpense,
    index,
    currentExpItems,
    loadingExpItem,
    readonly,
    handleDelete
  }: ComponentProps
) => {

  const {userLocale: locale, companyCurrencyCode, companyDecimalNum: decimalNum} = useLoggedUser();

  const formMethods = useFormContext<NewExpenseFormValues>();

  const additionalExpense = formMethods.getValues(`additionalExpenses.${index}`);

  const expItem: (AutocompleteGenericOption & NewExpenseExpItem | null) = additionalExpense.expenseItem;

  const [showCompPeriod, setShowCompPeriod] = useState(expItem ? expItem.compPeriod : false);

  const [amountChangeTO, setAmountChangeTO] = useState<any>(null);

  const [convertedAmount, setConvertedAmount] = useState(editExpense ? formatNumber(editExpense.compAmount, locale, decimalNum) : '');

  const {t} = useTranslation('exp-note', {keyPrefix: 'expense.edit'});

  const editableAndReadonly = !!editExpense && readonly;

  const compPeriodStartLabel = expItem?.compPeriodStartLabel;
  const compPeriodEndLabel = expItem?.compPeriodEndLabel;

  const currency: AutocompleteGenericOption | null = useWatch({name: "currency", exact: true});
  const exchange: string = useWatch({name: "exchange", exact: true});

  const {
    updateSingleExpenseItem,
    filterExpenseItems
  } = useExpenseForm();

  useEffect(() => {
    //TODO: non va bene perchè quando apro in modifica ricalcola subito il controvalore
    //      invece di mostrare il valore che arriva dal BE
    doAfterAmountChanged(formMethods.getValues(`additionalExpenses.${index}.amount`));
  }, [currency, exchange]);

  const onChangeExpItem = (expItemId: number) => {
    //TODO: reset campi??

    const currentExpItem = currentExpItems?.find(item => item.id === expItemId);
    if (currentExpItem) {
      updateSingleExpenseItem(currentExpItem, index + 1);
      checkCompPeriod(currentExpItem);
    }
  }

  const checkCompPeriod = (expItem: StaffTravelPolicyExpenseItem) => {
    if (expItem && expItem.compPeriod) {
      setShowCompPeriod(true);
    } else {
      setShowCompPeriod(false);
    }
  }

  const onChangeCompDate = (date: Date | null) => {
    //TODO: filtrare le carte di credito???
  }

  const onAmountChangeHandler = (amount: string) => {
    if (formMethods.getValues('currency')?.code === companyCurrencyCode) {
      return;
    }

    if (amountChangeTO) {
      clearTimeout(amountChangeTO);
    }
    setAmountChangeTO(setTimeout(() => {
      doAfterAmountChanged(amount)
    }, 400));
  }

  const doAfterAmountChanged = (amount: string) => {
    const parsedExchange = parseNumber(exchange, locale);
    const parsedAmount = parseNumber(amount, locale);
    if (parsedExchange && parsedAmount) {
      setConvertedAmount(formatNumber(parsedAmount / parsedExchange, locale, decimalNum));
    } else {
      setConvertedAmount('');
    }
  }

  return (
    <Box className="additional-expense" mb={2.5} sx={{position: "relative"}}>

      {readonly ? <></> : (
        <Box
          className="delete-button"
        >
          <IconButton
            size={"small"}
            onClick={() => handleDelete(index)}
          >
            <Delete
              className={"text-danger"}
              fontSize={"small"}
            />
          </IconButton>
        </Box>
      )}

      <Grid
        container
        columnSpacing={3}
        rowSpacing={2}
      >
        <Grid item xs={6}>
          {/*Voci spesa*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              label={t('expenseItem')}
              value={expItem?.desc}
              InputProps={{
                startAdornment: expItem ? <Box pt={2.8} mr={1}><ExpenseImg label={expItem.desc}
                                                                           img={expItem.icon ? expItem.icon : ''}/></Box> : <></>
              }}
            />
          ) : (
            <>
              {loadingExpItem && <Skeleton height={SkeletonRowHeight}/>}
              {!loadingExpItem &&
                <Controller
                  name={`additionalExpenses.${index}.expenseItem`}
                  control={formMethods.control}
                  render={({field, fieldState}) => {
                    return <AutocompleteZts
                      id={field.name}
                      label={t('expenseItem')}
                      options={filterExpenseItems(currentExpItems)
                        .map(i => createAutocompleteGenericOption(
                          i.id,
                          i.expenseCode,
                          i.expenseDescription,
                          i.expenseIcon))}
                      selectedValue={field.value}
                      setValue={(id, value: AutocompleteGenericOption) => {
                        if (value && value.id) {
                          onChangeExpItem(value.id);

                          const expItem = currentExpItems.find(e => e.id === value.id);
                          if (expItem) {
                            formMethods.setValue(id,
                              {
                                ...value,
                                type: expItem.expenseType,
                                colleagues: expItem.colleagueEnb,
                                guests: expItem.guestEnb,
                                routes: expItem.carRouteEnb,
                                measUnit: expItem.measureUnit,
                                compPeriod: expItem.compPeriod,
                                compPeriodStartLabel: expItem.compPeriodStartLabel,
                                compPeriodEndLabel: expItem.compPeriodEndLabel,
                                attachRequired: expItem.attachRequired,
                                localityRequired: expItem.localityRequired,
                                notesRequired: expItem.notesRequired,
                                projectRequired: expItem.projectRequired,
                              }
                            );
                          } else {
                            formMethods.setValue(id, value);
                          }
                        }
                      }}
                      codeAndDesc={false}
                      errorMsg={fieldState.error?.message}
                      renderIcon={(icon) => <ExpenseImg label="" img={icon}/>}
                    />
                  }}
                />
              }
            </>
          )}
        </Grid>

        <>
          {!showCompPeriod ? (
            <>
              <Grid item xs={3}>
                {/*Data competenza*/}
                {editableAndReadonly ? (
                  <TextFieldZts
                    disabled={true}
                    sx={{width: "100%"}}
                    label={t('compDate')}
                    value={formatDate(additionalExpense.compDate, locale)}
                  />
                ) : (
                  <Controller
                    name={`additionalExpenses.${index}.compDate`}
                    control={formMethods.control}
                    render={({field, fieldState}) => {
                      return <DatePickerZts
                        label={t("compDate")}
                        field={field}
                        errorMsg={fieldState.error?.message}
                        minDate={expNote ? moment(expNote.startDate, DateFormat) : null}
                        maxDate={expNote ? moment(expNote.endDate, DateFormat) : null}
                        width={"100%"}
                        onChangeHandler={(value: Date) => {
                          field.onChange(value);
                          onChangeCompDate(value);
                        }}
                        defaultCalendarMonth={expNote ? moment(expNote.startDate) : null}
                      />
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={3}></Grid>
            </>
          ) : (
            <>
              <Grid item xs={3}>
                {/*Data inizio competenza*/}
                {editableAndReadonly ? (
                  <TextFieldZts
                    disabled={true}
                    sx={{width: "100%"}}
                    label={compPeriodStartLabel ? compPeriodStartLabel : t('compStartDate')}
                    value={formatDate(additionalExpense.compStartDate, locale)}
                  />
                ) : (
                  <Controller
                    name={`additionalExpenses.${index}.compStartDate`}
                    control={formMethods.control}
                    render={({field, fieldState}) => {
                      return <DatePickerZts
                        label={compPeriodStartLabel ? compPeriodStartLabel : t("compStartDate")}
                        field={field}
                        errorMsg={fieldState.error?.message}
                        minDate={expNote ? moment(expNote.startDate, DateFormat) : null}
                        maxDate={expNote ? moment(expNote.endDate, DateFormat) : null}
                        width={"100%"}
                        onChangeHandler={(value: Date) => {
                          field.onChange(value);
                        }}
                        defaultCalendarMonth={expNote ? moment(expNote.startDate) : null}
                      />
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                {/*Data fine competenza*/}
                {editableAndReadonly ? (
                  <TextFieldZts
                    disabled={true}
                    sx={{width: "100%"}}
                    label={compPeriodEndLabel ? compPeriodEndLabel : t('compEndDate')}
                    value={formatDate(additionalExpense.compEndDate, locale)}
                  />
                ) : (
                  <Controller
                    name={`additionalExpenses.${index}.compEndDate`}
                    control={formMethods.control}
                    render={({field, fieldState}) => {
                      return <DatePickerZts
                        label={compPeriodEndLabel ? compPeriodEndLabel : t("compEndDate")}
                        field={field}
                        errorMsg={fieldState.error?.message}
                        minDate={expNote ? moment(expNote.startDate, DateFormat) : null}
                        maxDate={expNote ? moment(expNote.endDate, DateFormat) : null}
                        width={"100%"}
                        onChangeHandler={(value: Date) => {
                          field.onChange(value);
                          onChangeCompDate(value);
                        }}
                        defaultCalendarMonth={expNote ? moment(expNote.startDate) : null}
                      />
                    }}
                  />
                )}
              </Grid>
            </>
          )}
        </>

        <Grid item xs={3}>
          {/*Importo*/}
          {editableAndReadonly ? (
            <TextFieldZts
              disabled={true}
              label={t('amount')}
              value={additionalExpense.amount}
            />
          ) : (
            <Controller
              name={`additionalExpenses.${index}.amount`}
              control={formMethods.control}
              render={({field, fieldState}) => {
                return <TextFieldZts
                  label={t('amount')}
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value);

                    onAmountChangeHandler(value);
                  }
                  }
                  errorMsg={fieldState.error?.message}
                  align={'right'}
                  fullWidth
                />
              }}
            />
          )}
        </Grid>

        <Grid item xs={3}>
          {/*Divisa*/}
          <TextFieldZts
            disabled={true}
            label={t('currency')}
            value={formMethods.getValues('currency')?.code}
          />
        </Grid>

        <Grid item xs={3}>
          {/*Controvalore*/}
          {formMethods.getValues('currency')?.code !== companyCurrencyCode ? (
            <TextFieldZts
              label={companyCurrencyCode}
              disabled={true}
              value={convertedAmount}
              inputProps={{
                style: {textAlign: 'right'}
              }}
            />
          ) : <></>}
        </Grid>

        <Grid item xs={3}></Grid>

      </Grid>

      {expNote && expItem && (expItem.colleagues || expItem.guests) &&
        <Grid
          container
          columnSpacing={3}
          mt={2}
        >
          {/*Colleghi*/}
          {expNote && expItem && expItem.colleagues &&
            <Grid item xs={6}>
              <Colleagues
                staffId={expNote.staffId}
                editableAndReadonly={editableAndReadonly}
                formArrayName={`additionalExpenses.${index}.colleagues`}
              />
            </Grid>
          }

          <Grid item xs={6}>
            {/*Ospiti*/}
            {expNote && expItem && expItem.guests &&
              <Guests
                expNote={expNote}
                editableAndReadonly={editableAndReadonly}
                formArrayName={`additionalExpenses.${index}.guests`}
              />}
          </Grid>
        </Grid>}

    </Box>
  );
}
