import {UserItemType} from "../model";
import {Grid} from "@mui/material";
import {UserItem} from "./UserItem";
import React from "react";
import {CreditCard, Euro, Person} from "@mui/icons-material";
import {Suitcase} from "../../base/svg-icons";
import {useTranslation} from "react-i18next";

type ComponentProps = {
  handleTravelPoliciesOpen: Function,
  handleProjectsOpen: Function,
  handleCreditCardOpen: Function,
  handleExpenseOpen: Function,
  disabled?: boolean
}

export const Items = (props: ComponentProps) => {

  const {t} = useTranslation('user', {keyPrefix: "detail.items"});

  return (
    <Grid
      container
      rowSpacing={{
        xs: 2,
        sm: 0,
        lg: 2
      }}
      columnSpacing={{
        xs: 0,
        sm: 2,
        lg: 0
      }}
      alignSelf={"flex-start"}
      mt={{
        xs: 1,
        lg: 1
      }}
      sx={{
        flexBasis: {
          lg: '226px'
        },
        flexShrink: 0
      }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        lg={12}
      >
        <UserItem
          type={UserItemType.TRAVEL_POLICY}
          title={t("tp.title")}
          icon={
            <Suitcase fontSize={"small"} />
          }
          disabled={props.disabled}
          onItemClick={props.handleTravelPoliciesOpen}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={12}
      >
        <UserItem
          type={UserItemType.PROJECT}
          title={t("projects.title")}
          icon={
            <Person fontSize={"small"} />
          }
          disabled={props.disabled}
          onItemClick={props.handleProjectsOpen}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={12}
      >
        <UserItem
          type={UserItemType.CARD}
          title={t("creditCards.title")}
          icon={
            <CreditCard fontSize={"small"} />
          }
          disabled={props.disabled}
          onItemClick={props.handleCreditCardOpen}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={12}
      >
        <UserItem
          type={UserItemType.TARIF}
          title={t("tarif.title")}
          icon={
            <Euro fontSize={"small"} />
          }
          disabled={props.disabled}
          onItemClick={props.handleExpenseOpen}
        />
      </Grid>
    </Grid>
  );
}
