import {ReactElement} from "react";
import {PopupContentProps} from "../model";
import {ITALIAN} from "../../../util/LocalizationUtil";

export const SettingAccountsPopupContent = ({locale}: PopupContentProps): ReactElement => {
  switch (locale) {
    case ITALIAN:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile attivare la gestione contabile delle note spese. Sono previsti tre conti che devono
          essere specificati nelle impostazioni generali: conto cassa, conto IVA e un &ldquo;<strong>Conto fornitore
            generico</strong>&rdquo; cui verranno attribuire in ZTravel Smart i debiti verso i fornitori. Dopo aver
          completato questa configurazione la &ldquo;Contabilizzazione&rdquo; delle note spese produrr&agrave; un foglio
          Excel con il <strong>dettaglio delle scritture contabili di tutte le spese e potr&agrave; essere importato
            nell&rsquo;ERP aziendale</strong>.</p>
      );
    default:
      return (
        <p>Attraverso questa funzionalit&agrave;, disponibile nella versione Professional di ZTravel
          Smart, &egrave; possibile attivare la gestione contabile delle note spese. Sono previsti tre conti che devono
          essere specificati nelle impostazioni generali: conto cassa, conto IVA e un &ldquo;<strong>Conto fornitore
            generico</strong>&rdquo; cui verranno attribuire in ZTravel Smart i debiti verso i fornitori. Dopo aver
          completato questa configurazione la &ldquo;Contabilizzazione&rdquo; delle note spese produrr&agrave; un foglio
          Excel con il <strong>dettaglio delle scritture contabili di tutte le spese e potr&agrave; essere importato
            nell&rsquo;ERP aziendale</strong>.</p>
      );
  }
}
